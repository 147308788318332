<header>
 

</header>

<div *ngIf="user" class="navigation-container" [class.navigation-is-mobile]="isMobile()">
  <mat-toolbar>
    <!-- Logo -->
    <a class="BO9-logo"
      [class.dark]="isDarkTheme"
      [class.light]="isLightTheme"
      routerLink='/profile/Edit'>
      BO9
    </a>
    <button mat-button (click)="drawer.toggle()"><mat-icon>menu</mat-icon></button>
    Welcome {{ user.contact.names.name.first }}
    <div class="helpfooter"><button mat-stroked-button (click)="help()"><mat-icon>help_center</mat-icon></button></div>
    <div class="helpfooter"><button mat-stroked-button (click)="support()"><mat-icon>support_agent</mat-icon></button></div>
    <div class="logoutfooter"><button mat-stroked-button (click)="logout()"><mat-icon>logout</mat-icon></button></div>
  </mat-toolbar>
    <app-notify></app-notify>

  <mat-sidenav-container autosize class="navigation-sidebar-container">

    <mat-sidenav #drawer mode="side" opened role="navigation">

    <mat-nav-list *ngIf="!isUser()">
     
        <a class="sidebar-people" mat-list-item *ngIf="isAdmin()"
        routerLink='/dashboard'
        routerLinkActive="sidebar-active-link">
        <mat-icon>manage_accounts</mat-icon>
        <span *ngIf="!isMobile()">Admin</span>
      </a>
      <a class="sidebar-dashboard" mat-list-item *ngIf="isJourneyman()"
      routerLink='/guild/edit'
      routerLinkActive="sidebar-active-link">
      <mat-icon>dashboard</mat-icon>
      <span *ngIf="!isMobile()">Dashboard</span>
    </a>


      <a class="sidebar-people" mat-list-item *ngIf="isAdmin()"
        routerLink='/people'
        routerLinkActive="sidebar-active-link">
        <mat-icon>group</mat-icon>
        <span *ngIf="!isMobile()">People</span>
      </a>

      <a class="sidebar-messages" mat-list-item *ngIf="isMaster()"
        routerLink='/events'
        routerLinkActive="sidebar-active-link">
        <mat-icon>event</mat-icon>
        <span *ngIf="!isMobile()">Event Mgr</span>
      </a>
      <!--
      <a class="sidebar-messages" mat-list-item *ngIf="isAdmin()"
      routerLink='/affiliates'
      routerLinkActive="sidebar-active-link">
      <mat-icon>supervised_user_circle</mat-icon>
      <span *ngIf="!isMobile()">Guild Mgr</span>
    </a>-->
    <a class="sidebar-people" mat-list-item *ngIf="isAdmin()"
    routerLink='/students'
    routerLinkActive="sidebar-active-link">
    <mat-icon>cast_for_education</mat-icon>
    <span *ngIf="!isMobile()">Student Mgr</span>
  </a>
  <a class="sidebar-pearls" mat-list-item *ngIf="isMaster()"
  routerLink='/pearls'
  routerLinkActive="sidebar-active-link">
  <mat-icon>receipt_long</mat-icon>
  <span *ngIf="!isMobile()">Pearls</span>
</a>
<a class="sidebar-products" mat-list-item *ngIf="isAdmin()"
routerLink='/products'
routerLinkActive="sidebar-active-link">
<mat-icon>inventory_2</mat-icon>
<span *ngIf="!isMobile()">Products</span>
</a>
      <a class="sidebar-messages" mat-list-item *ngIf="isAdmin()"
      routerLink='/orders'
      routerLinkActive="sidebar-active-link">
      <mat-icon>shopping_basket</mat-icon>
      <span *ngIf="!isMobile()">Orders</span>
    </a>
      <a class="sidebar-messages" mat-list-item *ngIf="isAdmin()"
        routerLink='/activecampaign'
        routerLinkActive="sidebar-active-link">
        <mat-icon>email</mat-icon>
        <span *ngIf="!isMobile()">Act. Camp.</span>
      </a>
      <a class="sidebar-messages" mat-list-item *ngIf="isAdmin()"
      routerLink='/deals'
      routerLinkActive="sidebar-active-link">
      <mat-icon>attach_money</mat-icon>
      <span *ngIf="!isMobile()">Deals</span>
    </a>
    <a class="sidebar-messages" mat-list-item *ngIf="isAdmin()"
    routerLink='/credit'
    routerLinkActive="sidebar-active-link">
    <mat-icon>article</mat-icon>
    <span *ngIf="!isMobile()">Credit Hours</span>
  </a>
      <a class="sidebar-messages" mat-list-item *ngIf="isAdmin()"
      routerLink='/errors'
      routerLinkActive="sidebar-active-link">
      <mat-icon>notification_important</mat-icon>
      <span *ngIf="!isMobile()">Errors</span>
      </a>
      <a class="sidebar-messages" mat-list-item *ngIf="isAdmin()"
      routerLink='/relations'
      routerLinkActive="sidebar-active-link">
      <mat-icon>family_restroom</mat-icon>
      <span *ngIf="!isMobile()">Relations</span>
      </a>
      <a class="sidebar-messages" mat-list-item *ngIf="isAdmin()"
      routerLink='/groups'
      routerLinkActive="sidebar-active-link">
      <mat-icon>groups</mat-icon>
      <span *ngIf="!isMobile()">Groups</span>
      </a>
            <!--
      <a class="sidebar-messages" mat-list-item *ngIf="isAdmin()"
      routerLink='/movement'
      routerLinkActive="sidebar-active-link">
      <mat-icon>animation</mat-icon>
      <span *ngIf="!isMobile()">Movements</span>
      </a>

      <a class="sidebar-messages" mat-list-item *ngIf="isAdmin()"
        routerLink='/invites'
        routerLinkActive="sidebar-active-link">
      <mat-icon>drafts</mat-icon>
      <span class="sidebar-dashboard-text">Invites</span>
      </a>-->
      <a class="sidebar-messages" mat-list-item *ngIf="isMaster()"
      routerLink='/pages'
      routerLinkActive="sidebar-active-link">
      <mat-icon>pages</mat-icon>
      <span *ngIf="!isMobile()">Pages</span>
    </a>
    <a class="sidebar-messages" mat-list-item *ngIf="isAdmin()"
    routerLink='/agreements'
    routerLinkActive="sidebar-active-link">
    <mat-icon>handshake</mat-icon>
    <span *ngIf="!isMobile()">Agreements</span>
  </a>
    <!--
    <a class="sidebar-messages" mat-list-item *ngIf="isMaster()"
    routerLink='/templates'
    routerLinkActive="sidebar-active-link">
    <mat-icon>web</mat-icon>
    <span *ngIf="!isMobile()">Templates</span>
    </a>-->
    <a class="sidebar-messages" mat-list-item *ngIf="isAdmin()"
    routerLink='/maintenance'
    routerLinkActive="sidebar-active-link">
    <mat-icon>engineering</mat-icon>
    <span *ngIf="!isMobile()">Maintenance</span>
    </a>
    <a class="sidebar-home" mat-list-item
    routerLink='/profile/Edit'
    routerLinkActive="sidebar-active-link">
    <mat-icon>settings</mat-icon>
    <span *ngIf="!isMobile()">Profile</span>
  </a>
  

  <!--
      <a class="sidebar-messages" mat-list-item *ngIf="isAdmin()"
        routerLink='/certification'
        routerLinkActive="sidebar-active-link">
        <mat-icon>school</mat-icon>
        <span class="sidebar-dashboard-text">Certification</span>
      </a>

-->
    </mat-nav-list>
    <div class="versionfooter">Version {{ version }}</div>
    <div class="versionfooter" [ngClass]="{'serverMismatch': serverVersion !== srvrVersion}">Srvr {{srvrVersion }}</div>
    <button mat-stroked-button (click)="releaseNotes()">Rel. Notes</button>
  </mat-sidenav>
  <mat-sidenav-content >
    <router-outlet ></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
</div>