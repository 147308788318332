  <mat-card class="bo9mat">

  <my-slide-panel [activePane]="currentPane">

    <div centerPane>
      <button mat-stroked-button (click)="onRefreshClick()"   [ngClass]="{'green' : togglec, 'red': !togglec}">Clear Filter<mat-icon>clear</mat-icon></button>
      <button mat-stroked-button (click)="onToDBClick()"   [ngClass]="{'green' : togglen, 'red': !togglen}">Filter for Not In DB<mat-icon>save_alt</mat-icon></button>
      <button mat-stroked-button (click)="onMergeClick()"   [ngClass]="{'green' : togglem, 'red': !togglem}">Filter for Missing NN<mat-icon>merge_type</mat-icon></button>
      <form novalidate >
      <div class="">
        <label  >
        Search:
        </label>
          <input type="text" [(ngModel)]="searchString" name="searchString"/>
          <button mat-stroked-button name="search" (click)="onClickSearch()">Search <mat-icon>search</mat-icon></button>
          <button mat-stroked-button name="search" (click)="onClickLoad()">Load via Filter<mat-icon>run_circle</mat-icon></button>
            <mat-icon>save</mat-icon> - Needs Acc Camp Update  &nbsp;  <mat-icon>thumb_up_alt</mat-icon> - No action needed &nbsp; <mat-icon>system_update_alt</mat-icon> - Contact NOT in DB

      </div>
    </form> 
      <mat-progress-spinner *ngIf="loading" mode="indeterminate"> </mat-progress-spinner>

        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef> Edit </th>
            <td mat-cell *matCellDef="let element">
              <button type="button" (click)="onClickView(element)" matTooltip="Edit Contact"> <mat-icon>manage_accounts</mat-icon></button>
     
            </td>
          </ng-container>
          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.person!=null && element.naturalNumberIssue "> <button type="button" (click)="onClickMerge(element)" matTooltip="Save"> <mat-icon>save</mat-icon></button></span>
            <!--  <span *ngIf="element.person!=null && ( element.differences.length ==0 || element.diffAccepted) "> <button type="button"  matTooltip="No Merge Needed">  <mat-icon>thumb_up_alt</mat-icon></button></span> -->
              <span *ngIf="element.person==null" matTooltip="Not in Backend"> <button type="button" (click)="onClickSave(element)"> <mat-icon>system_update_alt</mat-icon></button></span>
            </td>
          </ng-container>
          <ng-container matColumnDef="actCid">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ActID </th>
            <td mat-cell *matCellDef="let element"><a href="https://bodyof9.activehosted.com/app/contacts/{{element._key}}" target="_blank">{{element._key}} </a>  <span *ngIf="element.person && element.person.mailCampaign && element.person.mailCampaign.customerId && element._key !== element.person.mailCampaign.customerId.id">!!</span></td>
          </ng-container>
          <ng-container matColumnDef="first">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> First </th>
            <td mat-cell *matCellDef="let element"> {{element.first}} </td>
          </ng-container>
          <ng-container matColumnDef="last">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last </th>
            <td mat-cell *matCellDef="let element"> {{element.last}} </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.actCampPerson.contact.DNC" > <mat-icon>do_not_touch</mat-icon> <button mat-button [cdkCopyToClipboard]="element.email"><mat-icon >content_copy</mat-icon></button>{{element.email}} </span>
              <span *ngIf="!element.actCampPerson.contact.DNC" ><button mat-button [cdkCopyToClipboard]="element.email"><mat-icon >content_copy</mat-icon></button>{{element.email}}   </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="naturalNumber" >
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Acc Nat. Num. </th>
            <td mat-cell  *matCellDef="let element" [className]="element.naturalNumberIssue? 'nnissue':'centered'"> {{element.naturalNumber}} </td>
          </ng-container>
          <ng-container matColumnDef="assertedNumber">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> DB Nat. Num. </th>
            <td mat-cell *matCellDef="let element" class="centered" > {{element.person.naturalNumbers.identified.naturalNumber}} </td>
          </ng-container>
          <ng-container matColumnDef="dateIdentified">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> When </th>
            <td mat-cell *matCellDef="let element"> {{element.dateIdentified}} </td>
          </ng-container>
          <ng-container matColumnDef="idAt">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> At </th>
            <td mat-cell *matCellDef="let element"> {{element.idAt}} </td>
          </ng-container>
          <ng-container matColumnDef="idBy">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> By </th>
            <td mat-cell *matCellDef="let element"> {{element.idBy}} </td>
          </ng-container>
          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> City </th>
            <td mat-cell *matCellDef="let element"> {{element.city}} </td>
          </ng-container>
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef > Delete </th>
            <td mat-cell *matCellDef="let element"><button type="button" (click)="onClickDelete(element)"> <mat-icon>delete_forever</mat-icon></button></td>
          </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
          </table>
          <mat-paginator   [pageSize]="pageSize"  [pageSizeOptions]="[5, 10, 15, 20, 50, 100]" (page)="handlePage($event)"></mat-paginator>  
    </div>

    <div rightPane> 
      <button mat-stroked-button (click)="onCloseClick()">Back</button><br>
      <span *ngIf="operation == 'View'" >  <person-form [person]="currentP$ | async"></person-form></span>
      <span *ngIf="operation == 'Merge'" ><merge-person-form [source]="ActiveCampaign" 
        [origPerson]="origP" [newPerson]="newP" [differences]=differences 
        [extSource]="'Act Campaign'" (notifyParent)="mergeSubmittedHandler($event)"></merge-person-form></span>

    </div>
  </my-slide-panel>


  
</mat-card>
