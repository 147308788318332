    <ul>
        <li *ngFor="let diff of differences"> {{ diff.item }} - Int DB: '{{ diff.db }}'',  Ext. Source: '{{ diff.new }}'</li>
    </ul>
      <form [formGroup]="MergePersonForm" (ngSubmit)="submitMergePersonForm()" class="personal-form">
        <button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Update Int. DB</button>
       <span *ngIf="extSource != 'Person'"> <button mat-button type="button" class="btn btn-save mat-submit-button" (click)="onClickAcceptDifferences()"> Accept Diffs</button></span>
      User Key: {{ origPerson?._key }}
        <table>
          <tr><th> Known (In Database) </th><th> Replace </th><th> New ({{ extSource }}) </th></tr>
          <tr><td> Key(In Database) {{ origPerson?._key }} </td><td> Replace </td><td> Key ({{ extSource }}) {{ newPerson?._key }}</td></tr>
          <tr><td> <contact-form formControlName="origcontact" [diffs]="differences"></contact-form></td>
              <td><button mat-button type="button" (click)="onClickOverwriteDB('contact')"> <mat-icon>arrow_left</mat-icon></button> </td>
              <td> <contact-form formControlName="newcontact" [diffs]="differences"></contact-form></td></tr>

              <tr><td><nn-form formControlName="orignatnum" [diffs]="differences"></nn-form></td>
                <td>
                    <button mat-button type="button" (click)="onClickOverwriteDB('natnum')"> <mat-icon>arrow_left</mat-icon></button>
                    </td>
                <td><nn-form formControlName="newnatnum" [diffs]="differences"></nn-form></td></tr>
          <tr><td>          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Biography</mat-label>
            <textarea matInput  formControlName="origbio" ></textarea>

          </mat-form-field></td>
              <td><button mat-button type="button" (click)="onClickOverwriteDB('bio')"> <mat-icon>arrow_left</mat-icon></button></td>
              <td>          <mat-form-field appearance="outline" class="full-width">
                <mat-label>Biography</mat-label>
                <textarea matInput  formControlName="newbio" ></textarea>
              </mat-form-field></td></tr>
          <tr><td>          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Notes</mat-label>
           <textarea matInput  formControlName="orignotes" ></textarea>

          </mat-form-field></td>
              <td><button mat-button type="button" (click)="onClickOverwriteDB('notes')"> <mat-icon>arrow_left</mat-icon></button> </td>
              <td>          <mat-form-field appearance="outline" class="full-width">
                <mat-label>Notes</mat-label>
               <textarea matInput  formControlName="newnotes" ></textarea>
              </mat-form-field></td></tr>

              <tr><td>       
                <mat-form-field  appearance="outline" >
                  <mat-label>Referral Source</mat-label>
                    <mat-select formControlName="origsource">
                    <mat-option *ngFor="let nns of referrals" [value]="nns">
                      {{ nns }}
                    </mat-option>
                  </mat-select> 
                </mat-form-field>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>BirthOrder on Mother's Side</mat-label>
                  <input matInput  formControlName="origBirthOrder" />
                </mat-form-field>
                <mat-form-field  appearance="outline" >
                  <mat-label>Person Or Other</mat-label>
                  <input matInput 
                    formControlName="origpersonOrOther"
                  />
                </mat-form-field>
              </td>
                  <td><button mat-button type="button" (click)="onClickOverwriteDB('source')"> <mat-icon>arrow_left</mat-icon></button> </td>
                  <td>   
                    <mat-form-field  appearance="outline" >
                      <mat-label>Referral Source</mat-label>
                        <mat-select formControlName="newsource">
                        <mat-option *ngFor="let nns of referrals" [value]="nns">
                          {{ nns }}
                        </mat-option>
                      </mat-select> 
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width">
                      <mat-label>BirthOrder on Mother's Side</mat-label>
                      <input matInput  formControlName="newBirthOrder" />
                    </mat-form-field>
                    <mat-form-field  appearance="outline" >
                      <mat-label>Person Or Other</mat-label>
                      <input matInput 
                        formControlName="newpersonOrOther"
                      />
                    </mat-form-field>
                 </td></tr>

        </table>

        <button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Update Int. DB</button>
        <span *ngIf="extSource != 'Person'"> <button mat-button type="button" class="btn btn-save mat-submit-button" (click)="onClickAcceptDifferences()"> Accept Diffs</button></span>
      <!-- <button mat-button type="button" class="btn btn-save mat-submit-button" (click)="onClickRemote()" >  Update {{ extSource }}</button>--> 
    </form>