<div [formGroup]="form">
  <div>
    <h3>Phones</h3>
  
    <mat-label>Do Not Contact</mat-label> &nbsp; <mat-checkbox (change)="selectDNC()" [checked]="form.value.DNC"></mat-checkbox>
  

  <ng-container formArrayName="items">
    <div *ngFor="let item of items.controls; index as idx" [formGroupName]="idx">
      <mat-label>Primary? </mat-label><mat-checkbox (change)="selectPrimary(idx)" [checked]="item.value.primary"></mat-checkbox><br />
        <mat-form-field  appearance="outline" >
          <mat-label>Type</mat-label>

          <mat-select matNativeControl  formControlName="type" required>
            <mat-option *ngFor="let nns of contactTypes" [value]="nns">
              {{ nns }}
            </mat-option>
          </mat-select>

        </mat-form-field>
             
        <mat-form-field  appearance="outline" >
          <mat-label>Number</mat-label>
          <input matInput 
            formControlName="number"
            id="number"
          />
        </mat-form-field>
          <mat-form-field  appearance="outline" >
            <mat-label>Country Code</mat-label>
            <input matInput 
              formControlName="countryCode"
              id="countryCode"
            />
  
   
        </mat-form-field>
        <button mat-button type="button" (click)="removePhoneItem(idx)"> <mat-icon>delete</mat-icon></button>
    </div>
    <button mat-button type="button" (click)="addPhoneItem()">Add New Phone Number</button><br>
  </ng-container>
</div>
 
</div>