import { Component, OnInit, OnDestroy } from '@angular/core';

import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { AuthService } from 'src/services/auth/auth.service';
import { Person } from '../../../../bo9-shared/models/person/person.model';

import { ChatRoom } from '../../../../bo9-shared/models/chat/chatroom.model';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { SystemService } from 'src/services/system.service';

import { EventEmitterService } from 'src/services/events/events.service';

import { BO9Container, DisplayMessage } from '../../../../bo9-shared/models/containers.model';
import { ArangoService } from 'src/services/arangoservice/arango.service';

import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { PCount } from '../../../../bo9-shared/models/pearl/pearl.model';
import { userIsAdmin, userIsMaster, userIsJourneyman, userIsApprentice, userisSeniorJ } from '../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { getProductFromSKU, Product } from '../../../../bo9-shared/models/product/product.model';
import { SystemVariable } from '../../../../bo9-shared/models/BO9_base/systemvariables.model';


import { fieldValue, tagValue } from '../../../../bo9-shared/models/mailCampaign/activeCampaign.model';



import { formGroup, Group } from '../../../../bo9-shared/models/person/group.model';

import { createProgramsEntry, IdentificationMethod, productIsProgram, ProgramDefinition, programFromSKU, ProgramNotification } from '../../../../bo9-shared/models/program/program.model';

import { getTupleFromArray, SystemVariablesService, SysTuple } from 'src/services/systemvariables/systemvariables.service';
import { Visit } from '../../../../bo9-shared/models/page/page.model';




@Component({
  selector: 'app-guildmembers',
  templateUrl: './guildmembers.component.html',
  styleUrls: ['./guildmembers.component.scss']
})
export class GuildMembersComponent implements OnInit, OnDestroy, OnDestroy {

  chatrooms: ChatRoom[];
  chatroom$: Observable<ChatRoom[]>;
  loading = false;

  nineECounts: Observable<any>;
  arangoCounts: any;
  arangoNumbers: Observable<any>;

  messages$: Observable<DisplayMessage[]>
  user: Person;

  numberToXfer: number;
  numberToXferActivity: number;
  searchString: string;

  currentP$: Observable<Person>;
  dataSource: SystemVariable[];
  dataSourceTags: tagValue[];
  dataSourceFields: fieldValue[];

  pearlCount: PCount[];
  formGroup$: Observable<formGroup>;
  operNNId: boolean;

  products: Product[] = [];
  programLists: ProgramDefinition[]=[];
  navSubscription;
  messagesLoading$: Observable<boolean>;
  PersonEditForm: FormGroup;
  operation='edit';
  identificationsPurchased= 0;
  packages: ProgramNotification[] = [];
  idAppSKUs: string [] = [];
  visit: Visit;
  idIdentificationSKUs: SysTuple[] = [];

  subscriptions: Subscription[] = [];
  public displayedColumns = ['name','value'];
  public displayedColumnsF = ['name','value'];
  public displayedColumnsT = ['name','value'];
  constructor(
    private auth: AuthService,
    private router: Router,

    private arangoService: ArangoService ) {
      this.initializeWidgets();
      this.user = this.auth.getAuthUser();
      this.visit = {
        page: this.router.url, component: 'GuildComponent', 
        visitor: this.user._key,
        help: false
      };
      this.subscriptions.push(this.arangoService.getVisit(this.visit,0, 5)
      .subscribe ({next: async (res: any) => {
        if (!this.user) {
          console.error('User Not logged in ');
          alert('EMail support&#64;bodyof9.com - Permission  Error #026');
          this.router.navigate(['login'] );
        } else if (!(userIsAdmin(this.user) || userIsApprentice(this.user)  || userIsJourneyman(this.user) || userIsMaster(this.user)   || userisSeniorJ(this.user) ) ) {
          console.error('User Not authorized to see dashboard');
          alert('EMail support&#64;bodyof9.com - Permission  Error - Unauthorized');
          this.router.navigate(['profile/Edit'] );
        } 
        if (res && res.content && res.content.length > 0) { // has already visited
          const res1 = await firstValueFrom(this.arangoService.recordVisit(this.visit));
        } else { // first visit
          // announce first visit
          alert("Welcome to your dashboard - watch this video for instructions how to use it");
          const res1 = await firstValueFrom(this.arangoService.recordVisit(this.visit, true));
        }
       

         
        }

      }))
     }



  ngOnInit() {

  }




  loadHome() {


  }

  onRefreshClick() {
    console.log('Refresh click ');
    this.loadHome();

  }

  makeForm() {

  }

  initializeWidgets() {
  }


  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.subscriptions.forEach(s => s.unsubscribe());

  }

}
