import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { BO9EVENTS } from '../../../../bo9-shared/models/BO9_base/BO9_base.model';
import { ArangoService } from '../arangoservice/arango.service';

import { SearchService } from '../search/search.service';

import webappConfig from '../../../../bo9-shared/config/webappconfig.json';

@Injectable()
export class CalendlyService {
// TODO shrink number returned

 
    constructor (
        private http: HttpClient,
        private arangoService: ArangoService,
        private searchService: SearchService
    ) {

    }

    public getWebHooks():  Observable<any>  {
     return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.CALENDLYWEBHOOKS);

    }

    public makeWebHooks():  Observable<any>  {
        const params = {
            param: ''
        }
        return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.CALENDLYWEBHOOKS,params);
   
    }

    public deleteWebHook(url: string):  Observable<any>  {
    return this.http.delete(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.CALENDLYWEBHOOKS+'?url='+url);

    }
}