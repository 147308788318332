import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';


import { FormRelation, Relation } from '../../../../../bo9-shared/models/person/relation.model';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { Subscription } from 'rxjs';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth/auth.service';

@Component({
  selector: 'app-dialog-relation',
  templateUrl: './relationDialog.component.html',
  styleUrls: ['./relationDialog.component.scss'],

})

export class RelationDialogComponent implements OnInit  {
  disableBtn = false;
  user: Person;
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private dialogRef: MatDialogRef<RelationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: { relation: FormRelation } ,
    private arangoService: ArangoService,private router: Router,
   ) {
      console.log ('Init relation ' + JSON.stringify(data));
      this.user = this.auth.getAuthUser();
      this.subscriptions.forEach(s => s.unsubscribe());
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'RelationDialogComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
     }

  ngOnInit() {

    console.log ('Init relation init ' + JSON.stringify(this.data));

  }


  deleteSelectRelation(p: FormRelation ) {
    this.dialogRef.close(p);
  }

  close() {
    this.dialogRef.close();
  }
}
