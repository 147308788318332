import { Person, mkEmptyPerson, getGender} from '../person/person.model';
import { Event, mkMTEvent} from '../event/event.model';
import { Activity, mkMTActivity } from '../activity/activity.model';
import { createUUID } from '../../uuid.service';
import { updateMeta } from '../BO9_base/meta.model';
import { PhoneType, existingPhoneNumber } from '../contact/phone.model';
import { EmailType, existingEmail } from '../contact/email.model';
import { BO9Address, mkMTAddress, existingAddress, BO9AddressType } from '../contact/address.model';
import { DateToYYMMDDhhmmss } from '../../helpers/time.helper';
import { mkMTContact } from '../contact/contact.model';
import { NaturalNumber, NaturalNumberType,  setNaturalNumber, nnSource } from '../naturalNumber/naturalNumber.model';
import { BO9UUID } from '../UUID.model';
import { mkMTMedia } from '../media/media.model';
import { type } from 'os';


export interface EspressEventDiscount {
    id: string;
    coupon_code: string;
    coupon_code_price:string;
    use_percentage:string;
    coupon_code_description: string;
    each_attendee: string;
    wp_user: number;
    apply_to_all: number;
}
export interface EspressoEventTime { 
    event_id: string,
    start_time: string,
    end_time: string,
    reg_limit: string
}

export interface EspressoEventCategory{ 
    event_id: number,
    category_name: string
}


export interface EspressoEventAttendeeCount{ 
    event_id: string,
    attendees: number
}

export interface WPEvent {
    events: Event[],
    categories: EspressoEventCategory[],
    attendees: EspressoEventAttendeeCount[]
}
export interface EspressoEvent {
    id?: string;
    address2: string;
    event_code: string;
    event_name: string;
    event_desc: string;
    display_desc: string;
    display_reg_form: string;
    event_identifier: string;
    address: string;
    zip: string;
    city: string;
    state: string;
    country: string;
    phone   : string;
    virtual_url: string;
    virtual_phone   : string;
    venue_title: string;
    venue_url: string;
    venue_phone: string;
    venue_image: string;
    registration_start: string;
    registration_end: string;
    start_date: string;
    end_date: string;
    allow_multiple: string;
    send_mail       : string;
    is_active       : string;
    event_status: string;
    conf_mail       : string;
    use_coupon_code: string;
    member_only: string;
    externalURL: string;
    early_disc: string;
    early_disc_date: string;
    early_disc_percentage   : string;
    alt_email: string;
    question_groups: string;
    registration_startT: string;
    registration_endT       : string;
    reg_limit       : number;
    additional_limit: number;
    recurrence_id   : number;
    email_id: number;
    wp_user : number;
    event_meta: string;
    require_pre_approval    : string;
    timezone: string;
    submitted       : string;
    ticket_id       : number;
 
}




export interface Event9e {
    activity_id: string;
    contact_id: string;
    event_id: string;
    event_name: string;
    start_date: string;
    activity_type: string;
    activity_date: string;
    first_name: string;
    last_name: string;
    natural_energy: NaturalNumber;
    gender: string;
    email: string;
    phone: string;
    city : string;
    state: string;
    zipcode: string;
    country: string;
    identification_source: string;
    contact_permission: string;
    photo_permission: string;
    notes : string;
    email_method_id: string;
    phone_method_id : string;
    address_id: string;
    date_identified?: string;
    lastTransferDate?: Date;
    timezone: string;

}

export interface Event9Decoded {
    person: Person;
    event: Event;
    activity: Activity[];
}

let events = new Map();
let persons = new Map();

export function makeCategoryList( id: string, cats: EspressoEventCategory[]) : string[] {
    const e: string[] = [];
    if (cats && cats.length > 0) {
        for (let j=0; j< cats.length; j++ ) {

                if (cats[j].event_id == +id) {
                    e.push(cats[j].category_name)
                }

          }
    }

    return e;
}

export function decodeEvent9e(c :Event9e, user_id: BO9UUID) : Event9Decoded {
    let existingEvent: boolean = false;
    let existingPerson: boolean = false;
    const d: Event9Decoded = {
        event: mkMTEvent(),
        activity: [],
        person: mkEmptyPerson('Decoded 9E', c.email)
    }
    const p: PhoneType = {
        updatedAt: '',
        items: [],
        DNC: false
    }
    const e: EmailType = {
        updatedAt: '',
        items: [],
        DNC: false
    }
    const at: BO9AddressType = {
        updatedAt: '',
        items: [],
    } 
    const a: BO9Address = mkMTAddress();

    console.log('Event9e from '+JSON.stringify(c));
    d.person.contact = mkMTContact();
   
    if (c.event_id != null) {
       d.event._key = String(c.event_id);
       if (events.get(c.event_id)) {
           existingEvent = true;
       } else {
           events.set(d.event._key, c);
       }
    } else {
       d.event._key = createUUID();
    }
    d.event.name = c.event_name;

    d.event.startDate = c.start_date;
    d.event.meta = updateMeta(user_id, d.event.meta);

    if (c.contact_id != null) {
        d.person._key = String(c.contact_id)
        if (persons.get(c.contact_id)) {
            existingPerson = true;
        } else {
            d.person.contact._key = String(c.contact_id)
            d.person.contact.names.name.first = c.first_name;
            d.person.contact.names.name.last = c.last_name;
            d.person.details = {
                gender : getGender(c.gender)
            }
            
            persons.set( d.person._key, c);
        }
     } else {
        d.person._key = createUUID();
        d.person.contact._key = d.person._key
     }

    d.activity.push ({
        _key: createUUID(),
        from: d.person._key,
        to: d.event._key,
        kind: c.activity_type,
        date: c.activity_date,
        NN: c.natural_energy,
        by: d.person._key,
        meta: updateMeta(user_id)
    })
   
    // Update NN to make sure it gets set
    if ( c.natural_energy) {
        const nn: NaturalNumberType = {
            setAt: d.event._key + ':' + d.event.name + '?',
            setBy: {
                comment: '',
                name:  c.identification_source,
                uuid: ''
            },
            naturalNumber: c.natural_energy,
            dateSet: c.date_identified ? c.date_identified: ''
        }
        nn.meta = updateMeta(user_id)
        // ONly update NN where set if set at this event
        if ( (c.activity_type != null && c.activity_type.indexOf('dent') > 0) ||
            (c.event_name != null && c.event_name.indexOf('dent') > 0)  ) {// > not >= as 'I' of Ident had to be first at least
            nn.setAt = d.event._key + ':' + d.event.name;
            if (c.date_identified != null && c.date_identified != '') { // If empty just use now
                if (c.date_identified.indexOf('1901') >= 0) {  // If set to empty, use date of event
                    nn.meta.dateCreated = c.start_date
                    nn.meta.lastModified = c.start_date
                    nn.dateSet = c.start_date
                } else { // If set to  a useful date, use it
                    nn.meta.dateCreated = c.date_identified
                    nn.meta.lastModified = c.date_identified
                    nn.dateSet = c.date_identified
                }
            } else {
                nn.dateSet = c.start_date
            }
            d.person.naturalNumbers= setNaturalNumber(d.person.naturalNumbers,nn,nnSource.Identified);
        }
    }
   
    console.log(`NN date id ${c.date_identified}  act type ${c.activity_type}`);

    d.person.notes = c.notes;
    d.person.primaryEmail = c.email;

    if (!existingPhoneNumber(d.person.contact.phone, c.phone)) {
       
        p.items.push({
            number: c.phone, 
            type: 'primary',
            status: '',
            countrycode: ''
        });
        d.person.contact.phone = p;
     }
   

    if (c.contact_permission != null && ( c.contact_permission.indexOf('Y') >= 0 || c.contact_permission.indexOf('y') >= 0)) {
        p.DNC = false;
        e.DNC = false;
    } else {
        p.DNC = true;
        e.DNC = true;
    }

    

    if (!existingEmail(d.person.contact.email.items, c.email)) {
        e.items.push({value: c.email, 
            type: 'primary',
            status: ''});
        e.updatedAt = DateToYYMMDDhhmmss(new Date());
        d.person.contact.email = e;
     }
   
     d.person.media = mkMTMedia();
     if (c.photo_permission != null &&  (c.photo_permission.indexOf('Y') >= 0 || c.photo_permission.indexOf('y') >= 0)) {
        d.person.media.photo.permission = true;
        d.person.media.video.permission = true;
    } else {
        d.person.media.photo.permission = false;
        d.person.media.video.permission = false;

    }
    d.person.media.photo.updatedAt = DateToYYMMDDhhmmss(new Date());
    d.person.media.video.updatedAt = DateToYYMMDDhhmmss(new Date());


    at.updatedAt =  DateToYYMMDDhhmmss(new Date());
    if (c.address_id != null) {
        a._key = String(c.address_id)
    } else {
        a._key = createUUID();
    }
    a.city = c.city;
    a.state = c.state;
    a.zip = c.zipcode;
    a.country = c.country;
    if (!existingAddress(d.person.contact.address, a)) {
        at.items.push(a);
       
    }
    d.person.contact.address = at;

     d.person.contact.meta = updateMeta(user_id, d.person.contact.meta);

     d.person.meta = updateMeta(user_id, d.person.meta);

    return (d);

}