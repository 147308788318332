<my-slide-panel [activePane]="currentPearlPane" style='overflow:hidden;'>
	<section centerPane>
		<h2>My Pearls</h2>

		<div *ngIf="selectedPearl$">
			<button mat-stroked-button (click)="onCloseClick()">Close Pearl Edit</button>
			<pearl-form [pearl]="selectedPearl$ | async" (notifyParent)="pearlSubmittedHandler($event)"></pearl-form>
		</div>
		<table mat-table [dataSource]="dataSource" matSort>
			<ng-container matColumnDef="edit">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Edit</th>
				<td mat-cell *matCellDef="let element" >
					<button mat-stroked-button (click)="onEditClick(element)"><mat-icon>edit</mat-icon></button>
				</td>
			</ng-container>
			<ng-container matColumnDef="title">
				<th mat-header-cell *matHeaderCellDef mat-sort-header style='width:60%'> Title & Content </th>
				<td mat-cell *matCellDef="let element" >
					<strong style='color:#666'>- {{element.title}} -</strong><br>
					<span> {{element.content}} </span></td>
			</ng-container>
			<ng-container matColumnDef="myNN">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="th-center-text"> My<br>Nat. Num </th>
				<td mat-cell *matCellDef="let element" class="td-center-text">{{ element.myNN }}</td>
			</ng-container>
			<ng-container matColumnDef="theirNN">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="th-center-text"> Their<br>Nat. Num </th>
				<td mat-cell *matCellDef="let element" class="td-center-text">{{ element.theirNN }}</td>
			</ng-container>
			<ng-container matColumnDef="relationship">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="th-center-text"> Relationship </th>
				<td mat-cell *matCellDef="let element" class="td-center-text">{{ element.relationship}}</td>
			</ng-container>
			<ng-container matColumnDef="pearlmodule">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="th-center-text"> Module </th>
				<td mat-cell *matCellDef="let element" class="td-center-text">{{ element.pearlModule }}</td>
			</ng-container>
			<ng-container matColumnDef="timeStamp">
			  <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-center-text"> Last Seen </th>
			  <td mat-cell *matCellDef="let element" class="td-center-text"> {{element.lastAdded}} </td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

			</table>
			<mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]">
			</mat-paginator>
	</section>
</my-slide-panel>
