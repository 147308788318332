import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnChanges, OnDestroy, AfterViewInit, ComponentFactoryResolver } from '@angular/core';

import { Activity, FormActivity } from '../../../../../bo9-shared/models/activity/activity.model';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import { Enrolled, Student } from '../../../../../bo9-shared/models/institute/student.model';
import { Course, CourseTrack } from '../../../../../bo9-shared/models/institute/course.model';
import { dateFormating, DateToFullDate, DateToYYMMDDhhmmss } from '../../../../../bo9-shared/helpers/time.helper';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom } from 'rxjs';
import { AlertPromise } from 'selenium-webdriver';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { createUUID } from '../../../../../bo9-shared/uuid.service';
import { AuthService } from 'src/services/auth/auth.service';
import { contactFullName } from '../../../../../bo9-shared/models/contact/contact.model';
import { AttendanceCertificate, CertificateAuthority, CertificateType, PersonCertificate, ProgramCertificate, ProgramData } from '../../../../../bo9-shared/models/certification/certification.model';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';


@Component({
  selector: 'course-list',
  templateUrl: './courseList.component.html',
  styleUrls: ['./courseList.component.scss'],
  // make fade in animation available to this component

})
export class CourseListComponent implements OnInit{
  @Input() student: Person;
  @Input() tracks: CourseTrack[];
  @Input() courses: Course[];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public courseColumns = ['program','name','registered','hours','purchased','certificate' ]; // No delete available?
  public dataSource = new MatTableDataSource<Enrolled>();
  public headers = ['Competence','Apply/Date Applied','Complete/Date Completed'];
  enrolled: Enrolled[];
  user:Person;
  constructor(  private arangoService: ArangoService,    private auth: AuthService) {
    if (!this.tracks) {
    this.tracks=[{
      name: '',
      term_id: 0,
      slug: '',
      term_group: 0
    }]
    }
    this.user = this.auth.getAuthUser();
  }

 async  clickRegister(el: Enrolled,course: Course) {
    console.log("Register "+el.date_purchased);
    const theCourse = el.track.courses.find(e => e.id === course.id);
    theCourse.date_registered = DateToYYMMDDhhmmss();
    const result = await firstValueFrom(this.arangoService.updatePerson(this.student));
    console.log('Updated  '+JSON.stringify(result));
  }

 async  clickComplete(el: Enrolled,course: Course) {
   if (course.date_registered) {
    course.date_completed= DateToYYMMDDhhmmss();
    el.date_all_completed = course.date_completed;
    console.log("complete");
    const result = await firstValueFrom(this.arangoService.updatePerson(this.student));
    console.log('Updated  '+JSON.stringify(result));
   } else {
     alert("Not registered for course");
   }

  }

  async oncreateCert(el: Enrolled) {
    if (this.allCompleted(el)) {
      const program = this.student.student.courses.find(e => e.program_id == el.program_id);

      if (!program) {
        alert("Can't find program with id "+el.program_id);
      } else {
        if (!program.date_all_completed) {
          program.date_all_completed= DateToYYMMDDhhmmss();
        }
        const prCert: ProgramData = {
          enrolled: el
        }
        const pc: ProgramCertificate = {
          date_all_completed : program.date_all_completed,
          data: prCert,
          dateGenerated: DateToYYMMDDhhmmss()
        }
        const cert: PersonCertificate = {
          name: contactFullName(this.student.contact),
          email: this.student.primaryEmail,
          from: this.student._key,
          to: this.student._key,
          by: this.user._key,
          authority: CertificateAuthority.BO9,
          fromData: pc,
          kind: CertificateType.Program,
          meta: updateMeta()
        }
        const result = await firstValueFrom(this.arangoService.makeCertificate( cert));
  
        if (result && result.content) {
          program.certificate_url= 'https://bo9images.bodyof9.com/'+result.content.fileOut.Key;
          console.log("complete "+ el.certificate_url);
          const result1 = await firstValueFrom(this.arangoService.updatePerson(this.student));
        }
        console.log('Updated  '+JSON.stringify(result));
      }
    } else {
      alert("Not all Courses completed!");
    }
      
  }

  allCompleted(el: Enrolled): boolean {
    if (el && el.track) {
      if (el.track.courses && el.track.courses.length>0) {
        for(const course of el.track.courses) {
          if (!course.date_completed || course.date_completed == '' || course.date_registered == '') {
            return false
          }
        }
        return true;
      }           
    }
    return false;
  }

  load() {
    if (this.tracks && this.tracks.length > 0){
      this.courseColumns = ['program','name','registered','hours','purchased','certificate' ]
    }  else {

      this.courseColumns = ['name','purchased','hours' ]; 
    }
    if (this.tracks && this.student.student.courses && this.student.student.courses.length > 0) {

      for( const program of this.student.student.courses) {
        if (program.track && program.track.courses && program.track.courses.length>0 ) {
          for (const crs of program.track.courses) {
            if (!crs.date_completed) {
              crs.date_completed = '';
            }
            if (!crs.date_registered) {
              crs.date_registered = '';
            }
          }

        }
      }
    } 
   
     this.enrolled = this.student.student.courses;
  //   console.log("Courses "+JSON.stringify(this.courses));
  }

 ngOnInit(): void {
  this.load();
 }

 dateFormating(date: any, kind: string) {
  return dateFormating(date, kind);
}
 async onSelected($event:any, el: Enrolled) {

   if (this.student  && $event.value != '') {
    const trackSelected = this.tracks.find( e => e.name == $event.value);
    console.log('on track Selected ');
  
    const course = this.student.student.courses.find( e=> e.program_id == el.program_id);
    console.log('on course Selected ');
    course.track =trackSelected;
    course.date_registered = DateToYYMMDDhhmmss();
   
    const result = await firstValueFrom(this.arangoService.updatePerson(this.student));
    console.log('Updated  '+JSON.stringify(result));
    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.load();
    }
     }

   
 }

}
