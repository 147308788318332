<div [formGroup]="form">
  <div>
    <h3>Name</h3>

    <mat-form-field  appearance="outline">
      <mat-label>Title</mat-label>
      <input matInput 
        formControlName="title"
        id="title"
      />
    </mat-form-field>

    
    <mat-form-field  appearance="outline" >
      <mat-label>First Name</mat-label>
      <input matInput 
        formControlName="first"
        id="first"
        required
      />
    </mat-form-field>

    <mat-form-field  appearance="outline" >
      <mat-label>Middle name</mat-label>
      <input matInput 
        formControlName="middle"
        id="middle"
      />
    </mat-form-field>

    <mat-form-field  appearance="outline" >
      <mat-label>Last name</mat-label>
      <input matInput 
        formControlName="last"
        id="last"
        required
      />

    </mat-form-field>
  </div>

</div>