declare var require:any; // hack TODO
var Buffer = require('buffer/').Buffer  // note: the trailing slash is important!

import { v4 as uuidv4 } from 'uuid';

export function createUUID(): string {
    return(uuidv4());
}

export function UUIDToID(u: string): string {
    return( u.slice(0,6));
}

export function trimUUID(u: string): string {
    if (u.length < 24) return u;

    return u.substring(24);
}

export function responseEncode(str: string, optionally?: string): string {
    var h: number = 0;
    let source = UUIDToID(str);
    const lastCh = str[str.length-1];
    if (optionally) {
        source = source + optionally+lastCh;
    } else {
        source = source + lastCh;
    }
    for (var i = 0; i < source.length; i++) {
        h = 31 * h + source.charCodeAt(i);
    }
    const basic='a';
    console.log('resposeEncode '+(UUIDToID(str)+(basic.charCodeAt(0) + (h & 0xFF)).toString()))
    return (UUIDToID(str)+ (basic.charCodeAt(0) + (h & 0xFF)).toString());
}

export function base64Encode(str1: string, str2: string): string {
    return(Buffer.from(str1+str2, 'binary').toString('base64'));
}

export function base64Decode(str1: string): string {
    return(Buffer.from(str1, 'base64').toString('ascii'));
}