import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ChatEvent} from '../../../../bo9-shared/models/chat/event.model';
import { ChatSeenEvent } from '../../../../bo9-shared/models/chat/seenevent.model';
import { networkConfig } from 'src/config/webapp.config';
import { BO9EVENTS } from "../../../../bo9-shared/models/BO9_base/BO9_base.model";
import { BO9UUID } from '../../../../bo9-shared/models/UUID.model';


@Injectable()
export class ChatMessageService {

    messages: ChatEvent[];
    seenMessages: ChatSeenEvent[];

    constructor (
        private http: HttpClient
    ) {
      this.messages = [];
    }

    private loadChatMessageList(): Observable<any> {
      console.log('Get Message  ' + JSON.stringify(networkConfig));
        return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.MESSAGE);
    }

    public setChatMessages(m: any[]) {
      for (const message of m) {
        this.messages.push(message.msg);
      }
      
    }

    // 11/28/19 MRF - always force re-read of messages - no cache
    public getChatMessages(): Observable<ChatEvent[]> {
      return new Observable( observer =>{
            const sub = this.loadChatMessageList()
            .subscribe({next:  (msg: any) => {
              console.log('message from db');
            this.setChatMessages(msg.content);
            
            observer.next(msg.content);
            observer.complete();
          }, error: (e) => {console.log('error from db'); observer.error(e)}})
    } )
    }

    public sendChatMessage(m: string, chatRoomId: BO9UUID) {
      const param: any = {
        m: m,
        chatRoomId: chatRoomId,
        created: new Date()
      };
      console.log('Send chat message ' + JSON.stringify(m));

      return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.MESSAGE, param);

    }


}

