import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { networkConfig } from 'src/config/webapp.config';
import { Relation, FormRelation } from '../../../../bo9-shared/models/person/relation.model';
import { BO9EVENTS } from '../../../../bo9-shared/models/BO9_base/BO9_base.model';

import { Product } from '../../../../bo9-shared/models/product/product.model';


@Injectable()
export class ProductsService {
// TODO shrink number returned


    products: Product[];

    constructor (
        private http: HttpClient,
    ) {
        this.flush();
    }

    private flush() {
        this.products = [];
    }

    public loadProducts():  Observable<any>  {
            console.log('get products ');
            return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.PRODUCTS);
      }

      public setCoupon(email: string, code?: string, amount?: string):  Observable<any>  {
        const param = {
            email: email,
            couponCode: code,
            amount: amount
          };
            return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WOOCOUPON, param);
    }

    public getUserCoupon(email: string):  Observable<any>  {

            return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WOOCOUPONUSER+'?email='+email);
    }

    public searchCoupon(search: string):  Observable<any>  {

        return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WOOCOUPON+'?search='+search);
}
  

}