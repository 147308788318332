<div class="invite-modal">

      <div flex="45">
        Do you really want to delete the Activity?
      </div>

  <footer>
    <button mat-stroked-button (click)="close()">
      NO!
    </button>
    <button class="btn-save" mat-flat-button
      (click)="deleteSelectActivity(data.activity)">
      Delete Activity
    </button>
  </footer>
</div>
