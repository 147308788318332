import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { externalConfig } from 'src/config/webapp.config';
import { QPARAMS } from '../../../../bo9-shared/models/htmltemplate/htmltemplate.model';
import { BO9EVENTS } from "../../../../bo9-shared/models/BO9_base/BO9_base.model";

@Injectable()
export class TemplateService {


    constructor (
        private http: HttpClient,
    ) {

    }

    // TODO implement by distance, order by distance...

    public loadTemplate(slug: string, params: QPARAMS[]): Observable<any> {

        let qstr = '?';

        for (const param of params) {
            if (qstr != '?') {
                qstr = qstr + '&';
            }
            qstr = qstr + param.htmlized+'='+param.value;
        }
        console.log (`loadtemplate ${slug}${qstr}`);
        const port = externalConfig.controlPort===0? "":':' + externalConfig.controlPort;
        return this.http.get(externalConfig.hostName + port + '/' + BO9EVENTS.APIV10 + '/' +slug+ qstr,
        {responseType: 'text'});
    }

   
}