<div #addidentified>

<form [formGroup]="form" (ngSubmit)="submitForm()" class="relate-form">
  <div>
    <h3>Add Identified or Asserted*</h3>
    
    <mat-form-field  appearance="outline" >
      <mat-label>First Name</mat-label>
      <input matInput 
        formControlName="first"
        id="first"
        required
      />

    </mat-form-field>


    <mat-form-field  appearance="outline" >
      <mat-label>Last name</mat-label>
      <input matInput 
        formControlName="last"
        id="last"
        required
      />

    </mat-form-field>

    <br />
     

    <mat-form-field  appearance="outline" >
      <mat-label>Email</mat-label>
      <input matInput 
        formControlName="email"
        id="email"
      />

    </mat-form-field>
    <mat-label>Do Not Contact</mat-label> &nbsp;   <mat-checkbox (change)="selectDNC()" [checked]="form.value.DNC"></mat-checkbox>
    <br />
    <mat-form-field  appearance="outline" >
      <mat-label>City</mat-label>
      <input matInput 
        formControlName="city"
        id="city"
      />

    </mat-form-field>
    <mat-form-field  appearance="outline" >
      <mat-label>State</mat-label>
      <input matInput 
        formControlName="state"
        id="state"
      />

    </mat-form-field>
    <br />
    <mat-form-field  appearance="outline" >
      <mat-label>Zip</mat-label>
      <input matInput 
        formControlName="zip"
        id="zip"
      />

    </mat-form-field>
    <mat-form-field  appearance="outline" >
      <mat-label>Country</mat-label>
      <input matInput 
        formControlName="country"
        id="country"
      />

    </mat-form-field>
    <br />
    <mat-form-field  appearance="outline" >
      <mat-label>Phone</mat-label>
      <input matInput 
        formControlName="phone"
        id="phone"
      />

    </mat-form-field>
    <mat-form-field  appearance="outline" >
      <mat-label>Gender</mat-label>
      <mat-select matNativeControl   formControlName="gender">
        <mat-option *ngFor="let nnI of genderTypes" [value]="nnI">
          {{ nnI }}
        </mat-option>
      </mat-select>


    </mat-form-field>
    <br />
    <mat-form-field  appearance="outline" required>
      <mat-label>Natural Number</mat-label>
      <mat-select matNativeControl  formControlName="naturalNumber" required>
        <mat-option *ngFor="let nns of nnKeys" [value]="nns">
          {{ nns }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field  appearance="outline" required>
      <mat-label>Identified By</mat-label>
      <mat-select matNativeControl  multiple formControlName="idBy" required>
        <mat-option *ngFor="let nns of identifiers" [value]="nns">
          {{ nns }}
        </mat-option>
      </mat-select> 

    </mat-form-field>
    <br />
    <mat-form-field  appearance="outline" required>
      <mat-label>Identified Location</mat-label>
      <input matInput 
        formControlName="idAt"
        id="idAt"
        required 
      />

    </mat-form-field>
    <mat-form-field  appearance="outline" >
      <mat-label>Date Identified</mat-label>
      <input matInput 
        formControlName="dateIdentified"
        id="dateIdentified"
        required
        type="date"
        [matDatepicker]="dateIdentified"
      />
      <mat-datepicker-toggle matIconSuffix [for]="dateIdentified"></mat-datepicker-toggle>
    <mat-datepicker #dateIdentified touchUi></mat-datepicker>
  </mat-form-field>
  <br />
  <mat-form-field appearance="outline" >
    <mat-label>Type of Identification</mat-label>
    <mat-select matNativeControl  formControlName="sourceType" required>
      <mat-option *ngFor="let nns of nnSource" [value]="nns">
        {{ nns }}
      </mat-option>
    </mat-select>
    
  </mat-form-field>
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>Notes</mat-label>
   <textarea matInput  formControlName="notes" ></textarea>

  </mat-form-field>
  </div>
  <button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Submit</button>
  

</form>
<br />
<br />
<hr>
<button mat-button  (click)="resetForm()"  class="btn">Reset</button>
</div>