import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ChatSeenEvent} from '../../../../bo9-shared/models/chat/seenevent.model';
import { networkConfig } from 'src/config/webapp.config';
import { BO9EVENTS } from "../../../../bo9-shared/models/BO9_base/BO9_base.model";
import { ChatEvent } from '../../../../bo9-shared/models/chat/event.model';
import { BO9UUID } from '../../../../bo9-shared/models/UUID.model';


@Injectable()
export class ChatseenMessageservice {
    seenMessages: ChatSeenEvent[];

    constructor (
        private http: HttpClient
    ) {
      this.seenMessages = null;
    }

    private loadChatSeenMessageList(): Observable<any> {
      // TODO - optimize eg only send unseen
      console.log('Get Seen Message  ' + JSON.stringify(networkConfig));
        return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.SEENMESSAGE);
    }

    public setSeen(seen: ChatEvent[], userId: BO9UUID): Observable<any> {
      const seenMessages: ChatSeenEvent[] = [];

      for (const s of seen) {
          const sm: ChatSeenEvent = {
            messageId: s.msg.messageId,
            chatRoomId: s.msg.chatRoomId,
            personWhoReadId: userId,
            timeSeen: new Date()
          };
          let seenAlready = false;
          for (const checkseen of s.msg.seenMessages) {
            if (checkseen.personWhoReadId === userId) {
              seenAlready = true;
            }
          }
          if (!seenAlready) {
            seenMessages.push(sm);
          }

      }
      const params: any = {
        seen: seenMessages
      };
      console.log('Update Seen Message  ' + JSON.stringify(seen));
      if (seenMessages.length > 0) {
        return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.SEENMESSAGE, params);
      } else {
        return of({});
      }
    }

    public setChatseenMessages(m: ChatSeenEvent[]) {
      this.seenMessages = m;
    }

    public getChatseenMessages(): Observable<ChatSeenEvent[]> {
      return new Observable( observer =>{
        if (this.seenMessages) {
            observer.next(this.seenMessages);
            observer.complete();
        }  else {
          const sub = this.loadChatSeenMessageList()
            .subscribe( {next: (msg: any) => {
              console.log('chatseen from db');
            this.setChatseenMessages(msg.content);
            
            observer.next(msg.content);
            observer.complete();
          }, error:(e) => {console.log('error from db'); observer.error(e)}})
        }
    } )
    }

}

