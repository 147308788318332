
			  <table mat-table [dataSource]="dataSource" matSort>
				<!-- Header row first group -->

				  <ng-container matColumnDef="name">
					 <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
					 <td mat-cell *matCellDef="let element"> {{element.firstName}} {{element.lastName}}   {{element.naturalNumbers.identified?.naturalNumber}}<br />{{element.email}}</td>
				  </ng-container>

	
				  <ng-container matColumnDef="whenidentified">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> When Id. </th>
					<td mat-cell class="th-center" *matCellDef="let element"> {{ dateFormating(element.naturalNumbers.identified?.dateSet, "identified") }} </td>
				 </ng-container>
	
				  <ng-container matColumnDef="capability">
					<th mat-header-cell *matHeaderCellDef  mat-sort-header> Apntc Jrymn SnrJmn Mastr </th>
					<td mat-cell *matCellDef="let element">
	
							<mat-checkbox   [checked]="isApprentice(element.realPerson)"></mat-checkbox>
							<mat-checkbox   [checked]="isJourneyman(element.realPerson)"></mat-checkbox>
							<mat-checkbox   [checked]="isSenior(element.realPerson)"></mat-checkbox> 
							<mat-checkbox   [checked]="isMaster(element.realPerson)"></mat-checkbox>

				</td>
				
					</ng-container>
				 <ng-container matColumnDef="identifier">
					<th mat-header-cell *matHeaderCellDef  mat-sort-header> Can Identify</th>
					<td mat-cell *matCellDef="let element">

						<mat-checkbox [checked]="canIdentify(element.realPerson)"></mat-checkbox>

				</td>
				</ng-container>

				  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

			  </table>
			  <mat-paginator  [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]" (page)="onPageChange($event)"> </mat-paginator>


