<button mat-stroked-button (click)="close()"> Close</button>
<div class="video-player-wrapper">
    <vg-player (onPlayerReady)="videoPlayerInit($event)">
      <vg-overlay-play></vg-overlay-play>
      <vg-buffering></vg-buffering>
      <vg-scrub-bar>
        <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
        <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
      </vg-scrub-bar>
      <vg-controls>
        <vg-play-pause></vg-play-pause>
        <vg-playback-button></vg-playback-button>
        <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
        <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>
        <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
        <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
        <vg-track-selector></vg-track-selector>
        <vg-mute></vg-mute>
        <vg-volume></vg-volume>
        <vg-fullscreen></vg-fullscreen>
      </vg-controls>
      <video #media [vgMedia]="$any(media)" [src]="data.video" id="singleVideo" preload="auto" crossorigin>
      </video>
    </vg-player>
    
  </div>
  <button mat-stroked-button (click)="close()"> Close</button>