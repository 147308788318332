
<table mat-table [dataSource]="enrolled" matSort multiTemplateDataRows >
	<ng-container matColumnDef="program">
		<mat-header-cell *matHeaderCellDef  mat-sort-header> Program </mat-header-cell>
		<mat-cell *matCellDef="let el; let i = dataIndex">
			<mat-select matNativeControl (selectionChange)="onSelected($event, el)" [(value)]="el.name">
				<mat-option value=""></mat-option>
                <mat-option *ngFor="let nns of tracks" [value]="nns.name">
                  {{ nns.name }}
                </mat-option>
              </mat-select> 

		</mat-cell>
		</ng-container>
	<ng-container matColumnDef="name">
		<mat-header-cell *matHeaderCellDef  mat-sort-header> Name </mat-header-cell>
		<mat-cell *matCellDef="let el"> {{el.name}}
		</mat-cell>
		</ng-container>
		<ng-container matColumnDef="registered">
			<mat-header-cell *matHeaderCellDef  mat-sort-header> Registered </mat-header-cell>
			<mat-cell *matCellDef="let el"> {{    dateFormating(el.date_registered, "ts")}}
			</mat-cell>
			</ng-container>
		<ng-container matColumnDef="purchased">
		<mat-header-cell *matHeaderCellDef  mat-sort-header> Purchased </mat-header-cell>
		<mat-cell *matCellDef="let el"> {{    dateFormating(el.date_purchased, "ts")}}
		</mat-cell>
		</ng-container>
		<ng-container matColumnDef="hours">
		<mat-header-cell *matHeaderCellDef  mat-sort-header> Hours </mat-header-cell>
		<mat-cell *matCellDef="let el"> {{el.hours_taken}}
		</mat-cell>
		</ng-container>
		<ng-container matColumnDef="certificate">
			<mat-header-cell *matHeaderCellDef  mat-sort-header> Create/Link to Certificate </mat-header-cell>
			<mat-cell *matCellDef="let el"> 
			<span *ngIf="allCompleted(el); else noteligible">
				<span *ngIf="el.certificate_url==''; else cert"> <button mat-stroked-button (click)="oncreateCert(el)"  [disabled]="disableBtn" >Create<mat-icon>approval</mat-icon></button> </span>
				<ng-template #cert><a href="{{ el.certificate_url }}" target="_blank">View</a></ng-template> 
			</span>
			<ng-template #noteligible>Not all courses completed</ng-template> 
			</mat-cell>
			</ng-container>
		<ng-container matColumnDef="expandedDetail">
			<mat-cell *matCellDef="let el" [attr.colspan]="courseColumns.length">
				<span *ngIf="el.track && el.track.courses"> 
					<table>
						<th *ngFor = "let column of headers">
							{{column}}
						</th>
						<tr *ngFor = "let row of el.track.courses; let j = index;">
							<td >{{ row.title.rendered }}</td>
							<td ><span *ngIf="row.date_registered==''; else registered"><mat-checkbox (change)="clickRegister( el,row)" [checked]="false"></mat-checkbox></span>
								<ng-template #registered>{{ dateFormating(row.date_registered, "ts" )}}</ng-template> </td>
							<td > <span *ngIf="row.date_completed==''; else completed"><mat-checkbox (change)="clickComplete(el,row)" [checked]="false"></mat-checkbox></span>
								<ng-template #completed>{{  dateFormating(row.date_completed, "ts")}}</ng-template> </td>

						  </tr>
					</table>
				</span>
			</mat-cell>
		</ng-container>
		<mat-header-row *matHeaderRowDef="courseColumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: courseColumns;"></mat-row>
		<mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>>
</table>