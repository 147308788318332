  <my-slide-panel [activePane]="currentPane">

	<section leftPane>
		<button mat-stroked-button (click)="onCloseClick()">Back To People</button><br>
		<span *ngIf="operation == 'Invite'" > <invite-list [from]="fromI$ | async" [canAll]=false ></invite-list></span>
		<span *ngIf="operation == 'Family'" > <relation-list (notifyParent)="relateSubmittedHandler($event)"   [name]="fromName" [from]="fromF$ | async" [canAll]=false ></relation-list></span>
		<span *ngIf="operation == 'Activity'" > <activity-list [from]="fromA$ | async" [canAll]=false></activity-list></span>
		<span *ngIf="operation == 'Subscription'" > <subscription-list [from]="fromF$ | async" ></subscription-list></span>
		<span *ngIf="operation == 'Guild'" > <guild [person]="fromF$ | async" ></guild></span>
		<span *ngIf="operation == 'Visit'" > <visitlist [person]="fromF$ | async" ></visitlist></span>
		<span *ngIf="operation == 'Groups'" > <group-list [from]="fromG$ | async" (notifyParent)="groupHandler($event)" [canAll]=false ></group-list></span>
		<span *ngIf="operation == 'Pearl'" ><seen-pearl-list [email]="email"></seen-pearl-list></span>
		<span *ngIf="operation == 'Purchases'" ><purchase-list [person]="fromF$ | async"></purchase-list></span>
		<span *ngIf="operation == 'SysRoles' && isAdmin()" ><sysroles-form [person]="origP" (notifyParent)="roleHandler($event)"></sysroles-form></span>
		<span *ngIf="operation == 'Merge'" ><merge-person-form [source]="PersonMerge"
		  [origPerson]="origP" [newPerson]="newP" [differences]=diffs
		  [extSource]="'Person'" (notifyParent)="mergeSubmittedHandler($event)"></merge-person-form></span>
	</section>

	<section centerPane>

		<div #table class="action">
			<span>
				<button mat-stroked-button (click)="addPersonClick()" matTooltip="Add a Person">Add<mat-icon>add_box</mat-icon></button>
			</span>

			<span>
				<label class="search">NN (select 99 for all):</label> <br>
				<select matNativeControl  [(ngModel)]="nnSearch" name="searchString" multiple>
					<option *ngFor="let nnI of nnKeys" [value]="nnI">
					  {{ nnI }}
					</option>
				  </select><br />
				  </span>
				  <span>	
					  Appr Jrymn SnrJmn Mastr<br>
					<mat-checkbox  (change)="clickSearch('Apprentice')" [checked]="isSearch('Apprentice')"></mat-checkbox>
					<mat-checkbox  (change)="clickSearch('Journeyman')" [checked]="isSearch('Journeyman')"></mat-checkbox>
					<mat-checkbox  (change)="clickSearch('SeniorJ')" [checked]="isSearch('SeniorJ')"></mat-checkbox> 
					<mat-checkbox  (change)="clickSearch('Master')" [checked]="isSearch('Master')"></mat-checkbox></span>
				 
				  <span>
				<label class="search">Search (max return 100 rows): <br>
					
					<input type="text" [(ngModel)]="searchString" name="searchString" (keyup.enter)="onClickSearch($event)"/>
				</label>
				<button mat-flat-button color="primary" name="search" (click)="onClickSearch(null)">Search</button>
			</span>

 			<span>
 				<mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="30" class='spin'> </mat-progress-spinner>
 			</span>

			 <span>

					<label class="search">Select Search to Repeat:</label> <br>
					<select matNativeControl  [(ngModel)]="history" name="history" multiple>
						<option *ngFor="let nnI of searches" [value]="nnI">
						 search: {{ nnI.search }}
						</option>
					  </select><br /><button mat-stroked-button (click)="onRepeatClick()">Repeat<mat-icon>refresh</mat-icon></button>
			</span>
			<span>
				<button mat-stroked-button (click)="onRefreshClick()">Refresh<mat-icon>refresh</mat-icon></button>
			</span>
		 </div>
		<mat-card class="bo9mat" *ngIf='makeEmail'>
		  <button mat-stroked-button (click)="onCloseEmail()">Close</button>
		  <sendEmail-form [person]="emailer"></sendEmail-form>
		</mat-card>

		<mat-card class="bo9mat" class="flex" style="box-shadow: 0 0 0 0 rgba(0, 0, 0, 0)">
		  <div class="scrolls">
			  <table mat-table [dataSource]="dataSource" matSort>
				<!-- Header row first group -->
				<ng-container matColumnDef="header-row-first-group">
					<th mat-header-cell *matHeaderCellDef 
						[style.text-align]="center"
						[attr.colspan]="5"> 
					Person
					</th>
				</ng-container>
				
				<!-- Header row second group -->
				<ng-container matColumnDef="header-row-second-group">
					<th mat-header-cell *matHeaderCellDef [attr.colspan]="4"> Natural Number </th>
				</ng-container>

				<!-- Header row second group -->
				<ng-container matColumnDef="header-row-third-group">
					<th mat-header-cell *matHeaderCellDef [attr.colspan]="2"> Capability </th>
				</ng-container>
				<ng-container matColumnDef="header-row-forth-group">
					<th mat-header-cell *matHeaderCellDef [attr.colspan]="2">  </th>
				</ng-container>


				  <ng-container matColumnDef="selection">
					 <th mat-header-cell *matHeaderCellDef> Select Operation </th>
					 <td mat-cell *matCellDef="let element">
						<mat-select matNativeControl (selectionChange)="onSelected($event, element)" [(ngModel)]="selectedOption" placeholder="Select Operation" >
						  <mat-option *ngFor="let nns of selections" [value]="nns">
							 {{ nns }}
						  </mat-option>
						</mat-select>
					 </td>
				  </ng-container>

				  <ng-container matColumnDef="mailcampaigns">
					 <th mat-header-cell *matHeaderCellDef> In ActCamp? & DNC? </th>
					 <td mat-cell class="th-center" *matCellDef="let element">
						<span *ngIf="element.email && !element.DNC && element.activeCampaignContact" > <mat-icon>email</mat-icon></span>
						<span *ngIf="element.DNC" > <mat-icon>do_not_touch</mat-icon></span>
						<span *ngIf="element.email && !element.DNC && !element.activeCampaignContact" >
							<button type="button" (click)="onClickAddToActiveCampign(element.realPerson)" matTooltip="Add to Active Campaign">
								<mat-icon>warning</mat-icon>
							</button>
						</span>
					 </td>
				  </ng-container>
				  <ng-container matColumnDef="_key">
					 <th mat-header-cell  *matHeaderCellDef> Key </th>
					 <td mat-cell *matCellDef="let element"> <mat-icon (click)="showKey(element._key)" matTooltip="{{element._key}}">key</mat-icon></td>
				  </ng-container>
				  <ng-container matColumnDef="email">
					 <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
					 <td mat-cell *matCellDef="let element">
						<span *ngIf="element.email " > <button mat-button [cdkCopyToClipboard]="element.email"><mat-icon >content_copy</mat-icon></button> {{element.email}} </span>
					 </td>
				  </ng-container>
				  <ng-container matColumnDef="name">
					 <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
					 <td mat-cell *matCellDef="let element"> {{element.firstName}} {{element.lastName}} </td>
				  </ng-container>
				  <ng-container matColumnDef="asserted">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Asserted </th>
					<td mat-cell class="th-center" *matCellDef="let element"> {{element.naturalNumbers.asserted?.naturalNumber}}</td>
				 </ng-container>
				 <ng-container matColumnDef="whenasserted">
				   <th mat-header-cell *matHeaderCellDef mat-sort-header> When Ass.</th>
				   <td mat-cell class="th-center" *matCellDef="let element"> {{ dateFormating(element.naturalNumbers.asserted?.dateSet, "identified") }} </td>
				</ng-container>
				  <ng-container matColumnDef="identified">
					 <th mat-header-cell *matHeaderCellDef mat-sort-header> ID NN (Act Camp) </th>
					 <td mat-cell class="th-center" *matCellDef="let element"> {{element.naturalNumbers.identified.naturalNumber}} <span *ngIf="element.realnn !== element.actCampNN" > ({{element.actCampNN}})</span></td>
				  </ng-container>
				  <ng-container matColumnDef="whenidentified">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> When Id. </th>
					<td mat-cell class="th-center" *matCellDef="let element"> {{ dateFormating(element.naturalNumbers.identified?.dateSet, "identified") }} </td>
				 </ng-container>
				  <ng-container matColumnDef="source">
					 <th mat-header-cell *matHeaderCellDef mat-sort-header> Source </th>
					 <td mat-cell *matCellDef="let element"> {{ element.source }} </td>
				  </ng-container>
				  <ng-container matColumnDef="modified">
					 <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Modified </th>
					 <td mat-cell *matCellDef="let element"> <small>{{ dateFormating(element.modified, "ts") }}</small> </td>
				  </ng-container>
				  <ng-container matColumnDef="capability">
					<th mat-header-cell *matHeaderCellDef  mat-sort-header> Apntc Jrymn SnrJmn Mastr None</th>
					<td mat-cell *matCellDef="let element">
						<span *ngIf="isAdmin()">
							<mat-checkbox  [disabled]="disableBtn" (change)="clickRole($event,element.realPerson,'Apprentice')" [checked]="isApprentice(element.realPerson)"></mat-checkbox>
							<mat-checkbox  [disabled]="disableBtn" (change)="clickRole($event,element.realPerson,'Journeyman')" [checked]="isJourneyman(element.realPerson)"></mat-checkbox>
							<mat-checkbox  [disabled]="disableBtn" (change)="clickRole($event,element.realPerson,'SeniorJ')" [checked]="isSenior(element.realPerson)"></mat-checkbox> 
							<mat-checkbox  [disabled]="disableBtn" (change)="clickRole($event,element.realPerson,'Master')" [checked]="isMaster(element.realPerson)"></mat-checkbox>
							<mat-checkbox  [disabled]="disableBtn" (change)="clickRole($event,element.realPerson,'None')" [checked]=false></mat-checkbox>
						</span>
						<span *ngIf="!isAdmin()">
							<mat-checkbox   [checked]="isApprentice(element.realPerson)"></mat-checkbox>
							<mat-checkbox   [checked]="isJourneyman(element.realPerson)"></mat-checkbox>
							<mat-checkbox   [checked]="isSenior(element.realPerson)"></mat-checkbox> 
							<mat-checkbox   [checked]="isMaster(element.realPerson)"></mat-checkbox>
						</span>
				</td>
				
					</ng-container>
				 <ng-container matColumnDef="identifier">
					<th mat-header-cell *matHeaderCellDef  mat-sort-header> Can Identify</th>
					<td mat-cell *matCellDef="let element">
						
					
					<span *ngIf="isAdmin()">
						<mat-checkbox  [disabled]="disableBtn" (change)="clickIdentify(element.realPerson)" [checked]="canIdentify(element.realPerson)"></mat-checkbox>
					</span>
					<span *ngIf="!isAdmin()">
						<mat-checkbox [checked]="canIdentify(element.realPerson)"></mat-checkbox>
					</span>
				</td>
				</ng-container>
				  <ng-container matColumnDef="delete">
					 <th mat-header-cell *matHeaderCellDef> Delete </th>
					 <td mat-cell  *matCellDef="let element">
						<button type="button" (click)="onClickDelete(element)" matTooltip="Delete"> <mat-icon>delete_forever</mat-icon></button>
					 </td>
				  </ng-container>
				  <tr mat-header-row *matHeaderRowDef="['header-row-first-group', 'header-row-second-group','header-row-third-group', 'header-row-forth-group']"></tr>
				  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

			  </table>
			  <mat-paginator  [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]" (page)="onPageChange($event)"> </mat-paginator>
			</div>
		</mat-card>
	 </section>

	 <section rightPane>
		<button mat-stroked-button (click)="onCloseClick()">Back</button><br>
		<span> <person-form [person]="realP" [actCamp]="actCamp" (notifyParent)="personSubmittedHandler($event)"></person-form></span>
	 </section>
  </my-slide-panel>
