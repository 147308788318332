import { Person } from '../../../../bo9-shared/models/person/person.model';
import { networkConfig, externalConfig } from 'src/config/webapp.config';
import { BO9EVENTS } from '../../../../bo9-shared/models/BO9_base/BO9_base.model';
import { firstValueFrom, from, Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventAttendee } from '../../../../bo9-shared/models/event/event.model';
import { EspressoEvent } from '../../../../bo9-shared/models/event/event9e.model';
@Injectable()
export class EventDBService {
  subscriptions: Subscription[] = [];
  constructor (
    private http: HttpClient
  ) {

    // hack not empty
  }


    public getEvents(fromDate: string, toDate:string, search: string, all?: boolean):  Observable<any>  {
      console.log('get events');
      let q = '';
      if (!(all && all === true)) {
        q = '?from='+fromDate+'&to='+toDate+'&search='+search;
      } else if (all && all==true) {
        q = '?from=2020-01-01&to=2025-01-01&search='+search
      }
      return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WPEVENTS+q);
    }


    public getCurrentEvents():  Observable<any>  {
      console.log('get currentevents');

      const port = externalConfig.controlPort===0? "":':' + externalConfig.controlPort;
      return this.http.get(externalConfig.hostName + port + '/' + BO9EVENTS.CURRENTEVENTS);
    }


    public getEventsByEmail(email:string):  Observable<any>  {
      console.log('get events by email');
      let q = '';

      return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WPEVENTSBYEMAIL+'?email='+email);
    }

    public getCalendlyEventsByEmail(e: string):  Observable<any>  {
      console.log('get calevent');
      return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.CALENDLYEVENTSBYEMAIL+'/?email='+e);
    }
    public getCalendlyEventsByOrderID(e: string):  Observable<any>  {
      console.log('get calevent');
      return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.CALENDAR+'/?order_id='+e);
    }

    public getCalendlyEvents(fromDate: string, toDate:string, search: string, all?: boolean): Promise<any> {
      let s = '?';
      if (!(all && all === true)) {
        s = '?from='+fromDate+'&to='+toDate+'&search='+search+'&';
      
      } else if (all && all==true) {
        s = '?from=2020-01-01&to=2025-01-01&search='+search
      } else {
        s = '?';
      }
      return new Promise( async resolve =>{

          const msg: any = await firstValueFrom (this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.CALENDLYEVENTS +s+'offset='+0+'&limit='+100))

          resolve(msg.content);
      } )
  }


    public getCategories():  Observable<any>  {
      console.log('get categories');
      return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WPEVENTCATEGORIES);
    }

    public getEventAttendees(e: string):  Observable<any>  {
      console.log('get getEventAttendees');
      return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WPEVENTATTENDEES + '?event_id='+e );
    }

    public isEmailRegistered(ev: string, email: string):  Observable<any>  {
      console.log('get isEmailRegistered');
      return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WPEVENTATTENDEES + '?event_id='+ev +'&email='+email);
    }

    public getEventByID(e: string):  Observable<any>  {
      console.log('get geteventbyid');
      return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WPEVENTS + '?event_id='+e );
    }
    
    public setEventAttendeeAttended(id: string, pid: string, eid: string, start_date: string, value: number, hours:number):  Observable<any>  {
      console.log('set EventAttendee');
      const param = {
        id: id,
        attended: value,
        pid: pid,
        eid: eid,
        start: start_date,
        hours: hours
      }
      return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WPEVENTATTENDEEATTENDED, param);
    }
    public deleteAttendee(attendee_id: string):  Observable<any>  {
      console.log('del cal att');
      return this.http.delete(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WPEVENTATTENDEES+ '?attendee_id='+attendee_id);
    }
  

    public setEventAttendee(a: EventAttendee):  Observable<any>  {
      const param = {
        attendee: a,
        update: false
      }
      console.log('set EventAttendee');
      return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WPEVENTATTENDEES, param);
    }

    public setSimpleEventAttendee(a: EventAttendee):  Observable<any>  {
      const param = {
        attendee: a,
        update: false
      }
      const port = externalConfig.controlPort===0? "":':' + externalConfig.controlPort;
      console.log('set simple EventAttendee: '+externalConfig.hostName + ':' + port+ '/' + BO9EVENTS.WPEVENTATTENDEES);


      return this.http.post(externalConfig.hostName  + port+ '/' + BO9EVENTS.WPEVENTATTENDEES, param);
    }


    public updateEventAttendee(a: EventAttendee):  Observable<any>  {
      const param = {
        attendee: a,
        update: true
      }
      console.log('update EventAttendee');
      return this.http.put(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WPEVENTATTENDEES, param);
    }
 

  
}
