<div class="invite-modal">

<h1>{{userName}}</h1>
      
Agreement: {{ agreement.title }} <br/>
Version:   {{ version.version }} <br/>
Dated: {{ version.dateCreated }} <br/>
FileName: {{ version.sourceFileName }} <br>


<a href="{{url}}" target="_blank">Download Agreement({{ version.sourceFileName }})</a>

<br>
<div *ngIf="isPdf">
  <!--
<textarea [(ngModel)] = "url"></textarea>-->

<pdf-viewer [src]="url"
            [render-text]="true"
            [original-size]="false"
            style="width: 700px; height: 500px"
></pdf-viewer>
</div>

<br><br>

<button mat-stroked-button (click)="accept()">
  Accept
</button>
<br><br>
<button mat-stroked-button (click)="skip()">
  Decline
 </button>

  <footer>
    <br><!--
    <button mat-stroked-button (click)="close()">
      Cancel
    </button>
   -->
  </footer>
</div>
