import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnChanges, OnDestroy, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person, getPurchaseList, getUserName } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';

import { FormRelation, Relation} from '../../../../../bo9-shared/models/person/relation.model';
import { MatDialog } from '@angular/material/dialog';
import { SearchService } from 'src/services/search/search.service';
import { EventEmitterService } from 'src/services/events/events.service';
import { ProductsService } from 'src/services/products/products.service';
import { ModuleDetail, Product, multiProduct } from '../../../../../bo9-shared/models/product/product.model';
import { addMonths, addYears, dateFormating, DateToYYMMDDhhmmss } from '../../../../../bo9-shared/helpers/time.helper';
import { SelectListDialogComponent } from 'src/displayComponents/dialogs/selectListDialog/selectListDialog.component';
import { addPurchase, createPurchaseFromSubscription } from '../../../../../bo9-shared/models/purchase/purchase.model';
import { userIsAdmin } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { PearlService } from 'src/services/pearl/pearl.service';
import { EventAttendee } from '../../../../../bo9-shared/models/event/event.model';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'dupemail-list',
  templateUrl: './dupEmailList.component.html',
  styleUrls: ['./dupEmailList.component.scss'],
  // make fade in animation available to this component

})
export class DupEmailList implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() attendees: EventAttendee[];
  @Input() emailList: number[];
  @Input() canAll: boolean;
  @Output() notifyParent: EventEmitter<number> = new EventEmitter <number>;

  public colList =   ['select','name','email'];
  valid: false;

  user: Person;
  person: Person;

  public dataSource = new MatTableDataSource<EventAttendee>();

  
  subscriptions: Subscription[] = [];
  constructor(
    private router: Router,
    private arangoService: ArangoService,
    private auth: AuthService,

    )
  {

   this.user = this.auth.getAuthUser();
   this.subscriptions.push(this.arangoService.recordVisit({
     page: this.router.url, component: 'DupEmailList', 
     visitor: this.user._key,
     help: false
   }).subscribe({
     next: () => {
     
     
     }
   })); 
   
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  ngOnChanges() {
    for (let i = 0; i < this.emailList.length; i++) {
      this.dataSource.data.push(this.attendees[this.emailList[i]]);
    }
    console.log("Email list" + this.emailList)
  }



  ngAfterViewInit(): void {
   
  }


  

  isAdmin(): boolean {
    return(userIsAdmin(this.user));
  }

  onClickSelect(index: number) {
    if (confirm("Clean Emails?")) {
      if (this.notifyParent) { this.notifyParent.emit(this.emailList[index]); }
    }

  }
  

  ngOnInit() {


  }


}
