import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';


import { WebsiteService } from 'src/services/website/website.services';


@Component({
  selector: 'app-web-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class WebContactComponent implements OnInit {


  showSpinner = false;
  webpage: any;

  constructor(
    private webService: WebsiteService,
    ) {
    }

  ngOnInit() {
   
  }

  
}
