      <form [formGroup]="PearlForm" (ngSubmit)="submitPearlForm()" class="Pearl-form" novalidate >
        <button mat-button type="submit"  [disabled]="disableBtn" class="btn btn-save mat-submit-button">Submit</button>
        <div >
          <mat-form-field appearance="outline"  class="full-width">
            <mat-label>Key</mat-label>
            <input matInput  formControlName="_key"  />
           
          </mat-form-field>
        </div>
        <div >
          <mat-form-field appearance="outline" class="full-width" >
            <mat-label>Title</mat-label>
            <input matInput  formControlName="title" required />
           
          </mat-form-field>
        </div>
        <div>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Content</mat-label>
              <qeditor
                formControlName="content"
                placeholder="Type your wisdom here..."
                required
              ></qeditor>
              <mat-error *ngIf="PearlForm.get('content').hasError('required')">
                Field is <strong>required</strong>
              </mat-error>
             </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" >
            <mat-label>My Natural Number?</mat-label>
            <mat-select matNativeControl   formControlName="myNN" required>
              <mat-option *ngFor="let nnI of nnKeys" [value]="nnI">
                {{ nnI }}
              </mat-option>
            </mat-select>

            </mat-form-field>
            <mat-form-field appearance="outline" >
              <mat-label>Their Natural Number?</mat-label>
              <mat-select matNativeControl   formControlName="theirNN" required>
                <mat-option *ngFor="let nnI of nnKeys" [value]="nnI">
                  {{ nnI }}
                </mat-option>
              </mat-select>
  
              </mat-form-field>
         
          <mat-form-field appearance="outline">
            <mat-label>Relationship</mat-label>
            <mat-select matNativeControl   formControlName="relationship" required multiple> 
              <mat-option *ngFor="let reltype of invites " [value]="reltype">
                {{ reltype}}
              </mat-option>
            </mat-select>

            </mat-form-field>
            <mat-form-field appearance="outline" >
              <mat-label>Module</mat-label>
              <mat-select matNativeControl  formControlName="pearlmodule" required multiple>
                <mat-option *ngFor="let reltype of PearlModuleTypes" [value]="reltype">
                  {{ reltype }}
                </mat-option>
              </mat-select>
              </mat-form-field>
                
        <mat-form-field  appearance="outline" >
          <mat-label>Start Date</mat-label>
          <input matInput 
            formControlName="startDate"
            id="startDate"
            type="date"
            [matDatepicker]="startDate"
          />
          <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
          
        <mat-form-field  appearance="outline" >
          <mat-label>End Date</mat-label>
          <input matInput 
            formControlName="endDate"
            id="endDate"
            type="date"
            [matDatepicker]="endDate"
          />
          <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>

          <mat-label>Pearl Approved?</mat-label>
          <mat-checkbox (change)="clickApproved()" [checked]="moderated.approved"></mat-checkbox>

        <div *ngIf="pearl?.moderated">
          Approved By: {{ pearl?.moderated.reviewer }} on {{ pearl?.moderated.whenRated }} as {{ pearl?.moderated.reason }} 
        </div>
        <br />

              Image File:
              <mat-form-field>
                <ngx-mat-file-input [formControl]="fileControl" >
                </ngx-mat-file-input>
             </mat-form-field>
             <button mat-stroked-button (click)="delImage()">Delete Img</button>
            </div>       
            <button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Submit</button>    
            <div>
            <img *ngIf="image" src="{{image}}" alt="Pearl Image">    
          </div>
      </form>

   