<button class='back-to-list' mat-stroked-button (click)="onCloseClick()">Back to list<mat-icon>arrow_forward</mat-icon></button>
<h2> {{ from.contact.names.name.first }} - Document Your Relationships....</h2>

<span *ngIf="attendees?.length > 0 && isJourneyman()">
	<button mat-flat-button class="btn" (click)="selectPersonFromAttendees()" >Fill From List</button>
</span>
<span *ngIf="isAdmin()">
	<button mat-flat-button class="btn" (click)="selectPerson()" >Fill From Select</button> <hr>
</span>
<form [formGroup]="RelateForm" (ngSubmit)="submitRelateForm()" class="relate-form">


		<mat-form-field appearance="outline">
			<mat-label>First Name </mat-label>
			<input matInput  formControlName="firstName" required />
		</mat-form-field>
    <mat-form-field appearance="outline" >
			<mat-label>Last Name </mat-label>
			<input matInput  formControlName="lastName" required />
		</mat-form-field><br />
		<mat-form-field appearance="outline"  >
			<mat-label>Email</mat-label>
			<input formControlName="email" type="text" placeholder="Enter Email if Known" matInput >
		</mat-form-field>
<br />
		<mat-form-field appearance="outline">
			<mat-label>Relation (IS MY)</mat-label>
			<mat-select matNativeControl  formControlName="relationType" required>
				<mat-option *ngFor="let reltype of relations" [value]="reltype">
					{{ reltype }}
				</mat-option>
			</mat-select>
		</mat-form-field>
		 &nbsp;

      <mat-form-field appearance="outline" >
        <mat-label>Natural Number?</mat-label>
        <mat-select matNativeControl  formControlName="nnGuess" >
          <mat-option *ngFor="let nnI of nnKeys" [value]="nnI">
            {{ nnI }}
          </mat-option>
        </mat-select>
      </mat-form-field>

	<br />
		&nbsp;
    <span *ngIf="isAdmin()" >
		<mat-form-field appearance="outline" >
			<mat-label>How Identified</mat-label>
			<mat-select matNativeControl  formControlName="sourceType">
				<mat-option *ngFor="let nns of nnSources" [value]="nns">
					{{ nns }}
				</mat-option>
			</mat-select>
		</mat-form-field>
  </span>
		&nbsp; 
		<mat-form-field appearance="outline" >
			<mat-label>Birth Order (Birth Mother)</mat-label>
			<input matInput  formControlName="momBirthOrder" />
		</mat-form-field>


	<button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Submit</button>

</form>
