<div >
  ROLES:
    <form (ngSubmit)="onSubmit()">

     <div>
      <label class="custom-control-label" for="practicing">Master</label>
      <mat-checkbox (change)="clickMaster()" [checked]="master"></mat-checkbox>
   </div>
     <div>
       
      <label class="custom-control-label" for="partner">Senior JourneyMan</label>
      <mat-checkbox (change)="clickSenior()" [checked]="senior"></mat-checkbox>
   </div>

   <div>
      <label class="custom-control-label" for="practicing">JourneyMan</label>
      <mat-checkbox (change)="clickJourneyman()" [checked]="junior"></mat-checkbox>
   </div>
   <div>
      <label class="custom-control-label" for="practicing">Apprentice</label>
      <mat-checkbox (change)="clickApprentice()" [checked]="apprentice"></mat-checkbox>
   </div>
   <div>
      <label class="custom-control-label" for="edit">Admin</label>
      <mat-checkbox (change)="clickAdmin()" [checked]="admin"></mat-checkbox>
   </div>
   <button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Submit</button>
    </form>

</div>