
<mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="30" class='spin'> </mat-progress-spinner>

<div class="ident-link">

	<p>
		<span>
			Individual Identification link<br>
			<button mat-button [cdkCopyToClipboard]="'https://bodyof9.com/product/individual-identification'"><mat-icon >content_copy</mat-icon>COPY</button>
			<a href="https://bodyof9.com/product/individual-identification" target="_blank">GO TO<mat-icon >shortcut</mat-icon></a>
		</span> &nbsp;
		<span>
			Family Identification link<br>
			<button mat-button [cdkCopyToClipboard]="'https://bodyof9.com/product/parenting-session'"><mat-icon >content_copy</mat-icon>COPY</button>
			<a href="https://bodyof9.com/product/parenting-session" target="_blank">GO TO<mat-icon >shortcut</mat-icon></a>
		</span> &nbsp;
		<span>
			Couple Identification link<br>
			<button mat-button [cdkCopyToClipboard]="'https://bodyof9.com/product/couples-identification'"><mat-icon >content_copy</mat-icon>COPY</button>
			<a href="https://bodyof9.com/product/couples-identification" target="_blank">GO TO<mat-icon >shortcut</mat-icon></a>
		</span>

	</p>
	
	<p>


		<button mat-button [cdkCopyToClipboard]="couponCode"><mat-icon >content_copy</mat-icon>COPY</button>
		<span *ngIf="couponCode">&nbsp; <b>YOUR Coupon Code: </b> &nbsp; {{ couponCode }}</span>
		
		<span *ngIf="isAdmin()">&nbsp; <button mat-button (click)="addCouponCode()"> Add Coupon Code </button></span>

	</p>

</div>



<div class="action">
	<div *ngIf="prepaidCount > 0 || client">
		<button type="button" (click)="onClickInvite()">
			<span *ngIf="client">Invite Client</span>
			<span *ngIf="!client">Click to Assign PrePaid Identification</span>
			<mat-icon>how_to_reg</mat-icon>
		</button>
	</div>

	<span *ngIf="isAdmin() && client">
		<button type="button" (click)="onClickAdd(inputGroup)" >
			<span *ngIf="client">Add Client</span> <mat-icon>add</mat-icon>
		</button>
	</span>

</div>

<div *ngIf='editing && client' class='editing'>
	<button class='close' mat-stroked-button (click)="onCloseClick()">Close <mat-icon>close</mat-icon></button>
 	<client-member-form [inputGroup]="inputGroup" (notifyParent)="memberSubmittedHandler($event)" ></client-member-form>
</div>

<div *ngIf='editing && !client' class='editing'>
	<button class='close' mat-stroked-button (click)="onCloseClick()">Close <mat-icon>close</mat-icon></button>
 	<prepaid-member-form [person]="person" [members]="members" [inputGroup]="inputGroup" [canMEA]="canMEA" [packages]="packages" (notifyParent)="memberSubmittedHandler($event)" ></prepaid-member-form>
</div>

<div *ngIf="inputGroup && inputGroup.members.length > 0">
	<p>After you have purchased your pre-paid Identification you can assign it to your client here. 
		After assigning the name and email below an email will be sent to your client directly from Body of 9 with instruction on how to book their Identification
		 and invite you to attend.</p>

<span *ngIf="client"><h2>Clients of: {{ inputGroup?.group.groupName }}</h2></span>
<span *ngIf="!client"><h2>List of: {{ inputGroup?.group.groupName }}</h2></span>
	<span>
		<button mat-stroked-button (click)="onRefreshClick()">Refresh <mat-icon>refresh</mat-icon></button>
	</span>

	<table mat-table [dataSource]="dataSource" matSort>

		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
			<td mat-cell *matCellDef="let element"> {{element.formPerson.firstName}} {{element.formPerson.lastName}} </td>
		</ng-container>

		<ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
			<td mat-cell *matCellDef="let element"> {{ element.invitedStatus }}</td>
		</ng-container>

		<ng-container matColumnDef="email">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
			<td mat-cell *matCellDef="let element"> {{ element.person.primaryEmail }}</td>
		</ng-container>

		<ng-container matColumnDef="nn">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Nat. Num. </th>
			<td mat-cell *matCellDef="let element"> {{ element.formPerson.naturalNumber}}</td>
		</ng-container>

		<ng-container matColumnDef="link">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Booking Link </th>
			<td mat-cell *matCellDef="let element"> <span *ngIf="!element.eventParams?.unassignedDate && !element.eventParams?.usedDate; else NoLink">
				<button mat-button [cdkCopyToClipboard]="element.invitedPerson.link"><mat-icon >content_copy</mat-icon>Copy</button><a href="element.invitedPerson.link">Booking Link</a></span>
				<ng-template #NoLink>N/A</ng-template>
			</td>
		</ng-container>

		<ng-container matColumnDef="calendly">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Calendy </th>
			<td mat-cell *matCellDef="let element">
				<span *ngIf="element.formPerson.naturalNumber==0">
					<span *ngIf="element.formPerson.idLink; else noLink">
						<button mat-button [cdkCopyToClipboard]="element.formPerson.idLink"><mat-icon >content_copy</mat-icon></button><a href="element.formPerson.idLink">Booking Link</a>
					</span>
				<ng-template #noLink>Use Links above</ng-template>
				</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="revoke">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> Revoke </th>
		<td mat-cell *matCellDef="let element">
			 <span *ngIf="!element.eventParams?.unassignedDate && !element.eventParams?.usedDate"><button type="button" (click)="onClickRevoke(element)"> <mat-icon>remove_done</mat-icon></button></span>
			 <span *ngIf="element.eventParams?.usedDate"> Used </span>
			 <span *ngIf="element.eventParams?.unassignedDate"> Revoked </span>

		</td>
		</ng-container>

		<ng-container matColumnDef="delete">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Remove </th>
			<td mat-cell *matCellDef="let element">
				<span *ngIf="!(element.person._key == inputGroup.createdBy)"><button type="button" (click)="onClickLeave(element)"> <mat-icon>logout</mat-icon></button></span>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

	</table>
	<mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]" (page)="onChangePage($event)"></mat-paginator>

</div>
