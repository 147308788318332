import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { Agreement, mkMTAgreement  } from '../../../../bo9-shared/models/person/agreements.model';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';

import { ArangoService } from '../../services/arangoservice/arango.service';
import { validationMessages } from '../../helpers/form.helper';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { Person } from '../../../../bo9-shared/models/person/person.model';
import { EventEmitterService } from '../../services/events/events.service';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})
  
export class AgreementComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selectedAgreement$: Observable<Agreement>;
  messagesLoading$: Observable<boolean>;

  currentPane = 'center';

  public displayedColumns = ['edit','title', 'timeStamp','delete'];
  public dataSource = new MatTableDataSource<Agreement>();

  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;

  Agreements: Agreement[];
  pageSize: number;

  validationMessages = validationMessages;
  user:Person;
  AgreementSize: number;
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private router: Router,
    private arangoService: ArangoService,
    private emitterService: EventEmitterService
    ) {
      this.user = this.auth.getAuthUser();
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'AgreementComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
      if (!this.user) {
        console.error('User Not logged in ');
        alert('EMail support&#64;bodyof9.com - Permission  Error #025'); 
        this.router.navigate(['login'] );
      }  
     this.loadAgreements();
     }

  loadAgreements() {
    if (this.user.preferences) {
      this.AgreementSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }
    
    this.arangoService.getAgreements()
    .subscribe( (result) => {
      const e = result.content;
 
      this.dataSource.data = e as Agreement[];
      
    });
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  onClickDelete($event) {
    console.log('Delete click '+JSON.stringify($event));
    if (confirm("Are you sure you want to delete this Agreement? "+ $event.title)) {

      this.arangoService.deleteAgreement($event._key)
      .subscribe ( (result) => {

        console.log('Save result  is: ' + JSON.stringify(result.content));
        this.emitterService.emitter.emit('Message','Agreement ' +  $event.title + ' deleted');
        if (this.user.preferences && this.user.preferences.autoRefresh) {
          this.loadAgreements(); // refreash
        }

      })
    }


  }

  ngOnInit() {

  }
  onCloseClick() {
    this.currentPane = 'center';
  }
  tabChanged(event : any) {
    
  }

  addAgreementClick() {
    this.selectedAgreement$ = of(mkMTAgreement(""));
    this.currentPane = 'left';
  }

  onClickEdit(p:Agreement) {
    console.log('Expand Agreement  Contact '+ JSON.stringify(p.title));
    this.selectedAgreement$ = of(p);

    this.currentPane = 'left';
  }

  onRefreshClick() {
    console.log('Refresh click ');
    this.loadAgreements();

  }

  agreementSubmittedHandler($event: any) {
    console.log(' Agreement  submitted '+ JSON.stringify($event));
    this.loadAgreements();
    this.currentPane = 'center';

  }
  

}
