import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';

import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { firstName, getUserName, lastName, mkEmptyPerson, mkSimplePerson, Person } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { contactFullName } from '../../../../../bo9-shared/models/contact/contact.model';
import { Invite, FormInvite, InvitedStatus } from '../../../../../bo9-shared/models/person/invite.model';
import { addNaturalNumber, makeMTNaturalNumber, NaturalNumber, NaturalNumberTypes } from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';
import { map, catchError, startWith, debounceTime, switchMap, finalize, tap } from 'rxjs/operators';

import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import { DateToYYMMDDhhmmss } from '../../../../../bo9-shared/helpers/time.helper';

import { SelectsService, SelectTypes } from 'src/services/selects/selects.service';
import { InvitesService } from 'src/services/invites/invites.service';
import { SearchService } from 'src/services/search/search.service';
import { findInvalidControlsRecursive } from '../../../helpers/form.helper';
import { responseEncode } from '../../../../../bo9-shared/uuid.service';
import { Product } from '../../../../../bo9-shared/models/product/product.model';
import { formPerson } from '../../../../../bo9-shared/models/person/formPerson.model';
import { SelectPersonDialogComponent } from 'src/displayComponents/dialogs/selectPersonDialog/selectPersonDialog.component';
import { MatDialog } from '@angular/material/dialog';
import {  createWooCustomerFromPerson, createWooOrderFromPersonProduct} from '../../../../../bo9-shared/models/woocommerce/wooorder.model';
import { WooCommerceService } from 'src/services/woocommerce/woocommerce.service';
import { WooCustomer } from '../../../../../bo9-shared/models/woocommerce/woocustomer.model';
import { EventEmitterService } from 'src/services/events/events.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth/auth.service';


@Component({
  selector: 'update-product-form',
  templateUrl: './updateProductForm.component.html',
  styleUrls: ['./updateProductForm.component.scss'],
  // make fade in animation available to this component

})
export class UpdateProductFormFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() product: Product;
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
  messagesLoading$: Observable<boolean>;


  people: Person[];
  valid: false;
  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;
  disableBtn = false;
  searchString: string;
  currentP$: Observable<Person>;
  AssignForm: FormGroup;
  relatingPersonName: string;

  validationMessages = validationMessages;

  PearlModuleTypes: string[];
  nnKeys: string[];
  nnSource: string[];

  emails$: Observable<string[]>;
  isLoading = false;



  firstname= '';


  selectedPerson: string;
  subscriptions: Subscription[] = [];
  public skills: Observable<string[]> = of(["Angular", "React", "Node", "Java"]);
  user: Person;
  constructor(
    private formBuilder: FormBuilder,
    private emitterService: EventEmitterService,
    private wooServices: WooCommerceService,
    private router: Router,
    private arangoService: ArangoService,
    private dialog: MatDialog,
    private searchService: SearchService,
    private auth: AuthService 
    ) 
  {

    this.AssignForm = this.formBuilder.group({

      email: new FormControl('', Validators.compose([ Validators.email])),
      quantity: new FormControl('1', Validators.compose([ Validators.email])),

    });
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'UpdateProductFormComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
  }

  ngOnInit() {

  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  displayFn(email?: any) : string {
    console.log('displayFn ' + JSON.stringify(email));
    return email ? email : ''
  }

  get emailControl() { return this.AssignForm.controls.email; }



  ngOnChanges() {

    this.AssignForm.reset();
    this.AssignForm.controls.quantity.setValue(1);

  }

  selectPerson() {
      
    const dRef = this.dialog.open(SelectPersonDialogComponent, {
      width: '75%',
      height: '75%',
      data: {group: null, grouped: false}
    });

    dRef.afterClosed()
    .subscribe(  (result: formPerson[]) => {
      this.selectedPerson = result[0].email;
      if (result && result.length > 0) { // Only take the first
        

        this.AssignForm.controls.email.setValue(result[0].email);


      }

    })

}

  submitForm() {
    console.log('Submit updateproduct form ' + JSON.stringify(this.selectedPerson));
    if (this.AssignForm.value.email && this.AssignForm.value.email!=='') {
      this.selectedPerson = this.AssignForm.value.email;
    }
    if (this.selectedPerson) {
      this.subscriptions.push(  this.searchService.searchEmail(this.selectedPerson.toLowerCase(), false)
      .subscribe(async  (persons: Person[]) => {
        if (persons) {
          const person = persons[0];

          let wooCustomers = await this.wooServices.searchCustomers(this.selectedPerson);
          let woocustomer;
          if ( !(wooCustomers && wooCustomers.length> 0)) { //No customer for this user yet
            const makeCustomer = createWooCustomerFromPerson(person);
            const newCust = await firstValueFrom(this.wooServices.createCustomer(makeCustomer ));
            if (newCust && newCust.content) {
              if (typeof(newCust.content) == 'string' && newCust.content.includes("Create error")) {
                makeCustomer.username = makeCustomer.username+'.'+Math.trunc(Math.random()*100).toString(); // Try with username with random
                const newerCust = await firstValueFrom(this.wooServices.createCustomer(makeCustomer ));
                if (typeof(newerCust.content) == 'string' &&  newerCust.content.includes("Create error")) {
                  alert('Cant create new customer, tried twice');
                } else {
                  woocustomer = newCust.content.customer;
                }
              } else {
                woocustomer = newCust.content.customer;
              }
 
            } else {
              alert('Cant create new customer');
            }
          } else {
            woocustomer = wooCustomers[0];
          }

          const order = createWooOrderFromPersonProduct(this.product, person, woocustomer,this.AssignForm.value.quantity );
          const newOrder = await firstValueFrom(this.wooServices.createOrder(order ));
          if (typeof(newOrder.content) == 'string' && newOrder.content.includes("error")) {
            this.emitterService.emitter.emit('Message','Order create error '+ JSON.stringify(newOrder.content));
            alert('Order create error '+ JSON.stringify(newOrder.content));
          } else {
            const completOrder = await firstValueFrom(this.wooServices.completeOrder(newOrder.content.id));
            this.emitterService.emitter.emit('Message','Order Completed ');
          }
          
        } else {
          alert("Email not known "+this.selectedPerson);
        }
      }))
    } else {
      alert("Oops missed an email ");
    }


   


    }



  
  
}
