import { Component, forwardRef, ChangeDetectionStrategy, OnDestroy, OnInit, Input, OnChanges, Output, EventEmitter, DebugEventListener } from '@angular/core';
import {  FormControl, ControlValueAccessor, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { validationMessages } from 'src/helpers/form.helper';
import { Event } from '../../../../../bo9-shared/models/event/event.model';
import { EspressoEvent, EspressoEventCategory } from '../../../../../bo9-shared/models/event/event9e.model';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { EventEmitterService, } from 'src/services/events/events.service';
import { EventDBService } from 'src/services/events/eventdb.service';
import { findInvalidControlsRecursive } from '../../../helpers/form.helper';
import { SearchService } from 'src/services/search/search.service';
import { Router } from '@angular/router';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { AuthService } from 'src/services/auth/auth.service';
import { userIsAdmin } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { addDays, DateToYYMMDD, tzSubset } from '../../../../../bo9-shared/helpers/time.helper';



@Component({
    selector: 'event-form',
    templateUrl: './eventForm.component.html',
    styleUrls: ['./eventForm.component.scss'],
})
export class EventFormComponent implements OnChanges, OnInit, OnDestroy {
      @Input() event: Event;
      @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();

      statusList=['Open','Closed'];
      valid: false;
      recording_link='';
      disableBtn = false;
      EventForm: FormGroup;
      URLForm: FormGroup;
      RecordingForm: FormGroup;
      newFileName: string;
      files: any ;
      loading = false;
      fileControl: FormControl;
      catListDB: string[] = [];
      identifiers: string[] = [];
      fullCategories: EspressoEventCategory[] = [];
      timezones: string[] =  tzSubset();
      relatingPersonName: string;
      disableReadOnly = false;
      user: Person;
      validationMessages = validationMessages;
      subscriptions: Subscription[] = [];
      constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private auth: AuthService,
        private arangoService: ArangoService,
        private searchService: SearchService,
        private emitterService: EventEmitterService,
        private eventDBService: EventDBService
        ) 
      {     
        this.makeForm();
        this.disableBtn = false;
        this.user = this.auth.getAuthUser();
        this.subscriptions.push(this.eventDBService.getCategories()
        .subscribe( (res) => {
          console.log('get categories ' + JSON.stringify(res));
          this.fullCategories = res.content;
          for (const cat of res.content as EspressoEventCategory[]) {
            this.catListDB.push(cat.category_name);
          }
          
        }))
        this.subscriptions.push(this.arangoService.getIdentifiers()
        .subscribe( async  (res) => {
          console.log('get identifers ' + JSON.stringify(res));
          for (const cat of res.content) {
            this.identifiers.push(cat.Name);
          }    
          if (this.event) {
            const resE = await this.searchService.searchEventKey(this.event._key, true);

            if (resE.event.length > 0) {  // found an event in arango
              this.event.identifiers = resE.event[0].identifiers;
              this.event.recording_link = resE.event[0].recording_link;
            }
          }

        }));
        this.subscriptions.push(this.arangoService.recordVisit({
          page: this.router.url, component: 'EventFormComponent', 
          visitor: this.user._key,
          help: false
        }).subscribe ({next: () => {
          this.disableBtn = false;
        }})); 
        this.fileControl = new  FormControl('',[]);
        this.fileControl.valueChanges.subscribe((files: any) => {
          console.log('File ' + JSON.stringify(files.name))
          if (!Array.isArray(files)) {
            this.files= [files];
          } else {
            this.files = files;
          }
        });
      }

      ngOnInit() {
        //this.makeForm();    
      }

      startSet(el: any) {
        this.EventForm.controls.endDate.setValue(el.target.value);
      }

      //get notesControl() { return this.EventForm.controls.recording; }
    
      makeForm() {
        let status='Open';

        if (this.event &&  (this.event.event_status == 'Closed' || this.event.event_status =='D')) {
          let status = 'Closed';
        }
        this.EventForm = this.formBuilder.group({
          name: new FormControl('', Validators.compose([Validators.required])),
          startDate: new FormControl('', Validators.compose([Validators.required])),
          endDate: new FormControl('', Validators.compose([Validators.required])),
          startTime: new FormControl('', Validators.compose([Validators.required])),
          timezone: new FormControl('', Validators.compose([])),
          identifiers: new FormControl('', Validators.compose([])),
          linkUrl: new FormControl('', Validators.compose([])),
          recording_link: new FormControl([''], Validators.compose([])),
          categories: new FormControl('', Validators.compose([Validators.required])),
          status: new FormControl(status, Validators.compose([Validators.required])),
        });

        this.URLForm = this.formBuilder.group({
          url: new FormControl('', Validators.compose([Validators.required])),
        });

        this.RecordingForm = this.formBuilder.group({
          fileControl: new FormControl('', Validators.compose([Validators.required])),
        });
    
      }

      
    
      submitURLForm() {
        this.disableBtn = true;
        if  (this.event.prior_status && this.event.prior_status == 'Closed'){
          alert("Can't change a Closed Event");
          this.disableBtn = false;
          return;
        }
        if (this.URLForm.valid) {
          this.subscriptions.push( this.arangoService.getURL(this.URLForm.value.url)
          .subscribe( (res) => {
            this.disableBtn = false;
            this.newFileName = res.content;
          }))
        }
      }

      submitRecordingForm() {
        console.log('Submit Recording file ');
        if (!this.files || this.files.length ===0) {
          alert("No file specified")
          return;
        } else if  (this.event.prior_status && this.event.prior_status == 'Closed'){
          alert("Can't change a Closed Event");
          this.disableBtn = false;
          return;
        } else {
          this.loading = true;
          this.disableBtn = true;
            this.arangoService.addRecordingFile(this.event._key, this.files[0])
            .subscribe( (res) => {
              this.disableBtn = false;
              console.log('Recording file response ' + JSON.stringify(res));
              this.EventForm.controls.recording_link.setValue(res.content);
              this.loading = false;
            })
        }

      }

      submitEventForm() {
        console.log('Submit event form ' + JSON.stringify(this.EventForm.value));
        if (this.EventForm.valid) {
          this.disableBtn = true;
          console.log('Submit event form - valid');
          let registration_end = DateToYYMMDD( addDays(new Date(this.EventForm.value.endDate), 2))
          let event_status = 'A';
          if (this.event) {
            this.event.prior_status = this.event.event_status? this.event.event_status : event_status ;
          }
          if (this.event && this.event.prior_status && this.EventForm.value.status == 'Closed' && this.event.prior_status != 'Closed') {
            if (confirm("Are you sure you want to close the Event? "+ this.EventForm.value.name)) {
              registration_end = '2023-01-01';
              event_status = 'Closed';
            } else {
              this.disableBtn = false;
              return;
            }
          } else if  (this.event && this.EventForm.value.status == 'Open' && this.event.prior_status == 'Closed' && !userIsAdmin(this.user)){
            alert("Only Admins can Open a previously Closed Event");
            this.disableBtn = false;
            return;
          } else if  (this.event && this.event.prior_status == 'Closed' && !userIsAdmin(this.user)){
            alert("Can't change a Closed Event");
            this.disableBtn = false;
            return;
          }
          // Ok to change
          const catList = this.EventForm.value.categories;
          let catIDs: string[] =[];
          for (const cl of catList) {
            for (const c of this.fullCategories) {
              if (c.category_name === cl) {
                catIDs.push(c.event_id.toString());
              }
            }
          }
          if (this.event) {  // Update
            
              this.event.categories = this.EventForm.value.categories;
              this.event.startDate = this.EventForm.value.startDate;
              this.event.endDate = this.EventForm.value.endDate;
              this.event.startTime = this.EventForm.value.startTime;
              this.event.name = this.EventForm.value.name;
              this.event.registration_end = registration_end;
              this.event.event_status = event_status;
              this.event.linkUrl= this.EventForm.value.linkUrl,
              this.event.recording_link = this.EventForm.value.recording_link?this.EventForm.value.recording_link: '';
              this.event.identifiers = this.EventForm.value.identifiers;

  
              this.subscriptions.push( this.arangoService.updateEvent(this.event,catIDs, catList)
              .subscribe ( (result) => {
                this.disableBtn = false;
                console.log('Update result  is: ' + JSON.stringify(result.content));
                this.emitterService.emitter.emit('Message','Event ' +  this.event.name + ' saved');

                this.notifyParent.emit('Event Saved');
              }))
          } else {

              const newEvent: Event = {
                _key: null,
                name: this.EventForm.value.name,
                description: 'Auto Generate '+this.EventForm.value.name,
                code: null,
                startDate: this.EventForm.value.startDate,
                endDate: this.EventForm.value.endDate,
                startTime: this.EventForm.value.startTime,
                registration_end : registration_end,
                event_status : event_status,
                contacts: [],
                identifiers: this.EventForm.value.identifiers,
                recording_link: this.EventForm.value.recording_link?this.EventForm.value.recording_link: '',
                categories: catList,
                linkUrl: this.EventForm.value.linkUrl,
                attendees: 0,
                tagId: '',
                source: 'wordpress',
                timezone: this.EventForm.value.timezone,
              }
              
              this.subscriptions.push( this.arangoService.updateEvent(newEvent,  catIDs, catList)
              .subscribe ( (result) => {
                console.log('Update result  is: ' + JSON.stringify(result.content));
                this.disableBtn = false;  
                this.emitterService.emitter.emit('Message','Event ' +  newEvent.name + ' saved');
                this.EventForm.reset();
                this.notifyParent.emit('Event Created');

               }))

            }
          } else {
            alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.EventForm)));
          }

      }
    
    
      onPageChange(event){
        console.log('Page change '+event);
      }
      ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
      }
    
    
      ngOnChanges() {
        this.EventForm.reset();
        this.URLForm.reset();
        this.RecordingForm.reset();
          if (this.event) {
             this.searchService.searchEventKey(this.event._key, true)
             .then( (result) => {
              console.log('Event DB '+ JSON.stringify(result));
              if (result && result.event && result.event.length > 0) { // Pulled in from the DB
                const dbEvent: Event = result.event[0]
                this.event.identifiers= dbEvent.identifiers;
                this.event.recording_link = dbEvent.recording_link;
                this.recording_link = dbEvent.recording_link;
   
                this.event.categories = dbEvent.categories;
              }
              this.EventForm.patchValue(this.event);
             
              this.EventForm.controls.categories.setValue(this.event.categories);
            
              this.EventForm.controls.status.setValue('Open');

//if (this.event && (this.event.recordingEndedAt || this.event.event_status == 'Closed')) {
              if (this.event && this.event.event_status == 'Closed') {
                this.EventForm.controls.status.setValue('Closed');
              }
              
              // Everything now enabled 2022-04-20, allow wp event update
            })
          }
      }
    
    }
    
