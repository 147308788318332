import { Component, forwardRef, ChangeDetectionStrategy, OnDestroy, OnInit, ElementRef,Input, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { validationMessages } from 'src/helpers/form.helper';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { AuthService } from 'src/services/auth/auth.service';
import { NaturalNumber, NaturalNumbersType, NaturalNumberType, NaturalNumberTypes } from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';
import { Difference } from '../../../../../bo9-shared/models/person/differences.model';
import { checkDiffs } from '../../../../../bo9-shared/models/person/differencesCode.model';
import { Person } from '../../../../../bo9-shared/models/person/person.model';

// todo replace this with BO9Contact


@Component({
    selector: 'nn-form',
    templateUrl: './naturalNumberForm.component.html',
    styleUrls: ['./naturalNumberForm.component.scss'],
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => NatNumFormComponent),
        multi: true
      },
      {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => NatNumFormComponent),
        multi: true,
      }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
  })
  export class NatNumFormComponent implements OnInit ,ControlValueAccessor, OnDestroy  {
    @Input() diffs: Difference[];
    @Input() self: boolean;
    form: FormGroup;
    reportedIDMethod: string;
    personOrOther: string;
    historyList: any[];
    checkDiffs = checkDiffs;
    identifiedNN= NaturalNumber.None;
    sourceType= NaturalNumber.None;
    invitedNN = NaturalNumber.None;
    changeText: boolean = false;
    nnIconIdentified: string;
    nnIconAsserted: string;


    nnNone = NaturalNumber.None

    nnSource;
    user: Person;

      subscriptions: Subscription[] = [];
      validationMessages = validationMessages;

      constructor(private formBuilder: FormBuilder,
        private arangoService: ArangoService,
        private router: Router,     
        private auth: AuthService,
        ) {
        this.nnSource = Object.keys(NaturalNumberTypes).map(key => NaturalNumberTypes[key]).filter(value => typeof value === 'string') as string[];
        this.form = this.formBuilder.group({
          asserted: [],
          identified: [],
            invited: []
        });
        this.user = this.auth.getAuthUser();

        this.subscriptions.push(
          this.form.valueChanges.subscribe(value => {
          //  console.log('NN valuechange '+ JSON.stringify(value));
            this.onChange(value);
            this.onTouched();
          })
        );
        
            

        this.subscriptions.push(this.arangoService.recordVisit({
          page: this.router.url, component: 'NatNumFormComponent', 
          visitor: this.user._key,
          help: false
        }).subscribe ({next: () => {}})); 
      }

      ngOnInit() {

      }

        get value(): NaturalNumbersType {
           //console.log('nn get '+ JSON.stringify(this.form.value));
          return this.form.value;
        }

        set value(value: NaturalNumbersType) {
          console.log('nn set '+ JSON.stringify(value));
          this.form.patchValue(value);

          this.historyList = value.history;
          this.identifiedNN = value.identified? value.identified.naturalNumber : NaturalNumber.None;
          this.sourceType = value.asserted? value.asserted.naturalNumber : NaturalNumber.None;
          this.invitedNN = value.invited? value.invited.naturalNumber: NaturalNumber.None;
			 this.nnIconIdentified = (Number(this.identifiedNN) > 0) ? "filter_" + value.identified.naturalNumber : "grid_on";
			 this.nnIconAsserted = (Number(this.sourceType) > 0) ? "filter_" + value.asserted.naturalNumber : "grid_on";

         this.onChange(value);
         this.onTouched();
        }

        get identifiedControl() {return this.form.get('identified')};
        get assertedControl() {return  this.form.get('asserted')};
        get invitedControl() {return this.form.get('invited')};
        get historyControl() {return this.form.get('history')};


        ngOnDestroy() {
          console.log('NN destroy ');
          this.subscriptions.forEach(s => s.unsubscribe());
        }

        onChange: any = () => {
          console.log('NN chnages ');
        };
        onTouched: any = () => { console.log('NN touched ');};

        registerOnChange(fn) {
          this.onChange = fn;
        }

        writeValue(value) {
          console.log('NN write value ' + JSON.stringify(value));
          if (value) {
            this.value = value;
          }

          if (value === null) {
            this.form.reset();
          }
        }

        registerOnTouched(fn) {
          this.onTouched = fn;
        }

        validate(_: FormControl) {
       //   console.log('nn valid '+ JSON.stringify(this.form.valid));
          return this.form.valid ? true : false;
        }

        reset() {
          this.form.reset();
        }
      }
