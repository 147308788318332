<p>After submitting this form, your client will receive an email inviting them to accept your invitation. When they accept you will be able to 
to assign them one of your pre-purchased identifcations and pass them the link to book it. Or if you don't have any available 
pre-purchased applications you can send them the link to purchase an identification themselves.
</p>
<form [formGroup]="form" (ngSubmit)="submitForm()" class="relate-form">
	<div>
		<h3>{{ inputGroup?.group.groupName}}: Invite Client</h3>

		<mat-form-field  appearance="outline" >
			<mat-label>First Name</mat-label>
			<input matInput formControlName="first" id="first" required />
		</mat-form-field>
		 &nbsp;
		<mat-form-field  appearance="outline" >
			<mat-label>Last name</mat-label>
			<input matInput formControlName="last" id="last" required />
		</mat-form-field>
		 &nbsp;
		<mat-form-field  appearance="outline" >
			<mat-label>Email</mat-label>
			<input matInput formControlName="email" id="email"	/>
		</mat-form-field>

	</div>

	<br>
	<button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Submit</button>

</form>
