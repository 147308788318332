import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { LoginService } from 'src/services/loginservice/login.service';

import { Observable, of, Subscription, timer } from 'rxjs';
import { mkEmptyPerson, Person, getGender } from '../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';


import { SearchService } from 'src/services/search/search.service';
import { take } from 'rxjs/operators';
import { actCampaignDelay, contactLimit } from 'src/config/webapp.config';

import { ActiveCampaignService } from 'src/services/activecampaign/activecampaign.service';
import { EventEmitterService } from 'src/services/events/events.service';
import { ProductsService } from 'src/services/products/products.service';
import { ModuleDetail, Product } from '../../../../bo9-shared/models/product/product.model';

import { Pearl } from '../../../../bo9-shared/models/pearl/pearl.model';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { canContact } from '../../../../bo9-shared/models/contact/contact.model';



@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  // make fade in animation available to this component

})


export class ProfileComponent implements OnInit, OnDestroy {
  messagesLoading$: Observable<boolean>;

  people$: Observable<Person[]>;
  totalFound: number=0;
  totalFound$: Observable<number>;

  currentPane = 'center';
  valid: false;
  fromI$:Observable<string>;
  fromF$:Observable<string>;
  fromA$:Observable<string>;

  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  fieldValues: number;
  fromName = 'Not Set';


  currentP$: Observable<Person>;
  currentIKey$: Observable<string>;
  currentPerson: Person;
  RelateForm: FormGroup;
  loading= false;
  invitingPersonName: string;
  familyPersonName: string;
  operation = '';
  contactLmt = contactLimit;
  makeEmail = false;
  emailer: Person;
  email: string;
  user: Person;
  validationMessages = validationMessages;
  person: Person;
  seenPearls: Pearl[];
  subscriptions: Subscription[] = [];
  products: Product[];
  constructor(
    private formBuilder: FormBuilder,

    private searchService: SearchService,
    private activeCampaignService: ActiveCampaignService,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private emitterService: EventEmitterService,
    private arangoService: ArangoService
    ) 
  {
    this.user = this.auth.getAuthUser();

    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'ProfileComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error'); 
      this.router.navigate(['login'] );
    } 

   // this.webSocket.initSocket(this.user.wsSocket);

    this.makeForm();

    this.operation = 'Edit';
    this.route.params.subscribe(params => {
       
      console.log(params); // log the entire params object
      console.log(params['oper']); // log the value of id
      this.products = [];

      this.operation = params['oper'];
     
      this.fillUser();

    });
    
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  get emailControl() { return this.RelateForm.controls.email; }
  get nameControl() { return this.RelateForm.controls.name; }

  makeForm() {

    this.RelateForm = this.formBuilder.group({
      name: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([ Validators.email])),
      Invite: new FormControl('', Validators.compose([])),
      pearlmodule: new FormControl('', Validators.compose([])),
    });
  }


  submitRelateForm() {
    console.log('Submit relate form ' + JSON.stringify(this.RelateForm.value));

    if (this.RelateForm.valid) {
      console.log('Submit relate form - valid');
      this.currentPane = 'center'
    }
  }


  ngOnInit() {
    this.email = this.user.primaryEmail;
  }



  onPageChange(event){
    console.log(event);

  }
  
  onCloseEmail($event) {
    this.makeEmail = false;
  }

  sendEmail($event) {
    console.log('Send Email to '+$event);
    this.emailer = $event;
    this.makeEmail = true;
  }

  fillUser() {
    this.subscriptions.push(  this.searchService.searchEmail(this.user.primaryEmail, true)
    .subscribe( (persons: Person[]) => {
      if (!persons) {
        console.error('No person found for '+this.user.primaryEmail);
        this.person = null;
      }
      this.person = persons[0];
      this.fromName = this.person.contact.names.name.first + ' ' + this.person.contact.names.name.last;
      
    }))
  }


  onCloseClick() {
    this.currentPane = 'center';
  }

  onClickInvite(p:Person) {

    this.operation = 'Invite';

  }


  onClickActivity(p:Person) {

    this.operation = 'Activity';

  }

  onClickFamily(p:Person) {
    this.operation = 'Family';
  }

  addPersonClick() {
    console.log('Add Person  Contact ');
    this.currentP$ = of(mkEmptyPerson('Contact',''));

  }


  onClickExpand(p: Person) {
    console.log('Expand Person  Contact '+ JSON.stringify(p));
    this.operation = 'Edit';

  }

 

  personSubmittedHandler($event: any) {
    console.log(' Person  submitted '+ JSON.stringify($event));
    this.fillUser(); // Load again
    this.currentPane = 'center';

  }

  relateSubmittedHandler($event: any) {
    console.log(' Relate  submitted '+ JSON.stringify($event));
    this.currentPane = 'center';

  }

  onClickPearls(person) {


      this.operation='Pearl';

   
  }

  

}
