import { ArangoNodeType, ArangoRevType, ArangoKeyType, BO9UUID } from "../arango/arango.model";
import { Meta } from "../BO9_base/meta.model";
import { NaturalNumber, NaturalNumbersType, NaturalNumberType } from '../naturalNumber/naturalNumber.model';
import { InvitedPerson, InvitedStatus } from "./invite.model";
import { Person } from './person.model';

export interface FormRelation {
    _key: string;
    fromFirstName: string,
    fromLastName: string,
    fromName?: string, // depr
    toName?: string, // depr
    toFirstName?: string,
    toLastName?: string,
    toEmail?: string,
    inviteType?: string,
    pearlModule?: string,
    naturalNumber?: string,
    sourceType?: string,
    relationType?: string,
    relation?: Relation,
    momBirthOrder?: number,
    fromPerson: Person,
    toPerson?: Person,
    invitedStatus: InvitedStatus,
    invitedPerson?: InvitedPerson,
    meta?: Meta
  }
export interface RelationType  {
    relationtype: string,
    mirrortype: string
};

export interface Relation {
    _key: BO9UUID;
    from: string;
    to: string;
    relationType: string;
    naturalNumber: NaturalNumber;
    sourceType: string;
    pearlModule: string;
    momBirthOrder: number,
    inviteType: string;
    invitedStatus: InvitedStatus,
    invitedPerson?: InvitedPerson,
    existing: Boolean; // Was this relations made with an exisiting person
    meta: Meta;
}

export function inverseRelate(relation: string, relations: RelationType[]) : string {

    let result = 'Unknown';

    for (const r of relations) {
        if (r.relationtype === relation) {
            return r.mirrortype;
        }
    }

    return result;
}

