<div class="selectCustomer-modal">


  <mat-card class="bo9mat">

    <mat-card-content>
        <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef> Select </th>
          <td mat-cell *matCellDef="let element">
            <button type="button" (click)="selectFormCustomer(element)" matTooltip="Select"> <mat-icon>check_circle_outline</mat-icon></button>
          </td>
        </ng-container>
        <ng-container matColumnDef="wooid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Woo Id</th>
          <td mat-cell *matCellDef="let element">{{element.externalid}} </td>
          </ng-container>
          <ng-container matColumnDef="product">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="title"> Product</th>
          <td mat-cell *matCellDef="let element"> {{element.title}} </td>
          </ng-container>
          <ng-container matColumnDef="categories" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header="category"> Categories</th>
          <td mat-cell *matCellDef="let element"> {{element.category}} </td>
          </ng-container>
          <ng-container matColumnDef="sku">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> SKU</th>
          <td mat-cell *matCellDef="let element" > {{element.sku}} </td>
          </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"  [ngClass]="{'make-gold': row.selected }"></tr>
      
        </table>
        <mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]">
        </mat-paginator>
    </mat-card-content>
  </mat-card>
  <footer>
    <button mat-stroked-button (click)="close()">
      Cancel
    </button>
    <button class="btn-save" mat-flat-button
      (click)="selectAndClose()">
      Add Product to Person
    </button>
  </footer>
</div>
