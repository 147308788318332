import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { LoginService } from '../loginservice/login.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private auth: AuthService,
        private loginService: LoginService
    ) { }

    isLogginOut = false;

    intercept(req: HttpRequest<any>,
              next: HttpHandler): Observable<HttpEvent<any>> {
                  
        const token = this.auth.getSessionToken();
       // console.log('Intercept JWT ' + JSON.stringify(token));
        if (token) {
           // console.log('Use JWT ');
            const cloned = req.clone({
                headers: req.headers.set('Authorization',
                    'Bearer ' + token)
            });

            return next.handle(cloned).pipe( catchError( error => {
                console.log('HTTP Error ' + JSON.stringify(error));
                let message;
                if (error.error instanceof ErrorEvent) {
                    // handle client-side error
                    message = `Error: ${error.error.message}`;
                }
                if (error.status === 401 || error.status === 403) {
                    message = `Error: ${error.message}`;
                    if (!this.isLogginOut) {
                        alert('Authorization Error - Logging Out - Esc to continue');
                        this.isLogginOut = true;
                    }
                    
                    this.loginService.logout(''); // logout
                } else {
                    message = `Error: ${error.message}`;
                }
                return throwError(()=> new Error(message));
            })
            );
        } else {
            console.error('NO JWT ');
          
             return next.handle(req);
        }
    }

    OnDestroy(): void {
        console.log('Destroy Interceptor');
    }
}
