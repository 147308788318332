<h2> {{ firstname }} Invite a Friend....</h2>
<form [formGroup]="InviteForm" (ngSubmit)="submitInviteForm()" class="relate-form">
	<div>
		<mat-form-field appearance="outline" class="full-width">
			<mat-label>First Name </mat-label>
			<input matInput  formControlName="firstName" required />
		</mat-form-field>
    <mat-form-field appearance="outline" class="full-width">
			<mat-label>Last Name </mat-label>
			<input matInput  formControlName="lastName" required />
		</mat-form-field>
		<mat-form-field appearance="outline" class="full-width" >
			<mat-label>Email</mat-label>
			<input formControlName="email" type="text" placeholder="Enter Email" matInput [matAutocomplete]="auto">
			<mat-autocomplete #auto="matAutocomplete">
				<mat-option *ngFor="let email of skills | async" [value]="email"  (onSelectionChange)="optionSelected($event)">{{ email }}</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>
	<div>
		<mat-form-field appearance="outline">
			<mat-label>Invite</mat-label>
			<mat-select matNativeControl formControlName="inviteType" required multiple>
				<mat-option *ngFor="let reltype of invites" [value]="reltype">
					{{ reltype }}
				</mat-option>
			</mat-select>
		</mat-form-field>
		&nbsp;
		<mat-form-field appearance="outline" >
			<mat-label>Module</mat-label>
			<mat-select matNativeControl  formControlName="pearlModule" required multiple>
				<mat-option *ngFor="let reltype of PearlModuleTypes" [value]="reltype">
					{{ reltype}}
				</mat-option>
			</mat-select>
		</mat-form-field>
		&nbsp;
		<mat-form-field appearance="outline" >
			<mat-label>Natural Number?</mat-label>
			<mat-select matNativeControl  formControlName="nnGuess" required>
				<mat-option *ngFor="let nnI of nnKeys" [value]="nnI">
					{{ nnI }}
				</mat-option>
			</mat-select>
		</mat-form-field>
		&nbsp;
		<mat-form-field appearance="outline" >
			<mat-label>How Identified</mat-label>
			<mat-select matNativeControl  formControlName="sourceType" required>
				<mat-option *ngFor="let nns of nnSource" [value]="nns">
					{{ nns }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Submit</button>
</form>


	  <!-- <invite-list [person]="p$ | async" [inviter]=true></invite-list>-->
