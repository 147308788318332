import { Person } from "./person.model";
import decode from 'jwt-decode';
import { Capabilities } from '../../models/person/capabilities.model';
import { checkCapable } from "../../helpers/auth.helper";


export function maxCapability(p: Person): string {
    if (p && p.jwt) {
        if (userIsAdmin(p) || userIsSuperAdmin(p)) {
            return("Master"); 
        }
        if (userIsMaster(p)) {
            return("Master");
        }
        if (userisSeniorJ(p)) {
            return("SeniorJ");
        }
        if (userIsJourneyman(p)) {
            return("JourneyMan");
        }
        if (userIsApprentice(p)) {
            return("Apprentice");
        }
    }
    return "User";
}

export function levelFromSysRole(roles: any[]): string {
   for (let role of roles) {
        if (role.systemRoles.includes("Admin") || role.systemRoles.includes("SuperAdmin")) {
            return("Master"); 
        }
        if (role.systemRoles.includes("Master") ) {
            return("Master");
        }
        if (role.systemRoles.includes("SeniorJ") ) {
            return("SeniorJ");
        }
        if (role.systemRoles.includes("Journeyman") ) {
            return("JourneyMan");
        }
        if (role.systemRoles.includes("Apprentice") ) {
            return("Apprentice");
        }
    }
    return "";
}
export function userCanSee(u: Person, pg: string, pageItem?: string) : boolean {
    const pageName  = pg.toLowerCase();

    if (u && u.jwt) {
        switch (pageName) {
            case 'admin': {
                if (userIsAdmin(u) || userIsSuperAdmin(u)) {
                    return(true);
                }
                break;
            }
            default: {
                return false; // If you have to ask, and it's not yes.....
            }
        }
    }
    return false;
}

export function userIsApprentice(u: Person): boolean {
    //console.log('isJourneyman '+JSON.stringify(u));
     if (u && u.jwt) {
         const tokenPayload:any = decode(u.jwt);
         if (checkCapable(Capabilities.Apprentice, tokenPayload) ) {
             
             return(true);
         }
     }
     return false;
}
 
export function userIsJourneyman(u: Person): boolean {
   //console.log('isJourneyman '+JSON.stringify(u));
    if (u && u.jwt) {
        const tokenPayload:any = decode(u.jwt);
        if (checkCapable(Capabilities.Journeyman, tokenPayload) ) {
            
            return(true);
        }
    }
    return false;
}

export function userisSeniorJ(u: Person): boolean {
    //console.log('IsProact '+JSON.stringify(u));
     if (u && u.jwt) {
         const tokenPayload:any = decode(u.jwt);
         if (checkCapable(Capabilities.SeniorJ, tokenPayload)) {
             
             return(true);
         }
     }
     return false;
 }


export function userHasRole(u: Person, role: string): boolean {
    // console.log('HasRole '+JSON.stringify(u));
    if (u && u.systemRoles) {
        if (u.systemRoles.includes(role)) {
            
            return(true);
        }
    }
    return false;
}
export function userIsAdmin(u: Person): boolean {

    if (u && u.jwt) {
        const tokenPayload:any = decode(u.jwt);
        if (checkCapable(Capabilities.Admin, tokenPayload)) {
           // console.log('IsAdmin');
            return(true);
        }
    }
    return false;
}

export function userIsSuperAdmin(u: Person): boolean {

    if (u) {
        const tokenPayload:any = decode(u.jwt);
        if (checkCapable(Capabilities.SuperAdmin, tokenPayload) ) {
        // console.log('IsAdmin');
            return(true);
        }
    }
    return false;
}

export function userIsMaster(u: Person): boolean {
    if (u) {
        const tokenPayload:any = decode(u.jwt);
        if (checkCapable(Capabilities.Master, tokenPayload)) {
        // console.log('IsAdmin');
            return(true);
        }
    }
    return false;
}

export function removeRole(u: Person, role: string): string[] {

    var res: string[];

    res = [];

    if (u.systemRoles) {
        u.systemRoles.forEach(element => {
            if (element != role) {
                res.push(element);
            }
        });
    }


    return res;
    
}

export function clearRoles(u: Person): string[] {

    u.systemRoles = removeRole(u, "Apprentice");
    u.systemRoles = removeRole(u, "Journeyman");
    u.systemRoles = removeRole(u, "SeniorJ");
    u.systemRoles = removeRole(u, "Master");
   u.systemRoles = removeRole(u, "Partner");
    u.systemRoles = removeRole(u, "Practicing");
    u.systemRoles = removeRole(u, "Practising");


    return u.systemRoles;
    
}
