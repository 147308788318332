import { Injectable } from '@angular/core';
import { networkConfig } from 'src/config/webapp.config';
import { PageMonitor, BO9EVENTS } from '../../../bo9-shared/models/BO9_base/BO9_base.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
import { BO9UUID } from '../../../bo9-shared/models/UUID.model';


@Injectable()
export class SystemService {

  constructor(
    private http: HttpClient,

    private router: Router
    ) {}

  public setSystemMonitor(p: PageMonitor): Observable<any> {
    console.log('update profile view with ' + JSON.stringify(p));
    
    return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.SYSTEMMONITOR , p);
  }


  public getPageViews(orgid: BO9UUID): Observable<any> {
    console.log('getPageViews');
    return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.SYSTEMMONITOR + '/?orgid=' + orgid);
  }

  public errorAlert( where: string, m: string) {
    console.error('Location ' + where + 'Err:' + m);
    alert(m);
    this.router.navigate(['/profile/Edit']);


  }

}

