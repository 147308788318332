import { BO9UUID } from "../UUID.model";


export type DateStringType = string;
export type DateTimeStringType = string;


export type URLstring = string;


export interface PageMonitor {
    personId: BO9UUID;
    routeName: string;
    idOfPage: string;
    otherPageParams: string;
    created: Date;
}

export const enum BO9Actions {
    JOINED="JOINED",   // 0
    LEFT="LEFT",
    RENAME="RENAME",
    MESSAGE="MESSAGE",
    SOCKETCREATED="SOCKETCREATED",
    USERDATA="USERDATA",
    VALIDATEUSER="VALIDATEUSER",
    SOCKETNOTCREATED="SOCKETNOTCREATED",
    CREATESOCKET="CREATESOCKET",
    GETCOMMUNITYUPDATES="GETCOMMUNITYUPDATES", // 10
    GETMESSAGES="GETMESSAGES",
    GETSEENMESSAGES="GETSEENMESSAGES",
    GETADDRESSES="GETADDRESSES",
    GETPROFILES="GETPROFILES",
    GETCHATROOMS="GETCHATROOMS",
    CREATECHATROOM="CREATECHATROOM", // 20
    REGISTERED="REGISTERED", 
    REJECTED = "REJECTED",
    SYSTEM="SYSTEM",
    PAGEVIEWS="PAGEVIEWS", // 28
    LOGOUT="LOGOUT",
    EVENT="EVENT",
    TRANSFERFILES = 'TRANSFERFILES',
    ERROR = 'ERROR',
    UNKNOWNEMAIL = "UNKNOWNEMAIL",
    NONATURALNUMBER = "NONATURALNUMBER"
}

export enum SECURITYEVENTS {
    LOGIN = "applogin",
    LOGOUT = "applogout",
    AUTHRESPONSE = "auth-response"
}

// Socket.io events
export enum BO9EVENTS {
    CONNECT = 'connect',
    VERSION="version",
    DISCONNECT = 'disconnect',
    MESSAGE = 'message',
    SEENMESSAGE = 'seenmessage',
    CHATROOM = 'chatroom',
    USERLIST = 'userlist',
    LOGIN = 'loginMess', // hide a little
    REGISTER = 'register',
    REGISTERAPP = 'registerapp',
    MOVEMENTS = 'movements',
    GROUP = 'group',
    GROUPMEMBERS = 'groupmembers',
    HAPPYTESTS='happytests',
    GROUPTYPES = 'grouptypes',
    ERROR = 'error',
    COMMUNITY = 'community',
    PEOPLE = 'people',
    IDENTIFIERS = 'identifiers',
    IDENTIFIEDBY = 'identifiedby',
    REFERRALS = 'referrals',
    PROFILES = 'profiles',
    ADDRESSES = 'addresses',
    SYSTEMMONITOR = 'systemmonitor',
    SYSTEMVARIABLES = 'systemvariables',
    LOGOUT = 'logout',
    EMAIL = 'emailMess', // hide it a little
    VALIDATEEMAIL = 'validateemail',
    BROADCAST = 'broadcast',
    UPLOADFILE = 'uploadfile',
    PRODUCTS = 'products',
    ACTIONS = 'actions',
    GETCOUNTS = 'getcounts',
    ISSUE = 'issue',
    COUNTPEARLS = 'countpearls',
    COUNTNUMBERS = 'getnumbercount',
    XFERFROM9E = 'transferfrom9e',
    XFERFROM9EACTIVITY = 'transferfrom9eactivity',
    SEARCH = 'search',
    SEARCHEMAIL = 'searchemail',
    SEARCHEVENT = 'searcheevent',
    SEARCHKEY = 'searchkey',
    SEARCHSORT = 'searchsort',
    EVENTPARAMS = 'eventparams',
    SETEVENTPARAMS = 'seteventparams',
    SEARCHACTIVITY = 'searchactivity',
    COMPAREEMAILID = 'compareemailid',
    CAPABILITIES = 'capabilities',
    PERSON = 'person',
    PERSONIMAGE = 'personimage',
    PERS0N = 'pers0n',
    MERGE = 'merge',
    SELECTS = 'selects',
    LOGGEDINUSERS = 'loggedinusers',
    PROGRAMS = 'programs',
    VIDEOKEY='videokey',
    VIDEOURL='videourl',
    VIDEOSESSION='videosession',
    ORDERTRACK = 'ordertrack',
    ALLEVENTS = 'allevents',
    WPEVENTS = 'wpevents',
    WPUSERBYEMAIL = 'wpuserbyemail',
    WPUSERBYID = 'wpuserbyid',
    WPEVENTBYID = 'wpeventbyid',
    WPEVENTATTENDEES = 'wpeventattendees',
    WPEVENTSBYEMAIL = 'wpeventsbyemail',
    WPEVENTCATEGORIES = 'wpeventcategories',
    NEWATTENDEE= 'newattendee',
    WPEVENTATTENDEEATTENDED = 'wpeventattendeeattended',
    PEARL = 'pearl',
    PEARLBYID = 'pearlbyid',
    PEARLSEARCH = 'pearlsearch',
    PEARLSEEN = 'pearlseen',
    PEARLSUBSCRIBE= 'pearlsubscribe',
    PEARLUNSUBSCRIBE= 'pearlunsubscribe',
    BANRESET = 'banreset',
    PEARLFILE = 'pearlfile',
    RECORDINGFILE = 'recordingfile',
    PAGE = 'page',
    AGREEMENT='agreement',
    VISIT='visit',
    TEMPLATE = 'template',
    MISCSCOPYTOS3= 'misccopytos3',
    ACTIVECAMPAIGNCONTACTBYEMAIL = 'activecampaigncontactbyemail',
    ACTIVECAMPAIGNCONTACT = 'activecampaigncontact',
    ACTIVECAMPAIGNCONTACTTAG = 'activecampaigncontacttag',
    ACTIVECAMPAIGNCONTACTLIST = 'activecampaigncontactlist',
    ACTIVECAMPAIGNTEMPLATE= 'activecampaigntemplate',
    READACTIVECAMPAIGN = 'readactivecampaign',
    READACTIVECAMPAIGNUSERFIELDS = 'readactivecampaignfields',
    READACTIVECAMPAIGNLIST = 'readactivecampaignlist',
    ACTIVECAMPAIGNLISTS = 'activecampaignlists',
    READACTIVECAMPAIGNUSERTAGS = 'readactivecampaigntags',
    READACTIVECAMPAIGNTAGSANDFIELDS = 'readactivecampaigntagsandfields',
    DELETEACTIVECAMPAIGNCONTACT = 'deleteactivecampaigncontact',
    ACTIVECAMPAIGNADDCONTACTTAG = 'activecampaignaddcontacttag',
    ACTIVECAMPAIGNCREATETAG = 'activecampaigncreatetag',
    ACTIVECAMPAIGNCREATEORDER = 'activecampaigncreateorder',
    ACTIVECAMPAIGNCREATECUSTOMER = 'activecampaigncreatecustomer',
    ACTIVECAMPAIGNINVITE= 'activecampaigninvite',
    ACTIVECAMPAIGNPREPAY= 'activecampaignprepay',
    ACTIVECAMPAIGNPIPELINES= 'activecampaignpipelines',
    ACTIVECAMPAIGNDEAL= 'activecampaigndeal',
    ACTIVECAMPAIGNDEALDATA= 'activecampaigndealdata',
    ACTIVECAMPAIGNDEALSTAGE= 'activecampaigndealstage',
    ACTIVECAMPAIGNPREPAYUSE= 'activecampaignprepayuse',
    ACTIVECAMPAIGNREVOKE= 'activecampaignrevoke',
    WOOCUSTOMERS = "woocustomers",
    WOOCUSTOMERSFROMDB = "woocustomersfromdb",
    WOOORDERSSYNC = "wooorderssync",
    WOOORDERS = "wooorders",
    WOOORDERSFROMDB = "wooordersfromdb",
    WOOBILLING = "woobilling",
    WOODOWNLOADS = "woodownloads",
    WOODOWNLOADERS = "woodownloaders",
    WOOORDERCOUNT= "wooordercount",
    WOOCOUPONUSER = "woocouponuser",
    WOOCOUPON = "woocoupon",
    AUTOLOGIN = 'autologin',  // post adds or updates
    USERAUTOLOGIN = 'userautologin',  // post adds or updates
    MEMBER = 'member',
    STUDENT = 'student',
    INSTITUTESTUDENT = 'institutestudent',
    ENROLLINSTITUTESTUDENT = 'enrollinstitutestudent',
    INSTITUTECOURSE= 'institutecourse',
    INSTITUTETRACK = 'institutetrack',
    INSTITUTECREDIT = 'institutecredit',
    APPROVEAFFILIATE = 'appaffiliate',
    RESOURCE = 'resource',
    RESOURCESEARCH = 'resourcesearch',
    EVENT = 'event',
    EVENTMETA = 'eventmeta',
    INVITE = 'invite',
    RELATION = 'relation',
    ACTIVITY = 'activity',
    ACTCAMPUNSUB = 'unsub',
    ACTCAMPUPDATE = 'conupdate',
    SPALOGIN = 'spalogin',
    SPALOGOUT = 'spalogout',
    APIV10 = 'APIV10',
    HBEAT = 'hbeat',
    HELP = 'help',
    CERTIFICATE = 'certificate',
    PHONETEST = 'phonetest',
    CALENDAR = 'calendly',
    CALENDLYEVENTS = 'calendlyevents',
    CALENDLYWEBHOOKS = 'calendlywebhooks',
    CALENDLYEVENTSBYEMAIL = 'calendlyeventsbyemail',
    EVENTSBYID = 'eventsbyid',
    CURRENTEVENTS = 'currentevents',
    CALENDLYATTENDEES = 'calendlyattendees',
    CALENDLYEVENTATTENDEEATTENDED = 'calendlyeventattended',

    API2021_01 ='app/2021-1',
    EVENTS_API = 'events.json',
    USER_TOKEN_API = 'user-token.json',
    APP_TOKEN_API = 'app-token.json',
    PEARLS_API = 'pearls.json',
    WOOCOMMERCE_API_ORDER_CREATED = 'wooordercreate',
    ZOOM_WEBHOOK = 'zoomwebhookapi',
    DISCORD_CHANNELS = 'discordchannels',
    DISCORD_MESSAGES = 'discordmessages',

    SCHEDULEDEVENTS = 'scheduledevents',
    SCHEDULEDPEARLS= 'scheduledpearls',
    GETUSERPEARL= 'getuserpearl',
    INVITERESPONSE = 'inviteresponse',

    SLACK = 'slack'

}