<form [formGroup]="ProfileForm" class="personal-form">


		<mat-tab-group dynamicHeight class="profile-tabs profile-form" mat-align-tabs="start" data-css='partner'>

			<mat-tab class="person-tab">
				<ng-template mat-tab-label >
					
						<mat-icon>person_pin</mat-icon> Contact

				</ng-template>
				<div class="tab-header">
					<h3>Contact</h3><h4>Your Natural Number is: {{ nn }}</h4>
					<div *ngIf="!submitting"><button mat-button type="button" (click)="submitProfileForm()" class="btn btn-save mat-submit-button">Update</button></div>
				</div>
				<div>
					<contact-form formControlName="contact"></contact-form>
				</div>
			</mat-tab>
<!--
			<mat-tab>
				<ng-template mat-tab-label>
					<mat-icon>{{ nnIcon }}</mat-icon>
					Natural Number
				</ng-template>
				<div class="tab-header">
					<h3>Natural Number</h3>
					<div><button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Update</button></div>
				</div>
				<div>
					<nn-form formControlName="natnum" [self]="true"></nn-form>
				</div>

			</mat-tab>
-->
			<mat-tab>
				<ng-template mat-tab-label>
					
					<mat-icon>content_paste</mat-icon> Details
				</ng-template>
				<div class="tab-header">
					<h3>Details</h3>
					<div><button mat-button type="submit"  [disabled]="disableBtn" class="btn btn-save mat-submit-button">Update</button></div>
				</div>
				<div class="frame-box">
					<div class="instructions">
						<p>Upload headshot of yourself (.jpg or .png).</p>
			
						<p>Link to your business website URL</p>
			
						<p>2-3 paragraphs written in 1st person to include:</p>
						<span class="instructions_indent">
						  <p>How and when you were identified & the power of your experience.</p>
			
						  <p>An overview of your business and specialty and how you incorporate Body of 9 .</p>
						</span>
								  </div>
					<mat-form-field appearance="outline" class="full-width">
						<mat-label>Biography</mat-label>
						<textarea matInput  formControlName="bio" ></textarea>
  
						<mat-error *ngFor="let validation of validationMessages.bio">
						   <mat-error class="error-message"
							  *ngIf="bioControl.hasError(validation.type) && bioControl.dirty
								   || submitAttempt || bioControl.touched">
							  {{ validation.message }}
						   </mat-error>
						</mat-error>
					  </mat-form-field>
					  <mat-form-field appearance="outline" class="full-width">
						<mat-label>Website URL</mat-label>
						<input matInput  formControlName="websiteUrl" placeholder="e.g. https://bodyof9.com"/>
					  </mat-form-field>
					<mat-form-field appearance="outline" class="full-width">
					  <mat-label>Birth Order (Mother's Side)</mat-label>
					  <input matInput formControlName="birthOrder"  />
					</mat-form-field>

					<mat-form-field  appearance="outline" >
						<mat-label>Gender</mat-label>
						<mat-select matNativeControl   formControlName="gender">
							<mat-option *ngFor="let nnI of genderTypes" [value]="nnI">
							  {{ nnI }}
							</mat-option>
						  </mat-select>
					
						<mat-error *ngFor="let validation of validationMessages.gender">
							<mat-error class="error-message"
							  *ngIf="genderControl.hasError(validation.type) && genderControl.dirty
									|| submitAttempt || genderControl.touched">
							  {{ validation.message }}
							</mat-error>
						</mat-error>
					</mat-form-field>
					Image File:
					<mat-form-field>
					  <ngx-mat-file-input [formControl]="fileControl" >
					  </ngx-mat-file-input>
				   </mat-form-field>
				   <div *ngIf="image  && image !=''">
					<img  src="{{image}}" alt="Person Image">    <button mat-stroked-button (click)="delImage()">Delete Img</button> 
				   </div>
				  

				</div>
			</mat-tab>
			<mat-tab>
				<ng-template mat-tab-label>
				
					<mat-icon>content_paste</mat-icon> Purchases
				</ng-template>
				<div>
					<purchase-list [person]="person"></purchase-list>
				  </div>
			</mat-tab>
			<mat-tab>
				<ng-template mat-tab-label>
				
					<mat-icon>settings</mat-icon> Preferences
				</ng-template>
				<div class="tab-header">
					<h3>Preferences</h3>
					<div><button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Update</button></div>
				</div>
				<div class="frame-box">
					<div>
						<div >
							<mat-checkbox (change)="resetDefaults()">Reset To Defaults</mat-checkbox>
						</div>
						<hr>
						<!-- no mat form field required-->
						<mat-checkbox (change)="selectAutoRefresh()"  [(ngModel)]="person.preferences.autoRefresh" [ngModelOptions]="{standalone: true}">Auto Refresh</mat-checkbox>
						<hr>

						<div>
							<mat-form-field appearance="outline" >
								<mat-label>Event History Age (days)</mat-label>
								<input matInput  formControlName="eventHistory"  />
							</mat-form-field>
							<hr>
						</div>
						<div>
							<mat-form-field appearance="outline" >
								<mat-label>Event Future Days to Retreive</mat-label>
								<input matInput  formControlName="eventFutureDays"  />
							</mat-form-field>
							<hr>
						</div>
					</div>
					<div>
						<div>
							<mat-form-field appearance="outline" >
								<mat-label>Rows Per Page for Tables</mat-label>
								<input matInput  formControlName="rowsPerPage"  />
							</mat-form-field>
							<hr>
						</div>
					</div>
					<div>
						<form [formGroup]="emailForm" (ngSubmit)="submitEForm()">
							<mat-form-field  appearance="outline" >
								<mat-label>New Login Email</mat-label>
								<input matInput formControlName="newemail" id="newemail" />
							</mat-form-field>
							 &nbsp;
							<button mat-flat-button color="primary" name="changepwdbutton" (click)="onClickChangeEmail()">
								Change Login Email
							</button>
						</form>
					</div>
				</div>
			</mat-tab>
			<mat-tab>
				<ng-template mat-tab-label>
				
					<mat-icon>handshake</mat-icon> Agreements
				</ng-template>
				<div>
					<table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
						<ng-container matColumnDef="title">
							<th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
							<td mat-cell *matCellDef="let element"> {{element.title}} </td>
						  </ng-container>
					
						 
						  <ng-container matColumnDef="created">
							<th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
							<td mat-cell *matCellDef="let element"> {{element.created}} </td>
						  </ng-container>

						  <ng-container matColumnDef="versions">
							<th mat-header-cell *matHeaderCellDef mat-sort-header> Versions </th>
							<td mat-cell *matCellDef="let element"> 
								<table>
									<tr><th>Version</th><th>File</th><th>Agreed</th></tr>
									<tr *ngFor="let version of element.versions">
										<td>{{version.version}}</td><td>{{version.sourceFileName}}</td><td>{{version.dateAgreed}}</td>
									</tr>
								</table>
							</td>
						  </ng-container>
						  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

						  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
						  <tr mat-row *matRowDef="let row; columns: versionColumns"></tr>
						
					 </table>
				  </div>
			</mat-tab>
		</mat-tab-group>

		<hr>

		<!-- <button mat-flat-button color="primary" name="changepwdbutton"
		  (click)="testEmail()"
		  >
		  Test Email
		  </button>-->
        <span *ngIf="!submitting"><button mat-button type="button" (click)="submitProfileForm()" class="btn btn-save mat-submit-button">Update</button></span>
    
	</form>
