import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CommunityUpdate } from '../../../../bo9-shared/models/communityupdate.model';
import { networkConfig } from 'src/config/webapp.config';
import { BO9EVENTS } from "../../../../bo9-shared/models/BO9_base/BO9_base.model";


@Injectable()
export class CommunityUpdateService {

    updates: CommunityUpdate[];

    constructor (
        private http: HttpClient
    ) {
        this.updates = null;

    }

    private loadCommunityUpdates(): Observable<any> {
            console.log('Get Community Update ' + JSON.stringify(networkConfig));
            return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.COMMUNITY);
    }

    public getCommunityUpdates(): Observable<CommunityUpdate[]> {
        return new Observable( observer =>{
            if (this.updates) {
                observer.next(this.updates);
                observer.complete();
            }  else {
                const sub =  this.loadCommunityUpdates()
                .subscribe( {
                    next:(msg: any) => {
                    console.log('commu  from db');
                    this.setCommunityUpdates(msg.content);
                
                    observer.next(msg.content);
                    observer.complete();
                }, 
                error:( e) => {console.log('error from db'); observer.error(e)}
            })
        }
        } )
    }

    public setCommunityUpdates( u: CommunityUpdate[]) {
        this.updates = u;
    }

    public sendCommunityUpdate(u: CommunityUpdate, f1: File): Observable<any> {

        const formData: any = new FormData();
        formData.append('file1', f1);
        formData.append('update', JSON.stringify(u));

        console.log('sensendUpdate  '+JSON.stringify(formData));
  
        return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.COMMUNITY, formData);
      }

      public deleteCommunityUpdate(u: CommunityUpdate): Observable<any> {

        return this.http.delete(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.COMMUNITY+'?cu='+u._key);
      }


}
