<div class="login-wrapper">
  <mat-card class="bo9mat login-card">
    <button class="login-logo" routerLink="/profile/Edit">BO9</button>
    <mat-card-content>
      <form class="login-form" [formGroup]="loginForm">
        <table class="login" cellspacing="0">
          <tr>
            <td>
              <mat-form-field class="login" appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email"
				  name="username"
				  required
				  (focus)="clearMessage()"
				  />
              </mat-form-field>
            </td>
          </tr>
          <tr>
          <td>
            <mat-form-field class="login" appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput formControlName="password"
                type="password"
                name="password"
                required
					 (focus)="clearMessage()"
                (keydown.enter)="onClickLogin()"
              />
            </mat-form-field>
          </td>
        </tr></table>
      </form>
      <div class="BO9-loading-wrapper">
        <mat-spinner diameter="40" [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>
      </div>
      <p class="login-message" *ngIf='loginmessage != ""'>{{ loginmessage }}</p>
    </mat-card-content>
    <mat-card-actions>
      
      <button mat-flat-button color="primary" name="loginbutton" class="loginbutton"
        [disabled]="!loginForm.valid"
        *ngIf='loginvisible'
        (click)="onClickLogin()">
        Login
      </button>
      <button mat-flat-button color="primary" name="registerbutton"  class="loginbutton"
      [disabled]="!loginForm.valid"
      (click)="onClickRegister()">
      Register as a New User
    </button>

    <button mat-flat-button color="primary" name="forgotpwdbutton"  class="loginbutton"
	 [disabled]="!loginForm.touched"
    (click)="onClickForgotPwd()">
    Forgot Password
  </button>

    </mat-card-actions>
    <span  *ngIf="!loginForm.valid" style="font-size: 11px !important; font-weight: bold !important;">*Please Fill in Email and Password to Enable Buttons</span><br />
    <button mat-stroked-button (click)="video()">Video Tutorial</button>
  </mat-card>

    
</div>
