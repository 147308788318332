<div class="invite-modal add-email">
	<h2>Confirm Email</h2>
	<div *ngIf="allOk; else checkingEmail">
		<div *ngIf="notAdmin()" flex="45">
			Please confirm that you have access to:  {{ emailItem.value}} which will now be sent a validation email - please check your Spam Folder
		</div>
	</div>
	<ng-template #checkingEmail>Checking Email is available....</ng-template>
	<div *ngIf="emailErrors.length > 0">
		<div flex="45">
			<ul *ngFor="let err of emailErrors">
				<li>{{err}}
			</ul>
		</div>
	</div>

	<footer>
		<div *ngIf="allOk; else done">
			<div *ngIf="notAdmin(); else finish">
				<button mat-stroked-button (click)="close()">Yes I have Access</button>
				&nbsp;
				<button mat-stroked-button (click)="cancel()">Cancel</button>
			</div>
			<ng-template #finish>
				<button mat-stroked-button (click)="completed()">Done</button>
			</ng-template>
		</div>
		<ng-template #done>
			<button mat-stroked-button (click)="cancel()">Done</button>
		</ng-template>
	</footer>
</div>
