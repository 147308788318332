import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { Invite } from '../../../../../bo9-shared/models/person/invite.model';
import { Credit} from '../../../../../bo9-shared/models/institute/credits.model';
import { createUUID } from '../../../../../bo9-shared/uuid.service';
import { DateToYYMMDDhhmmss } from '../../../../../bo9-shared/helpers/time.helper';
import { Meta } from '@angular/platform-browser';
import { UserService } from 'src/services/auth/session.service';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import {  MaxSizeValidator } from '../../../validators/max-size.validators';
import { findInvalidControlsRecursive } from '../../../helpers/form.helper';

@Component({
  selector: 'credit-form',
  templateUrl: './creditForm.component.html',
  styleUrls: ['./creditForm.component.scss'],
  // make fade in animation available to this component
})
export class CreditFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() credit: Credit;
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
  messagesLoading$: Observable<boolean>;

  disableBtn = false;
  valid: false;
  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;
  currentP$: Observable<Credit>;
  CreditForm: FormGroup;

  validationMessages = validationMessages;

  invites$: Observable<Invite[]>;
  PearlModuleTypes$: Observable<string[]>;
  creditT: string[];
  nnSource: string[];

  isLoading = false;
  user: Person;
  files: any ;
  fileControl: FormControl;
  image: string;
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    private arangoService: ArangoService
    ) 
  {


    this.CreditForm = this.formBuilder.group({
      creditId:  new FormControl('', Validators.compose([])),
      _key:  new FormControl('', Validators.compose([])),
      eventCode: new FormControl('', Validators.compose([Validators.required])),
      html: new FormControl('', Validators.compose([ Validators.required])),
      mtHours: new FormControl(1, Validators.compose([ Validators.required])),
      bo9Hours: new FormControl(1, Validators.compose([ Validators.required])),
      icfHours: new FormControl(1, Validators.compose([ Validators.required])),
      icfResourcePercent: new FormControl(100, Validators.compose([ Validators.required])),
      
    });
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'CreditFormComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
  }

  ngOnInit() {
    this.fileControl = new  FormControl(this.files,[]);
    this.fileControl.valueChanges.subscribe((files: any) => {
      console.log('File ' + JSON.stringify(files.name))
      if (!Array.isArray(files)) {
        this.files= [files];
      } else {
        this.files = files;
      }
    })
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  get accessKeyControl() { return this.CreditForm.controls.accessKeyControl; }
  get titleControl() { return this.CreditForm.controls.title; }
  get contentControl() { return this.CreditForm.controls.content; }
  get creditTypeControl() { return this.CreditForm.controls.theirNN;}



  onCreditChange(event){
    console.log(event);
  }

  optionSelected(event: any) {
    console.log('Selected  ' + JSON.stringify(event.source.value));
  }

 

   

  ngOnChanges() {
  
    const p:Credit = this.credit
    this.CreditForm.reset();
    console.log('Got Credit form ' + JSON.stringify(this.credit));
  //  console.log('Got Credit img ' + JSON.stringify(this.credit.fileInfo[0].Location));
    if (this.credit && this.credit.fileInfo && this.credit.fileInfo.length > 0) {
      this.image = this.credit.fileInfo[0].Location;
    }
    this.CreditForm.patchValue({
      _key: p? p._key: null,
      creditId: p? p.creditId : '',
      eventCode: p? p.eventCode : '',
      html: p? p.html : '',
      mtHours: p? p.hours.MT.hours: 0,
      bo9Hours: p? (p.hours.BO9? p.hours.BO9.hours: 0): 0,
      icfHours: p? p.hours.ICF.hours: 0,
      icfResourcePercent: p? p.hours.ICF.resourcePercentage : 0
    });

  }

  submitCreditForm() {
    console.log('Submit Credits form ' + JSON.stringify(this.CreditForm.value));
    if (this.CreditForm.valid) {
      console.log('Submit Credit form - valid');
      
      const p: Credit = {
        _key: this.CreditForm.value._key,
        creditId: this.CreditForm.value.creditId? this.CreditForm.value.creditId: createUUID(),
        imageURL: '',
        eventCode: this.CreditForm.value.eventCode,
        html: this.CreditForm.value.html,
        hours: {
          attended: 0,
          ICF: {
            hours: this.CreditForm.value.icfHours,
            resourcePercentage: this.CreditForm.value.icfResourcePercent
          },
          MT: {
            hours: this.CreditForm.value.mtHours,
            resourcePercentage: 0
          },
          BO9: {
            hours: this.CreditForm.value.bo9Hours,
            resourcePercentage: 0
          }
        }
      }
      
      this.subscriptions.push( this.arangoService.addCredit(p, this.files? this.files[0]: [])
      .subscribe( (res) => {
        console.log('Credit add response ' + JSON.stringify(res));
        
        this.notifyParent.emit('Credit Saved');
      }))

    } else {
      console.log('Submit Credit form - Not valid');
      alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.CreditForm)));

    }
  }
  
}
