<mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="30" class='spin' style='float:right;margin-right:15px;'> </mat-progress-spinner>

<div class="calendar" >
    <div>Event Color Map: <span class="attended"> Attended </span>&nbsp;<span class="past"> Past </span>&nbsp;<span class="missed"> Did Not Attend </span>&nbsp;<span class="upcoming"> Upcoming (click to register) </span>&nbsp;<span class="registered"> Registered </span>&nbsp;</div>
	<app-calendar 
    [language]="'en'" 
    [dataSource]="allEvents$ | async" 
    (dayEvents)="selectDay($event)"
    (newMonth)="newMonth($event)"> </app-calendar>

	
</div>
