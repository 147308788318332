<form [formGroup]="form" (ngSubmit)="submitForm()" class="relate-form">
	<div>
		<h3>{{ inputGroup?.group.groupName}}: Invite Member</h3>

		<mat-form-field  appearance="outline" >
			<mat-label>First Name</mat-label>
			<input matInput formControlName="first" id="first" required />
		</mat-form-field>
		 &nbsp;
		<mat-form-field  appearance="outline" >
			<mat-label>Last name</mat-label>
			<input matInput formControlName="last" id="last" required />
		</mat-form-field>
		 &nbsp;
		<mat-form-field  appearance="outline" >
			<mat-label>Email</mat-label>
			<input matInput formControlName="email" id="email"	/>
		</mat-form-field>
		<br>
		<mat-checkbox (change)="selectAdmin()" [checked]="gm.admin">Admin</mat-checkbox>
		 &nbsp;  &nbsp;
		<mat-checkbox (change)="selectPrimary()" [checked]="gm.primaryContact">Primary Contact</mat-checkbox>

	</div>

	<br>
	<button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Submit</button>

</form>
