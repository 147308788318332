<div >
 
    

    <mat-card class="bo9mat" fxLayout="row wrap">
      <h2>Person Count</h2>
      <div> Shows Natural Number / Total </div>
      <table *ngIf="arangoNumbers">
        <tr><td><strong>Identified</strong></td>
          <td>{{ arangoNumbers.identified[0]?.natnum }}:{{ arangoNumbers.identified[0]?.numnatnum }}</td>
          <td>{{ arangoNumbers.identified[1]?.natnum }}:{{ arangoNumbers.identified[1]?.numnatnum }}</td>
          <td>{{ arangoNumbers.identified[2]?.natnum }}:{{ arangoNumbers.identified[2]?.numnatnum }}</td>
          <td>{{ arangoNumbers.identified[3]?.natnum }}:{{ arangoNumbers.identified[3]?.numnatnum }}</td>
          <td>{{ arangoNumbers.identified[4]?.natnum }}:{{ arangoNumbers.identified[4]?.numnatnum }}</td>
          <td>{{ arangoNumbers.identified[5]?.natnum }}:{{ arangoNumbers.identified[5]?.numnatnum }}</td>
          <td>{{ arangoNumbers.identified[6]?.natnum }}:{{ arangoNumbers.identified[6]?.numnatnum }}</td>
          <td>{{ arangoNumbers.identified[7]?.natnum }}:{{ arangoNumbers.identified[7]?.numnatnum }}</td>
          <td>{{ arangoNumbers.identified[8]?.natnum }}:{{ arangoNumbers.identified[8]?.numnatnum }}</td>
          <td>{{ arangoNumbers.identified[9]?.natnum }}:{{ arangoNumbers.identified[9]?.numnatnum }}</td>
          <td>{{ arangoNumbers.identified[10]?.natnum }}:{{ arangoNumbers.identified[10]?.numnatnum }}</td>
          <td>Total:{{ totalIdentifed }}</td>
        </tr>
        <tr><td><strong>Asserted</strong></td>
          <td>{{ arangoNumbers.asserted[0]?.natnum }}:{{ arangoNumbers.asserted[0]?.numnatnum }}</td>
          <td>{{ arangoNumbers.asserted[1]?.natnum }}:{{ arangoNumbers.asserted[1]?.numnatnum }}</td>
          <td>{{ arangoNumbers.asserted[2]?.natnum }}:{{ arangoNumbers.asserted[2]?.numnatnum }}</td>
          <td>{{ arangoNumbers.asserted[3]?.natnum }}:{{ arangoNumbers.asserted[3]?.numnatnum }}</td>
          <td>{{ arangoNumbers.asserted[4]?.natnum }}:{{ arangoNumbers.asserted[4]?.numnatnum }}</td>
          <td>{{ arangoNumbers.asserted[5]?.natnum }}:{{ arangoNumbers.asserted[5]?.numnatnum }}</td>
          <td>{{ arangoNumbers.asserted[6]?.natnum }}:{{ arangoNumbers.asserted[6]?.numnatnum }}</td>
          <td>{{ arangoNumbers.asserted[7]?.natnum }}:{{ arangoNumbers.asserted[7]?.numnatnum }}</td>
          <td>{{ arangoNumbers.asserted[8]?.natnum }}:{{ arangoNumbers.asserted[8]?.numnatnum }}</td>
          <td>{{ arangoNumbers.asserted[9]?.natnum }}:{{ arangoNumbers.asserted[9]?.numnatnum }}</td>
          <td>{{ arangoNumbers.asserted[10]?.natnum }}:{{ arangoNumbers.asserted[10]?.numnatnum }}</td>
          <td></td>
        </tr>
        <tr><td><strong>Invited</strong></td>
          <td>{{ arangoNumbers.invited[0]?.natnum }}:{{ arangoNumbers.invited[0]?.numnatnum }}</td>
          <td>{{ arangoNumbers.invited[1]?.natnum }}:{{ arangoNumbers.invited[1]?.numnatnum }}</td>
          <td>{{ arangoNumbers.invited[2]?.natnum }}:{{ arangoNumbers.invited[2]?.numnatnum }}</td>
          <td>{{ arangoNumbers.invited[3]?.natnum }}:{{ arangoNumbers.invited[3]?.numnatnum }}</td>
          <td>{{ arangoNumbers.invited[4]?.natnum }}:{{ arangoNumbers.invited[4]?.numnatnum }}</td>
          <td>{{ arangoNumbers.invited[5]?.natnum }}:{{ arangoNumbers.invited[5]?.numnatnum }}</td>
          <td>{{ arangoNumbers.invited[6]?.natnum }}:{{ arangoNumbers.invited[6]?.numnatnum }}</td>
          <td>{{ arangoNumbers.invited[7]?.natnum }}:{{ arangoNumbers.invited[7]?.numnatnum }}</td>
          <td>{{ arangoNumbers.invited[8]?.natnum }}:{{ arangoNumbers.invited[8]?.numnatnum }}</td>
          <td>{{ arangoNumbers.invited[9]?.natnum }}:{{ arangoNumbers.invited[9]?.numnatnum }}</td>
          <td>{{ arangoNumbers.invited[10]?.natnum }}:{{ arangoNumbers.invited[10]?.numnatnum }}</td>
          <td></td>
        </tr>

      </table>
    </mat-card>
    
    <pearls-info [pCount]="pearlCount"></pearls-info>
    
    </div>
    <div>
      <mat-card class="bo9mat">
      <h2>Logged In</h2>
    
      <table *ngIf="loggedIn">
        <tr><th>Email</th><td>Last Connected</td></tr>
        <tr *ngFor="let item of loggedIn">
          <td>{{item.email}}</td><td>{{item.lastConnected}}</td>
          </tr>
      </table>
      </mat-card>
    </div>

    <div>
      <mat-card class="bo9mat">
      <h2>Pearl Signups</h2>
      Pearls: {{ pearlList }} - Invites: {{ invitedPearls }}
      </mat-card>
    </div>
    <div>
      <mat-card class="bo9mat">
      <h2>Calendly Webhooks</h2>
      <table *ngIf="calendlyWebhooks">
        <tr><th>Created</th><th>Updated</th><th>State</th><th>URL</th></tr>
        <tr *ngFor="let item of calendlyWebhooks">
          <td>{{item.created_at}}</td><td>{{item.updated_at}}</td><td>{{item.state}}</td><td>{{item.uri}}</td>
          </tr>
      </table>
      </mat-card>
    </div>
    <div>
      <mat-card class="bo9mat">
      <h2>Product List</h2>

    
        <table *ngIf="products">
          <tr><th>Product</th><th>Categories</th><th>Duration</th></tr>
          <tr *ngFor="let item of products">
            <td>{{item.title}}</td><td>{{item.category}}</td><td>{{item.moduleDetail.duration}}</td>
            </tr>
        </table>


      </mat-card>
 
      <mat-card class="bo9mat" fxLayout="row wrap">
     
        <button mat-stroked-button (click)="onRefreshClick()">Refresh<mat-icon>refresh</mat-icon></button>
        <mat-card class="bo9mat" *ngIf="arangoCounts"  appearance="outline">
            <h3>Nodes</h3>
            People: {{ arangoCounts.person }} <br>
            Events: {{ arangoCounts.event }} <br>
            Pearls: {{ arangoCounts.pearl }} <br>
            Products: {{ products.length }} <br>
            Sales: {{ arangoCounts.sale }} <br>
        </mat-card>
         
        <mat-card class="bo9mat" *ngIf="arangoCounts"  appearance="outline">
            <h3>Links</h3>
            Relationship: {{ arangoCounts.relation}} <br>
            Activity: {{ arangoCounts.activity}} <br>
            Purchase: {{ arangoCounts.purchase}} <br>
            Wisdom: {{ arangoCounts.wisdom}} <br>
        </mat-card>

        <mat-card class="bo9mat" *ngIf="dataSource.data"  appearance="outline">
          <h3>System Variables</h3>
          <table mat-table [dataSource]="dataSource" matSort>
  
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let element">  <span >{{element.name}} </span></td>
            </ng-container>
  
            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
              <td mat-cell *matCellDef="let element">  <span >{{element.value | json}}</span></td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </mat-card>
      </mat-card>
      <mat-card class="bo9mat" fxLayout="row wrap">
      <mat-card class="bo9mat" *ngIf="dataSourceFields.data"  appearance="outline">
        <h3>Fields</h3>
        <table mat-table [dataSource]="dataSourceFields" matSort>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element">  <span >{{element.title}} </span></td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
            <td mat-cell *matCellDef="let element">  <span >{{element.perstag}}</span></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsF"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsF;"></tr>
          </table>
    </mat-card>

    <mat-card class="bo9mat" *ngIf="dataSourceTags.data"  appearance="outline">
      <h3>Tags</h3>
      <table mat-table [dataSource]="dataSourceTags" matSort>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element">  <span >{{element.tag}} </span></td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
          <td mat-cell *matCellDef="let element">  <span >{{element.description}}</span></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsT"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsT;"></tr>
        </table>
  </mat-card>
  <mat-card class="bo9mat">
  <button mat-stroked-button (click)="resetBan()">Clear Ban List</button>
</mat-card>
    </mat-card>
     <!--  <app-messages></app-messages>-->

    </div>
