import {ErrorHandler, Injectable} from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { releaseLevel } from 'src/config/webapp.config';
import { Visit } from '../../../../bo9-shared/models/page/page.model';
import { Email } from '../../../../bo9-shared/models/person/email.model';
import { Person } from '../../../../bo9-shared/models/person/person.model';
import { ArangoService } from '../arangoservice/arango.service';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class CustomErrorHandlerService extends ErrorHandler {

    user: Person;

    errorCount = 0;

    lastError = '';

    constructor(
        private arangodb: ArangoService,
        private auth: AuthService ) {

        super();
        this.user = this.auth.getAuthUser();
    }

    handleError(error) {
        this.errorCount++
        let ignore = false;
        if (error && error.stack) {
            const err = JSON.stringify(error.stack);
            ignore = err.includes('NG0100') || err.includes('no elements in sequence');
            
        }

        if (this.user && this.errorCount < 3  && !ignore && releaseLevel === 'production') { // Cant report it if not logged in, or if not production
            setTimeout( () => {
                const param: Visit = {
                    page: '',
                    visitor: this.user? this.user._key: '0',
                    error: JSON.stringify(error.stack),
                    component: JSON.stringify(this.arangodb.lastVisitComponent)
                }
                if (this.lastError != param.error  ) {
                    this.lastError = param.error;
                    if (confirm ("Error reported "+param.error+" click 'Yes' to report his error to Support")) {
                        const e: Email = {
                            to: 'support@bodyof9.com',
                            subject: this.user.primaryEmail+" auto-report Error from "+param.component,
                            text: '',
                            html: param.error,
                            from: 'bonnie@bodyof9.com'
                          }
                  
                          this.arangodb.sendIssue(e)
                          .subscribe( () => {}); // must subscribe due to super
                    }
                    this.arangodb.recordVisit( param)
                    .subscribe ( res => {
                        this.errorCount = 0; // No error on the recordvisit
                        super.handleError(error);
                    })
                } else {
                    super.handleError(error);
                }
                // And any other code that should run only after n second
              }, 1000); // Max 1 per second
            

        } else {
            super.handleError(error);
        }
    }
}