import { NaturalNumber } from "../../../../bo9-shared/models/naturalNumber/naturalNumber.model";
import { PCount } from "../../../../bo9-shared/models/pearl/pearl.model";

export class PearlsMap {

   // pearlMap:  Map<string, number>[];
    pearlMap:  number[];
    currentNN = 0;
  
    constructor() {
     // this.pearlMap =  [new Map(),new Map(),new Map(),new Map(),new Map(),new Map(),new Map(),new Map(),new Map(),new Map()];
      this.pearlMap =  [0,0,0,0,0,0,0,0,0,0];
    }
  
  
    public add(pInfo: PCount) {
      if (pInfo.pnum === NaturalNumber.Any) this.currentNN = 9; // Index offset
      else if (pInfo.pnum === NaturalNumber.None || pInfo.pnum === NaturalNumber.OldNone) this.currentNN = 9; // Index offset
      else this.currentNN = +pInfo.pnum - 1;
  
      this.pearlMap[this.currentNN] +=pInfo.numnatnum;
      
    }
  }