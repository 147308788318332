<div [formGroup]="form">
	<h3 class=contact-form-title>Contact</h3>
	<mat-accordion [multi]="true">
		<mat-expansion-panel  [expanded]="checkDiffs('name_form', diffs)">
			<mat-expansion-panel-header>
				<mat-panel-title  *ngIf="name; else noName">
					<span class="header-name"><mat-icon>person</mat-icon></span> &nbsp; {{ name }}
				</mat-panel-title>
			</mat-expansion-panel-header>
			<ng-template #noName>
				<span class="header-name"><mat-icon>person</mat-icon></span> &nbsp; <em class="empty-title">No name listed</em>
			</ng-template>
			<name-form formControlName="names"></name-form>

			<div *ngIf="others && others.length>0; else noOtherNames">
				Other names;
				<ul>
					<li *ngFor="let on of others">{{ on.title }} {{on.first}} {{on.middle}} {{ on.last }}
				</ul>
			</div>
			<ng-template #noOtherNames>No other names recorded</ng-template>
		</mat-expansion-panel>

		<mat-expansion-panel [expanded]="checkDiffs('email_form', diffs)">
			<mat-expansion-panel-header>
				<mat-panel-title  *ngIf="email; else noEmail">
					<span class="header-name"><mat-icon>email</mat-icon></span> &nbsp; {{ email }}
				</mat-panel-title>
			</mat-expansion-panel-header>
			<ng-template #noEmail>
				<span class="header-name"><mat-icon>email</mat-icon></span> &nbsp; <em class="empty-title">No email listed</em>
			</ng-template>
			<email-form formControlName="email"></email-form>
		</mat-expansion-panel>

		<mat-expansion-panel [expanded]="checkDiffs('address_form', diffs)">
			<mat-expansion-panel-header>
				<mat-panel-title  *ngIf="existingAddress; else noAddress">
					<span class="header-name"><mat-icon>cottage</mat-icon></span> &nbsp; {{ existingAddress }}
				</mat-panel-title>
			</mat-expansion-panel-header>
			<ng-template #noAddress>
				<span class="header-name"><mat-icon>cottage</mat-icon></span> &nbsp; <em class="empty-title">No address listed</em>
			</ng-template>
			<address-form formControlName="address"></address-form>
		</mat-expansion-panel>

		<mat-expansion-panel [expanded]="checkDiffs('phone_form', diffs)">
			<mat-expansion-panel-header>
				<mat-panel-title  *ngIf="existingPhoneNumber; else noPhone">
					<span class="header-name"><mat-icon>phone</mat-icon></span> &nbsp; {{ existingPhoneNumber }}
				</mat-panel-title>
			</mat-expansion-panel-header>
			<ng-template #noPhone>
				<span class="header-name"><mat-icon>phone</mat-icon></span> &nbsp; <em class="empty-title">No phone listed</em>
			</ng-template>
			<phone-form formControlName="phone" ></phone-form>
		</mat-expansion-panel>
		<mat-expansion-panel [expanded]="checkDiffs('social_form', diffs)">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<span class="header-name"><mat-icon>chat</mat-icon></span>  &nbsp; Social Media
				</mat-panel-title>
			</mat-expansion-panel-header>
			<social-form formControlName="socialMedia" ></social-form>
		</mat-expansion-panel>
	</mat-accordion>
