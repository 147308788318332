<my-slide-panel [activePane]="currentPane">

  <div centerPane>
    <!--
  <mat-card class="bo9mat">
    <form novalidate >
      <div class="">
        
        Filename to upload from /tmp to s3 videos:

         <input type="text" [(ngModel)]="fileName" name="fileName"/>
        <button  [disabled]="disableBtn" mat-stroked-button name="search" (click)="onClickCopyToS3()">Upload to S3 <mat-icon>publish</mat-icon></button>

      </div>
    </form> 

  </mat-card>-->
  <mat-card class="bo9mat">

      <div class="">
        
       Generate Attendance pdf
        <button  [disabled]="disableBtn" mat-stroked-button name="search" (click)="onClickGenCert()">Gen Cert<mat-icon>publish</mat-icon></button>

      </div>


  </mat-card>
  <mat-card class="bo9mat">


     <!--<button mat-stroked-button (click)="onRefreshClick()"   [ngClass]="{'green' : togglec, 'red': !togglec}">Clear Filter<mat-icon>clear</mat-icon></button>--> 
      <button mat-stroked-button (click)="onDupEmail()"   [ngClass]="{'green' : togglen, 'red': !togglen}">Filter for Duplicate Emails<mat-icon>mail_outline</mat-icon></button>
      <button mat-stroked-button (click)="onDupName()"   [ngClass]="{'green' : togglem, 'red': !togglem}">Filter for Duplicate Names<mat-icon>perm_identity</mat-icon></button>
      <form novalidate >
      <div class="">
  
          <button  [disabled]="disableBtn" mat-stroked-button name="search" (click)="onClickSearch()">Search <mat-icon>search</mat-icon></button>

      </div>
    </form> 
      <mat-progress-spinner *ngIf="loading" mode="indeterminate"> </mat-progress-spinner>

        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef> Edit </th>
            <td mat-cell *matCellDef="let element">
              <button type="button" (click)="onClickView(element.realPerson)" matTooltip="Edit Contact"> <mat-icon>manage_accounts</mat-icon></button>
     
            </td>
          </ng-container>
          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef> Merge </th>
            <td mat-cell *matCellDef="let element">
              <button type="button" (click)="onMergeClick(element.realPerson)" matTooltip="Merge"> <mat-icon>merge_type</mat-icon></button>
            </td>
          </ng-container>
          <ng-container matColumnDef="dbid">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> DBId </th>
            <td mat-cell *matCellDef="let element">   {{element._key}}</td>
          </ng-container>
          <ng-container matColumnDef="actCid">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ActID </th>
            <td mat-cell *matCellDef="let element">   <span *ngIf="element.activeCampaignContact; else noactcamp"><a href="https://bodyof9.activehosted.com/app/contacts/{{element.activeCampaignContact._key}}" target="_blank">{{element.activeCampaignContact._key}}</a></span>
            <ng-template #noactcamp>NONE</ng-template>
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.firstName}}  {{element.lastName}}</td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.realPerson.contact.DNC" > <mat-icon>do_not_touch</mat-icon>{{element.email}}  <button mat-button [cdkCopyToClipboard]="element.email"><mat-icon >content_copy</mat-icon></button></span>
              <span *ngIf="!element.realPerson.contact.DNC" ><button mat-button [cdkCopyToClipboard]="element.email"><mat-icon >content_copy</mat-icon></button>{{element.email}}   </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="naturalNumber" >
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Nat. Num. (DB/Act) </th>
            <td mat-cell  *matCellDef="let element" [className]="(element.naturalNumber!=element.activeCampaignContact?.naturalNumber)? 'nnissue':'centered'"> {{element.naturalNumber}} / {{element.activeCampaignContact?.naturalNumber}}</td>
          </ng-container>

          <ng-container matColumnDef="dateIdentified">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> When </th>
            <td mat-cell *matCellDef="let element"> {{element.identified}} </td>
          </ng-container>

          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef > Delete </th>
            <td mat-cell *matCellDef="let element"><button type="button" (click)="onClickDelete(element)"> <mat-icon>delete_forever</mat-icon></button></td>
          </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
          </table>
          <mat-paginator   [pageSize]="pageSize"  [pageSizeOptions]="[5, 10, 15, 20, 50, 100]" (page)="handlePage($event)"></mat-paginator>  
        </mat-card>
    </div>

    <div rightPane> 
      <button mat-stroked-button (click)="onCloseClick()">Back</button><br>
      <span *ngIf="operation == 'View'" >  <person-form [person]="currentP$ | async"></person-form></span>
    </div>
  </my-slide-panel>


  

