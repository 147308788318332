<h2>Select Pre-purchased Identification to Assign to {{ data.form.value.first }}</h2>
<div class="invite-modal">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Program </th>
      <td mat-cell *matCellDef="let element"> {{element.programName}}  </td>
    </ng-container>
    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Purchased </th>
      <td mat-cell *matCellDef="let element"> {{element.quantity}}  </td>
    </ng-container>
    <ng-container matColumnDef="used">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Used </th>
      <td mat-cell *matCellDef="let element"> {{element.used}}  </td>
    </ng-container>
    <ng-container matColumnDef="remaining">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Available </th>
      <td mat-cell *matCellDef="let element"> {{element.available}}  </td>
    </ng-container>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Select </th>
      <td mat-cell *matCellDef="let element"> 
        <span *ngIf="element.available > 0"> <button mat-button type="button" (click)="onSelect(element)"> <mat-icon>check</mat-icon> </button></span>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
      <button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Submit</button>


      <footer>
        <button mat-stroked-button (click)="close()">
          Close
        </button>
      </footer>
</div>
