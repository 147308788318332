<div class="website-container">
<div class="login">
    <span class="login-button"><button mat-button routerLink='/login'>Login</button></span>
    </div>
    <h1>Please select the button above to login into your Body of 9 Professional Guild dashboard.
    </h1>
    <h2> If you'd like to learn more about what the Body of 9 Professional Guild Program offers, select the button below.</h2>
    
    <a class="btn" href="https://bodyof9.com/guild-professional-program">Learn More</a>

    
    </div>
    
    
    