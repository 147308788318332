<mat-card class="bo9mat" >
  <h2>Pearls Count</h2> 
  <div>
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="relationship">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Relationships </th>
      <td mat-cell *matCellDef="let element" >{{element.relationship}} </td>
    </ng-container>

    <ng-container matColumnDef="one">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> NN 1 </th>
      <td mat-cell *matCellDef="let element" >{{element.one}}</td>
    </ng-container>
    <ng-container matColumnDef="two">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> NN 2 </th>
      <td mat-cell *matCellDef="let element" >{{element.two}}</td>
    </ng-container>
    <ng-container matColumnDef="three">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> NN 3 </th>
      <td mat-cell *matCellDef="let element" >{{element.three}} </td>
    </ng-container>
    <ng-container matColumnDef="four">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> NN 4 </th>
      <td mat-cell *matCellDef="let element" >{{element.four}} </td>
    </ng-container>
    <ng-container matColumnDef="five">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> NN 5 </th>
      <td mat-cell *matCellDef="let element" >{{element.five}} </td>
    </ng-container>
    <ng-container matColumnDef="six">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> NN 6 </th>
      <td mat-cell *matCellDef="let element" >{{element.six}} </td>
    </ng-container>
    <ng-container matColumnDef="seven">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> NN 7 </th>
      <td mat-cell *matCellDef="let element" >{{element.seven}}</td>
    </ng-container>
    <ng-container matColumnDef="eight">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> NN 8 </th>
      <td mat-cell *matCellDef="let element" >{{element.eight}} </td>
    </ng-container>
    <ng-container matColumnDef="nine">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> NN 9 </th>
      <td mat-cell *matCellDef="let element" >{{element.nine}}  </td>
    </ng-container>
    <ng-container matColumnDef="any">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Any NN </th>
      <td mat-cell *matCellDef="let element" >{{element.anyc}}   </td>
    </ng-container>
    <ng-container matColumnDef="totals">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Totals </th>
      <td mat-cell *matCellDef="let element" >{{element.totals}}  </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
Total: {{ total }}
</div>
</mat-card>