import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ScriptService } from "../../../services/scriptservice/script.service";
import { ActivatedRoute, Route } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { EventParams } from '../../../../../bo9-shared/models/event/event.model';

const SCRIPT_PATH = "https://assets.calendly.com/assets/external/widget.js";


@Component({
  selector: 'app-web-contact',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class WebScheduleComponent implements OnInit {
  @ViewChild('container') container: ElementRef;

  showSpinner = false;
  webpage: any;
  eid = '';
  constructor(
    private scriptService: ScriptService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private arangoService: ArangoService
    ) {
      this.route.params.subscribe( async (params) => {
       
        console.log(params); // log the entire params object
        console.log(" Params "+params['id']); // log the value of id
  
        if (params['id']) {
          this.eid= params['id'];
         const result = await firstValueFrom(this.arangoService.getEventParams(this.eid),{ defaultValue: { content: []}}); // Can only be from wordpress event

         const scriptElement = this.scriptService.loadJsScript(this.renderer, SCRIPT_PATH);
          scriptElement.onload = () => {
          console.log(' Script loaded');
         
          console.log('EP '+JSON.stringify(result));
          const epms: EventParams = result.content[0];
          let firstName = '';
          let lastName='';
          let email = '';
          let id = '';
          if (epms) {
            firstName = epms.first;
            lastName = epms.last;
            email = epms.email;
            id = epms.id
          }
          window.Calendly.initInlineWidget({
            url: 'https://calendly.com/body-of-9/individual-identification',
            parentElement: this.container.nativeElement,
            prefill: {
                name: firstName +' '+lastName,
                email: email
                } ,
            utm: {
                utmSource: "PrePaid",
                utmTerm: id
            }      
            });
          }
          scriptElement.onerror = () => {
            console.log('Could not load the  Script!');
          }
              }
         
      })
    }

  ngOnInit() {
    
  }
}
