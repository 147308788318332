<div class="relation-modal">
	<mat-card class="bo9mat">
		<mat-card-title>
			Delete Group<br>
		</mat-card-title>

		<mat-card-content>
			<div>
				Do you really want to delete this Group ? <br> <strong>"{{ data.group.group.groupName }}"</strong>

			</div>
		</mat-card-content>
	</mat-card>

	<footer>
		<button mat-stroked-button (click)="close()">NO!</button>
		 &nbsp;  &nbsp; &nbsp;  &nbsp;
		<button class="btn-save" mat-flat-button (click)="deleteSelectGroup(data.group)">Delete Group</button>
	</footer>
</div>
