import { BO9UUID } from "../UUID.model";

import { DateTimeStringType, DateStringType } from "../BO9_base/BO9_base.model";
import { BO9Contact } from "../contact/contact.model";
import { Meta, updateMeta } from "../BO9_base/meta.model";
import { BO9Address, BO9AddressType } from "../contact/address.model";
import { Person } from '../person/person.model';
import { Difference} from '../person/differences.model';

import { Purchase , Order} from '../purchase/purchase.model';
import { CalendlyEvent, CalendlyEventInfo, CalendlyEventPayload, CalendlyQA } from '../calendly/calendlyEvent.model';
import { NaturalNumber, NaturalNumberType, NaturalNumberTypes, stringToNaturalNumber } from '../naturalNumber/naturalNumber.model';
import { EspressoEvent, makeCategoryList, WPEvent } from "./event9e.model";
import { RegisterHours } from "../institute/credits.model";
import { createUUID } from "../../uuid.service"

export interface AttendedLog {
    attended: number,
    registered: number,
    notAttended: number,
    type: string
}

export enum EventType {
    None,
    Wordpress,
    Calendly
}

export enum EventColor {
    upcoming= "green",
    attended="lisghtseagreen",
    missed="red",
    registered = "blue",
    past = "gray"
}

export class EventCounts{
    eventCounts: AttendedLog[];

    constructor() {
        this.eventCounts = [];
     }

     public getLogByType(type: string){
         for (const l of this.eventCounts) {
             if (l.type == type) {
                 return l;
             }
         }
         const e:AttendedLog = {
             registered:0,
             attended:0,
             notAttended:0,
             type: type
         }
         return (e);
     }

    public setEventCount(event: Event) {

        let found = false;
        for (const ec of this.eventCounts) {

            if (event.categories && event.categories.includes( ec.type)) {
                if (event.event_status == 'Open') {
                    ec.registered ++;
                } else if (event.event_status == 'Closed') {
                    if (event.attended) {
                        ec.attended ++;
                    } else {
                        ec.notAttended++;
                    }
                    
                }
                found = true;
            } 
        }
        if (!found) {
            if (event.categories){
                for (const cat of event.categories) {
                    const newEC: AttendedLog = {
                        notAttended: 0,
                        attended: 0,
                        registered: 0,
                        type: cat
                    }
                    if (event.event_status == 'Open') {
                        newEC.registered ++;
                    } else if (event.event_status == 'Closed') {
                        if (event.attended) {
                            newEC.attended ++;
                        } else {
                            newEC.notAttended++;
                        }
                        
                    }
                    this.eventCounts.push(newEC);
                }
            }

        }

        return 
    }

    GetEventCounts(): AttendedLog[] {
        return this.eventCounts
    }
}

export interface PageMonitor {
    personId: BO9UUID;
    routeName: string;
    idOfPage: string;
    otherPageParams: string;
    created: Date;
}

export interface NamedContact {
    updatedAt: DateTimeStringType;
    contactRefName: string; 
    contact: BO9Contact;
}

export interface EventParams {
    id: string;
    first: string;
    last: string;
    email: string;
    creatorKey: string;
    link: string;
    assignedDate: string;
    usedDate?: string;
    unassignedDate?: string;
    order: Order;
    dealId: string;
    eventId?: string;
}

export interface EventPrice{
    id?   : number,
    event_id   : string ;
    price_type : string; // General Admission
    event_cost : string;
    surcharge : string ; // 0.00
    surcharge_type: string; // flat_rate
    member_price_type : string; // Members Admission
    member_price: string;
    max_qty : number; //0
    max_qty_members : number; // 0
}

export interface Event {
    _key: BO9UUID;
    name: string;
    description: string;
    registration_end?: string;
    code: string,
    startDate: DateTimeStringType;
    endDate: DateTimeStringType;
    startTime: string;
    endTime?: string;
    address?: BO9AddressType;
    generalContact?: BO9Contact;
    contacts: NamedContact[];
    identifiers: string[],
    recording_link: string;
    categories: string[];
    linkUrl: string;
    attendees: number;
    listAttendees?: EventAttendee[],
    tagId: string;
    event_status?: string;
    prior_status?: string;
    meta?: Meta;
    source: string;
    notes?: string;
    recordingStartedAt?: string;
    recordingEndedAt?: string;
    attended?: boolean;
    registered?: boolean;
    questions?: CalendlyQA;
    closedDate?: string;
    timezone: string;
    reg_limit?: number;
    color?: string;
    event_meta?: string;
    event_meta_object?: any;
    eventCode?: string;
    creatorId?: number;
    location?: BO9Address;
    registerHours?: RegisterHours;
    startTimeUTC?: Date;
    editable?: boolean;
    user_email?: string;
}

export interface EventAttendee {
    _key: BO9UUID;
    person_id: string;
    contact: BO9Contact;
    person?: Person;
    newPerson?: Person;
    difference? :Difference[];
    diffAccepted?: boolean;
    mailCampaignID?: number;
    tagged?: boolean;
    event_id: string;
    paid: string;
    transaction_details?: any;
    attended: number;
    email: string;
    phone: string;
    first: string;
    dbFirst?: string;
    last: string;
    dbLast?: string;
    street1: string;
    street2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    gender: string;
    start_date: string;
    end_date: string;
    event_time: string;
    end_time: string;
    date_created: string;
    nnIssue?: boolean;
    nn?: string;
    ass?: string;
    sourceType?: NaturalNumberTypes;
    idBy?: any;
    assBy?: any;
    idAt?: string;
    DNC?: boolean;
    dateIdentified?: string,
    notes?: string;
    guild?: string;
}

export interface ShortAttendee {
    _key: string,
    event_id: string,
    person_id: string,
    first : string,
    last : string,
    email : string,
    paid: string,
    transactions_details?: any,
    attended: boolean,
    phone: string,
    street1: string,
    street2 : string,
    city : string,
    state : string,
    zip : string,
    country: string,
    start_date : string,
    end_date : string,
    event_time : string,
    end_time : string,
    date_created : string   
}

export function setIdentifiedAttendeeNaturalNumber(attendee: EventAttendee, p: Person) : Person {

    const newPerson: Person = p;
    if (attendee.sourceType) {
        if (attendee.sourceType == NaturalNumberTypes.Identified) {
            newPerson.naturalNumbers.identified = {
                setAt: attendee.idAt ? attendee.idAt : '',
                setBy: {
                    name: attendee.idBy ? attendee.idBy : 'Susan Fisher'
                },
                naturalNumber: attendee.nn ? stringToNaturalNumber(attendee.nn) : NaturalNumber.None,
                dateSet: attendee.dateIdentified ? attendee.dateIdentified : attendee.date_created
            };
        } else if (attendee.sourceType == NaturalNumberTypes.Asserted) {
            newPerson.naturalNumbers.asserted = {
                setAt: attendee.idAt ? attendee.idAt : '',
                setBy: {
                    name: attendee.idBy ? attendee.idBy : 'Susan Fisher'
                },
                naturalNumber: attendee.nn ? stringToNaturalNumber(attendee.nn) : NaturalNumber.None,
                dateSet: attendee.dateIdentified ? attendee.dateIdentified : attendee.date_created
            };
        }
        }
    return newPerson;
}

export function findAttendedStage(categories: string[], systemVariable: any): string {
   
    if (categories) {
        const destinations: {
          eventCategory: string,
          group: string,
          stageName: string,
          stage: string
        }[] = systemVariable.getSystemVariableArray('Event Attended Mapping');
        for (const dest of destinations) {
          for (const cat of categories) {
              if (cat) {
                if (cat.toLowerCase() === dest.eventCategory.toLowerCase()) {
                    return(dest.stage);
                }
              } else if (cat.toLowerCase() === dest.eventCategory.toLowerCase()) {
                return(dest.stage);
            }
          }
        }
      } 
    return ('');
}

export function attendeeUnique(attendee: ShortAttendee | EventAttendee, attendees : ShortAttendee[] | EventAttendee[]) : boolean {

    if (attendee) {
        for (const att of attendees) {
            if (att.email && attendee.email && att.email.toLowerCase() === attendee.email.toLowerCase() ) {
                return false;
            }
            if (att.person_id && attendee.person_id != '' && att.person_id === attendee.person_id ) {
                return false;
            }
            /*
            // Removed per Susan 04/06/22
            if (att.first && att.last && attendee.first && attendee.last && 
                (att.first.toLowerCase() === attendee.first.toLowerCase()) && (att.last.toLowerCase() === attendee.last.toLowerCase()) ) {
                return false;
            }
            */
        }
    }

    return true;
}

export function attendeeEmailUnique(email: string, attendees : ShortAttendee[] | EventAttendee[]) : boolean {

    if (email) {
        for (const att of attendees) {
            if (att.email && email && att.email.toLowerCase() === email.toLowerCase() ) {
                return false;
            }
        }
    }

    return true;
}

export function eventInList(key: string, EList: Event[]): boolean {
    
    for (const event of EList) {
        if (event._key === key) {
            return true
        }
    }

    return false
}


let eventMap = new Map();

export function mkMTEvent(): Event{
    return( {
        _key: '',
        name: '',
        code: '',
        description: '',
        startDate: '',
        startTime: '',
        endDate: '',
        contacts: [],
        recording_link: '',
        categories: [],
        attendees: 0,
        identifiers: [],
        linkUrl:'',
        tagId: '',
        source: '',
        timezone: 'MST'
    })

}

export function saveEvent(e:Event) {
    if (!eventMap.get(e._key)) {
        eventMap.set(e._key, e);
    }
}

export function eventAlreadyListed(arr:any, val:any) : boolean {
    return arr.some(function(arrVal:any) {
        if (val === arrVal._key) return true;
        else return false;
    });
  }

export function orderFromEvent(e: Event, a: EventAttendee, pkey: string): Order {

    const ep: Order = {
        orderKey: '',
        externalid: 'EE'+e._key+'-'+a._key, // Event and attendee key
        orderId: 'EE'+e._key+'-'+a._key,
        source: 1, // Fire automation
        email: a.email,
        orderProducts: [{
            externalid: a.start_date,
            name: e.name,
            price: a.paid.replace('.',''),
            quantity: 1,
            category: e.categories.join(','),
            sku: 'EEORDER',
            description: e.description
        }],
        orderUrl: e.linkUrl,
        currency: 'USD',
        orderNumber: e.code,
        connectionid: '3',
        customerid: pkey, // Customer ID ie person key
        totalPrice: a.paid.replace('.',''),
        externalCreatedDate: a.date_created.replace('Z','')
    }

    if (ep.totalPrice === "000") ep.totalPrice = "010";

    console.log('OrderFrom event '+JSON.stringify(ep));
    return ep;

}



export function makeEventFromEspresso( ce: WPEvent) : Event {

    return ({
        _key: ce.events[0]._key,
        name: ce.events[0].name+ ' Wordpress Event',
        description: ce.events[0].description,
        startDate: ce.events[0].startDate,
        startTime: ce.events[0].startTime,
        endDate: ce.events[0].endDate,
        contacts: [],
        recording_link: '',
        categories: makeCategoryList(ce.events[0]._key, ce.categories),
        attendees: ce.attendees[0].attendees,
        identifiers: ["Susan Fisher", "Martin Fisher"],
        linkUrl:ce.events[0].linkUrl,
        tagId: '',
        code: '',
        event_status: '?',
        source: 'wordpress',
        timezone: ce.events[0].timezone,
        meta: updateMeta()
    })
}

export interface Transaction_Detail_Data {
    id:string,
    object:string,
    amount:number,
    amount_captured:number,
    amount_refunded:number,
    application:null,
    application_fee:null,
    application_fee_amount:null,
    balance_transaction:string,
    billing_details:{
        address:{
            city:string,
            country:string,
            line1:null,
            line2:null,
            postal_code:string,
            state:string
        },
        email:string,
        name:string,
        phone:null
        },
    calculated_statement_descriptor:string
    captured:boolean,
    created:number,
    currency:string,
    customer:null,
    description:string,
    destination:null,
    dispute:null,
    disputed:boolean,
    failure_balance_transaction:null,
    failure_code:null,
    failure_message:null,
    fraud_details:{
    },
    invoice:null,
    livemode:true,
    metadata:{
    },
    on_behalf_of:null,
    order:null,
    outcome:{
        network_status:string,
        reason:null,
        risk_level:string,
        seller_message:string,
        type:string
    },
    paid:boolean,
    payment_intent:string,
    payment_method:string,
    payment_method_details:{
        card:{
            amount_authorized:number,
            brand:string,
            checks:{
                address_line1_check:null,
                address_postal_code_check:string,
                cvc_check:string
         },
        country:string,
        exp_month:number,
        exp_year:number,
        extended_authorization:{
            status:string
        },
    fingerprint:string,
    funding:string,
    incremental_authorization:{
        status:string
    },
    installments:null,
    last4:number,
    mandate:null,
    multicapture:{
    status:string
    },
    network:string,
    network_token:{
    used:boolean
    },
    overcapture:{
    maximum_amount_capturable:number,
    status:string
    },
    three_d_secure:null,
    wallet:{
    dynamic_last4:null,
    link:{
    },
    type:string
    }
    },
    type:string
    },
    radar_options:{
    },
    receipt_email:null,
    receipt_number:null,
    receipt_url:string,
    refunded:false,
    refunds:{
    object:string,
    data:{
    },
    has_more:false,
    total_count:0,
    url:string
    },
    review:null,
    shipping:null,
    source:null,
    source_transfer:null,
    statement_descriptor:null,
    statement_descriptor_suffix:null,
    status:string,
    transfer_data:null,
    transfer_group:null
    }

export interface Transaction_Detail {
    id: string,
    object: string,
    amount:number,
    amount_capturable: number,
    amount_details:{
        tip:{
        }
    },
    amount_received:number,
    application:null,
    application_fee_amount:null,
    automatic_payment_methods:null,
    canceled_at:null,
    cancellation_reason:null,
    capture_method: string, // set here
    charges: {
        object: string,
        data: Transaction_Detail_Data[];
        has_more:boolean,
        total_count:number,
        url:string
    },
    client_secret:string,
    confirmation_method:string,
    created:number,
    currency:string,
    customer:null,
    description:string,
    invoice:null,
    last_payment_error:null,
    latest_charge:string,
    livemode:true,
    metadata:{
    },
    next_action:null,
    on_behalf_of:null,
    payment_method:string,
    payment_method_configuration_details:null,
    payment_method_options:{
    card:{
    installments:null,
    mandate_options:null,
    network:null,
    request_three_d_secure:string
    }
    },
    payment_method_types:[
    string
    ],
    processing:null,
    receipt_email:null,
    review:null,
    setup_future_usage:null,
    shipping:null,
    source:null,
    statement_descriptor:null,
    statement_descriptor_suffix:null,
    status:string,
    transfer_data:null,
    transfer_group:null
    }
    
export function Set_Transaction(desc: string, paid: string, td: Transaction_Detail): Transaction_Detail {
    
    if (paid) {
        const nums = paid.toString().replace(".", "");
        td.description = desc;
        td.amount = +nums;
    }

    return td;
}

export function MTTransaction_Detail(): Transaction_Detail {
    return ({
        id: createUUID(),
        object: "payment_intent",
        amount: 0,
        amount_capturable: 0,
        amount_details: {
            tip: {
            }
        },
        amount_received: 0,
        application: null,
        application_fee_amount: null,
        automatic_payment_methods: null,
        canceled_at: null,
        cancellation_reason: null,
        capture_method: "",
        charges: {
            object: "list",
            data:
                [],
            has_more: false,
            total_count: 0,
            url: ""
        },
        client_secret: "",
        confirmation_method: "none",
        created: (new Date()).getSeconds(),
        currency: "usd",
        customer: null,
        description: "",
        invoice: null,
        last_payment_error: null,
        latest_charge: "",
        livemode: true,
        metadata: {
        },
        next_action: null,
        on_behalf_of: null,
        payment_method: "",
        payment_method_configuration_details: null,
        payment_method_options: {
            card: {
                installments: null,
                mandate_options: null,
                network: null,
                request_three_d_secure: "automatic"
            }
        },
        payment_method_types: ["none"
        ],
        processing: null,
        receipt_email: null,
        review: null,
        setup_future_usage: null,
        shipping: null,
        source: null,
        statement_descriptor: null,
        statement_descriptor_suffix: null,
        status: "succeeded",
        transfer_data: null,
        transfer_group: null
    });
}