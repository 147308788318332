<div>
	<mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="30" class='spin' style="float: left"> </mat-progress-spinner>
	<h2>Body of 9 - Dashboard</h2>

	<mat-tab-group dynamicHeight class="profile-tabs profile-form" mat-align-tabs="start" data-css='partner'>
		<mat-tab *ngIf="!isMobile()" class="calendar-tab" data-css='tabset1'>
			<ng-template mat-tab-label>
				<mat-icon>calendar_month</mat-icon> Calendar
			</ng-template>
			<ng-template matTabContent>
				<event-calendar-display [person]="person" [events]="allEvents" (notifyDateChange)="datesHandler($event)"></event-calendar-display>
			</ng-template>
		</mat-tab>



<!--
		<mat-tab class="client-tab">
	 		<ng-template mat-tab-label>
		 		<mat-icon>person_pin</mat-icon> Clients
	 		</ng-template>
	 		<clientmember-list [person]="person" [inputGroup]="clientGroup$ | async" [client]="true" [packages]="packages"></clientmember-list>
		</mat-tab>
-->
		<mat-tab class="resources-tab">
			<ng-template mat-tab-label>
				<mat-icon>tips_and_updates</mat-icon> Resources
			</ng-template>
			<ng-template matTabContent>
				<partner-resource-list [person]="person"></partner-resource-list>
			</ng-template>
		</mat-tab>
		<mat-tab class="directory_tab">
			<ng-template mat-tab-label>
				<mat-icon>contacts</mat-icon> Directory
			</ng-template>
			<ng-template matTabContent>
				<directory-list> </directory-list>
			</ng-template>
		</mat-tab>

		<mat-tab class="events-tab">
			<ng-template mat-tab-label>
				<mat-icon>event_available</mat-icon> Events
			</ng-template>
			<ng-template matTabContent>
				<calendly-bookings [person]="person"></calendly-bookings>
				<div>
					<personal-events-list [eventList]="registeredEvents$ | async" [person]="person" [registered]="'true'"  ></personal-events-list>
				</div>
				<div>
					<upcoming-events-list [eventList]="notMyEvents$ | async" [person]="person" (notifyEvents)="datesHandler($event)" ></upcoming-events-list>
				</div>
				<div>
					<personal-events-list [eventList]="attendedEvents$ | async" [person]="person" [attended]="'true'" (notifyParent)="eventCountHandler($event)" ></personal-events-list>
				</div>
			</ng-template>
		</mat-tab>

		<mat-tab class="pre-paid-tab">
			<ng-template mat-tab-label>
				<mat-icon>fingerprint</mat-icon> Identification
			</ng-template>
			<ng-template matTabContent>
				<app-identified [person]="person" 
				(notifyParent)="attendeeSubmittedHandler($event)"
				(mergePerson)="mergePerson($event)">
			  </app-identified>
			</ng-template>
		</mat-tab>
		<mat-tab class="pre-paid-tab">
			<ng-template mat-tab-label>
				<mat-icon>redeem</mat-icon> Referrals
			</ng-template>
			<ng-template matTabContent>
				<calendly-bookings [person]="person" ></calendly-bookings>
				<clientmember-list [person]="person" [inputGroup]="prepaidGroup"  [eventCounts]="eventCounts" [client]="false" [canMEA]="false" [packages]="packages" [prepaidCount]="identificationsPurchased"></clientmember-list>
			</ng-template>
		</mat-tab>

		<!--

		<mat-tab class="programs-tab">
			<ng-template mat-tab-label>
				<mat-icon>school</mat-icon> Training
			</ng-template>
			<ng-template matTabContent>
				<partner-form [person]="person" [eventCounts]="eventCounts"></partner-form>
			</ng-template>
		</mat-tab>-->
<!-- 
		<mat-tab class="programs-tab">
			<ng-template mat-tab-label>
				<mat-icon>loyalty</mat-icon> Certificates
			</ng-template>
			<ng-template matTabContent>
				<hours-form [person]="person"></hours-form>
			</ng-template>
		</mat-tab>
-->
		<mat-tab *ngIf="canMEA" class="mea-tab">
			<ng-template mat-tab-label>
				<mat-icon>person_pin</mat-icon> M.E.A
			</ng-template>
			<ng-template matTabContent>
				<h3>M.E.A.s</h3>
				<mea-list [person]="person" [packages]="packages"></mea-list>
			</ng-template>
		</mat-tab>

	</mat-tab-group>

</div>
