import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { networkConfig } from 'src/config/webapp.config';
import { Relation, FormRelation } from '../../../../bo9-shared/models/person/relation.model';
import { BO9EVENTS } from '../../../../bo9-shared/models/BO9_base/BO9_base.model';

import { HttpClient } from '@angular/common/http';
import { UsernameValidator } from 'src/validators/username.validator';
import { WooCustomer } from '../../../../bo9-shared/models/woocommerce/woocustomer.model';
import { WooDownload } from '../../../../bo9-shared/models/woocommerce/woodownload.model';
import { WooAPI3Order, WooOrder } from '../../../../bo9-shared/models/woocommerce/wooorder.model';
@Injectable()
export class WooCommerceService {
    

    customers: WooCustomer[]=[];;
    downloads: WooDownload[];
    orders: WooOrder[];

    constructor (
        private http: HttpClient
    ) {
        this.customers = [];
    }

    public setCustomers(cs: WooCustomer[]) {
        this.customers = cs;
    } 


    public setDownloads(cs: WooDownload[]) {
        this.downloads = cs;
    } 

    public searchCustomers(e: string, list?: WooCustomer[]): Promise<WooCustomer[]> {
        return new Promise( async (resolve) => {
            const found: WooCustomer[] = [];
            if (this.customers && this.customers.length ===0) {
                const res = await firstValueFrom(this.loadCustomers());
                this.customers = res.content;
            }
            const lookup = list? list: this.customers; // Can be a subsetted list
            for (const c of lookup) {
                if (c.email.toLowerCase().includes(e.toLowerCase()) ||
                    c.first_name.toLowerCase().includes(e.toLowerCase()) ||
                       c.last_name.toLowerCase().includes(e.toLowerCase()) ||
                          c.username.toLowerCase().includes(e.toLowerCase())) {
                              found.push(c);
                          }
            }
            resolve( found);
        });
       
    }
    public searchOrders(e: string):  Observable<any>  {
        console.log('search orders '+e);
        return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WOOORDERS+'?search='+e);
    }


    public searchDownloads(orderid: number, list?: WooDownload[]): WooDownload[] {
        const found: WooDownload[] = [];
        const lookup = list? list: this.downloads; // Can be a subsetted list
        for (const c of lookup) {
            if (c.order_id == orderid) {
                          found.push(c);
                      }
        }
        return  found;
    }

    public loadCustomers():  Observable<any>  {
        console.log('load customers ');
        return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WOOCUSTOMERS);
    }

    public loadOrders():  Observable<any>  {
        console.log('load customers ');
        return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WOOORDERS);
    }

    public getCustomersFromDB(email? : string):  Observable<any>  {
        console.log('load getCustomersFromDB ');
        let ext= '';
        if (email) {
            ext='?email='+email;
        }
        return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WOOCUSTOMERSFROMDB+ext);
    }

    public getOrdersFromDB(cust_id? : string):  Observable<any>  {
        console.log('load getCustomersFromDB ');
        let ext= '';
        if (cust_id) {
            ext='?customer_id='+cust_id;
        }
        return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WOOORDERSFROMDB+ext);
    }

    public searchDownloaders(text: string):  Observable<any>  {
        console.log('load customers ');
        return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WOODOWNLOADERS+'?search='+text);
    }

    public loadDownload(custNum: number):  Observable<any>  {
        console.log('get downloads ');
        return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WOODOWNLOADS+'?customer='+custNum);
    }

    public loadOrdersByCustomer(custNum: number):  Observable<any>  {
        console.log('get orders ');
        return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WOOORDERS+'?customer='+custNum);
    }

    public loadOrdersById(oid: number):  Observable<any>  {
        console.log('get orders ');
        return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WOOORDERS+'?order_id='+oid);
    }

    public loadOrderCount():  Observable<any>  {
        console.log('get order count ');
        return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WOOORDERCOUNT);
    }

    public createCustomer(c: WooCustomer):  Observable<any>  {
        console.log('create customer '+JSON.stringify(c));
        const param ={
            customer: c
        }
        return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WOOCUSTOMERS, param);
    }

    public createOrder(c: WooAPI3Order):  Observable<any>  {
        console.log('create order '+JSON.stringify(c));
        const param ={
            order: c
        }
        return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WOOORDERS, param);
    }

    public completeOrder(c: string):  Observable<any>  {
        console.log('complete order '+JSON.stringify(c));
        const param ={
            order_id: c
        }
        return this.http.put(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WOOORDERS, param);
    }


}
