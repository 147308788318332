<button mat-button class="btn" (click)="selectPerson()" >Fill From Select</button>

<hr>
<form [formGroup]="AssignForm" (ngSubmit)="submitForm()" class="relate-form">
  <div>


    <mat-form-field  appearance="outline" >
      <mat-label>Email</mat-label>
      <input matInput 
        formControlName="email"
        id="email"
      />

    </mat-form-field>
 
    <mat-form-field  appearance="outline" >
      <mat-label>Quantity</mat-label>
      <input matInput 
        formControlName="quantity"
        id="quantity"
      />

    </mat-form-field>

  </div>
  <button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Submit</button>

</form>