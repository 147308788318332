import { Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

import { LoginService } from 'src/services/loginservice/login.service';
import { RegisterCredentials } from '../../services/loginservice/login.service';
import { InnerMessage } from '../../../../bo9-shared/models/innermessage.model';
import { BO9Actions } from '../../../../bo9-shared/models/BO9_base/BO9_base.model';
import { Person } from '../../../../bo9-shared/models/person/person.model';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth/auth.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {  BO9AppToken } from 'src/config/webapp.config';
import { AuthInterceptor } from 'src/services/auth/authinterceptor';

import { encodeB64 } from '../../../../bo9-shared/helpers/numbers.helper'
import { MatDialog } from '@angular/material/dialog';
import { VideoPlayerDialogComponent } from 'src/displayComponents/dialogs/videoPlayerDialog/videoPlayerDialog.component';
import { Subscription, firstValueFrom } from 'rxjs';
import { userIsAdmin, userIsApprentice, userIsJourneyman } from '../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Agreement, mergeAgreements } from '../../../../bo9-shared/models/person/agreements.model';
import { AgreementService } from 'src/services/agreementservice/agreement.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  creds: RegisterCredentials = {
    email: '',
    password: '',
    lastLogin: ''
  };

  showSpinner = false;

  loginvisible = true;
  loginmessage = '';

  subscriptions: Subscription[] = [];
  constructor(
    private loginService: LoginService,
    private router: Router,
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private arangoService: ArangoService,
    private dialog: MatDialog,
    private agreementService: AgreementService,
    private intercept: AuthInterceptor) {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            lastLogin: [''],
        });
        this.showSpinner = true;
        this.loginvisible = false;


    }

  ngOnInit() {
    this.showSpinner = false;
    this.loginvisible = true;
  }
  

  onClickForgotPwd() {
	 this.loginmessage = '';
    const credentials = {
      email: this.loginForm.get('email').value,
      password: this.loginForm.get('password').value,
      lastLogin: this.loginForm.get('lastLogin').value,
    };
    if (credentials.email && credentials.email != '') {
		this.loginmessage = 'Sending password reset';
      this.loginService.forgotPwd(credentials)
      .then ( (user: any) => {
          console.log('Got user forgotpwd >' + JSON.stringify( user) + '<');
      })
      .catch( (error) =>  {
          this.showSpinner = false;
          this.loginvisible = true;
          this.loginmessage = error.message;
          console.log('Other response from forgotpwd Pwd ' + JSON.stringify(error));
        })
    } else {
      this.showSpinner = false;
      this.loginvisible = true;
      this.loginmessage = 'No Email Given';
    }

  }

  onClickRegister() {
    this.showSpinner = true;
    this.loginvisible = false;

    this.loginmessage ='';

    const credentials = {
        email: this.loginForm.get('email').value,
        password: this.loginForm.get('password').value,
        lastLogin: this.loginForm.get('lastLogin').value,
    };

    this.loginService.register(credentials)
    .then ( (user: any) => {
        console.log('Got user register  response >' + JSON.stringify( user) + '<');
        this.completelogin(user);

    })
    .catch( (error) =>  {
        this.showSpinner = false;
        this.loginvisible = true;
        this.loginmessage = error.message;
        console.log('Other response from Register ' + JSON.stringify(error));
      })
  }

  onClickLogin() {
    this.showSpinner = true;
    this.loginvisible = false;

    this.loginmessage ='';

    const credentials = {
        email: this.loginForm.get('email').value,
        password: this.loginForm.get('password').value,
        lastLogin: this.loginForm.get('lastLogin').value,
    };

    credentials.email= credentials.email.toLowerCase().trim();
    this.loginService.login(credentials)
    .then ( (user: any) => {
      console.log('Got user login  response >' + JSON.stringify( user) + '<');
       this.completelogin(user);

    })
    .catch( (error) =>  {
        this.showSpinner = false;
        this.loginvisible = true;
        this.loginmessage = error.message;
        console.log('Other response from Register ' + JSON.stringify(error));
      })
  }

  clearMessage(){
    this.loginmessage = '';
  }

  video() {
    const dRef = this.dialog.open(VideoPlayerDialogComponent, {
      width: '100%',
      height: '100%',
      data: {video: 'https://bo9videos.bodyof9.com/dashboard.mp4'}
    });

    this.subscriptions.push( dRef.afterClosed()
    .subscribe(  (result: any) => {
      
    }))
  }

  async userLoggedIn(response: InnerMessage) {
    this.showSpinner = false;
    switch (response.messageType) {
      case BO9Actions.SOCKETCREATED: {
        console.log('socketCreated ' + JSON.stringify(response.content));
        let person: Person = response.content;
        if (person.wsSocket !== 0) {
          this.auth.setSessionToken(person.jwt);
          this.auth.setAuthUser(person);


          const db = await firstValueFrom(this.arangoService.getAgreements());
          const agreements = db.content as Agreement[]
          person.agreements = mergeAgreements(agreements, person);
          person = await this.agreementService.confirmAgreements(person); // sideeffect update person if changed
          this.auth.setAuthUser(person); // Duplicate to capture agreements
          // this.socketService.initSocket(Person.wsSocket);
         
         if (userIsAdmin(person)) {
          this.router.navigate(['dashboard'] );  // Admin Starts at Dashboard
          } else  if (userIsApprentice(person)) {
            this.router.navigate(['guild/edit'] );  // Partner Starts at Partner Dashboard
          } else {
            this.router.navigate(['profile/Edit'] );
          }
        } else {
          console.error(`User ${this.creds.email} not authorized `);
        }
        break;
      }
      case BO9Actions.UNKNOWNEMAIL: {
        console.log('Email not knownn ' + JSON.stringify(response));
        // this.event = of(JSON.stringify(response));
       
        alert("the email you used to register/login '"+response.content.email+ "' was not recognized - please use the email you registered when you were identified");
        this.loginvisible = true;
        this.loginmessage = 'Unknown Email';
        break;
      }
      case BO9Actions.NONATURALNUMBER: {
        console.log('Email not know ' + JSON.stringify(response));
        // this.event = of(JSON.stringify(response));
        alert("=the email you used to login '" +response.content.email+ "' does not have a Natural Number Identified, a requirement to join the Body of 9 Guild.  You will be redirected to our Start Here page");
        this.loginvisible = true;
        this.loginmessage = 'No Natural Number';
        document.location.href = 'https://bodyof9.com/start_here';
        break;
      }

      case BO9Actions.SOCKETNOTCREATED: {
        console.log('Socket Not Created ' + JSON.stringify(response));
        // this.event = of(JSON.stringify(response));
        this.loginvisible = true;
        this.loginmessage = 'Invalid Login';
        break;
      }
      case BO9Actions.USERDATA: {
        console.log('USER Data ' + JSON.stringify(response));
        // this.event = of(JSON.stringify(response));
        break;
      }
      case BO9Actions.MESSAGE: {
        console.log('MESSAGE Data ' + JSON.stringify(response));
        // event = of(JSON.stringify(response));
        break;
      }
      case BO9Actions.REJECTED: {
        console.log('Rejected Data ' + JSON.stringify(response));
        // event = of(JSON.stringify(response));
        break;
      }
      default: {
        console.error('Unknown Action' + JSON.stringify(response));
        break;
      }

    }

  }

  completelogin(user: any) {

    const regcreds: RegisterCredentials = {
      email: '',
      password: encodeB64(BO9AppToken),
      lastLogin: ''
    };
    this.loginmessage ='';
    const regSub = this.loginService.registerApp(regcreds)
    .subscribe ({
      next:  (reg) => {
        console.log('Got register response >' + JSON.stringify( reg ) + '<');
        if (reg.messageType === BO9Actions.REJECTED) {
          this.loginmessage = 'App Cannot connect to Server';
          this.showSpinner = false;
          this.loginvisible = false;
          if (regSub) {
            regSub.unsubscribe();
          }
          this.router.navigate(['/'] );
        } else if (reg.messageType === BO9Actions.SOCKETNOTCREATED) {
          this.showSpinner = false;
          this.loginvisible = false;
          this.loginmessage = 'Cannot connect to Server';
          if (regSub) {
            regSub.unsubscribe();
          }
          this.router.navigate(['/'] );
        } else {
          if (regSub) {
            regSub.unsubscribe();
          }
          this.loginmessage = 'Connected to Server - registering user';
          this.auth.setSessionToken(reg.content);  // Connected to back end
          this.showSpinner = false;
          this.loginvisible = true;
          const logSub = this.loginService.registerUser(user)
          .subscribe ( {next: (response: InnerMessage) => {
            console.log('Got completeLogin response >' + JSON.stringify( response) + '<');
            this.userLoggedIn(response)
          } ,
          error: (error) => {
            console.error('error caught in login ' + JSON.stringify(error));
            this.loginmessage = 'Login '+ error.statusText;
            this.showSpinner = false;
          } })
        }
      },
      error: (error) => {
        console.error('error caught in register ' + JSON.stringify(error));
        this.loginmessage = 'Network Error - App '+ error.statusText;
        this.showSpinner = false;
        alert('App '+ error.statusText+ '; Please try again');
        this.router.navigate(['/'] );
      }} )
    
  }
}
