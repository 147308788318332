import { Component, OnInit, OnDestroy } from '@angular/core';

import { firstValueFrom, Observable, of, Subscription } from 'rxjs';

import { CommunityUpdate } from '../../../../bo9-shared/models/communityupdate.model';

import { CommunityUpdateService } from '../../services/communityupdateservice/communityupdate.service';
import { AuthService } from '../../services/auth/auth.service';
import { Person } from '../../../../bo9-shared/models/person/person.model';

import { ChatRoom } from '../../../../bo9-shared/models/chat/chatroom.model';
import { Router, NavigationEnd } from '@angular/router';

import { Member } from '../../../../bo9-shared/models/member/member.model';
import { EventEmitterService } from '../../services/events/events.service';
import { BO9Container, DisplayMessage } from '../../../../bo9-shared/models/containers.model';
import { ArangoService } from '../../services/arangoservice/arango.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { PCount } from '../../../../bo9-shared/models/pearl/pearl.model';
import { userIsAdmin, userIsMaster } from '../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { ProductsService } from '../../services/products/products.service';
import { Product } from '../../../../bo9-shared/models/product/product.model';
import { SystemVariable } from '../../../../bo9-shared/models/BO9_base/systemvariables.model';
import { WooCommerceService } from '../../services/woocommerce/woocommerce.service';
import { ActiveCampaignService } from '../../services/activecampaign/activecampaign.service';
import { fieldValue, tagValue } from '../../../../bo9-shared/models/mailCampaign/activeCampaign.model';
import { MatTableDataSource} from '@angular/material/table';
import { MemberService } from '../../services/memberService/member.service';
import { SystemVariablesService } from 'src/services/systemvariables/systemvariables.service';
import { CalendlyService } from 'src/services/calendlyservice/calendly.service';
import { UserService } from 'src/services/auth/session.service';




@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, OnDestroy {

  chatrooms: ChatRoom[];
  chatroom$: Observable<ChatRoom[]>;

  nineECounts: Observable<any>;
  arangoCounts: any;
  arangoNumbers: any;

  messages$: Observable<DisplayMessage[]>
  user: Person;

  numberToXfer: number;
  numberToXferActivity: number;
  searchString: string;

  currentP$: Observable<Person>;
  dataSource= new MatTableDataSource<SystemVariable>();
  dataSourceTags=new MatTableDataSource<tagValue>();
  dataSourceFields =new MatTableDataSource<fieldValue>();

  pearlCount: PCount[];

  products: Product[] = [];
  navSubscription;
  messagesLoading$: Observable<boolean>;
  PersonEditForm: FormGroup;
  affiliateCount: number=0;
  affiliateRevenue: number=0;
  totalIdentifed: number = 0;
  pearlList=0;
  invitedPearls = 0;
  subscriptions: Subscription[] = [];
  loggedIn: string[] = [];
  calendlyWebhooks: any[];
  public displayedColumns = ['name','value'];
  public displayedColumnsF = ['name','value'];
  public displayedColumnsT = ['name','value'];
  constructor(
    private community: CommunityUpdateService,
    private auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activeCampaignService: ActiveCampaignService,
    private calendlyService: CalendlyService,
    private emitterService: EventEmitterService,
    private systemVariablesService: SystemVariablesService,
    private userService: UserService,
    private arangoService: ArangoService,
    private productService: ProductsService,
    private woocommerceService: WooCommerceService ) {
      this.initializeWidgets();
      this.user = this.auth.getAuthUser();
      this.subscriptions.forEach(s => s.unsubscribe());
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'DashboardComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
     }

  ngOnInit() {
    this.user = this.auth.getAuthUser();
    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #017'); 
      this.router.navigate(['login'] );
    } else if (!(userIsAdmin(this.user) || userIsMaster(this.user)) ) {
      console.error('User Not authorized to see dashboard');
      this.router.navigate(['profile/Edit'] );
      this.user = this.auth.getAuthUser();
    }  


    //this.webSocket.initSocket(this.user.wsSocket);


      this.makeForm();


      this.loadDashboard();

      this.emitterService.emitter.on('Event', () => {
          console.log('Refresh Event occurred ');
         // this.router.navigate(['dashboard']);
      });
      // Handle reload
      this.navSubscription = this.router.events.subscribe ( (e: any) => {
        if (e instanceof NavigationEnd) {
          console.log('Reset Messages');
          };
        });


  }

  resetBan() {
    this.subscriptions.push( this.arangoService.banReset()
      .subscribe((result) => {
        alert("Clear Ban result " + JSON.stringify(result));
    }))
  }

  loadDashboard() {
    this.subscriptions.push( this.arangoService.countPearls()
    .subscribe( async (result) => {
      try {

        const pearlListId = await firstValueFrom(this.systemVariablesService.getSystemVariable('ListDailyPearls'));
        const pearlInviteListId = await firstValueFrom(this.systemVariablesService.getSystemVariable('ListInvitedPearls'));

      const onPearlList = await firstValueFrom(this.activeCampaignService.readList(pearlListId));

      const onInviteList = await firstValueFrom(this.activeCampaignService.readList(pearlInviteListId));

      const loggedInList = await firstValueFrom(this.userService.getLoggedIn());

      this.loggedIn = loggedInList.content;

      this.pearlList = onPearlList.content.length;

      this.invitedPearls = onInviteList.content.length;

      this.pearlCount = result.content;
      const res = await firstValueFrom(this.arangoService.countCollections());

      const wh  =await firstValueFrom(this.calendlyService.getWebHooks());
      this.calendlyWebhooks = wh.content.collection;
      
    //  console.log('Count collections'+ JSON.stringify(res))
      this.arangoCounts = res.content;
      const res1 = await firstValueFrom(this.woocommerceService.loadOrderCount());

      console.log('counts'+ JSON.stringify(res1))
      this.arangoCounts.sale = res1.content[0].total;
      const res2 = await firstValueFrom(this.arangoService.countNumbers());
      console.log('Count numbers'+ JSON.stringify(res2))
      this.arangoNumbers = res2.content;
      for (const c in this.arangoNumbers.identified) {
        if (typeof (this.arangoNumbers.identified[c].natnum) === 'string') { // if its a number - leave it alone
          // Add the '1' etc to the real numbers
          const realC: number = +this.arangoNumbers.identified[c].natnum;

          if (realC >0 && realC <=9) {
            this.arangoNumbers.identified[realC-1].natnum = realC;
            if ( this.arangoNumbers.identified[realC-1] && this.arangoNumbers.identified[realC-1].numnatnum ) {
              this.arangoNumbers.identified[realC-1].numnatnum += this.arangoNumbers.identified[c].numnatnum;
            } else {
              this.arangoNumbers.identified[realC-1].numnatnum = this.arangoNumbers.identified[c].numnatnum;
            }
            
          }
          
        }
        this.totalIdentifed += this.arangoNumbers.identified[c].numnatnum;
      }
      console.log('Count numbers identified done')
      for (const c in this.arangoNumbers.asserted) {
        if (typeof (this.arangoNumbers.asserted[c].natnum) === 'string') {
          // Add the '1' etc to the real numbers
          const realC: number = +this.arangoNumbers.asserted[c].natnum;

          if (realC >0 && realC <=9) {
            this.arangoNumbers.asserted[realC-1].natnum = realC;
            if ( this.arangoNumbers.asserted[realC-1] && this.arangoNumbers.asserted[realC-1].numnatnum ) {
              this.arangoNumbers.asserted[realC-1].numnatnum += this.arangoNumbers.asserted[c].numnatnum;
            } else {
              this.arangoNumbers.asserted[realC-1].numnatnum = this.arangoNumbers.asserted[c].numnatnum;
            }
          }
          
        }
      } 
      console.log('Count numbers asserted done')
      const tmpArray: {natnum: number, numnatnum: number}[]=[];
      for (let c =0; c< this.arangoNumbers.invited.length; c++) {
        tmpArray.push({natnum:c+1, numnatnum:0});
      }
      for (let c =0; c< this.arangoNumbers.invited.length; c++) {
        
        const act = +this.arangoNumbers.invited[c].natnum - 1;
        
        if (act>= 0) {
          if (! tmpArray[act]) {
            tmpArray[act] = {natnum: act, numnatnum: this.arangoNumbers.invited[c].numnatnum}
          } else {
            tmpArray[act].numnatnum = this.arangoNumbers.invited[c].numnatnum;
          }
          
        }
        
      }
      this.arangoNumbers.invited = tmpArray;
      console.log('Count numbers invited done')
      const res3 = await firstValueFrom(this.productService.loadProducts());

      for (const p of res3.content as Product[]) {
        // console.log('product'+ JSON.stringify(p))

          if (userIsAdmin(this.user)) {
            this.products.push(p);
          } else {
            if (p.status === 'publish') {
              this.products.push(p);
            }
          }

      }
      const res4 =  await firstValueFrom(this.systemVariablesService.getSystemVariables(true));

     // console.log('sys vbles'+ JSON.stringify(res4))
      this.dataSource.data = res4;
      const res5 = await  firstValueFrom(this.activeCampaignService.getContactTagFieldValues(true));

     // console.log('getcontact dashboard'+ JSON.stringify(res5))
      this.dataSourceTags.data= res5.tags as tagValue[];
      this.dataSourceFields.data= res5.customFields as fieldValue[];

      
      } catch (err) {
          
      }
      
    }))

 
    
  }

  onRefreshClick() {
    console.log('Refresh click ');
    this.loadDashboard();

  }

  makeForm() {
    this.PersonEditForm = this.formBuilder.group({
      volume: new FormControl('', Validators.compose([
        Validators.minLength(1),
        Validators.maxLength(30),
        Validators.pattern('[0-9. ]*'),
        Validators.required])),
      });
  }

  initializeWidgets() {
  }



  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    if (this.navSubscription) {
      this.navSubscription.unsubscribe();
    }

  }

}
