<div>

  <mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="30" class='spin'>  </mat-progress-spinner>
  </div>
  <div >
		<button type="button" (click)="onClickInvite()">
			<span>Click to Assign MEA Meeting ID</span>
			<mat-icon>how_to_reg</mat-icon></button>

      <a href="{{meetingUrl}}" target="_blank">Click me to open MEA App</a>
		</div>
	<div *ngIf='editing' class='editing'>
		<button class='close' mat-stroked-button (click)="onCloseClick()">Close <mat-icon>close</mat-icon></button>
	   <prepaid-member-form  [person]="person" [members]="members" [inputGroup]="inputGroup" [canMEA]="true" [packages]="packages" (notifyParent)="memberSubmittedHandler($event)" ></prepaid-member-form>
   </div>
     <div *ngIf="dataSource.data.length > 0; else nodata">
     <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows >

      <ng-container matColumnDef="first">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> First </th>
        <td mat-cell *matCellDef="let element"> {{element.first}} </td>
      </ng-container>
      <ng-container matColumnDef="last">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last </th>
        <td mat-cell *matCellDef="let element"> {{element.last}} </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>
      <ng-container matColumnDef="assigned">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Assigned Date </th>
        <td mat-cell *matCellDef="let element"> {{dateFormating(element.assignedDate, "ts") }}  </td>
      </ng-container>
      <ng-container matColumnDef="used">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Used </th>
        <td mat-cell *matCellDef="let element"> <span *ngIf="element.usedDate; else notUsed"> {{dateFormating(element.usedDate, "ts") }} </span>
        <ng-template #notUsed>Unused</ng-template>
         </td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Meeting ID </th>
        <td mat-cell *matCellDef="let element"> 
        {{ element.id }}  
        </td>
      </ng-container>

        <ng-container matColumnDef="link">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Meeting Link </th>
          <td mat-cell *matCellDef="let element">
            <button mat-button [cdkCopyToClipboard]="meetingUrl+element.link"><mat-icon >content_copy</mat-icon></button><a href="{{meetingUrl + element.link}}" target="_blank"> Link</a>
            
          </td>
        </ng-container>
    


   
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
      </table>
      <mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]">
      </mat-paginator>
  </div>
  <ng-template #nodata>
    <p style="margin:80px 10px 40px;font-weight:bold;">No MEAs Found</p>
  </ng-template>


