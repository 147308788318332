import { Component, forwardRef, ChangeDetectionStrategy, OnDestroy, OnInit, Input, OnChanges, EventEmitter } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { validationMessages } from 'src/helpers/form.helper';

import { Email} from '../../../../../../bo9-shared/models/person/email.model';

import { EmailService } from 'src/services/email/email.service';
import { Person } from '../../../../../../bo9-shared/models/person/person.model';
import { findInvalidControlsRecursive } from '../../../../helpers/form.helper';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth/auth.service';


@Component({
    selector: 'sendEmail-form',
    templateUrl: './sendEmailForm.component.html',
    styleUrls: ['./sendEmailForm.component.scss'],
 
  })
  export class SendEmailFormComponent implements OnDestroy {
    @Input() person: Person;
    name: string;
    form: FormGroup;
    subscriptions: Subscription[] = [];
    validationMessages = validationMessages;
    user: Person;
    DNC: boolean;
    
    disableBtn = false;
    get value(): Email {
      console.log('name get '+ JSON.stringify(this.form.value));
      return this.form.value;
    }
  
    set value(value: Email) {
      console.log('name set '+ JSON.stringify(value));
      this.form.setValue(value);
    }

    get firstControl() {
        return this.form.controls.first;
    }

    get lastControl() {
        return this.form.controls.last;
    }

    get emailControl() {
      return this.form.controls.email;
  }
    constructor(private formBuilder: FormBuilder,   
      private auth: AuthService,
      private router: Router,  private arangoService: ArangoService, private emailService: EmailService) {
      this.user = this.auth.getAuthUser();
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'SendEmailFormComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
    this.makeForm();

    }

    makeForm() {
      this.form = this.formBuilder.group({
        text: ['', Validators.compose([])],
        subject: ['', Validators.compose([Validators.required])],
        html: ['', Validators.compose([Validators.required])],
      });
      
    }

    submitForm() {
      console.log('Submit email form ' + JSON.stringify(this.form.value));
      if (this.form.valid) {
       const e: Email = {
         to: this.person.primaryEmail,
         subject: this.form.value.subject,
         text: this.form.value.text,
         html: this.form.value.html,
         from: 'bonnie@bodyof9.com'
       }
       this.subscriptions.push( this.emailService.sendEmail(e)
       .subscribe( (res) => {
         console.log('Emailed '+JSON.stringify(res));
         
       }))
      } else {
        alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.form)));
      }
    }
    //this.PersonForm.reset();
  
    ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
    }

  

  
    reset() {
      this.form.reset();
    }
  }