
<my-slide-panel [activePane]="currentPane">

  <section leftPane>
    <button mat-stroked-button (click)="onCloseClick()">Back<mat-icon>arrow_back</mat-icon></button> 
  </section>

  <section centerPane>
    <h1>Products</h1>
    <div>

      <product-list ></product-list>

    </div>


  </section>
  <section rightPane>

  </section>
</my-slide-panel>

 