import { updateMeta } from '../BO9_base/meta.model';
import { addShortAddress, BO9Address, BO9AddressType, existingAddress, mkMTAddress } from '../contact/address.model';
import { addEmail, existingEmail } from '../contact/email.model';
import { addName } from '../contact/name.model';
import { addPhone, existingPhoneNumber } from '../contact/phone.model';
import { addNaturalNumber, makeMTNaturalNumber, makeMTNaturalNumbers, NaturalNumber, stringToNaturalNumber } from '../naturalNumber/naturalNumber.model';
import { createUUID } from '../../uuid.service';
import { mkEmptyPerson, Person  } from '../person/person.model'
import { Difference } from '../person/differences.model'
import { differencesForSource, getDifferences, sameDifference } from '../person/differencesCode.model'
import { mkMTMailCampaign, TransportNative } from './mailCampaign.model';
import { DateToYYMMDDhhmmss } from '../../helpers/time.helper';
import { setPreferences } from '../person/preferences.model';
import { primaryEmail } from '../contact/contact.model';


export interface fieldValue {
    field: string;
    value: string;
    strVal?: string;
}

export interface tagValue {
    id: string;
    tag: string;
    value: string;
}
export interface ActiveCampaignContactTag {
    contact: string;
    tag: string;
    cdate: string;
    created_timestamp: string;
    updated_timestamp: string;
    created_by: string;
    updated_by: string;
    links: {
        tag: string;
        contact: string;
    },
    id: string;
}

export interface ActiveCampaignList {
    stringid: string;
    userid:  string;
    name:  string;
    cdate:  string;
    p_use_tracking: string;
    p_use_analytics_read:  string;
    p_use_analytics_link:  string;
    p_use_twitter: string;
    p_use_facebook:  string;
    p_embed_image:  string;
    p_use_captcha:  string;
    send_last_broadcast:  string;
    private:  string;
    analytics_domains:  string;
    analytics_source:  string;
    analytics_ua:  string;
    twitter_token:  string;
    twitter_token_secret:  string;
    facebook_session:  string;
    carboncopy:  string;
    subscription_notify:  string;
    unsubscription_notify:  string;
    require_name:  string;
    get_unsubscribe_reason:  string;
    to_name:  string;
    optinoptout:  string;
    sender_name:  string;
    sender_addr1: string;
    sender_addr2:  string;
    sender_city:  string;
    sender_state:  string;
    sender_zip: string;
    sender_country:  string;
    sender_phone:  string;
    sender_url:  string;
    sender_reminder:  string;
    fulladdress: string;
    optinmessageid:  string;
    optoutconf:  string;
    deletestamp:  string;
    udate:  string;
    links: {
        contactGoalLists:  string;
        user:  string;
        addressLists:  string;
    },
    id:  string;
    user:  string;

}
export interface ActiveCampaignTag {
    tagType: string;
    tag: string;
    description: string;
    cdate: string;
    subscriber_count: string;
    created_timestamp: string;
    updated_timestamp: string;
    created_by: string;
    updated_by: string;
    links: {
        tag: string;
        contact: string;
    },
    id: string;
    associated_id?: string;

}

export interface ActiveCampaignFieldOptions {
    options: any[];
}

export interface ActiveCampaignFieldRels {
    options: any[];
}

export interface ActiveCampaignField {
    title: string;
    descript: string;
    type: string;
    isrequired: string;
    perstag: string;
    defval: string;
    show_in_list: string;
    rows: string;
    cols: string;
    visible: string;
    service: string;
    ordernum: string;
    cdate: string;
    udate: string;
    options: [],
    relations: [],
    links: {
        name: string;
        url: string;
    }[],
    id: string;
}
export interface ActiveCampaignFields {
    fieldOptions: ActiveCampaignFieldOptions[];
    fieldRels: ActiveCampaignFieldRels[];
    fields: ActiveCampaignField[];
    meta: {
        total: string;
    }
}

export interface ActiveCampaignDealCustomFieldMeta {
    id: string;
    fieldLabel: string;
    fieldType: string;
    fieldOptions:string;
    fieldDefault: string;
    fieldDefaultCurrency: string;
    isFormVisible: string;
    isRequired: string;
    displayOrder: string;
    personalization: string;
    knownFieldId: string;
    hideFieldFlag:string;
    createdTimestamp: string;
    updatedTimestamp: string;
    links: {
        dealCustomFieldData:string;
    }
}


export interface ActiveCampaignContact {
        id: string,
        email: string;
        firstName: string;
        lastName: string;
        phone: string;
        fieldValues: fieldValue[];
        tags?: ActiveCampaignContactTag[]
        deals?: ActiveCampaignDeal[];
        links?:{
            bounceLogs:string,
            contactAutomations:string,
            contactData:string,
            contactGoals:string,
            contactLists:string,
            contactLogs:string,
            contactTags:string,
            contactDeals:string,
            deals:string,
            fieldValues:string,
            geoIps:string,
            notes:string,
            organization:string,
            plusAppend:string,
            trackingLogs:string,
            scoreValues:string,
         },
}

export interface ActiveCampaignCustomField {
    customFieldId: number,
    fieldValue: string,
    fieldCurrency?: string // "usd"
}

export interface ActiveCampaignDeal {
    id?: string;  // Filled in from ACTCAMP
    contact: string, // contact id of the person/attendee
    account: string, // blank
    description: string, // event title
    currency:  string, //usd
    group: string, // event pipeline id
    owner: string, // susan's contact id
    percent: null,
    stage: string, // 1
    status: number, // 0
    title: string, // event type
    value: number,
    cdate?: string,
    mdate?: string,
    links?: {
        dealActivities: string,
        contact: string,
        contactDeals: string,
        group: string,
        nextTask: string,
        notes: string,
        account: string,
        customerAccount: string,
        organization: string,
        owner: string,
        coreValues: string,
        stage: string,
        tasks: string,
        dealCustomFieldData: string,
    },
    fields: ActiveCampaignCustomField[],
    dealFields?: ActCamFields;

}

export interface ActiveCampaignInboundContact {
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    id: string;
    date: string;
    org_name: string;
}

export interface ActiveCampaignAutomation {
    name: string;
            cdate: string;
            mdate: string;
            userid: string;
            status: string;
            entered: string;
            exited: string;
            hidden: string;
            defaultscreenshot: string;
            screenshot: string;
            links: {
                campaigns:string;
                contactGoals: string;
                contactAutomations: string;
                blocks: string;
                goals: string;
                sms: string;
                sitemessages: string;
            },
            id: string;
}


export interface ActCamFields {
    email: string;
    first: string;
    last: string;
    _key: string;
    dateIdentified: string;
    naturalNumber: string;
    naturalNumberIssue?: boolean;
    assertedNumber: string;
    invitedNumber: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    country: string;
    notes: string;
    idAt: string;
    idBy: string;
    person?: Person;
    actCampPerson: Person;
    differences?: Difference[];
    diffAccepted: boolean;
    pearlText?: string;
    pearlImage?: string;
    event_id: string;
    event_date: string;
    event_time: string;
    process_event_id: string;
    process_event_date : string;
    referredBy: string;
    personOrOther: string;
    studyId: string;
    deals?: any;
    tags?: ActiveCampaignContactTag[];
}

export interface ActiveCampaignPipeLines {
    dealGroups: ActiveCampaignDealGroup[],
    dealStages: ActiveCampaignDealStage[],
    meta: {
        total: string;
    }
}

export interface ActiveCampaignDealGroup {
    allgroups: string,
    allusers: string,
    autoassign: string,
    cdate: string,
    currency: string,
    id: string,
    links: {
      dealGroupGroups: string,
      dealGroupUsers: string,
      stages: string
    },
    stages: string[],
    title: string,
    udate: string
}

export interface ActiveCampaignDealStage {
    cardRegion1: string,
    cardRegion2: string,
    cardRegion3: string,
    cardRegion4: string,
    cardRegion5: string,
    cdate: string,
    color: string,
    dealOrder: string,
    group: string,
    id: string,
    links: {
      group: string,
    },
    order: string,
    title: string,
    udate: string,
    width: string,
}

export function ActCamFillFields(acf: ActCamFields, fields: fieldValue[], natives: TransportNative ) : ActCamFields {
    if (fields) {

    
    for (let i=0; i< fields.length; i++) {
        //console.log(' field '+JSON.stringify(fields[i].field));
        const customField = getFieldFromInput(fields[i].field, natives.customFields);
        //console.log('Cust field '+JSON.stringify(customField));

        if (customField) {
            fields[i].strVal = customField.perstag;
            switch (customField.perstag) {
                case 'IDENTIFIED_AT' : {
                    acf.idAt = fields[i].value;
                    break;
                }
                case 'IDENTIFIED_BY' : {
                    acf.idBy = fields[i].value;
                    break;
                }
                case 'IDENTIFICATION_DATE' : {
                    break;
                }
                case 'NATURAL_NUMBER' : {
                    acf.naturalNumber = fields[i].value;
                    break;
                }
                case 'CITY' : {
                    acf.city = fields[i].value;
                    break;
                }
                case 'STATE' : {
                    acf.state = fields[i].value;
                    break;
                }
                case 'ZIP' : {
                    acf.zip = fields[i].value;
                    break;
                }
                case 'COUNTRY' : {
                    acf.country = fields[i].value;
                    break;
                }
                case 'NOTES_RELATIONSHIPS' : {
                    acf.notes = fields[i].value;
                    break;
                }
                case 'USER_REPORTED_ID_METHOD' : {
                    acf.actCampPerson.referralSource = fields[i].value;
                    break;
                }
                case 'SELFIDENTIFICATION' : {
                    const nn: NaturalNumber = fields[i].value as  NaturalNumber;
                    if (parseInt(nn) === parseInt(fields[i].value)) {
                        acf.assertedNumber = nn;
                        acf.actCampPerson.naturalNumbers.asserted.naturalNumber = nn;
                    } else {
                        acf.assertedNumber = NaturalNumber.None
                        acf.actCampPerson.naturalNumbers.asserted.naturalNumber = NaturalNumber.None
                    }
                    break;
                }
                case 'BEST_TIMES_FOR_ONLINE_CALL' : {
                    break;
                }
                case 'INVITED_ID' : {
                    const nn: NaturalNumber = fields[i].value as  NaturalNumber;
                    if (parseInt(nn) === parseInt(fields[i].value)) {
                        acf.invitedNumber = nn;
                        acf.actCampPerson.naturalNumbers.invited.naturalNumber = nn;
                    } 
                    break;
                }
                case 'SOURCE' : {
                    acf.actCampPerson.referralSource = fields[i].value;
                    break;
                }
                case 'REFERRED_BY' : 
                     acf.actCampPerson.referralSource = fields[i].value;
                    break;
                case 'PERSON_OR_OTHER': {
                    acf.actCampPerson.personOrOtherReferredBy = fields[i].value;
                    break;
                }
                case 'WHAT_KIND_OF_EVENT_ARE_YOU_INTERESTED_IN' : {
                    break;
                }
                case 'ORIGINAL_ENTRY_POINT' : {

                   break;
                }
                case 'HOW_WOULD_YOU_LIKE_TO_PARTICIPATE' : {
                    break;
                }
                case 'PEARL_TEXT' : {
                    break;
                }
                case 'PEARL_IMAGE' : {
                    break;
                }
                case 'EVENT_DATE' : {
                    acf.event_date = fields[i].value;
                    break;
                }
                case 'EVENT_ID' : {
                    acf.event_id = fields[i].value;
                    break;
                }
                case 'PROCESS_EVENT_ID' : {

                    acf.process_event_id = fields[i].value;
                    break;
                }
                case 'PROCESS_EVENT_DATE' : {
                    acf.process_event_date= fields[i].value;
                    break;
                }
                case 'POST_EVENT_ID' : {
                    break;
                }
                case 'COUPON_CODE' : {
                    break;
                }
                case 'INCOME' : {
                    break;
                }
                case 'INCOME' : {
                    break;
                }
                case 'STUDY_ID' : {
                    break;
                }
                default: {
                    console.error('Unknown custom Field '+customField.perstag);
                }
            }
        }
        }
    }

    return acf;
}


export function ActCamCustomFieldMap(mailContact: ActiveCampaignInboundContact, 
    fields: fieldValue[], 
    person: Person, 
    natives: TransportNative): ActCamFields {

    let acf: ActCamFields = {
        email: mailContact.email,
        first: mailContact.first_name,
        last: mailContact.last_name,
        _key: mailContact.id,
        phone: mailContact.phone,
        dateIdentified: mailContact.date,
        naturalNumber:  NaturalNumber.None,
        assertedNumber: NaturalNumber.None,
        invitedNumber: NaturalNumber.None,
        city: '',
        state: '',
        zip: '',
        country: '',
        notes: '',
        idAt: '',
        idBy: '',
        actCampPerson: mkEmptyPerson('ActiveCampaign',mailContact.email),
        diffAccepted: false,
        event_date: '',
        event_time: '',
        event_id: '',
        process_event_id: '',
        process_event_date: '',
        referredBy: '',
        personOrOther: '',
        studyId: '',
        deals: null
    }

    acf = ActCamFillFields(acf, fields, natives);

    acf.actCampPerson = personFromActForm(acf);

    if (person) {
        acf.person = person;
        acf.differences = getDifferences(person, acf.actCampPerson); // Differrrecnes between p and actcampaign
        const pDiffs = differencesForSource('ActiveCampaign', person); // what is already known from before, if anything
        if (pDiffs.acceptedAlready && sameDifference(pDiffs.differences, acf.differences)) { // has it already been accepted and the diffs haven't changed
            acf.diffAccepted = true;
        } else {
            acf.diffAccepted = false;
        }
    }
   // console.log("ACF "+ JSON.stringify(acf));
    return acf;
}

export function getFieldValue(fid: string, fields: fieldValue[]) : string {
    console.log('get field value'+JSON.stringify(fid));
    console.log('get field value'+JSON.stringify(fields));
    for (const f of fields) {
        if (f.strVal && (f.strVal.toLowerCase() === fid.toLowerCase())) {
            return f.value
        }
    }
    return '';
}

export function personFromActContact( mailContact: ActiveCampaignContact, natives?: TransportNative ) : Person {

        let acf: ActCamFields = {
            email: mailContact.email,
            first: mailContact.firstName,
            last: mailContact.lastName,
            _key: mailContact.id,
            phone: mailContact.phone,
            dateIdentified: '',
            naturalNumber:  NaturalNumber.None,
            assertedNumber: NaturalNumber.None,
            invitedNumber: NaturalNumber.None,
            city: '',
            state: '',
            zip: '',
            country: '',
            notes: '',
            idAt: '',
            idBy: '',
            actCampPerson: mkEmptyPerson('ActiveCampaign',mailContact.email),
            diffAccepted: false,
            event_date: '',
            event_time: '',
            event_id: '',
            process_event_id: '',
            process_event_date: '',
            referredBy: '',
            personOrOther: '',
            studyId: '',
            deals: null
        }
    
        if (mailContact.fieldValues && natives) { // Adding from addattendee, with not exists activecampaign entry, has no 'fields'
            acf = ActCamFillFields(acf, mailContact.fieldValues, natives);
        }
        const newPerson: Person = personFromActForm(acf);  

      return newPerson;

}


  // /ACF {"email":"603agc@gmail.com","first":"Grant","last":"Clemens","_key":"13754","dateIdentified":"2020-03-03T11:18:39-06:00","naturalNumber":"9","city":"Belgrade","state":"MT","zip":"59714","country":"USA",
  // "notes":"Sue Miller (5)'s Boyfriend ","idAt":"NEI March 2020","idBy":"Susan Fisher"}
export function personFromActForm( p: ActCamFields): Person {
    const newPerson: Person = {
      _key: createUUID(),
      userUID: '',
      contact: {
        _key: createUUID(),
        usage: 'person',
        email: addEmail(null, false, p.email),
        names : addName(null, p.first, p.last),
        phone: addPhone(null,false,p.phone),
        URL: '',
        notes: '',
        address: addShortAddress(null, p.city, p.state, p.zip, p.country),
        
      },
      appEntered: 'ActiveCampaign',
      primaryEmail: p.email,
      details: {
        birthday: '',
        momBirthOrder: 0,
        gender: p.person? p.person.details.gender : ''
      },
      externalIds: {
        activeCampaign: ''
    },
      preferences: setPreferences(),
      naturalNumbers: makeMTNaturalNumbers(),
        notes: p.notes,
      agreements:[],
      referralSource: p.actCampPerson? p.actCampPerson.referralSource : '',
      source: p.actCampPerson? p.actCampPerson.source : '',
      personOrOtherReferredBy: p.actCampPerson? p.actCampPerson.personOrOtherReferredBy : '',
      meta: updateMeta(p.person? p._key: '',p.person? (p.person.meta? p.person.meta: null) : null)
    }
    if (p.naturalNumber != ""  && p.naturalNumber != "0" && !(p.naturalNumber === NaturalNumber.None || p.naturalNumber === NaturalNumber.OldNone )) {
      newPerson.naturalNumbers.identified = addNaturalNumber(stringToNaturalNumber(p.naturalNumber), p.idAt, p.idBy,  
            p.dateIdentified, 'Identified', 'Event',  p.actCampPerson.personOrOtherReferredBy)
    }
    if (p.assertedNumber != "" && p.assertedNumber != "0" && !(p.assertedNumber === NaturalNumber.None || p.assertedNumber === NaturalNumber.OldNone )) {
      newPerson.naturalNumbers.asserted = addNaturalNumber(stringToNaturalNumber(p.assertedNumber), p.idAt, p.first + '  '+ p.last,  p.dateIdentified, 'Self', 'Self', '')
    }
    if (p.invitedNumber != "" && p.invitedNumber != "0" && !(p.invitedNumber === NaturalNumber.None || p.invitedNumber === NaturalNumber.OldNone )) {
        newPerson.naturalNumbers.invited = addNaturalNumber(stringToNaturalNumber(p.invitedNumber), p.idAt,  p.actCampPerson.personOrOtherReferredBy,  
        p.dateIdentified, 'Invited', 'Email', '')
      }
    return newPerson;
  }


export function getFieldFromInput(i: string, natives: any[]): any {
    // console.log('getfield from input '+JSON.stringify(i));
    for(const f of natives) {
        if (f.id === i) {
           // console.log('getfield from input result f '+JSON.stringify(f.perstag));
            return f;
        }
    }
    console.log('getfield from input null ');
    return null;
}


export function hasTag(target: string, tagList: any[]): boolean {
    for (const tag of tagList) {
        if (tag.value.toLowerCase() === target.toLowerCase()) return true;
    }
    return false
}
export function getTagId(target: string, natives: any[]): string {
    for (const tag of natives) {
        if (tag.tag.toLowerCase() === target.toLowerCase()) return tag.id;
    }
    return ''
}
export function getTagFromInput(tags: any[], natives: tagValue[]): any[] {

    for (const tag of tags) {
        for(const f of natives) {
            if (tag.tag === f.id) {
                tag.value = f.tag;
            }
        }
    }

    return tags;
}

function getFieldIDFromFieldName(i: string, natives: any[]): string {
    for(const f of natives) {
        if (f.perstag === i) {
            return f.id;
        }
    }
    console.log('No field '+JSON.stringify(i));
    return '';
}

export function actCampaignAddField(c: ActiveCampaignContact, fieldName: string, val: any, natives: any[]) {
   // console.log('ACC in '+JSON.stringify(c.fieldValues));
    const f: string = getFieldIDFromFieldName(fieldName, natives);
    if (!c.fieldValues) {
        c.fieldValues = [];
    }
    if (f !== '' && val) {
        c.fieldValues.forEach( (element,index) => { // Remove existing copy of field
            if (element.field === f) {
                c.fieldValues.splice(index,1); 
            }
        } );
        if (typeof val != "string" && val.name) {
            c.fieldValues.push( {
                field: f,
                value:val.name
            })
        } else {
            c.fieldValues.push( {
                field: f,
                value:val
            })
        }
       
    }
   // console.log('ACC out '+JSON.stringify(c.fieldValues));
}

export function updateByActiveCampaign( a: ActCamFields): Person {
          if (a.person) {
                const actAddr: BO9Address = {
                    _key: '',
                    city: a.city,
                    country: a.country,
                    state: a.state,
                    zip: a.zip,
                }
                if (!existingAddress(a.person.contact.address,actAddr)) {
                    const newAddr = {
                        updatedAt: DateToYYMMDDhhmmss(),
                        items: [a.person.contact.address.items[0]]
                    }
                    newAddr.items = newAddr.items.concat(actAddr); // New add plus old ones in array
                    a.person.contact.address = newAddr;
                }
                if (!existingEmail(a.person.contact.email.items, a.email)) {
                    a.person.contact.email = addEmail(a.person.contact.email, a.person.contact.email.DNC, a.email, '', '')
                }
                if (!existingPhoneNumber(a.person.contact.phone, a.phone)) {
                    a.person.contact.phone = addPhone(a.person.contact.phone, a.person.contact.email.DNC, a.phone, '01','', '')
                }
                if (a.naturalNumber != ""  && a.naturalNumber != "0" && !(a.naturalNumber === NaturalNumber.None || a.naturalNumber === NaturalNumber.OldNone )) {
                    a.person.naturalNumbers.identified = addNaturalNumber(stringToNaturalNumber(a.naturalNumber), a.idAt, a.idBy,  
                        a.dateIdentified, 'Identified', 'Event',  a.actCampPerson.personOrOtherReferredBy)
                } 
                if (a.assertedNumber != "" && a.assertedNumber != "0" && !(a.assertedNumber === NaturalNumber.None || a.assertedNumber === NaturalNumber.OldNone )) {
                    a.person.naturalNumbers.asserted = addNaturalNumber(stringToNaturalNumber(a.assertedNumber), a.idAt, a.first + '  '+ a.last,  a.dateIdentified, 'Self', 'Self', '')
                }
                    
                if (a.invitedNumber != "" && a.invitedNumber != "0" && !(a.invitedNumber === NaturalNumber.None || a.invitedNumber === NaturalNumber.OldNone )) {
                    a.person.naturalNumbers.invited = addNaturalNumber(stringToNaturalNumber(a.invitedNumber), a.idAt, a.actCampPerson.personOrOtherReferredBy,  
                    a.dateIdentified, 'Invited', 'Email', '')
                }
                console.log('Update Existing Person from Act Campaign '+JSON.stringify(a.person));
                return a.person
            } else {
                const p: Person = {
                    _key: createUUID(),  // Only if there's no exisiting key, which should never happen
                    userUID: '',
                    contact: {
                      _key: createUUID(),
                      usage: 'person',
                      email: addEmail(null, false, a.email),
                      names : addName(null, a.first, a.last),
                      phone: addPhone(null,false,a.phone),
                      URL: '',
                      notes: '',
                      address: addShortAddress(null, a.city, a.state, a.zip, a.country),
 
                    },
                    agreements: [],
                    externalIds: {
                        activeCampaign: ''
                    },
                    appEntered: 'ActiveCampaign',
                    primaryEmail: a.email,
                    details: {
                      birthday: '',
                      gender: ''
                    },
                    preferences: setPreferences(),
                    naturalNumbers: makeMTNaturalNumbers(),
                    notes: a.notes,
                    referralSource: a.actCampPerson? a.actCampPerson.referralSource : '',
                    source: a.actCampPerson? a.actCampPerson.source : '',
                    personOrOtherReferredBy: a.actCampPerson? a.actCampPerson.personOrOtherReferredBy : '',
                    meta: updateMeta()
                  }
                console.log('Insert/Update Person from Act Campaign '+JSON.stringify(p));

                return p;
            }


}

export function actCampaignContactFromPerson(p: Person, natives: any[]): ActiveCampaignContact {
    const c: ActiveCampaignContact = {
            email: p.primaryEmail,
            firstName: p.contact.names.name.first,
            lastName: p.contact.names.name.last,
            phone: '',
            id: '', // needs to be filled in later after sync
            fieldValues: []
    }
    try {
        if (!p.primaryEmail || p.primaryEmail=="") {
            c.email = primaryEmail(p.contact);
        }
        if (p.contact.phone && p.contact.phone.items.length >0 && p.contact.phone.items[0].number) {
                c.phone = p.contact.phone.items[0].number
        }

        if (p.contact.address && p.contact.address.items.length >0 && p.contact.address.items[0].state) {
            actCampaignAddField(c, 'STATE', p.contact.address.items[0].state, natives);
        }

        if (p.contact.address && p.contact.address.items.length >0 && p.contact.address.items[0].city) {
            actCampaignAddField(c, 'CITY', p.contact.address.items[0].city, natives);
        }

        if (p.contact.address && p.contact.address.items.length >0 && p.contact.address.items[0].zip) {
            actCampaignAddField(c, 'ZIP', p.contact.address.items[0].zip, natives);
        }

        if (p.contact.address && p.contact.address.items.length >0 && p.contact.address.items[0].country) {
            actCampaignAddField(c, 'COUNTRY', p.contact.address.items[0].country, natives);
        }

        if (p.naturalNumbers.asserted && p.naturalNumbers.asserted.naturalNumber) {
            actCampaignAddField(c, 'SELFIDENTIFICATION', p.naturalNumbers.asserted.naturalNumber, natives);
        }
        if (p.naturalNumbers.invited && p.naturalNumbers.invited.naturalNumber) {
            actCampaignAddField(c, 'INVITED_ID', p.naturalNumbers.invited.naturalNumber, natives);
        }

        if (p.naturalNumbers.identified && p.naturalNumbers.identified.naturalNumber) {
            actCampaignAddField(c, 'NATURAL_NUMBER', p.naturalNumbers.identified.naturalNumber, natives);
        }

        if (p.naturalNumbers.identified && p.naturalNumbers.identified.setAt) {
            actCampaignAddField(c, 'IDENTIFIED_AT', p.naturalNumbers.identified.setAt, natives);
        }

        if (p.naturalNumbers.identified && p.naturalNumbers.identified.dateSet) {
            actCampaignAddField(c, 'IDENTIFICATION_DATE', p.naturalNumbers.identified.dateSet, natives);
        }

        if (p.source) {
            actCampaignAddField(c, 'SOURCE', p.source, natives);
        }

        if (p.referralSource) {
            actCampaignAddField(c, 'SOURCE', p.referralSource, natives);
        }

        if (p.personOrOtherReferredBy) {
            actCampaignAddField(c, 'REFERRED_BY', p.personOrOtherReferredBy, natives);
        }

        if (p.naturalNumbers.identified && p.naturalNumbers.identified.setBy) {
            actCampaignAddField(c, 'IDENTIFIED_BY', p.naturalNumbers.identified.setBy.name, natives);
        }
        if (p.naturalNumbers.asserted && p.naturalNumbers.asserted.setBy) {
            actCampaignAddField(c, 'IDENTIFIED_BY', p.naturalNumbers.identified.setBy.name, natives);
        }

        if (p.notes) {
            actCampaignAddField(c, 'NOTES_RELATIONSHIPS', p.notes, natives);
        }
/*
        if (p.latestEvent) {
            actCampaignAddField(c, 'EVENT_ID', p.latestEvent.event_id, natives);
            actCampaignAddField(c, 'EVENT_DATE', p.latestEvent.event_date, natives);
        }
*/
       
    } catch (err) {
            
    }
    console.log('create ACT CONTACT ' + JSON.stringify(c));
    return c;


}

export function makeDealFromFullDeal(inDeal: ActiveCampaignDeal): ActiveCampaignDeal {
    const newDeal: ActiveCampaignDeal = {
        id: inDeal.id,
        contact: inDeal.contact,
        account: inDeal.account,
        description: inDeal.description,
        currency:  inDeal.currency,
        group: inDeal.group,
        owner: inDeal.owner,
        percent: inDeal.percent,
        stage:  inDeal.stage,
        status: inDeal.status,
        title: inDeal.title,
        value: inDeal.value,
        fields: []
    }
    for (const f of inDeal.fields) {
        newDeal.fields.push({customFieldId: f.customFieldId, fieldValue: f.fieldValue, fieldCurrency: f.fieldCurrency? f.fieldCurrency: undefined})
    }

    return newDeal;
}
