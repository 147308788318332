import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';




import { WebsiteService } from 'src/services/website/website.services';


@Component({
  selector: 'app-web-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class WebHomeComponent implements OnInit {

  showSpinner = false;
  webpage: any;

  constructor(
    private webService: WebsiteService
  ) {
    //webService.getWebPage(246856).subscribe((result) => {
     // this.webpage = result.content.rendered;
    //});
    }

  ngOnInit() {
    
  }
}
