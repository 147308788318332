
<my-slide-panel [activePane]="currentPane">

	 <section centerPane>
		 <div class="action">

			<span *ngIf="pipelines">
				<label class="search"><h3>Select a PipeLine:</h3></label> 
				<mat-card class="bo9mat">
				<select matNativeControl  [(ngModel)]="selectedPipeLine" name="selectedPipeLine" (change)="selectPipeline()" multiple>
					<option *ngFor="let nnI of pipelines.dealGroups" [value]="nnI.id">
					  {{ nnI.title }}
					</option>
				  </select>
				</mat-card>
				<span *ngIf="stages.length > 0">
					<label class="search"><h3>Select a Stage:</h3></label> 
				<mat-card class="bo9mat">
				  <select matNativeControl  [(ngModel)]="selectedStage" name="selectedStage" multiple>
					<option *ngFor="let nnI of stages" [value]="nnI.id">
					  {{ nnI.title }}
					</option>
				  </select>
				</mat-card>
			</span><br />
				  
				  Seach Results to include: <input type="text" [(ngModel)]="searchString" name="searchString"/> <br />
				  <mat-checkbox (change)="selectAsc()" [checked]="orderAsc">Order Oldest First</mat-checkbox>
				<button mat-flat-button color="primary" name="search" (click)="onClickSearch()">Load</button>
			</span>

			 <span>
				 <mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="30" class='spin'> </mat-progress-spinner>
			 </span>

			 <span>
				 <button mat-stroked-button (click)="onRefreshClick()">Refresh<mat-icon>refresh</mat-icon></button>
			 </span>
		 </div>


		<table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>

		 <ng-container matColumnDef="id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Deal Id </th>
			<td mat-cell *matCellDef="let element">{{element.id}}</td>
		 </ng-container>
		 <ng-container matColumnDef="title">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
			<td mat-cell *matCellDef="let element" > {{element.title}} </td>
		 </ng-container>

		 <ng-container matColumnDef="stage">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Stage </th>
			<td mat-cell *matCellDef="let element" > 
				<mat-select matNativeControl [(ngModel)]="element.stage" (selectionChange)="stageSelected($event, element)">
					<mat-option *ngFor="let nns of stages" [value]="nns.id">
					  {{ nns.title }}
					</mat-option>
				  </mat-select>
			</td>
		 </ng-container>
		 <ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
			<td mat-cell *matCellDef="let element" > {{element.status}} </td>
		 </ng-container>
		 <ng-container matColumnDef="contact">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Contact </th>
			<td mat-cell *matCellDef="let element" > {{element.dealFields.email}} 
				 </td>
		 </ng-container>
		 <ng-container matColumnDef="expandedDetail">
			<td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
			   <div class="example-element-detail"  >
				   <span *ngIf="element.fields">
				Extra Info: Id-{{ element.fields[1]?.fieldValue }}  {{ element.fields[2]?.fieldValue }} {{ element.fields[8]?.fieldValue }} {{ element.fields[10]?.fieldValue }}
					</span>
			   </div>
			</td>
		 </ng-container>
		 <ng-container matColumnDef="mdate">
			<th mat-header-cell *matHeaderCellDef  mat-sort-header> Modified </th>
			<td mat-cell *matCellDef="let element"> {{dateFormating(element.mdate, "ts")}} </td>
		 </ng-container>


		 <ng-container matColumnDef="delete">
			<th mat-header-cell *matHeaderCellDef  mat-sort-header> Delete </th>
			<td mat-cell *matCellDef="let element"> <button mat-button type="button"  [disabled]="disableBtn"  (click)="onDelete(element)"> <mat-icon>delete_forever</mat-icon></button> 
			  </td>
		 </ng-container>

		 <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		 <tr mat-row *matRowDef="let row; columns: displayedColumns;"
											   class="example-element-row"
											   [class.example-expanded-row]="expandedElement === row"
											   (click)="expandedElement = row"
										   >
		 </tr>
		 <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
		 
		</table>
		<mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]"  (page)="onChangePage($event)"></mat-paginator>
	 </section>


</my-slide-panel>
