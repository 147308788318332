import { DateToYYMMDDhhmmss } from '../../helpers/time.helper';
import { DateStringType } from "../BO9_base/BO9_base.model";

export interface MediaItem {
    url: string;
    status: string;
    notes: string;
}

export interface MediaType {
    permission: boolean;
    updatedAt: DateStringType;
    items: MediaItem[];
}

export interface MediaGroupType {
    photo: MediaType;
    video: MediaType;
}

export function mkMTMedia(): MediaGroupType {
    return( {
        photo: {
            permission: false,
            updatedAt: DateToYYMMDDhhmmss(),
            items: []
        },
        video:  {
            permission: false,
            updatedAt: DateToYYMMDDhhmmss(),
            items: []
        }
    })

}