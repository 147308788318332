import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription, timer } from 'rxjs';
import { mkEmptyPerson, Person, getGender } from '../../../../../bo9-shared/models/person/person.model';
import { addNaturalNumber, NaturalNumber } from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';
import { validationMessages } from 'src/helpers/form.helper';
import { contactFullName, canContact, primaryEmail, contactFirstLastName } from '../../../../../bo9-shared/models/contact/contact.model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';

import { SearchService } from 'src/services/search/search.service';
import { actCampaignDelay, contactLimit } from 'src/config/webapp.config';

import { ActiveCampaignService } from 'src/services/activecampaign/activecampaign.service';
import { EventEmitterService } from 'src/services/events/events.service';
import { dateFromYYYYMMDD, DateToYYMMDDhhmmss, dateFormating } from '../../../../../bo9-shared/helpers/time.helper';
import { formPerson, makeFormPersonFromPerson } from '../../../../../bo9-shared/models/person/formPerson.model';
import { Relation } from '../../../../../bo9-shared/models/person/relation.model';
import { Difference } from '../../../../../bo9-shared/models/person/differences.model';
import { clearRoles, removeRole, userIsAdmin, userIsSuperAdmin } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { ActCamFields } from '../../../../../bo9-shared/models/mailCampaign/activeCampaign.model';
import { searchParams, Visit } from '../../../../../bo9-shared/models/page/page.model';
import { removeString } from '../../../../../bo9-shared/helpers/text.helper';
import { MemberService } from 'src/services/memberService/member.service';

@Component({
  selector: 'directory-list',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.scss'],
  // make fade in animation available to this component

})
export class DirectoryComponent implements OnInit, OnDestroy, AfterViewInit, OnDestroy {
  messagesLoading$: Observable<boolean>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('table', {read: ElementRef}) table: ElementRef;
  people$: Observable<Person[]>;
  totalFound: number=0;
  totalFound$: Observable<number>;
  pageNum$: Observable<number>;
  totalPages$: Observable<number>;
  pageSize$: Observable<number>;
  capabilitySearch: string[] =[];
  pageNum: number=0;
  totalPages: number;
  pageSize: number=15;
  people: Person[];
  nnSearch: number[] = [];
  currentPane = 'center';
  valid: false;
  fromI$:Observable<string>;
  fromF$:Observable<Person>;
  fromA$:Observable<string>;
  fromG$:Observable<string>;
  email: string;
  fromName= 'Not Set';

  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string='';
  fieldValues: number;
  formPersons: formPerson[] =[];

  currentP$: Observable<Person>;
  currentIKey$: Observable<string>;
  currentPerson: Person;
  RelateForm: FormGroup;
  loading= false;
  invitingPersonName: string;
  familyPersonName: string;
  operation = '';
  contactLmt = contactLimit;
  makeEmail = false;
  emailer: Person;
  user: Person;

  origP: Person;
  realP: Person;
  newP: Person;
  diffs: Difference[];

  searching = false;
  length=0;
  selectedOption='Select One';

  actCamp$:Observable<any>;
  actCamp:any;
  visit: Visit;
  history: searchParams[];
  searches: searchParams[];
  identifiers: string[]=[];
  validationMessages = validationMessages;
  public displayedColumns = ['name', 'capability', 'identifier'];

  public dataSource = new MatTableDataSource<formPerson>();
  nnKeys: number[]=[0,1,2,3,4,5,6,7,8,9,99];
  selections = []
  subscriptions: Subscription[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private arangoService: ArangoService,
    private searchService: SearchService,
    private activeCampaignService: ActiveCampaignService,
    private auth: AuthService,
    private memberService: MemberService,
    private router: Router,
    private emitterService: EventEmitterService
    )
  {

    this.user = this.auth.getAuthUser();

    this.visit = {
      page: this.router.url, component: 'DirectoryComponent', 
      visitor: this.user._key,
      help: false
    };
   
    this.subscriptions.push(this.arangoService.getIdentifiers()
    .subscribe(async (res) => {
      console.log('get identifers ' + JSON.stringify(res));
      for (const cat of res.content) {
        this.identifiers.push(cat.Name);
      }
    }
    ));
  


    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #028'); 
      this.router.navigate(['login'] );
    } else {
      if (userIsSuperAdmin(this.user)) {
        console.warn('User is super ');
        this.selections.push('SysRoles');
      }
    }
     this.subscriptions.push( this.activeCampaignService.getContactTagFieldValues(false) // people fields
     .subscribe( (res) => {
        this.makeForm();
     }))
    this.getPeople()

  }


  isSuperAdmin() {
    return userIsSuperAdmin(this.user);
  }

  isAdmin() {
    return userIsAdmin(this.user);
  }

  get emailControl() { return this.RelateForm.controls.email; }
  get nameControl() { return this.RelateForm.controls.name; }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }
  makeForm() {

  }

  ngOnDestroy() {
    console.log('Destory person page');
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  ngOnInit() {
    this.formPersons = [];
    this.searching = false;
  }


  onPageChange(event){
    console.log(event);
    console.log(event);
    const oldPage = this.pageNum
    this.pageNum = event.pageIndex
    
    
  }




	showKey (key: String) {
		alert(key);
	}




  async getPeople() {

    const resI = await firstValueFrom(this.arangoService.getIdentifiers())
    this.identifiers = [];
      for (const cat of resI.content) {
        this.identifiers.push(cat.Name);
      }

    this.makeEmail= false;
    this.loading = true;
    let startWith =0;
    if (this.user.preferences) {
      this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }
    let limit = this.pageSize+1;
   

    this.selectedOption='Select One';
    // this.arangoService.search(this.searchString, (this.pageNum -1)* this.pageSize, this.pageSize)
    if (this.pageNum ===0) {
      this.dataSource.data=[];
      this.formPersons = [];
      this.length=0;
    } else {
      limit = this.pageSize;
      startWith = this.pageNum* this.pageSize + 1
    }
    console.log(`Search start ${startWith} limit ${limit}`);

 



   const res = await firstValueFrom(this.searchService.search("", this.nnSearch, ["Apprentice"], startWith, 100));
     // console.log('search  ' + JSON.stringify(res.content.persons));
    this.people = res.content;
    this.people.sort((a: Person, b: Person) => {
      if (a.contact.names.name.first > b.contact.names.name.first) {
        return 1;
      } else { return -1}
    })
      this.fieldValues = 0;
      this.searching = false;
      if (this.people) {
        for (let i=0; i< this.people.length; i++) {
          let actP:ActCamFields = null;
          if (!this.people[i].primaryEmail || this.people[i].primaryEmail=='') {
            this.people[i].primaryEmail = primaryEmail(this.people[i].contact);
          }
      

          const roles = await firstValueFrom(this.arangoService.getRoles(this.people[i]));
          this.people[i].systemRoles = roles && roles.content[0]? roles.content[0].systemRoles: [];
          const fp = makeFormPersonFromPerson(this.people[i], actP);
        
          if (this.isApprentice(this.people[i]) || this.isJourneyman(this.people[i]) ||this.isSenior(this.people[i]) ||this.isMaster(this.people[i])  ) { // If the system is restarting, flush this entry

            this.formPersons.push(fp);
            this.length = this.formPersons.length;
            this.dataSource.data = this.formPersons as formPerson[];
          }

          this.fieldValues++;
          this.capabilitySearch = [];
      }
      }

      this.fieldValues = contactLimit;
      //this.dataSource.data = this.formPersons as formPerson[];
      this.loading = false;
      //this.table.nativeElement.scrollIntoView(true);
  }




	dateFormating(date: any, kind: string) {
		return dateFormating(date, kind);
  }
  isJourneyman(p: Person): boolean {
    if (p && p.systemRoles && (p.systemRoles.includes('Partner') || p.systemRoles.includes('Journeyman')) ) {
         return true

    } else {
      return false
    }

  }
  isSenior(p: Person): boolean {
    if (p && p.systemRoles && (p.systemRoles.includes('Practicing') || p.systemRoles.includes('SeniorJ')) ) {
         return true

    } else {
      return false
    }

  }

  isMaster(p: Person): boolean {
    if (p && p.systemRoles && p.systemRoles.includes('Master')) {
         return true

    } else {
      return false
    }

  }

  isApprentice(p: Person): boolean {
    if (p && p.systemRoles && p.systemRoles.includes('Apprentice')) {
         return true

    } else {
      return false
    }

  }

  canIdentify(p: Person): boolean {
    if (p) {
      const checkName = contactFirstLastName(p.contact);
      for (const name of this.identifiers) {
        if (name === checkName) {
          return true
        }
      }
    } 
    return false

  }




  isSearch(cap: string) {
    return this.capabilitySearch.includes(cap);
  }
  
  clickSearch(cap: string) {
      this.capabilitySearch = [cap]
  }

}
