import { DateStringType } from "./BO9_base.model";
import { BO9UUID } from "../UUID.model";
import { DateToYYMMDDhhmmss } from "../../helpers/time.helper";
import { createUUID } from "../../uuid.service";

export interface Meta {
   dateCreated: DateStringType;
   lastModified: DateStringType;
   createdBy: BO9UUID;
   modifiedBy: BO9UUID;
}

export function updateMeta (uuid?: BO9UUID, metaIn?: Meta | null): Meta {
    const metaOut: Meta = {
        dateCreated: '',
        lastModified: '',
        createdBy: '',
        modifiedBy: ''
    }
    let newuuid = uuid? uuid: '';

    if (newuuid == null || newuuid == '') {
        newuuid = createUUID();
    }
    // console.log(" Meta UUID "+ JSON.stringify(newuuid));
  
    if ((metaIn == null) || (metaIn.createdBy == null)) {
        metaOut.createdBy = newuuid;
        metaOut.dateCreated = DateToYYMMDDhhmmss(new Date())
    }
    metaOut.modifiedBy = newuuid;
    metaOut.lastModified = DateToYYMMDDhhmmss(new Date())

    return metaOut;

}