import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';


import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { MatTableDataSource} from '@angular/material/table';
import { formPerson, makeFormPersonFromPerson } from '../../../../../bo9-shared/models/person/formPerson.model';

import { SearchService } from 'src/services/search/search.service';
import { dateFormating } from '../../../../../bo9-shared/helpers/time.helper';
import { Subscription } from 'rxjs';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth/auth.service';


@Component({
  selector: 'app-dialog-selectPerson',
  templateUrl: './selectPersonDialog.component.html',
  styleUrls: ['./selectPersonDialog.component.scss'],

})

export class SelectPersonDialogComponent implements OnInit  {

  public displayedColumns = ['select', 'email', 'name', 'naturalNumber', 'source', 'identified', 'primary','admin'];
  public dataSource = new MatTableDataSource<formPerson>();

  user: Person;
  formPersons: formPerson[];
  disableBtn = false;
  selected: formPerson[] = [];
  searchString: string;
  loading: boolean;
  subscriptions: Subscription[] = [];
  constructor(
    private arangoService: ArangoService,
    private dialogRef: MatDialogRef<SelectPersonDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: { persons: Person[], grouped: boolean } ,
    private searchService: SearchService,
    private auth: AuthService,
    private router: Router,
   ) {
      console.log ('Init select person ' + JSON.stringify(data));
      if (data.grouped) {
        this.displayedColumns = ['select', 'email', 'name', 'naturalNumber', 'source', 'identified', 'primary','admin'];
      } else {
        this.displayedColumns = ['select', 'email', 'name', 'naturalNumber', 'source', 'identified'];
      }
      this.user = this.auth.getAuthUser();
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'SelectPersonDialogComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
     }

     dateFormating(date: any, kind: string) {
      return dateFormating(date, kind);
    }

  ngOnInit() {

    console.log ('Init select person init ' + JSON.stringify(this.data));
    this.dataSource.data = [];

  }

  onClickSearch($event: any) {
    if ($event) {
      $event.preventDefault();
    }
    this.getPeople(this.searchString);
  
  }



  getPeople(search: string) {

    this.formPersons = [];
    this.loading = true;
    this.subscriptions.push( this.searchService.search(search, null, null, 0, 60)
    .subscribe({next:  (res: any) => {
     console.log('search  ' + JSON.stringify(res));
     if (res.content  && res.content.length> 0) {
      for (const p of res.content) {
        const fp = makeFormPersonFromPerson(p);
        fp.selected = false;
          
        this.formPersons.push(fp);
        this.dataSource.data = this.formPersons as formPerson[];
       }
     } else {
       alert("No-one matches "+search);
     }

      this.loading = false;
      
    }}))

  }


  selectFormPerson(p: formPerson ) {
    for (const fp of this.formPersons) {
      if (fp._key ===  p._key) {
        fp.selected = !fp.selected;
        this.dataSource.data = this.formPersons as formPerson[];
      }
    }

  }

  selectSinglePerson(p: formPerson ) {
    this.dialogRef.close([p]);
  }

  selectAndClose() {
    this.selected = [];
    if (!this.formPersons) {
      alert("No one in list ");
    } else {
      for (const fp of this.formPersons) {
        if (fp.selected) {
          this.selected.push(fp);
        }
      }
      if (this.selected.length > 0) {
        this.dialogRef.close(this.selected);
      } else {
        alert("No one chosen");
      }

    }

  }


  close() {
    this.dialogRef.close([]);
  }
}
