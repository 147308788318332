import bitwise from 'bitwise';
import { Capabilities } from '../models/person/capabilities.model';

export type Bit = 0 | 1;

export type Word32Bit = 0x00000000;

export function createWord(): Word32Bit {
    const w = 0x00000000 as Word32Bit;
    return w;
}

export function setBit(num: Word32Bit, pos: Capabilities): Word32Bit {
    return bitwise.integer.setBit(num, pos, 1) as Word32Bit
}

export function unSetBit(num: Word32Bit, pos: Capabilities): Word32Bit {
    return bitwise.integer.setBit(num, pos, 0) as Word32Bit
}

export function isSet(num: Word32Bit, pos: Capabilities): boolean {
    const isset = bitwise.integer.getBit(num, pos) 
    return ( isset === 1)
}