import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';

import {StyleManager} from '../style-manager';
import {DocsSiteTheme, ThemeStorage} from '../theme-storage/theme-storage';

import { AuthService } from 'src/services/auth/auth.service';

import { Person } from '../../../../../bo9-shared/models/person/person.model'
import { Router } from '@angular/router';
import { InnerMessage } from '../../../../../bo9-shared/models/innermessage.model';
import { BO9UUID } from '../../../../../bo9-shared/models/UUID.model';
import { userIsMaster, userIsAdmin, userIsJourneyman, userIsApprentice } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { config } from 'rxjs';
import { srvrVersion, webAppVersion } from 'src/config/webapp.config';



import {ActivatedRoute, ParamMap} from '@angular/router';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

import { LoginService } from 'src/services/loginservice/login.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelpDialogComponent } from 'src/displayComponents/dialogs/helpDialog/helpDialog.component';
import { EmailDialogComponent } from 'src/displayComponents/dialogs/emailDialog/emailDialog.component';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { DeviceDetectorService} from 'ngx-device-detector'

@Component({
  selector: 'app-homenav',
  templateUrl: './homenav.component.html',
  styleUrls: ['./homenav.component.scss'],
  providers: [StyleManager, ThemeStorage],
})
export class HomeNavComponent implements OnInit {
 
  private _queryParamSubscription = Subscription.EMPTY;
  currentTheme: DocsSiteTheme;

  themes: DocsSiteTheme[] = [

    {
      primary: '#3F51B5',
      accent: '#E91E63',
      name: 'light-theme',
      isDark: false,
      isDefault: true,
    },
  ];

  user: Person;
  srvrVersion = srvrVersion;
  version = webAppVersion;

  selectedOrg: BO9UUID;
  serverVersion: string = '';
  isLightTheme:boolean;
  isDarkTheme:boolean;
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private loginService: LoginService,

    private dialog: MatDialog,
    public styleManager: StyleManager,
    private _themeStorage: ThemeStorage,
    private _activatedRoute: ActivatedRoute,
    private arangoService: ArangoService,
    private deviceService: DeviceDetectorService
    
  ) {
    this.installTheme('light-theme');
    
    /*
    const themeName = this._themeStorage.getStoredThemeName();
    if (themeName) {
      this.installTheme(themeName);
    } else {
      this.installTheme('light-theme');
    }
    */

    const result = this.arangoService.getServerVersion()
    .subscribe( (res) => {
      this.serverVersion = res.content;
    })
  }

  isMobile(): boolean {
    return this.deviceService.isMobile()
  }


  ngOnInit() {
    console.log('Init nav');
    this.user = this.auth.getAuthUser();


    this._queryParamSubscription = this._activatedRoute.queryParamMap
      .pipe(map((params: ParamMap) => params.get('theme')))
      .subscribe((themeName: string | null) => {
        if (themeName) {
          this.installTheme(themeName);
        }
    });
  }

  ngOnDestroy() {
    this._queryParamSubscription.unsubscribe();
   
  }

  support() {
    const dRef = this.dialog.open(EmailDialogComponent, {
      width: '75%',
      height: '75%',
      data: {from: this.user}
    });

    this.subscriptions.push( dRef.afterClosed()
    .subscribe( () => {
      
    }))
  }

  releaseNotes() {
    const dRef = this.dialog.open(HelpDialogComponent, {
      width: '75%',
      height: '75%',
      data: {isRel: true}
    });

    this.subscriptions.push( dRef.afterClosed()
    .subscribe( () => {
      
    }))
  }
  isMaster(): boolean {
    return(userIsMaster(this.user));
  }
  help() {
    const dRef = this.dialog.open(HelpDialogComponent, {
      width: '75%',
      height: '75%',
      data: {}
    });

    this.subscriptions.push( dRef.afterClosed()
    .subscribe( () => {
      
    }))
  }
  logout() {
    console.log('LOGOUT');
    //this.websocketService.closeSocket(); // cant put in loginservice anymore
    this.loginService.signOut(this.user._key);
  }

  isAdmin(): boolean {
      return(userIsAdmin(this.user));
    }

    isJourneyman(): boolean {
      return(userIsJourneyman(this.user));
    }

    isApprentice(): boolean {
    return(userIsApprentice(this.user));
  }

  isUser(): boolean {
    return(! (userIsAdmin(this.user) || userIsMaster(this.user)))
  }

  installTheme(themeName: string) {
    const theme = this.themes.find(currentTheme => currentTheme.name === themeName);
    console.log("Install theme "+themeName);
    if (!theme) {
      return;
    }

    if(themeName == 'dark-theme') {
      this.isLightTheme = false;
      this.isDarkTheme = true;
    } else {
      this.isLightTheme = true;
      this.isDarkTheme = false;
    }

    this.currentTheme = theme;

    if (theme.isDefault) {
      this.styleManager.removeStyle('theme');
    } else {
      this.styleManager.setStyle('theme', `styles/${theme.name}.css`);
    }

    if (this.currentTheme) {
      this._themeStorage.storeTheme(this.currentTheme);
    }
  }
}
