import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { FormGroup} from '@angular/forms';
import { MatTableDataSource} from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { meetingUrl } from 'src/config/webapp.config';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { AuthService } from 'src/services/auth/auth.service';

import { dateFormating } from '../../../../../bo9-shared/helpers/time.helper';
import { Member, AffiliateByID } from '../../../../../bo9-shared/models/member/member.model';
import { AttendedLog, EventCounts, EventParams } from '../../../../../bo9-shared/models/event/event.model';


import { Person } from '../../../../../bo9-shared/models/person/person.model';

import { getProductFromSKU, Product } from '../../../../../bo9-shared/models/product/product.model';
import { ProgramDefinition, ProgramNotification } from '../../../../../bo9-shared/models/program/program.model';



@Component({
  selector: 'mea-list',
  templateUrl: './meaList.component.html',
  styleUrls: ['./meaList.component.css'],
})

export class MEAListComponent implements OnInit, OnDestroy,OnChanges {
  @Input() person: Person;
  @Input() packages: ProgramNotification[];
  form: FormGroup;
  subscriptions: Subscription[] = [];

  products: Product[]=[];
  disableBtn = false;
  currentPerson: Person;
  eventParams: EventParams[];
  RelationForm: FormGroup;
  pageSize: number = 15;
  loading = false;
  editing = false;
  fieldValues = 0;
  affiliate: Member;
  opernnid: boolean;
  purchaseOperNNId= false;
  formGroup: FormGroup;
  public displayedColumns = ['first', 'last','email', 'assigned', 'used','id','link'];
  user: Person;
  meetingUrl = meetingUrl;
  public dataSource = new MatTableDataSource<EventParams>();

 /*
  id: string;
    first: string;
    last: string;
    email: string;
    creatorKey: string;
    link: string;
    assignedDate: string;
    usedDate?: string;
    unassignedDate?: string;
    */

  constructor(
    private auth: AuthService,

    private arangoService: ArangoService,
    private router: Router,
    ) {
      this.user = this.auth.getAuthUser();
      if (!this.person) {
        this.person = this.user;
      }
      this.eventParams = [];
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'MEAListComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {
        this.loadEPs();
      }})); 
     }
     
  loadEPs() {
    this.loading=true;
    this.subscriptions.push(this.arangoService.getEventParamsByCreator(this.person._key)
    .subscribe( (result)=> {
      if (result && result.content && result.content.length > 0) {
        this.eventParams = [];

        for( const ep of result.content) {
          console.log("Url "+ep.link);
          ep.link = ep.link.replace("https://meeting.bodyof9.com/","");
          ep.link = ep.link.replace("/#/","");
          this.eventParams.push(ep);
        }
        this.dataSource.data = this.eventParams;
      }
      this.loading=false;
    }))
  }

  memberSubmittedHandler($event) {
    console.log('memberSubmitted  '+ JSON.stringify($event));
    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.loadEPs();
    }
    this.editing = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onClickInvite() {
    this.editing = true;
  }
  onCloseClick() {
    this.editing = false;
  }

  ngOnInit() {
    if (this.person) {
      this.dataSource.data = this.eventParams;
    }

  }
  ngOnChanges() {
    if (this.person) {
     this.loadEPs
    }
  }

  dateFormating(date: any, kind: string) {
		return dateFormating(date, kind);
	}

  
}
