import { FormArray, FormGroup } from "@angular/forms";

export let validationMessages = {
    usage: [
    ],
    primaryEmail: [
      { type: 'required', message: 'PrimaryEmail is required.' },
    ],
    gender: [
      { type: 'required', message: 'Gender is required.' },
    ],
    username: [
      { type: 'required', message: 'Username is required.' },
      { type: 'minlength', message: 'Username must be at least 5 characters long.' },
      { type: 'maxlength', message: 'Username cannot be more than 25 characters long.' },
      { type: 'pattern', message: 'Your username must contain only numbers and letters.' },
      { type: 'validUsername', message: 'Your username has already been taken.' }
    ],
    eventName: [
      { type: 'required', message: 'Event Name is required.' }
    ],
    last: [
      { type: 'required', message: 'Last name is required.' },
      { type: 'minlength', message: 'Last name must be at least 3 characters long.' },
      { type: 'maxlength', message: 'Last name cannot be more than 25 characters long.' },
      { type: 'pattern', message: 'Your Last Name must contain only letters.' },
    ],
    first: [
        { type: 'required', message: 'First name is required.' },
        { type: 'minlength', message: 'First name must be at least 3 characters long.' },
        { type: 'maxlength', message: 'First name cannot be more than 25 characters long.' },
        { type: 'pattern', message: 'Your First Name must contain only letters.' },
      ],
      middle: [

      ],
      templateID: [],
      slug: [],
      queryParameters: [],
      title: [

      ],
      bio: [

      ],
      notes: [

      ],
      howIdentified: [],
      identifiedBy: [],
    email: [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Enter a valid email.' }
    ],
    phone: [
      { type: 'required', message: 'Phone is required.' },
      { type: 'validCountryPhone', message: 'Phone incorrect for the country selected' },
      { type: 'minlength', message: 'Please include the area code.' },
      { type: 'pattern', message: 'Phone - only numbers spaces and - .' },
    ],
    password: [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'Password must be at least 5 characters long.' },
      { type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, and one number.' }
    ],
    confirm_password: [
      { type: 'required', message: 'Confirm password is required' }
    ],
    matching_passwords: [
      { type: 'areEqual', message: 'Password mismatch' }
    ],
    terms: [
      { type: 'pattern', message: 'You must accept terms and conditions.' }
    ],
    type: [
        { type: 'required', message: 'You must enter an Event Type.' },
//  { type: 'checktype', message: 'Type can only be 'Identification', 'Workshop or Private'' }
      ],
    startDate: [
        { type: 'pattern', message: 'You must enter a Valid Date in the form of \'2018-05-29\'.' }
      ],
      dateIdentified: [
        { type: 'pattern', message: 'You must enter a Valid Date in the form of \'2018-05-29\'.' }
      ],
    description: [
        { type: 'minlength', message: 'You must enter at least a 5 character description ' },
        { type: 'required', message: 'You must enter a  description ' }
    ],
    eventNumber: [
        { type: 'required', message: 'Event Number is required' }
    ],
    name: [],
    street1:[],
    street2:[],
    content:[],
    city: [
      { type: 'required', message: 'City is required' },
      { type: 'maxlength', message: 'Max length 30 for City ' },
      { type: 'pattern', message: 'Only alpha and spaces .' }
    ],
    state: [
      { type: 'required', message: 'State is required' },
      { type: 'maxlength', message: 'Max length 30' },
      { type: 'pattern', message: 'Only alpha and spaces .' }
    ],
    zip: [
    { type: 'required', message: 'Zip/Postal Code is required' },
    { type: 'minlength', message: 'Min length 5.' },
    { type: 'maxlength', message: 'Max length 30.' },
    { type: 'pattern', message: 'Only alpha and spaces .' },
    ],
    country: [
      { type: 'required', message: 'Country is required' },
      { type: 'maxlength', message: 'Max length 30.' },
    { type: 'pattern', message: 'Only alpha and spaces .' },
    ],
    volume: [
      { type: 'required', message: 'Volume is required' }
    ]
  };


export function capitalizeFirstLetter(s: string): string {
    return s && s.charAt(0).toUpperCase() + s.slice(1);
}

export function findInvalidControlsRecursive(formToInvestigate: any):string[] {
  var invalidControls:string[] = [];
  let recursiveFunc = (form:FormGroup|FormArray) => {
    Object.keys(form.controls).forEach(field => { 
      const control = form.get(field);
      if (control) {
          if (control.invalid) invalidControls.push(field);
          if (control instanceof FormGroup) {
            recursiveFunc(control);
          } else if (control instanceof FormArray) {
            recursiveFunc(control);
          } 
      }
     
    });
  }
  recursiveFunc(formToInvestigate);
  return invalidControls;
}