import { Component, OnDestroy, Input, OnChanges, Output, EventEmitter, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { firstValueFrom, Subscription } from 'rxjs';
import { canContact, contactFullName, mkMTContact } from '../../../../../bo9-shared/models/contact/contact.model';
import { validationMessages } from 'src/helpers/form.helper';
import { Event, EventAttendee, attendeeUnique, setIdentifiedAttendeeNaturalNumber } from '../../../../../bo9-shared/models/event/event.model';
import { createUUID } from '../../../../../bo9-shared/uuid.service';
import { DateToYYMMDD, DateToYYMMDDhhmmss, OnlyTimePart } from '../../../../../bo9-shared/helpers/time.helper';
import { EventDBService } from 'src/services/events/eventdb.service';
import { EventEmitterService } from 'src/services/events/events.service';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { formPerson } from '../../../../../bo9-shared/models/person/formPerson.model';
import { MatDialog } from '@angular/material/dialog';
import { SelectPersonDialogComponent } from 'src/displayComponents/dialogs/selectPersonDialog/selectPersonDialog.component';
import { findInvalidControlsRecursive } from '../../../helpers/form.helper';
import { addPhone, existingPhoneNumber } from '../../../../../bo9-shared/models/contact/phone.model';
import { BO9Address, existingAddress, mkMTAddress } from '../../../../../bo9-shared/models/contact/address.model';
import { GenderTypes, Person, getGender, personFromAttendee } from '../../../../../bo9-shared/models/person/person.model';
import { SearchService } from 'src/services/search/search.service';
import { primaryEmail } from '../../../../../bo9-shared/models/contact/contact.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/services/auth/auth.service';
import { NaturalNumber, NaturalNumberTypes } from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';
import { ActiveCampaignService } from 'src/services/activecampaign/activecampaign.service';
import { addPurchase, createPurchaseFromSubscription } from '../../../../../bo9-shared/models/purchase/purchase.model';
import { makeDefaultPearl } from '../../../../../bo9-shared/models/product/product.model';
import { PearlService } from 'src/services/pearl/pearl.service';
import { element } from 'protractor';
import { userIsAdmin } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';


export interface IdentifiedFormValues {
  email: string;
  first: string;
  last: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  gender: string;
  naturalNumber: string;
  idBy: string;
  idAt: string;
  }

@Component({
    selector: 'identified-form',
    templateUrl: './identifiedForm.component.html',
    styleUrls: ['./identifiedForm.component.scss'],
 
  })
  export class IdentifiedFormComponent implements OnDestroy, OnChanges, AfterViewInit {
    @Input() attendee: EventAttendee;
    @Input() attendees: EventAttendee[];
    @ViewChild('addidentified', {read: ElementRef}) addIdentifiedDiv: ElementRef;
    name: string;
    form: FormGroup;
    nnKeys: string[];
    nnSource: string[];
    user: Person;
    identifiers: string[] = [];
    subscriptions: Subscription[] = [];
    validationMessages = validationMessages;
  
    genderTypes = GenderTypes;
    selectedPerson: boolean;

    disableBtn = false;
  
    get value(): IdentifiedFormValues {
      console.log('name get '+ JSON.stringify(this.form.value));
      return this.form.value;
    }
  
    set value(value: IdentifiedFormValues) {
      console.log('name get '+ JSON.stringify(value));
      this.form.setValue(value);
    }

    get firstControl() {
        return this.form.controls.first;
    }

    get lastControl() {
        return this.form.controls.last;
    }
    get phoneControl() {
      return this.form.controls.phone;
    }
    get genderControl() {
      return this.form.controls.gender;
    }

    get emailControl() {
      return this.form.controls.email;
    }
    constructor(private formBuilder: FormBuilder, 
      private emitterService: EventEmitterService,
      private activeCampaignService: ActiveCampaignService,
      private arangoService: ArangoService,
      private searchService: SearchService,
      private route: ActivatedRoute,
      private pearlService: PearlService,
      private router: Router,
      private auth: AuthService,
      private eventDBService: EventDBService) {

    this.makeForm();
    this.selectedPerson = false;
    this.route.params.subscribe( async (params) => {
       
      console.log(params); // log the entire params object

      this.user = this.auth.getAuthUser();
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'IdentifiedFormComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
      this.subscriptions.push(this.arangoService.getIdentifiers()
      .subscribe( (res) => {
        console.log('get identifers ' + JSON.stringify(res));
        for (const cat of res.content) {
          this.identifiers.push(cat.Name);
        }    
      }));
    });
      this.nnKeys = Object.keys(NaturalNumber).map(key => NaturalNumber[key]).filter(value => typeof value === 'string') as string[];
      this.nnSource = Object.keys(NaturalNumberTypes).map(key => NaturalNumberTypes[key]).filter(value => typeof value === 'string') as string[];

    }

    ngAfterViewInit() {
      this.addIdentifiedDiv.nativeElement.scrollIntoView();
    }
  
    isAdmin(): boolean {
      return userIsAdmin(this.user);
     }
 

    makeForm() {
      this.form = this.formBuilder.group({
        first: [' ', Validators.compose([Validators.required])],
        last: [' ', Validators.compose([Validators.required])],
        email: ['', Validators.compose([])],
        city: ['', Validators.compose([])],
        state: ['', Validators.compose([])],
        phone: ['', Validators.compose([])],
        gender: ['', Validators.compose([])],
        zip: [' ', Validators.compose([])],
        country: [' ', Validators.compose([])],
        _key: ['', Validators.compose([])],
        DNC: [false, Validators.compose([])],
        naturalNumber: [false, Validators.compose([Validators.required])],
        idBy: [false, Validators.compose([Validators.required])],
        idAt: [false, Validators.compose([Validators.required])],
        dateIdentified: ['', Validators.compose([Validators.required])],
        sourceType: ['Asserted', Validators.compose([Validators.required])],
        notes: ['', Validators.compose([])],
      });
      
    }

    ngOnChanges() {
      console.log('OnChange ');
      this.form.reset();
      if ( this.addIdentifiedDiv) {
        this.addIdentifiedDiv.nativeElement.scrollIntoView();
      }
      
      if (this.attendee) {
        this.form.patchValue(this.attendee);
        if (this.attendee.person ) {
          if (this.attendee.person.contact.phone && this.attendee.person.contact.phone.items && this.attendee.person.contact.phone.items[0] && this.attendee.person.contact.phone.items[0].number) {
            this.form.controls.phone.setValue(this.attendee.person.contact.phone.items[0].number);
          } else {
            this.form.controls.phone.setValue('');
          }
          this.form.controls.email.setValue(primaryEmail(this.attendee.person.contact));

          
          this.form.controls.gender.setValue(getGender(this.attendee.person.details.gender?this.attendee.person.details.gender:''));
          this.form.controls.DNC.setValue(canContact(this.attendee.person.contact)?false:true);
          this.form.controls.naturalNumber.setValue(this.attendee.nn);
          this.form.controls.idBy.setValue(this.attendee.idBy? this.attendee.idBy : [contactFullName(this.user.contact)]);
          this.form.controls.idAt.setValue(this.attendee.idAt);
          this.form.controls.dateIdentified.setValue(this.attendee.dateIdentified);
          this.form.controls.notes.setValue(this.attendee.person.notes ? this.attendee.person.notes : '');
          if (userIsAdmin(this.user)) {
            this.form.controls.sourceType.setValue( this.attendee.sourceType? this.attendee.sourceType: 'Identified')
          } else {
            this.form.controls.sourceType.setValue( this.attendee.sourceType? this.attendee.sourceType: 'Asserted')
          }
        
        }
        } else {
        this.form.controls.idBy.setValue([contactFullName(this.user.contact)]);
        this.form.controls.sourceType.setValue('Identified');
        this.form.controls.dateIdentified.setValue(DateToYYMMDD());
        }
        
    }

    selectDNC() {
      this.form.controls.DNC.setValue(!this.form.value.DNC);
      console.log('dnc'+ JSON.stringify(this.form.value.DNC));
    }

   async  submitForm() {
      console.log('Submit attendee form ' + JSON.stringify(this.form.value));

      if (this.form.valid) {
        this.disableBtn = true;
        const newAttendee: EventAttendee = {
          _key: this.form.value._key? this.form.value._key : '', // filled in on insert
          person_id: this.form.value._key? this.form.value._key : '', 
          contact: mkMTContact(),
          mailCampaignID: 0,
          event_id: this.user._key,
          paid: "0.00",
          attended: 0,
          email: this.form.value.email,
          first: this.form.value.first,
          last: this.form.value.last,
          street1: '',
          street2: '',
          city: this.form.value.city? this.form.value.city: '',
          state: this.form.value.state? this.form.value.state: '',
          phone: this.form.value.phone? this.form.value.phone:'',
          gender: this.form.value.gender,
          zip: this.form.value.zip? this.form.value.zip: '',
          country: this.form.value.country? this.form.value.country: 'USA',
          start_date: '',
          end_date: '',
          event_time: '',
          DNC: this.form.value.DNC? this.form.value.DNC: false,
          end_time: '',
          date_created: DateToYYMMDDhhmmss(),
          nn: this.form.value.naturalNumber,
          idAt: this.form.value.idAt,
          idBy: this.form.value.idBy.join(","),
          dateIdentified: this.form.value.dateIdentified,
          sourceType: this.form.value.sourceType,
          notes: this.form.value.notes

        }

        if (!this.isAdmin()) {
          newAttendee.sourceType = NaturalNumberTypes.Asserted
        }
        
          if (this.attendee) { // Passed in, ie was existing in event

            const added = await this.updatePerson(newAttendee);
            this.emitterService.emitter.emit('Message','Attendee updated in Event');
            this.form.reset();
          } else { // new 
        
                if (newAttendee.email) {
                  const checkpersons = await firstValueFrom(this.searchService.searchEmail(newAttendee.email, false));
                  if (checkpersons && checkpersons.length > 0) {
                    newAttendee._key = checkpersons[0]._key;
                    newAttendee.person_id = checkpersons[0]._key;
                  }
                }
                const added = await this.updatePerson(newAttendee);
   
                this.emitterService.emitter.emit('Message', ` ${newAttendee.email} added as Identified`);
                
                this.form.reset();

          }
          
          this.disableBtn = false;
 
      } else {
        alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.form)));
      }
    }

  
    ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
    }


    resetForm() {
      this.form.reset();
      this.disableBtn = false;
    }

    

   
    reset() {
      this.form.reset();
    }

    updatePerson(attendee: EventAttendee): Promise<boolean> {
      return new Promise ( async (resolve, reject) => {
        if (attendee.person) {
          let person = attendee.person;
          person.contact.names.name.first = this.form.value.first;
          person.contact.names.name.last = this.form.value.last;
          if (person.details) {
            person.details.gender = this.form.value.gender;
          } else {
            person.details = {
              gender : this.form.value.gender
            }
          }
          person.contact.email.DNC = attendee.DNC;
          if (!existingPhoneNumber(person.contact.phone,this.form.value.phone)) {
            person.contact.phone = addPhone(person.contact.phone,attendee.DNC, this.form.value.phone)
          }
          person.notes = attendee.notes ? attendee.notes : '';
          const newAddr: BO9Address = {
            _key: createUUID(),
            name: '',
            altitude: 0,
            latitude: 0,
            longitude: 0,
            street1: '',
            street2: '',
            city: this.form.value.city,
            state: this.form.value.state,
            zip: this.form.value.zip,
            timezone: '',
            country:  this.form.value.country,
          }
          if (!existingAddress(person.contact.address, newAddr)) {
            person.contact.address.items.push(newAddr);
          }
          person = setIdentifiedAttendeeNaturalNumber(attendee, person);

          console.log('Update exisitng person to ' + JSON.stringify(person));
          const res = await firstValueFrom(this.arangoService.updatePerson(person));
          if (attendee.sourceType == NaturalNumberTypes.Identified) {
            const p = person;
            p.purchases = addPurchase(p.purchases, createPurchaseFromSubscription(p._key,p.primaryEmail, [makeDefaultPearl()]))
            console.log(`Create Pearl Purchase for  ${p.primaryEmail} `);
            const update = await firstValueFrom( this.arangoService.updatePerson(p));
            console.log(`Create Pearl subscription for  ${p.primaryEmail} `);
            this.emitterService.emitter.emit('Message',`Create Pearl subscription for  ${p.primaryEmail} `);
            const r = await firstValueFrom(this.pearlService.subscribe(p.primaryEmail, 'daily'));
          }
          resolve(true);
        } else { // New person
          const p: Person = personFromAttendee(attendee);
 
          const res = await firstValueFrom(this.arangoService.updatePerson(p));
          if (canContact(p.contact)) {
            const resSync = await  firstValueFrom(this.activeCampaignService.syncContact(p))
            this.emitterService.emitter.emit('Message',p.primaryEmail + ' DB Updated and ActiveCampaign Updated');
          } else {
            this.emitterService.emitter.emit('Message',p.primaryEmail + ' DB Updated but not ActiveCampaign Updated (DNC)');
          }
          if (attendee.sourceType == NaturalNumberTypes.Identified) {
            p.purchases = addPurchase(p.purchases, createPurchaseFromSubscription(p._key,p.primaryEmail, [makeDefaultPearl()]))
            console.log(`Create Pearl Purchase for  ${p.primaryEmail} `);
            const update = await firstValueFrom( this.arangoService.updatePerson(p));
            console.log(`Create Pearl subscription for  ${p.primaryEmail} `);
            this.emitterService.emitter.emit('Message',`Create Pearl subscription for  ${p.primaryEmail} `);
            const r = await firstValueFrom(this.pearlService.subscribe(p.primaryEmail, 'daily'));
          }


          resolve(true);
        }
      });
    }
  }