<div class="BO9-messages-wrapper">

  <mat-card class="bo9mat" class="BO9-messages-message" name="message-chat" *ngIf="message$">
    <div class="BO9-card-title">
      <div>
        <div class="BO9-message-author-avatar">
          <img src="{{ chatWithAvatar }}" alt="avatar">
        </div>
        {{ chatWith }}
      </div>
    </div>
    <div class="BO9-content-scroll BO9-messages-message-conversation"
      id="message-container">
      <ul class="BO9-message-wrapper">
        <li *ngFor="let m of message$ | async">
          <div *ngIf="m.PersonId==user.uuid; else notme">
            <div class="BO9-message BO9-message-mine">
              <div class="BO9-message-author-avatar">
                <img src="{{ m.authorAvatar }}" alt="avatar">
              </div>
              <div class="BO9-message-author-name">{{ m.authorName}}</div>
              <div class="BO9-message-content">
                <div class="BO9-message-text">{{ m.message }}</div>
                <div class="BO9-message-meta">
                  {{ m.created | date:'medium' }}
                </div>
              </div>
            </div>
          </div>
          <ng-template #notme>
            <div class="BO9-message BO9-message-notme">
              <div class="BO9-message-author-avatar">
                <img src="{{ m.authorAvatar }}" alt="avatar">
              </div>
              <div class="BO9-message-author-name">{{ m.authorName}}</div>
              <div class="BO9-message-content other-user">
                <div class="BO9-message-text">{{ m.message }}</div>
                <div class="BO9-message-meta">
                  {{ m.created | date:'medium' }}
                </div>
              </div>
            </div>
          </ng-template>
        </li>

      </ul>
    </div>

    <div class="BO9-message-form-wrapper">
      <form class="BO9-message-form message-form">
        <mat-form-field class="message-full-width" appearance="outline">
          <input matInput
            placeholder="Message"
            [(ngModel)]="newmessage"
            name="newmessage"
            (keydown.enter)="onClickSendMessage()"
          />
        </mat-form-field>
      </form>
      <button mat-flat-button (click)="onClickSendMessage()">
        Send
      </button>
    </div>

    <ng-template #noChats>
      <mat-card class="bo9mat" class="BO9-messages-message">
        <div class="BO9-card-title">Message</div>
        <div class="BO9-content-loading">
          <p>Loading Message</p>
        </div>
      </mat-card>
    </ng-template>
  </mat-card>
</div>
