import { DateToYYMMDDhhmmss } from '../../helpers/time.helper';
import { createUUID } from '../../uuid.service';
import { ArangoNodeType, BO9UUID, ArangoKeyType, ArangoRevType } from "../arango/arango.model";
import { DateTimeStringType } from "../BO9_base/BO9_base.model";
import { Meta } from "../BO9_base/meta.model";
import { BO9Who } from "../contact/contact.model";
import { makeMTNaturalNumber, NaturalNumber } from '../naturalNumber/naturalNumber.model';
import { ModuleDetail, multiProduct } from '../product/product.model';

export interface Review {
    reviewer?: string,
    approved: boolean,
    positive?: number,
    whenRated: DateTimeStringType,
    reason: string,
    meta: Meta
}

export interface PCount {
    approved: boolean,
    numnatnum: number,
    prel: string,
    pmod: string,
    pnum: string
}

export enum PearlPeriods {
    "Daily",
    "Weekly",
    "Monthly"
}

export interface PearlModule {
    pearlmodules: string[];
    sku: string;
    modulename: string;
    relationships: string[];
}

export interface UserPearl {
    email: string,
    pearl: string
}


export interface PearlToPerson {
    _key: BO9UUID;
    fromPearl: ArangoNodeType;
    toPerson: ArangoNodeType;
    naturalNumber: NaturalNumber;
    pearlModule: string;
    inviteType: string;
    period: string;
    sku: string;
    whenAdded: DateTimeStringType | null
    meta: Meta;
}

export interface PearlSearch {
    search: string;
    myNN: NaturalNumber;
    theirNN: NaturalNumber;
    relationships: string[];
    pearlModule: string[];
    startDate: string;
    endDate: string;
    random: boolean;
    approved: boolean;
}

export interface Pearl {

    _key: BO9UUID;
    imageURL: string;
    fileInfo?: [{
        Etag: string,
        BucketName: string,
        Key: string,
        Location: string
    }],
    title: string;
    content: string;
    myNN: NaturalNumber;
    theirNN: NaturalNumber;
    relationship: string[];
    pearlModule: string[];
    moderated? :Review;
    reviews?: Review[];
    timeStamp: DateTimeStringType;
    language?: string;
    startDate?: string;
    endDate?: string;
    meta?: Meta;
}

export function mkMTPearl(): Pearl {
    return ({
        _key: createUUID(),
        imageURL: '',
        title: '',
        content: '',
        theirNN: NaturalNumber.None,
        myNN: NaturalNumber.None,
        relationship: [''],
        pearlModule: [''],
        timeStamp: DateToYYMMDDhhmmss()})
}

export function getModuleByName(name: string, moduleList: PearlModule[]) : PearlModule | null {

    for (const m of moduleList) {
        if (m.modulename === name) return (m);
    }

    return null
}


export function getPearlModuleForSKU(sku: string, moduleList: PearlModule[]) : PearlModule | null {
    let res = '';

    for (const m of moduleList) {
        if (m.sku === sku) return (m);
    }

    return null
}

export function getActiveModule(module: PearlModule, moduleList: multiProduct[]): multiProduct | null {
    for (const m of moduleList) {
        if (m.productInfo.sku === module.sku) return (m);
    }
    return null

}

export function getNextPearlModuleToSee(seen: string, moduleList: string[]) : string {
    if (seen && moduleList) {
        if (moduleList.length === 1) {
            console.log("IS 1")
            return (moduleList[0]);
        } else {
            
            for (let i=0; i <  moduleList.length; i++) {
                console.log("compare "+moduleList[i] + 'and ' +seen );
                if (seen === moduleList[i]) {
                    if (moduleList[i+1]) {
                        console.log("IS 2")
                       return(moduleList[i+1]); // use the next one, if there is a next one
                    } else {
                        console.log("IS 3 ")
                        return(moduleList[0]); // isn't a next one, use the first one
                    }
                    
                }
            }
            console.log("IS 4");
            console.log("LOG "+seen);
        }
    }

    return "Public";

}

