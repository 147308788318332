import { base64Encode } from "../../uuid.service";
import { Order } from "../purchase/purchase.model";

export interface WooDownloadLink {
    href: string;
}

export interface WooDownload {
    download_id	: string,
    download_url	: string,
    product_id	: number,
    product_name	: string,
    download_name	: string,
    order_id	: number,
    order_key	: string,
    downloads_remaining	: string,
    access_expires	: string,
    access_expires_gmt	: string,
    file?	: {
        name: string,
        file: string
    }, 
    _links? : {
        collection: WooDownloadLink[],
        product: WooDownloadLink[],
        order: WooDownloadLink[]
    }

}

export interface WooDownloadInfo {
    permission_id : number,
    download_id : string,
    product_id : number,
    order_id : number,
    order_key: string,
    user_email : string,
    user_id: string,
    downloads_remaining: number,
    access_granted: string,
    access_expires : string,
    download_count : number
}

export function urlFromDownload(d: Order, names: string): string {

    let url = '';
    if (d) {
        const id= '?id='+d.orderKey.substring(9);
        const download_file = '&download_file='+d.orderId;
        const order = '&order='+d.orderKey;
        const key= '&key='+d.download?.download_id;
     //   console.log('URL names '+names);
        const client = '&client='+base64Encode(d.orderId+';', names)
        url = id+download_file+order+key+client;

    } 
   // console.log('URL '+url);

    return url;
}

export function urlFromName(email: string, names: string): string {



     //   console.log('URL names '+names);
        const url = '&client='+base64Encode(email, names);
   // console.log('URL '+url);

    return url;
}