import { dateFormating } from "../../helpers/time.helper";
import { replaceHTMLPercentTag } from "../../helpers/text.helper";
import { Meta } from "../BO9_base/meta.model";
import { Event } from "../event/event.model";

import { Enrolled } from "../institute/student.model";
import { RegisterHours } from "../institute/credits.model";


export enum CertificateType {
    "Program",
    "Attendance",
    "EventAttendance"
}

export enum CertificateAuthority {
    "ICF",
    "MT",
    "BO9"
}

export function StringToCertificateAuthority(str: string): CertificateAuthority {
    switch (str) {
        case 'BO9':
            return(CertificateAuthority.BO9);
        case "ICF":
            return(CertificateAuthority.ICF);
        case "MT":
            return(CertificateAuthority.MT);
        default:
            return(CertificateAuthority.BO9);
    }
}

export function CertificateAuthorityToString(ca: CertificateAuthority): string {
    switch (ca) {
        case CertificateAuthority.BO9:
            return("BO9");
        case CertificateAuthority.MT:
                return("MT");
        case CertificateAuthority.ICF:
                    return("ICF");
        default:
            return("BO9");
    }
}

export interface PersonCertificate {
    _key?: string;
    name: string;
    email: string;
    from: string; // person _key
    to: string; // Event
    kind: CertificateType; // type of certificate
    authority: CertificateAuthority;
    certInfo?: any; // of certificate
    by: string; // generated by
    fromData: EventAttendanceCertificate | AttendanceCertificate | ProgramCertificate; // the data 
    meta?: Meta;
    fileInfo?: {
        Etag: string,
        BucketName: string,
        Key: string,
        Location: string
    },
}
export interface EventAttendanceCertificate {
    dateGenerated: string;
    data: AttendanceData;
}

export interface AttendanceData {
    eventName: string;
    eventId: string;
    startDate: string;
    eventCode: string;
    hours: RegisterHours;
    url?: string;
    genDate?: string;
    certKey?: string;
    authority?: string;
}
export interface AttendanceCertificate {
        dateFrom: string;
        dateTo: string;
        dateGenerated: string;
        data: AttendanceData[];
        total: number;
}

export interface ProgramData {
    enrolled: Enrolled
}

export interface ProgramCertificate {
    date_all_completed: string;
    dateGenerated: string;
    data: ProgramData
}

interface hrsResHrs  {
    hours: number;
    resHours: number;
}

function hoursFromRegisterHours(authority: CertificateAuthority, resHrs: RegisterHours): hrsResHrs {
    if (resHrs) {
        switch (authority) {
            case CertificateAuthority.BO9: {
                if (resHrs.attended) {
                    return({hours: +resHrs.attended,resHours: 0});
                } else {
                    return({hours: +resHrs.BO9.hours, resHours: 0});
                }
            }
            case CertificateAuthority.MT: {
                if (resHrs.attended) {
                    return({hours: +resHrs.attended,resHours: 0});
                } else {
                    return({hours: +resHrs.MT.hours, resHours: 0});
                }
            }
            case CertificateAuthority.ICF: {
           
                if (resHrs.attended) {
                    return({hours: +resHrs.attended* ((100-resHrs.ICF.resourcePercentage)/100),resHours: +resHrs.attended* ((resHrs.ICF.resourcePercentage)/100)});
                } else {
                    return({hours: +resHrs.ICF.hours * (100-resHrs.ICF.resourcePercentage)/100, resHours: resHrs.ICF.resourcePercentage/100});
                }
                }
            default:
    
        }
    }
    
    return({hours:0, resHours:0});
}

export function genEventTable(cert : PersonCertificate): string {
    let result = '';

    result += '<table>';
    result += '<tr>';
    result += '  <th>Event Name</th>';
    result += '  <th>Event Date</th>';
    result += '  <th>Hours</th>';
    result += '</tr>';

    const attData= cert.fromData as AttendanceCertificate;
    if (attData.data && attData.data.length > 0) {
        for( const attCert of attData.data) {
            result += '<tr>';
            result += '<td>'+attCert.eventName+'</td>';
            result += '<td>'+attCert.startDate+'</td>';
            result += '<td>';
            const hrs = hoursFromRegisterHours(cert.authority,attCert.hours);
            if (hrs.resHours != 0) {
                result+= 'Hrs: '+hrs.hours + 'Res: '+hrs.resHours
            } else {
                result += hrs.hours.toString()
            }
            result += '</tr>';
        }
    }
   
    result += '</table>';

    return result;
}

export function totalHoursFromCert(cert: PersonCertificate): string {


    const attData= cert.fromData as AttendanceCertificate;

    let total: hrsResHrs = {hours:0, resHours:0};
    if (attData.data && attData.data.length > 0) {
        for( const attCert of attData.data) {
            const hrs = hoursFromRegisterHours(cert.authority,attCert.hours)
            total.hours += hrs.hours;
            total.resHours += hrs.resHours;
        }
    }
   
    if (total.resHours != 0) {
        return(  'Hrs: '+total.hours + 'Res: '+total.resHours)
    }
    return(total.hours.toString())
}

export function hoursFromCert(cert: PersonCertificate): string {


    const attCert= cert.fromData as EventAttendanceCertificate;


    if (attCert) {
        const hrs = hoursFromRegisterHours(cert.authority,attCert.data.hours)
        if (hrs.resHours != 0) {
            return( 'Hrs: '+hrs.hours.toString()
            + 'Res: '+hrs.resHours.toString());
        } else {
            return hrs.hours.toString()
        }
    }
    return("0");
}

export function convertCreditHourTemplate( cert: PersonCertificate, event: Event, html: string ) : string {
    let result = html;
    console.log("HTML "+html);

    const attCert= cert.fromData as EventAttendanceCertificate;

    const tag = 'class="ql-align-center"';
	const re = new RegExp(tag, "ig");

    let hours = hoursFromCert(cert);

    let totalhours = totalHoursFromCert(cert);

    let eventTable = genEventTable(cert);

    result = result.replace(re,'style="text-align:center;"');
    result = replaceHTMLPercentTag(result, 'ATTENDEE_NAME', cert.name);
    result = replaceHTMLPercentTag(result, 'EVENT_TITLE', attCert.data.eventName);
    result = replaceHTMLPercentTag(result, 'EVENT_DATE', dateFormating(attCert.data.startDate,'purchased'));
    if (event) {
        if (event.linkUrl && event.linkUrl.includes('zoom')) {
            result = replaceHTMLPercentTag(result, 'EVENT_LOCATION', 'Zoom');
        } else if (event.linkUrl) {
            result = replaceHTMLPercentTag(result, 'EVENT_LOCATION', event.linkUrl);
        } else {
            result = replaceHTMLPercentTag(result, 'EVENT_LOCATION', "On site");
        }
    }

    result = replaceHTMLPercentTag(result, 'HOURS', hours);
    result = replaceHTMLPercentTag(result, 'TOTALHOURS', totalhours);
    result = replaceHTMLPercentTag(result, 'EVENTTABLE', eventTable);
    console.log("RESULT "+result);
    return result;
}
