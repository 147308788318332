<div [formGroup]="form">
  <h3>Address</h3>
  <div>
    <ng-container formArrayName="items">
      <div *ngFor="let item of items.controls; index as idx" [formGroupName]="idx">
        <mat-form-field  appearance="outline" class="full-width">
          <mat-label>Name</mat-label>
          <input matInput 
            formControlName="name"
            id="name"
          />
        </mat-form-field>

         
          <mat-form-field  appearance="outline" class="full-width">
            <mat-label>Street 1</mat-label>
            <input matInput 
              formControlName="street1"
              id="street1"
            />
          </mat-form-field>

         
          <mat-form-field  appearance="outline" class="full-width">
            <mat-label>Street 2</mat-label>
            <input matInput 
              formControlName="street2"
              id="street2"
            />
            
          </mat-form-field>

          
          <mat-form-field  appearance="outline" >
            <mat-label>City</mat-label>
            <input matInput 
              formControlName="city"
              id="city"
      
            />
           
          </mat-form-field>
         
          <mat-form-field  appearance="outline" >
            <mat-label>State</mat-label>
            <input matInput 
              formControlName="state"
              id="state"
            />
           
          </mat-form-field>
       
      
          
          <mat-form-field  appearance="outline" >
            <mat-label>Zip</mat-label>
            <input matInput 
              formControlName="zip"
              id="zip"
            />
          
          </mat-form-field>

          
          <mat-form-field  appearance="outline" class="full-width">
            <mat-label>Country</mat-label>
            <input matInput 
              formControlName="country"
              id="country"
            />
           
          </mat-form-field>

      
          <button mat-button type="button" (click)="removeAddrItem(idx)"> <mat-icon>delete</mat-icon></button>
      </div>
      <button mat-button type="button" (click)="addAddrItem()">Add New Address</button><br>
    </ng-container>
    </div>
  
</div>