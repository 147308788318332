import { DateToYYMMDDhhmmss } from '../../helpers/time.helper';
import { createUUID } from '../../uuid.service';
import { BO9UUID, ArangoRevType } from "../arango/arango.model";
import { DateTimeStringType } from "../BO9_base/BO9_base.model";
import { Meta } from "../BO9_base/meta.model";
import { BO9Who } from "../contact/contact.model";

export interface PageReview {
    reviewer: BO9Who,
    approved: boolean,
    positive: boolean,
    whenRated: DateTimeStringType,
    reason: string,
    meta: Meta
}

export enum PageType {
    "None",
    "About",
    "Help",
    "Privacy",
    "Settings",
    "Instruction",
    "Overview",
    "Policy",
    "TOS",
    "Button1",
    "Button2",
    "Button3"
}

export interface Page {
    _key: BO9UUID;
    imageURL: string;
    fileInfo?: [{
        Etag: string,
        BucketName: string,
        Key: string,
        Location: string
    }],
    title: string;
    content: string;
    pageType: PageType;
    accessKey?: string;
    moderated? :PageReview;
    reviews?: PageReview[];
    timeStamp: DateTimeStringType;
    meta?: Meta;
}

export interface searchParams {
        search: string;
        otherParam: string;
        offset: number;
        limit: number;
        capabilites: string[]
}

export interface Visit {
        page: string;
        visitor: string;
        help?: boolean;
        when?: string;
        error?: any;
        component: string;
        version?: string;
        search?: searchParams;
        first?: boolean;
        
}

export function mkMTPage(): Page {
    return ({
        _key: createUUID(),
        imageURL: '',
        title: '',
        content: '',
        pageType: PageType.None,
        timeStamp: DateToYYMMDDhhmmss()})
}

export function getPageByKey(key: string, pages:Page[]) : Page | null {

    if (pages) {
        for (const p of pages) {
            if (p.accessKey?.toLowerCase() === key.toLowerCase()) {
                return p;
            }
        }
    }

    return null;
}
