<my-slide-panel [activePane]="currentPane">
	<section leftPane>
		<button mat-stroked-button (click)="onCloseClick(lastPane)">Back</button><br>
		<app-attendees [event]="currentEvent$ | async"
		  (notifyParent)="attendeeSubmittedHandler($event)"
		  (mergePerson)="mergePerson($event)">
		</app-attendees>
	</section>

	<section centerPane>
	 <div class="action">

		 <span *ngIf="isAdmin()">
			 <button mat-stroked-button type="button" (click)="onClickAddEvent()">Add <mat-icon>add_circle</mat-icon></button> <br />
			 <button mat-stroked-button type="button" (click)="onClickAsserted()">Asserted <mat-icon>check</mat-icon></button> <br />
		</span>
		 <span>
			<form novalidate >
				Only WordPress <mat-checkbox (change)="onClickOnlyWordpress()" [checked]="onlyWordpress"></mat-checkbox>
				Only Calendly <mat-checkbox (change)="onClickOnlyCalendly()" [checked]="onlyCalendly"></mat-checkbox> <br />

			   <div><label class='search'>From (yyyy-mm-dd): </label>
				   <input type="text" [(ngModel)]="searchFromString" name="searchFromString"/></div>

				<div><label class='search'>To (yyyy-mm-dd): </label>
					<input type="text" [(ngModel)]="searchToString" name="searchToString"/></div>
				<div><label class='search'>Search: </label>
						<input type="text" [(ngModel)]="search" name="search"/></div>

				<button  [disabled]="disableBtn" mat-flat-button color="primary" name="search" (click)="onClickSearchSome()">Get</button>
				 &nbsp;
				<button [disabled]="disableBtn" mat-flat-button color="primary" name="search" (click)="onClickSearchAll()">Get All</button>
				&nbsp;
				<button [disabled]="disableBtn" mat-flat-button color="primary" name="search" (click)="onClickToday()">Today</button>
			</form>
		</span>

		 <span>
			 <mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="30" class='spin'> </mat-progress-spinner>
		 </span>

		 <span>
			 <button [disabled]="disableBtn" mat-stroked-button (click)="onRefreshClick()">Refresh<mat-icon>refresh</mat-icon></button>
		 </span>
	 </div>
	<mat-card class="bo9mat" class="flex" style="box-shadow: 0 0 0 0 rgba(0, 0, 0, 0)">

			<table mat-table [dataSource]="dataSource" matSort>
				 <ng-container matColumnDef="view">
					<th mat-header-cell *matHeaderCellDef> View Event</th>
					<td mat-cell *matCellDef="let element">
				<span *ngIf="element.editable">  <button type="button" (click)="onClickView(element)"> <mat-icon>preview</mat-icon></button></span>	
					</td>
				 </ng-container>

				 <ng-container matColumnDef="viewatt">
					<th mat-header-cell *matHeaderCellDef> Add/View <br> Attendees</th>
					<td mat-cell *matCellDef="let element">
					 <!-- <span *ngIf="!element.attendees"> <button mat-button type="button" (click)="onClickAdd(element)"> <mat-icon>add_circle</mat-icon></button></span> -->
					 <span *ngIf="element.editable"> <button mat-button type="button" (click)="onClickExpand(element)"> <mat-icon>explore</mat-icon></button></span>
					</td>
				 </ng-container>

				 <ng-container matColumnDef="smallatt">
					<th mat-header-cell *matHeaderCellDef> Atts </th>
					<td mat-cell *matCellDef="let element">
					 <!-- <span *ngIf="!element.attendees"> <button mat-button type="button" (click)="onClickAdd(element)"> <mat-icon>add_circle</mat-icon></button></span> -->
					 <span> <button mat-button type="button" (click)="onClickExpand(element)"> <mat-icon>explore</mat-icon></button></span>
					</td>
				 </ng-container>

				 <ng-container matColumnDef="_key">
					<th mat-header-cell *matHeaderCellDef> Key </th>
					<td mat-cell *matCellDef="let element"> <mat-icon (click)="showKey(element._key)" matTooltip="{{element._key}}">key</mat-icon></td>
				 </ng-container>

				 <ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
					<td mat-cell *matCellDef="let element" matTooltip="{{element._key}}" > {{element.name}} </td>
				 </ng-container>
				 <ng-container matColumnDef="startDate">
					<th mat-header-cell *matHeaderCellDef  mat-sort-header> Start </th>
					<td mat-cell *matCellDef="let element"> {{element.startDate}} </td>
				 </ng-container>
				 <ng-container matColumnDef="timezone">
					<th mat-header-cell *matHeaderCellDef  mat-sort-header> Local Time / From TZ </th>
					<td mat-cell *matCellDef="let element"> {{element.startTime}} {{ showTZ(element.timezone) }} </td>
				 </ng-container>
				 <ng-container matColumnDef="categories">
					<th mat-header-cell *matHeaderCellDef  mat-sort-header> Categories </th>
					<td mat-cell *matCellDef="let element"> {{element.categories}}</td>
				 </ng-container>
				 <ng-container matColumnDef="status">
					<th mat-header-cell *matHeaderCellDef  mat-sort-header> Status </th>
					<td mat-cell *matCellDef="let element"> {{element.event_status}}</td>
				 </ng-container>
				 <ng-container matColumnDef="attendees">
					<th mat-header-cell *matHeaderCellDef  mat-sort-header> Count </th>
					<td mat-cell *matCellDef="let element"> {{element.attendees}} </td>
				 </ng-container>
				 <ng-container matColumnDef="source">
					<th mat-header-cell *matHeaderCellDef  mat-sort-header> Event </th>
					<td mat-cell *matCellDef="let element; let i = index;"> 

						<span *ngIf="element.eventCode!=''; else noCode">{{element.eventCode}}</span> 
			<ng-template #noCode> <input matInput [(ngModel)]="newCode[i]" name="newCode"  placeholder="Enter EventCode"/>
				<button mat-button (click)="fixEvent(i ,element)">Update Code</button>
			</ng-template>
					</td>
				 </ng-container>
				 <ng-container matColumnDef="hasVideo">
					<th mat-header-cell *matHeaderCellDef  mat-sort-header> Video </th>
					<td mat-cell *matCellDef="let element">  
					<span *ngIf="element.recording_link"> <mat-icon>video_call</mat-icon></span>
					</td>
				 </ng-container>
				 <ng-container matColumnDef="delete">
					<th mat-header-cell *matHeaderCellDef  mat-sort-header> Close / Delete  </th>
					<td mat-cell *matCellDef="let element">  
						
						<span *ngIf="!element.attendees && isAdmin()"><button [disabled]="disableBtn" mat-button type="button" (click)="onDelete(element)"> <mat-icon>delete_forever</mat-icon></button> </span>
						<span *ngIf="element.attendees  && !(element.event_status=='Closed')"><button [disabled]="disableBtn" mat-button type="button" (click)="onClickClose(element)"> <mat-icon>curtains_closed</mat-icon></button> </span>
					  </td>
				 </ng-container>
				 <tr mat-header-row *matHeaderRowDef="colList"></tr>
				 <tr mat-row *matRowDef="let row; columns: colList;"></tr>

			</table>
			<mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10,  20, 50, 100]"  (page)="onChangePage($event)"></mat-paginator>

	</mat-card>
	</section>

	<section rightPane>
	<button mat-stroked-button (click)="onCloseClick(lastPane)">Back</button>
	<span *ngIf="operation == 'Event'" >  <event-form [event]="event" (notifyParent)="eventSubmittedHandler($event)"></event-form></span>
	<span *ngIf="operation == 'View'" >  <person-form [person]="origP"></person-form></span>
	<span *ngIf="operation == 'Asserted'" >  <asserted-app></asserted-app></span>
	<!--<span *ngIf="operation == 'AddAttendee'" > <attendee-form [event]="event" [attendees]="noAttendees"></attendee-form></span> -->
	<span *ngIf="operation == 'Merge'" ><merge-person-form [origPerson]="origP" [newPerson]="newP" [differences]="difference" [extSource]="'Events'"></merge-person-form></span>
	</section>
</my-slide-panel>
