<form [formGroup]="form" (ngSubmit)="submitForm()" class="relate-form">
	<div>
		<h3 *ngIf="!canMEA">{{ inputGroup?.group.groupName}}: Pre pay</h3>

		<mat-form-field  appearance="outline" >
			<mat-label>First Name</mat-label>
			<input matInput formControlName="first" id="first" required />
		</mat-form-field>
		 &nbsp;
		<mat-form-field  appearance="outline" >
			<mat-label>Last name</mat-label>
			<input matInput formControlName="last" id="last" required />
		</mat-form-field>
		 &nbsp;
		<mat-form-field  appearance="outline" >
			<mat-label>Email</mat-label>
			<input matInput formControlName="email" id="email" required	/>
		</mat-form-field>
		
	<span *ngIf="!canMEA && !eventParam?.link">	<button mat-stroked-button type="button" (click)="assignLink()">Create Booking Link<mat-icon>link</mat-icon></button>  </span>
		
	<span *ngIf="canMEA">  <button mat-stroked-button type="button" (click)="assignMEA()">M.E.A. <mat-icon>link</mat-icon></button> </span>
	<!--<span *ngIf="eventParam?.link"><button mat-button [cdkCopyToClipboard]="eventParam?.link"><mat-icon >content_copy</mat-icon></button> {{ eventParam?.link }}</span> -->
	
	</div>

	<br>
	<span *ngIf="eventParam?.link">	<button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Complete Assignment</button></span>

</form>
