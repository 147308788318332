<div [formGroup]="form">
  <div>
    <h3>Social Media Accounts</h3>
  
  <ng-container formArrayName="items">
    <div *ngFor="let item of items.controls; index as idx" [formGroupName]="idx">
      <mat-form-field  appearance="outline" >
        <mat-label>Type</mat-label>

              
        <mat-select matNativeControl  formControlName="company" required>
          <mat-option *ngFor="let nns of companyNames" [value]="nns">
            {{ nns }}
          </mat-option>
        </mat-select>

      </mat-form-field>
      <mat-form-field  appearance="outline" required >
        <mat-label>Handle</mat-label>
        <input matInput 
          formControlName="handle"
          id="handle"
        />

        </mat-form-field>
        <button mat-button type="button" (click)="removeSocialItem(idx)"> <mat-icon>delete</mat-icon></button>
    </div>
    <button mat-button type="button" (click)="addSocialItem()">Add Social Media Account</button><br>
  </ng-container>
</div>
 
</div>