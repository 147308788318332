import { NgModule } from '@angular/core';
import { Location } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { MessagesComponent } from '../pages/messages/messages.component';

import { LoginComponent } from '../pages/login/login.component';

import { SupportComponent } from '../pages/support/support.component';

import { AuthGuardService } from 'src/services/auth/auth-guard.service';
import { AppLayoutComponent } from 'src/pages/layouts/applayout.component';
import { WebLayoutComponent } from 'src/pages/layouts/weblayout.component';
import { NotFoundComponent } from 'src/pages/notfound/notfound.component';

import { WebContactComponent } from 'src/pages/website/contact/contact.component';

import { WebHomeComponent } from 'src/pages/website/home/home.component';
import { PearlsComponent } from 'src/pages/pearls/pearls.component';
import { PeopleComponent } from 'src/pages/people/people.component';
import { InvitesComponent } from 'src/pages/invites/invites.component';
import { EventsComponent } from 'src/pages/events/events.component';
import { CertificationComponent } from 'src/pages/certification/certification.component';
import { ActiveCampaignComponent } from 'src/pages/activecampaign/activecampaign.component';
import { RelationsComponent } from 'src/pages/relations/relations.component';
import { PageComponent } from 'src/pages/page/page.component';
import { AppLoginComponent } from 'src/pages/website/applogin/applogin.component';
import { ProfileComponent } from 'src/pages/profile/profile.component';
import { TemplateComponent } from 'src/pages/template/template.component';
import { GroupsComponent } from 'src/pages/groups/groups.component';
import { OrdersComponent } from 'src/pages/orders/orders.component';
import { HomeComponent } from 'src/pages/home/home.component';
import { HomeLayoutComponent } from 'src/pages/layouts/homelayout.component';
import { PartnerLayoutComponent } from 'src/pages/layouts/partnerlayout.component';
import { GuildMembersComponent } from 'src/pages/guildmembers/guildmembers.component';
import { ProductsPageComponent } from 'src/pages/products/products.component';
import { MovementComponent } from 'src/pages/movement/movement.component';
import { MaintenanceComponent } from 'src/pages/maintenance/maintenance.component';
import { AttendeeFormComponent } from 'src/displayComponents/forms/attendeeForm/attendeeForm.component';
import { AttendeesComponent } from 'src/displayComponents/lists/attendeeList/attendees.component';
import { ErrorsComponent } from 'src/pages/errors/errors.component';
import { WebScheduleComponent } from 'src/pages/website/schedule/schedule.component';
import { DealsComponent } from 'src/pages/deals/deals.component';
import { StudentsComponent } from 'src/pages/students/students.component';
import { CreditsComponent } from 'src/pages/credits/credits.component';
import { SimpleAttendeeFormComponent } from 'src/displayComponents/forms/attendeeForm/simpleattendeeForm.component';
import { AgreementComponent } from 'src/pages/agreement/agreeement.component';

const appRoutes: Routes = [
  { path: '', redirectTo:'home', pathMatch: 'full'},
  { path: 'schedule/:id', component: WebScheduleComponent, data: {title: 'Schedule'}  },
  { path: 'register', component: SimpleAttendeeFormComponent, data: {title: 'Register'}  },
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuardService],
    data: {title: 'None', expectedRole:'User'},
    children: [
            { path: 'profile/:oper', component: ProfileComponent, data: {title: 'Profile',expectedRole: 'User' }, 
      canActivate: [AuthGuardService] },
    ]

  },
  {
    path: '',
    component: PartnerLayoutComponent,
    canActivate: [AuthGuardService],
    data: {title: 'None', expectedRole:'User'},
    children: [
      { path: 'guild/:oper', component: GuildMembersComponent, data: {title: 'Guild',expectedRole:'Apprentice' }, 
        canActivate: [AuthGuardService]
      },
      { path: 'events', component: EventsComponent, data: {title: 'Events',expectedRole: 'Apprentice'}, 
      canActivate: [AuthGuardService] },
    ]

  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuardService],
    data: {title: 'None', expectedRole:'User'},
    children: [
      { path: '', redirectTo: 'dashboard', data: { title: 'Login Component',expectedRole: 'Master' }, pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent, data: {title: 'Dashboard',expectedRole: 'Master' },
      canActivate: [AuthGuardService],  runGuardsAndResolvers: 'always' },
      { path: 'messages', redirectTo: 'messages/0' },
      { path: 'messages/:id', component: MessagesComponent, data: {title: 'Messages',expectedRole: 'Master' },
          canActivate: [AuthGuardService] },
      { path: 'pearls', component: PearlsComponent, data: {title: 'Pearls',expectedRole: 'Master' }, 
          canActivate: [AuthGuardService] },
      { path: 'maintenance', component: MaintenanceComponent, data: {title: 'Maintenance',expectedRole: 'Master' }, 
      canActivate: [AuthGuardService] },
        { path: 'students', component: StudentsComponent, data: {title: 'Students',expectedRole: 'Master' }, 
        canActivate: [AuthGuardService]
      },
      { path: 'agreements', component: AgreementComponent, data: {title: 'Agreement',expectedRole: 'Admin' }, 
      canActivate: [AuthGuardService] },
      { path: 'pages', component: PageComponent, data: {title: 'Pages',expectedRole: 'Master' }, 
        canActivate: [AuthGuardService] },
 //     { path: 'templates', component: TemplateComponent, data: {title: 'Templates',expectedRole: 'Master' }, 
   //     canActivate: [AuthGuardService] },
      { path: 'people', component: PeopleComponent, data: {title: 'People',expectedRole: 'Admin' }, 
        canActivate: [AuthGuardService] },
      { path: 'products', component: ProductsPageComponent, data: {title: 'Products',expectedRole: 'Admin' }, 
        canActivate: [AuthGuardService] },
      { path: 'invites', component: InvitesComponent, data: {title: 'Invites',expectedRole: 'Admin'}, 
        canActivate: [AuthGuardService] },
      { path: 'relations', component:RelationsComponent, data: {title: 'Relations',expectedRole: 'Admin'}, 
        canActivate: [AuthGuardService] },
        { path: 'deals', component:DealsComponent, data: {title: 'Deals',expectedRole: 'Admin'}, 
        canActivate: [AuthGuardService] },
        { path: 'credit', component:CreditsComponent, data: {title: 'Credit',expectedRole: 'Admin'}, 
        canActivate: [AuthGuardService] },
        { path: 'errors', component:ErrorsComponent, data: {title: 'Errors',expectedRole: 'Admin'}, 
        canActivate: [AuthGuardService] },
      { path: 'groups', component:GroupsComponent, data: {title: 'Groups',expectedRole: 'Admin'}, 
        canActivate: [AuthGuardService] },
  //    { path: 'movement', component:MovementComponent, data: {title: 'Movements',expectedRole: 'Admin'}, 
   //     canActivate: [AuthGuardService] },
      { path: 'attendees', component: AttendeeFormComponent, data: {title: 'Attendee',expectedRole: 'Master'}, 
        canActivate: [AuthGuardService] },
      { path: 'attendeelist/:id', component: AttendeesComponent, data: {title: 'Attendees',expectedRole: 'Master'}, 
        canActivate: [AuthGuardService] },
      { path: 'events', component: EventsComponent, data: {title: 'Events',expectedRole: 'Master'}, 
        canActivate: [AuthGuardService] },
      { path: 'orders', component: OrdersComponent, data: {title: 'Orders',expectedRole: 'Admin'}, 
        canActivate: [AuthGuardService] },
      { path: 'activecampaign', component: ActiveCampaignComponent, data: {title: 'Events',expectedRole: 'Admin'}, 
        canActivate: [AuthGuardService] },
      { path: 'certification', component: CertificationComponent, data: {title: 'Certification',expectedRole: 'Admin'}, 
        canActivate: [AuthGuardService] },
      { path: 'support', component: SupportComponent, data: {title: 'Support',expectedRole: 'Admin'}, 
        canActivate: [AuthGuardService] },

    ]
  },
  {
    path: '',
    component: WebLayoutComponent,
    children: [
      { path: '', redirectTo:'home', pathMatch: 'full', data: {title: 'Home', expectedRole:'User'}},
      { path: 'home', component: WebHomeComponent, data: {title: 'Home'} },
      { path: 'login', component: LoginComponent, data: {title: 'Login',expectedRole: 'User'} },
      { path: 'contact', component: WebContactComponent, data: {title: 'Contact'}  },
    //  { path: 'schedule/:id', component: WebScheduleComponent, data: {title: 'Schedule'}  }
    ]
  },
  { path: '404', component: NotFoundComponent },

  { path: '**', component: NotFoundComponent }
];


@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes,
      { useHash: true,
      // enableTracing: true, // TODO remove on production
       onSameUrlNavigation: 'reload'
      }
      ),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
