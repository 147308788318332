import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { LoginService } from 'src/services/loginservice/login.service';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { mkEmptyPerson, Person, mkSimplePerson, firstName, lastName, getUserName } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { FormInvite, Invite, InvitedStatus } from '../../../../../bo9-shared/models/person/invite.model';
import {  mostAccurateNaturalNumber, NaturalNumber, NaturalNumberTypes, nnSource  } from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';
import { createUUID, responseEncode } from '../../../../../bo9-shared/uuid.service';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import { DateToYYMMDDhhmmss } from '../../../../../bo9-shared/helpers/time.helper';
import { FormRelation, Relation, RelationType, inverseRelate } from '../../../../../bo9-shared/models/person/relation.model';
import { SelectsService } from 'src/services/selects/selects.service';
import { SearchService } from 'src/services/search/search.service';
import { RelationsService } from 'src/services/relations/relations.service';
import { EventEmitterService } from 'src/services/events/events.service';
import { SelectPersonDialogComponent } from 'src/displayComponents/dialogs/selectPersonDialog/selectPersonDialog.component';
import { MatDialog } from '@angular/material/dialog';
import { formPerson } from '../../../../../bo9-shared/models/person/formPerson.model';
import { findInvalidControlsRecursive } from '../../../helpers/form.helper';
import { SelectNameDialogComponent } from 'src/displayComponents/dialogs/selectNameDialog/selectNameDialog.component';
import { userIsAdmin, userIsMaster, userIsJourneyman } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';

@Component({
  selector: 'relation-form',
  templateUrl: './relationForm.component.html',
  styleUrls: ['./relationForm.component.scss'],
  // make fade in animation available to this component

})
export class RelationFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() inputRelation: FormRelation;
  @Input() from: Person; // Key
  @Input() attendees: any[];
  @Output() notifyParent: EventEmitter<Relation> = new EventEmitter<Relation>();
  messagesLoading$: Observable<boolean>;

  people: Person[];
  valid: false;
  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;
  disableBtn = false;
  searchString: string;
  currentP$: Observable<Person>;
  RelateForm: FormGroup;
  relatingPersonName: string;

  validationMessages = validationMessages;

  relations: string[];

  relationBoth: RelationType[];
  PearlModuleTypes: string[];
  nnKeys: string[];
  nnSources: string[];
  emails$: Observable<string[]>;
  names$: Observable<string[]>;
  isLoading = false;

  firstname = '';
  selectedEmail: string;
  selectedName: string;

  emailKeys:{key: string; email: string, naturalNumber: NaturalNumber }[] =[];
  nameKeys:{key: string; name: string, naturalNumber: NaturalNumber }[] =[];
  selectedKey: string;
  invites: string[];

  invited=false;
  notMe: any[] = [];
  user: Person;
  notSelected = true;

  public emailList: Observable<string[]> = of(["Angular", "React", "Node", "Java"]);
  public nameList: Observable<string[]> = of(["Angular", "React", "Node", "Java"]);
  subscriptions: Subscription[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private arangoService: ArangoService,
    private emitterService: EventEmitterService,
    private selectsService: SelectsService,
    private searchService: SearchService,
    private auth: AuthService,
    private relateService: RelationsService,
    ) 
  {
    this.user = this.auth.getAuthUser();
  
    this.nnKeys = Object.keys(NaturalNumber).map(key => NaturalNumber[key]).filter(value => typeof value === 'string') as string[];
    this.nnSources = Object.keys(NaturalNumberTypes).filter(value => typeof value === 'string') as string[];
    this.RelateForm = this.formBuilder.group({
      firstName: new FormControl('', Validators.compose([Validators.required])),
      lastName: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([ Validators.email])),
      relationType: new FormControl('', Validators.compose([Validators.required])),
      nnGuess: new FormControl('', Validators.compose([])),
      sourceType:  new FormControl('', Validators.compose([])),
      momBirthOrder:  new FormControl('', Validators.compose([])),
      inviteType:  new FormControl('', Validators.compose([])),
      _key:  new FormControl('', Validators.compose([]))
    });
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'RelationFormComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
   this.subscriptions.push(  this.selectsService.getSelects()
    .subscribe( (result) => {
      this.relations = this.selectsService.selects.relationtypes as string[];
      this.relationBoth = this.selectsService.selects.relationbothtypes as RelationType[];
 
      this.PearlModuleTypes = this.selectsService.selects.pearlmoduletypes;
      this.invites = this.selectsService.selects.invitetypes;

      console.log('getselects ' + JSON.stringify(result));
    }))
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  onClickInvite() {

  }
   
  selectPersonFromAttendees() {
    const index = this.attendees.findIndex(item => item.person._key== this.from._key)
    const shrink =  index > -1 ? this.attendees.splice(index,1): this.attendees;
    const dRef = this.dialog.open(SelectNameDialogComponent, {
      width: '75%',
      height: '75%',
      data: {group: this.attendees, grouped: false}
    });

    this.subscriptions.push( dRef.afterClosed()
    .subscribe(  (result: any[]) => {
      console.log('Selected people is ' + JSON.stringify(result));

      if (result && result.length > 0) { // Only take the first
        if (result[0].email === this.from.primaryEmail) {
          alert("Can't have self as a relation "+this.from.primaryEmail);
          this.selectedKey = null;
        } else {
          this.RelateForm.controls.firstName.setValue(result[0].first);
          this.RelateForm.controls.lastName.setValue(result[0].last);
          this.RelateForm.controls.email.setValue(result[0].email);
          this.RelateForm.controls.nnGuess.setValue(result[0].nn);
          this.RelateForm.controls._key.setValue(result[0]._key);
          if (result[0].nn != "0") {
            this.RelateForm.controls.sourceType.setValue("Identified");
          }
  
          this.selectedKey = result[0]._key;
        }
      }
      
    }))

  }

  isAdmin(): boolean {
    return(userIsAdmin(this.user));
  }

  isMaster(): boolean {
    return(userIsMaster(this.user));
  }

  isJourneyman(): boolean {
    return(userIsJourneyman(this.user));
  }

  invitedChange($event) {
    this.invited = !this.invited;
    if (this.invited) {
      this.RelateForm.controls.inviteType.enable();
    } else {
      this.RelateForm.controls.inviteType.disable();
    }

  }

  ngOnInit() {
    
  }


  get emailControl() { return this.RelateForm.controls.email; }
  get firstNameControl() { return this.RelateForm.controls.firstName; }
  get lastNameControl() { return this.RelateForm.controls.lastName; }
  get eSearchControl() { return this.RelateForm.controls.eSearch;}


  onPageChange(event){
    console.log(event);
  }


  ngOnChanges() {
    this.selectedEmail = '';
    this.RelateForm.reset();
    if (this.inputRelation && this.inputRelation.toPerson) {
      console.log('inputI  ' + JSON.stringify(this.inputRelation.toPerson._key));
        this.RelateForm.controls._key.setValue(this.inputRelation.toPerson._key);

        if (this.inputRelation.toFirstName ) {
          this.RelateForm.controls.firstName.setValue(this.inputRelation.toFirstName);
        }
        if (this.inputRelation.toLastName) {
          this.RelateForm.controls.lastName.setValue(this.inputRelation.toLastName);
        }

        this.RelateForm.controls.email.setValue(this.inputRelation.toEmail);
        this.RelateForm.controls.inviteType.setValue(this.inputRelation.inviteType);
        this.RelateForm.controls.nnGuess.setValue(this.inputRelation.naturalNumber);
        this.RelateForm.controls.sourceType.setValue(this.inputRelation.sourceType);
        this.RelateForm.controls.relationType.setValue(this.inputRelation.relationType);
        this.RelateForm.controls.momBirthOrder.setValue(this.inputRelation.momBirthOrder);
    }
  }

  

  submitRelateForm() {
    console.log('Submit relates form ' + JSON.stringify(this.RelateForm.value));

    if (this.RelateForm.valid) {
      console.log('Submit relate form - valid');
      this.addRelationship().then ( () => {
        this.RelateForm.reset();
      });
    } else {
      alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.RelateForm)));
    }
  }

  selectPerson() {
    this.notSelected = true;
    const dRef = this.dialog.open(SelectPersonDialogComponent, {
      width: '75%',
      height: '75%',
      data: {group: null, grouped: false}
    });

    this.subscriptions.push( dRef.afterClosed()
    .subscribe(  (result: formPerson[]) => {
      console.log('Selected people is ' + JSON.stringify(result));
      if (result && result.length > 0) { // Only take the first
        if (result[0].email === this.from.primaryEmail) {
          alert("Can't have self as a relation "+this.from.primaryEmail);
          this.notSelected = true;
        } else {
        this.RelateForm.controls.firstName.setValue(result[0].firstName);
        this.RelateForm.controls.lastName.setValue(result[0].lastName);
        this.RelateForm.controls._key.setValue(result[0]._key);
        this.RelateForm.controls.email.setValue(result[0].email);
        if (result[0].realPerson) {
          const p: Person = result[0].realPerson;
          if (p.naturalNumbers.identified && p.naturalNumbers.identified.naturalNumber) {
            this.RelateForm.controls.nnGuess.setValue(p.naturalNumbers.identified.naturalNumber.toString());
            this.RelateForm.controls.sourceType.setValue(nnSource.Identified.toString());
          } else  if (p.naturalNumbers.asserted && p.naturalNumbers.asserted.naturalNumber) {
            this.RelateForm.controls.nnGuess.setValue(p.naturalNumbers.asserted.naturalNumber.toString());
            this.RelateForm.controls.sourceType.setValue("Self Identified");

          }
        } else {
          this.RelateForm.controls.nnGuess.setValue(result[0].naturalNumber);
          this.RelateForm.controls.sourceType.setValue(nnSource.Invited.toString());
        }
        this.notSelected = false;
      }

      }
      
    }))

  }

  onCloseClick() {
    console.log('Close');
    this.notifyParent.emit(null);
  }
  


 addRelationship() : Promise<any> {

  return new Promise( async (resolve, reject) => {

        let toPerson: Person;
        let exist = false;

        const relation: Relation = {
          _key: this.RelateForm.value._key? this.RelateForm.value._key : createUUID(),
          from: this.from._key,
          to: this.RelateForm.value._key? this.RelateForm.value._key: '',  
          relationType: this.RelateForm.value.relationType,
          sourceType: NaturalNumberTypes.Asserted,
          naturalNumber: this.RelateForm.value.nnGuess,
          pearlModule: 'Public',
          existing: exist,
          inviteType:  this.RelateForm.value.inviteType,
          momBirthOrder: this.RelateForm.value.momBirthOrder,
          invitedPerson: null,
          invitedStatus: this.notSelected? InvitedStatus.Unconfirmed : InvitedStatus.Confirmed,
          meta: updateMeta()
        }

        if (userIsAdmin(this.user)) { // Can only do these things if Admin
          if (this.RelateForm.value.email) { 
            const result = await firstValueFrom(this.searchService.searchEmail(this.RelateForm.value.email, true));
            if (result && result.length > 0) {
              toPerson = result[0];  // Email exists in system
              relation.to = toPerson._key;
            } 
          }
          relation.invitedStatus = InvitedStatus.Confirmed; // Hack for Admins
          relation.sourceType = this.RelateForm.value.sourceType;
        }

    // All things related to the relating person now handled on the backend. 11/15/21
    

        if (this.RelateForm.value.email) {
          if (!userIsAdmin(this.user) || this.notSelected) { // If admin and selected, dont need to set up invite flow
            relation.invitedPerson = {
              first: this.RelateForm.value.firstName,
              last: this.RelateForm.value.lastName,
              email: this.RelateForm.value.email,
              whenInvited: DateToYYMMDDhhmmss(),
              accept: responseEncode(relation.to,"C"),
              decline: responseEncode(relation.to,"U"),
              inviteTo: this.from.primaryEmail? this.from.primaryEmail: "Relative",
              inviteBy: getUserName(this.from)
            }
          }
        } else {
          if (!confirm("This relationship can never be confirmed, as no Email has been provided")) {
            
            resolve(true);
          } else {
            relation.invitedPerson = {
              first: this.RelateForm.value.firstName,
              last: this.RelateForm.value.lastName,
              email: '',
              whenInvited: DateToYYMMDDhhmmss(),
              accept: '',
              decline: '',
              inviteTo: this.from.primaryEmail? this.from.primaryEmail: "Relative",
              inviteBy: getUserName(this.from)
            }
          }
        }

        console.log('Relation is: ' + JSON.stringify(relation));
        const result = await  firstValueFrom(this.relateService.updateRelation(relation));
        console.log('add relation result is: ' + JSON.stringify(result));
        if (userIsAdmin(this.user) && result && result.content) { // Can't do inverse yet if not admin, nor if to a person with no email
          const sigNatNum = mostAccurateNaturalNumber(this.from.naturalNumbers);
          const invrelation: Relation = {
            _key:  createUUID(),
            to: this.from._key,
            from: result.content._key,
            relationType: inverseRelate(this.RelateForm.value.relationType, this.relationBoth),
            sourceType: sigNatNum.setAt,
            naturalNumber: sigNatNum.naturalNumber,
            pearlModule: 'Public',
            existing: true,
            invitedStatus: InvitedStatus.Confirmed,
            inviteType:  this.RelateForm.value.inviteType,
            momBirthOrder: this.RelateForm.value.momBirthOrder,
            meta: updateMeta()
          }
          console.log('InvRelation is: ' + JSON.stringify(invrelation));
          const invresult = await  firstValueFrom(this.relateService.updateRelation(invrelation));

        }
        console.log('Save result  is: ' + JSON.stringify(result.content));
        this.emitterService.emitter.emit('Message','Relation  saved');

        /* 
        // TODO maybe allow invite to pearls sometime in the future from here 
        if (this.invited) {
          const invite: Invite = {
            _key: createUUID(),
            from: this.from._key,
            to: toPerson._key,
            inviteType: this.RelateForm.value.inviteType,
            sourceType: this.RelateForm.value.sourceType,
            pearlModule: 'Public',
            invited: this.RelateForm.value.nnGuess,
            existing: exist,
            meta: updateMeta()
          }
          
          console.log('invite  ' + JSON.stringify(invite));
          const inResult = this.inviteService.updateInvite(invite));
          this.emitterService.emitter.emit('Message','Invite ' + this.RelateForm.value.name + ' save result ' + JSON.stringify(result.content));
        }

        */
        this.notifyParent.emit(relation);
      

        this.RelateForm.reset();
        resolve(true);
    });
 }

}
