import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-login-layout',
  template: `
    <div class="website-container">
      <web-navigation *ngIf="showNav"></web-navigation>
      <router-outlet></router-outlet>
      <web-footer *ngIf="showFooter"></web-footer>
    </div>
  `,
  styles: []
})

export class WebLayoutComponent {
  showNav: boolean = true;
  showFooter: boolean = true;

  constructor(private router: Router) {
    router.events.forEach((event) => {
      if (event['url'] == '/login') {
        this.showNav = false;
        this.showFooter = false;
      } else {
        this.showNav = true;
        this.showFooter = true;
      }
    });
  }
}
