import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { networkConfig } from 'src/config/webapp.config';
import { Relation, FormRelation } from '../../../../bo9-shared/models/person/relation.model';
import { BO9EVENTS } from '../../../../bo9-shared/models/BO9_base/BO9_base.model';
import { ArangoService } from '../arangoservice/arango.service';
import { firstName, lastName, Person } from '../../../../bo9-shared/models/person/person.model';
import { contactFullName } from '../../../../bo9-shared/models/contact/contact.model';
import { BO9UUID } from '../../../../bo9-shared/models/UUID.model';
import { SearchService } from '../search/search.service';
import { InvitedStatus } from '../../../../bo9-shared/models/person/invite.model';
import { resultOK } from '../../../../bo9-shared/models/message/message.model';


@Injectable()
export class RelationsService {
// TODO shrink number returned


    relations: FormRelation[];

    constructor (
        private http: HttpClient,
        private arangoService: ArangoService,
        private searchService: SearchService
    ) {
        this.flush();
    }



    private flush() {
        this.relations = [];
    }

    public loadRelations(key?: string):  Observable<any>  {
        if (key) {
            console.log('get relations key- ' + key);
            return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.RELATION+'?key='+key);
        } else {
            console.log('get relations no key- ');
            return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.RELATION);
        }

      }



   
  
    public deleteRelation(p: Relation, owner: string):  Observable<any>  {
        this.flush();
        return this.http.delete(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.RELATION+'/?relation='+p._key+'&key='+owner);
      }
  
  


    public updateRelation(p: Relation):  Observable<any>  {
        const param = {
          relation: p
        };
        this.flush()
          return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.RELATION, param);
      }
      

    public getRelations(key: string): Promise<FormRelation[]> {
        return new Promise( resolve =>{
           
                this.relations = [];
                const load = this.loadRelations(key)
                .subscribe( async (msg: any) => {  
                    
                    if (resultOK(msg)) {
                      for (let i=0; i< msg.content.length; i++) {
                        const rel:Relation = msg.content[i].relation;
                        if (rel && rel.from) {
                            const fromPerson:Person =  await this.searchService.searchPersonKey(rel.from, false);
                            const toPerson: Person = await this.searchService.searchPersonKey(rel.to, true);

                            const iForm: FormRelation = {
                                _key: msg.content[i]._key, // deletes need this
                                fromFirstName: fromPerson? firstName(fromPerson): '',
                                fromLastName: fromPerson? lastName(fromPerson): '',
                                toFirstName: toPerson?  firstName(toPerson): 'No To',
                                toLastName: toPerson?  lastName(toPerson): 'No To',
                                toEmail: toPerson? toPerson.primaryEmail : 'No Email',
                                inviteType: rel.inviteType,
                                pearlModule: rel.pearlModule,
                                naturalNumber: rel.naturalNumber,
                                sourceType: rel.sourceType,
                                relationType: rel.relationType,
                                relation: rel,
                                invitedStatus: rel.invitedStatus? rel.invitedStatus: InvitedStatus.Unconfirmed,
                                momBirthOrder: rel.momBirthOrder,
                                meta: rel.meta,
                                fromPerson: fromPerson,
                                toPerson: toPerson
                            }
                            this.relations.push(iForm);    
                        }
                    } 
                    const reply=this.relations;
                    load.unsubscribe();
                    resolve(reply);

                } else { // Nothing to do
                    console.log('No relations');
                    load.unsubscribe();
                    resolve([]);
                }
            })
    })
}
}