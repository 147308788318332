import { createUUID } from "../../uuid.service";
import { Meta, updateMeta } from "../BO9_base/meta.model";
import { maxCapability } from "./BO9rolesmethods.model";
import { Capabilities } from "./capabilities.model";
import { Person } from "./person.model";

export interface AgreementVersions  {
    _key: string,
    fileInfo?: [{
        Etag: string,
        BucketName: string,
        Key: string,
        Location: string
    }],
    file?: File,
    name?: string,
    sourceFileName?: string,
    version: string;
    dateCreated: Date;
    dateAgreed?: Date;
    dateSeen?: Date;
    fileAddedIndex?: number;
    checksum?: string;

}

export interface Agreement {
    _key: string;
    title: string;
    created: Date;
    versions: AgreementVersions[],
    capabilities: string[] ,
    meta: Meta

}


export function mkMTAgreement(title: string): Agreement {
    return {
        _key: createUUID(),
        title: title,
        created: new Date(),
        versions: [],
        meta: updateMeta(),
        capabilities: []
    }
}

export function mergeAgreements(master: Agreement[], person: Person): Agreement[] {
    
    const ofuser = person.agreements
    for (let base of master) {
        const found = ofuser.find((agree) => {
                return agree.title === base.title
        })
        const maxCapable = maxCapability(person);
        if (found) {
            if (base.versions[base.versions.length - 1].version !== found.versions[found.versions.length - 1].version) {
                
                if (base.capabilities.includes(maxCapable)) {
                    found.versions.push(base.versions[base.versions.length - 1]) // only add those that match my level

                }
                  
            }
        } else {
            const newAgree = mkMTAgreement(base.title);
            newAgree.capabilities = base.capabilities;
            newAgree.versions = [base.versions[base.versions.length -1]] // init to the last added of array
            ofuser.push(newAgree)
        }
    }
    console.log(JSON.stringify(ofuser));
    return ofuser;


}