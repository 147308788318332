  <mat-card class="bo9mat">

  <my-slide-panel [activePane]="currentPane">
    <div leftPane>

    </div>
    <div centerPane>
      <mat-card class="bo9mat">
        <!-- 
        <span *ngIf="customers" >{{ customers.length }} Customers loaded.</span>
        <form novalidate >
          <label>Build select from customers containing this string: </label>
          <input type="text" [(ngModel)]="searchString" name="searchString"/>
          <button mat-flat-button color="primary" name="search" (click)="onClickSearch()">Search and Build Select List</button>
        </form>  -->
        <form novalidate >
          <label>Build select from order billing email that contains this string: </label>
          <input type="text" [(ngModel)]="searchString" name="searchString"/>
          <button mat-flat-button color="primary" name="searchb" (click)="onClickSearchBilling()">Search and Build Select List</button>
        </form>  
      </mat-card>
      
      <span *ngIf="customers$"><order-list [customers]="customers$ | async" ></order-list> </span>
    </div>

    <div rightPane> 
     

    </div>
  </my-slide-panel>


  
</mat-card>
