<div #addattendee>
  <div *ngIf="isAdmin()">
    <button mat-button class="btn" (click)="selectPerson()" >Fill From Select</button>
  </div>


<hr>
<form [formGroup]="form" (ngSubmit)="submitForm()" class="relate-form">
  <div>
    <h3>{{ event?.name}}: {{ event?._key}} : Add Attendee (Key: {{attendee?.person?._key}})</h3>
    
    <mat-form-field  appearance="outline" >
      <mat-label>First Name</mat-label>
      <input matInput 
        formControlName="first"
        id="first"
        required
      />

    </mat-form-field>


    <mat-form-field  appearance="outline" >
      <mat-label>Last name</mat-label>
      <input matInput 
        formControlName="last"
        id="last"
        required
      />

    </mat-form-field>

    <br />
     

    <mat-form-field  appearance="outline" >
      <mat-label>Email</mat-label>
      <input matInput 
        formControlName="email"
        id="email"
      />

    </mat-form-field>
    <mat-label>Do Not Contact</mat-label> &nbsp;   <mat-checkbox (change)="selectDNC()" [checked]="form.value.DNC"></mat-checkbox>
    <br />


    <mat-form-field  appearance="outline" >
      <mat-label>City</mat-label>
      <input matInput 
        formControlName="city"
        id="city"
      />

    </mat-form-field>
    <mat-form-field  appearance="outline" >
      <mat-label>State</mat-label>
      <input matInput 
        formControlName="state"
        id="state"
      />

    </mat-form-field>
    <br />
    <mat-form-field  appearance="outline" >
      <mat-label>Zip</mat-label>
      <input matInput 
        formControlName="zip"
        id="zip"
      />

    </mat-form-field>
    <mat-form-field  appearance="outline" >
      <mat-label>Country</mat-label>
      <input matInput 
        formControlName="country"
        id="country"
      />

    </mat-form-field>
    <br />
    <mat-form-field  appearance="outline" >
      <mat-label>Phone</mat-label>
      <input matInput 
        formControlName="phone"
        id="phone"
      />

    </mat-form-field>
    <mat-form-field  appearance="outline" >
      <mat-label>Gender</mat-label>
      <mat-select matNativeControl   formControlName="gender">
        <mat-option *ngFor="let nnI of genderTypes" [value]="nnI">
          {{ nnI }}
        </mat-option>
      </mat-select>

    </mat-form-field>
    <br />
    <mat-form-field  appearance="outline" >
      <mat-label>Amount Paid/Donated</mat-label>
      <input matInput 
        formControlName="paid"
        id="paid"
      />
      </mat-form-field>
  
  </div>
  <button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Submit</button>
  

</form>
<button mat-button  (click)="resetForm()"  class="btn">Reset</button>
</div>