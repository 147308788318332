import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { networkConfig } from 'src/config/webapp.config';
import { BO9EVENTS } from '../../../../bo9-shared/models/BO9_base/BO9_base.model';
import { ArangoService } from '../arangoservice/arango.service';
import { mkEmptyPerson, Person } from '../../../../bo9-shared/models/person/person.model';

import { SearchService } from '../search/search.service';
import { Group, GroupMembership, formGroup } from '../../../../bo9-shared/models/person/group.model';
import { resultOK } from '../../../../bo9-shared/models/message/message.model';





@Injectable()
export class GroupsService {
// TODO shrink number returned


    groups: Group[];

    constructor (
        private http: HttpClient,
        private arangoService: ArangoService,
        private searchService: SearchService
    ) {
        this.flush();
    }



    private flush() {
        this.groups = [];
    }


    public loadGroups(key?: string, keyType?:string, groupType?: string):  Observable<any>  {
        let gType='';
        if (groupType)  {
            gType = '&gtype='+groupType;
        }
        if (key && keyType == 'key') {
            console.log('get groups key- ' + key);
            return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.GROUP+'?key='+key+gType);
        } else if (key && keyType=='memberOf') {
            console.log('get groups memberOf- ' + key);
            return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.GROUP+'?memberOf='+key+gType);
        } else if (key && keyType=='createdBy') {
            console.log('get groups createdBy- ' + key);
            return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.GROUP+'?createdBy='+key+gType);
        } else if (key && keyType=='partner') {
            console.log('get groups createdBy- ' + key);
            return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.GROUP+'?createdBy='+key+gType);
        } else if (key && keyType=='createdByKey') {
            console.log('get groups createdByKey- ' + key);
            return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.GROUP+'?createdByKey='+key+gType);
        } else {
            console.log('get groups no key- ');
            return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.GROUP);
        }

      }

      public loadGroupMembers(key?: string):  Observable<any>  {

            console.log('get groupMewmbers key- ' + key);
            return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.GROUPMEMBERS+'?key='+key);

      }

  
    public deleteGroup(p: Group):  Observable<any>  {
        this.flush();
        return this.http.delete(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.GROUP+'/?group='+p._key);
      }

      public leaveGroup(g: string, m:string, o: string):  Observable<any>  {

        return this.http.delete(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.GROUPMEMBERS+'/?group='+g+'&member='+m+'&owner='+o);
      }
  
  
      public updateGroupMembership(p: GroupMembership):  Observable<any>  {
        const param = {
          groupmember: p
        };
        this.flush()
          return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.GROUPMEMBERS, param);
      }
    public updateGroup(p: Group):  Observable<any>  {
        const param = {
          group: p
        };
        this.flush()
          return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.GROUP, param);
      }

    public getGroupMembers(g: Group) :  Promise<GroupMembership[]> {
        return new Promise( resolve =>{
            const people: GroupMembership[] = [];
            const load = this.loadGroupMembers(g._key)
            .subscribe( async (msg: any) => {  
               
                if (resultOK(msg)) {
                    for (const member of msg.content) {
                        if (member && member.membership.to) {
                            const k:string = member.membership.to;
                            const l = k.replace('person/','');
                            const from =  await this.searchService.searchPersonKey(l, true);
                            console.log(`from isd ${k} ` + JSON.stringify(from));
                            if (from && from.contact.names.name.first &&  from.contact.names.name.last) {
                                member.membership.person = from;
                            } else  if (member.membership.invitedPerson) {
                                const p = mkEmptyPerson('Group',member.membership.invitedPerson.email);
                                p.contact.names.name.first = member.membership.invitedPerson.first;
                                p.contact.names.name.last = member.membership.invitedPerson.last;
                                member.membership.person = p;
                            }

                            people.push(member.membership)
                        }
                    } 
                    load.unsubscribe();
                    resolve(people);

                } else { // Nothing to do
                    console.log(`No group members for ${g._key}`);
                    load.unsubscribe();
                    resolve([]);
                }
        })
        })
    }

    public getGroupsMemberOf(key: string): Promise<Group[]> {
        return new Promise( resolve =>{
                const groups: Group[] = [];
                const load = this.loadGroups(key,'memberOf')
                .subscribe( async (msg: any) => {  
                 console.log(`groups members for ${key} ` +JSON.stringify(msg));
                 const memberships: any[] = msg.content;

                 const groupList: string[] = [];
                 for (const g of memberships) {
                     if (!(groupList.includes(g.membership.from))) {
                        groupList.push(g.membership.from)
                     }
                 }
                 for (const gkey of groupList) {
                     const result = await firstValueFrom(this.loadGroups(gkey,'key'));
                     console.log(`groups  for ${gkey} ` +JSON.stringify(result));
                     if (resultOK(result)) {
                        groups.push(result.content[0])
                     }
                 }
                   resolve(groups);
                })
            });
    }
      

    public getGroups(key: string, keyType: string, groupType: string): Promise<Group[]> {
        return new Promise( resolve =>{
                this.groups = [];
                const load = this.loadGroups(key, keyType, groupType)
                .subscribe( (msg: any) => {  
                    
                   resolve(msg.content);
                })
            });
    }
}