import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Email } from '../../../../bo9-shared/models/person/email.model';
import { networkConfig } from 'src/config/webapp.config';
import { BO9EVENTS } from '../../../../bo9-shared/models/BO9_base/BO9_base.model';
import { EmailContactItem } from '../../../../bo9-shared/models/contact/email.model';
import { Person } from '../../../../bo9-shared/models/person/person.model';
@Injectable()
export class EmailService  {



    constructor (    private http: HttpClient) {
        console.log('Emitter create');

    }
    public sendEmail(e: Email):  Observable<any>  {
        const param: any = {
          email: e
        };
       // console.log("email  "+JSON.stringify(param));
        return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.EMAIL, param)
  
      }

      public validateEMail(email: EmailContactItem, person: Person):  Observable<any> {
        const param: any = {
          email: email,
          person: person
        };
        console.log("validate email  "+JSON.stringify(param));
        return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.VALIDATEEMAIL, param)
      }
    
};

