import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { Observable, of, Subscription } from 'rxjs';
import { mkEmptyPerson, mkSimplePerson, Person } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { FormActivity } from '../../../../../bo9-shared/models/activity/activity.model';
import { addNaturalNumber, makeMTNaturalNumber, NaturalNumber } from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';

import { RelationType } from '../../../../../bo9-shared/models/person/relation.model';
import { Event } from '../../../../../bo9-shared/models/event/event.model';
import { ArangoService } from 'src/services/arangoservice/arango.service';

@Component({
  selector: 'activity-form',
  templateUrl: './activityForm.component.html',
  styleUrls: ['./activityForm.component.scss'],
  // make fade in animation available to this component

})
export class ActivityFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() inputActivity: FormActivity;
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
  messagesLoading$: Observable<boolean>;

  disableBtn = false;
  people: Person[];
  valid: false;
  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;
  currentP$: Observable<Person>;
  ActivityForm: FormGroup;
  relatingPersonName: string;

  validationMessages = validationMessages;

  PearlModuleTypes$: Observable<string[]>;
  nnKeys: string[];
  nnSource: string[];

  emails$: Observable<string[]>;
  isLoading = false;

  emailKeys:{key: string; email: string, naturalNumber: number }[] =[];
  selectedKey: string;
  activity$: Observable<string[]>;
  relation$: Observable<RelationType[]>;
  activityd=false;
  firstname= '';
  selectedEmail: string;
  toEvent: Event;
  subscriptions: Subscription[] = [];
  user: Person;
  constructor(
    private auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private arangoService: ArangoService,

    ) 
  {
    this.nnKeys = Object.keys(NaturalNumber).map(key => NaturalNumber[key]).filter(value => typeof value === 'string') as string[];
  
    this.ActivityForm = this.formBuilder.group({
      name: new FormControl('', Validators.compose([Validators.required])),
      kind: new FormControl('', Validators.compose([ Validators.email])),
      date: new FormControl('', Validators.compose([])),
      by: new FormControl('', Validators.compose([])),
      NN: new FormControl('', Validators.compose([Validators.required]))
    });
    this.user = this.auth.getAuthUser();

    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'ActivityFormComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit() {
    
    
  }


  ngOnChanges() {
    this.selectedEmail = '';
    this.ActivityForm.reset();
    this.toEvent = null
    if (this.inputActivity) {
      console.log('inputI  ' + JSON.stringify(this.inputActivity));
      this.toEvent = this.inputActivity.toEvent;
     
      this.ActivityForm.controls.name.setValue(this.inputActivity.toName);
      this.ActivityForm.controls.kind.setValue(this.inputActivity.kind);
      this.ActivityForm.controls.date.setValue(this.inputActivity.date);
      this.ActivityForm.controls.by.setValue(this.inputActivity.by);
      this.ActivityForm.controls.NN.setValue(this.inputActivity.NN);
    }
  }

  submitActivityForm() {
    console.log('Submit activity form ' + JSON.stringify(this.ActivityForm.value));

  }
  
}
