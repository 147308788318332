
<h2>Group Members of: {{ inputGroup?.group.groupName }}</h2>

<div class="action">
	<span *ngIf="inputGroup && isGroupAdmin()">
		<button type="button" (click)="onClickInvite(inputGroup)" matTooltip="Invite To Join">Invite <mat-icon>how_to_reg</mat-icon></button>
	</span>
	<span *ngIf="isAdmin()  || isJourneyman()">
 		<button type="button" (click)="onClickAdd(inputGroup)" matTooltip="Add member from Selection">Add member <mat-icon>add</mat-icon></button>
	</span>
	<span>
		<button mat-stroked-button (click)="onRefreshClick()">Refresh <mat-icon>refresh</mat-icon></button>
	</span>
	<span>
		<span *ngIf="!client">
		<button mat-stroked-button (click)="onBackClick()">Back to Groups <mat-icon>arrow_forward</mat-icon></button></span>
	</span>
</div>
<div *ngIf="inputGroup">

	<div *ngIf='editing' class='editing'>
 		<button class='close' mat-stroked-button (click)="onCloseClick()">Close <mat-icon>close</mat-icon></button>
		<group-member-form [inputGroup]="inputGroup" (notifyParent)="memberSubmittedHandler($event)" ></group-member-form>
	</div>


 <table mat-table [dataSource]="dataSource" matSort>
	<!--
	<ng-container matColumnDef="edit">
	  <th mat-header-cell *matHeaderCellDef mat-sort-header> Edit </th>
	  <td mat-cell *matCellDef="let element">
	<button type="button" (click)="onClickEdit(element)"> <mat-icon>edit</mat-icon></button>
	  </td>
	</ng-container>
	-->
	<ng-container matColumnDef="name">
	  <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
	  <td mat-cell *matCellDef="let element"> {{element.person.contact.names.name.first}} {{element.person.contact.names.name.last}} </td>
	</ng-container>
	<ng-container matColumnDef="admin">
	  <th mat-header-cell *matHeaderCellDef mat-sort-header> Admin </th>
	  <td mat-cell *matCellDef="let element">     <mat-checkbox (change)="selectAdmin(element)" [checked]="element.admin"></mat-checkbox>
	  </td>
	</ng-container>
	<ng-container matColumnDef="primary">
	  <th mat-header-cell *matHeaderCellDef mat-sort-header> Primary </th>
	  <td mat-cell *matCellDef="let element">   <mat-checkbox (change)="selectPrimary(element)" [checked]="element.primaryContact"></mat-checkbox></td>
	</ng-container>
	<ng-container matColumnDef="status">
	  <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
	  <td mat-cell *matCellDef="let element">  {{ element.invitedStatus }}</td>
	</ng-container>

	<ng-container matColumnDef="delete">
	  <th mat-header-cell *matHeaderCellDef mat-sort-header> Leave </th>
	  <td mat-cell *matCellDef="let element">
		 <span *ngIf="!(element.person._key == inputGroup.createdBy)"><button type="button" (click)="onClickLeave(element)"> <mat-icon>logout</mat-icon></button></span>
	  </td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

	</table>
	<mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]">
	</mat-paginator>
 </div>
