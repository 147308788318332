import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { networkConfig } from 'src/config/webapp.config';
import { Activity, FormActivity } from '../../../../bo9-shared/models/activity/activity.model';
import { BO9EVENTS } from '../../../../bo9-shared/models/BO9_base/BO9_base.model';
import { ArangoService } from '../arangoservice/arango.service';
import { Person } from '../../../../bo9-shared/models/person/person.model';
import { contactFullName } from '../../../../bo9-shared/models/contact/contact.model';
import { Event } from '../../../../bo9-shared/models/event/event.model';
import { BO9UUID } from '../../../../bo9-shared/models/UUID.model';
import { SearchService } from '../search/search.service';


@Injectable()
export class ActivityService {
// TODO shrink number returned

    activityMap: Map<string,FormActivity>;
    activitys: FormActivity[];


    constructor (
        private http: HttpClient,
        private arangoService: ArangoService,
        private searchService: SearchService
    ) {
        this.flush();
    }

    // TODO implement by distance, order by distance...


    private flush() {
        this.activityMap = new Map();
        this.activitys = [];
    }

    public loadActivitys():  Observable<any>  {
        console.log('get activitys');
        return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.ACTIVITY);
      }

    public deleteActivity(p: Activity):  Observable<any>  {
        this.flush();
        return this.http.delete(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.ACTIVITY+'/?activity='+p);
      }
  
    public addActivity(p: Activity, c: string):  Observable<any>  {
        const param = {
          activity: p,
          collection: c
        };
        this.flush()
          return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.ACTIVITY, param);
      }

    public getActivity(by: string, force?: boolean): Promise<FormActivity[]> {
        return new Promise( async (resolve)  =>{
            this.activitys = [];

            let msg = await firstValueFrom(this.searchService.searchActivityKey(by));

 
            let added = 0;
            if (msg && msg.content.activity.length > 0) {
                for (const act of msg.content.activity) {
                    const activity = act.activity;
                    let fromPerson: Person;
                    if (activity.by) {
                        fromPerson =  await this.searchService.searchPersonKey(activity.from, false);
                    }
                    
                    let cleanE;
                    if (activity._to) { // For old data it'll be _to
                        cleanE = activity._to.replace('event/', '');
                    } 
                    if (activity.to && typeof activity.to === 'string') {
                        cleanE = activity.to.replace('event/', '');
                    }

                    const iForm: FormActivity = {
                        _key: activity._key,
                        fromName: fromPerson? contactFullName(fromPerson.contact): '',
                        toName: activity.note,
                        kind: activity.kind,
                        date: activity.date,
                        by: activity.by,
                        NN: activity.NN,
                        meta: activity.meta,
                        fromPerson: fromPerson,
                        toEvent: cleanE
                    }
                   // console.log (`Activity ${added} of ${msg.length} `+JSON.stringify(activity))
                    this.activitys.push(iForm);
                    added = added + 1;
                    }
               // msg = await firstValueFrom(this.searchService.searchActivityKey(by));
            }
            resolve(this.activitys);
        })
    }
}