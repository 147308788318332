<mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="30" class='spin' style='float:right;margin-right:15px;'> </mat-progress-spinner>


<h3>Upcoming Events</h3>

<mat-card class="bo9mat" class="flex" style="box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); margin-top:0; padding-top:10px;display:block;">

	<table mat-table [dataSource]="dataSource" matSort>
		<ng-container matColumnDef="_key">
			<th mat-header-cell *matHeaderCellDef> Key </th>
			<td mat-cell *matCellDef="let element"> <mat-icon (click)="showKey(element._key)" matTooltip="{{element._key}}">key</mat-icon></td>
		</ng-container>

		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
			<td mat-cell *matCellDef="let element">
				<mat-icon style="float:right; color:#666;" matTooltip="{{element.description}}">tab</mat-icon> {{element.name}}
			</td>
		</ng-container>
		<ng-container matColumnDef="startDate">
			<th mat-header-cell *matHeaderCellDef  mat-sort-header> Start </th>
			<td mat-cell *matCellDef="let element">{{element.startDate}}  </td>
		</ng-container>
		<ng-container matColumnDef="timezone">
			<th mat-header-cell *matHeaderCellDef  mat-sort-header> Time </th>
			<td mat-cell *matCellDef="let element"> {{element.startTime}} </td>
		</ng-container>
		<ng-container matColumnDef="category">
			<th mat-header-cell *matHeaderCellDef  mat-sort-header> Categories </th>
			<td mat-cell *matCellDef="let element"> {{element.categories.join(', ')}}</td>
		</ng-container>

		<ng-container matColumnDef="source">
			<th mat-header-cell *matHeaderCellDef  mat-sort-header> Source </th>
			<td mat-cell *matCellDef="let element"> {{element.source}} </td>
		</ng-container>
		<ng-container matColumnDef="register">
			<th mat-header-cell *matHeaderCellDef  mat-sort-header> Register </th>
			<td mat-cell *matCellDef="let element">
				<span *ngIf="!(element.registered)"><button mat-button type="button" [disabled]="disableBtn" (click)="onClickRegister(element)"><mat-icon>how_to_reg</mat-icon></button></span>
				<span *ngIf="(element.registered)">Registered </span>

			</td>
		</ng-container>
		<ng-container matColumnDef="link">
			<th mat-header-cell *matHeaderCellDef  mat-sort-header> Client Link (Copy)</th>
			<td mat-cell *matCellDef="let element">
				<span >
				<button mat-button [cdkCopyToClipboard]="'https://bodyof9.com/event-registration'+'/?ee='+element._key"><mat-icon >content_copy</mat-icon></button>
				https://bodyof9.com/event-registration/?ee={{element._key}}</span>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

	</table>
	<mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]" (page)="onChangePage($event)"></mat-paginator>

</mat-card>
