import { Meta, updateMeta } from "../BO9_base/meta.model";
import { Event } from "../event/event.model";
import { Product } from "../product/product.model";
import { Order, Purchase } from "../purchase/purchase.model";
import { WooDownload } from "../woocommerce/woodownload.model";

/*
    the program model defines the initial settings for a progrma, and is then 
    copied into the user's datastructure which is used to manage that user's 
    progression through the program
    Thus ? elements are filled from user perspective, but the original values are set
    upon program definition
    */

export enum IdentificationMethod {
    "Identification",
    "Manual Application",
    "Automated Application"
}

export interface ProgramNotification {
    idMethod: IdentificationMethod;
    programName: string;
    order: Order;
    product: Product;
    quantity: number;
    used?: number;
    available? :number;
}

export interface SupportMaterial {
    name: string,
    quantityRequired: number,
    sent?: {
        quantitySent: number,
        dateSent: string
    }
}

export interface ProgramTracker {
        program: ProgramDefinition,
        status : SubscribedToProgram
}

export interface SupportMaterials {
    sku: string
}

export interface ProgramEvent {
        eventCategory: string,
        numberAllowed: number
}

export interface ProgramDefinition {
    _id: string,
    name: string,
    skus: string[],
    events: ProgramEvent[],
    training: ProgramEvent[],
    coaching: ProgramEvent[],
    supportMaterials: SupportMaterials[],
    startDate: string,
    endDate: string,
    purchaseDate?: string,
    orderId?: string,
    quantity?: number,
    download?: WooDownload,
    meta: Meta
}

export interface SubscribedToProgram {
    subscriptionId: string,
    programId: string,
    attendedCommunityCalls: number,
    attendedCoachingCalls: number,
    userId: string,
    makeupTrainingEvents: Event[],
    supportMaterials: SupportMaterials| null,
    meta: Meta
}

export function createProgramsEntry(order: Product, program: ProgramDefinition, purchase: Purchase): ProgramTracker {
    const pT: ProgramTracker = {
            program: program,
            status: {
              subscriptionId: order.externalid,
              programId: program._id,
              userId: purchase.customerId,
              makeupTrainingEvents: [],
              attendedCommunityCalls: 0,
              attendedCoachingCalls : 0,
              supportMaterials: null,
              meta: updateMeta(),
            } as SubscribedToProgram
         }
         return pT;
}
export function productIsProgram(p: Product, programs: ProgramDefinition[]): string {

    console.log(JSON.stringify(programs));

    for (const progs of programs) {
        if (progs &&  progs.skus.includes(p.sku)) return progs.name;
    }

    return '';
}

export function programFromSKU(sku: string, programs: ProgramDefinition[]): ProgramDefinition | null{

    console.log(JSON.stringify(programs));

    for (const progs of programs) {
        if (progs &&  progs.skus.includes(sku)) return progs;
    }

    return null;
}