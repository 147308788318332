<div class="invite-modal">
  <mat-card class="bo9mat">
    <mat-card-header>


    </mat-card-header>
    <mat-card-content>
      <div flex="45">
        Do you really want to delete the Invitation?
      </div>

    </mat-card-content>
  </mat-card>
  <footer>
    <button mat-stroked-button (click)="close()">
      NO!
    </button>
    <button class="btn-save" mat-flat-button
      (click)="deleteSelectInvite(data.invite)">
      Delete Invitation
    </button>
  </footer>
</div>
