import { Injectable } from '@angular/core';
import { Person } from '../../../../bo9-shared/models/person/person.model';
import { Observable, of } from 'rxjs';
import { InnerMessage } from '../../../../bo9-shared/models/innermessage.model';
import { HttpClient } from '@angular/common/http';
import { firebaseConfig, networkConfig } from 'src/config/webapp.config';
import { BO9EVENTS } from "../../../../bo9-shared/models/BO9_base/BO9_base.model";
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

import { initializeApp } from 'firebase/app';
import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { resolve } from 'path';
import { catchError, map } from 'rxjs/operators';


export interface RegisterCredentials  { email: string; password: string; lastLogin: string; }

@Injectable()
export class LoginService {

    private currentUser: Person;

    public StoredEmail = '';
    public StoredPwd = '';
    public LastLogin = '';
    private loggedIn = false;
    public lauth;

    messageList$: Observable<InnerMessage[]>;

    Person$: Observable<Person>;

    public registerCredentials: RegisterCredentials;

    constructor (
        private http: HttpClient,
        private router: Router,
        private auth: AuthService
    ) {
      this.registerCredentials =  {
        email: '',
        password: '',
        lastLogin: ''
      };
      const app = initializeApp(firebaseConfig);
      this.lauth = getAuth();

      onAuthStateChanged(this.lauth,(user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          var uid = user.uid;
          console.log('User state change '+JSON.stringify(user));

          // ...
        } 
      });
    }

    public GetStoredCredentials(): RegisterCredentials {
       
        return(this.registerCredentials);
    }


    public login(credentials: RegisterCredentials): Promise<any> {
      return new Promise( (resolve, reject) => {
        signInWithEmailAndPassword(this.lauth,credentials.email, credentials.password)
        .then((user) => {
          this.loggedIn = true;
          resolve(user);
        })
        .catch((error) => {
         console.error('Login error code '+error.code);
         console.error('Login error ' + error.message);
          reject(error);
        });
      
      })

    }


    public changeEmail(newEmail: string): Promise<any> {
      return new Promise( (resolve, reject) => {
        const user = this.lauth().currentUser;

        user.updateEmail(newEmail).then(() => {
          // Update successful
          resolve(null);
        })
        .catch((error) => {
         console.error('changeEmail error code '+error.code);
         console.error('changeEmail error ' + error.message);
          reject(error);
        });
      
      })
    }

    public forgotPwd(credentials: RegisterCredentials): Promise<any> {
      return new Promise( (resolve, reject) => {
        sendPasswordResetEmail(this.lauth,credentials.email)
        .then(() => {
          resolve(null);
        })
        .catch((error) => {
         console.error('changePwd error code '+error.code);
         console.error('changePwd error ' + error.message);
          reject(error);
        });
      
      })

    }


    public register(credentials: RegisterCredentials): Promise<any> {
      return new Promise( (resolve, reject) => {
      createUserWithEmailAndPassword(this.lauth,credentials.email, credentials.password)
      .then((user) => {
        resolve(user);
      })
      .catch((error) => {
       console.error('Register User error code '+error.code);
       console.error('Register User error ' + error.message);
        reject(error);
      });
    
    })

  }

  // User logged in or newly registered

  public registerUser(credentials: any): Observable<any> {
    console.log('Register User with ' + JSON.stringify(credentials));
    return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.LOGIN , credentials)
  }

    public registerApp(credentials: RegisterCredentials): Observable<any> {
      console.log('Register with ' + JSON.stringify(credentials));
      return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.REGISTERAPP , credentials);
    }

    public logoutUser(uid: string): Observable<any> {
      console.log('Logoutuser '+uid);
      this.loggedIn = false;
      return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.LOGOUT+'/?id='+uid);
    }

    public signOut(uid: string): Promise<any> {
      return new Promise( (resolve, reject) => {
        this.lauth.signOut()
        .then( ()=>{
          this.logout(uid)
          resolve(null);
        })
      .catch((error) => {
        console.error('Logout User error code '+error.code);
        console.error('Logout User error ' + error.message);
        reject(error);
        });
      })
    }


    public logout(id: string, fromLogin?: boolean) {
      if (this.loggedIn) {
        const sub =  this.logoutUser(id)
        .subscribe( () => {
          this.auth.clearSessionToken();
          this.currentUser = null;
          if (!fromLogin) {
            this.router.navigate(['login'] );
          }
          
        })
      } else {
        this.router.navigate(['login'] );
      }
     


    }

    public getCurrentPerson(): Person {
        return this.currentUser;
    }
}
