<h3> {{ firstname }} Activity....</h3>
<form [formGroup]="ActivityForm" (ngSubmit)="submitActivityForm()" class="relate-form">
	<div>
		<mat-form-field appearance="outline" class="full-width">
			<mat-label>Name</mat-label>
			<input matInput  formControlName="name" required />
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label>Kind</mat-label>
			<input matInput  formControlName="kind" required />
		</mat-form-field>
		 &nbsp;
		<mat-form-field  appearance="outline" >
		<mat-label>Date </mat-label>
			<input matInput
				formControlName="date"
				id="date"
				type="date"
				[matDatepicker]="date"
				required
			/>
			<mat-datepicker #date></mat-datepicker>
		</mat-form-field>
		 &nbsp;
		<mat-form-field appearance="outline" >
			<mat-label>By</mat-label>
			<input matInput  formControlName="kind" required />
		</mat-form-field>
		 &nbsp;
		<mat-form-field appearance="outline" >
			<mat-label>Natural Number?</mat-label>
			<mat-select matNativeControl  formControlName="NN" required>
				<mat-option *ngFor="let nnI of nnKeys" [value]="nnI">{{ nnI }}</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Submit</button>
</form>
