<form [formGroup]="form" (ngSubmit)="submitForm()" class="relate-form">
  <div>
    <h3>Email: {{ person.primaryEmail }}</h3>
    
    <mat-form-field  appearance="outline" >
      <mat-label>Subject</mat-label>
      <input matInput 
        formControlName="subject"
        id="subject"
        required
      />

    </mat-form-field><br>

    <mat-form-field  appearance="outline" >
      <mat-label>HTML</mat-label>
      <qeditor
      formControlName="html"
      placeholder="Type your html here..."
      required
    ></qeditor>

    </mat-form-field> <br>
    <mat-form-field  appearance="outline" >
      <mat-label>Text (Might not appear)</mat-label>
      <input matInput 
        formControlName="text"
        id="text"
      />

    </mat-form-field><br>



  </div>
  <span *ngIf="!person?.contact.DNC"><button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Send</button></span>

</form>