<div [formGroup]="form">
	<div>
		<h3 class="nn-form-title">Natural Number</h3>
		<div>
			<mat-expansion-panel  [expanded]="checkDiffs('identified', diffs)">
				<mat-expansion-panel-header>
					<mat-panel-title  *ngIf="identifiedNN  != nnNone; else notIdentified">
						<span class="header-name"><mat-icon>{{ nnIconIdentified }}</mat-icon></span> &nbsp; Identified
					</mat-panel-title>
				</mat-expansion-panel-header>
				<ng-template #notIdentified>
					<span class="header-name"><mat-icon>{{ nnIconIdentified }}</mat-icon></span> &nbsp; <em class="empty-title">Not yet identified</em>
				</ng-template>
				<span *ngIf="!(self)">
					<source-form formControlName="identified" ></source-form>
				</span>
				<span *ngIf="self">
					<span *ngIf="value.identified.naturalNumber"> Natural Number: {{ value.identified.naturalNumber }},
						<span *ngIf="value.identified.setBy && value.identified.setBy.name"> By: {{ value.identified.setBy.name }},</span>
						<span *ngIf="value.identified.setAt"> At: {{ value.identified.setAt }}, </span>
						<span *ngIf="value.identified.dateSet"> On: {{ value.identified.dateSet }}</span>
			 		</span>
					<span (mouseenter)="changeText=true" (mouseleave)="changeText=false">
						<span *ngIf="!changeText"><span class="material-icons">help_outline</span></span>
						<span *ngIf="changeText"> <bold>Please email susanandmartin&#64;bodyof9.com if this information is not correct.</bold></span>
					</span>
				</span>
			</mat-expansion-panel>

		<mat-expansion-panel  [expanded]="checkDiffs('asserted', diffs)">
			<mat-expansion-panel-header>
				<mat-panel-title  *ngIf="sourceType != nnNone; else notAsserted">
					<span class="header-name"><mat-icon>{{ nnIconAsserted }}</mat-icon></span> &nbsp; Asserted
				</mat-panel-title>
			</mat-expansion-panel-header>
				<ng-template #notAsserted>
					<span class="header-name"><mat-icon>{{ nnIconAsserted }}</mat-icon></span> &nbsp; <em class="empty-title">No NN asserted</em>
				</ng-template>
			<source-form formControlName="asserted" ></source-form>
		</mat-expansion-panel>

		<mat-expansion-panel  [expanded]="checkDiffs('invited', diffs)">
			<mat-expansion-panel-header>
				<mat-panel-title  *ngIf="invitedNN  != nnNone; else noInvitations">
					<span class="header-name"><mat-icon>group</mat-icon></span> &nbsp; Invited
				</mat-panel-title>
			</mat-expansion-panel-header>
			<ng-template #noInvitations>
				<span class="header-name"><mat-icon>group</mat-icon></span> &nbsp; <em class="empty-title">No one yet invited</em>
			</ng-template>
			<source-form formControlName="invited"></source-form>
		</mat-expansion-panel>

		<mat-expansion-panel  [expanded]="checkDiffs('history', diffs)">
			<mat-expansion-panel-header>
				<mat-panel-title  *ngIf="historyList  && historyList.length > 0; else noHistoryTitle">
					<span class="header-name"><mat-icon>history</mat-icon></span> &nbsp; History
				</mat-panel-title>
			</mat-expansion-panel-header>
			<ng-template #noHistoryTitle>
				<span class="header-name"><mat-icon>history</mat-icon></span> &nbsp; <em class="empty-title">No active history</em>
			</ng-template>
			<div *ngIf="historyList  && historyList.length > 0; else noHistory">
				<ul>
					<li *ngFor="let h of historyList">NN:{{ h.naturalNumber }}, By:{{ h.setBy.name}}, At:{{h.setAt }}, SRC:{{ h.source }}, Date:{{ h.dateSet }}</li>
				</ul>
			</div>
			<ng-template #noHistory>No Natural Number History recorded</ng-template>
		</mat-expansion-panel>

	</div>
</div>
