import { Injectable } from '@angular/core';

import { ArangoService } from '../arangoservice/arango.service';
import { Agreement } from '../../../../bo9-shared/models/person/agreements.model';
import { Person } from '../../../../bo9-shared/models/person/person.model';
import { Subscription, firstValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AgreementDialogComponent } from 'src/displayComponents/dialogs/agreementDialog/agreementDialog.component';
import { maxCapability } from '../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { Email } from '../../../../bo9-shared/models/person/email.model';


@Injectable()
export class AgreementService {
// TODO shrink number returned

subscriptions: Subscription[] = [];
    constructor (
        private arangoService: ArangoService,
        private dialog: MatDialog,
    ) {

    }

    public confirmAgreements( p: Person): Promise<Person> {
        return new Promise(async  (resolve, reject) => {
            let changed = false;

            for (let agree of p.agreements) {
                
                const maxCapable = maxCapability(p);

                if (agree.capabilities.includes(maxCapable)) {
                    const latestVersion = agree.versions[agree.versions.length - 1];

                    if (!latestVersion.dateAgreed) {
                        console.log("Not Agreed " + agree.title)
                        const dRef = this.dialog.open(AgreementDialogComponent, {
                            width: '100%',
                            height: '75%',
                            data: {agreement: agree}
                          });
                      
                        const result = await firstValueFrom(dRef.afterClosed());
                        console.log("result " + result.changed)
                        if (result.changed) {
                            changed = true;
                            agree = result.agreement
                        }
                        if (result.skip) {// Not good
                            const e: Email = {
                                to: 'guild.manager@bodyof9.com',
                                subject: "User skipped or Declined Agreement",
                                text: p.primaryEmail + ' declined or skipped ' + agree.title + ' version ' + latestVersion.version,
                                html: p.primaryEmail + ' declined or skipped ' + agree.title + ' version ' + latestVersion.version,
                                from: 'bonnie@bodyof9.com'
                              }
                      
                              const r = await firstValueFrom(this.arangoService.sendIssue(e));
                        }
                        
                    }
                }
                
            }
            if (changed) {
                await firstValueFrom(this.arangoService.updatePerson(p));
        
            }


            resolve(p);
            
            
            
          })
    }

    ngOnDestroy() {
    
          this.subscriptions.forEach(s => s.unsubscribe());
    
      }



}