<div class="invite-modal">
  <form [formGroup]="NNForm" (ngSubmit)="submitNNForm()" class="Pearl-form" novalidate >
    <button mat-button [disabled]="disableBtn" type="submit"  class="btn btn-save mat-submit-button">Submit</button>
      <div flex="45">
        <mat-form-field appearance="outline" >
          <mat-label>Natural Number?</mat-label>
          <mat-select matNativeControl   formControlName="selectedNN" required multiple>
            <mat-option *ngFor="let nnI of nnKeys" [value]="nnI">
              {{ nnI }}
            </mat-option>
          </mat-select>

          </mat-form-field>
       
      </div>
      </form>

  <footer>

  </footer>
</div>
