
<my-slide-panel [activePane]="currentPane">
	 <section leftPane>
		<button mat-stroked-button (click)="onCloseFormClick()">Close</button>

	 </section>

	 <section centerPane>
		 <div class="action">
			<!--
			 <span>
				<button mat-stroked-button type="button"  [disabled]="disableBtn"  (click)="onClickAdd()">Select Person to Make Student<mat-icon>add_circle</mat-icon></button>

		   </span>
		   <span>
			<button mat-stroked-button type="button"  [disabled]="disableBtn"  (click)="onClickEmail()">Send Email to All Students<mat-icon>email</mat-icon></button>

	   		</span>

		-->
			 <span>
				 <mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="30" class='spin'> </mat-progress-spinner>
			 </span>

			 <span>
				 <button mat-stroked-button (click)="onRefreshClick()">Refresh<mat-icon>refresh</mat-icon></button>
			 </span>
		 </div>

		 <h2>Enrolled</h2>
		 <table mat-table [dataSource]="insdataSource" matSort multiTemplateDataRow>
			<ng-container matColumnDef="view">
			   <th mat-header-cell *matHeaderCellDef> Edit Student</th>
			   <td mat-cell *matCellDef="let element">
				 <button type="button" (click)="onClickEdit(element)" matTooltip="View"> <mat-icon>preview</mat-icon></button>
   
			   </td>
			</ng-container>
   
   
			<ng-container matColumnDef="email">
			   <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
			   <td mat-cell *matCellDef="let element" >  <button mat-button [cdkCopyToClipboard]="element.primaryEmail"><mat-icon >content_copy</mat-icon></button>{{element.primaryEmail}} 
					</td>
			</ng-container>
			<ng-container matColumnDef="name">
			   <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
			   <td mat-cell *matCellDef="let element" > {{element.contact.names.name.first}} {{element.contact.names.name.last}}  </td>
			</ng-container>
   
			<ng-container matColumnDef="courses">
			   <th mat-header-cell *matHeaderCellDef  mat-sort-header> Courses </th>
			   <td mat-cell *matCellDef="let element"> 

				<course-list [student]="element" [tracks]="tracks" [courses]="courses"></course-list>
				</td>
			</ng-container>
   
			<ng-container matColumnDef="delete">
			   <th mat-header-cell *matHeaderCellDef  mat-sort-header> Delete </th>
			   <td mat-cell *matCellDef="let element">  <span><button mat-button type="button" (click)="onDelete(element)"> <mat-icon>delete_forever</mat-icon></button> </span>
				 </td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="insdisplayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: insdisplayedColumns;"></tr>
   
		   </table>

		 <h2>Eligible for Enrollment</h2>
		<table mat-table [dataSource]="dataSource" matSort multiTemplateDataRow>
		 <ng-container matColumnDef="enroll">
			<th mat-header-cell *matHeaderCellDef> Enroll Student</th>
			<td mat-cell *matCellDef="let element">

			  <button type="button" (click)="onClickenroll(element)" matTooltip="View"> <mat-icon>school</mat-icon></button>

			</td>
		 </ng-container>


		 <ng-container matColumnDef="email">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
			<td mat-cell *matCellDef="let element" >  <button mat-button [cdkCopyToClipboard]="element.primaryEmail"><mat-icon >content_copy</mat-icon></button>{{element.primaryEmail}} 
				 </td>
		 </ng-container>
		 <ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
			 <td mat-cell *matCellDef="let element" > {{element.contact.names.name.first}} {{element.contact.names.name.last}}  </td>
		 </ng-container>

		 <ng-container matColumnDef="courses">
			<th mat-header-cell *matHeaderCellDef  mat-sort-header> Courses </th>
			<td mat-cell *matCellDef="let element"> 
				<course-list [student]="element" [tracks]="[]" ></course-list>
				
			</td>
		 </ng-container>

		 <ng-container matColumnDef="delete">
			<th mat-header-cell *matHeaderCellDef  mat-sort-header> Delete </th>
			<td mat-cell *matCellDef="let element">  <span><button mat-button type="button" (click)="onDelete(element)"> <mat-icon>delete_forever</mat-icon></button> </span>
			  </td>
		 </ng-container>
		 <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		 <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

		</table>
		<mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]"  (page)="onChangePage($event)"></mat-paginator>
	 </section>

	 <section rightPane>
		<button mat-stroked-button (click)="onCloseFormClick()">Close</button>
		
	 </section>
</my-slide-panel>
