import { Component, forwardRef, ChangeDetectionStrategy, OnDestroy, OnInit, Input, OnChanges, EventEmitter, Inject } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { firstValueFrom, Subscription } from 'rxjs';
import { validationMessages } from 'src/helpers/form.helper';

import { Email} from '../../../../../bo9-shared/models/person/email.model';

import { EmailService } from 'src/services/email/email.service';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { findInvalidControlsRecursive } from '../../../helpers/form.helper';
import { Member } from '../../../../../bo9-shared/models/member/member.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmailContactItem } from '../../../../../bo9-shared/models/contact/email.model';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth/auth.service';
import { webAppVersion } from 'src/config/webapp.config';


@Component({
    selector: 'email-dialog',
    templateUrl: './emailDialog.component.html',
    styleUrls: ['./emailDialog.component.scss'],
 
  })
  export class  EmailDialogComponent  implements OnInit {
    disableBtn = false;
    name: string;
    form: FormGroup;
    subscriptions: Subscription[] = [];
    validationMessages = validationMessages;
    allOk = false;
    emailErrors: string[] = [];
    user: Person;
    emailItem: EmailContactItem ;

    DNC: boolean;

    version = webAppVersion;

    constructor (private formBuilder: FormBuilder, private router: Router,private arangoDBService: ArangoService,
      private auth: AuthService,
      private dialogRef: MatDialogRef<EmailDialogComponent>,
      @Inject(MAT_DIALOG_DATA)  public data: { from: Person} ,

     ) {
      this.form = this.formBuilder.group({
        subject: ['By:'+this.data.from.primaryEmail+ " - Version:"+this.version+' ', Validators.compose([Validators.required])],
        html: ['', Validators.compose([Validators.required])],
      });
        console.log ('Init activity ' + JSON.stringify(data));
        this.emailErrors= [];
        this.user = this.auth.getAuthUser();

        this.subscriptions.push(this.arangoDBService.recordVisit({
          page: this.router.url, component: 'SupportDialogComponent', 
          visitor: this.user._key,
          help: false
        }).subscribe ({next: () => {
         
        }})); 
       }
  

    ngOnInit() {

    }

    close() {

      this.dialogRef.close({});
      
    }

    async submitForm() {
      console.log('Submit email form ' + JSON.stringify(this.form.value));
      if (this.form.valid) {
       
        const e: Email = {
          to: 'support@bodyof9.com',
          subject: this.form.value.subject,
          text: '',
          html: this.form.value.html,
          from: 'bonnie@bodyof9.com'
        }

        const r = await firstValueFrom(this.arangoDBService.sendIssue(e));
        alert("Sent!");
        this.dialogRef.close();
        
      } else {
        alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.form)));
      }
    }
    //this.PersonForm.reset();
  
    ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
    }


  }