import { Component,OnDestroy, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { firstValueFrom, Subscription } from 'rxjs';
import { validationMessages } from 'src/helpers/form.helper';
import { createUUID,  responseEncode, trimUUID} from '../../../../../bo9-shared/uuid.service';
import { DateToYYMMDDhhmmss } from '../../../../../bo9-shared/helpers/time.helper';
import { EventEmitterService } from 'src/services/events/events.service';
import { MatDialog } from '@angular/material/dialog';
import { findInvalidControlsRecursive } from '../../../helpers/form.helper';
import { formGroup, GroupMembership } from '../../../../../bo9-shared/models/person/group.model';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import { GroupsService } from 'src/services/groups/groups.service';
import { InvitedStatus } from '../../../../../bo9-shared/models/person/invite.model';
import { ActiveCampaignService } from 'src/services/activecampaign/activecampaign.service';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Router } from '@angular/router';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { AuthService } from 'src/services/auth/auth.service';
import { IdentificationMethod, ProgramNotification } from '../../../../../bo9-shared/models/program/program.model';
import { SelectIdentificationDialogComponent } from 'src/displayComponents/dialogs/selectIdentificationDialog/selectIdentificationDialog.component';
import { EventCounts, EventParams } from '../../../../../bo9-shared/models/event/event.model';
import { ActiveCampaignDeal } from '../../../../../bo9-shared/models/mailCampaign/activeCampaign.model';
import { Order } from '../../../../../bo9-shared/models/purchase/purchase.model';


@Component({
    selector: 'prepaid-member-form',
    templateUrl: './prepaidMemberForm.component.html',
    styleUrls: ['./prepaidMemberForm.component.scss'],
 
  })
  export class PrePaidMemberFormComponent implements OnDestroy, OnChanges {
    @Input() inputGroup: formGroup;
    @Input() packages: ProgramNotification[]
    @Input() eventCounts: EventCounts;
    @Input() members: GroupMembership[];
    @Input() person: Person;
    @Input() canMEA: boolean;
    @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
    name: string;
    form: FormGroup;
    user: Person;
    subscriptions: Subscription[] = [];
    validationMessages = validationMessages;
    disableBtn = false;
    gm: GroupMembership;
    eventId: string;
    link: string;
    eventParam: EventParams;
    get value(): formGroup {
      console.log('name get '+ JSON.stringify(this.form.value));
      return this.form.value;
    }
  
    set value(value: formGroup) {
      console.log('name get '+ JSON.stringify(value));
      this.form.setValue(value);
    }


    constructor(private formBuilder: FormBuilder, 
      private emitterService: EventEmitterService,
      private groupService: GroupsService,
      private arangoService: ArangoService,
      private router: Router,
      private dialog: MatDialog,
      private activeService: ActiveCampaignService,
      private auth: AuthService) {
        this.link = '';
      this.makeForm();
      this.gm = {
        _key:  createUUID(),
        from: this.inputGroup?._key? this.inputGroup._key: '',
        to: null,
        primaryContact: false,
        groupOwner: '',
        admin: false,
        invitedStatus: InvitedStatus.Invited,
        meta: updateMeta()
      }
      this.user = this.auth.getAuthUser();
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'ClientMemberFormComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 

    }

    makeForm() {
      this.form = this.formBuilder.group({
        first: ['', Validators.compose([Validators.required])],
        last: ['', Validators.compose([Validators.required])],
        email: ['', Validators.compose([])]
      });
      
    }

    assignMEA() {
      if (this.form.valid) {
        const id = trimUUID(createUUID());
        let order: Order;
        for (const d of this.packages) {
          console.log ('Init template d ' + JSON.stringify(d.idMethod));
          if (d.idMethod === IdentificationMethod['Automated Application']) {
            order = d.order;
          }
        }
        this.eventParam = {
          id: id,
          first: this.form.value.first,
          last: this.form.value.last,
          email: this.form.value.email,
          creatorKey: this.person._key,
          assignedDate: DateToYYMMDDhhmmss(),
          link: '/#/meeting/'+id,
          order: order,
          dealId: ''
        }
    } else {
      console.log("oops");
      alert("Unable to assign MEA , not all data completed");
    }
    }

    assignLink() {
      if (this.form.valid) {
        //console.log("Assign link "+JSON.stringify(this.packages));
        const dRef = this.dialog.open(SelectIdentificationDialogComponent, {
          width: '75%',
          height: '75%',
          data: {packages: this.packages, members: this.members, form: this.form}
        });
    
        dRef.afterClosed()
        .subscribe(  (result: ProgramNotification) => {
          console.log('Selected people is ' + JSON.stringify(result));
          if (result) {
            const id = trimUUID(createUUID())
            this.eventParam = {
              id: id,
              first: this.form.value.first,
              last: this.form.value.last,
              email: this.form.value.email,
              creatorKey: this.person._key,
              assignedDate: DateToYYMMDDhhmmss(),
              link: 'https://identification.bodyof9.com/#/schedule/'+id,
              order: result.order,
              dealId: ''
            }
          }
  
          });
      } else {
        console.log("oops");
        alert("Unable to assign pre-purchase identifcation, not all data completed");
      }

    }

    ngOnChanges() {
      console.log('OnChange ');
      this.form.reset();
    }

    async submitForm() {
      console.log('Prepay member form ' + JSON.stringify(this.form.value));
      // zdeal flow depends on assessment type
      if (this.form.valid) {
        let invitedTo = '';
        if (this.canMEA) {
          const result  = await firstValueFrom (this.arangoService.addEventParams(this.eventParam))// make a deal

          console.log('add ep result  is: ' + JSON.stringify(result.content));
          this.emitterService.emitter.emit('Message','MEA ' + this.eventParam.first+ ' ' +this.eventParam.last);
          this.notifyParent.emit(this.eventParam.first+ ' ' +this.eventParam.last+' setup for MEA');
          
        } else {
          const newmember: GroupMembership = {
            _key: this.gm._key,
            to: createUUID(),
            from: this.inputGroup._key,
            primaryContact: this.gm.primaryContact,
            groupOwner: this.inputGroup.group.createdBy,
            admin: this.gm.admin,
            invitedStatus: InvitedStatus.Invited, // Added by admin can't decline :
  
            invitedPerson: {
              first: this.form.controls.first.value,
              last: this.form.controls.last.value,
              email: this.form.controls.email.value,
              whenInvited: DateToYYMMDDhhmmss(),
              link: this.eventParam.link,
              id: this.eventParam.id,  
              inviteTo: 'Assigned Assessment',
              inviteBy: this.inputGroup.group.createdBy,
            },
            meta: updateMeta()
          }
  
          console.log('Assessment member is: ' + JSON.stringify(newmember));
          const result  = await firstValueFrom (this.activeService.prepay(newmember) );// make a deal

            if (result.content) {
              newmember.invitedPerson.inviteDeal = result.content.deal as ActiveCampaignDeal;
              this.eventParam.dealId = newmember.invitedPerson.inviteDeal.id;
              const r = await firstValueFrom( this.arangoService.addEventParams(this.eventParam)); // Has dealID now
              console.log('Prepay result  is: ' + JSON.stringify(result.content));
              const res = await firstValueFrom(this.groupService.updateGroupMembership(newmember));
  
              this.emitterService.emitter.emit('Message','Asessesing Member ' + newmember.invitedPerson.first+ ' ' +newmember.invitedPerson.last);
              this.notifyParent.emit('person prepaid setup');
            } 
      
        }
       
      } else {
        alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.form)));
      }
    }

  
    ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
    }

  
    reset() {
      this.form.reset();
    }
  }