<div class="selectPerson-modal">


	<form novalidate >
		<div>
			<label class='search'>Search (max return 60 rows): <br>
				<input type="text" [(ngModel)]="searchString" name="searchString" (keyup.enter)="onClickSearch($event)"/>
			</label>
			 &nbsp;
			<button mat-flat-button color="primary" name="search" (click)="onClickSearch(null)">Search</button>
		</div>
	</form>
	<hr>
	<button mat-stroked-button (click)="close()">Cancel</button>
	 &nbsp;
	<button class="btn-save" mat-flat-button (click)="selectAndClose()">Add People</button>
	<mat-card class="bo9mat">
	 <mat-card-content>
		  <table mat-table [dataSource]="dataSource" matSort>
		  <ng-container matColumnDef="select">
			 <th mat-header-cell *matHeaderCellDef> Select </th>
			 <td mat-cell *matCellDef="let element">
				<button type="button" (click)="selectFormPerson(element)" matTooltip="Select"> <mat-icon>check_circle_outline</mat-icon></button>
			 </td>
		  </ng-container>

		  <ng-container matColumnDef="email">
			 <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
			 <td mat-cell *matCellDef="let element">

				<span (click)="selectSinglePerson(element)">{{element.email}} </span>
			 </td>
		  </ng-container>
		  <ng-container matColumnDef="name">
			 <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
			 <td mat-cell *matCellDef="let element">  <span (click)="selectSinglePerson(element)">{{element.firstName}} {{element.lastName}} </span></td>
		  </ng-container>
		  <ng-container matColumnDef="naturalNumber">
			 <th mat-header-cell *matHeaderCellDef mat-sort-header> Nat. Num </th>
			 <td mat-cell *matCellDef="let element"> {{element.naturalNumber}} </td>
		  </ng-container>
		  <ng-container matColumnDef="source">
			 <th mat-header-cell *matHeaderCellDef mat-sort-header> Source </th>
			 <td mat-cell *matCellDef="let element"> {{ element.source }} </td>
		  </ng-container>
		  <ng-container matColumnDef="identified">
			 <th mat-header-cell *matHeaderCellDef mat-sort-header> Identified </th>
			 <td mat-cell *matCellDef="let element"> {{ dateFormating(element.identified, "identified") }} </td>
		  </ng-container>
		  <ng-container matColumnDef="primary">
			 <th mat-header-cell *matHeaderCellDef mat-sort-header> Primary Contact </th>
			 <td mat-cell *matCellDef="let element"> <mat-checkbox (change)="selectPrimary(element)" [checked]="element.primaryContact"></mat-checkbox></td>
		  </ng-container>
		  <ng-container matColumnDef="admin">
			 <th mat-header-cell *matHeaderCellDef mat-sort-header> Admin </th>
			 <td mat-cell *matCellDef="let element"><mat-checkbox (change)="selectAdmin(element)" [checked]="element.admin"></mat-checkbox> </td>
		  </ng-container>

		  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		  <tr mat-row *matRowDef="let row; columns: displayedColumns;"  [ngClass]="{'make-gold': row.selected }"></tr>

		  </table>
	 </mat-card-content>
  </mat-card>
  <footer>
	 <button mat-stroked-button (click)="close()"> Cancel</button>
	  &nbsp;
	 <button class="btn-save" mat-flat-button (click)="selectAndClose()">Add People</button>
  </footer>
</div>
