export interface FirstLastName {
    title: string;
    first: string;
    middle?: string
    last: string;
}

export interface FullName {
    name: FirstLastName;
    other: FirstLastName[];
}

export function addName(original: FullName | null, first: string, last: string,  title?: string,middle?: string): FullName {
    const newName: FullName = {
        name: {
            title: title? title : '',
            first: first,
            middle: middle ? middle : '',
            last: last
        },
        other: original ? (original.other ? original.other.concat(original.name): [original.name]) : []
    }
    return newName
}

export function existingName(names: FirstLastName[], check: FirstLastName) {
    let result: boolean = false;

    if (names.length > 0) {
        for (const e of names ) {
            if (e.first == check.first && 
                e.last == check.last) {
                    return true;
                }
        }
    }

    return(false);
}


export function mergeName(original: FullName, additional: FullName): FullName {
    let newP: FullName = {
        name: additional.name,  // new name rules
        other: original.other // we put the orignal others on here, add new to them later
    }
    // If original name not same as new name OR new name not in existing others
    if ( !((original.name.first == additional.name.first) && 
            (original.name.last == additional.name.last))) {// name not the same 
        if (!existingName(original.other, additional.name)) { // Not already in others
            newP.other.push(original.name); // onto end
        }
    }

    // now check to see if new others any match of current others
    for (let e=0; e< additional.other.length; e++) { // check each to see if it needs to be added
        if (!existingName(original.other, additional.other[e])) {
            const newName: FirstLastName = {
                first: additional.other[e].first ? additional.other[e].first : '',
                middle: additional.other[e].middle ?additional.other[e].middle : '',
                last: additional.other[e].last ? additional.other[e].last : '',
                title: additional.other[e].title ? additional.other[e].title : '',
            }
            newP.other.push(newName); // onto end
        }
    }
    return(newP);
}