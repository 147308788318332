var Buffer = require('buffer/').Buffer  // note: the trailing slash is important!

export function isNumeric(val: any): boolean {
    const res = !(val instanceof Array) && (val - parseFloat(val) + 1) >= 0
    console.log(`IS Numeric ${val} `+ JSON.stringify(res))
    return res
}

export function decodeB64 (str: string):string {
    return Buffer.from(str, 'base64').toString('binary');
}

export function encodeB64 (str: string):string {
    return Buffer.from(str, 'binary').toString('base64');
}