  <mat-card class="bo9mat" *ngIf="operation=='relations'">
    <button mat-stroked-button (click)="onCloseClick()" >Close</button>
    <relation-list 
      [attendees]="attendees"  
      [from]="fromF$ | async" 
      [name]="fromName"
      (notifyParent)="relationSubmittedHandler($event)" 
      [canAll]=false ></relation-list>
  </mat-card>

  <mat-card class="bo9mat" *ngIf="operation=='editattendee'" >
    <button mat-stroked-button (click)="onCloseClick()"  >Close</button>
      <identified-form  [attendees]="attendees" (notifyParent)="attendeeSubmittedHandler($event)" [attendee]="attendee"></identified-form>
  </mat-card>
  <mat-card class="bo9mat" *ngIf="operation=='subscriptions'">
    <button mat-stroked-button (click)="onCloseClick()">Close</button>
    <subscription-list [from]="fromF$ | async" ></subscription-list>
  </mat-card>
  <mat-card class="bo9mat">
    
    <mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="30" class='spin'> </mat-progress-spinner>
    <h3 #table> Identified/Asserted*</h3>  
    <div *ngIf="isAdmin()">
    <button mat-stroked-button (click)="onClickAddIdentified()">Add Person (Identified or Asserted*)<mat-icon>add_circle</mat-icon></button>	
    </div>
    <div *ngIf="!isAdmin()">
      <button mat-stroked-button (click)="onClickAddIdentified()">Add Person - Asserted*<mat-icon>add_circle</mat-icon></button>	
    </div>
    

   <button mat-stroked-button (click)="onRefreshClick()"  [disabled]="disableBtn" >Refresh<mat-icon>refresh</mat-icon></button> 

        <table mat-table [dataSource]="dataSource" matSort class="full_width">
          <ng-container matColumnDef="controls">
            <th mat-header-cell *matHeaderCellDef> Controls </th>
            <td mat-cell *matCellDef="let element; let i = index">

              <span *ngIf="i==0" >
                <span *ngIf="element.person!=null"><button type="button" (click)="onClickID(element)" matTooltip="Edit Identifed"> <mat-icon>edit</mat-icon></button></span>
              <span *ngIf="element.person!=null"><button type="button" (click)="onClickEdit(element)" matTooltip="Edit Contact"> <mat-icon>preview</mat-icon></button></span>
              <span *ngIf="element.person!=null"> <button type="button" (click)="onClickRelate(element)" matTooltip="Relations"> <mat-icon>family_restroom</mat-icon></button></span>
              <span *ngIf="element.person!=null"> <button type="button" (click)="onClickSubscriptions(element)" matTooltip="Subscriptions"> <mat-icon>subscriptions</mat-icon></button></span>
              <span *ngIf="element.person!=null && (!element.diffAccepted && (element.difference && element.difference.length > 0))"> <button  type="button" (click)="onClickMerge(element)" matTooltip="Merge"> <mat-icon>merge_type</mat-icon></button></span>
            </span>
            <span *ngIf="i!=0" >
              <span *ngIf="element.person!=null"><button type="button" (click)="onClickID(element)"> <mat-icon>edit</mat-icon></button></span>
              <span *ngIf="element.person!=null"><button type="button" (click)="onClickEdit(element)" > <mat-icon>preview</mat-icon></button></span>
              <span *ngIf="element.person!=null"> <button type="button" (click)="onClickRelate(element)" > <mat-icon>family_restroom</mat-icon></button></span>
              <span *ngIf="element.person!=null"> <button type="button" (click)="onClickSubscriptions(element)"> <mat-icon>subscriptions</mat-icon></button></span>
              <span *ngIf="element.person!=null && (!element.diffAccepted && (element.difference && element.difference.length > 0))"> <button type="button" (click)="onClickMerge(element)"> <mat-icon>merge_type</mat-icon></button></span>
            </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef> Edit </th>
            <td mat-cell *matCellDef="let element">
              <span ><button type="button" (click)="onClickEdit(element)" > <mat-icon>edit</mat-icon></button></span>
              <span *ngIf="element.person!=null"> <button type="button" (click)="onClickRelate(element)" > <mat-icon>family_restroom</mat-icon></button></span>
            </td>
          </ng-container>

          <ng-container matColumnDef="who">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Who </th>
            <td mat-cell *matCellDef="let element">  {{element.first}} {{element.last}} - {{element.email}}   </td>
          </ng-container>
          <ng-container matColumnDef="key">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Att.id </th>
            <td mat-cell *matCellDef="let element"> {{element._key}} </td>
          </ng-container>
          <ng-container matColumnDef="first">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> First </th>
            <td mat-cell *matCellDef="let element" matTooltip="{{element._key}} ">
              {{element.first}} 
              <span *ngIf="element.first != element.dbFirst" style="background-color: red"><br/>{{ element.dbFirst }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="last">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last </th>
            <td mat-cell *matCellDef="let element" matTooltip="{{element._key}} "> {{element.last}} 
              <span *ngIf="element.last != element.dbLast" style="background-color: red"><br/>{{ element.dbLast}}</span> </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let element">
            <span *ngIf="element.email " > {{element.email}}  </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="dnc">
            <th mat-header-cell *matHeaderCellDef class="td-center-text"> DNC </th>
            <td mat-cell *matCellDef="let element" class="attended-col, td-center-text"> 
              <mat-checkbox (change)="selectDNC(element)" [checked]="element.person.contact.email.DNC"></mat-checkbox></td>
          </ng-container>
          <ng-container matColumnDef="idBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="td-center-text panelClass" disableOptionCentering > Set By </th>
            <td mat-cell *matCellDef="let element">  
               <mat-select matNativeControl multiple [(ngModel)]="element.idBy" (selectionChange)="bySelected($event, element)">
                <mat-option *ngFor="let nns of idList" [value]="nns">
                  {{ nns }}
                </mat-option>
              </mat-select> </td>
          </ng-container>
          <ng-container matColumnDef="idAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="td-center-text panelClass" disableOptionCentering > Set At </th>
            <td mat-cell *matCellDef="let element">  {{element.idAt}}
              </td>
          </ng-container>
          <ng-container matColumnDef="createDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="td-center-text panelClass" disableOptionCentering > Modified </th>
            <td mat-cell *matCellDef="let element">  {{element.date_created}}
              </td>
          </ng-container>
          <ng-container matColumnDef="nn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> NN </th>
            <td mat-cell *matCellDef="let element" class="attended-col, td-center-text">  
              <mat-select matNativeControl [(ngModel)]="element.nn" (selectionChange)="nnSelected($event, element)" [disabled]="!element.idBy">
                <mat-option *ngFor="let nns of nnKeys" [value]="nns">
                  {{ nns }} <span *ngIf="element.sourceType=='Asserted'">*</span>
                </mat-option>
              </mat-select> 
               <!-- <span *ngIf="element.nnIssue">!!</span> <span class='othernn'>/ {{ element.sourceType }}</span>-->
              </td>
          </ng-container>
       
          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> City </th>
            <td mat-cell *matCellDef="let element"> {{element.city}} </td>
          </ng-container>
          <ng-container matColumnDef="zip">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Zip </th>
            <td mat-cell *matCellDef="let element"> {{element.zip}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="colList"></tr>
          <tr mat-row *matRowDef="let row; columns: colList;"></tr>
        
          </table>
          <mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]">
          </mat-paginator>
  
</mat-card>
