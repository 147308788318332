import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { ArangoService } from 'src/services/arangoservice/arango.service';

import { Observable, of, Subscription, timer } from 'rxjs';
import { Person} from '../../../../bo9-shared/models/person/person.model';
import { Difference} from '../../../../bo9-shared/models/person/differences.model';
import { validationMessages } from 'src/helpers/form.helper';
import { contactFullName } from '../../../../bo9-shared/models/contact/contact.model';
import { Event } from '../../../../bo9-shared/models/event/event.model';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent as PageEvent } from '@angular/material/paginator';


import { ActiveCampaignService } from 'src/services/activecampaign/activecampaign.service';


import { SearchService } from 'src/services/search/search.service';

import {  ActCamFields,personFromActForm } from '../../../../bo9-shared/models/mailCampaign/activeCampaign.model';

import { EventEmitterService } from 'src/services/events/events.service';
import { ThisYear } from 'src/config/webapp.config';

@Component({
  selector: 'app-events',
  templateUrl: './activecampaign.component.html',
  styleUrls: ['./activecampaign.component.scss'],
  // make fade in animation available to this component

})
export class ActiveCampaignComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  subscriptions: Subscription[] = [];
  messagesLoading$: Observable<boolean>;
  people$: Observable<Person[]>;
  totalFound: number=0;
  totalFound$: Observable<number>;
  pageNum$: Observable<number>;
  totalPages$: Observable<number>;
  pageSize$: Observable<number>;
  pageNum: number;
  totalPages: number;
  pageSize: number=15;
  people: Person[];
  newP: Person;
  origP: Person;
  searchString: string;
  searchE: string;

  currentPane = 'center';
  valid: false;

  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;
  ActForm: FormGroup;

  public displayedColumns = ['edit','expand','actCid','first', 'last', 'email', 'naturalNumber', 'assertedNumber', 'dateIdentified','idAt', 'idBy','city','delete'];
  public dataSource = new MatTableDataSource<ActCamFields>();

  currentP$: Observable<Person>;
  currentEvent$: Observable<Event>
  currentPerson: Person;
  RelateForm: FormGroup;
  fieldValues: number;
  differences: Difference[];
  relatingPersonName: string;
  operation: string;
  offset = 0;
  loading=false;
  makeEmail= false;
  emailAddr= '';
  user: Person;
  fillType: string;
  selected: ActCamFields;
  togglec = true;
  togglen = false;
  togglem = false;
  allLists: any[] = [];
  contactList: any[] = [];

  pageEvent: PageEvent;
  maxPageSeen = 0; // force second page load

  validationMessages = validationMessages;
  events: Event[];
  constructor(
    private auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,

    private emitterService: EventEmitterService,
    private arangoService: ArangoService,
    private activeCampaignService: ActiveCampaignService,
    private searchService: SearchService,
    ) 
  {

    this.makeForm();
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'ActiveCampaignComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #014'); 
      this.router.navigate(['login'] );
    } 

   // this.webSocket.initSocket(this.user.wsSocket);
    this.subscriptions.push( this.activeCampaignService.getContactTagFieldValues(false)
    .subscribe( () => {
      this.fillType = 'all';

    }))

    const suba = this.activeCampaignService.readAllLists()
    .subscribe( (res:any) => {
      if (res.content.lists) {
        this.allLists = res.content.lists;
      }


    });
    
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }

  onCloseEmail($event) {
    this.makeEmail = false;
  }

  sendEmail($event) {
    console.log('Send Email to '+$event);
    this.emailAddr = $event.email;
    this.makeEmail = true;
  }

  onClickSearch() {
    this.offset = 0;
    this.loadContacts(this.offset, this.pageSize+1, this.searchString);
  }


  handlePage($event) {
    console.log($event);
    if (($event.previousPageIndex <  $event.pageIndex) && ( $event.pageIndex > this.maxPageSeen)) {
      this.maxPageSeen = $event.pageIndex; // Don't reload this page 
      this.offset = this.pageSize+1* $event.pageIndex;
      this.loadContacts(this.offset, this.pageSize+1);
    }

  }

  makeForm() {
    this.ActForm = this.formBuilder.group({
      email: new FormControl('', Validators.compose([Validators.required])),
      selectedAct: new FormControl('', Validators.compose([Validators.required])),
    });
   
  }

  loadContacts(offset: number, limit: number, email?: string) {
    let count=limit;
    let off = offset

    if (offset !==0) {
      off= offset+1; // first page needs to go one over to force paginatior
    } else {
      count = limit + 1;
    }
    if (this.user.preferences) {
      this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }
    this.loading=true;
    let incoming:ActCamFields[] = [];

    this.subscriptions.push( this.activeCampaignService.getContacts(off, count, this.fillType, email) 
    .subscribe(  (result) => {
        incoming = result as ActCamFields[];
        this.loading = false;
        if (offset === 0) {
          this.dataSource.data = incoming;
        } else {
          this.dataSource.data = this.dataSource.data.concat(incoming);
        }
        
      }) )
  }

  ngOnInit() {
  }

  onRefreshClick() {
    console.log('Refresh click ');
    this.fillType = 'all';
    this.togglec = true;
    this.togglen = false;
    this.togglem = false;
  }

  onToDBClick() {
    console.log('onToDB click ');
    this.fillType = 'todb';
    this.togglec = false;
    this.togglen = true;
    this.togglem = false;
  }

  onClickLoad() {
    this.offset = 0;
    this.loadContacts(this.offset, this.pageSize+1);
  }

  onMergeClick() {
    console.log('Merge click ');
    this.fillType = 'merge';
    this.togglec = false;
    this.togglen = false;
    this.togglem = true;
  }

  onPageChange(event){
    console.log(event);
  }

  onCloseClick() {
    this.currentPane = 'center';
    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.loadContacts(this.offset, this.pageSize+1);
    }
  }

  onClickSave(p:any) {
    console.log('Save Act Campaign '+ JSON.stringify(p));
    const newP =  personFromActForm(p);
    this.subscriptions.push( this.arangoService.updatePerson(newP)
    .subscribe( (result) =>{
      console.log('Save result  is: ' + JSON.stringify(result.content));
      this.emitterService.emitter.emit('Message','Contact ' + contactFullName(newP.contact) + ' saved');
      if (this.user.preferences && this.user.preferences.autoRefresh) {
        this.loadContacts(this.offset, this.pageSize+1);
      }
      
    }))
  
  }

  submitActForm() {

    console.log('Selected LIST'+JSON.stringify(this.ActForm.value.selectedAct));
    console.log('Selected EMAIL '+JSON.stringify(this.selected.email));
    console.log('Selected key '+JSON.stringify(this.selected._key));
    if (confirm('Really remove '+this.selected.email+' from list?') ){
      this.activeCampaignService.deleteFromList(this.selected._key, this.ActForm.value.selectedAct )
      .subscribe( (res=> {
        alert('List delete result '+JSON.stringify(res));
      }))
    }

  }

  onClickRemove(p:ActCamFields) {
    console.log('Remove act camp '+ JSON.stringify(p.email));
    this.contactList = [];
    this.selected = p;
    this.subscriptions.push( this.activeCampaignService.getContactLists(p._key)
    .subscribe( (res: any)=> {
      if (res.content) {

        for (const cList of res.content.contactLists) {
          for (const list of this.allLists) {
            if (cList.list === list.id) {
              this.contactList.push (list);
            }
          }
          
        }
      }
  
      this.currentPane = 'left';
    }))

  }


  onClickView(p:ActCamFields) {
    console.log('View act camp '+ JSON.stringify(p.person.primaryEmail));
    this.currentP$ = of(p.person)
    this.operation='View';
    this.currentPane = 'right';
  }

  onClickMerge(p: ActCamFields) {
    this.subscriptions.push( this.activeCampaignService.syncContact(p.person)
    .subscribe( (result) =>{
      console.log('Sync result  is: ' + JSON.stringify(result.content));
      this.emitterService.emitter.emit('Message','Contact Synced back to Act Camp. ' + contactFullName(p.person.contact));
    
    }) )
  }

  onClickDelete(p:any) {
    console.log('Delete Event '+ JSON.stringify(p._key));
    if (confirm("Are you sure you want to delete "+ p.email+' from ActiveCampaign?')) {
      this.subscriptions.push( this.activeCampaignService.deleteContact(p._key)
      .subscribe( (result) =>{
        console.log('Save result  is: ' + JSON.stringify(result.content));
        this.emitterService.emitter.emit('Message','Contact Deleted ' + contactFullName(p.person.contact));
        if (this.user.preferences && this.user.preferences.autoRefresh) {
          this.loadContacts(this.offset, this.pageSize+1);
        }
        
      
      }) )
    }
    
  }


  mergeSubmittedHandler($event: any) {
    console.log(' Person  submitted '+ JSON.stringify($event));
    this.currentPane = 'center';
    this.loadContacts(this.offset, this.pageSize+1);
  }


  
  editPerson($event: any) {
    console.log(' Attendee  submitted '+ JSON.stringify($event));
    this.currentP$ = of($event)
    this.currentPane = 'right';
  }

}
