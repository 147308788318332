import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Observable, of, Subscription } from 'rxjs';
import { Person } from '../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { contactFullName } from '../../../../bo9-shared/models/contact/contact.model';
import { Invite } from '../../../../bo9-shared/models/person/invite.model';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';

export interface Relations {
  from: string,
  to: string,
  email: string,
  Invite: string,
  pearlmodule: string,
  naturalnumber: string,
  source: string
}
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  // make fade in animation available to this component

})
export class ProductsPageComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public displayedColumns = ['from','to',  'email', 'relation', 'naturalnumber','source'];
  public dataSource = new MatTableDataSource<Relations>();
  messagesLoading$: Observable<boolean>;

  currentPane = 'center';
  valid: false;

  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;

  currentP$: Observable<Person>;
  currentPerson: Person;
  InviteForm: FormGroup;
  user: Person;
  invites: Relations[] =[];

  validationMessages = validationMessages;

  Invites$: Observable<Invite[]>;
  PearlModuleTypes$: Observable<string[]>;
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private router: Router,
    private arangoService: ArangoService

    ) 
  {
    this.currentPane = 'center';
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'ProductsPageComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #029'); 
      this.router.navigate(['login'] );
    } 
  }

  

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }

  ngOnInit() {

  }

  onPageChange(event){
    console.log(event);
  }


  onCloseClick() {
    this.currentPane = 'center';
  }



  onClickExpand(p:Person) {
    console.log('Expand Person  Contact '+ JSON.stringify(p.contact));
    this.currentP$ = of(p);

    this.currentPane = 'right';
  }

  personSubmittedHandler($event: any) {
    console.log(' Person  submitted '+ JSON.stringify($event));
    this.currentPane = 'center';
  }

  relateSubmittedHandler($event: any) {
    console.log(' Relate  submitted '+ JSON.stringify($event));
    this.currentPane = 'center';
  }

}
