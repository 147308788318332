import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription, timer } from 'rxjs';
import { Person} from '../../../../bo9-shared/models/person/person.model';
import { Difference} from '../../../../bo9-shared/models/person/differences.model';
import { validationMessages } from 'src/helpers/form.helper';
import { contactFullName } from '../../../../bo9-shared/models/contact/contact.model';
import { Event } from '../../../../bo9-shared/models/event/event.model';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';


import { ActiveCampaignService } from 'src/services/activecampaign/activecampaign.service';


import { SearchService } from 'src/services/search/search.service';

import {  ActCamFields,personFromActForm } from '../../../../bo9-shared/models/mailCampaign/activeCampaign.model';

import { EventEmitterService } from 'src/services/events/events.service';
import { formPerson, makeFormPersonFromPerson } from '../../../../bo9-shared/models/person/formPerson.model';
import { AttendanceCertificate, AttendanceData, CertificateAuthority, CertificateType, PersonCertificate } from '../../../../bo9-shared/models/certification/certification.model';
import { DateToYYMMDD, getArangoDate } from '../../../../bo9-shared/helpers/time.helper';
import { EventDBService } from 'src/services/events/eventdb.service';
import { updateMeta } from '../../../../bo9-shared/models/BO9_base/meta.model';
import { php_unserialize } from '../../../../bo9-shared/helpers/php.helper';
import { getAttendanceData } from 'src/services/events/makeEventLists.service';
import { addRegisterHours, mkMTRegisterHours } from '../../../../bo9-shared/models/institute/credits.model';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  // make fade in animation available to this component

})
export class MaintenanceComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  messagesLoading$: Observable<boolean>;
  people$: Observable<Person[]>;
  totalFound: number=0;
  totalFound$: Observable<number>;
  pageNum$: Observable<number>;
  totalPages$: Observable<number>;
  pageSize$: Observable<number>;
  pageNum: number;
  totalPages: number;
  pageSize: number=15;
  people: Person[];
  newP: Person;
  origP: Person;
  searchString: string='';
  searchE: string;

  currentPane = 'center';
  valid: false;

  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;
  ActForm: FormGroup;

  public displayedColumns = ['edit','expand','dbid','actCid','name', 'email', 'naturalNumber',  'dateIdentified','delete'];
  public dataSource = new MatTableDataSource<formPerson>();

  currentP$: Observable<Person>;
  currentEvent$: Observable<Event>
  currentPerson: Person;
  RelateForm: FormGroup;
  fieldValues: number;
  differences: Difference[];
  relatingPersonName: string;
  operation: string;
  offset = 0;
  loading=false;
  makeEmail= false;
  emailAddr= '';
  user: Person;
  fillType: string;
  selected: ActCamFields;
  togglec = true;
  togglen = false;
  togglem = false;
  allLists: any[] = [];
  contactList: any[] = [];
  incoming:formPerson[] = [];
  fileName: string;
  pageEvent: PageEvent;
  maxPageSeen = 0; // force second page load
  subscriptions: Subscription[] = [];
  validationMessages = validationMessages;
  events: Event[];
  disableBtn: boolean;
  constructor(
    private auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private eventDBService: EventDBService,
    private emitterService: EventEmitterService,
    private arangoService: ArangoService,
    private activeCampaignService: ActiveCampaignService,
    private searchService: SearchService,
    ) 
  {
    this.fileName = '';
    this.makeForm();
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'MaintenanceComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #014'); 
      this.router.navigate(['login'] );
    } 

   // this.webSocket.initSocket(this.user.wsSocket);
    this.subscriptions.push( this.activeCampaignService.getContactTagFieldValues(false)
    .subscribe( () => {
      this.fillType = 'all';

    }))

    const suba = this.activeCampaignService.readAllLists()
    .subscribe( (res:any) => {
      if (res.content.lists) {
        this.allLists = res.content.lists;
      }


    });
    
  }
/*
  onClickCopyToS3() {
    if (this.fileName !== '') {
      this.disableBtn = true;
      this.subscriptions.push( this.miscService.copyTmpToS3(this.fileName)
        .subscribe( (result) => {
          console.log("copy result"+JSON.stringify(result));
          this.disableBtn = false;
    }))
    } else {
      alert("No filename given");
    }
  }
  */

  onClickGenCert() {
    const   attendanceData: AttendanceData[]=[];
    let totalHours = mkMTRegisterHours();
    const from = new Date('2020-01-01');
    const to = new Date('2023-01-01');
    const sophiaKey = '055b47d8-8cd5-4add-bd21-69fbec163283';
    // sophia
    this.searchService.searchActivity(sophiaKey, getArangoDate(from), getArangoDate(to),'Attended')
    .subscribe(async  (result: any) => {

      const events: Event[]= [];

      if (result.content.activity && result.content.activity.length>0) {
        for( const activity of result.content.activity ) {
          if (activity && activity.activity.to) {
            const eventsWP = await firstValueFrom(this.eventDBService.getEventByID(activity.activity.to));
            if (eventsWP.content && eventsWP.content.events && eventsWP.content.events.length >0) {
              const event = (eventsWP.content.events as Event[])[0];
              if (eventsWP.content && eventsWP.content.events && eventsWP.content.events.length >0) {
                const event = (eventsWP.content.events as Event[])[0];
                if (event.event_meta) {
                  event.event_meta_object = php_unserialize(event.event_meta);
                  if (event.event_meta_object  && event.event_meta_object.event_code) {
                    event.eventCode = event.event_meta_object.event_code;
                  } else {
                    event.eventCode = '';
                  }
                }
                if ( event.eventCode) {
                  const credres:any = await firstValueFrom(this.arangoService.getCredit(event.eventCode));
                  console.log("CREDIT "+JSON.stringify(credres));
                  if (credres && credres.content && credres.content.credit && credres.content.credit.length > 0) {
                      event.registerHours = addRegisterHours(credres.content.credit[0].hours,  event.registerHours );
                  }
                }
              }
              const dbEvent  = await firstValueFrom(this.arangoService.getEventById(activity.activity.to));
              if (dbEvent && dbEvent.content && dbEvent.content.events && dbEvent.content.events.length > 0) {
                event.event_status = dbEvent.content.events[0].event_status? dbEvent.content.events[0].event_status : 'Open';
                event.recording_link = dbEvent.content.events[0].recording_link? dbEvent.content.events[0].recording_link : '';
                event.identifiers = dbEvent.content.events[0].identifiers? dbEvent.content.events[0].identifiers: ['Susan Fisher','Martin Fisher'];

              }
              events.push(event);
            }
            
          }
        }
        for( const e of events) {
          const ad =  await getAttendanceData(e, this.arangoService)
          attendanceData.push(ad);
          totalHours = addRegisterHours(totalHours, ad.hours);
        }
      
        const atCert: AttendanceCertificate = {
          dateFrom: DateToYYMMDD(from),
          dateTo: DateToYYMMDD(to),
          dateGenerated: DateToYYMMDD(),
          data: attendanceData,
          total: 0
        }
        const cert: PersonCertificate = {
          name: 'Sophia Padilla',
          email: 'martin.fisher@kinetre.com',
          from: sophiaKey,
          to: sophiaKey,
          by: sophiaKey,
          authority: CertificateAuthority.BO9,
          fromData: atCert,
          kind: CertificateType.Attendance,
          meta: updateMeta()
        }
        this.arangoService.makeCertificate(cert)
        .subscribe ({next: (result) => {
          console.log(JSON.stringify(result));
        }})
      }
    })
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }



  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }

  onCloseEmail($event) {
    this.makeEmail = false;
  }

  sendEmail($event) {
    console.log('Send Email to '+$event);
    this.emailAddr = $event.email;
    this.makeEmail = true;
  }


  async handlePage($event) {
    console.log($event);
    if (($event.previousPageIndex <  $event.pageIndex) && ( $event.pageIndex > this.maxPageSeen)) {
      this.maxPageSeen = $event.pageIndex; // Don't reload this page 
      this.incoming = [];
      while (this.incoming.length < this.pageSize) {
        this.disableBtn = true;
        const got = await this.loadContacts(this.offset, this.pageSize+1);
        this.offset += got;
      
        if (got == 0) break; // no more
      }
      this.disableBtn = false;
      this.loading = false;
    }

  }

  makeForm() {
    this.ActForm = this.formBuilder.group({
      email: new FormControl('', Validators.compose([Validators.required])),
      selectedAct: new FormControl('', Validators.compose([Validators.required])),
    });
   
  }

  loadContacts(offset: number, limit: number, email?: string): Promise<number> {
    return new Promise( resolve => {
      this.disableBtn = false;
      let count=limit;
      let off = offset
  
      if (offset !==0) {
        off= offset+1; // first page needs to go one over to force paginatior
      } else {
        count = limit + 1;
      }
      if (this.user.preferences) {
        this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
      }
      this.loading=true;

      const show: formPerson[]=[];
  
      this.subscriptions.push( this.searchService.search(this.searchString, null,null,off, limit)
      .subscribe( async (result) => {
          this.people = result.content.persons as Person[];
          if (this.people.length ==0) {
            resolve(0);
          }

          let actP;
          for (const p of this.people) {
            // Since these others include the first ignore the first
            let otherPeople: Person[] = [];
            if (this.fillType === 'email' && p.primaryEmail) {
              const others:any = await firstValueFrom(this.searchService.searchEmail(p.primaryEmail, true));
              if (others && others.length>1){ // has to be more than 1
  
                otherPeople = others;
              }
      
            } else if (this.fillType === 'name') {
              const others:any = await firstValueFrom(this.searchService.search(contactFullName(p.contact),null,null,0, 10));
              if (others && others.content && others.content.persons.length > 0){
  
                otherPeople =  others.content.persons;
              }
            }
            if (otherPeople && otherPeople.length > 1) {
              for (const op of otherPeople) {
                if (op.primaryEmail) {
                  let oactP;
                  const actContact: ActCamFields  = await firstValueFrom(this.activeCampaignService.getContactByEmail(op.primaryEmail));// Activecampign person exists
                  if (actContact) {
                    oactP = actContact;
                  }
                  const ofp = makeFormPersonFromPerson(op, oactP);
                  let dup = false;
                  for (const current of this.incoming) {
                    if (current.realPerson && ofp.realPerson && current.realPerson._key == ofp.realPerson._key) {
                      dup = true;
                    }

                  }
                  if (!dup) {
                    show.push(ofp);
                    this.incoming.push(ofp);
                  }

                }
   
              }
            }
           
           
            
          }
          
         
          if (offset === 0) {
            this.dataSource.data = show;

          } else {
            this.dataSource.data = this.dataSource.data.concat(show);

          }
          this.disableBtn = false;
          resolve(this.people.length );
    
        }) )
    })
   
  }

  ngOnInit() {
  }

  onRefreshClick() {
    console.log('Refresh click ');
    this.fillType = 'all';
    this.togglec = true;
    this.togglen = false;
    this.togglem = false;
  }

  onDupEmail() {
    console.log('onEmail click ');
    this.fillType = 'email';
    this.togglec = false;
    this.togglen = true;
    this.togglem = false;
  }

  onDupName() {
    console.log('onTName click ');
    this.fillType = 'name';
    this.togglec = false;
    this.togglen = false;
    this.togglem = true;
  }

  async onClickSearch() {
    if (this.togglem || this.togglen) {
      this.offset = 0;
      this.incoming = [];
      while (this.incoming.length < this.pageSize) {
        const got = await this.loadContacts(this.offset, this.pageSize+1);
        this.offset += got;
      
        if (got == 0) break; // no more
      }
      this.loading = false;
    } else {
      alert('No criteria selected');
    }

    console.log('loaded');
  }

  onMergeClick(p: Person) {
    console.log('Merge click ');
    this.fillType = 'merge';
    this.togglec = false;
    this.togglen = false;
    this.togglem = true;
    const mergeWith = prompt("Email to Merge with and make Primary? ");
        if (mergeWith && mergeWith==p.primaryEmail) {
          alert(`Can't Merge with Self!`);
        } else if (mergeWith) {
          this.subscriptions.push( this.searchService.searchEmail(mergeWith, true)
          .subscribe( async (result) => {
            if (result && result.length > 0) {
              console.log('merge with ' + JSON.stringify(result));
              const res = await firstValueFrom(this.arangoService.mergeByKey(p._key, result[0]._key));
            } else {
              alert(`No one found for email ${mergeWith}`);
            }
            
          }))
        } else {
          alert(`No EMail given!`);
        }
  }

  onPageChange(event){
    console.log(event);
  }

  async onCloseClick() {
    this.currentPane = 'center';
    if (this.user.preferences && this.user.preferences.autoRefresh) {
      while (this.incoming.length < this.pageSize) {
        const got = await this.loadContacts(this.offset, this.pageSize+1);
        this.offset += got;
      
        if (got == 0) break; // no more
      }
    }
  }







  onClickView(p:Person) {
    console.log('View act camp '+ JSON.stringify(p.primaryEmail));
    this.currentP$ = of(p)
    this.operation='View';
    this.currentPane = 'right';
  }


  onClickDelete(p:any) {
    console.log('Delete Event '+ JSON.stringify(p._key));
    if (confirm("Are you sure you want to delete "+ p.email+' from ActiveCampaign?')) {
      this.subscriptions.push( this.activeCampaignService.deleteContact(p._key)
      .subscribe( (result) =>{
        console.log('Save result  is: ' + JSON.stringify(result.content));
        this.emitterService.emitter.emit('Message','Contact Deleted ' + contactFullName(p.person.contact));
        if (this.user.preferences && this.user.preferences.autoRefresh) {
          this.loadContacts(this.offset, this.pageSize+1);
        }
        
      }) )
    }
    
  }

  onClickRemove(p:any) {
    console.log('Delete Event '+ JSON.stringify(p._key));
    if (confirm("Are you sure you want to delete "+ p.email+' from the DB?')) {
      this.subscriptions.push( this.arangoService.deletePerson(p._key)
      .subscribe( (result) =>{
        console.log('Save result  is: ' + JSON.stringify(result.content));
        this.emitterService.emitter.emit('Message','Contact Deleted ' + contactFullName(p.person.contact));
        if (this.user.preferences && this.user.preferences.autoRefresh) {
          this.loadContacts(this.offset, this.pageSize+1);
        }
        
      }) )
    }
    
  }


}
