<button mat-stroked-button (click)="close()">
  Cancel
</button>
<form [formGroup]="form" (ngSubmit)="submitForm()" class="relate-form">
  <div>
    <h3>Send Issues to Support - Suggestions, Errors, Problems, etc.</h3>
    <p>This page allows comments and issues to be emailed to Support at Body of 9. The subject line is automatically filled 
      with your email, and you may add to the subject line.

      The HTML block allows you to send text and images describing issues you wish to raise.

      Click 'Send' to send your email, and Cancel to abort.
    </p>
    
    <mat-form-field  class="full-width"appearance="outline" >
      <mat-label>Subject</mat-label>
      <input matInput 
        formControlName="subject"
        id="subject"
        required
      />

    </mat-form-field><br>

    <mat-form-field appearance="outline" class="full-width" >
      <mat-label>HTML</mat-label>
      <qeditor
      formControlName="html"
      placeholder="Type your html here..."
      required
    ></qeditor>

    </mat-form-field> <br>

  </div>
  <span><button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Send</button></span>

</form>
<button mat-stroked-button (click)="close()">
  Cancel
</button>