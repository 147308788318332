import { BO9Who } from "../contact/contact.model";
import { Meta, updateMeta } from "../BO9_base/meta.model";
import { DateStringType } from '../BO9_base/BO9_base.model';
import { type } from "os";

export enum NaturalNumber{
    Any = "Any",
    One = "1",
    Two = "2" ,
    Three = "3",
    Four = "4",
    Five = "5",
    Six = "6",
    Seven = "7",
    Eight = "8",
    Nine = "9",
    None = "0" ,
    OldNone = "None"
}

export enum nnSource {
    Identified = 'Identified',
    Asserted = 'Asserted',
    Invited = 'Invited'
}

export enum NaturalNumberTypes {
    SelfIdentified = "Self Identified",
    Identified = "Identified",
    Invited = "Invited",
    Asserted = "Asserted",
    UnKnown = "Unknown"
}
export interface NaturalNumberType {
    setAt: string;
    setBy: BO9Who;
    naturalNumber: NaturalNumber;
    dateSet: DateStringType;
    appVersion?: string;
    idLink?: string; // link to the tool or calendly event 
    meta?: Meta;
}

export interface NaturalNumbersType {
    identified: NaturalNumberType;
    asserted: NaturalNumberType;
    invited: NaturalNumberType;
    history: NaturalNumberType[];
}

export type NaturalNumberStatusType = string;


export function hasNaturalNumber(nn: NaturalNumber | string) : boolean {
    let natNum: NaturalNumber;
    if (typeof nn == 'string') {
         natNum = stringToNaturalNumber(nn);
    } else {
        natNum = nn;
    }

    if (natNum === NaturalNumber.None || natNum==NaturalNumber.OldNone) {
        return false;
    }


    return true;
}

export function fixIDBy(natNum: NaturalNumberType): string[] {
    
    let res: string;
    
    if (typeof natNum === 'string') {
        res = natNum;
    } else if (natNum.setBy && typeof natNum.setBy === 'string') {
        const s: string = natNum.setBy;
        res = s.replace(' and ', ',');
    } else if (natNum.setBy && typeof natNum.setBy.name === 'string') {
        const s1: string = natNum.setBy.name;
        res = s1.replace(' and ', ',');
    } else {
        res = 'Susan Fisher';
    }
    return (res.split(','));
}

export function naturalNumberToNumber(nn: NaturalNumber): number {
    if (!(nn === NaturalNumber.None || nn==NaturalNumber.OldNone || nn === NaturalNumber.Any)) {
        return +nn;
    }
    return 0;
}

export function stringToNaturalNumber (nn: any): NaturalNumber  {
    let n: NaturalNumber= NaturalNumber.None;
    if (!nn) {
        nn= "0";
    } else if (typeof nn !== 'string') {
        nn = nn.toString();
    }

    switch (nn.toLowerCase()) {
        case "0":
            n = NaturalNumber.None
            break;
        case "1":
            n = NaturalNumber.One
            break;
        case "2":
            n = NaturalNumber.Two
            break;
        case "3":
            n = NaturalNumber.Three
            break;
        case "4":
            n = NaturalNumber.Four
            break;
        case "5":
            n = NaturalNumber.Five
            break;
        case "6":
            n = NaturalNumber.Six
            break;
        case "7":
            n = NaturalNumber.Seven
            break;
        case "8":
            n = NaturalNumber.Eight
            break;
        case "9":
            n = NaturalNumber.Nine
            break;
        case "a":
            n = NaturalNumber.Any
            break;
        case "any":
            n = NaturalNumber.Any
            break;
        default:
            n=NaturalNumber.None
    }

    return n;

}

export function cleanNaturalNumber( naturalNumber:any): NaturalNumberType {

    if (naturalNumber && naturalNumber.setBy && naturalNumber.setBy.name) {
        if (naturalNumber.setBy.name.name) {
            naturalNumber.setBy.name = naturalNumber.setBy.name.name;
        }
    }
    return naturalNumber;

}

export function addNaturalNumber(naturalNumber:NaturalNumber, at: string, by: string,  dateSet: string, 
    source: string, method: string, personOrOther: string ) : NaturalNumberType {
        
    const newNN: NaturalNumberType = {
        setBy: {
            name:  by
        },
        setAt: at,
        naturalNumber: naturalNumber,
        dateSet: dateSet,
        meta: updateMeta()
    }
    return newNN
}

export function naturalNumberChanged( original: NaturalNumberType, updated: NaturalNumberType) : boolean {

    if (original.setBy && original.setBy.name != "" && updated.setBy.name != "" && original.setBy.name !== updated.setBy.name) {
        return true;
    }
    if (original.setAt !== updated.setAt) {
        return true;
    }
    if (original.dateSet !== updated.dateSet) {
        return true;
    }

    if (original.naturalNumber !== updated.naturalNumber) {
        return true;
    }
    return false;
}


export function setNaturalNumber(original: NaturalNumbersType, additional: NaturalNumberType, source: nnSource): NaturalNumbersType {
    let newNN: NaturalNumbersType = original;

    if (!newNN.history) {
        newNN.history = [];
    }
    switch (source) {
        case nnSource.Asserted:
            newNN.history.push(newNN.asserted);
            newNN.asserted = additional;
            break;
        case nnSource.Identified:
            newNN.history.push(newNN.identified);
            newNN.identified = additional;
            break;
        case nnSource.Invited:
            newNN.history.push(newNN.invited);
            newNN.invited = additional;
            break;
        default:
            newNN.asserted = makeMTNaturalNumber();
            newNN.invited = makeMTNaturalNumber();
            newNN.identified = makeMTNaturalNumber();
    }

    

    return(newNN);
}

export function makeMTNaturalNumbers(): NaturalNumbersType {
    return({
        identified: makeMTNaturalNumber(),
        asserted: makeMTNaturalNumber(),
        invited:makeMTNaturalNumber(),
        history: [],

    });
}

export function makeMTNaturalNumber(): NaturalNumberType {
    const newNN: NaturalNumberType = {
        setAt: '',
        setBy: {
            name: '',
            uuid: '',
            comment: ''
        },
        naturalNumber: NaturalNumber.None,
        dateSet:''
    }
    return (newNN);

}

// Given the possible NN, return what shoudl be the most accurate
export function mostAccurateNaturalNumber(nns: NaturalNumbersType): NaturalNumberType {
   // console.log('NNS ' + JSON.stringify(nns));
    if (nns) {
        if (nns.identified && nns.identified.naturalNumber != NaturalNumber.None && 
            nns.identified.naturalNumber != NaturalNumber.Any)
            return(nns.identified);
        if (nns.asserted && nns.asserted.naturalNumber != NaturalNumber.None && 
            nns.asserted.naturalNumber != NaturalNumber.Any)
            return(nns.asserted);
        if (nns.invited && nns.invited.naturalNumber && nns.invited.naturalNumber != NaturalNumber.None && 
            nns.invited.naturalNumber != NaturalNumber.Any) {
                return(nns.invited);
            }

    }
  
    return(makeMTNaturalNumber()); // None

}

export function mergeNaturalNumbers(original: NaturalNumbersType, additional: NaturalNumbersType): NaturalNumbersType {
    var _ = require('lodash');
    let newNN: NaturalNumbersType = original;
    let changed = false;
    if (!newNN.history) {
        newNN.history = [];
    }

    if (hasNaturalNumber(additional.asserted.naturalNumber) && additional.asserted.naturalNumber != original.asserted.naturalNumber) {
        newNN.history.push(original.asserted);
        newNN.asserted = additional.asserted;
        changed=true;
    }
    if (hasNaturalNumber(additional.invited.naturalNumber) && additional.invited.naturalNumber != original.invited.naturalNumber) {
        newNN.history.push(original.invited);
        newNN.invited = additional.invited;
        changed=true;
    }
    if (hasNaturalNumber(additional.identified.naturalNumber) && additional.identified.naturalNumber != original.identified.naturalNumber) {
        newNN.history.push(original.identified);
        newNN.identified = additional.identified;
        changed=true;

    }
    if (changed && additional.history) {
        newNN.history.concat(additional.history);
    }

    const tempp =  newNN.history;
   
    newNN.history = _.unionWith(tempp,_.isEqual); // Should be uniques
    
    return(newNN);
}