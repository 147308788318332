<my-slide-panel [activePane]="currentPearlPane" style='overflow:hidden;'>
	<section centerPane>
		<h2>Daily Pearls</h2>
		<mat-progress-spinner *ngIf="loading" mode="indeterminate"> </mat-progress-spinner>
		<div *ngIf="searchable">

			<form [formGroup]="SearchForm" (ngSubmit)="submitSearchForm()" class="Pearl-form" novalidate >
				<mat-form-field appearance="outline" >
					<mat-label>Search</mat-label>
					<input matInput  formControlName="content" placeholder="Search text"/>
				</mat-form-field>

				<mat-form-field appearance="outline" >
					<mat-label>My Natural Number?</mat-label>
					<mat-select matNativeControl   formControlName="myNN" >
						<mat-option *ngFor="let nnI of nnKeys" [value]="nnI">{{ nnI }}</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="outline" >
					<mat-label>Their Natural Number?</mat-label>
					<mat-select matNativeControl   formControlName="theirNN">
						<mat-option *ngFor="let nnI of nnKeys" [value]="nnI">{{ nnI }}</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>Relationship</mat-label>
					<mat-select matNativeControl   formControlName="relationship" multiple>
						<mat-option *ngFor="let reltype of invites " [value]="reltype">{{ reltype}}</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="outline" >
					<mat-label>Module</mat-label>
					<mat-select matNativeControl  formControlName="pearlmodule"  multiple>
						<mat-option *ngFor="let reltype of PearlModuleTypes" [value]="reltype">{{ reltype }}</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field  appearance="outline" >
					<mat-label>Start Date</mat-label>
					<input matInput
						formControlName="startDate"
						id="startDate"
						type="date"
						[matDatepicker]="startDate"
					/>
					<mat-datepicker #startDate></mat-datepicker>
			  </mat-form-field>

				<mat-form-field  appearance="outline" >
					<mat-label>End Date</mat-label>
					<input matInput
						formControlName="endDate"
						id="endDate"
						type="date"
						[matDatepicker]="endDate"
					/>
					<mat-datepicker #endDate></mat-datepicker>
				</mat-form-field>
				<mat-label> All Pearls?</mat-label>
				<mat-checkbox (change)="clickAll()" [checked]="allPearls"></mat-checkbox>
				<mat-label>Only Approved Pearls? </mat-label>
				<mat-checkbox (change)="clickApproved()" [checked]="onlyApproved"></mat-checkbox>
				<mat-label> Only Not Approved Pearls?</mat-label>
				<mat-checkbox (change)="clickNotApproved()" [checked]="onlyNotApproved"></mat-checkbox> <br />
				<button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Search</button>
			</form>
		</div>
		<table mat-table [dataSource]="dataSource" matSort>

			<ng-container matColumnDef="edit">
				<th mat-header-cell *matHeaderCellDef> Edit </th>
				<td mat-cell *matCellDef="let element" class="td-center-text">
					<button type="button" (click)="onClickEdit(element)"> <mat-icon>edit</mat-icon></button>
				</td>
			</ng-container>

			<ng-container matColumnDef="title">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Title / Content </th>
				<td mat-cell *matCellDef="let element"> {{element.title}} <div [innerHTML]="element.content"></div> </td>
			</ng-container>

			<ng-container matColumnDef="myNN">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> My Nat. Num </th>
				<td mat-cell *matCellDef="let element" class="td-center-text">
					<mat-select matNativeControl [(ngModel)]="element.myNN" (selectionChange)="myNNSelected($event, element)" >
						<mat-option *ngFor="let nns of nnKeys" [value]="nns">{{ nns }}</mat-option>
					</mat-select>
				</td>
			</ng-container>

			<ng-container matColumnDef="theirNN">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Their Nat. Num </th>
				<td mat-cell *matCellDef="let element" class="td-center-text">
					<mat-select matNativeControl [(ngModel)]="element.theirNN" (selectionChange)="theirNNSelected($event, element)" >
						<mat-option *ngFor="let nns of nnKeys" [value]="nns">{{ nns }}</mat-option>
					</mat-select>
				</td>
			</ng-container>

			<ng-container matColumnDef="relationship">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Relationship </th>
				<td mat-cell *matCellDef="let element"class="td-center-text">
					<mat-select matNativeControl   [(ngModel)]="element.relationship"  (selectionChange)="relationshipSelected($event, element)" multiple>
						 <mat-option *ngFor="let reltype of invites " [value]="reltype">{{ reltype}}</mat-option>
					 </mat-select>
				 </td>
			</ng-container>

			<ng-container matColumnDef="pearlmodule">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Module </th>
				<td mat-cell *matCellDef="let element" class="td-center-text">
					<mat-select matNativeControl [(ngModel)]="element.pearlModule"  (selectionChange)="pearlModuleSelected($event, element)"  multiple>
						<mat-option *ngFor="let reltype of PearlModuleTypes" [value]="reltype">{{ reltype }}</mat-option>
					</mat-select>
				</td>
			</ng-container>

			<ng-container matColumnDef="approved">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Approved </th>
				<td mat-cell *matCellDef="let element" class="td-center-text">
					<mat-checkbox (change)="selectApproved(element)" [checked]="element.moderated?.approved"></mat-checkbox>
				</td>
			</ng-container>

			<ng-container matColumnDef="timeStamp">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Time Stamp </th>
				<td mat-cell *matCellDef="let element"> {{element.timeStamp}} </td>
			</ng-container>

			<ng-container matColumnDef="delete">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Delete </th>
				<td mat-cell *matCellDef="let element" class="td-center-text">
					<button type="button" (click)="onClickDelete(element)"> <mat-icon>delete</mat-icon></button>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>

		</table>
		<mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]"></mat-paginator>
	</section>
</my-slide-panel>
