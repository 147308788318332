import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { GenderTypes, getGender, mergePerson, Person, personFromPersonForm } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { canContact, contactFullName } from '../../../../../bo9-shared/models/contact/contact.model';
import { Invite } from '../../../../../bo9-shared/models/person/invite.model';
import { NaturalNumber } from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';
import { EventEmitterService } from 'src/services/events/events.service';
import { Activity } from '../../../../../bo9-shared/models/activity/activity.model';
import { createUUID, responseEncode } from '../../../../../bo9-shared/uuid.service';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import { DateToYYMMDDhhmmss } from '../../../../../bo9-shared/helpers/time.helper';
import { findInvalidControlsRecursive } from '../../../helpers/form.helper';
import { ActivityService } from 'src/services/activity/activity.service';
import { ActiveCampaignService } from 'src/services/activecampaign/activecampaign.service';
import { MatTableDataSource} from '@angular/material/table';
import { ActiveCampaignDeal } from '../../../../../bo9-shared/models/mailCampaign/activeCampaign.model';
import { userIsSuperAdmin } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { EmailContactItem } from '../../../../../bo9-shared/models/contact/email.model';
import { EmailService } from 'src/services/email/email.service';
import { Router } from '@angular/router';


@Component({
  selector: 'person-form',
  templateUrl: './personForm.component.html',
  styleUrls: ['./personForm.component.scss'],
  // make fade in animation available to this component

})
export class PersonFormComponent implements OnChanges, OnInit {

  @Input() person: Person;
  @Input() actCamp: any;
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
  messagesLoading$: Observable<boolean>;

  public displayedColumns = ['group','stage', 'title', 'description', 'modified'];
  public dataSource = new MatTableDataSource<ActiveCampaignDeal>();

  valid: false;
  files: any ;
  disableBtn = false;

  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  PersonForm: FormGroup;

  relatingPersonName: string;
  referrals: string[] =[];
  subscriptions: Subscription[] = [];
  validationMessages = validationMessages;
  user: Person;
  Invites$: Observable<Invite[]>;
  PearlModuleTypes$: Observable<string[]>;
  nnKeys;
  fileControl: FormControl;
  nnSource;
  image: string;
  key: string;
    
  genderTypes = GenderTypes;
  constructor(
    private formBuilder: FormBuilder,
    private activityService: ActivityService,
    private activeCampaignService: ActiveCampaignService,
    private arangoService: ArangoService,
    private emailService: EmailService,
    private auth: AuthService,
    private emitterService: EventEmitterService,
    private router: Router,
    ) 
  {
    this.nnKeys = Object.keys(NaturalNumber).map(key => NaturalNumber[key]).filter(value => typeof value === 'string') as string[];
    this.makeForm();
    this.user = this.auth.getAuthUser();

    this.subscriptions.forEach(s => s.unsubscribe());

    this.subscriptions.push( this.arangoService.recordVisit({
      page: this.router.url, component: 'PersonFormComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}}))

  }

  get bioControl() { return this.PersonForm.controls.bio; }
  get genderControl() { return this.PersonForm.controls.gender; }
  get notesControl() { return this.PersonForm.controls.notes; }
  get sourceControl() { return this.PersonForm.controls.source; }
  get personorOtherControl() { return this.PersonForm.controls.personorOther; }

  makeForm() {
    this.PersonForm = this.formBuilder.group({
      actContactId: new FormControl('', Validators.compose([])),
      key: new FormControl('', Validators.compose([])),
      userUID: new FormControl('', Validators.compose([])),
      contact: [],
      natnum: [],
      bio: new FormControl('', Validators.compose([])),
      notes: new FormControl('', Validators.compose([])),
      source: new FormControl('', Validators.compose([])),
      personOrOther: new FormControl('', Validators.compose([])),
      gender: new FormControl('', Validators.compose([])),
      birthOrder: new FormControl('', Validators.compose([])),
      websiteUrl:  new FormControl('', Validators.compose([]))
    });

  }

  delImage() {
    this.files =[];
    this.image = null;
    if (this.person) {
      this.person.imageURL = null;
      this.person.fileInfo = null;
    }
  }

  submitPersonForm() {
    if (this.PersonForm.valid) {
      this.disableBtn=true;
      const emailToValidate: EmailContactItem[]=[];
      const newP = personFromPersonForm(this.PersonForm.value);
       //const mergedP = mergePerson(this.person, newP, this.user._key);
      console.log('new person '+ JSON.stringify(newP));
      if (newP.contact.email.items && newP.contact.email.items.length > 0) { // check to see it there are new unvalidated emails 
        for (let i=0; i < newP.contact.email.items.length; i++) {
          if (newP.contact.email.items[i].toValidate && !newP.contact.email.items[i].confirmed) { // Hasn't been sent to validate yet
            newP.contact.email.items[i].confirmed = responseEncode(this.person._key,"C");
            newP.contact.email.items[i].unconfirmed= responseEncode(this.person._key,"U");
            emailToValidate.push(newP.contact.email.items[i]);
          }
        }
      }
      
      this.subscriptions.push( this.arangoService.updatePerson(newP,false)
      .subscribe ( async (result) => {
        if (this.files && this.files.length > 0) {
          const active = await  firstValueFrom(this.arangoService.updatePersonsImage( newP, this.files[0]))
        }
        const act: Activity = {
          _key :createUUID(),
          from : this.person? this.person._key: newP._key,
          to : newP._key,
          kind : 'Admin updated data',
          note : 'User data changed...',
          date : DateToYYMMDDhhmmss(),
          by : contactFullName(newP.contact),
          meta : updateMeta()
        }

        const active = await  firstValueFrom(this.activityService.addActivity( act, 'person'))
        if (canContact(newP.contact)) {
          const actc = await firstValueFrom(this.activeCampaignService.syncContact(newP));
        }

        for (const email of emailToValidate) {
          const res = await firstValueFrom(this.emailService.validateEMail(email, newP));
        }
        console.log('Save result  is: ' + JSON.stringify(result.content));
        this.emitterService.emitter.emit('Message','Person ' + contactFullName(this.PersonForm.value.contact) + ' saved');
        
        this.notifyParent.emit('person Saved');
        this.PersonForm.reset();
        this.loadPerson();
        this.disableBtn = false;
      }))
    } else {
      alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.PersonForm)));
    }
    //this.PersonForm.reset();
  }

  isSuperAdmin() {
    return userIsSuperAdmin(this.user);
  }


  onPageChange(event){
    console.log(event);
  }

  ngOnInit() {
    this.fileControl = new  FormControl('',[]);
    this.fileControl.valueChanges.subscribe((files: any) => {
      console.log('File ' + JSON.stringify(files.name))
      if (!Array.isArray(files)) {
        this.files= [files];
      } else {
        this.files = files;
      }
    })
    this.subscriptions.push( this.arangoService.getReferrals()
    .subscribe( (res) => {
      console.log('referrals back '+ JSON.stringify(res.content));
      if (res.content) {
        for (const r of res.content) {
          this.referrals.push(r.Name);
        }
      }
      
    }))
  }

  loadPerson() {
    if (this.person) {
      const p: Person = this.person;
      if (this.actCamp && this.actCamp.deals && this.actCamp.deals.length > 0) {
        this.dataSource.data = this.actCamp.deals;
      } else {
        this.dataSource.data = [];
      }
      if (this.person && this.person.imageURL) {
        this.image = this.person.imageURL;
      } else {
        this.image = null;
        this.files = [];
      }
      
      console.log('Expand Person '+ JSON.stringify(p));
      p._key = this.person._key;
      this.key = p._key;
      const oldcontact: any = p.contact;
    // this.contact = semiContact;
      this.PersonForm.patchValue({
        key: p._key,
        primaryEmail: p.primaryEmail,
        contact: p.contact,
        bio: p.bio,
        notes: p.notes,
        natnum: p.naturalNumbers,
        source: p.source,
        gender: p.details? getGender(p.details.gender): getGender(oldcontact.gender? oldcontact.gender: ''),
        personOrOther: p.personOrOtherReferredBy,
        birthOrder: p.details? (p.details.momBirthOrder? p.details.momBirthOrder: 0) : 0,
        websiteUrl:  p.details? p.details.websiteUrl: '',
      });
    }

  }
  ngOnChanges() {
    this.PersonForm.reset();
    this.loadPerson();

  }

}
