import { BO9UUID } from '../UUID.model';
import { DateTimeStringType } from '../BO9_base/BO9_base.model';
import { DateToYYMMDDhhmmss } from '../../helpers/time.helper';


export interface Message {
    messageId: BO9UUID;
    created: DateTimeStringType;
    modified: DateTimeStringType;
    chatRoomId: BO9UUID;
    message: string;
    userId: BO9UUID;
   // seenMessages: ChatSeenEvent[];

}

function mkEmptyMessage(): Message {
    return ( {
        messageId: '',
        created: DateToYYMMDDhhmmss(),
        modified: DateToYYMMDDhhmmss(),
        chatRoomId: '',
        message: '',
        userId: '',
       // seenMessages: []
    })
  }

// SInce uuid are unique, return when the uuid fieldmatches
export function getMessageByUUID(uuid: BO9UUID, items: Message[]): Message {
    for (let i of items) {
        if ( i.messageId === uuid ) {
                return(i)
        }   
    }
    return mkEmptyMessage();
}

export function resultOK( result: any): boolean{
    if (result && result.content && result.content.length>0) {
        return true;
    }
    return false;
}

// SInce uuid are unique, return when any uuid fieldmatches
/*
export function getMessagesBySeenUUID(uuid: any, items: Message[]): Message[] {
    const result: Message[] = [];
    for (let i of items) {
        if (i.messageId === uuid ||
            i.chatRoomId === uuid) {
                result.push(i);
            }
        for (let s of i.seenMessages) {
            if (s.personWhoReadId === uuid) {
                    result.push(i);
            }
        }
    }
    console.log('getChatSeen  ' + JSON.stringify(result));
    return result;
}

*/
