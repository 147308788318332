
import { createUUID } from '../../uuid.service';
import { Meta, updateMeta } from "../BO9_base/meta.model";
import { BO9UUID, ArangoKeyType, ArangoRevType } from "../arango/arango.model";
import { DateStringType } from '../BO9_base/BO9_base.model';
import { DateToYYMMDDhhmmss } from '../../helpers/time.helper';

export type BO9AddressId = string;

export interface ISOAddress {
    name: string;
    ISO: string;
}

export interface BO9AddressType {
    updatedAt: DateStringType;
    items: BO9Address[];
}

export interface BO9Address {
    _key: BO9UUID;
    name?: string;
    altitude?: number;
    latitude?: number;
    longitude?: number;
    street1?: string;
    street2?: string;
    city: string;
    state: string;
    zip: string;
    timezone?: string;
    country: string;
    meta?: Meta;
}
export function addShortAddress( Address: BO9AddressType | null, city: string, state: string, zip: string, country: string ): BO9AddressType {
    const newItem: BO9Address = {
        _key: createUUID(),
        city: city? city: '',
        state: state? state: '',
        zip: zip? zip: '',
        country:  country? country: '',
        meta: updateMeta()
    }
    let newIA: BO9Address[];
    if (Address && Address.items.length > 0) {
        newIA = Address.items;
        newIA.push(newItem);
    } else {
        newIA = [newItem]
    }
    const newAddress: BO9AddressType = {
        updatedAt: DateToYYMMDDhhmmss(),

        items: newIA
    }
    return newAddress
}

export function addressToString(adr: BO9Address): string {
    let result= "";

    if (adr.name) {
        result = result + adr.name
    }
    if (adr.street1) {
        if (result !== '') {
            result = result + ', ';
        }
        result = result + adr.street1
    }
    if (adr.street2) {
        if (result !== '') {
            result = result + ', ';
        }
        result = result + adr.street2
    }
    if (adr.city) {
        if (result !== '') {
            result = result + ', ';
        }
        result = result + adr.city
    }
    if (adr.state) {
        if (result !== '') {
            result = result + ', ';
        }
        result = result + adr.state
    }
    if (adr.zip) {
        if (result !== '') {
            result = result + ', ';
        }
        result = result + adr.zip
    }


    return result;
}

export function mkMTAddress(): BO9Address {
    return( {
        _key: createUUID(),
        name: '',
        altitude: 0,
        latitude: 0,
        longitude: 0,
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        timezone: 'UTC',
        country:  ''
    });
}
// TODO - make map maybe?
// SInce uuid are unique, return when any uuid fieldmatches
export function getAddressesByUUID(uuid: any, items: any[]): BO9Address[] {
    //console.log('Get DDR UUID ' + uuid + ' from ' + JSON.stringify(items));
    const result: BO9Address[] = [];
    for (let i of items) {
        if ( i.uuid === uuid ||
            i.authorId === uuid) {
                result.push(i);
            }
        
    }
    console.log('getaddr result '  + JSON.stringify(result));
    return result;
}

export function hideExistenceInAddress(a: BO9Address) : BO9Address {

    return a;
}

export function cleanISO(inVar: any): string {

    if (inVar && (inVar.ISO || inVar.ISO=='')) {
        return inVar.name
    } else {
        return inVar;
    }

}

export function existingAddress(addr: BO9AddressType, parts: BO9Address| any) {
    let result: boolean = false;
    let part: BO9Address;

    //console.log('-----------existing addr '  + JSON.stringify(addr));
   // console.log('-----------parts '  + JSON.stringify(parts));

    if (parts.items && parts.items.length > 0) {
        part = parts.items[0]
    } else {
        part = parts;
    }
    if ( !part ||  !(part.city && part.state && part.zip )) { // All blank don't add so fake it already exists
        return true;
    }
    for (let e of addr.items ) {

        if (part.city && (part.city == e.city) && part.state && (part.state == e.state) && part.zip && (part.zip == e.zip)) {
            // if streets null, ignore, if street1 not null check, if street2 not null check
            if ((part.street1 == null && part.street2 == null) ||
               (part.street1 == e.street1 && (part.street2 == null || part.street2 == e.street2)) )
            return(true);
        }
    }
    return(false);
}

export function mergeAddress(original: BO9AddressType, additional: BO9AddressType): BO9AddressType {
    let newP: BO9AddressType = {
        updatedAt: DateToYYMMDDhhmmss(new Date),
        items: []

    }
    for (const orig of original.items) {
        if (orig) {
            newP.items.push(orig);
        }
    }
    for (let e=0; e< additional.items.length; e++) { // check each to see if it needs to be added
        if (!existingAddress(newP, additional.items[e])) {
            const newAddress: BO9Address = {
                _key: additional.items[e]._key? additional.items[e]._key : createUUID(),
                name: additional.items[e].name? additional.items[e].name : '',
                altitude: additional.items[e].altitude? additional.items[e].altitude : 0,
                latitude: additional.items[e].latitude? additional.items[e].latitude : 0,
                longitude: additional.items[e].longitude? additional.items[e].longitude : 0,
                street1: additional.items[e].street1? additional.items[e].street1 : '',
                street2: additional.items[e].street2? additional.items[e].street2 : '',
                city: additional.items[e].city? additional.items[e].city : '',
                state: additional.items[e].state? additional.items[e].state :'',
                zip: additional.items[e].zip? additional.items[e].zip : '',
                timezone: additional.items[e].name? additional.items[e].timezone : '',
                country: additional.items[e].country? additional.items[e].country : ''
    
            }
            newP.items.push(newAddress); // onto end
        }
    }
    return(newP);
}