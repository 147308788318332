const abbrTZ: {abbr: string, tz:string}[] = [
    {abbr: "MDT",tz:"US Mountain Standard Time"},
    {abbr: "MST",tz:"US Mountain Standard Time"},
    {abbr: "PDT",tz:"Pacific Standard Time"},
    {abbr: "PST",tz:"Pacific Standard Time"},
]


const mapTZ: {other:string, territory:string, type:string}[] = [

			/** (UTC-12:00) International Date Line West **/
			{other:"Dateline Standard Time" ,territory:"001" ,type:"Etc/GMT+12"},
			{other:"Dateline Standard Time" ,territory:"ZZ" ,type:"Etc/GMT+12"},

			/** (UTC-11:00) Coordinated Universal Time-11 **/
			{other:"UTC-11" ,territory:"001" ,type:"Etc/GMT+11"},
			{other:"UTC-11" ,territory:"AS" ,type:"Pacific/Pago_Pago"},
			{other:"UTC-11" ,territory:"NU" ,type:"Pacific/Niue"},
			{other:"UTC-11" ,territory:"UM" ,type:"Pacific/Midway"},
			{other:"UTC-11" ,territory:"ZZ" ,type:"Etc/GMT+11"},

			/** (UTC-10:00) Aleutian Islands **/
			{other:"Aleutian Standard Time" ,territory:"001" ,type:"America/Adak"},
			{other:"Aleutian Standard Time" ,territory:"US" ,type:"America/Adak"},

			/** (UTC-10:00) Hawaii **/
			{other:"Hawaiian Standard Time" ,territory:"001" ,type:"Pacific/Honolulu"},
			{other:"Hawaiian Standard Time" ,territory:"CK" ,type:"Pacific/Rarotonga"},
			{other:"Hawaiian Standard Time" ,territory:"PF" ,type:"Pacific/Tahiti"},
			{other:"Hawaiian Standard Time" ,territory:"UM" ,type:"Pacific/Johnston"},
			{other:"Hawaiian Standard Time" ,territory:"US" ,type:"Pacific/Honolulu"},
			{other:"Hawaiian Standard Time" ,territory:"ZZ" ,type:"Etc/GMT+10"},

			/** (UTC-09:30) Marquesas Islands **/
			{other:"Marquesas Standard Time" ,territory:"001" ,type:"Pacific/Marquesas"},
			{other:"Marquesas Standard Time" ,territory:"PF" ,type:"Pacific/Marquesas"},

			/** (UTC-09:00) Alaska **/
			{other:"Alaskan Standard Time" ,territory:"001" ,type:"America/Anchorage"},
			{other:"Alaskan Standard Time" ,territory:"US" ,type:"America/Anchorage America/Juneau America/Metlakatla America/Nome America/Sitka America/Yakutat"},

			/** (UTC-09:00) Coordinated Universal Time-09 **/
			{other:"UTC-09" ,territory:"001" ,type:"Etc/GMT+9"},
			{other:"UTC-09" ,territory:"PF" ,type:"Pacific/Gambier"},
			{other:"UTC-09" ,territory:"ZZ" ,type:"Etc/GMT+9"},

			/** (UTC-08:00) Baja California **/
			{other:"Pacific Standard Time (Mexico)" ,territory:"001" ,type:"America/Tijuana"},
			{other:"Pacific Standard Time (Mexico)" ,territory:"MX" ,type:"America/Tijuana America/Santa_Isabel"},

			/** (UTC-08:00) Coordinated Universal Time-08 **/
			{other:"UTC-08" ,territory:"001" ,type:"Etc/GMT+8"},
			{other:"UTC-08" ,territory:"PN" ,type:"Pacific/Pitcairn"},
			{other:"UTC-08" ,territory:"ZZ" ,type:"Etc/GMT+8"},

			/** (UTC-08:00) Pacific Time (US & Canada) **/
			{other:"Pacific Standard Time" ,territory:"001" ,type:"America/Los_Angeles"},
			{other:"Pacific Standard Time" ,territory:"CA" ,type:"America/Vancouver"},
			{other:"Pacific Standard Time" ,territory:"US" ,type:"America/Los_Angeles"},
			{other:"Pacific Standard Time" ,territory:"ZZ" ,type:"PST8PDT"},

			/** (UTC-07:00) Arizona **/
			{other:"US Mountain Standard Time" ,territory:"001" ,type:"America/Phoenix"},
			{other:"US Mountain Standard Time" ,territory:"CA" ,type:"America/Creston America/Dawson_Creek America/Fort_Nelson"},
			{other:"US Mountain Standard Time" ,territory:"MX" ,type:"America/Hermosillo"},
			{other:"US Mountain Standard Time" ,territory:"US" ,type:"America/Phoenix"},
			{other:"US Mountain Standard Time" ,territory:"ZZ" ,type:"Etc/GMT+7"},

			/** (UTC-07:00) Chihuahua, La Paz, Mazatlan **/
			{other:"Mountain Standard Time (Mexico)" ,territory:"001" ,type:"America/Chihuahua"},
			{other:"Mountain Standard Time (Mexico)" ,territory:"MX" ,type:"America/Chihuahua America/Mazatlan"},

			/** (UTC-07:00) Mountain Time (US & Canada) **/
			{other:"Mountain Standard Time" ,territory:"001" ,type:"America/Denver"},
			{other:"Mountain Standard Time" ,territory:"CA" ,type:"America/Edmonton America/Cambridge_Bay America/Inuvik America/Yellowknife"},
			{other:"Mountain Standard Time" ,territory:"MX" ,type:"America/Ojinaga"},
			{other:"Mountain Standard Time" ,territory:"US" ,type:"America/Denver America/Boise"},
			{other:"Mountain Standard Time" ,territory:"ZZ" ,type:"MST7MDT"},

			/** (UTC-07:00) Yukon **/
			{other:"Yukon Standard Time" ,territory:"001" ,type:"America/Whitehorse"},
			{other:"Yukon Standard Time" ,territory:"CA" ,type:"America/Whitehorse America/Dawson"},

			/** (UTC-06:00) Central America **/
			{other:"Central America Standard Time" ,territory:"001" ,type:"America/Guatemala"},
			{other:"Central America Standard Time" ,territory:"BZ" ,type:"America/Belize"},
			{other:"Central America Standard Time" ,territory:"CR" ,type:"America/Costa_Rica"},
			{other:"Central America Standard Time" ,territory:"EC" ,type:"Pacific/Galapagos"},
			{other:"Central America Standard Time" ,territory:"GT" ,type:"America/Guatemala"},
			{other:"Central America Standard Time" ,territory:"HN" ,type:"America/Tegucigalpa"},
			{other:"Central America Standard Time" ,territory:"NI" ,type:"America/Managua"},
			{other:"Central America Standard Time" ,territory:"SV" ,type:"America/El_Salvador"},
			{other:"Central America Standard Time" ,territory:"ZZ" ,type:"Etc/GMT+6"},

			/** (UTC-06:00) Central Time (US & Canada) **/
			{other:"Central Standard Time" ,territory:"001" ,type:"America/Chicago"},
			{other:"Central Standard Time" ,territory:"CA" ,type:"America/Winnipeg America/Rainy_River America/Rankin_Inlet America/Resolute"},
			{other:"Central Standard Time" ,territory:"MX" ,type:"America/Matamoros"},
			{other:"Central Standard Time" ,territory:"US" ,type:"America/Chicago America/Indiana/Knox America/Indiana/Tell_City America/Menominee America/North_Dakota/Beulah America/North_Dakota/Center America/North_Dakota/New_Salem"},
			{other:"Central Standard Time" ,territory:"ZZ" ,type:"CST6CDT"},

			/** (UTC-06:00) Easter Island **/
			{other:"Easter Island Standard Time" ,territory:"001" ,type:"Pacific/Easter"},
			{other:"Easter Island Standard Time" ,territory:"CL" ,type:"Pacific/Easter"},

			/** (UTC-06:00) Guadalajara, Mexico City, Monterrey **/
			{other:"Central Standard Time (Mexico)" ,territory:"001" ,type:"America/Mexico_City"},
			{other:"Central Standard Time (Mexico)" ,territory:"MX" ,type:"America/Mexico_City America/Bahia_Banderas America/Merida America/Monterrey"},

			/** (UTC-06:00) Saskatchewan **/
			{other:"Canada Central Standard Time" ,territory:"001" ,type:"America/Regina"},
			{other:"Canada Central Standard Time" ,territory:"CA" ,type:"America/Regina America/Swift_Current"},

			/** (UTC-05:00) Bogota, Lima, Quito, Rio Branco **/
			{other:"SA Pacific Standard Time" ,territory:"001" ,type:"America/Bogota"},
			{other:"SA Pacific Standard Time" ,territory:"BR" ,type:"America/Rio_Branco America/Eirunepe"},
			{other:"SA Pacific Standard Time" ,territory:"CA" ,type:"America/Coral_Harbour"},
			{other:"SA Pacific Standard Time" ,territory:"CO" ,type:"America/Bogota"},
			{other:"SA Pacific Standard Time" ,territory:"EC" ,type:"America/Guayaquil"},
			{other:"SA Pacific Standard Time" ,territory:"JM" ,type:"America/Jamaica"},
			{other:"SA Pacific Standard Time" ,territory:"KY" ,type:"America/Cayman"},
			{other:"SA Pacific Standard Time" ,territory:"PA" ,type:"America/Panama"},
			{other:"SA Pacific Standard Time" ,territory:"PE" ,type:"America/Lima"},
			{other:"SA Pacific Standard Time" ,territory:"ZZ" ,type:"Etc/GMT+5"},

			/** (UTC-05:00) Chetumal **/
			{other:"Eastern Standard Time (Mexico)" ,territory:"001" ,type:"America/Cancun"},
			{other:"Eastern Standard Time (Mexico)" ,territory:"MX" ,type:"America/Cancun"},

			/** (UTC-05:00) Eastern Time (US & Canada) **/
			{other:"Eastern Standard Time" ,territory:"001" ,type:"America/New_York"},
			{other:"Eastern Standard Time" ,territory:"BS" ,type:"America/Nassau"},
			{other:"Eastern Standard Time" ,territory:"CA" ,type:"America/Toronto America/Iqaluit America/Montreal America/Nipigon America/Pangnirtung America/Thunder_Bay"},
			{other:"Eastern Standard Time" ,territory:"US" ,type:"America/New_York America/Detroit America/Indiana/Petersburg America/Indiana/Vincennes America/Indiana/Winamac America/Kentucky/Monticello America/Louisville"},
			{other:"Eastern Standard Time" ,territory:"ZZ" ,type:"EST5EDT"},

			/** (UTC-05:00) Haiti **/
			{other:"Haiti Standard Time" ,territory:"001" ,type:"America/Port-au-Prince"},
			{other:"Haiti Standard Time" ,territory:"HT" ,type:"America/Port-au-Prince"},

			/** (UTC-05:00) Havana **/
			{other:"Cuba Standard Time" ,territory:"001" ,type:"America/Havana"},
			{other:"Cuba Standard Time" ,territory:"CU" ,type:"America/Havana"},

			/** (UTC-05:00) Indiana (East) **/
			{other:"US Eastern Standard Time" ,territory:"001" ,type:"America/Indianapolis"},
			{other:"US Eastern Standard Time" ,territory:"US" ,type:"America/Indianapolis America/Indiana/Marengo America/Indiana/Vevay"},

			/** (UTC-05:00) Turks and Caicos **/
			{other:"Turks And Caicos Standard Time" ,territory:"001" ,type:"America/Grand_Turk"},
			{other:"Turks And Caicos Standard Time" ,territory:"TC" ,type:"America/Grand_Turk"},

			/** (UTC-04:00) Asuncion **/
			{other:"Paraguay Standard Time" ,territory:"001" ,type:"America/Asuncion"},
			{other:"Paraguay Standard Time" ,territory:"PY" ,type:"America/Asuncion"},

			/** (UTC-04:00) Atlantic Time (Canada) **/
			{other:"Atlantic Standard Time" ,territory:"001" ,type:"America/Halifax"},
			{other:"Atlantic Standard Time" ,territory:"BM" ,type:"Atlantic/Bermuda"},
			{other:"Atlantic Standard Time" ,territory:"CA" ,type:"America/Halifax America/Glace_Bay America/Goose_Bay America/Moncton"},
			{other:"Atlantic Standard Time" ,territory:"GL" ,type:"America/Thule"},

			/** (UTC-04:00) Caracas **/
			{other:"Venezuela Standard Time" ,territory:"001" ,type:"America/Caracas"},
			{other:"Venezuela Standard Time" ,territory:"VE" ,type:"America/Caracas"},

			/** (UTC-04:00) Cuiaba **/
			{other:"Central Brazilian Standard Time" ,territory:"001" ,type:"America/Cuiaba"},
			{other:"Central Brazilian Standard Time" ,territory:"BR" ,type:"America/Cuiaba America/Campo_Grande"},

			/** (UTC-04:00) Georgetown, La Paz, Manaus, San Juan **/
			{other:"SA Western Standard Time" ,territory:"001" ,type:"America/La_Paz"},
			{other:"SA Western Standard Time" ,territory:"AG" ,type:"America/Antigua"},
			{other:"SA Western Standard Time" ,territory:"AI" ,type:"America/Anguilla"},
			{other:"SA Western Standard Time" ,territory:"AW" ,type:"America/Aruba"},
			{other:"SA Western Standard Time" ,territory:"BB" ,type:"America/Barbados"},
			{other:"SA Western Standard Time" ,territory:"BL" ,type:"America/St_Barthelemy"},
			{other:"SA Western Standard Time" ,territory:"BO" ,type:"America/La_Paz"},
			{other:"SA Western Standard Time" ,territory:"BQ" ,type:"America/Kralendijk"},
			{other:"SA Western Standard Time" ,territory:"BR" ,type:"America/Manaus America/Boa_Vista America/Porto_Velho"},
			{other:"SA Western Standard Time" ,territory:"CA" ,type:"America/Blanc-Sablon"},
			{other:"SA Western Standard Time" ,territory:"CW" ,type:"America/Curacao"},
			{other:"SA Western Standard Time" ,territory:"DM" ,type:"America/Dominica"},
			{other:"SA Western Standard Time" ,territory:"DO" ,type:"America/Santo_Domingo"},
			{other:"SA Western Standard Time" ,territory:"GD" ,type:"America/Grenada"},
			{other:"SA Western Standard Time" ,territory:"GP" ,type:"America/Guadeloupe"},
			{other:"SA Western Standard Time" ,territory:"GY" ,type:"America/Guyana"},
			{other:"SA Western Standard Time" ,territory:"KN" ,type:"America/St_Kitts"},
			{other:"SA Western Standard Time" ,territory:"LC" ,type:"America/St_Lucia"},
			{other:"SA Western Standard Time" ,territory:"MF" ,type:"America/Marigot"},
			{other:"SA Western Standard Time" ,territory:"MQ" ,type:"America/Martinique"},
			{other:"SA Western Standard Time" ,territory:"MS" ,type:"America/Montserrat"},
			{other:"SA Western Standard Time" ,territory:"PR" ,type:"America/Puerto_Rico"},
			{other:"SA Western Standard Time" ,territory:"SX" ,type:"America/Lower_Princes"},
			{other:"SA Western Standard Time" ,territory:"TT" ,type:"America/Port_of_Spain"},
			{other:"SA Western Standard Time" ,territory:"VC" ,type:"America/St_Vincent"},
			{other:"SA Western Standard Time" ,territory:"VG" ,type:"America/Tortola"},
			{other:"SA Western Standard Time" ,territory:"VI" ,type:"America/St_Thomas"},
			{other:"SA Western Standard Time" ,territory:"ZZ" ,type:"Etc/GMT+4"},

			/** (UTC-04:00) Santiago **/
			{other:"Pacific SA Standard Time" ,territory:"001" ,type:"America/Santiago"},
			{other:"Pacific SA Standard Time" ,territory:"CL" ,type:"America/Santiago"},

			/** (UTC-03:30) Newfoundland **/
			{other:"Newfoundland Standard Time" ,territory:"001" ,type:"America/St_Johns"},
			{other:"Newfoundland Standard Time" ,territory:"CA" ,type:"America/St_Johns"},

			/** (UTC-03:00) Araguaina **/
			{other:"Tocantins Standard Time" ,territory:"001" ,type:"America/Araguaina"},
			{other:"Tocantins Standard Time" ,territory:"BR" ,type:"America/Araguaina"},

			/** (UTC-03:00) Brasilia **/
			{other:"E. South America Standard Time" ,territory:"001" ,type:"America/Sao_Paulo"},
			{other:"E. South America Standard Time" ,territory:"BR" ,type:"America/Sao_Paulo"},

			/** (UTC-03:00) Cayenne, Fortaleza **/
			{other:"SA Eastern Standard Time" ,territory:"001" ,type:"America/Cayenne"},
			{other:"SA Eastern Standard Time" ,territory:"AQ" ,type:"Antarctica/Rothera Antarctica/Palmer"},
			{other:"SA Eastern Standard Time" ,territory:"BR" ,type:"America/Fortaleza America/Belem America/Maceio America/Recife America/Santarem"},
			{other:"SA Eastern Standard Time" ,territory:"FK" ,type:"Atlantic/Stanley"},
			{other:"SA Eastern Standard Time" ,territory:"GF" ,type:"America/Cayenne"},
			{other:"SA Eastern Standard Time" ,territory:"SR" ,type:"America/Paramaribo"},
			{other:"SA Eastern Standard Time" ,territory:"ZZ" ,type:"Etc/GMT+3"},

			/** (UTC-03:00) City of Buenos Aires **/
			{other:"Argentina Standard Time" ,territory:"001" ,type:"America/Buenos_Aires"},
			{other:"Argentina Standard Time" ,territory:"AR" ,type:"America/Buenos_Aires America/Argentina/La_Rioja America/Argentina/Rio_Gallegos America/Argentina/Salta America/Argentina/San_Juan America/Argentina/San_Luis America/Argentina/Tucuman America/Argentina/Ushuaia America/Catamarca America/Cordoba America/Jujuy America/Mendoza"},

			/** (UTC-03:00) Greenland **/
			{other:"Greenland Standard Time" ,territory:"001" ,type:"America/Godthab"},
			{other:"Greenland Standard Time" ,territory:"GL" ,type:"America/Godthab"},

			/** (UTC-03:00) Montevideo **/
			{other:"Montevideo Standard Time" ,territory:"001" ,type:"America/Montevideo"},
			{other:"Montevideo Standard Time" ,territory:"UY" ,type:"America/Montevideo"},

			/** (UTC-03:00) Punta Arenas **/
			{other:"Magallanes Standard Time" ,territory:"001" ,type:"America/Punta_Arenas"},
			{other:"Magallanes Standard Time" ,territory:"CL" ,type:"America/Punta_Arenas"},

			/** (UTC-03:00) Saint Pierre and Miquelon **/
			{other:"Saint Pierre Standard Time" ,territory:"001" ,type:"America/Miquelon"},
			{other:"Saint Pierre Standard Time" ,territory:"PM" ,type:"America/Miquelon"},

			/** (UTC-03:00) Salvador **/
			{other:"Bahia Standard Time" ,territory:"001" ,type:"America/Bahia"},
			{other:"Bahia Standard Time" ,territory:"BR" ,type:"America/Bahia"},

			/** (UTC-02:00) Coordinated Universal Time-02 **/
			{other:"UTC-02" ,territory:"001" ,type:"Etc/GMT+2"},
			{other:"UTC-02" ,territory:"BR" ,type:"America/Noronha"},
			{other:"UTC-02" ,territory:"GS" ,type:"Atlantic/South_Georgia"},
			{other:"UTC-02" ,territory:"ZZ" ,type:"Etc/GMT+2"},

			/** (UTC-01:00) Azores **/
			{other:"Azores Standard Time" ,territory:"001" ,type:"Atlantic/Azores"},
			{other:"Azores Standard Time" ,territory:"GL" ,type:"America/Scoresbysund"},
			{other:"Azores Standard Time" ,territory:"PT" ,type:"Atlantic/Azores"},

			/** (UTC-01:00) Cabo Verde Is. **/
			{other:"Cape Verde Standard Time" ,territory:"001" ,type:"Atlantic/Cape_Verde"},
			{other:"Cape Verde Standard Time" ,territory:"CV" ,type:"Atlantic/Cape_Verde"},
			{other:"Cape Verde Standard Time" ,territory:"ZZ" ,type:"Etc/GMT+1"},

			/** (UTC) Coordinated Universal Time **/
			{other:"UTC" ,territory:"001" ,type:"Etc/UTC"},
			{other:"UTC" ,territory:"ZZ" ,type:"Etc/UTC Etc/GMT"},

			/** (UTC+00:00) Dublin, Edinburgh, Lisbon, London **/
			{other:"GMT Standard Time" ,territory:"001" ,type:"Europe/London"},
			{other:"GMT Standard Time" ,territory:"ES" ,type:"Atlantic/Canary"},
			{other:"GMT Standard Time" ,territory:"FO" ,type:"Atlantic/Faeroe"},
			{other:"GMT Standard Time" ,territory:"GB" ,type:"Europe/London"},
			{other:"GMT Standard Time" ,territory:"GG" ,type:"Europe/Guernsey"},
			{other:"GMT Standard Time" ,territory:"IE" ,type:"Europe/Dublin"},
			{other:"GMT Standard Time" ,territory:"IM" ,type:"Europe/Isle_of_Man"},
			{other:"GMT Standard Time" ,territory:"JE" ,type:"Europe/Jersey"},
			{other:"GMT Standard Time" ,territory:"PT" ,type:"Europe/Lisbon Atlantic/Madeira"},

			/** (UTC+00:00) Monrovia, Reykjavik **/
			{other:"Greenwich Standard Time" ,territory:"001" ,type:"Atlantic/Reykjavik"},
			{other:"Greenwich Standard Time" ,territory:"BF" ,type:"Africa/Ouagadougou"},
			{other:"Greenwich Standard Time" ,territory:"CI" ,type:"Africa/Abidjan"},
			{other:"Greenwich Standard Time" ,territory:"GH" ,type:"Africa/Accra"},
			{other:"Greenwich Standard Time" ,territory:"GL" ,type:"America/Danmarkshavn"},
			{other:"Greenwich Standard Time" ,territory:"GM" ,type:"Africa/Banjul"},
			{other:"Greenwich Standard Time" ,territory:"GN" ,type:"Africa/Conakry"},
			{other:"Greenwich Standard Time" ,territory:"GW" ,type:"Africa/Bissau"},
			{other:"Greenwich Standard Time" ,territory:"IS" ,type:"Atlantic/Reykjavik"},
			{other:"Greenwich Standard Time" ,territory:"LR" ,type:"Africa/Monrovia"},
			{other:"Greenwich Standard Time" ,territory:"ML" ,type:"Africa/Bamako"},
			{other:"Greenwich Standard Time" ,territory:"MR" ,type:"Africa/Nouakchott"},
			{other:"Greenwich Standard Time" ,territory:"SH" ,type:"Atlantic/St_Helena"},
			{other:"Greenwich Standard Time" ,territory:"SL" ,type:"Africa/Freetown"},
			{other:"Greenwich Standard Time" ,territory:"SN" ,type:"Africa/Dakar"},
			{other:"Greenwich Standard Time" ,territory:"TG" ,type:"Africa/Lome"},

			/** (UTC+00:00) Sao Tome **/
			{other:"Sao Tome Standard Time" ,territory:"001" ,type:"Africa/Sao_Tome"},
			{other:"Sao Tome Standard Time" ,territory:"ST" ,type:"Africa/Sao_Tome"},

			/** (UTC+01:00) Casablanca **/
			{other:"Morocco Standard Time" ,territory:"001" ,type:"Africa/Casablanca"},
			{other:"Morocco Standard Time" ,territory:"EH" ,type:"Africa/El_Aaiun"},
			{other:"Morocco Standard Time" ,territory:"MA" ,type:"Africa/Casablanca"},

			/** (UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna **/
			{other:"W. Europe Standard Time" ,territory:"001" ,type:"Europe/Berlin"},
			{other:"W. Europe Standard Time" ,territory:"AD" ,type:"Europe/Andorra"},
			{other:"W. Europe Standard Time" ,territory:"AT" ,type:"Europe/Vienna"},
			{other:"W. Europe Standard Time" ,territory:"CH" ,type:"Europe/Zurich"},
			{other:"W. Europe Standard Time" ,territory:"DE" ,type:"Europe/Berlin Europe/Busingen"},
			{other:"W. Europe Standard Time" ,territory:"GI" ,type:"Europe/Gibraltar"},
			{other:"W. Europe Standard Time" ,territory:"IT" ,type:"Europe/Rome"},
			{other:"W. Europe Standard Time" ,territory:"LI" ,type:"Europe/Vaduz"},
			{other:"W. Europe Standard Time" ,territory:"LU" ,type:"Europe/Luxembourg"},
			{other:"W. Europe Standard Time" ,territory:"MC" ,type:"Europe/Monaco"},
			{other:"W. Europe Standard Time" ,territory:"MT" ,type:"Europe/Malta"},
			{other:"W. Europe Standard Time" ,territory:"NL" ,type:"Europe/Amsterdam"},
			{other:"W. Europe Standard Time" ,territory:"NO" ,type:"Europe/Oslo"},
			{other:"W. Europe Standard Time" ,territory:"SE" ,type:"Europe/Stockholm"},
			{other:"W. Europe Standard Time" ,territory:"SJ" ,type:"Arctic/Longyearbyen"},
			{other:"W. Europe Standard Time" ,territory:"SM" ,type:"Europe/San_Marino"},
			{other:"W. Europe Standard Time" ,territory:"VA" ,type:"Europe/Vatican"},

			/** (UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague **/
			{other:"Central Europe Standard Time" ,territory:"001" ,type:"Europe/Budapest"},
			{other:"Central Europe Standard Time" ,territory:"AL" ,type:"Europe/Tirane"},
			{other:"Central Europe Standard Time" ,territory:"CZ" ,type:"Europe/Prague"},
			{other:"Central Europe Standard Time" ,territory:"HU" ,type:"Europe/Budapest"},
			{other:"Central Europe Standard Time" ,territory:"ME" ,type:"Europe/Podgorica"},
			{other:"Central Europe Standard Time" ,territory:"RS" ,type:"Europe/Belgrade"},
			{other:"Central Europe Standard Time" ,territory:"SI" ,type:"Europe/Ljubljana"},
			{other:"Central Europe Standard Time" ,territory:"SK" ,type:"Europe/Bratislava"},

			/** (UTC+01:00) Brussels, Copenhagen, Madrid, Paris **/
			{other:"Romance Standard Time" ,territory:"001" ,type:"Europe/Paris"},
			{other:"Romance Standard Time" ,territory:"BE" ,type:"Europe/Brussels"},
			{other:"Romance Standard Time" ,territory:"DK" ,type:"Europe/Copenhagen"},
			{other:"Romance Standard Time" ,territory:"ES" ,type:"Europe/Madrid Africa/Ceuta"},
			{other:"Romance Standard Time" ,territory:"FR" ,type:"Europe/Paris"},

			/** (UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb **/
			{other:"Central European Standard Time" ,territory:"001" ,type:"Europe/Warsaw"},
			{other:"Central European Standard Time" ,territory:"BA" ,type:"Europe/Sarajevo"},
			{other:"Central European Standard Time" ,territory:"HR" ,type:"Europe/Zagreb"},
			{other:"Central European Standard Time" ,territory:"MK" ,type:"Europe/Skopje"},
			{other:"Central European Standard Time" ,territory:"PL" ,type:"Europe/Warsaw"},

			/** (UTC+01:00) West Central Africa **/
			{other:"W. Central Africa Standard Time" ,territory:"001" ,type:"Africa/Lagos"},
			{other:"W. Central Africa Standard Time" ,territory:"AO" ,type:"Africa/Luanda"},
			{other:"W. Central Africa Standard Time" ,territory:"BJ" ,type:"Africa/Porto-Novo"},
			{other:"W. Central Africa Standard Time" ,territory:"CD" ,type:"Africa/Kinshasa"},
			{other:"W. Central Africa Standard Time" ,territory:"CF" ,type:"Africa/Bangui"},
			{other:"W. Central Africa Standard Time" ,territory:"CG" ,type:"Africa/Brazzaville"},
			{other:"W. Central Africa Standard Time" ,territory:"CM" ,type:"Africa/Douala"},
			{other:"W. Central Africa Standard Time" ,territory:"DZ" ,type:"Africa/Algiers"},
			{other:"W. Central Africa Standard Time" ,territory:"GA" ,type:"Africa/Libreville"},
			{other:"W. Central Africa Standard Time" ,territory:"GQ" ,type:"Africa/Malabo"},
			{other:"W. Central Africa Standard Time" ,territory:"NE" ,type:"Africa/Niamey"},
			{other:"W. Central Africa Standard Time" ,territory:"NG" ,type:"Africa/Lagos"},
			{other:"W. Central Africa Standard Time" ,territory:"TD" ,type:"Africa/Ndjamena"},
			{other:"W. Central Africa Standard Time" ,territory:"TN" ,type:"Africa/Tunis"},
			{other:"W. Central Africa Standard Time" ,territory:"ZZ" ,type:"Etc/GMT-1"},

			/** (UTC+02:00) Amman **/
			{other:"Jordan Standard Time" ,territory:"001" ,type:"Asia/Amman"},
			{other:"Jordan Standard Time" ,territory:"JO" ,type:"Asia/Amman"},

			/** (UTC+02:00) Athens, Bucharest **/
			{other:"GTB Standard Time" ,territory:"001" ,type:"Europe/Bucharest"},
			{other:"GTB Standard Time" ,territory:"CY" ,type:"Asia/Nicosia Asia/Famagusta"},
			{other:"GTB Standard Time" ,territory:"GR" ,type:"Europe/Athens"},
			{other:"GTB Standard Time" ,territory:"RO" ,type:"Europe/Bucharest"},

			/** (UTC+02:00) Beirut **/
			{other:"Middle East Standard Time" ,territory:"001" ,type:"Asia/Beirut"},
			{other:"Middle East Standard Time" ,territory:"LB" ,type:"Asia/Beirut"},

			/** (UTC+02:00) Cairo **/
			{other:"Egypt Standard Time" ,territory:"001" ,type:"Africa/Cairo"},
			{other:"Egypt Standard Time" ,territory:"EG" ,type:"Africa/Cairo"},

			/** (UTC+02:00) Chisinau **/
			{other:"E. Europe Standard Time" ,territory:"001" ,type:"Europe/Chisinau"},
			{other:"E. Europe Standard Time" ,territory:"MD" ,type:"Europe/Chisinau"},

			/** (UTC+02:00) Damascus **/
			{other:"Syria Standard Time" ,territory:"001" ,type:"Asia/Damascus"},
			{other:"Syria Standard Time" ,territory:"SY" ,type:"Asia/Damascus"},

			/** (UTC+02:00) Gaza, Hebron **/
			{other:"West Bank Standard Time" ,territory:"001" ,type:"Asia/Hebron"},
			{other:"West Bank Standard Time" ,territory:"PS" ,type:"Asia/Hebron Asia/Gaza"},

			/** (UTC+02:00) Harare, Pretoria **/
			{other:"South Africa Standard Time" ,territory:"001" ,type:"Africa/Johannesburg"},
			{other:"South Africa Standard Time" ,territory:"BI" ,type:"Africa/Bujumbura"},
			{other:"South Africa Standard Time" ,territory:"BW" ,type:"Africa/Gaborone"},
			{other:"South Africa Standard Time" ,territory:"CD" ,type:"Africa/Lubumbashi"},
			{other:"South Africa Standard Time" ,territory:"LS" ,type:"Africa/Maseru"},
			{other:"South Africa Standard Time" ,territory:"MW" ,type:"Africa/Blantyre"},
			{other:"South Africa Standard Time" ,territory:"MZ" ,type:"Africa/Maputo"},
			{other:"South Africa Standard Time" ,territory:"RW" ,type:"Africa/Kigali"},
			{other:"South Africa Standard Time" ,territory:"SZ" ,type:"Africa/Mbabane"},
			{other:"South Africa Standard Time" ,territory:"ZA" ,type:"Africa/Johannesburg"},
			{other:"South Africa Standard Time" ,territory:"ZM" ,type:"Africa/Lusaka"},
			{other:"South Africa Standard Time" ,territory:"ZW" ,type:"Africa/Harare"},
			{other:"South Africa Standard Time" ,territory:"ZZ" ,type:"Etc/GMT-2"},

			/** (UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius **/
			{other:"FLE Standard Time" ,territory:"001" ,type:"Europe/Kiev"},
			{other:"FLE Standard Time" ,territory:"AX" ,type:"Europe/Mariehamn"},
			{other:"FLE Standard Time" ,territory:"BG" ,type:"Europe/Sofia"},
			{other:"FLE Standard Time" ,territory:"EE" ,type:"Europe/Tallinn"},
			{other:"FLE Standard Time" ,territory:"FI" ,type:"Europe/Helsinki"},
			{other:"FLE Standard Time" ,territory:"LT" ,type:"Europe/Vilnius"},
			{other:"FLE Standard Time" ,territory:"LV" ,type:"Europe/Riga"},
			{other:"FLE Standard Time" ,territory:"UA" ,type:"Europe/Kiev Europe/Uzhgorod Europe/Zaporozhye"},

			/** (UTC+02:00) Jerusalem **/
			{other:"Israel Standard Time" ,territory:"001" ,type:"Asia/Jerusalem"},
			{other:"Israel Standard Time" ,territory:"IL" ,type:"Asia/Jerusalem"},

			/** (UTC+02:00) Juba **/
			{other:"South Sudan Standard Time" ,territory:"001" ,type:"Africa/Juba"},
			{other:"South Sudan Standard Time" ,territory:"SS" ,type:"Africa/Juba"},

			/** (UTC+02:00) Kaliningrad **/
			{other:"Kaliningrad Standard Time" ,territory:"001" ,type:"Europe/Kaliningrad"},
			{other:"Kaliningrad Standard Time" ,territory:"RU" ,type:"Europe/Kaliningrad"},

			/** (UTC+02:00) Khartoum **/
			{other:"Sudan Standard Time" ,territory:"001" ,type:"Africa/Khartoum"},
			{other:"Sudan Standard Time" ,territory:"SD" ,type:"Africa/Khartoum"},

			/** (UTC+02:00) Tripoli **/
			{other:"Libya Standard Time" ,territory:"001" ,type:"Africa/Tripoli"},
			{other:"Libya Standard Time" ,territory:"LY" ,type:"Africa/Tripoli"},

			/** (UTC+02:00) Windhoek **/
			{other:"Namibia Standard Time" ,territory:"001" ,type:"Africa/Windhoek"},
			{other:"Namibia Standard Time" ,territory:"NA" ,type:"Africa/Windhoek"},

			/** (UTC+03:00) Baghdad **/
			{other:"Arabic Standard Time" ,territory:"001" ,type:"Asia/Baghdad"},
			{other:"Arabic Standard Time" ,territory:"IQ" ,type:"Asia/Baghdad"},

			/** (UTC+03:00) Istanbul **/
			{other:"Turkey Standard Time" ,territory:"001" ,type:"Europe/Istanbul"},
			{other:"Turkey Standard Time" ,territory:"TR" ,type:"Europe/Istanbul"},

			/** (UTC+03:00) Kuwait, Riyadh **/
			{other:"Arab Standard Time" ,territory:"001" ,type:"Asia/Riyadh"},
			{other:"Arab Standard Time" ,territory:"BH" ,type:"Asia/Bahrain"},
			{other:"Arab Standard Time" ,territory:"KW" ,type:"Asia/Kuwait"},
			{other:"Arab Standard Time" ,territory:"QA" ,type:"Asia/Qatar"},
			{other:"Arab Standard Time" ,territory:"SA" ,type:"Asia/Riyadh"},
			{other:"Arab Standard Time" ,territory:"YE" ,type:"Asia/Aden"},

			/** (UTC+03:00) Minsk **/
			{other:"Belarus Standard Time" ,territory:"001" ,type:"Europe/Minsk"},
			{other:"Belarus Standard Time" ,territory:"BY" ,type:"Europe/Minsk"},

			/** (UTC+03:00) Moscow, St. Petersburg **/
			{other:"Russian Standard Time" ,territory:"001" ,type:"Europe/Moscow"},
			{other:"Russian Standard Time" ,territory:"RU" ,type:"Europe/Moscow Europe/Kirov"},
			{other:"Russian Standard Time" ,territory:"UA" ,type:"Europe/Simferopol"},

			/** (UTC+03:00) Nairobi **/
			{other:"E. Africa Standard Time" ,territory:"001" ,type:"Africa/Nairobi"},
			{other:"E. Africa Standard Time" ,territory:"AQ" ,type:"Antarctica/Syowa"},
			{other:"E. Africa Standard Time" ,territory:"DJ" ,type:"Africa/Djibouti"},
			{other:"E. Africa Standard Time" ,territory:"ER" ,type:"Africa/Asmera"},
			{other:"E. Africa Standard Time" ,territory:"ET" ,type:"Africa/Addis_Ababa"},
			{other:"E. Africa Standard Time" ,territory:"KE" ,type:"Africa/Nairobi"},
			{other:"E. Africa Standard Time" ,territory:"KM" ,type:"Indian/Comoro"},
			{other:"E. Africa Standard Time" ,territory:"MG" ,type:"Indian/Antananarivo"},
			{other:"E. Africa Standard Time" ,territory:"SO" ,type:"Africa/Mogadishu"},
			{other:"E. Africa Standard Time" ,territory:"TZ" ,type:"Africa/Dar_es_Salaam"},
			{other:"E. Africa Standard Time" ,territory:"UG" ,type:"Africa/Kampala"},
			{other:"E. Africa Standard Time" ,territory:"YT" ,type:"Indian/Mayotte"},
			{other:"E. Africa Standard Time" ,territory:"ZZ" ,type:"Etc/GMT-3"},

			/** (UTC+03:30) Tehran **/
			{other:"Iran Standard Time" ,territory:"001" ,type:"Asia/Tehran"},
			{other:"Iran Standard Time" ,territory:"IR" ,type:"Asia/Tehran"},

			/** (UTC+04:00) Abu Dhabi, Muscat **/
			{other:"Arabian Standard Time" ,territory:"001" ,type:"Asia/Dubai"},
			{other:"Arabian Standard Time" ,territory:"AE" ,type:"Asia/Dubai"},
			{other:"Arabian Standard Time" ,territory:"OM" ,type:"Asia/Muscat"},
			{other:"Arabian Standard Time" ,territory:"ZZ" ,type:"Etc/GMT-4"},

			/** (UTC+04:00) Astrakhan, Ulyanovsk **/
			{other:"Astrakhan Standard Time" ,territory:"001" ,type:"Europe/Astrakhan"},
			{other:"Astrakhan Standard Time" ,territory:"RU" ,type:"Europe/Astrakhan Europe/Ulyanovsk"},

			/** (UTC+04:00) Baku **/
			{other:"Azerbaijan Standard Time" ,territory:"001" ,type:"Asia/Baku"},
			{other:"Azerbaijan Standard Time" ,territory:"AZ" ,type:"Asia/Baku"},

			/** (UTC+04:00) Izhevsk, Samara **/
			{other:"Russia Time Zone 3" ,territory:"001" ,type:"Europe/Samara"},
			{other:"Russia Time Zone 3" ,territory:"RU" ,type:"Europe/Samara"},

			/** (UTC+04:00) Port Louis **/
			{other:"Mauritius Standard Time" ,territory:"001" ,type:"Indian/Mauritius"},
			{other:"Mauritius Standard Time" ,territory:"MU" ,type:"Indian/Mauritius"},
			{other:"Mauritius Standard Time" ,territory:"RE" ,type:"Indian/Reunion"},
			{other:"Mauritius Standard Time" ,territory:"SC" ,type:"Indian/Mahe"},

			/** (UTC+04:00) Saratov **/
			{other:"Saratov Standard Time" ,territory:"001" ,type:"Europe/Saratov"},
			{other:"Saratov Standard Time" ,territory:"RU" ,type:"Europe/Saratov"},

			/** (UTC+04:00) Tbilisi **/
			{other:"Georgian Standard Time" ,territory:"001" ,type:"Asia/Tbilisi"},
			{other:"Georgian Standard Time" ,territory:"GE" ,type:"Asia/Tbilisi"},

			/** (UTC+04:00) Volgograd **/
			{other:"Volgograd Standard Time" ,territory:"001" ,type:"Europe/Volgograd"},
			{other:"Volgograd Standard Time" ,territory:"RU" ,type:"Europe/Volgograd"},

			/** (UTC+04:00) Yerevan **/
			{other:"Caucasus Standard Time" ,territory:"001" ,type:"Asia/Yerevan"},
			{other:"Caucasus Standard Time" ,territory:"AM" ,type:"Asia/Yerevan"},

			/** (UTC+04:30) Kabul **/
			{other:"Afghanistan Standard Time" ,territory:"001" ,type:"Asia/Kabul"},
			{other:"Afghanistan Standard Time" ,territory:"AF" ,type:"Asia/Kabul"},

			/** (UTC+05:00) Ashgabat, Tashkent **/
			{other:"West Asia Standard Time" ,territory:"001" ,type:"Asia/Tashkent"},
			{other:"West Asia Standard Time" ,territory:"AQ" ,type:"Antarctica/Mawson"},
			{other:"West Asia Standard Time" ,territory:"KZ" ,type:"Asia/Oral Asia/Aqtau Asia/Aqtobe Asia/Atyrau"},
			{other:"West Asia Standard Time" ,territory:"MV" ,type:"Indian/Maldives"},
			{other:"West Asia Standard Time" ,territory:"TF" ,type:"Indian/Kerguelen"},
			{other:"West Asia Standard Time" ,territory:"TJ" ,type:"Asia/Dushanbe"},
			{other:"West Asia Standard Time" ,territory:"TM" ,type:"Asia/Ashgabat"},
			{other:"West Asia Standard Time" ,territory:"UZ" ,type:"Asia/Tashkent Asia/Samarkand"},
			{other:"West Asia Standard Time" ,territory:"ZZ" ,type:"Etc/GMT-5"},

			/** (UTC+05:00) Ekaterinburg **/
			{other:"Ekaterinburg Standard Time" ,territory:"001" ,type:"Asia/Yekaterinburg"},
			{other:"Ekaterinburg Standard Time" ,territory:"RU" ,type:"Asia/Yekaterinburg"},

			/** (UTC+05:00) Islamabad, Karachi **/
			{other:"Pakistan Standard Time" ,territory:"001" ,type:"Asia/Karachi"},
			{other:"Pakistan Standard Time" ,territory:"PK" ,type:"Asia/Karachi"},

			/** (UTC+05:00) Qyzylorda **/
			{other:"Qyzylorda Standard Time" ,territory:"001" ,type:"Asia/Qyzylorda"},
			{other:"Qyzylorda Standard Time" ,territory:"KZ" ,type:"Asia/Qyzylorda"},

			/** (UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi **/
			{other:"India Standard Time" ,territory:"001" ,type:"Asia/Calcutta"},
			{other:"India Standard Time" ,territory:"IN" ,type:"Asia/Calcutta"},

			/** (UTC+05:30) Sri Jayawardenepura **/
			{other:"Sri Lanka Standard Time" ,territory:"001" ,type:"Asia/Colombo"},
			{other:"Sri Lanka Standard Time" ,territory:"LK" ,type:"Asia/Colombo"},

			/** (UTC+05:45) Kathmandu **/
			{other:"Nepal Standard Time" ,territory:"001" ,type:"Asia/Katmandu"},
			{other:"Nepal Standard Time" ,territory:"NP" ,type:"Asia/Katmandu"},

			/** (UTC+06:00) Astana **/
			{other:"Central Asia Standard Time" ,territory:"001" ,type:"Asia/Almaty"},
			{other:"Central Asia Standard Time" ,territory:"AQ" ,type:"Antarctica/Vostok"},
			{other:"Central Asia Standard Time" ,territory:"CN" ,type:"Asia/Urumqi"},
			{other:"Central Asia Standard Time" ,territory:"IO" ,type:"Indian/Chagos"},
			{other:"Central Asia Standard Time" ,territory:"KG" ,type:"Asia/Bishkek"},
			{other:"Central Asia Standard Time" ,territory:"KZ" ,type:"Asia/Almaty Asia/Qostanay"},
			{other:"Central Asia Standard Time" ,territory:"ZZ" ,type:"Etc/GMT-6"},

			/** (UTC+06:00) Dhaka **/
			{other:"Bangladesh Standard Time" ,territory:"001" ,type:"Asia/Dhaka"},
			{other:"Bangladesh Standard Time" ,territory:"BD" ,type:"Asia/Dhaka"},
			{other:"Bangladesh Standard Time" ,territory:"BT" ,type:"Asia/Thimphu"},

			/** (UTC+06:00) Omsk **/
			{other:"Omsk Standard Time" ,territory:"001" ,type:"Asia/Omsk"},
			{other:"Omsk Standard Time" ,territory:"RU" ,type:"Asia/Omsk"},

			/** (UTC+06:30) Yangon (Rangoon) **/
			{other:"Myanmar Standard Time" ,territory:"001" ,type:"Asia/Rangoon"},
			{other:"Myanmar Standard Time" ,territory:"CC" ,type:"Indian/Cocos"},
			{other:"Myanmar Standard Time" ,territory:"MM" ,type:"Asia/Rangoon"},

			/** (UTC+07:00) Bangkok, Hanoi, Jakarta **/
			{other:"SE Asia Standard Time" ,territory:"001" ,type:"Asia/Bangkok"},
			{other:"SE Asia Standard Time" ,territory:"AQ" ,type:"Antarctica/Davis"},
			{other:"SE Asia Standard Time" ,territory:"CX" ,type:"Indian/Christmas"},
			{other:"SE Asia Standard Time" ,territory:"ID" ,type:"Asia/Jakarta Asia/Pontianak"},
			{other:"SE Asia Standard Time" ,territory:"KH" ,type:"Asia/Phnom_Penh"},
			{other:"SE Asia Standard Time" ,territory:"LA" ,type:"Asia/Vientiane"},
			{other:"SE Asia Standard Time" ,territory:"TH" ,type:"Asia/Bangkok"},
			{other:"SE Asia Standard Time" ,territory:"VN" ,type:"Asia/Saigon"},
			{other:"SE Asia Standard Time" ,territory:"ZZ" ,type:"Etc/GMT-7"},

			/** (UTC+07:00) Barnaul, Gorno-Altaysk **/
			{other:"Altai Standard Time" ,territory:"001" ,type:"Asia/Barnaul"},
			{other:"Altai Standard Time" ,territory:"RU" ,type:"Asia/Barnaul"},

			/** (UTC+07:00) Hovd **/
			{other:"W. Mongolia Standard Time" ,territory:"001" ,type:"Asia/Hovd"},
			{other:"W. Mongolia Standard Time" ,territory:"MN" ,type:"Asia/Hovd"},

			/** (UTC+07:00) Krasnoyarsk **/
			{other:"North Asia Standard Time" ,territory:"001" ,type:"Asia/Krasnoyarsk"},
			{other:"North Asia Standard Time" ,territory:"RU" ,type:"Asia/Krasnoyarsk Asia/Novokuznetsk"},

			/** (UTC+07:00) Novosibirsk **/
			{other:"N. Central Asia Standard Time" ,territory:"001" ,type:"Asia/Novosibirsk"},
			{other:"N. Central Asia Standard Time" ,territory:"RU" ,type:"Asia/Novosibirsk"},

			/** (UTC+07:00) Tomsk **/
			{other:"Tomsk Standard Time" ,territory:"001" ,type:"Asia/Tomsk"},
			{other:"Tomsk Standard Time" ,territory:"RU" ,type:"Asia/Tomsk"},

			/** (UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi **/
			{other:"China Standard Time" ,territory:"001" ,type:"Asia/Shanghai"},
			{other:"China Standard Time" ,territory:"CN" ,type:"Asia/Shanghai"},
			{other:"China Standard Time" ,territory:"HK" ,type:"Asia/Hong_Kong"},
			{other:"China Standard Time" ,territory:"MO" ,type:"Asia/Macau"},

			/** (UTC+08:00) Irkutsk **/
			{other:"North Asia East Standard Time" ,territory:"001" ,type:"Asia/Irkutsk"},
			{other:"North Asia East Standard Time" ,territory:"RU" ,type:"Asia/Irkutsk"},

			/** (UTC+08:00) Kuala Lumpur, Singapore **/
			{other:"Singapore Standard Time" ,territory:"001" ,type:"Asia/Singapore"},
			{other:"Singapore Standard Time" ,territory:"BN" ,type:"Asia/Brunei"},
			{other:"Singapore Standard Time" ,territory:"ID" ,type:"Asia/Makassar"},
			{other:"Singapore Standard Time" ,territory:"MY" ,type:"Asia/Kuala_Lumpur Asia/Kuching"},
			{other:"Singapore Standard Time" ,territory:"PH" ,type:"Asia/Manila"},
			{other:"Singapore Standard Time" ,territory:"SG" ,type:"Asia/Singapore"},
			{other:"Singapore Standard Time" ,territory:"ZZ" ,type:"Etc/GMT-8"},

			/** (UTC+08:00) Perth **/
			{other:"W. Australia Standard Time" ,territory:"001" ,type:"Australia/Perth"},
			{other:"W. Australia Standard Time" ,territory:"AU" ,type:"Australia/Perth"},

			/** (UTC+08:00) Taipei **/
			{other:"Taipei Standard Time" ,territory:"001" ,type:"Asia/Taipei"},
			{other:"Taipei Standard Time" ,territory:"TW" ,type:"Asia/Taipei"},

			/** (UTC+08:00) Ulaanbaatar **/
			{other:"Ulaanbaatar Standard Time" ,territory:"001" ,type:"Asia/Ulaanbaatar"},
			{other:"Ulaanbaatar Standard Time" ,territory:"MN" ,type:"Asia/Ulaanbaatar Asia/Choibalsan"},

			/** (UTC+08:45) Eucla **/
			{other:"Aus Central W. Standard Time" ,territory:"001" ,type:"Australia/Eucla"},
			{other:"Aus Central W. Standard Time" ,territory:"AU" ,type:"Australia/Eucla"},

			/** (UTC+09:00) Chita **/
			{other:"Transbaikal Standard Time" ,territory:"001" ,type:"Asia/Chita"},
			{other:"Transbaikal Standard Time" ,territory:"RU" ,type:"Asia/Chita"},

			/** (UTC+09:00) Osaka, Sapporo, Tokyo **/
			{other:"Tokyo Standard Time" ,territory:"001" ,type:"Asia/Tokyo"},
			{other:"Tokyo Standard Time" ,territory:"ID" ,type:"Asia/Jayapura"},
			{other:"Tokyo Standard Time" ,territory:"JP" ,type:"Asia/Tokyo"},
			{other:"Tokyo Standard Time" ,territory:"PW" ,type:"Pacific/Palau"},
			{other:"Tokyo Standard Time" ,territory:"TL" ,type:"Asia/Dili"},
			{other:"Tokyo Standard Time" ,territory:"ZZ" ,type:"Etc/GMT-9"},

			/** (UTC+09:00) Pyongyang **/
			{other:"North Korea Standard Time" ,territory:"001" ,type:"Asia/Pyongyang"},
			{other:"North Korea Standard Time" ,territory:"KP" ,type:"Asia/Pyongyang"},

			/** (UTC+09:00) Seoul **/
			{other:"Korea Standard Time" ,territory:"001" ,type:"Asia/Seoul"},
			{other:"Korea Standard Time" ,territory:"KR" ,type:"Asia/Seoul"},

			/** (UTC+09:00) Yakutsk **/
			{other:"Yakutsk Standard Time" ,territory:"001" ,type:"Asia/Yakutsk"},
			{other:"Yakutsk Standard Time" ,territory:"RU" ,type:"Asia/Yakutsk Asia/Khandyga"},

			/** (UTC+09:30) Adelaide **/
			{other:"Cen. Australia Standard Time" ,territory:"001" ,type:"Australia/Adelaide"},
			{other:"Cen. Australia Standard Time" ,territory:"AU" ,type:"Australia/Adelaide Australia/Broken_Hill"},

			/** (UTC+09:30) Darwin **/
			{other:"AUS Central Standard Time" ,territory:"001" ,type:"Australia/Darwin"},
			{other:"AUS Central Standard Time" ,territory:"AU" ,type:"Australia/Darwin"},

			/** (UTC+10:00) Brisbane **/
			{other:"E. Australia Standard Time" ,territory:"001" ,type:"Australia/Brisbane"},
			{other:"E. Australia Standard Time" ,territory:"AU" ,type:"Australia/Brisbane Australia/Lindeman"},

			/** (UTC+10:00) Canberra, Melbourne, Sydney **/
			{other:"AUS Eastern Standard Time" ,territory:"001" ,type:"Australia/Sydney"},
			{other:"AUS Eastern Standard Time" ,territory:"AU" ,type:"Australia/Sydney Australia/Melbourne"},

			/** (UTC+10:00) Guam, Port Moresby **/
			{other:"West Pacific Standard Time" ,territory:"001" ,type:"Pacific/Port_Moresby"},
			{other:"West Pacific Standard Time" ,territory:"AQ" ,type:"Antarctica/DumontDUrville"},
			{other:"West Pacific Standard Time" ,territory:"FM" ,type:"Pacific/Truk"},
			{other:"West Pacific Standard Time" ,territory:"GU" ,type:"Pacific/Guam"},
			{other:"West Pacific Standard Time" ,territory:"MP" ,type:"Pacific/Saipan"},
			{other:"West Pacific Standard Time" ,territory:"PG" ,type:"Pacific/Port_Moresby"},
			{other:"West Pacific Standard Time" ,territory:"ZZ" ,type:"Etc/GMT-10"},

			/** (UTC+10:00) Hobart **/
			{other:"Tasmania Standard Time" ,territory:"001" ,type:"Australia/Hobart"},
			{other:"Tasmania Standard Time" ,territory:"AU" ,type:"Australia/Hobart Australia/Currie Antarctica/Macquarie"},

			/** (UTC+10:00) Vladivostok **/
			{other:"Vladivostok Standard Time" ,territory:"001" ,type:"Asia/Vladivostok"},
			{other:"Vladivostok Standard Time" ,territory:"RU" ,type:"Asia/Vladivostok Asia/Ust-Nera"},

			/** (UTC+10:30) Lord Howe Island **/
			{other:"Lord Howe Standard Time" ,territory:"001" ,type:"Australia/Lord_Howe"},
			{other:"Lord Howe Standard Time" ,territory:"AU" ,type:"Australia/Lord_Howe"},

			/** (UTC+11:00) Bougainville Island **/
			{other:"Bougainville Standard Time" ,territory:"001" ,type:"Pacific/Bougainville"},
			{other:"Bougainville Standard Time" ,territory:"PG" ,type:"Pacific/Bougainville"},

			/** (UTC+11:00) Chokurdakh **/
			{other:"Russia Time Zone 10" ,territory:"001" ,type:"Asia/Srednekolymsk"},
			{other:"Russia Time Zone 10" ,territory:"RU" ,type:"Asia/Srednekolymsk"},

			/** (UTC+11:00) Magadan **/
			{other:"Magadan Standard Time" ,territory:"001" ,type:"Asia/Magadan"},
			{other:"Magadan Standard Time" ,territory:"RU" ,type:"Asia/Magadan"},

			/** (UTC+11:00) Norfolk Island **/
			{other:"Norfolk Standard Time" ,territory:"001" ,type:"Pacific/Norfolk"},
			{other:"Norfolk Standard Time" ,territory:"NF" ,type:"Pacific/Norfolk"},

			/** (UTC+11:00) Sakhalin **/
			{other:"Sakhalin Standard Time" ,territory:"001" ,type:"Asia/Sakhalin"},
			{other:"Sakhalin Standard Time" ,territory:"RU" ,type:"Asia/Sakhalin"},

			/** (UTC+11:00) Solomon Is., New Caledonia **/
			{other:"Central Pacific Standard Time" ,territory:"001" ,type:"Pacific/Guadalcanal"},
			{other:"Central Pacific Standard Time" ,territory:"AQ" ,type:"Antarctica/Casey"},
			{other:"Central Pacific Standard Time" ,territory:"FM" ,type:"Pacific/Ponape Pacific/Kosrae"},
			{other:"Central Pacific Standard Time" ,territory:"NC" ,type:"Pacific/Noumea"},
			{other:"Central Pacific Standard Time" ,territory:"SB" ,type:"Pacific/Guadalcanal"},
			{other:"Central Pacific Standard Time" ,territory:"VU" ,type:"Pacific/Efate"},
			{other:"Central Pacific Standard Time" ,territory:"ZZ" ,type:"Etc/GMT-11"},

			/** (UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky **/
			{other:"Russia Time Zone 11" ,territory:"001" ,type:"Asia/Kamchatka"},
			{other:"Russia Time Zone 11" ,territory:"RU" ,type:"Asia/Kamchatka Asia/Anadyr"},

			/** (UTC+12:00) Auckland, Wellington **/
			{other:"New Zealand Standard Time" ,territory:"001" ,type:"Pacific/Auckland"},
			{other:"New Zealand Standard Time" ,territory:"AQ" ,type:"Antarctica/McMurdo"},
			{other:"New Zealand Standard Time" ,territory:"NZ" ,type:"Pacific/Auckland"},

			/** (UTC+12:00) Coordinated Universal Time+12 **/
			{other:"UTC+12" ,territory:"001" ,type:"Etc/GMT-12"},
			{other:"UTC+12" ,territory:"KI" ,type:"Pacific/Tarawa"},
			{other:"UTC+12" ,territory:"MH" ,type:"Pacific/Majuro Pacific/Kwajalein"},
			{other:"UTC+12" ,territory:"NR" ,type:"Pacific/Nauru"},
			{other:"UTC+12" ,territory:"TV" ,type:"Pacific/Funafuti"},
			{other:"UTC+12" ,territory:"UM" ,type:"Pacific/Wake"},
			{other:"UTC+12" ,territory:"WF" ,type:"Pacific/Wallis"},
			{other:"UTC+12" ,territory:"ZZ" ,type:"Etc/GMT-12"},

			/** (UTC+12:00) Fiji **/
			{other:"Fiji Standard Time" ,territory:"001" ,type:"Pacific/Fiji"},
			{other:"Fiji Standard Time" ,territory:"FJ" ,type:"Pacific/Fiji"},

			/** (UTC+12:45) Chatham Islands **/
			{other:"Chatham Islands Standard Time" ,territory:"001" ,type:"Pacific/Chatham"},
			{other:"Chatham Islands Standard Time" ,territory:"NZ" ,type:"Pacific/Chatham"},

			/** (UTC+13:00) Coordinated Universal Time+13 **/
			{other:"UTC+13" ,territory:"001" ,type:"Etc/GMT-13"},
			{other:"UTC+13" ,territory:"KI" ,type:"Pacific/Enderbury"},
			{other:"UTC+13" ,territory:"TK" ,type:"Pacific/Fakaofo"},
			{other:"UTC+13" ,territory:"ZZ" ,type:"Etc/GMT-13"},

			/** (UTC+13:00) Nuku'alofa **/
			{other:"Tonga Standard Time" ,territory:"001" ,type:"Pacific/Tongatapu"},
			{other:"Tonga Standard Time" ,territory:"TO" ,type:"Pacific/Tongatapu"},

			/** (UTC+13:00) Samoa **/
			{other:"Samoa Standard Time" ,territory:"001" ,type:"Pacific/Apia"},
			{other:"Samoa Standard Time" ,territory:"WS" ,type:"Pacific/Apia"},

			/** (UTC+14:00) Kiritimati Island **/
			{other:"Line Islands Standard Time" ,territory:"001" ,type:"Pacific/Kiritimati"},
			{other:"Line Islands Standard Time" ,territory:"KI" ,type:"Pacific/Kiritimati"},
			{other:"Line Islands Standard Time" ,territory:"ZZ" ,type:"Etc/GMT-14"},
        ]

        export function convertAbbrToTZ(abbr: string): string {

            let res:string = "US Mountain Standard Time";

            for (const abArray of abbrTZ) {
                if( abArray.abbr === abbr.toUpperCase()) {
                    for(const tz of mapTZ) {
                        if (tz.other === abArray.tz) {
                            return(tz.type);
                        }
                    }
                }
            }
            console.error("TZ not mapped "+abbr);

            return res;
        }