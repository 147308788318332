
<my-slide-panel [activePane]="currentPane">
	<section leftPane>
		<button class="back-to-list" mat-stroked-button (click)="onCloseClick()">Back<mat-icon>arrow_forward</mat-icon></button>
		<activity-form  [inputActivity]="currentI$ | async"></activity-form>
	</section>
	<section centerPane>
		<h2>Activities</h2>

		<div *ngIf="!partnerList" class="action">
			<span *ngIf="isAdmin()">
				<button type="button" (click)="onClickActivity(element)">Add <mat-icon>playlist_add</mat-icon></button>
			</span>
			<span>
				<mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="30" class='spin'>  </mat-progress-spinner>
			</span>
			<span>
				<button mat-stroked-button (click)="onRefreshClick()">Refresh<mat-icon>refresh</mat-icon></button>
			</span>
		</div>

		<table mat-table [dataSource]="dataSource" matSort>
			<ng-container matColumnDef="edit">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> List/Edit </th>
				<td mat-cell *matCellDef="let element">
					<button type="button" (click)="onClickEdit(element)"> <mat-icon>edit</mat-icon></button>
				</td>
			</ng-container>
			<ng-container matColumnDef="from">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> From </th>
				<td mat-cell *matCellDef="let element"> {{element.fromName}} </td>
			</ng-container>
			<ng-container matColumnDef="to">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> To </th>
				<td mat-cell *matCellDef="let element"> {{element.toName}} </td>
			</ng-container>
			<ng-container matColumnDef="kind">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Kind </th>
				<td mat-cell *matCellDef="let element"> {{element.kind}} </td>
		 	</ng-container>
			<ng-container matColumnDef="date">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
				<td mat-cell *matCellDef="let element"> {{dateFormating(element.date, "ts")}} </td>
			</ng-container>
			<ng-container matColumnDef="by">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> By </th>
				<td mat-cell *matCellDef="let element"> {{element.by}} </td>
			</ng-container>
			<ng-container matColumnDef="NN">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Nat. Num </th>
				<td mat-cell *matCellDef="let element"> {{element.NN}} </td>
		 	</ng-container>
			<ng-container matColumnDef="delete">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Delete </th>
				<td mat-cell *matCellDef="let element">
					<button type="button" (click)="onClickDelete(element._key)"> <mat-icon>delete</mat-icon></button>
				</td>
		 	</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
		<mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]">
		</mat-paginator>
	</section>
	<section rightPane>

	</section>
</my-slide-panel>
