
import { networkConfig } from 'src/config/webapp.config';
import { BO9EVENTS } from '../../../../bo9-shared/models/BO9_base/BO9_base.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Member } from '../../../../bo9-shared/models/member/member.model';

import { Person } from '../../../../bo9-shared/models/person/person.model';
@Injectable()
export class MemberService {

    constructor (
      private http: HttpClient
    ) {

      // hack not empty
    }

   
  public autoLogin(user: Member): Observable<any>  {
    const id = user.wpUser.ID ? user.wpUser.ID : user.wpUser.id ? user.wpUser.id : 0;
    const params = {
      wpuid:id,
      code: user.temp_code,
      match: btoa(user.temp_code+id.toString())
    }
    return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.AUTOLOGIN,params);
  }

  public autoLoginUser(id: string,code: string, jwt:string): Observable<any>  {

    const params = {
      wpuid:id,
      code: code,
      match: jwt
    }
    return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.AUTOLOGIN,params);
  }

    public getwpuserbyemail(email: string) {
      return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WPUSERBYEMAIL+"/?email="+email);
    }

    public getwpuserbyid(id: string) {
      return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WPUSERBYID+"/?id="+id);
    }

    public dropMember(email: string) : Observable<any> {
      return this.http.delete(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.MEMBER+"/?email="+email);
    }

    public updateMember(person:Person, memberType: string) : Observable<any> {
      const param = {
        email: person.primaryEmail,
        memberType: memberType
      }
      return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.MEMBER, param);
    }

}
