export interface Preferences {
    autoRefresh: boolean;
    eventHistory: number;
    eventFutureDays: number;
    rowsPerPage: number;
}

export function setPreferences()  {
    const p:Preferences = {
        autoRefresh: true,
        eventHistory: 90,
        eventFutureDays: 180,
        rowsPerPage: 15
    }

    return p;
}

export function fillPreferences(pref: Preferences)  {
    const p:Preferences = {
        autoRefresh: false,
        eventHistory: pref.eventHistory? pref.eventHistory: 90,
        eventFutureDays: pref.eventFutureDays? pref.eventFutureDays: 180,
        rowsPerPage: pref.rowsPerPage? pref.rowsPerPage: 15
    }

    if (pref.autoRefresh && pref.autoRefresh == true) {
        p.autoRefresh = true;
    }

    return p;
}