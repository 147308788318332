import { Component, OnInit, OnDestroy, Input, Inject,  NgModule } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { AuthService } from 'src/services/auth/auth.service';

import { ChatRoomService } from 'src/services/chatmessageservice/chatroom.service';
import { Router } from '@angular/router';

import { forkJoin, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Activity } from '../../../../../bo9-shared/models/activity/activity.model';
import { BO9UUID } from '../../../../../bo9-shared/models/UUID.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { MatCardModule as MatCardModule } from '@angular/material/card';
import { ArangoService } from 'src/services/arangoservice/arango.service';


@Component({
  selector: 'app-dialog-activity',
  templateUrl: './activityDialog.component.html',
  styleUrls: ['./activityDialog.component.scss']

})

export class ActivityDialogComponent implements OnInit  {
  disableBtn = false;
  user: Person;
  subscriptions: Subscription[] = [];
  constructor(
    private dialogRef: MatDialogRef<ActivityDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: { activity: Activity } ,
    private route: ActivatedRoute,
    private auth: AuthService,
    private arangoService: ArangoService,
    private router: Router,
   ) {
      console.log ('Init activity ' + JSON.stringify(data));
      this.user = this.auth.getAuthUser();
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'ActivityDialogComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
     }

  ngOnInit() {

    console.log ('Init activity init ' + JSON.stringify(this.data));
    this.user = this.auth.getAuthUser();

    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #001'); 
      this.router.navigate(['login'] );
    }

  }


  deleteSelectActivity(p: Activity ) {
    this.dialogRef.close(p);
  }

  close() {
    this.dialogRef.close();
  }
}
