import { Component, OnDestroy, Input, OnChanges, Output, EventEmitter, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { firstValueFrom, Subscription } from 'rxjs';
import { canContact, contactFullName, mkMTContact } from '../../../../../bo9-shared/models/contact/contact.model';
import { validationMessages } from 'src/helpers/form.helper';
import { Event, EventAttendee, MTTransaction_Detail, Set_Transaction, attendeeUnique } from '../../../../../bo9-shared/models/event/event.model';
import { createUUID } from '../../../../../bo9-shared/uuid.service';
import { DateToYYMMDDhhmmss, OnlyTimePart } from '../../../../../bo9-shared/helpers/time.helper';
import { EventDBService } from 'src/services/events/eventdb.service';
import { EventEmitterService } from 'src/services/events/events.service';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { formPerson } from '../../../../../bo9-shared/models/person/formPerson.model';
import { MatDialog } from '@angular/material/dialog';
import { SelectPersonDialogComponent } from 'src/displayComponents/dialogs/selectPersonDialog/selectPersonDialog.component';
import { findInvalidControlsRecursive } from '../../../helpers/form.helper';
import { addPhone, existingPhoneNumber } from '../../../../../bo9-shared/models/contact/phone.model';
import { BO9Address, existingAddress, mkMTAddress } from '../../../../../bo9-shared/models/contact/address.model';
import { GenderTypes, Person, getGender, personFromAttendee } from '../../../../../bo9-shared/models/person/person.model';
import { SearchService } from 'src/services/search/search.service';
import { primaryEmail } from '../../../../../bo9-shared/models/contact/contact.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/services/auth/auth.service';
import { userIsAdmin } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';


export interface AttendeeFormValues {
  email: string;
  first: string;
  last: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  gender: string;
  }

@Component({
    selector: 'attendee-form',
    templateUrl: './attendeeForm.component.html',
    styleUrls: ['./attendeeForm.component.scss'],
 
  })
  export class AttendeeFormComponent implements OnDestroy, OnChanges, AfterViewInit {
    @Input() event: Event;
    @Input() attendee: EventAttendee;
    @Input() attendees: EventAttendee[];
    @ViewChild('addattendee', {read: ElementRef}) addattendeeDiv: ElementRef;
    @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
    name: string;
    form: FormGroup;
    user: Person;
    subscriptions: Subscription[] = [];
    validationMessages = validationMessages;
  
    genderTypes = GenderTypes;
    selectedPerson: boolean;

    disableBtn = false;
  
    get value(): AttendeeFormValues {
      console.log('name get '+ JSON.stringify(this.form.value));
      return this.form.value;
    }
  
    set value(value: AttendeeFormValues) {
      console.log('name get '+ JSON.stringify(value));
      this.form.setValue(value);
    }

    get firstControl() {
        return this.form.controls.first;
    }

    get lastControl() {
        return this.form.controls.last;
    }
    get phoneControl() {
      return this.form.controls.phone;
    }
    get genderControl() {
      return this.form.controls.gender;
    }

    get emailControl() {
      return this.form.controls.email;
    }
  
  
    constructor(private formBuilder: FormBuilder, 
      private emitterService: EventEmitterService,
      private arangoService: ArangoService,
      private searchService: SearchService,
      private dialog: MatDialog,
      private route: ActivatedRoute,
      private router: Router,
      private auth: AuthService,
      private eventDBService: EventDBService) {

    this.makeForm();
    this.selectedPerson = false;
    this.route.params.subscribe( async (params) => {
       
      console.log(params); // log the entire params object
      console.log(" Params "+params['id']); // log the value of id

      if (params['id']) {
        const result = await firstValueFrom(this.eventDBService.getEventByID(params['id']),{ defaultValue: { content: []}}); // Can only be from wordpress event
        if (result && result.content) {
          this.event = result.content.events[0];
        }
      }
      this.user = this.auth.getAuthUser();
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'AttendeeFormComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
    });

    }

    ngAfterViewInit() {
      this.addattendeeDiv.nativeElement.scrollIntoView();
    }

    isAdmin(): boolean {
     return userIsAdmin(this.user);
    }

    makeForm() {
      this.form = this.formBuilder.group({
        first: [' ', Validators.compose([Validators.required])],
        last: [' ', Validators.compose([Validators.required])],
        email: ['', Validators.compose([])],
        city: ['', Validators.compose([])],
        state: ['', Validators.compose([])],
        phone: ['', Validators.compose([])],
        gender: ['', Validators.compose([])],
        zip: [' ', Validators.compose([])],
        country: [' ', Validators.compose([])],
        _key: ['', Validators.compose([])],
        DNC: [false, Validators.compose([])],
        paid: ["0.00", Validators.compose([])],
      });
      
    }

    ngOnChanges() {
      console.log('OnChange ');
      this.form.reset();
      if ( this.addattendeeDiv) {
        this.addattendeeDiv.nativeElement.scrollIntoView();
      }
      if (this.attendee) {
        this.form.patchValue(this.attendee);
        if (this.attendee.person ) {
          if (this.attendee.person.contact.phone && this.attendee.person.contact.phone.items && this.attendee.person.contact.phone.items[0] && this.attendee.person.contact.phone.items[0].number) {
            this.form.controls.phone.setValue(this.attendee.person.contact.phone.items[0].number);
          } else {
            this.form.controls.phone.setValue('');
          }
          this.form.controls.email.setValue(primaryEmail(this.attendee.person.contact));
          
          this.form.controls.gender.setValue(getGender(this.attendee.person.details.gender?this.attendee.person.details.gender:''));
          this.form.controls.DNC.setValue(canContact(this.attendee.person.contact)?false:true);
        }
        this.form.controls.paid.setValue(this.attendee.paid)
      }
    }

    selectDNC() {
      this.form.controls.DNC.setValue(!this.form.value.DNC);
      console.log('dnc'+ JSON.stringify(this.form.value.DNC));
    }

   async  submitForm() {
      console.log('Submit attendee form ' + JSON.stringify(this.form.value));

      if (this.form.valid) {
        this.disableBtn = true;
        const newAttendee: EventAttendee = {
          _key: this.form.value._key? this.form.value._key : '', // filled in on insert
          person_id: this.form.value._key? this.form.value._key : '', 
          contact: mkMTContact(),
          mailCampaignID: 0,
          event_id: this.event._key,
          paid: this.form.value.paid? this.form.value.paid.replace('$',''): "0.00",
          transaction_details: Set_Transaction("Attendee Form", this.form.value.paid, MTTransaction_Detail()),
          attended: 0,
          email: this.form.value.email,
          first: this.form.value.first,
          last: this.form.value.last,
          street1: '',
          street2: '',
          city: this.form.value.city? this.form.value.city: '',
          state: this.form.value.state? this.form.value.state: '',
          phone: this.form.value.phone? this.form.value.phone:'',
          gender: this.form.value.gender,
          zip: this.form.value.zip? this.form.value.zip: '',
          country: this.form.value.country? this.form.value.country: 'USA',
          start_date: this.event.startDate,
          end_date: this.event.endDate,
          event_time: OnlyTimePart(this.event.startTimeUTC),
          DNC: this.form.value.DNC? this.form.value.DNC: false,
          end_time: "00:00",
          date_created: DateToYYMMDDhhmmss()
          
        }


        if (this.form.value._key && this.form.value._key!=='') {
          newAttendee.person = await this.searchService.searchPersonKey(this.form.value._key, true);
        } else if (newAttendee.email) {
          const persons = await firstValueFrom (this.searchService.searchEmail(newAttendee.email, false));
          if (persons && persons.length > 0) { // Email/Person already exists in DB
            newAttendee.person = persons[0];
          }
        }
       
         
          
          if (this.attendee) { // Passed in, ie was existing in event
            if (this.event.source !== 'calendly') {  // WP Event
              const res = await firstValueFrom(this.eventDBService.updateEventAttendee(newAttendee));
            } else {
              const res = await firstValueFrom(this.arangoService.setEventAttendee(newAttendee)); // does update
            }
            const added = await this.updatePerson(newAttendee);
            this.emitterService.emitter.emit('Message','Attendee updated in Event');
            this.form.reset();
            this.notifyParent.emit('person updated');
          } else { // new to event
            if (!this.attendees || attendeeUnique(newAttendee, this.attendees)) {
              let res: any;
              if (this.event.source !== 'calendly') {  // WP Event
                res = await firstValueFrom(this.eventDBService.setEventAttendee(newAttendee));
                if (!newAttendee.person) {
                  newAttendee._key = res.content.last_id; // Set by the add
                }
              } else {
                if (!newAttendee.person) {
                  newAttendee._key = createUUID(); // Set before the add
                } else if (!newAttendee._key) {
                  newAttendee._key = newAttendee.person._key;
                }
                res = await firstValueFrom(this.arangoService.setEventAttendee(newAttendee));

              }
              newAttendee.person = await this.updatePerson(newAttendee);
              if (this.attendees) {
                this.attendees.push(newAttendee);  // Local check to make sure they don't add the same person twice
               } else {
                this.attendees = [newAttendee];
               } 
               this.emitterService.emitter.emit('Message','Attendee '+ contactFullName(newAttendee.person.contact) + ' added to Event');
              this.form.reset();
              if (this.notifyParent) { this.notifyParent.emit('Attendee '+ contactFullName(newAttendee.person.contact) + ' added to Event'); }
            } else {
              alert ("Can't update attendee with an name/email already used by another attendee "
              + this.form.value.first + ' '+this.form.value.last + ':' + this.form.value.email);
            }
          }
          this.disableBtn = false;
 
      } else {
        alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.form)));
      }
    }

  
    ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
    }


    resetForm() {
      this.form.reset();
      this.disableBtn = false;
    }

    

    selectPerson() {
      
        const dRef = this.dialog.open(SelectPersonDialogComponent, {
          width: '75%',
          height: '75%',
          data: {group: null, grouped: false}
        });
    
        dRef.afterClosed()
        .subscribe(  (result: formPerson[]) => {
          this.selectedPerson = true;
          console.log('Selected people is ' + JSON.stringify(result));
          if (result && result.length > 0) { // Only take the first
            
            this.form.controls.first.setValue(result[0].firstName);
            this.form.controls.last.setValue(result[0].lastName);
            this.form.controls.email.setValue(result[0].email);
            this.form.controls.state.setValue(result[0].state);
            this.form.controls.zip.setValue(result[0].zip);
            this.form.controls.city.setValue(result[0].city);
            this.form.controls._key.setValue(result[0]._key);
            this.form.controls.gender.setValue(result[0].gender);
            this.form.controls.phone.setValue(result[0].phone);
            this.form.controls.country.setValue(result[0].country? result[0].country: 'USA');
            this.form.controls.DNC.setValue(result[0].DNC?result[0].DNC: false)

          }
    
        })

    }
  
    reset() {
      this.form.reset();
    }

    updatePerson(attendee: EventAttendee): Promise<Person> {
      return new Promise ( async (resolve, reject) => {
        if (attendee.person) {
          const person = attendee.person;
          person.contact.names.name.first = this.form.value.first;
          person.contact.names.name.last = this.form.value.last;
          if (person.details) {
            person.details.gender = attendee.gender;
          } else {
            person.details = {
              gender : attendee.gender
            }
          }
          person.contact.email.DNC = attendee.DNC;
          if (!existingPhoneNumber(person.contact.phone,attendee.phone)) {
            person.contact.phone = addPhone(person.contact.phone,attendee.DNC, attendee.phone)
          }
          person.latestEvent = { // Set this so the update knows that its an event based update
            event_id: this.event._key,
            event_date: this.event.startDate
          }
          const newAddr: BO9Address = {
            _key: createUUID(),
            name: '',
            altitude: 0,
            latitude: 0,
            longitude: 0,
            street1: '',
            street2: '',
            city: attendee.city,
            state: attendee.state,
            zip: attendee.zip,
            timezone: this.event.timezone,
            country:  attendee.country,
          }
          if (!existingAddress(person.contact.address, newAddr)) {
            person.contact.address.items.push(newAddr);
          }
          console.log('Update exisitng person to ' + JSON.stringify(person));
          const res = await firstValueFrom(this.arangoService.updatePerson(person));
          resolve(person);
        } else { // New person
          const p: Person = personFromAttendee(attendee);
          p.latestEvent = { // Set this so the update knows that its an event based update
            event_id: this.event._key,
            event_date: this.event.startDate
          }
          const res = await firstValueFrom(this.arangoService.updatePerson(p));
          resolve(p);
        }
      });
    }
  }