import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { Routes, ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'errrors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss']
})
export class ErrorsComponent {

  routep: any;

  constructor(
    public router: Router,
    private location: Location
  ) { 

  }
}
