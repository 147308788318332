<div class="selectCustomer-modal">


  <mat-card class="bo9mat">

    <mat-card-content>
        <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef> Select </th>
          <td mat-cell *matCellDef="let element">
            <button type="button" (click)="selectFormCustomer(element)" matTooltip="Select"> <mat-icon>check_circle_outline</mat-icon></button>
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
          <td mat-cell *matCellDef="let element"> 

            <span (click)="selectSingleCustomer(element)">{{element.email}} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.first_name}} {{element.last_name}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"  [ngClass]="{'make-gold': row.selected }"></tr>
      
        </table>
    </mat-card-content>
  </mat-card>
  <footer>
    <button mat-stroked-button (click)="close()">
      Cancel
    </button>
    <button class="btn-save" mat-flat-button
      (click)="selectAndClose()">
      Add Customers for Order Listing
    </button>
  </footer>
</div>
