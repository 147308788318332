export function toTitleCase(str: any) {
	return str.replace(
		/\w\S*/g,
		function(txt: any) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		}
	);
}

export function replaceHTMLCommentTag(body: string, tagName: string, text:string) {
	const tag = `<!-- ${tagName} -->`;
	var re = new RegExp(tag, "g");
	return body.replace(re, text);
}

export function replaceHTMLPercentTag(body: string, tagName: string, text:string): string {
	const tag = `%${tagName.toLowerCase()}%`;
	const re = new RegExp(tag, "ig");
	return body.replace(re, text);
}

export function matchAll(str: string, regExp: RegExp): string[] {
	const matches:string[] = [];
	let result;
	while ((result = regExp.exec(str)) !== null) {
		if (result !== null) {
			const res = result[0] as string;
			matches.push(res);
		}
	}
	return matches;
}

export function substitutesMatch(content: string, substitutes: string[]) : string[] {
	const regexp = new RegExp('%[a-zA-Z_]*?%','g');
	const matches = matchAll(content,regexp);
	const failures: string[] = [];
	const lsub: string[] = [];

	if (!substitutes) {
		substitutes = [];
	}
	for (const sub of substitutes) {
		lsub.push('%'+sub.toLowerCase()+'%');
	}
	for (const toMatch of matches) {
		console.log('Match these '+JSON.stringify(toMatch));
		if ( ! lsub.includes(toMatch.toLowerCase())) {
			failures.push(toMatch);
		}
	}
	console.log('failures '+JSON.stringify(failures));
	return failures;
}

export function zeroPad(num: number, numZeros: number) {
	var n = Math.abs(num);
	var zeros = Math.max(0, numZeros - Math.floor(n).toString().length );
	var zeroString = Math.pow(10,zeros).toString().substr(1);
	if( num < 0 ) {
		zeroString = '-' + zeroString;
	}
	return zeroString+n;
}


export function makeRandomText(len: number, basechar: string): string {
	let base = '';
	let result = '';
	for (let i=0; i < len; i++) {
		base = base +basechar.charAt(0);
	}
	for ( var i = 0; i < base.length; i++ ) {
		result += String.fromCharCode(base.charCodeAt(i) + Math.floor( Math.random() * 26 ))
	}
	return result;
};

export interface SplitName {
	first: string,
	last: string;
 }

 // takes first 2 words
export function nameFromString( name: string) : SplitName {
	const result:SplitName = {
		first: '',
		last: ''
	}
	if (name) {
		const nA = name.split(/\s+/);
		result.last = nA[1];
		nA.pop();
		result.first = nA[0];
	}
	return result
}

export function noHtml(data:string, filter:any){
	if ('entities' in filter) {
		if (filter['entities'] == 'spaces') {
			data = data.replace(/\u00a0/ig, ' ');
			data = data.replace(/&nbsp;/ig, ' ');
		}
	}
	return data.replace(/<[^>]+>/ig, '');
}

export function removeString(strings: string[], remove: string): string[] {

    var res: string[];

    res = [];

    if (strings) {
        strings.forEach(element => {
            if (element != remove) {
                res.push(element);
            }
        });
    }


    return res;
    
}