
import { networkConfig } from 'src/config/webapp.config';
import { BO9EVENTS } from '../../../../bo9-shared/models/BO9_base/BO9_base.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Person } from '../../../../bo9-shared/models/person/person.model';
@Injectable()
export class InsitututeService {

    constructor (
      private http: HttpClient
    ) {

      // hack not empty
    }



    public getwpuserbyemail(email: string) {
      return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WPUSERBYEMAIL+"/?email="+email);
    }

    public getwpuserbyid(id: string) {
      return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.WPUSERBYID+"/?id="+id);
    }

    public getOrders(search?: string) : Observable<any> {
      let param = '';
      if (search) {
        param = '/?search='+search;
      }
      return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.STUDENT+param);
    }

    public getInstituteStudent(search?: string) : Observable<any> {
      let param = '';
      if (search) {
        param = '/?search='+search;
      }
      return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.INSTITUTESTUDENT+param);
    }
    public getInstituteCourse(search?: string) : Observable<any> {
      let param = '';
      if (search) {
        param = '/?search='+search;
      }
      return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.INSTITUTECOURSE+param);
    }
    public getInstituteTrack(search?: string) : Observable<any> {
      let param = '';
      if (search) {
        param = '/?search='+search;
      }
      return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.INSTITUTETRACK+param);
    }

    public deleteStudent(id: string) : Observable<any> {
      return this.http.delete(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.STUDENT+"/?id="+id);
    }

    public addStudent(email: string, create: boolean, user_id? : number) : Observable<any> {
      const param = {
        email: email,
        create: create,
        user_id: user_id
      }
      return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.STUDENT, param);
    }

    public enrollStudent(p: Person) {
      const param = {
        person: p
      }
      return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.ENROLLINSTITUTESTUDENT, param);
    }


  
}
