import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { Routes, ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotFoundComponent {

  routep: any;

  constructor(
    public router: Router,
    private location: Location
  ) { 
    this.routep = router.events.subscribe(params => {

      console.log('Location was ' + JSON.stringify(location.path()));
    });
  }

  ngOnDestroy() {
    if (this.routep) {
      this.routep.unsubscribe();
    }
  }
}
