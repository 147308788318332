<h2> {{ firstname }} Edit your Group....</h2>
<hr>
<form [formGroup]="GroupForm" (ngSubmit)="submitGroupForm()" class="relate-form">
	<div >
		<mat-form-field appearance="outline" class="full-width">
			<mat-label>Group Name</mat-label>
			<input matInput formControlName="groupName" required />
		</mat-form-field>
	</div>
	<div>
		<mat-form-field appearance="outline">
			<mat-label>Group Of...</mat-label>
			<mat-select matNativeControl  formControlName="groupType" required>
				<mat-option *ngFor="let reltype of groupTypes" [value]="reltype">
					{{ reltype }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Submit</button>
</form>
