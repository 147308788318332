import { ArangoNodeType, ArangoRevType, ArangoKeyType, BO9UUID } from "../arango/arango.model";
import { Meta } from "../BO9_base/meta.model";
import { ActiveCampaignDeal } from "../mailCampaign/activeCampaign.model";
import { NaturalNumber } from '../naturalNumber/naturalNumber.model';
import { Person } from './person.model';

export enum InvitedStatus {
  Invited = "Invited",
  Accepted = "Accepted",
  Declined = "Declined",
  Unconfirmed = "Unconfirmed",
  Confirmed = "Confirmed"
}

export interface InvitedPerson {
  first: string,
  last: string,
  email: string,
  whenInvited: string,
  accept?: string; // this is passed around, but the lastchar version of confirmationNumber MUST match or its a fake
  decline?: string;
  inviteTo?: string;
  inviteBy?: string;
  inviteDeal?: ActiveCampaignDeal;
  id?: string;
  link?: string;
}
export interface FormInvite {
    _key: string;
    fromFirstName: string,
    fromLastName: string,
    fromName?: string, // depr
    toName?: string, // depr
    toFirstName?: string,
    toLastName?: string,
    toEmail?: string,
    inviteType?: string[],
    pearlModule?: string[],
    naturalNumber?: string,
    sourceType?: string,
    invite?: Invite,
    fromPerson: Person,
    toPerson?: Person,
    meta?: Meta
  }

export interface Invite {
    _key: BO9UUID;
    from: string;
    to: string;
    invited: NaturalNumber;
    identified?: NaturalNumber; // We actually know this to be true
    asserted?: NaturalNumber; // The invitee thinks they are this
    inviteType: string;
    invitedStatus: InvitedStatus,
    invitedPerson?: InvitedPerson,
    pearlModule: string;
    sourceType: string;
    existing: boolean; // Was this relations made with an existing person
    meta: Meta;
}

export function PositiveResponse( i: InvitedStatus): boolean {
  if (i === InvitedStatus.Accepted || i === InvitedStatus.Confirmed) {
    return true;
  }  
  return false;
}

