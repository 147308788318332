<div class="relation-modal">
  <mat-card class="bo9mat">
    <mat-card-header>


    </mat-card-header>
    <mat-card-content>
      <div flex="45">
        Do you really want to delete this relationship with {{ data.relation.toFirstName }} {{ data.relation.toLastName }} (Inverse relationship will not be deleted) ?
      </div>

    </mat-card-content>
  </mat-card>
  <footer>
    <button mat-stroked-button (click)="close()">
      NO!
    </button>
    <button class="btn-save" mat-flat-button
      (click)="deleteSelectRelation(data.relation)">
      Delete Relation
    </button>
  </footer>
</div>
