import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { Pearl, mkMTPearl, PCount } from '../../../../bo9-shared/models/pearl/pearl.model';
import { AuthService } from 'src/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import {  FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ArangoService } from 'src/services/arangoservice/arango.service';
import { validationMessages } from 'src/helpers/form.helper';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { PearlDialogComponent } from 'src/displayComponents/dialogs/pearlDialog/pearlDialog.component';
import { BO9UUID } from '../../../../bo9-shared/models/UUID.model';
import { Person } from '../../../../bo9-shared/models/person/person.model';

@Component({
  selector: 'app-pearls',
  templateUrl: './pearls.component.html',
  styleUrls: ['./pearls.component.scss']
})
export class PearlsComponent implements OnInit, OnDestroy{
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selectedPearl$: Observable<Pearl>;
  messagesLoading$: Observable<boolean>;
  currentPane = 'center';
  files: any ;
  fileControl: FormControl;
  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;

  pearls: Pearl[]=[];
  user: Person;
  pearlid: string;
  pageSize: number;
  PearlForm: FormGroup;

  relatingPersonName: string;
  disableBtn = false;
  validationMessages = validationMessages;

 pearlCount: PCount[];
 subscriptions: Subscription[] = [];
  constructor(
    private arangoService: ArangoService,
    private dialog: MatDialog,
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
    ) 
  {

    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'PearlsComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #027'); 
      this.router.navigate(['login'] );
    }  

    //this.webSocket.initSocket(this.user.wsSocket);

     }



  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  ngOnInit() {
    this.pearls = [];
    this.PearlForm = this.formBuilder.group({
      title: new FormControl('', Validators.compose([Validators.required])),
      
    });
   
    this.fileControl = new  FormControl('',[]);
    this.fileControl.valueChanges.subscribe((files: any) => {
      console.log('File ' + JSON.stringify(files.name))
      if (!Array.isArray(files)) {
        this.files= [files];
      } else {
        this.files = files;
      }
    });
   
  }
  onCloseClick() {
    this.currentPane = 'center';
  }
  tabChanged(event : any) {
    
  }
  loadPearls() {

    this.arangoService.countPearls()
    .subscribe( (result) => {
      this.pearlCount = result.content;

 
      this.arangoService.getPearls(null)
      .subscribe( (result1) => {
        const e = result1.content;
   
        this.pearls = e as Pearl[];
        if (this.pearlid) {
          this.arangoService.getPearlById(this.pearlid)
          .subscribe( (p) => {
            console.log(' Pearl By ID '+ JSON.stringify(p.title));
            this.selectedPearl$ = of(null);
            
            this.selectedPearl$ = of(p);
            this.currentPane = 'left';
          });
        }
        
      });
      
    });

  }

  onClickEdit(p:Pearl) {
    console.log('Expand Pearl  Contact '+ JSON.stringify(p.title));
    this.selectedPearl$ = of(null);
    
    this.selectedPearl$ = of(p);
    this.currentPane = 'left';
  }

  addPearlClick() {
    this.selectedPearl$ = of(null);
    this.currentPane = 'left';
  }


  onRefreshClick() {
    console.log('Refresh click ');
    this.loadPearls();

  }


  pearlEditHandler($event: Pearl) {
    console.log(' Pearl  edit '+ JSON.stringify($event));
    this.selectedPearl$ = of($event);
    this.currentPane = 'left';
  }

  pearlSubmittedHandler($event: any) {
    console.log(' Pearl  submitted '+ JSON.stringify($event));
    this.currentPane = 'center';
    this.loadPearls();
  }

  submitPearlForm() {
    console.log('Submit Pearls file ');
      this.arangoService.addPearlFile(this.PearlForm.value.title, this.files? this.files[0]: [])
      .subscribe( (res) => {
        console.log('Pearl file response ' + JSON.stringify(res));
      })
  }
  
  

}
