import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';


import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { MatTableDataSource} from '@angular/material/table';
import { formPerson,} from '../../../../../bo9-shared/models/person/formPerson.model';

import { SearchService } from 'src/services/search/search.service';
import { dateFormating } from '../../../../../bo9-shared/helpers/time.helper';
import { EventDBService } from 'src/services/events/eventdb.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { EventAttendee, Event } from '../../../../../bo9-shared/models/event/event.model';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { ProgramDefinition } from '../../../../../bo9-shared/models/program/program.model';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth/auth.service';


@Component({
  selector: 'app-dialog-emailList',
  templateUrl: './emailListDialog.component.html',
  styleUrls: ['./emailListDialog.component.scss'],

})

export class EmailListDialogComponent implements OnInit  {

  public displayedColumns = ['select', 'email', 'name', 'naturalNumber', 'source', 'identified', 'primary','admin'];
  public dataSource = new MatTableDataSource<EventAttendee>();

  user: Person;
  formPersons: formPerson[];
  disableBtn = false;
  selected: formPerson[] = [];
  searchString: string;
  loading: boolean;
  fullList : string = '';

  subscriptions: Subscription[] = [];
  constructor(
    private dialogRef: MatDialogRef<EmailListDialogComponent>,
    private eventdbService: EventDBService,
    private router: Router,
    private auth: AuthService,
    private arangoService: ArangoService,
    @Inject(MAT_DIALOG_DATA)  public data: { event: Event, programs: ProgramDefinition[]} ,
    private searchService: SearchService,
   ) {
      console.log ('Init select EmailListDialogComponent ' + JSON.stringify(data));

        this.displayedColumns = ['name', 'email'];
     
        this.user = this.auth.getAuthUser();
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions.push(this.arangoService.recordVisit({
          page: this.router.url, component: 'EmailListDialogComponent', 
          visitor: this.user._key,
          help: false
        }).subscribe ({next: () => {}})); 

     }

     dateFormating(date: any, kind: string) {
      return dateFormating(date, kind);
    }

  ngOnInit() {

    console.log ('Init select EmailListDialogComponent init ' + JSON.stringify(this.data));

    this.dataSource.data = [];
    this.getEvents();
  }


  getEvents() {

    this.formPersons = [];
    this.dataSource.data = [];
    this.loading = true;
    this.subscriptions.push( this.eventdbService.getEventByID(this.data.event._key)
    .subscribe({next:  async  (res: any) => {
     console.log('search  ' + JSON.stringify(res));
      if (res && res.content.events && res.content.events.length > 0) {
        const attendees = await firstValueFrom(this.eventdbService.getEventAttendees(this.data.event._key),{ defaultValue: { content: []}});
        console.log('Got '+JSON.stringify(attendees.content));
        this.fullList = '';
        let attends: EventAttendee[] = [];
        for (const att of (attendees.content as EventAttendee[])) {
          if (att.attended && att.attended != 0) {
            const allInfo: Person [] = await firstValueFrom(this.searchService.searchEmail(att.email, false));
            if (allInfo && allInfo.length > 0) {
              let programName  = '';
              const person = allInfo[0];

              if (person.programs && person.programs.length > 0) {
                for (const progs of person.programs) {

                    //  if (progs.program && this.data.event.categories.includes(progs.program.name)) {  // Persons program category matches this event category
                        this.fullList += att.email+';'
                         attends.push(att);
                        this.dataSource.data = attends;
                        break; // Only 1 required
                   //   }

                }
              }

             
            

             
            }

          }

        }
        this.loading = false;

        
      }
      this.loading = false;
      
    }}))

  }



  close() {
    this.dialogRef.close([]);
  }
}
