

<h3><span *ngIf="registered">Registered </span><span *ngIf="attended">Previously Registered </span>Events</h3>
	<mat-card class="bo9mat" class="flex" style="box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); margin-top:0; padding-top:10px;">
		<table mat-table [dataSource]="dataSource" matSort>

			 <ng-container matColumnDef="_key">
				<th mat-header-cell *matHeaderCellDef> Key </th>
				<td mat-cell *matCellDef="let element"> <mat-icon (click)="showKey(element._key)" matTooltip="{{element._key}}">key</mat-icon></td>
			 </ng-container>

			 <ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
				<td mat-cell *matCellDef="let element">
					<span *ngIf="!isMobile()">
					<mat-icon style="float:right; color:#666;" matTooltip="{{element.description}}">tab</mat-icon></span> {{element.name}}
				</td>
			 </ng-container>

			 <ng-container matColumnDef="startDate">
				<th mat-header-cell *matHeaderCellDef  mat-sort-header> Start </th>
				<td mat-cell *matCellDef="let element"> {{element.startDate}} </td>
			 </ng-container>

			 <ng-container matColumnDef="timezone">
				<th mat-header-cell *matHeaderCellDef  mat-sort-header> Time </th>
				<td mat-cell *matCellDef="let element"> {{element.startTime}} </td>
			 </ng-container>

			 <ng-container matColumnDef="category">
				<th mat-header-cell *matHeaderCellDef  mat-sort-header> Categories </th>
				<td mat-cell *matCellDef="let element"> {{element.categories.join(', ')}}</td>
			 </ng-container>
			 <ng-container matColumnDef="link">
				<th mat-header-cell *matHeaderCellDef  mat-sort-header> Event Link </th>
				<td mat-cell *matCellDef="let element"> 
					<span *ngIf="element.linkUrl">
					<button mat-button [cdkCopyToClipboard]="element.linkUrl"><mat-icon >content_copy</mat-icon></button><a href="{{element.linkUrl}}" target="_blank">Click: {{ element.linkUrl }}</a></span></td>
			 </ng-container>
			 <ng-container matColumnDef="clientlink">
				<th mat-header-cell *matHeaderCellDef  mat-sort-header> Client Link (Copy)</th>
				<td mat-cell *matCellDef="let element">
					<span >
					<button mat-button [cdkCopyToClipboard]="'https://bodyof9.com/event-registration'+'/?ee='+element._key"><mat-icon >content_copy</mat-icon></button>
					https://bodyof9.com/event-registration/?ee={{element._key}}
					</span>
				</td>
			</ng-container>

			 <ng-container matColumnDef="attended">
				<th mat-header-cell *matHeaderCellDef  mat-sort-header> Attended </th>
				<td mat-cell *matCellDef="let element"> {{ element.attended? 'yes' : 'no'}}  </td>
			 </ng-container>

			 <ng-container matColumnDef="emails">
				<th mat-header-cell *matHeaderCellDef  mat-sort-header> Emails </th>
				<td mat-cell *matCellDef="let element"> <span *ngIf="element.attended"><mat-icon (click)="showEmailList(element)">recent_actors</mat-icon></span> </td>
			 </ng-container>

			 <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			 <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

		</table>
		<mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]"  (page)="onChangePage($event)"></mat-paginator>
</mat-card>
