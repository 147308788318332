import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Capabilities } from '../../../../bo9-shared/models/person/capabilities.model';

import { Person } from '../../../../bo9-shared/models/person/person.model';
import { UserService } from './session.service';
import decode from 'jwt-decode';
import { checkCapable } from '../../../../bo9-shared/helpers/auth.helper';

// HACK HACK HACK TODO REMOVE (for sessionstorage)
declare const require: any;

@Injectable()
export class AuthService {

 sessionStorage = require('sessionstorage');

 public authUser: Person;

 everAuthenticated: boolean;

  constructor(
    private userService: UserService,
  ) {
    //
    this.everAuthenticated = false;
  }
  // ...
  public isAuthenticated(): boolean {
    const helper = new JwtHelperService();
    const token = this.getSessionToken();

    if (!token) {
      // Token not set at all -- so return (isTokenExpired can't handle null tokens)
      console.log('No session JWT ');
      return(false);
    }
    const decodedToken = helper.decodeToken(token);
   // console.log('Decoded session JWT ' + JSON.stringify(decodedToken));

    if (decodedToken.userid === 'app') { // APP Registered but not logged in
      return false;
    }
    const expirationDate = helper.getTokenExpirationDate(token);
    // Check whether the token is expired and return
    // true or false

    const authenticated: boolean = !helper.isTokenExpired(token);

    if (authenticated) {
      this.everAuthenticated = true;
    }

    return authenticated;
  }

  public checkCapable( role: Capabilities) {
    const token = this.getSessionToken();

    if (!token) {
      // Token not set at all -- so return (isTokenExpired can't handle null tokens)
      console.log('No session JWT ');
      return(false);
    }
    const tokenPayload:any = decode(token);
    const isCapable = checkCapable(role,tokenPayload)

    if (isCapable) {
      this.everAuthenticated = true;
    }

    return isCapable
  }

  public wasEverCapable(): boolean {
    return this.everAuthenticated;
  }

  public setSessionToken(t: any) {
     // console.log('Set session JWT ' + JSON.stringify(t));
      this.sessionStorage.setItem('token', t);
  }

  public getSessionToken(): any {
    //console.log('Get session JWT  for user ' + this.getUsername());
    return(this.sessionStorage.getItem('token'));
  }

  public clearSessionToken() { // Force user log off
    this.sessionStorage.removeItem('token');
  }

  public setAuthUser(u: Person) {
   // console.log('Set Auth User' + JSON.stringify(u));
    this.sessionStorage.setItem('authUser', JSON.stringify(u));
    this.userService.set(u._key);
}

  public getAuthUser(): Person {
	 // console.log('Auth '+this.sessionStorage.getItem('authUser'));
   if (this.sessionStorage.getItem('authUser')) {
    return(JSON.parse(this.sessionStorage.getItem('authUser')));
   }
    return(null);
  }

  public getUsername(): string {
    const u: Person = this.getAuthUser();
    if (!u || u === undefined) {
      return('');
    } else {
      return(u.contact.names.name.first);
    }
  }




}
