<header>
  <div class="layout-row">
    <!-- Logo -->
    <a class="BO9-logo" (click)="mobile = false" routerLink='/profile/Edit'>BO9</a>
  

    <nav class="layout-row" [ngClass]="{ 'show-nav': mobile }">
    </nav>
  </div>
</header>

<!-- Overlay for mobile menu -->
<div class="menu-overlay" *ngIf="mobile" (click)="mobile = false"></div>
