import { BO9UUID } from "../UUID.model";
import { BO9Who } from "../contact/contact.model";
import { DateStringType, URLstring } from "../BO9_base/BO9_base.model";
import { Meta } from "../BO9_base/meta.model";

export interface ProductSize {
    lengthUnits: string;
    height: number;
    width: number;
    depth: number;
    weightUnits: string;
    weight: number;
}

export interface BookDetail {
    ID: BO9UUID;
    ISBN: string;
    ISBN10: string;
    price: number;
    author: BO9Who;
    publisher: string;
    pubDate: DateStringType;
    edition: string;
    pages: number;
    language: string;
    size: ProductSize;
    sources: string[];
}

export interface PriceTag {
    size: string;
    price: number;
    color: string;
    sizeDetail: ProductSize;
}

export interface ClothingDetail {
    manufacturer: string;
    sizes: PriceTag[];
}

export interface multiProduct { 
    orderId?: string;
    productInfo: Product, 
    userData?: ModuleDetail, 
    productIndex: number, 
    orderIndex: number};

export interface ModuleDetail {
    duration: string;
    selectList: string;
    date_started?: string; // Set upon purchase
    date_renewed?: string;
    date_end?: string;
    status: string;
}
export interface Product {
    _key?: BO9UUID;
    externalid: string; 
    name: string; // product name
    price: string;
    quantity: number;
    sku: string; // Long form Tim
    category: string;
    title?: string;
    status?: string;
    subTitle?: string;
    moduleDetail?: ModuleDetail;
    bookDetail?: BookDetail;
    clothingDetail?: ClothingDetail;
    description: string;
    images?: URLstring[];
    program?: string;
    used?: number;
    meta?: Meta;
    selected?: boolean;
}

export function getProductFromSKU( sku: string, products: Product[]) : Product | null{
    //console.log("sku" +sku);
    try {
        for (const p of products) {
            if (p.sku === sku) {
                return p;
            }
        }
    } catch(e) {
        console.error(JSON.stringify(e))
    } 
    return null;
}

export function makeDefaultPearl(): Product {
    const p: Product = {
        _key:"239425",
        externalid:"239425",
        name:"my-pearls",
        price:"0.0000",
        quantity:0,
        status:"publish",
        sku:"SUBMP001",
        category:"Pearl Module",
        title:"My Pearls",
        description:"Product that lets a person receive Pearls about their Natural Number - Auto Add",
        moduleDetail :{"duration":"Lifetime","selectList":"","status":""}
      }
      return p;
}
