  
 			<span>
        <mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="30" class='spin'> </mat-progress-spinner>
      </span>

   <button mat-stroked-button (click)="onRefreshClick()">Refresh<mat-icon>refresh</mat-icon></button> 
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="page">
            <th mat-header-cell *matHeaderCellDef> Page </th>
              <td mat-cell *matCellDef="let element"> {{element.page}} </td>
          </ng-container>

          <ng-container matColumnDef="component">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Component</th>
            <td mat-cell *matCellDef="let element"> {{element.component}} </td>
          </ng-container>
          <ng-container matColumnDef="when">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> When </th>
            <td mat-cell *matCellDef="let element"> {{element.when}} </td>
          </ng-container>
          <ng-container matColumnDef="who">
            <th mat-header-cell *matHeaderCellDef> Who </th>
              <td mat-cell *matCellDef="let element"> {{element.visitor}} </td>
          </ng-container>
          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef> Version </th>
              <td mat-cell *matCellDef="let element"> {{element.version }} </td>
          </ng-container>
          <ng-container matColumnDef="error">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Error </th>
            <td mat-cell *matCellDef="let element"> {{element.error}} </td>
          </ng-container>
   

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
          </table>
          <mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]" (page)="onPageChange($event)"> </mat-paginator>
