<div [formGroup]="form">
  <div>
        <mat-form-field  appearance="outline" >
          <mat-label>Identified At</mat-label>
          <input matInput 
            formControlName="setAt"
            id="setAt"
            required
          />

        </mat-form-field>
    

        <mat-form-field  appearance="outline" >
          <mat-label>Identified By</mat-label>

            <mat-select matNativeControl  formControlName="setBy"  multiple>
              <mat-option *ngFor="let reltype of identifiers" [value]="reltype">
                {{ reltype }}
              </mat-option>
            </mat-select>


        </mat-form-field>
 
       
        <mat-form-field appearance="outline" >
          <mat-label>Natural Number</mat-label>
          <mat-select matNativeControl  formControlName="naturalNumber" required>
            <mat-option *ngFor="let nns of nnKeys" [value]="nns">
              {{ nns }}
            </mat-option>
          </mat-select>
          </mat-form-field>

        <mat-form-field  appearance="outline" >
          <mat-label>Date Identified</mat-label>
          <input matInput 
            formControlName="dateSet"
            id="dateSet"
            required
            type="date"
            [matDatepicker]="dateSet"
          />
        <mat-datepicker #dateSet></mat-datepicker>
        </mat-form-field>
    </div>
    </div>