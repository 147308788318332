import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';

import { Observable, of } from 'rxjs';

import { EventEmitterService } from 'src/services/events/events.service';
import { MatDialog } from '@angular/material/dialog';
import { NotifyDialogComponent } from '../dialogs/notifyDialog/notifyDialog.component';

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss']
})
export class NotifyComponent implements OnInit {

  message$: Observable<string[]>;
  messages: string[];
  allNotices = false;
  constructor(
    private emitterService: EventEmitterService,
    private dialog: MatDialog
  ) { 
    this.messages = [];
  }

  ngOnInit() {
    this.emitterService.emitter.on('Message', (msg) => {
      console.log('Message occurred '+JSON.stringify(msg));
      this.messages.unshift(msg);
      this.messages = this.messages.slice(0,9); // only last 10
      this.message$ = of(this.messages);
  });
  }


  showNotices() {
    const dRef = this.dialog.open(NotifyDialogComponent, {
      width: '55%',
      height: '65%',
      data: {messages: this.messages}
    });
  }
  
}
