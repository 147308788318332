import { Person } from '../../../../bo9-shared/models/person/person.model';
import { Event, EventAttendee, EventParams, ShortAttendee } from '../../../../bo9-shared/models/event/event.model';

import webappConfig from '../../../../bo9-shared/config/webappconfig.json';
import { BO9EVENTS } from '../../../../bo9-shared/models/BO9_base/BO9_base.model';
import { Observable, of } from 'rxjs';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pearl, PearlSearch } from '../../../../bo9-shared/models/pearl/pearl.model';
import { Page, Visit } from '../../../../bo9-shared/models/page/page.model';
import { HTMLTemplate } from '../../../../bo9-shared/models/htmltemplate/htmltemplate.model';
import { Movement } from '../../../../bo9-shared/models/movement/movement.model';
import { formPerson } from '../../../../bo9-shared/models/person/formPerson.model';
import { DateToYYMMDDhhmmss, OnlyTimePart } from '../../../../bo9-shared/helpers/time.helper';
import { AuthService } from '../auth/auth.service';
import {  OrderTrack } from '../../../../bo9-shared/models/purchase/purchase.model';
import { Resource, ResourceSearch } from '../../../../bo9-shared/models/resource/resource.model';
import { Email } from '../../../../bo9-shared/models/person/email.model';
import { webAppVersion } from 'src/config/webapp.config';
import { CertificateType, PersonCertificate } from '../../../../bo9-shared/models/certification/certification.model';
import { Enrolled } from '../../../../bo9-shared/models/institute/student.model';
import { Credit } from '../../../../bo9-shared/models/institute/credits.model';
import { Agreement } from '../../../../bo9-shared/models/person/agreements.model';
import { UUIDToID } from '../../../../bo9-shared/uuid.service';
@Injectable()
export class ArangoService {

  private customHttpClient: HttpClient;

  constructor(private http: HttpClient, private auth: AuthService, backend: HttpBackend) { 
    this.customHttpClient = new HttpClient(backend);
  }



  public getHelpPages():  Observable<any>  {
    //console.log('Count collections');
    return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.HELP);
}

public lastVisitComponent:string[]=[];


public getPrograms():  Observable<any>  {
  //console.log('Count collections');
  return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.PROGRAMS);
}

public getMovements():  Observable<any>  {
  //console.log('Count collections');
  return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.MOVEMENTS);
}

public banReset():  Observable<any>  {
  //console.log('Count collections');
  return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.BANRESET);
}

public getErrors(skip: number, limit: number):  Observable<any>  {
  //console.log('Count collections');
  if (!skip) {
    skip = 0;
    if (!limit) {
      limit =20;
    }
  } else if (!limit) {
    limit = 20;
  }
  return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ERROR+'/?offset='+skip+'&limit='+limit);
}
public getVisit(visit: Visit, skip: number, limit: number):  Observable<any>  {
  //console.log('Count collections');
  if (!skip) {
    skip = 0;
    if (!limit) {
      limit =20;
    }
  } else if (!limit) {
    limit = 20;
  }
  return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.VISIT+'/?search='+visit.visitor+'&component='+visit.component+'&offset='+skip+'&limit='+limit);
}

  public recordSearch(param: Visit, search: string, nn?: number[],capabilities?: string[], skip?: number, limit?: number):  Observable<any>  {


    param.search = {
      search: search,
      otherParam: nn.toString(),
      offset: skip,
      limit: limit,
      capabilites: capabilities
    }

    if (nn.length === 0) {
      param.search.otherParam = null;
    }

    param.version = webAppVersion;

    param.when = DateToYYMMDDhhmmss();

    return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.VISIT, param);
  }

  public searchCertificate(personKey:string):  Observable<any>  {
    //console.log('Count collections');

    return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.CERTIFICATE+'/?pkey='+personKey);
  }

  public deleteCertificate(certKey:string):  Observable<any>  {
    //console.log('Count collections');

    return this.http.delete(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.CERTIFICATE+'/?ckey='+certKey);
  }
  public makeCertificate(cert:PersonCertificate):  Observable<any>  {
    //console.log('Count collections');
   const param = {
      certificate: cert
   }


    return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.CERTIFICATE, param);
  }

  public updateCertificate(cert:PersonCertificate):  Observable<any>  {
    //console.log('Count collections');
   const param = {
      certificate: cert
   }


    return this.http.put(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.CERTIFICATE, param);
  }
  public recordVisit(param: Visit, first?: boolean):  Observable<any>  {
    //console.log('Count collections');
    if (param.error) {
      param.component = this.lastVisitComponent.join(', '); // Assume error came from last 4 componenet visited
    } else {
      this.lastVisitComponent.push(param.component.replace('Component',''));// Shrink by removing component from name
      this.lastVisitComponent = this.lastVisitComponent.slice(-4)
    }
    param.version = webAppVersion;

    param.when = DateToYYMMDDhhmmss();

    if (first) {
      param.first = true;
    }

    return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.VISIT, param);
  }
    public countCollections():  Observable<any>  {
        //console.log('Count collections');
        return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.GETCOUNTS);
    }

    public getServerVersion():  Observable<any>  {
      //console.log('Count numbers');
      return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.VERSION);
  }

    public countNumbers():  Observable<any>  {
      //console.log('Count numbers');
      return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.COUNTNUMBERS);
  }

  public countPearls():  Observable<any>  {
    //console.log('Count Pearls');
    return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.COUNTPEARLS);
}

public getActivity(from: string, to: string, kind: string):  Observable<any>  {

  return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVITY+ '?to='+to+'&from='+from+'&kind='+kind);
}

  public getPearlById(pid: string):  Observable<any>  {

    return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.PEARLBYID+ '?pearlid='+pid);
  }

  public getEventAttendees(event_id: string):  Observable<any>  {
    console.log('get cal att');
    return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.CALENDLYATTENDEES+ '?event_id='+event_id);
  }

  public isEmailRegistered(ev: string, email: string):  Observable<any>  {
    //console.log('get isEmailRegistered');
    return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.CALENDLYATTENDEES + '?event_id='+ev +'&email='+email);
  }

  public getDeals(selected: string,stage: string, orderAsc: boolean, skip?: number, limit?: number):  Observable<any>  {
    if (!skip) {
      skip = 0;
      if (!limit) {
        limit =20;
      }
    } else if (!limit) {
      limit = 20;
    }

    return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVECAMPAIGNDEAL+ '?selected='+selected+'&stage='+stage+'&orderAsc='+orderAsc+'&offset='+skip+'&limit='+limit);
  }
  
  public deleteDeals(deal_id: string):  Observable<any>  {
    console.log('del cal att');
    return this.http.delete(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVECAMPAIGNDEAL+ '?deal_id='+deal_id);
  }


  public getEventsByEmail(email: string):  Observable<any>  {
    console.log('get e by e');
    return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.WPEVENTSBYEMAIL+ '?email='+email);
  }

  public getEventById(id: string):  Observable<any>  {
    console.log('get e by e');
    return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.EVENTSBYID+ '?eventid='+id);
  }
  
  public deleteAttendee(attendee_id: string):  Observable<any>  {
    console.log('del cal att');
    return this.http.delete(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.CALENDLYATTENDEES+ '?attendee_id='+attendee_id);
  }

  public deleteMovement(movement_id: string):  Observable<any>  {
    console.log('del cal att');
    return this.http.delete(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.MOVEMENTS+ '?movement_id='+movement_id);
  }

    public getPearls(p: PearlSearch):  Observable<any>  {
      console.log('get pearls '+JSON.stringify(p));
      const param = {
        pearlsearch: p
      };
      return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.PEARLSEARCH, param);
    }

    public getAgreements():  Observable<any>  {
      console.log('get agreements');
      return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.AGREEMENT);
    }
    public getPages():  Observable<any>  {
      console.log('get pages');
      return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.PAGE);
    }

    public getCredits():  Observable<any>  {
      console.log('get credits');
      return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.INSTITUTECREDIT);
    }


    public getCredit(eventCode: string):  Observable<any>  {
      console.log('get credit');
      return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.INSTITUTECREDIT+'/?eventCode='+eventCode);
    }

    public getTemplates():  Observable<any>  {
      console.log('get templates');
      return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.TEMPLATE);
    }
    public getReferrals():  Observable<any>  {
      console.log('get referrals');
      return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.REFERRALS);
    }

    public getRelations():  Observable<any>  {
      console.log('get relatins');
      return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.RELATION);
    }

    public getURL(filename: string):  Observable<any>  {
      console.log('get url');
      return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.VIDEOURL+'?filename='+filename);
    }

    public getOrderTrackByOrderId(oid: string):  Observable<any>  {
      console.log('get ordertrack by oid');
      return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ORDERTRACK+'?order_id='+oid);
    }
    public getOrderTrackByPersonId(pid: string):  Observable<any>  {
      console.log('get ordertrack by pid');
      return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ORDERTRACK+'?person_id='+pid);
    }

    public addOrderTrack(ot: OrderTrack):  Observable<any>  {
      const param = {
        ordertrack: ot
      };
        return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ORDERTRACK, param);
    }

    public getEventParams(eid: string):  Observable<any>  {
      return this.customHttpClient.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.EVENTPARAMS+'?eid='+eid);
    }

    public getEventParamByEventId(eid: string):  Observable<any>  {
      return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.EVENTPARAMS+'?eventid='+eid);
    }

    public getEventParamsByCreator(eid: string):  Observable<any>  {
      return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.EVENTPARAMS+'?creatorid='+eid);
    }

    public addEventParams(p: EventParams):  Observable<any>  {
      const param = {
        param: p
      };
      return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.SETEVENTPARAMS, param);
    }
  
    public addIdentifier(firstLastname: string):  Observable<any>  {
      const param = {
        param: firstLastname
      };
      return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.IDENTIFIERS, param);
    }
  
    public getIdentifiers():  Observable<any>  {
      console.log('get ide');
      return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.IDENTIFIERS);
    }

    public sendIssue(e: Email):  Observable<any>  {
      const param: any = {
        email: e
      };
     // console.log("email  "+JSON.stringify(param));
      return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ISSUE, param)

    }

    public updateMovement(p: Movement):  Observable<any>  {
      const param = {
        movement: p
      };
        return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.MOVEMENTS, param);
    }
    
    public getIdentifiedBy(name: string):  Observable<any>  {


      return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.IDENTIFIEDBY+'?ider='+name);
    }

    public getResources(p: ResourceSearch):  Observable<any>  {

      const param = {
        resourcesearch: p
      };
      return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.RESOURCESEARCH, param);
    }
    public updateResource(p: Resource, f: File):  Observable<any>  {

    const formData: any = new FormData();
  
        formData.append('files', f);
        formData.append('resource', JSON.stringify(p));
  
          return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.RESOURCE, formData);
    }

    public deleteResource(p: Resource):  Observable<any>  {
        return this.http.delete(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.RESOURCE+'?_key='+p._key);
    }
    public updateEvent(p: Event,  catIDs: string[], catList:string[]):  Observable<any>  {
      const param = {
        event: p,
        catIDs: catIDs,
        catList: catList
      };
        return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.EVENT, param);
    }

    public updateEventMeta(id: string, em: string):  Observable<any>  {
      const param = {
        id: id,
        meta: em
      };
        return this.http.put(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.EVENTMETA, param);
    }


    public deleteEvent(p: Event):  Observable<any>  {
        return this.http.delete(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.EVENT+'?eid='+p._key);
    }

    public updateRoles(person: Person, roles: string[]):  Observable<any>  {
      const param = {
        roles: roles,
        key: person._key
      };
        return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.CAPABILITIES, param);
    }

    public getRoles(p: Person):  Observable<any>  {
        if (!p) {
          return of(null);
        }
        return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.CAPABILITIES+'?key='+p._key);
    }

    public mergeByKey(original: string, toMerge: string):  Observable<any>  {
      const param = {
        original: original,
        tomerge: toMerge
      };
        return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.MERGE, param);
    }

    
    public updatePerson(p: Person, merge?: boolean):  Observable<any>  {
      const param = {
        person: p,
        merge: merge? merge: false // don't merge person
      };

      const user = this.auth.getAuthUser();
      if (p._key === user._key) {
        p.jwt = user.jwt;
        p.userLastConnected = user.userLastConnected;
        p.isLoggedIn = user.isLoggedIn;
        this.auth.setAuthUser(p); // Update this user if the update is me.
      }
        return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.PERSON, param);
    }

    public updatePersonsImage(p: Person, file: File):  Observable<any>  {

      const formData: any = new FormData();

      formData.append('files', file);
      formData.append('person', JSON.stringify(p));

        return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.PERSONIMAGE, formData);
     
    }

    public addPearl(p: Pearl, f: File):  Observable<any>  {
      const formData: any = new FormData();

      formData.append('files', f);
      formData.append('pearl', JSON.stringify(p));

        return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.PEARL, formData);
    }

    public addPearlFile(title: string, f: File):  Observable<any>  {
      const formData: any = new FormData();

      formData.append('files', f);
      formData.append('title', title);

        return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.PEARLFILE, formData);
    }

    public addRecordingFile(event_id: string, f: File):  Observable<any>  {
      const formData: any = new FormData();

      formData.append('files', f);
      formData.append('event_id', event_id);
      formData.append('fileType','mp4');

        return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.RECORDINGFILE, formData);
    }

    public deleteTemplate(t: HTMLTemplate):  Observable<any>  {
      const param = {
        template: t
      };

        return this.http.delete(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.TEMPLATE+'/?template='+t._key);
    }
    public deletePearl(p: Pearl):  Observable<any>  {
      const param = {
        pearl: p
      };

        return this.http.delete(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.PEARL+'/?pearl='+p._key);
    }
    public deletePerson(p: string):  Observable<any>  {
      return this.http.delete(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.PERSON+'/?person='+p);
    }
    public addAgreement(p: Agreement):  Observable<any>  {
      const formData: any = new FormData();
      const files: any[] = [];
      let fileAddedIndex = 0;
      for (let i = 0; i < p.versions.length; i++) {
        p.versions[i].fileAddedIndex = -1; // clear out files from version if not one to load for this version
        if (p.versions[i].file && p.versions[i].file.name) {
          p.versions[i].sourceFileName = p.versions[i].file.name;
          p.versions[i].name = UUIDToID(p._key)+'-'+UUIDToID(p.versions[i]._key)+'-'+p.versions[i].file.name;
          formData.append('files', p.versions[i].file, p.versions[i].file.name);
          p.versions[i].fileAddedIndex = fileAddedIndex; // file adds to this version
          fileAddedIndex++;
        }

      }

      formData.append('agreement', JSON.stringify(p));
       console.log('AGreement '+JSON.stringify(p));

        return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.AGREEMENT, formData);
    }

    public addPage(p: Page, f: File):  Observable<any>  {
      const formData: any = new FormData();

      formData.append('files', f);


      formData.append('page', JSON.stringify(p));

        return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.PAGE, formData);
    }

    public addCredit(p: Credit, f: File):  Observable<any>  {
      const formData: any = new FormData();

      formData.append('files', f);


      formData.append('credit', JSON.stringify(p));

        return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.INSTITUTECREDIT, formData);
    }

    public deleteAgreement(agreement: string):  Observable<any>  {
      console.log('delete agreement');
      return this.http.delete(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.AGREEMENT+'/?agreement='+agreement);
    }
    public deletePage(page: string):  Observable<any>  {
      console.log('delete page');
      return this.http.delete(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.PAGE+'/?page='+page);
    }

    public deleteCredit(creditId: string):  Observable<any>  {
      console.log('delete credit');
      return this.http.delete(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.INSTITUTECREDIT+'/?creditId='+creditId);
    }

    

    public addTemplate(p: HTMLTemplate, f: File):  Observable<any>  {
      const formData: any = new FormData();

      formData.append('files', f);


      formData.append('template', JSON.stringify(p));

        return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.TEMPLATE, formData);
    }

    public setEventAttendeeAttended(id: string, pid: string, eid: string, start_date: string, value: number, hours: number):  Observable<any>  {
      console.log('set cal EventAttendee');
      const param = {
        id: id,
        attended: value,
        pid: pid,
        eid: eid,
        start: start_date,
        hours: hours
      }
      return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.CALENDLYEVENTATTENDEEATTENDED, param);
    }



    public setEventAttendee(a: EventAttendee):  Observable<any>  {
      let phone = '';

      if (a.person && a.person.contact && a.person.contact.phone && a.person.contact.phone.items && a.person.contact.phone.items.length > 0) {
        phone = a.person.contact.phone.items[0].number;
      }
      const s: ShortAttendee = {
        _key: a._key,
        person_id: a._key,
        event_id: a.event_id,
        first : a.first,
        last : a.last,
        email : a.email,
        paid: a.paid,
        phone: phone,
        attended: a.attended === 1? true: false,
        street1: a.street1,
        street2 : a.street2,
        city : a.city,
        state : a.state,
        zip : a.zip,
        country: a.country,
        start_date : a.start_date,
        end_date : a.end_date,
        event_time : a.event_time,
        end_time : a.end_time,
        date_created : a.date_created   
      }
      const param = {
        attendee: s,
        update: false
      }
      console.log('set cal EventAttendee');
      return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.CALENDLYATTENDEES, param);
    }
    
}
