import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';



import { ActivatedRoute } from '@angular/router';

import { AuthService } from 'src/services/auth/auth.service';

import { Router } from '@angular/router';


import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { MatTableDataSource} from '@angular/material/table';
import { formPerson, makeFormPersonFromPerson } from '../../../../../bo9-shared/models/person/formPerson.model';
import { SearchService } from 'src/services/search/search.service';
import { Subscription } from 'rxjs';
import { ArangoService } from 'src/services/arangoservice/arango.service';

@Component({
  selector: 'app-dialog-selectName',
  templateUrl: './selectNameDialog.component.html',
  styleUrls: ['./selectNameDialog.component.scss'],

})

export class SelectNameDialogComponent implements OnInit  {

  public displayedColumns = ['select', 'email', 'name', 'naturalNumber', 'source', 'identified', 'primary','admin'];
  public dataSource = new MatTableDataSource<formPerson>();

  user: Person;
  formPersons: formPerson[];
  disableBtn = false;
  selected: formPerson[] = [];
  searchString: string;
  loading: boolean;
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private arangoService: ArangoService,
    private router: Router,
    private dialogRef: MatDialogRef<SelectNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: { group: formPerson[], grouped: boolean } ,
    private searchService: SearchService,
   ) {
      console.log ('Init select person ' + JSON.stringify(data));
        this.displayedColumns = ['select','name',  'email', 'naturalNumber'];
        this.user = this.auth.getAuthUser();
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions.push(this.arangoService.recordVisit({
          page: this.router.url, component: 'SelectNameDialogComponent', 
          visitor: this.user._key,
          help: false
        }).subscribe ({next: () => {}})); 
     }

  ngOnInit() {
    this.formPersons = [];
    for (const fp of this.data.group) {
      fp.selected = false;
    }
    console.log ('Init select person init ' + JSON.stringify(this.data));
    this.formPersons= this.data.group;
    this.dataSource.data = this.data.group;

  }



  

  selectFormPerson(p: formPerson ) {
    for (const fp of this.formPersons) {
      if (fp._key ===  p._key) {
        fp.selected = !fp.selected;
       
      }
    }
    this.dataSource.data = this.formPersons as formPerson[];

  }

  selectSinglePerson(p: formPerson ) {
    this.formPersons = [];
    this.dialogRef.close([p]);
  }

  selectAndClose() {
    this.selected = [];
    for (const fp of this.formPersons) {
      if (fp.selected) {
        this.selected.push(fp);
      }
    }
    this.formPersons = [];
    this.dialogRef.close(this.selected);
  }


  close() {
    this.dialogRef.close([]);
  }
}
