    <h3>Event</h3>
    <mat-card class="bo9mat">
    <form [formGroup]="EventForm"  class="personal-form">
    <div *ngIf="event">Key: {{ event?._key }}</div>

    <div *ngIf="event">Attendees Count: {{ event.attendees}}</div>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"> </mat-progress-spinner>

      <mat-form-field  appearance="outline" >
        <mat-label>Event Name</mat-label>
        <input matInput 
          formControlName="name"
          id="name" required
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select matNativeControl  formControlName="status" required>
          <mat-option *ngFor="let reltype of statusList" [value]="reltype">
            {{ reltype }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" >
        <mat-label>Categories</mat-label>
        <mat-select matNativeControl  formControlName="categories" required multiple>
          <mat-option *ngFor="let reltype of catListDB" [value]="reltype">
            {{ reltype }}
          </mat-option>
        </mat-select>
        </mat-form-field>

      <mat-form-field  appearance="outline">
        <mat-label>Start Date (yyyy-mm-dd)</mat-label>
        <input matInput 
          formControlName="startDate"
          id="startDate" required
          (blur)="startSet($event)"
        />
      </mat-form-field>
      <mat-form-field  appearance="outline" >
        <mat-label>Start Time (24:00)</mat-label>
        <input matInput 
          formControlName="startTime"
          id="startTime" required
        />
      </mat-form-field>
      <mat-form-field  appearance="outline">
        <mat-label>End Date (yyyy-mm-dd)</mat-label>
        <input matInput 
          formControlName="endDate"
          id="endDate" required
        />
      </mat-form-field>
      <mat-form-field  appearance="outline" >
        <mat-label>Timezone</mat-label>
        <mat-select matNativeControl  formControlName="timezone">
        <mat-option *ngFor="let reltype of timezones" [value]="reltype">
          {{ reltype }}
        </mat-option>
        </mat-select>
      </mat-form-field>
 
      <mat-form-field  appearance="outline" >
        <mat-label>Event Link URL</mat-label>
        <input matInput 
          formControlName="linkUrl"
          id="linkUrl"
        />
      </mat-form-field>
        <mat-form-field  appearance="outline" >
          <mat-label>Recording URL</mat-label>
          <button mat-button [cdkCopyToClipboard]="EventForm.value.recording_link"><mat-icon >content_copy</mat-icon></button>
          <input matInput 
            formControlName="recording_link"
            id="recording_link"
          />
        </mat-form-field>

        <span *ngIf="event?.recordingStartedAt">Recording Started: {{ event.recordingStartedAt }}</span> <span *ngIf="event?.recordingStartedAt">- Recording Ended At {{ event.recordingEndedAt }} </span> <br />
        <button mat-button [disabled]="disableBtn" (click)="submitEventForm()" class="btn btn-save" mat-submit-button>Submit</button>
      </form>
    </mat-card>
    <mat-card class="bo9mat">
      <mat-progress-spinner *ngIf="loading" mode="indeterminate"> </mat-progress-spinner>
      <span *ngIf="event?._key; else noEvent">
      <form [formGroup]="RecordingForm"  (ngSubmit)="submitRecordingForm()" class="Pearl-form" novalidate >
				 Recording File Upload (Max size 500MB):
				<mat-form-field>
					<ngx-mat-file-input [formControl]="fileControl" ></ngx-mat-file-input>
				</mat-form-field> &nbsp;
  				<button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Load File</button>
			</form>
    </span>
    <ng-template #noEvent><H3>The event must be created and saved before a file can be uploaded</H3></ng-template>
    
</mat-card>
<mat-card class="bo9mat">
      
      <form [formGroup]="URLForm"  class="personal-form">

      <mat-form-field  appearance="outline" >
        <mat-label>Recording filename</mat-label>
        <input matInput 
          formControlName="url"
          id="url" required
        />
      </mat-form-field>
    </form>
    <button mat-button  [disabled]="disableBtn" (click)="submitURLForm()" class="btn btn-save" >RegenURL</button> <br />
       <button mat-button [cdkCopyToClipboard]="newFileName"><mat-icon >content_copy</mat-icon></button> 
      
       <div class="longurl">URL: {{ newFileName }} </div></mat-card>