<H2>Select the ONE PERSON that has this email </H2>
<table mat-table [dataSource]="dataSource" matSort class="full_width">

  <ng-container matColumnDef="select">
	<th mat-header-cell *matHeaderCellDef> Select </th>
	<td mat-cell *matCellDef="let element; let i = index">
	  <span ><button type="button" (click)="onClickSelect(i)" > <mat-icon>check_circle</mat-icon></button></span>
	</td>
  </ng-container>

  <ng-container matColumnDef="name">
	<th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
	<td mat-cell *matCellDef="let element">  {{element.first}} {{element.last}}  </td>
  </ng-container>


  <ng-container matColumnDef="email">
	<th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
	<td mat-cell *matCellDef="let element">
	<span *ngIf="element.email " > {{element.email}}  </span>
	</td>
  </ng-container>
  

  <tr mat-header-row *matHeaderRowDef="colList"></tr>
  <tr mat-row *matRowDef="let row; columns: colList;"></tr>

  </table>

