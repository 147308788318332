import { ArangoKeyType, BO9UUID, ArangoRevType } from "../arango/arango.model";
import { FirstLastName, FullName } from "./name.model";
import { EmailContactItem, EmailType } from "./email.model";
import { PhoneType } from "./phone.model";
import { Meta } from "../BO9_base/meta.model";
import { BO9Address, BO9AddressType } from "./address.model";
import { createUUID } from '../../uuid.service'
import { DateToYYMMDDhhmmss } from '../../helpers/time.helper';

export type ContactType = string;

export interface SocialMediaType {
    updatedAt: string;
    items: {
        company: string;
        handle: string;
    }[],

}

export interface BO9Contact {
    _key: BO9UUID;
    usage?: string; // eg General, person1, person etc
    names: FullName;
    email: EmailType;
    phone: PhoneType;
    address: BO9AddressType;
    socialMedia?: SocialMediaType;
    URL: string;
    kind?: ContactType;
    notes: string;
    referredBy?: BO9Who;

    meta?: Meta
}

export interface BO9Who {
    name: string;
    comment?: string;
    uuid?: BO9UUID;
}

let contactMap = new Map();

export function allPersonEmails(c: BO9Contact) : string[] {
    const eList:string[] = [];

    if (c.email && c.email.items && c.email.items.length > 0) {
        for (const e of c.email.items) {
            eList.push(e.value);
        }
    }
    return eList;
} 

export function canContact(c: BO9Contact): boolean {

    if ((c.email.DNC && c.email.DNC.toString() === 'true') || c.email.DNC === true) return false;

    //if ((c.phone.DNC && c.phone.DNC.toString() === 'true') || c.phone.DNC === true) return false;

    return true;
}

export function primaryEmail(c: BO9Contact): string {
    if (c && c.email) {
        const emails:EmailContactItem[] = c.email.items;
        if (emails && emails.length > 0) {
            for (let e of emails ) {
                if (e.primary) {
                    return(e.value);
                }
            }
            return(emails[0].value); // No primary, return the first one
           
        }
    }
   
    return('');
}

export function cleanContact(c: any): BO9Contact {
    if(c && c.address && c.address.items && c.address.items.length >0) {
        const newAddrItems: BO9Address[] = [];
        for (const addr of c.address.items) {
            if (addr) { // Can be null - not sure why
                if (addr.state && addr.state.name && addr.state.name.name) {
                    addr.state = '';
                  } else if (addr.state && addr.state.name) {
                    addr.state = addr.state.name; // remove old ISO state
                  }
                  newAddrItems.push(addr); //skip null
            }  

        }
        c.address.items = newAddrItems; // cleaned up.
    }
    return c
}

export function savePerson(c: BO9Contact) {
    if (!contactMap.get(c._key)) {
        contactMap.set(c._key, c);
    }
}

export function contactFullName(c: BO9Contact ): string {
    if (c  && c.names  && c.names.name) {
        if( c.names.name.middle && c.names.name.middle != '') {
            return( c.names.name.first + ' ' + c.names.name.middle + ' ' + c.names.name.last )
        } else {
            return( c.names.name.first + ' ' + c.names.name.last )
        }
    } else {
        return('');
    }
}

export function contactFirstLastName(c: BO9Contact ): string {
    if (c  && c.names  && c.names.name) {
            return( c.names.name.first + ' ' + c.names.name.last )
    } else {
        return('');
    }
}

export function contactNameMatch(name1: FirstLastName, name2: FirstLastName): boolean {
    return (
        name1 && name2 && 
            name1.first === name2.first &&
            name1.last === name2.last &&
            name1.title === name2.title &&
            typeof(name1.middle !== undefined) && typeof(name2.middle!== undefined) &&
            name1.middle === name2.middle
    )
}

export function makeContactFromForm(contact: any): BO9Contact {
        let newName: FirstLastName;
        let newOther: FirstLastName[] = [];

        if (contact.names && contact.names.name) {
            newName  = contact.names.name;
            newOther = contact.names.other;
        } else {
           newName = contact.names;
        }
        const trueNames: FullName = {
            name: newName,
            other:  newOther
        }

        let newAddr: BO9AddressType;

        const oldC: BO9Contact = contact;
        oldC.names = trueNames;

        console.log('ADDR '+JSON.stringify(contact.address));
        if (contact.address && contact.address.items && contact.address.items.length > 0) {
            newAddr = contact.address
        } else if (contact.address) {
            const nA: BO9Address = contact.address;
            nA.state = contact.address.state? contact.address.state: '',

            nA.country = contact.address.country? contact.address.country: 'USA';

            newAddr = {
                updatedAt: DateToYYMMDDhhmmss(),
                items: [nA]
            }
            oldC.address = newAddr;
        }
        
        return oldC
    }


export function mkMTContact() : BO9Contact {
    return ({
        _key: createUUID(),
        usage: '',
        names: {
            name:{
                first: '',
                last: '',
                title: '',
            },
            other: []
        },
        email: {
            updatedAt: '',
            items: [],
            DNC: false
        },
        phone: {
            updatedAt: '',
            items: [],
            DNC: false
        },
        address: {
            updatedAt: '',
            items: []
        },
        URL: '',
        kind: '',
        notes: '',
    })
}

export function mergeSocialMedia(original: SocialMediaType, additional: SocialMediaType): SocialMediaType {
    var _ = require('lodash');
    let newS: SocialMediaType = {
        updatedAt: DateToYYMMDDhhmmss(),
        items:[]
    }

    for (const orig of original.items) {

            newS.items.push(orig);
    }

    const tempp = newS.items.concat(...additional.items);
   
    newS.items = _.unionWith(tempp,_.isEqual); // Should be uniques
    
    return(newS);
}