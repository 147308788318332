import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { networkConfig } from 'src/config/webapp.config';
import { Invite, FormInvite } from '../../../../bo9-shared/models/person/invite.model';
import { RelationType } from '../../../../bo9-shared/models/person/relation.model';
import { BO9EVENTS } from '../../../../bo9-shared/models/BO9_base/BO9_base.model';
import { ArangoService } from '../arangoservice/arango.service';
import { firstName, lastName, Person } from '../../../../bo9-shared/models/person/person.model';
import { contactFullName } from '../../../../bo9-shared/models/contact/contact.model';
import { BO9UUID } from '../../../../bo9-shared/models/UUID.model';
import { SearchService } from '../search/search.service';
import { resultOK } from '../../../../bo9-shared/models/message/message.model';


@Injectable()
export class InvitesService {
// TODO shrink number returned

    inviteMap: Map<string,FormInvite>;
    invites: FormInvite[];


    constructor (
        private http: HttpClient,
        private arangoService: ArangoService,
        private searchService: SearchService
    ) {
        this.flush();
    }

    // TODO implement by distance, order by distance...


    private flush() {
        this.inviteMap = new Map();
        this.invites = [];
    }

    public loadInvites():  Observable<any>  {
        console.log('get invites');
        return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.INVITE);
      }

    public getInviteByKey(key: BO9UUID): Observable<FormInvite> {
        return new Observable( observer =>{
            if (this.inviteMap.size > 0) {
                const result = this.inviteMap.get(key);
                observer.next(result);
                observer.complete();
            } else {
                this.getInvites()
                .then( () => {
                    if (this.inviteMap.size > 0) {
                        const result = this.inviteMap.get(key);
                        observer.next(result);
                        observer.complete();
                    }
                })
            }
        });
        

    }

    public getInvitesFromByEmail(email: string, force?: boolean): Observable<FormInvite[]> {
        const emailList: FormInvite[] = [];
        console.log (`Invite email `+JSON.stringify(email))
        return new Observable( observer =>{
            if (this.invites.length > 0  && !force) {
                for (const f of this.invites) {
                    if (f.fromPerson && f.fromPerson.primaryEmail === email) {
                        emailList.push(f);
                    }
                }
                observer.next(emailList);
                observer.complete();
            }  else {
                this.getInvites(force)
                .then( () => {
                    if (this.invites.length > 0) {
                        for (const f of this.invites) {
                            if (f.fromPerson &&  f.fromPerson.primaryEmail === email) {
                                emailList.push(f);
                            }
                        }
                    }
                    observer.next(emailList);
                    observer.complete();
                });
            }
        });


    }

    public updateInvite(p: Invite):  Observable<any>  {
        const param = {
          invite: p
        };
        this.flush();
          return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.INVITE, param);
      }

    public deleteInvite(p: Invite, owner: string):  Observable<any>  {
        console.log('delete invite ' + JSON.stringify(p));
        this.flush();
          return this.http.delete(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.INVITE+'/?invite='+p._key+'&key='+owner);
      }

    public getInvites(force?: boolean): Promise<FormInvite[]> {
        return new Promise( resolve =>{
            if (this.invites.length > 0 && !force) {
                resolve(this.invites);
            }  else {
                if (force) {
                    this.invites = [];
                }
                const load = this.loadInvites()
                .subscribe( async (msg: any) => {  // TODO unsubscribe??
                  
                    let added = 0;
                    if (resultOK(msg)) {
                        let target = msg.content.length;
                      for (let i=0; i< msg.content.length; i++) {
                       
                        const invite:Invite = msg.content[i].invite;
                        if (invite && invite.from) {
                            const fromPerson:Person =  await this.searchService.searchPersonKey(invite.from, false) 
                            const toPerson:Person = await this.searchService.searchPersonKey(invite.to, false)
                            const iForm: FormInvite = {
                                _key: invite._key,
                                fromLastName: toPerson?   lastName(fromPerson): 'No From',
                                fromFirstName: toPerson?  firstName(fromPerson): 'No From',
                                toLastName: toPerson?   lastName(toPerson): 'No To',
                                toFirstName: toPerson?  firstName(toPerson): 'No To',
                                toEmail: toPerson? toPerson.primaryEmail : 'No Email',
                                inviteType: Array.isArray(invite.inviteType)?invite.inviteType: [invite.inviteType] ,
                                pearlModule: Array.isArray(invite.pearlModule)?invite.pearlModule: [invite.pearlModule],
                                naturalNumber: invite.invited,
                                sourceType: invite.sourceType,
                                invite: invite,
                                meta: invite.meta,
                                fromPerson: fromPerson,
                                toPerson: toPerson
                            }
                            console.log (`Invite ${added} of ${msg.content.length} `+JSON.stringify(invite))
                            this.invites.push(iForm);
                            this.inviteMap.set(invite._key, iForm);
                            target = target -1;
                            if (target === 0) {
                                load.unsubscribe();
                                resolve(this.invites);
                            }

                        } else {
                            target = target -1;
                            if (target === 0) {
                                load.unsubscribe();
                                resolve(this.invites);
                            }
                        }
                    }
                        
                    } else {
                        resolve([]);
                    }
                })

            }
        } )
    }
}