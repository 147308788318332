import { firstValueFrom } from "rxjs";
import { php_unserialize } from "../../../../bo9-shared/helpers/php.helper";
import { noHtml } from "../../../../bo9-shared/helpers/text.helper";
import { addDays, addHours, UTCTimeFromDateandtTimezone } from "../../../../bo9-shared/helpers/time.helper";
import { AttendanceData, hoursFromCert } from "../../../../bo9-shared/models/certification/certification.model";
import { attendeeEmailUnique, Event, eventAlreadyListed, EventColor } from "../../../../bo9-shared/models/event/event.model";
import { EspressoEventAttendeeCount, EspressoEventCategory, makeCategoryList } from "../../../../bo9-shared/models/event/event9e.model";
import { addRegisterHours, mkMTRegisterHours } from "../../../../bo9-shared/models/institute/credits.model";
import { userIsAdmin, userIsJourneyman } from "../../../../bo9-shared/models/person/BO9rolesmethods.model";
import { Person } from "../../../../bo9-shared/models/person/person.model";

export function getEventsBetweenDates(startDate, toDate,person: Person, dbService: any, searchService: any, sysVbleService: any, arangoService: any): Promise<Event[]> {
    const eList: Event[] = [];

    return new Promise( async (resolve) => {
        dbService.getEvents(startDate, toDate, '',false)
        .subscribe(async (result) => {
        /*
        // NOT calendly events except my own.....?
        const arangoEvents = await  this.eventdbService.getCalendlyEvents(this.searchFromString, this.searchToString, false);
        if (arangoEvents) {
            for (const cal of arangoEvents.events) {

            if (cal.event_status == "?") {
                cal.event_status = "Open";
            }
            eList.push(cal);
            }
        }
        */
            console.log("getEventsBetween dates");
        if (result.content) {
            const e = result.content.events as Event[];
            const ats = result.content.attendees as EspressoEventAttendeeCount[];
            const cats = result.content.categories as EspressoEventCategory[];
            let isJourneyman = false;
            let isSeniorJ = false;
            let isApprentice = false;
            let isMaster = false;
            const programAllowedEvents = await firstValueFrom(sysVbleService.getSystemVariableArray('Programs Allowed Events'));
            if (person) {
                const roles: any = await firstValueFrom(arangoService.getRoles(person));
                const systemRoles = roles && roles.content[0]? roles.content[0].systemRoles: [];
    
                for (const role of systemRoles ) {
                  if (role.includes('Partner')) {isJourneyman= true;};
                    if (role.includes('Admin')) { isSeniorJ = true};
                    if (role.includes('Practicing')) { isSeniorJ = true; };
                    if (role.includes('Apprentice')) { isApprentice = true; };
                    if (role.includes('Journeyman')) { isJourneyman = true; };
                    if (role.includes('SeniorJ')) { isSeniorJ = true; };
                    if (role.includes('Master')) {isMaster= true;};
                }
            }

            for (let i =0; i < e.length; i++) {

            if (e[i].event_status != 'D' && person && person.primaryEmail) {


                const res:any = await firstValueFrom( dbService.isEmailRegistered(e[i]._key,person.primaryEmail ));  // TDDO only get me.

                if (res && res.content && res.content.length> 0) {
                    e[i].registered = true;
                } else {
                    e[i].registered = false;
                }
                //e[i].registered = !attendeeEmailUnique(person.primaryEmail, e[i].listAttendees);

                if (e[i].registration_end) {
                if (new Date(e[i].registration_end) < new Date()) {
                    e[i].event_status = "Closed";
                } else {
                    e[i].event_status = "Open";
                }
                }

                for (let j=0; j< ats.length; j++ ) {
                if (ats[j].event_id === e[i]._key.toString()) {
                    e[i].attendees = ats[j].attendees
                    break;
                }
                }
                if (new Date(e[i].endDate).getTime()+(24*60*60*1000) < new Date().getTime()) { // 24 hours more
                    e[i].color = EventColor.past;
                } else {
                    e[i].color = EventColor.upcoming;
                }
            
                e[i].categories = [];
                e[i].source = 'wordpress';
                e[i].categories = makeCategoryList(e[i]._key, cats);
                e[i].categories.sort();

                e[i].startTimeUTC = UTCTimeFromDateandtTimezone(e[i].startTime, e[i].startDate, e[i].timezone);
                e[i].startTime =  e[i].startTimeUTC.getHours()+":"+ e[i].startTimeUTC.getMinutes().toString().padEnd(2,"0");
                const res1 = await searchService.searchEventKey(e[i]._key, true);

                if (res1.event.length > 0) {  // found an event in arango
                    e[i].identifiers = res1.event[0].identifiers;
                    e[i].recording_link = res1.event[0].recording_link;
                  
                }


                //console.log('Events search ' + JSON.stringify(res.event[0]));
               // if (person.programs && person.programs.length > 0) {
               //     for (const program of person.programs) {
                        

                let eventList: string[] = [];
                
                // SHould be by Level eventually
                        for (const p of programAllowedEvents as any) {
                            if (isApprentice || isJourneyman || isSeniorJ || isMaster) {
                                eventList = [...eventList, ...p.events]
                            }
                        }

                        let allowedEvent = false;
                        for (const cat of e[i].categories) {
                            if (eventList.includes(cat)) {
                                allowedEvent=true;
                                break;
                            }

                        }
                        if (allowedEvent && !e[i].registered && !eventAlreadyListed(eList,e[i]._key) ) { // Only push events this person can attend
                                    e[i].description = noHtml(e[i].description, {entities:'spaces'});
                            eList.push(e[i]);
                        }
              //      }
             //   }
                // Get any partner specific events 
                if (person && isJourneyman) {
            
                    let eventList = [];

                    for (const p of programAllowedEvents as any) {
                        if (p.programName == 'Journeyman') {
                            eventList = p.events;
                            break;
                        }
                    }

                    let allowedEvent = false;
                    for (const cat of e[i].categories) {
                        if (eventList.includes(cat)) {
                            allowedEvent=true;
                        }

                    }
                    if (allowedEvent && !e[i].registered  && !eventAlreadyListed(eList,e[i]._key) ) { // Only push events this person can attend
                        e[i].description = noHtml(e[i].description, {entities:'spaces'});
                        eList.push(e[i]);
                    }
                }
                }
            }
        }

        eList.sort ( (first,second) => {
            const firstDate = new Date(first.startDate);
            const secondDate = new Date(second.startDate);

            if (firstDate && secondDate) {
            if (firstDate < secondDate) return -1;
            if (firstDate > secondDate) return 1;
            return 0;
            } else {
            console.error("date sort fail no date" +JSON.stringify(first.startDate) + ' ' + JSON.stringify(second.startDate));
            if (firstDate) return -1;
            if (secondDate) return 1;
            return 0;
            }
        })

        resolve(eList)

        })
    })
}

export function getMyEvents(email:string,  dbService: any, searchService: any): Promise<Event[]> {
    const eList: Event[] = [];

    return new Promise( async (resolve) => {

        dbService.getEventsByEmail(email)
        .subscribe({next: async (result) => {

        if (result.content) {
            const e = result.content.events as Event[];
            const cats = result.content.categories as EspressoEventCategory[];

            for (let i =0; i < e.length; i++) {

            if (e[i].registration_end) {
                if (new Date(e[i].registration_end).getTime() < new Date().getTime()) {
                    e[i].event_status = "Closed"
                } else {
                    e[i].event_status = "Open";

                }
                const starting = addHours(new Date(e[i].startDate),23).getTime();

                if (starting >= new Date().getTime()) {
                    e[i].color = EventColor.registered; // Has to be registered to be on this list
                    eList.push(e[i]);
                } else {
                    if (e[i].attended) {
                        e[i].color = EventColor.attended;
                    }  else {
                        e[i].color = EventColor.missed;
                    }
                       
                    eList.push(e[i]);
                }

            e[i].categories = [];
            e[i].source = 'wordpress';
            e[i].categories = makeCategoryList(e[i]._key, cats);
                    e[i].categories.sort();
        //    this.eventCounts.setEventCount(e[i])
            }
            e[i].startTimeUTC = UTCTimeFromDateandtTimezone(e[i].startTime, e[i].startDate, e[i].timezone);
            const res = await searchService.searchEventKey(e[i]._key, true);
            e[i].startTime =  e[i].startTimeUTC.getHours()+":"+ e[i].startTimeUTC.getMinutes().toString().padEnd(2,"0");
            if (res.event.length > 0) {  // found an event in arango
                e[i].identifiers = res.event[0].identifiers;
                e[i].recording_link = res.event[0].recording_link;
            }   
                //console.log('Events search ' + JSON.stringify(res.event[0]));
            }
        }

        eList.sort ( (first,second) => {
            const firstDate = new Date(first.startDate);
            const secondDate = new Date(second.startDate);

            if (firstDate && secondDate) {
            if (firstDate > secondDate) return -1;
            if (firstDate < secondDate) return 1;
            return 0;
            } else {
            console.error("date sort fail no date" +JSON.stringify(first.startDate) + ' ' + JSON.stringify(second.startDate));
            if (firstDate) return -1;
            if (secondDate) return 1;
            return 0;
            }
        })

        resolve(eList)

    }})})

    }

    export function getAttendanceData(e: Event,dbService: any): Promise<AttendanceData> {
        return new Promise( async (resolve) => {

        let event_obj;
        if (e.event_meta && e.event_meta != '') {
            event_obj= php_unserialize(e.event_meta)
        } else {
            event_obj = {};
        }
          let eventCode = '';
          if (typeof event_obj === 'string') {
            eventCode = event_obj
          } else if (event_obj.event_code) {
            eventCode = event_obj.event_code;
          }
         
          const ad: AttendanceData = {
            eventId: e._key,
            eventName: e.name,
            startDate: e.startDate,
            eventCode: eventCode,
            hours: mkMTRegisterHours()
            }
          if (eventCode != '') {
            const credres:any = await firstValueFrom(dbService.getCredit(eventCode));
            console.log("CREDIT "+JSON.stringify(credres));
            if (credres && credres.content && credres.content.credit && credres.content.credit.length > 0) {
                ad.hours = addRegisterHours(credres.content.credit[0].hours, ad.hours);
            }
          }
          resolve(ad);
        })


    }
