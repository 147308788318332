import { DateStringType } from "../BO9_base/BO9_base.model";
import { DateToYYMMDDhhmmss } from '../../helpers/time.helper';

export interface EmailContactItem {
    type: string;
    status: string;
    value: string;
    toValidate?: boolean;
    confirmed?: string;
    unconfirmed?: string;
    codeString?: string;
    origValue?: string;
    primary?: boolean;
    deleted?: boolean;
}

export enum EmailContactType {
    "Home",
    "Business",
    "Other"
}
export interface EmailType {
    updatedAt: DateStringType;
    DNC: boolean;
    items: EmailContactItem[];
}



export function setPersonEmailConfirmed(p:EmailType, search:string ) : EmailType {

    let e = p;

    for (let i=0; i< e.items.length; i++) {
        if (e.items[i].confirmed == search) {
            e.items[i].toValidate = false;
            e.items[i].confirmed = '';
            e.items[i].unconfirmed = '';
            return(e);
        }
    }
    return e;
}

export function removePersonEmailDenied(p:EmailType, search:string ) : EmailType {

    const e:EmailType = {
        updatedAt: DateToYYMMDDhhmmss(),
        DNC: p.DNC,
        items: []
    }

    for (let i=0; i< p.items.length; i++) {
        if (!(p.items[i].unconfirmed == search)) { // Don't copy the one found
            e.items.push(p.items[i])
        }
    }
    return e;
}

export function addEmail( email: EmailType | null, DNC: boolean, value: string, type?: string, status?: string ): EmailType {
    const newItem: EmailContactItem = {
        type: type? type : '',
        value: value,
        status: status ? status: ''
    }
    let newIA: EmailContactItem[];
    if (email && email.items.length > 0) {
        newIA = email.items;
        newIA.push(newItem);
    } else {
        newIA = [newItem]
    }
    const newEmail: EmailType = {
        updatedAt: DateToYYMMDDhhmmss(),
        DNC: DNC,
        items: newIA
    }
    return newEmail
}

export function existingEmail(emails: EmailContactItem[], value: string) {

    for (let e of emails ) {
        if (e.value === value) {
            return(true);
        }
    }
    return(false);
}



export function mergeEmail(original: EmailType, additional: EmailType): EmailType {
    let newP: EmailType = {
        updatedAt: DateToYYMMDDhhmmss(new Date),
        DNC: (original.DNC || additional.DNC),
        items: []

    }

    newP.items = additional.items; // Take the incoming additional as the real ones

    for (let e=0; e< original.items.length; e++) { // check original list to see if any need to be re-added
        if (!existingEmail(newP.items, original.items[e].value) && !original.items[e].deleted) {
            const newEmail: EmailContactItem = original.items[e];
            newP.items.push(newEmail); // onto end
        } 
    }
    return(newP);
}
