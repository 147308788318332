import { Component,OnDestroy, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { firstValueFrom, Subscription } from 'rxjs';
import { validationMessages } from 'src/helpers/form.helper';
import { createUUID,  responseEncode} from '../../../../../bo9-shared/uuid.service';
import { DateToYYMMDDhhmmss } from '../../../../../bo9-shared/helpers/time.helper';
import { EventEmitterService } from 'src/services/events/events.service';
import { MatDialog } from '@angular/material/dialog';
import { findInvalidControlsRecursive } from '../../../helpers/form.helper';
import { formGroup, GroupMembership } from '../../../../../bo9-shared/models/person/group.model';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import { GroupsService } from 'src/services/groups/groups.service';
import { InvitedStatus } from '../../../../../bo9-shared/models/person/invite.model';
import { ActiveCampaignService } from 'src/services/activecampaign/activecampaign.service';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Router } from '@angular/router';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { AuthService } from 'src/services/auth/auth.service';

@Component({
    selector: 'client-member-form',
    templateUrl: './clientMemberForm.component.html',
    styleUrls: ['./clientMemberForm.component.scss'],
 
  })
  export class ClientMemberFormComponent implements OnDestroy, OnChanges {
    @Input() inputGroup: formGroup;
    @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
    name: string;
    form: FormGroup;
    user: Person;
    subscriptions: Subscription[] = [];
    validationMessages = validationMessages;
    disableBtn = false;
    gm: GroupMembership;
  
    get value(): formGroup {
      console.log('name get '+ JSON.stringify(this.form.value));
      return this.form.value;
    }
  
    set value(value: formGroup) {
      console.log('name get '+ JSON.stringify(value));
      this.form.setValue(value);
    }


    constructor(private formBuilder: FormBuilder, 
      private emitterService: EventEmitterService,
      private groupService: GroupsService,
      private arangoService: ArangoService,
      private router: Router,
      private activeService: ActiveCampaignService,
      private auth: AuthService) {

      this.makeForm();
      this.gm = {
        _key:  createUUID(),
        from: this.inputGroup?._key? this.inputGroup._key: '',
        to: null,
        primaryContact: false,
        groupOwner: '',
        admin: false,
        invitedStatus: InvitedStatus.Invited,
        meta: updateMeta()
      }
      this.user = this.auth.getAuthUser();
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'ClientMemberFormComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 

    }
    selectPrimary() {
     
      this.gm.primaryContact = !this.gm.primaryContact;
    }
  
    selectAdmin() {
     this.gm.admin = !this.gm.admin;
    }

    makeForm() {
      this.form = this.formBuilder.group({
        first: ['', Validators.compose([Validators.required])],
        last: ['', Validators.compose([Validators.required])],
        email: ['', Validators.compose([])]
      });
      
    }

    ngOnChanges() {
      console.log('OnChange ');
      this.form.reset();
    }

    submitForm() {
      console.log('Invite Client member form ' + JSON.stringify(this.form.value));

      if (this.form.valid) {
        const newmember: GroupMembership = {
            _key: this.gm._key,
            to: '',
            from: this.inputGroup._key,
            primaryContact: this.gm.primaryContact,
            groupOwner: this.inputGroup.group.createdBy,
            admin: this.gm.admin,
            invitedStatus: InvitedStatus.Invited, // Added by admin can't decline :)
            invitedPerson: {
              first: this.form.controls.first.value,
              last: this.form.controls.last.value,
              email: this.form.controls.email.value,
              whenInvited: DateToYYMMDDhhmmss(),
              accept: '',
              decline: '',
              inviteTo: this.inputGroup.group.groupName,
              inviteBy: this.inputGroup.group.createdBy,
            },
            meta: updateMeta()
        }
        newmember.to = createUUID();
        
        newmember.invitedPerson.accept = responseEncode(newmember.to,"Y");
        newmember.invitedPerson.decline = responseEncode(newmember.to,"N");
        console.log('Client member is: ' + JSON.stringify(newmember));
        this.subscriptions.push( this.activeService.invite(newmember, 'Client')
        .subscribe( async (result) => { 
          if (result.content) {
            newmember.invitedPerson.inviteDeal = result.content.deal;
            console.log('Invited result  is: ' + JSON.stringify(result.content));
            const res = await firstValueFrom(this.groupService.updateGroupMembership(newmember));

            this.emitterService.emitter.emit('Message','Client Member ' + newmember.invitedPerson.first+ ' ' +newmember.invitedPerson.last);
            this.notifyParent.emit('person invited');
          } else {
          }
          
        }))
        
      } else {
        alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.form)));
      }
    }

  
    ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
    }

  
    reset() {
      this.form.reset();
    }
  }