
		<span *ngIf="isAdmin()"><button mat-stroked-button (click)="onAddClick()">Add<mat-icon>add</mat-icon></button><button mat-stroked-button (click)="onClickRefresh()">Refresh</button></span>
		<span *ngIf="showForm"><button mat-stroked-button (click)="onCloseClick()">Close<mat-icon>close</mat-icon></button><resource-form [resource]="selectedResource$ | async" (notifyParent)="ResourceSubmittedHandler($event)"></resource-form></span>

		<h2>Resources</h2>
		<calendly-bookings [person]="person"></calendly-bookings>

		<div *ngIf="resourceCategories" >
			<form [formGroup]="SearchForm" (ngSubmit)="submitSearchForm()" class="Pearl-form" novalidate >
				<mat-form-field appearance="outline" >
					<mat-label>Search</mat-label>
					<input matInput  formControlName="content" placeholder="Search text"/>
				</mat-form-field>
					&nbsp; &nbsp;
				<mat-form-field appearance="outline" >
					<mat-label>Natural Number?</mat-label>
					<mat-select matNativeControl formControlName="naturalNumbers" multiple>
						<mat-option *ngFor="let nnI of nnKeys" [value]="nnI">{{ nnI }}</mat-option>
					</mat-select>
				</mat-form-field>
					&nbsp; &nbsp;
				<mat-form-field *ngIf="isAdmin()" appearance="outline" >
					<mat-label>Levels</mat-label>
					<mat-select matNativeControl formControlName="eventCategories" multiple>
						<mat-option *ngFor="let reltype of programs" [value]="reltype">{{ reltype }}</mat-option>
					</mat-select>
				</mat-form-field>
					&nbsp; &nbsp;
				<mat-form-field appearance="outline">
					<mat-label>Resource Types</mat-label>
					<mat-select matNativeControl formControlName="resourceTypes" multiple>
						<mat-option *ngFor="let reltype of resourceTypes " [value]="reltype">{{ reltype}}</mat-option>
					</mat-select>
				</mat-form-field>
				 &nbsp; &nbsp;
				 &nbsp; &nbsp;
				 <mat-form-field appearance="outline">
					 <mat-label>Categories</mat-label>
					 <mat-select matNativeControl formControlName="resourceCategories" multiple>
						 <mat-option *ngFor="let reltype of resourceCategories " [value]="reltype">{{ reltype}}</mat-option>
					 </mat-select>
				 </mat-form-field>
				<button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Search</button>
			</form>
		</div>
		<table mat-table [dataSource]="dataSource" matSort>

			<ng-container matColumnDef="edit">
				<th mat-header-cell *matHeaderCellDef> Edit </th>
				<td mat-cell *matCellDef="let element" class="td-center-text">
					<button type="button" (click)="onClickEdit(element)"> <mat-icon>edit</mat-icon></button>
				</td>
			</ng-container>

			<ng-container matColumnDef="dateCreated">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
				<td mat-cell *matCellDef="let element" > {{ dateFormating(element.meta.dateCreated, "purchased") }} </td>
			</ng-container>

			<ng-container matColumnDef="title">
				<th mat-header-cell *matHeaderCellDef mat-sort-header style='width:40%;'> Title / Content </th>
				<td mat-cell *matCellDef="let element"> {{element.title}} <div class="description"><div [innerHTML]="element.description"></div> </div></td>
			</ng-container>

			<ng-container matColumnDef="aboutNN" style="max-width:5%">
				<th mat-header-cell *matHeaderCellDef mat-sort-header nowrap> Nat-Nums </th>
				<td mat-cell *matCellDef="let element" class="td-center-text">
					{{element.aboutNN}}
				</td>
			</ng-container>

			<ng-container matColumnDef="eventCategories">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Levels </th>
				<td mat-cell *matCellDef="let element" class="td-center-text">
					{{ element.eventCategories.join(", ") }}
				</td>
			</ng-container>
			<ng-container matColumnDef="resourceCategories">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Categories </th>
				<td mat-cell *matCellDef="let element" class="td-center-text">
					{{ element.resourceCategories.join(", ") }}
				</td>
			</ng-container>

			<ng-container matColumnDef="resourceType">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
				<td mat-cell *matCellDef="let element" class="td-center-text" style='text-align:center;'>
					{{ element.resourceType}}<br>
					<small>
						<span *ngIf="element.url"><a href="{{ element.url}}" target="_blank"><mat-icon >visibility</mat-icon>VIEW</a></span>
						<span *ngIf="element.url && element.fileInfo && element.fileInfo.length > 0"><hr class='divider' /></span>
						<span *ngIf="element.fileInfo && element.fileInfo.length > 0">
							<a href="{{element.fileInfo[0].Location}}" target="_blank">DWNLD</a>
						</span>
					</small>
				</td>
			</ng-container>

			<ng-container matColumnDef="delete">
				<th mat-header-cell *matHeaderCellDef> Delete </th>
				<td mat-cell *matCellDef="let element" class="td-center-text">
					<button type="button" (click)="onClickDelete(element)"> <mat-icon>delete</mat-icon></button>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>

		</table>
		<mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]"></mat-paginator>
