
import { Person } from './person.model';
import { Difference, DifferenceBySource } from './differences.model';
import { NaturalNumber } from '../naturalNumber/naturalNumber.model';



export function differencesForSource(source: string, p:Person) : DifferenceBySource {
    if (p.differences && p.differences.length > 0) {
        for (const diff of p.differences) {
            if (diff.source.toLowerCase() === source.toLowerCase()) {
                return diff;
            }
        }
    }

    return {
        source: '',
        acceptedAlready: false,
        differences: []
    };

}

export function checkDiffs(checkForm: string, diffs: Difference[]): boolean {
    if ( !diffs || checkForm === '' || diffs.length <= 0) {
        return false;
    } else {
        for (const d of diffs) {
            if (d.showForm === checkForm) {
                return true;
            }
        }
    }
    return false;
}


export function sameDifference(accepted: Difference[], newList: Difference[]) : boolean {

    if (accepted.length !== newList.length) {
        return false;
    }
    for (let i =0; i< accepted.length; i++) {
        if (accepted[i].db !== newList[i].db) return false;
        if (accepted[i].item !== newList[i].item) return false;
        if (accepted[i].new !== newList[i].new) return false;
    }
    return true;
}

export function getDifferences( p1: Person, p2: Person): Difference[] {
    const differences: Difference[] = [];


    try {
        if (p1 && p2) {

            //console.log('P1 CONTACT ' + JSON.stringify(p1.contact));
           // console.log('P2 CONTACT ' + JSON.stringify(p2.contact));
            if (p1.primaryEmail && p2.primaryEmail &&  p1.primaryEmail.toLowerCase() != p2.primaryEmail.toLowerCase()) {
                differences.push({item: 'Primary Email', different: true, db:p1.primaryEmail, new: p2.primaryEmail, showForm: 'merge-person' });
            }
            if (p1.bio && p2.bio && p1.bio != p2.bio) {
                differences.push({item: 'Bio',  different: true, db:p1.bio? p1.bio: '', new: p2.bio? p2.bio: '', showForm: 'merge-person'  });
            }
            if (p1.notes && p2.notes && p1.notes != p2.notes) {
                differences.push({item: 'Notes',  different: true, db:p1.notes? p1.notes: '', new: p2.notes? p2.notes:'',  showForm: 'merge-person' });
            }

            if (p1.details && p2.details) {
                if (p1.details.momBirthOrder && p2.details.momBirthOrder && p1.details.momBirthOrder != p2.details.momBirthOrder   && p2.details.momBirthOrder != 0) {
                    differences.push({item: 'Birth Order',  different: true, db:p1.details.momBirthOrder? p1.details.momBirthOrder.toString():'', new: p2.details.momBirthOrder? p2.details.momBirthOrder.toString() : '',  showForm: 'merge-person' });
                }
                if (p1.details.websiteUrl && p2.details.websiteUrl && p1.details.websiteUrl != p2.details.websiteUrl) {
                    differences.push({item: 'Website Url',  different: true, db:p1.details.websiteUrl? p1.details.websiteUrl:'', new: p2.details.websiteUrl? p2.details.websiteUrl : '',  showForm: 'merge-person' });
                }
            }

            if (p1.imageURL && p2.imageURL  && p1.imageURL != p2.imageURL) {
                differences.push({item: 'Image Url',  different: true, db:p1.imageURL? p1.imageURL:'', new: p2.imageURL? p2.imageURL : '',  showForm: 'merge-person' });
            }

                /*
                if (p1.details.birthPlace && p2.details.birthPlace && p1.details.birthPlace != p2.details.birthPlace && p2.details.birthPlace  && p2.details.birthPlace.zip != '') {
                    differences.push({item: 'Birth Place',  different: true, 
                    db:p1.details.birthPlace? p1.details.birthPlace.zip:'' , new: p2.details.birthPlace? p2.details.birthPlace.zip : '',  showForm: 'merge-person'  });
                }
            } else {
                differences.push({item: 'Details',  different: true, 
                db: JSON.stringify(p1.details), new: JSON.stringify(p2.details),  showForm: 'merge-person'  });
            }
            */
            if (p1.referralSource != p2.referralSource  && p2.referralSource != '') {
                differences.push({item: ' ReferralSource',  different: true, 
                db:p1.referralSource ? p1.referralSource: '',
                new: p2.referralSource?  p2.referralSource: '',  showForm: 'nn-form'  });
            }
    
            if (p1.source != p2.source  && p2.source != '') {
                differences.push({item: ' Source',  different: true, 
                db:p1.source ? p1.source: '',
                new: p2.source?  p2.source: '',  showForm: 'nn-form'  });
            }
            if (p1.personOrOtherReferredBy != p2.personOrOtherReferredBy  && p2.personOrOtherReferredBy != '') {
                differences.push({item: ' personOrOther',  different: true, 
                db:p1.personOrOtherReferredBy ? p1.personOrOtherReferredBy: '',
                new: p2.personOrOtherReferredBy?  p2.personOrOtherReferredBy: '',  showForm: 'nn-form'  });
            }

            if (p1.naturalNumbers.asserted) {
                if (p1.naturalNumbers.asserted.naturalNumber != p2.naturalNumbers.asserted.naturalNumber  && p2.naturalNumbers.asserted.naturalNumber != NaturalNumber.None) {
                    differences.push({item: 'Asserted Natural Number',  different: true, db:p1.naturalNumbers.asserted.naturalNumber, new: p2.naturalNumbers.asserted.naturalNumber,  showForm: 'asserted' });
                }  
               
            }
            if (p1.naturalNumbers.identified) {
                if (p1.naturalNumbers.identified.naturalNumber != p2.naturalNumbers.identified.naturalNumber  && p2.naturalNumbers.identified.naturalNumber != NaturalNumber.None) {
                    differences.push({item: 'Identified Natural Number',  showForm: 'identified' ,  different: true, db:p1.naturalNumbers.identified.naturalNumber, new: p2.naturalNumbers.identified.naturalNumber });
                }
            }
    
            if (p1.naturalNumbers.invited) {
                if (p1.naturalNumbers.invited.naturalNumber != p2.naturalNumbers.invited.naturalNumber  && p2.naturalNumbers.invited.naturalNumber != NaturalNumber.None) {
                    differences.push({item: 'Invited Natural Number',  showForm: 'invited' ,  different: true, db:p1.naturalNumbers.invited.naturalNumber , new: p2.naturalNumbers.invited.naturalNumber  });
                }
            }
            if (p1.naturalNumbers.history) {
                if (p1.naturalNumbers.history.length != p2.naturalNumbers.history.length && p2.naturalNumbers.history.length != 0) {
                    differences.push({item: 'NN History',  showForm: 'history' ,  different: true, 
                        db: p1.naturalNumbers.history.length.toString() , new: p2.naturalNumbers.history.length.toString()  });
                }
            }
            if (p1.details && p2.details && p1.details.gender != p2.details.gender && p2.details.gender != '') {
                differences.push({item: 'Gender',  different: true, db:p1.details.gender? p1.details.gender: '', new: p2.details.gender? p2.details.gender: '',  showForm: 'merge-person'  });
            }
    
            if (p1.source != p2.source && p2.source != '') {
                differences.push({item: 'Source',  different: true, db:p1.source? p1.source: '', new: p2.source? p2.source: '',  showForm: 'merge-person'  });
            }
            /*
            if (p1.contact.DNC != p2.contact.DNC ) {
                differences.push({item: 'Contact DNC',  different: true, 
                db:p1.contact.DNC? p1.contact.DNC.toString(): '', new: p2.contact.DNC? p2.contact.DNC.toString():'' });
            }
    
            */
            if (p1.media &&  p2.media && (p1.media.photo.permission != p2.media.photo.permission)) {
                differences.push({item: 'Photo Permission',  different: true, 
                db:p1.media.photo.permission? p1.media.photo.permission.toString(): '', new: p2.media.photo.permission? p2.media.photo.permission.toString():'',  showForm: 'media'   });
            }
            if (p1.media &&  p2.media && (p1.media.video.permission != p2.media.video.permission)) {
                differences.push({item: 'Video Permission',  different: true, 
                db:p1.media.video.permission? p1.media.video.permission.toString(): '', new: p2.media.video.permission? p2.media.video.permission.toString():'',  showForm: 'media'  });
            }
            if (p1.contact.names.name.first.toLowerCase() != p2.contact.names.name.first.toLowerCase()) {
                differences.push({item: 'First Name',  different: true, db:p1.contact.names.name.first, new: p2.contact.names.name.first,  showForm: 'name_form'  });
            }
            if (p1.contact.names.name.last.toLowerCase() != p2.contact.names.name.last.toLowerCase()) {
                differences.push({item: 'Last Name',  different: true, db:p1.contact.names.name.last, new: p2.contact.names.name.last ,  showForm: 'name_form' });
            }
            /* Ignore DNC changes
            if (p1.contact.email.DNC != p2.contact.email.DNC) {
                differences.push({item: 'Email DNC',  different: true, db:p1.contact.email.DNC.toString(), new: p2.contact.email.DNC.toString() });
            }
            */
    
            if (p1.contact.email.items.length != p2.contact.email.items.length) {
                differences.push({item: 'Number of emails', different: true, 
                db:p1.contact.email.items.length.toString(), new: p2.contact.email.items.length.toString(),  showForm: 'email_form'  });
            } else if (p1.contact.email.items.length > 0 &&  p2.contact.email.items.length > 0  && // Must both be > 0 or more to get here
                p1.contact.email.items[0].value && p2.contact.email.items[0].value &&
                 (p1.contact.email.items[0].value.toLowerCase() != p2.contact.email.items[0].value.toLowerCase())) {
                differences.push({item: 'Email values',  different: true, db:p1.contact.email.items[0].value, new: p2.contact.email.items[0].value,  showForm: 'email_form'  });
            }
            if (p1.contact.phone.items.length != p2.contact.phone.items.length) {
                differences.push({item: 'Number of phone numbers', different: true, 
                db:p1.contact.phone.items.length.toString(), new: p2.contact.phone.items.length.toString(),  showForm: 'phone_form'  });
            } else if (p1.contact.phone.items.length > 0  && p2.contact.phone.items.length > 0 &&// Must both be > 0 or more to get here
             (p1.contact.phone.items[0].number != p2.contact.phone.items[0].number  && p2.contact.phone.items[0].number != '' )) {
                differences.push({item: 'Phone values',  different: true, db:p1.contact.phone.items[0].number, new: p2.contact.phone.items[0].number,  showForm: 'phone_form'  });
            }
            if (p1.contact.address.items.length != p2.contact.address.items.length) {
                differences.push({item: 'Number of addresses', different: true, 
                    db:p1.contact.address.items.length.toString(), new: p2.contact.address.items.length.toString(),  showForm: 'address_form'  });
            } else if (p1.contact.address.items.length > 0 && p2.contact.address.items.length > 0) {
                if (p1.contact.address.items[0].street1 && p2.contact.address.items[0].street1 && 
                        p1.contact.address.items[0].street1.toLowerCase() != p2.contact.address.items[0].street1.toLowerCase()) {
                    differences.push({item: 'Address Street1',  different: true, 
                        db:p1.contact.address.items[0].street1? p1.contact.address.items[0].street1: '', 
                        new: p2.contact.address.items[0].street1? p2.contact.address.items[0].street1: '' ,  showForm: 'address_form'});
                }
                if (p1.contact.address.items[0].street2 && p2.contact.address.items[0].street2 && 
                        p1.contact.address.items[0].street2.toLowerCase() != p2.contact.address.items[0].street2.toLowerCase()) {
                    differences.push({item: 'Address Street2',  different: true,  
                        db:p1.contact.address.items[0].street2? p1.contact.address.items[0].street2: '', 
                        new: p2.contact.address.items[0].street2? p2.contact.address.items[0].street2: '',  showForm: 'address_form' });
                }
                if (p1.contact.address.items[0].city && p2.contact.address.items[0].city  &&
                    p1.contact.address.items[0].city.toLowerCase() != p2.contact.address.items[0].city.toLowerCase()  &&  p2.contact.address.items[0].city != '') {
                    differences.push({item: 'Address City',  different: true, 
                    db:p1.contact.address.items[0].city? p1.contact.address.items[0].city: '', 
                    new: p2.contact.address.items[0].city? p2.contact.address.items[0].city: '',  showForm: 'address_form' });
                }
                if (p1.contact.address.items[0].zip && (p1.contact.address.items[0].zip.toLowerCase() != p2.contact.address.items[0].zip.toLowerCase()) &&  (p2.contact.address.items[0].zip != '') ) {
                    differences.push({item: 'Address Zip',  different: true, 
                    db:p1.contact.address.items[0].zip? p1.contact.address.items[0].zip: '', 
                    new: p2.contact.address.items[0].zip? p2.contact.address.items[0].zip: '',  showForm: 'address_form' });
                }
                
                if (p1.contact.address.items[0].state) { // state exists
                    if (p1.contact.address.items[0].state) { // has a name
                        if ((p1.contact.address.items[0].state as any).name) { // dup name.name
                            p1.contact.address.items[0].state = p1.contact.address.items[0].state;
                        }
                    } else { //No name
                        p1.contact.address.items[0].state = ''
                    }
                } else { // No state - add it
                    p1.contact.address.items[0].state = ''
                }
    
                if (p2.contact.address.items[0].state) { // state exists
                    if (p2.contact.address.items[0].state) { // has a name
                        if (p2.contact.address.items[0].state) { // dup name.name
                            p2.contact.address.items[0].state = p2.contact.address.items[0].state
                        }
                    } else { //No name
                        p2.contact.address.items[0].state = ''
                    }
                } else { // No state - add it
                    p2.contact.address.items[0].state = ''
                }
    
                if (p1.contact.address.items[0].state && p1.contact.address.items[0].state != p2.contact.address.items[0].state  &&  p2.contact.address.items[0].state != '') {
                    differences.push({item: 'Address State',  different: true, 
                    db:p1.contact.address.items[0].state? p1.contact.address.items[0].state: '', 
                    new: p2.contact.address.items[0].state? p2.contact.address.items[0].state: '',  showForm: 'address_form' });
                }
                if (p1.contact.address.items[0].country) { // state exists
                    if ((p1.contact.address.items[0].country as any).name === null) {
                        p1.contact.address.items[0].country = 'USA';
                    } else if ((p1.contact.address.items[0].country as any).name !== null) {
                        p1.contact.address.items[0].country = (p1.contact.address.items[0].country as any).name;
                    }
                } else { // No country - add it
                    p1.contact.address.items[0].country = '';
                }
    
                if (p2.contact.address.items[0].country) { // state exists
                    if ((p2.contact.address.items[0].country as any).name === null) {
                        p2.contact.address.items[0].country = 'USA'
                    } else if ((p2.contact.address.items[0].country as any).name) {
                        p2.contact.address.items[0].country = (p2.contact.address.items[0].country as any).name;
                    }
                } else { // No state - add it
                    p2.contact.address.items[0].country = 'USA';
                }

                if (p1.contact.address.items[0].country   && p1.contact.address.items[0].country.toLowerCase() != p2.contact.address.items[0].country.toLowerCase()  && 
                     (p2.contact.address.items[0].country!= '')) {
                    differences.push({item: 'Address Country',  different: true, 
                    db:p1.contact.address.items[0].country? p1.contact.address.items[0].country: '', 
                    new: p2.contact.address.items[0].country? p2.contact.address.items[0].country: '',  showForm: 'address_form' });
                }
            }
        }
    } catch (err) {
        console.log('dIFF ERR ' + JSON.stringify((err as any).message));
        console.log('P1 CONTACT ' + JSON.stringify(p1.contact));
        console.log('P2 CONTACT ' + JSON.stringify(p2.contact));
    }

    return differences;
}