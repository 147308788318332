import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnChanges, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { FormRelation, Relation} from '../../../../../bo9-shared/models/person/relation.model';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import { RelationDialogComponent } from '../../dialogs/relationDialog/relationDialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchService } from 'src/services/search/search.service';
import { RelationsService } from 'src/services/relations/relations.service';
import { userIsAdmin } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { Router } from '@angular/router';


@Component({
  selector: 'relation-list',
  templateUrl: './relationList.component.html',
  styleUrls: ['./relationList.component.scss'],
  // make fade in animation available to this component

})
export class RelationListComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() from: Person; // Key
  @Input() canAll: boolean;
  @Input() attendees: any[];
  @Input() name: string;
  @Output() notifyParent: EventEmitter<Relation> = new EventEmitter<Relation>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public displayedColumns = ['edit','from','to', 'relationType', 'naturalNumber','email','source', 'birthOrder','status','delete'];
  public dataSource = new MatTableDataSource<FormRelation>();
  messagesLoading$: Observable<boolean>;
  subscriptions: Subscription[] = [];
  currentRelationshipPane = 'center';
  valid: false;

  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;

  currentI$: Observable<FormRelation>;
  currentPerson: Person;
  RelationForm: FormGroup;
  loading = false;

  relations: FormRelation[] =[];
  notMe: any[] = [];
  operation = '';

  validationMessages = validationMessages;
	user: Person;
  pageSize: number;

  PearlModuleTypes$: Observable<string[]>;


  constructor(
	  private auth: AuthService,
    private router: Router,
    private arangoService: ArangoService,
    private dialog: MatDialog,
    private searchService: SearchService,
    private relationsService: RelationsService
    )
  {
    this.currentRelationshipPane = 'center';
    this.from = null;
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'RelationListComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 

	 this.displayedColumns = (userIsAdmin(this.user))
									? ['edit','from','person', 'relationType', 'naturalNumber','email','source', 'birthOrder','status','delete']
									: ['edit','person', 'relationType', 'naturalNumber','email','status','delete'];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  ngOnChanges() {
    if (!this.loading) {
      this.loadRelations(true);
    }

  }

  loadRelations(force?: boolean) {
    if (this.user.preferences) {
      this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }
    this.loading = true;
	 this.currentRelationshipPane = 'center';

    if (this.attendees) {
      this.notMe = [];
      for (const att of this.attendees) {
        if (att._key.toString() !== this.from._key) {
          att.name = att.first + ' ' + att.last;
          this.notMe.push(att);
        }
      }
      console.log('attendees' + JSON.stringify(this.notMe));
    }



    this.dataSource.data = [];
    console.log(' Loadrelations    '+ JSON.stringify(this.from));

    const gr = this.relationsService.getRelations(this.from? this.from._key: '')
    .then( (result: any[]) => {
      this.loading = false;
      this.dataSource.data = result;
    })


  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }

  onClickEdit(p:FormRelation) {
    //console.log('Expand Invite  '+ JSON.stringify(p));
    this.currentI$ = of(p);
    this.operation= 'Edit';
	 this.currentRelationshipPane = 'right';

  }

  onClickDelete(p: FormRelation) {
    const dRef = this.dialog.open(RelationDialogComponent, {
      width: '55%',
      height: '25%',
      data: {relation: p}
    });

    this.subscriptions.push( dRef.afterClosed()
    .subscribe( async (result: Relation) => {
      console.log('Selected result is ' + JSON.stringify(result));
      if (result) {
        const s = await firstValueFrom(this.relationsService.deleteRelation(result, this.from._key));
        alert("Relation deleted");
        this.loadRelations(true);
      }
    
    }))
  }

  onClickAdd(p:FormRelation) {
	  this.operation= 'Edit';
    this.currentRelationshipPane = 'right';
    this.currentI$ = of(p);
  }

  makeForm() {

  }


  ngOnInit() {
    //this.loadRelations();
  }


  onRefreshClick() {
    if (this.canAll) {
      this.from=null;
    }
    this.loadRelations(true);
  }
  onPageChange(event){
    console.log(event);
  }

  onCloseClick() {
    this.currentRelationshipPane = 'center';
    this.operation= '';
  }


  personSubmittedHandler($event: any) {
    console.log(' Person  submitted '+ JSON.stringify($event));
    this.currentRelationshipPane = 'center';
  }

  relateSubmittedHandler($event: any) {
    console.log(' Relate  submitted '+ JSON.stringify($event));
    this.loadRelations(true);
    this.currentRelationshipPane = 'center';
    this.operation = '';
  }

}
