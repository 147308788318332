// src/app/auth/auth-guard.service.ts
import { Injectable, setTestabilityGetter } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

import { Capabilities } from '../../../../bo9-shared/models/person/capabilities.model';

@Injectable()
export class AuthGuardService  {
  // Also RolesMethods for testing strings
  constructor(public auth: AuthService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {

    const expectedRole = route.data.expectedRole as Capabilities;
    
    if (!this.auth.isAuthenticated()) {
      if (this.auth.wasEverCapable()) {
        alert('EMail support&#64;bodyof9.com - Permission /Authentication Error');  // Only show if used to be capable, ie after an ok login
      }

      this.router.navigate(['login']);
      return false;
    } else {
      if (!this.auth.checkCapable(expectedRole)) {
        if (this.auth.wasEverCapable()) {
         alert('EMail support&#64;bodyof9.com - Capability Error'); 
        }
        this.router.navigate(['login']);
      } else {
        return true;
      }
    }

    alert('EMail support&#64;bodyof9.com - Permission /Session Error'); 
    this.router.navigate(['login']);
    return false;
  }

 
}
