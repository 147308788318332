import { DateStringType } from "../BO9_base/BO9_base.model";
import { DateToYYMMDDhhmmss } from '../../helpers/time.helper';

export interface PhoneContactItem {
    type: string;
    status: string;
    number: string;
    countrycode: string;
    primary?: boolean
}
export enum PhoneContactType {
   "Home",
    "Mobile",
    "Other"
}

export interface PhoneType {
    updatedAt: DateStringType;
    items: PhoneContactItem[];
    DNC: boolean;
}

export function getPrimaryPhoneNumber(phone: PhoneType): string {
    if (phone && phone.items && phone.items.length >0){
        for (const p of phone.items) {
            if (p.primary) {
                return (p.number)
            }
        }
        return phone.items[0].number; // No prmary assume first one
    }

return ('');
}

export function addPhone( Phone: PhoneType | null, DNC: boolean, number: string, countryCode?: string, type?: string, status?: string ): PhoneType {
    const newItem: PhoneContactItem = {
        type: type? type: '',
        number: number,
        countrycode: countryCode? countryCode: '01',
        status: status ? status: ''
    }
    let newIA: PhoneContactItem[];
    if (Phone && Phone.items.length > 0) {
        newIA = Phone.items;
        newIA.push(newItem);
    } else {
        newIA = [newItem]
    }
    const newPhone: PhoneType = {
        updatedAt: DateToYYMMDDhhmmss(),
        DNC: DNC,
        items: newIA
    }
    return newPhone
}

export function existingPhoneNumber(phones: PhoneType, pnum: string) {
    let result: boolean = false;

  //  console.log('-----------existing addr '  + JSON.stringify(phones));
   // console.log('-----------parts '  + JSON.stringify(pnum));

    if (phones) {

        if (phones.items === null) { return false;}

        for (let e of phones.items ) {
            if (e.number === pnum) {
                return(true);
            }
        }
    }         
    return(false);
}

export function mergePhones(original: PhoneType, additional: PhoneType): PhoneType {
    let newP: PhoneType = {
        updatedAt: DateToYYMMDDhhmmss(new Date),
        DNC: (original.DNC || additional.DNC),
        items: []

    }
   // console.log('-----------existing phone '  + JSON.stringify(original));
  //  console.log('-----------phone parts '  + JSON.stringify(additional));
    for (const orig of original.items) {
        if (orig && orig.number  && orig.number != '') {
            newP.items.push(orig);
        }
    }
    for (let e=0; e< additional.items.length; e++) { // check each to see if it needs to be added
        if (additional.items[e].number && additional.items[e].number!=='') {
            if (!existingPhoneNumber(newP, additional.items[e].number)) {
                const newPhone: PhoneContactItem = {
                    number: additional.items[e].number,
                    status: additional.items[e].status,
                    type: additional.items[e].type,
                    countrycode: additional.items[e].countrycode,
        
                }
                newP.items.push(newPhone); // onto end
            }

        }
    }
    return(newP);
}