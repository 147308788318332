import { createUUID } from "../../uuid.service";



export interface CECHours {
    hours: number;
    resourcePercentage: number;
}

export interface RegisterHours {
    attended: number | string;
    MT: CECHours;
    ICF: CECHours;
    BO9: CECHours
}

export function mkMTRegisterHours (): RegisterHours {
    return ({
        attended: 0,
        BO9: {
          hours:0,
          resourcePercentage:0
        },
        MT: {
        hours:0,
        resourcePercentage:0
        },
        ICF: {
            hours:0,
            resourcePercentage:0
        }
    })
}

export function addRegisterHours (orig: RegisterHours, add: RegisterHours): RegisterHours {
    if (orig &&  add   ) {
        return ({
            attended: +orig.attended + +add.attended,
            BO9: {
              hours:+orig.BO9?.hours+ +add.BO9?.hours,
              resourcePercentage:add.BO9.resourcePercentage
            },
            MT: {
                hours:+orig.MT?.hours+ +add.MT?.hours,
                resourcePercentage:add.MT.resourcePercentage
            },
            ICF: {
                hours:+orig.ICF?.hours+ +add.ICF?.hours,
                resourcePercentage:add.ICF.resourcePercentage
            }
        })
    } else if (orig) {
        return orig
    } else if (add) {
        return add
    } 
    return mkMTRegisterHours()
    
}

export interface Credit {
    _key?: string;
    creditId: string;
    eventCode: string;
    hours: RegisterHours;
    html: string;
    imageURL?: string;
    fileInfo?: [{
        Etag: string,
        BucketName: string,
        Key: string,
        Location: string
    }]
}

export function mkMTCredit(): Credit {
    return ({
        creditId: createUUID(),
       eventCode: '',
        hours: {
            attended: 0,
            MT: {
                hours: 0,
                resourcePercentage: 0
            },  
            ICF: {
                hours: 0,
                resourcePercentage: 0
            },
            BO9: {
                hours: 0,
                resourcePercentage: 0
            }
        },
        html: ''})
}