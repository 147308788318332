import { BO9UUID } from '../../../../bo9-shared/models/UUID.model';
import { Injectable } from '@angular/core';
import { BO9EVENTS } from '../../../../bo9-shared/models/BO9_base/BO9_base.model';
import { BO9Contact } from '../../../../bo9-shared/models/contact/contact.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import webappConfig from '../../../../bo9-shared/config/webappconfig.json';

@Injectable()
export class UserService {
    user: BO9UUID;

    constructor (
        private http: HttpClient,
    ) {

    }

    public getLoggedIn():  Observable<any>  {
     return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.LOGGEDINUSERS);

    }

    set(u: BO9UUID) {
        this.user = u;
    }

    get() : BO9UUID {
        return this.user;
    }


}