
<my-slide-panel [activePane]="currentGroupPane">

	<section leftPane>
		<button class="back-to-list" mat-stroked-button (click)="onCloseClick()">Back To List<mat-icon>arrow_forward</mat-icon></button>
		<group-form  [inputGroup]="currentI"  (notifyParent)="groupSubmittedHandler($event)"></group-form>
	</section>

	<section centerPane>
		<h2>Groups</h2>
		<div class="action">
			<span *ngIf="from !=''"><!-- only add from a person -->
				<button type="button" (click)="onClickAdd()">Add a Group <mat-icon>playlist_add</mat-icon></button>
			</span>
			<span>
				<mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="30" class='spin'>  </mat-progress-spinner>
			</span>
			<span>
				<button mat-stroked-button (click)="onRefreshClick()">Refresh<mat-icon>refresh</mat-icon></button>
			</span>
		</div>
		<table mat-table [dataSource]="dataSource" matSort>
			<ng-container matColumnDef="edit">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Edit </th>
				<td mat-cell *matCellDef="let element">
					<button type="button" (click)="onClickEdit(element)" matTooltip="Edit Group"> <mat-icon>manage_accounts</mat-icon></button>
				</td>
			</ng-container>
			<ng-container matColumnDef="add">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Members </th>
				<td mat-cell *matCellDef="let element">
					<span *ngIf="element.isAdmin"><button type="button" (click)="onClickMember(element)" matTooltip="Edit Members"> <mat-icon>group</mat-icon></button></span>
				</td>
			</ng-container>
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
				<td mat-cell *matCellDef="let element"> {{element.group.groupName}} </td>
			</ng-container>
			<ng-container matColumnDef="members">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Members </th>
				<td mat-cell *matCellDef="let element"> {{element.members.length}} </td>
			</ng-container>
			<ng-container matColumnDef="creator">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Creator </th>
				<td mat-cell *matCellDef="let element"> {{element.creatorName}} </td>
			</ng-container>
			<ng-container matColumnDef="groupType">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Group Type </th>
				<td mat-cell *matCellDef="let element"> {{element.group.groupType}} </td>
			</ng-container>
			<ng-container matColumnDef="delete">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Delete </th>
				<td mat-cell *matCellDef="let element">
					<span *ngIf="element.isAdmin && element.members.length == 1">
						<button type="button" (click)="onClickDelete(element)"> <mat-icon>delete</mat-icon></button>
					</span>
					<span *ngIf="!element.isAdmin  && from!= ''">
						<button type="button" (click)="onClickLeave(element)"> <mat-icon>logout</mat-icon></button>
					</span>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
		<mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]"></mat-paginator>
	</section>

	<section rightPane>
		<groupmember-list [inputGroup]="currentfg$ | async"  (notifyParent)="groupMemberSubmittedHandler($event)" [client]="'false'"></groupmember-list>
	</section>

</my-slide-panel>
