<my-slide-panel [activePane]="currentSubscriptionPane">
	<section leftPane>
		<button mat-stroked-button (click)="onCloseClick()">Close</button>
		<span *ngIf="currentP$"><update-product-form  [product]="currentP$ | async"></update-product-form ></span>

	</section>
	<section centerPane>
		<h2>Product List</h2>
		<mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="30" class='spin'> </mat-progress-spinner>
		<table mat-table [dataSource]="dataSource" matSort>
			
			<ng-container matColumnDef="update">
				<th mat-header-cell *matHeaderCellDef> Update</th>
				<td mat-cell *matCellDef="let element"><span *ngIf="element.program!= ''"><button mat-flat-button color="primary" (click)="onClickUpdate(element)">Update</button></span></td>
			  </ng-container>

			  <ng-container matColumnDef="wooid">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Woo Id</th>
				<td mat-cell *matCellDef="let element">{{element.externalid}} </td>
			  </ng-container>
			  <ng-container matColumnDef="product">
				<th mat-header-cell *matHeaderCellDef mat-sort-header="title"> Product</th>
				<td mat-cell *matCellDef="let element"> {{element.title}} </td>
			  </ng-container>
			  <ng-container matColumnDef="categories" >
				<th mat-header-cell *matHeaderCellDef mat-sort-header="category"> Categories</th>
				<td mat-cell *matCellDef="let element"> {{element.category}} </td>
			  </ng-container>
			  <ng-container matColumnDef="sku">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> SKU</th>
				<td mat-cell *matCellDef="let element" > {{element.sku}} </td>
			  </ng-container>
			  <ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
				<td mat-cell *matCellDef="let element" > {{element.status}} </td>
			  </ng-container>
			  <ng-container matColumnDef="price">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Price</th>
				<td mat-cell *matCellDef="let element">${{element.price | number:'1.2-2'}} </td>
			  </ng-container>
			  <ng-container matColumnDef="assign">
				<th mat-header-cell *matHeaderCellDef> Assign</th>
				<td mat-cell *matCellDef="let element"> 					
					<span *ngIf="isAdmin()">
					<button mat-flat-button color="primary" name="xferbutton" (click)="onClickAssign(element)"> Assign </button>
				</span></td>
			  </ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
	  
		</table>
		<mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]">
		</mat-paginator>
		
	</section>
	<section rightPane>
		<button mat-stroked-button (click)="onCloseClick()">Close</button>
		<span *ngIf="currentP$"><assign-product-form  [product]="currentP$ | async"></assign-product-form ></span>

	</section>
</my-slide-panel>
