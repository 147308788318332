import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';

import { MatDialogModule } from '@angular/material/dialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationComponent } from '../displayComponents/navigation/app/navigation.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { MessagesComponent } from '../pages/messages/messages.component';

import { LoginComponent } from '../pages/login/login.component';

import { SupportComponent } from '../pages/support/support.component';
import { CustomMaterialModule } from './material.module';
import { ToolbarComponent } from 'src/displayComponents/toolbar/toolbar.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatStepperModule } from '@angular/material/stepper';

import { LoginService } from 'src/services/loginservice/login.service';

import { HttpClient,  HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuardService } from 'src/services/auth/auth-guard.service';
import { AuthService } from 'src/services/auth/auth.service';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLayoutComponent } from 'src/pages/layouts/applayout.component';
import { WebLayoutComponent } from 'src/pages/layouts/weblayout.component';
import { CommunityUpdateService } from 'src/services/communityupdateservice/communityupdate.service';
import { AuthInterceptor } from 'src/services/auth/authinterceptor';
import { ChatMessageService } from 'src/services/chatmessageservice/chatevent.service';
import { NotFoundComponent } from 'src/pages/notfound/notfound.component';
import { ChatseenMessageservice } from 'src/services/chatmessageservice/chatseenevent.service';
import { ChatRoomService } from 'src/services/chatmessageservice/chatroom.service';
import { SystemService } from 'src/services/system.service';
import { MatTableModule } from '@angular/material/table';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule as MatAutocompleteModule } from '@angular/material/autocomplete';
import { EventEmitterService } from 'src/services/events/events.service';

import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CalendarComponent } from 'src/externals/ngx-calendar/calendar/calendar.component';
import { WebContactComponent } from 'src/pages/website/contact/contact.component';
import { WebHomeComponent } from 'src/pages/website/home/home.component';
import { WebNavigationComponent } from 'src/displayComponents/navigation/website/webnavigation.component';
import { WebsiteService } from 'src/services/website/website.services';
import { WebFooterComponent } from 'src/displayComponents/navigation/webfooter/webfooter.component';

import { QuillModule } from 'ngx-quill'

import { ArangoService } from 'src/services/arangoservice/arango.service';
import { PeopleComponent } from 'src/pages/people/people.component';
import { PearlsComponent } from 'src/pages/pearls/pearls.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SlidePanelComponent } from 'src/displayComponents/slide-panel/slide-panel.component';
import { ContactFormComponent } from 'src/displayComponents/forms/contactForm/contactForm.component';
import { NameFormComponent } from 'src/displayComponents/forms/nameForm/nameForm.component';

import { PasswordFormComponent } from 'src/displayComponents/forms/passwordForm/passwordForm.component';
import { OtherNamesFormComponent } from 'src/displayComponents/forms/nameForm/otherNamesForm.component';
import { NatNumFormComponent } from 'src/displayComponents/forms/naturalNumberForm/naturalNumberForm.component';
import { AddressFormComponent } from 'src/displayComponents/forms/addressesForm/addressForm.component';
import { EmailFormComponent } from 'src/displayComponents/forms/emailForm/emailForm.component';
import { PersonFormComponent } from 'src/displayComponents/forms/personForm/personForm.component';
import { InvitesComponent } from 'src/pages/invites/invites.component';
import { EventsComponent } from 'src/pages/events/events.component';
import { CertificationComponent } from 'src/pages/certification/certification.component';
import { AttendeesComponent } from 'src/displayComponents/lists/attendeeList/attendees.component';
import { EventDBService } from 'src/services/events/eventdb.service';
import { PhoneFormComponent } from 'src/displayComponents/forms/phoneForm/phoneForm.component';
import { PearlFormComponent } from 'src/displayComponents/forms/pearlForm/pearlForm.component';
import { EditorComponent } from 'src/displayComponents/editor/editor.component';
import { UserService } from 'src/services/auth/session.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { ActiveCampaignService } from 'src/services/activecampaign/activecampaign.service';
import { ActiveCampaignComponent } from 'src/pages/activecampaign/activecampaign.component';
import { RelationsComponent } from 'src/pages/relations/relations.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule as MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule as MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';

import { SourceFormComponent } from 'src/displayComponents/forms/naturalNumberForm/sourceForm.component';

import { FlexLayoutModule } from "@angular/flex-layout";
import { MergePersonFormComponent } from 'src/displayComponents/forms/personForm/mergePersonForm.component';
import {  InviteListComponent } from '../displayComponents/lists/inviteList/inviteList.component';
import { RelationFormComponent } from '../displayComponents/forms/relationForm/relationForm.component';
import { InviteFormComponent } from '../displayComponents/forms/inviteForm/inviteForm.component';
import { RelationListComponent } from '../displayComponents/lists/relationList/relationList.component';
import { PageComponent } from 'src/pages/page/page.component';
import { PageFormComponent } from 'src/displayComponents/forms/pageForm/pageForm.component';
import { PearlDialogComponent } from 'src/displayComponents/dialogs/pearlDialog/pearlDialog.component';
import { InviteDialogComponent } from 'src/displayComponents/dialogs/inviteDialog/inviteDialog.component';
import { RelationDialogComponent } from 'src/displayComponents/dialogs/relationDialog/relationDialog.component';
import { SelectsService } from 'src/services/selects/selects.service';
import { InvitesService } from 'src/services/invites/invites.service';
import { SearchService } from 'src/services/search/search.service';
import { RelationsService } from 'src/services/relations/relations.service';
import { ActivityListComponent } from 'src/displayComponents/lists/activityList/activityList.component';
import { ActivityService } from 'src/services/activity/activity.service';
import { ActivityFormComponent } from 'src/displayComponents/forms/activityForm/activityForm.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PearlsInfoComponent } from 'src/displayComponents/pearlsinfo/pearlsinfo.component';
import { EventFormComponent } from 'src/displayComponents/forms/eventForm/eventForm.component';
import { AttendeeFormComponent } from 'src/displayComponents/forms/attendeeForm/attendeeForm.component';
import { SendEmailFormComponent } from 'src/displayComponents/forms/emailForm/individualEmails/sendEmailForm.component';
import { EmailService } from 'src/services/email/email.service';
import { MatCardModule as MatCardModule } from '@angular/material/card';
import { AppLoginComponent } from 'src/pages/website/applogin/applogin.component';
import { NotifyComponent } from 'src/displayComponents/notify/notify.component';
import { NotifyDialogComponent } from 'src/displayComponents/dialogs/notifyDialog/notifyDialog.component';
import { ProfileComponent } from 'src/pages/profile/profile.component';
import { PearlListComponent } from 'src/displayComponents/lists/pearlList/pearlList.component';
import { ProductsService } from 'src/services/products/products.service';
import { ProfileFormComponent } from 'src/displayComponents/forms/profileForm/profileForm.component';
import { HelpDialogComponent } from 'src/displayComponents/dialogs/helpDialog/helpDialog.component';
import { TemplateComponent } from 'src/pages/template/template.component';
import { TemplateFormComponent } from 'src/displayComponents/forms/templateForm/templateForm.component';
import { SelectListDialogComponent } from 'src/displayComponents/dialogs/selectListDialog/selectListDialog.component';
import { SubscriptionListComponent } from 'src/displayComponents/lists/subscriptionList/subscriptionList.component';
import { DeleteTemplateDialogComponent } from 'src/displayComponents/dialogs/deleteTemplateDialog/deleteTemplateDialog.component';
import { TestTemplateDialogComponent } from 'src/displayComponents/dialogs/testTemplateDialog/testTemplateDialog.component';
import { EditorModule } from "@tinymce/tinymce-angular";

import { TemplateService } from 'src/services/template/template.service';
import {  SelectPersonDialogComponent } from 'src/displayComponents/dialogs/selectPersonDialog/selectPersonDialog.component';
import { GroupListComponent } from 'src/displayComponents/lists/groupList/groupList.component';
import { GroupFormComponent } from 'src/displayComponents/forms/groupForm/groupForm.component';
import { GroupsComponent } from 'src/pages/groups/groups.component';
import { GroupsService } from 'src/services/groups/groups.service';
import { groupDeleteDialogComponent } from 'src/displayComponents/dialogs/groupDialog/groupDeleteDialog.component';
import { GroupMemberListComponent } from 'src/displayComponents/lists/groupMemberList/groupMemberList.component';
import { OrdersComponent } from 'src/pages/orders/orders.component';
import { WooCommerceService } from 'src/services/woocommerce/woocommerce.service';
import { OrderListComponent } from 'src/displayComponents/lists/orderList/orderList.component';
import { SelectCustomerDialogComponent } from 'src/displayComponents/dialogs/selectCustomerDialog/selectCustomerDialog.component';
import { SelectNameDialogComponent } from 'src/displayComponents/dialogs/selectNameDialog/selectNameDialog.component';
import { HttpCancelService } from 'src/services/httpServices/httpcancel.service';
import { HttpCancelInterceptor } from 'src/services/httpServices/httpCancelInterceptor';
import { HomeComponent } from 'src/pages/home/home.component';
import { HomeNavComponent } from 'src/displayComponents/navigation/homenav/homenav.component';
import { HomeLayoutComponent } from 'src/pages/layouts/homelayout.component';
import { PearlService } from 'src/services/pearl/pearl.service';
import { SeenPearlListComponent } from 'src/displayComponents/lists/seenPearlList/seenPearlList.component';
import { GroupMemberFormComponent } from 'src/displayComponents/forms/groupMemberForm/groupMemberForm.component';
import { PartnerLayoutComponent } from 'src/pages/layouts/partnerlayout.component';
import { GuildMembersComponent } from 'src/pages/guildmembers/guildmembers.component';;
import { PartnerNavComponent } from 'src/displayComponents/navigation/partnernav/partnernav.component';
import { SysRolesFormComponent } from 'src/displayComponents/forms/sysrolesForm/sysrolesForm.component';
import { MemberService } from 'src/services/memberService/member.service';
import { SystemVariablesService } from 'src/services/systemvariables/systemvariables.service';
import { EmailCheckDialogComponent } from 'src/displayComponents/dialogs/emailCheckDialog/emailCheckDialog.component';
import { AffiliateFormComponent } from 'src/displayComponents/forms/affiliateForm/affiliateForm.component';
import { PurchaseListComponent } from 'src/displayComponents/lists/purchaseList/purchaseList.component';
import { PurchaseItemsListComponent } from 'src/displayComponents/lists/purchaseItemsList/purchaseItemsList.component';
import { ProductListComponent } from 'src/displayComponents/lists/productList/productList.component';
import { ProductsPageComponent } from 'src/pages/products/products.component';
import { AssignProductFormFormComponent } from 'src/displayComponents/forms/assignProductForm/assignproductForm.component';
import { UpdateProductFormFormComponent } from 'src/displayComponents/forms/updateProductForm/updateproductForm.component';
import { PartnerFormComponent } from 'src/displayComponents/forms/partnerForm/partnerForm.component';
import { ProgramItemsListComponent } from 'src/displayComponents/lists/programItemsList/programItemsList.component';
import { PersonalEventsComponent } from 'src/displayComponents/lists/personal-events-list/personal-events.component';
import { UpcomingEventsComponent } from 'src/displayComponents/lists/upcoming-events-list/upcoming-events.component';
import { MovementComponent } from 'src/pages/movement/movement.component';
import { MovementFormComponent } from 'src/displayComponents/forms/movementForm/movementForm.component';
import { SelectMeasurementDialogComponent } from 'src/displayComponents/dialogs/selectMeasurementDialog/selectMeasurementDialog.component';
import { ClientMemberListComponent } from 'src/displayComponents/lists/clientMemberList /clientMemberList.component';
import { ClientMemberFormComponent } from 'src/displayComponents/forms/clientMemberForm/clientMemberForm.component';
import { SendGroupEmailFormComponent } from 'src/displayComponents/forms/emailForm/groupEmails/sendGroupEmailForm.component';
import { SelectIdentificationDialogComponent } from 'src/displayComponents/dialogs/selectIdentificationDialog/selectIdentificationDialog.component';
import { EmailDialogComponent } from 'src/displayComponents/dialogs/emailDialog/emailDialog.component';
import { GuildComponent } from 'src/displayComponents/guild/guild.component';
import { ResourceFormComponent } from 'src/displayComponents/forms/resourceForm/resourceForm.component';
import { PartnerResourceListComponent } from 'src/displayComponents/lists/partnerResources/partnerResourceList.component';
import { MaintenanceComponent } from 'src/pages/maintenance/maintenance.component';
import { EmailListDialogComponent } from 'src/displayComponents/dialogs/emailListDialog/emailListDialog.component';
import { VideoPlayerDialogComponent } from 'src/displayComponents/dialogs/videoPlayerDialog/videoPlayerDialog.component';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { CustomErrorHandlerService } from 'src/services/errorService/error.service';
import { VisitListComponent } from 'src/displayComponents/lists/visitList/visitList.component';
import { ErrorsComponent } from 'src/pages/errors/errors.component';
import { PrePaidMemberFormComponent } from 'src/displayComponents/forms/prepaidMemberForm/prepaidMemberForm.component';
import { WebScheduleComponent } from 'src/pages/website/schedule/schedule.component';
import { NgxRerenderModule } from 'ngx-rerender';
import { ScriptService } from 'src/services/scriptservice/script.service';
import { CalendlyService } from 'src/services/calendlyservice/calendly.service';
import { MEAListComponent } from 'src/displayComponents/lists/meaList/meaList.component';
import { DealsComponent } from 'src/pages/deals/deals.component';
import { CalendlyBookingComponent } from 'src/displayComponents/calendlybookings/calendlybookings.component';
import { SocialMediaFormComponent } from 'src/displayComponents/forms/socialForm/socialForm.component';
import { EventCalendarComponent } from 'src/displayComponents/calendars/eventCalendar/calendar.component';
import { AddEventDialogComponent } from 'src/externals/ngx-calendar/dialog/add-event-dialog/add-event-dialog.component';
import { EditEventDialogComponent } from 'src/externals/ngx-calendar/dialog/edit-event-dialog/edit-event-dialog.component';
import { EventDialogComponent } from 'src/externals/ngx-calendar/dialog/event-dialog/event-dialog.component';
import { StudentsComponent } from 'src/pages/students/students.component';
import { InsitututeService } from 'src/services/instituteService/institute.service';
import { CourseListComponent } from 'src/displayComponents/lists/courseList/courseList.component';
import { HoursFormComponent } from 'src/displayComponents/forms/hours-form/hoursForm.component';
import { AttendedListDialogComponent } from 'src/displayComponents/dialogs/attendedListDialog/attendedListDialog.component';
import { CreditFormComponent } from 'src/displayComponents/forms/creditForm/creditForm.component';
import { CreditsComponent } from 'src/pages/credits/credits.component';
import { CertificateListComponent } from 'src/displayComponents/lists/certificateList/certificateList.component';
import { CertificateDialogComponent } from 'src/displayComponents/dialogs/certificateDialog/certificateDialog.component';
import { SelectProductDialogComponent } from 'src/displayComponents/dialogs/selectProductDialog/selectProductDialog.component';
import { IdentifiedListComponent } from 'src/displayComponents/lists/identifiedList/identifiedList.component';
import { IdentifiedFormComponent } from 'src/displayComponents/forms/identifiedForm/identifiedForm.component';
import { SimpleAttendeeFormComponent } from 'src/displayComponents/forms/attendeeForm/simpleattendeeForm.component';
import { DupEmailList } from 'src/displayComponents/lists/dupEmailList/dupEmailList.component';
import { AgreementComponent } from 'src/pages/agreement/agreeement.component';
import { agreementFormComponent } from 'src/displayComponents/forms/agreementForm/agreementForm.component';
import { AgreementService } from 'src/services/agreementservice/agreement.service';
import { AgreementDialogComponent } from 'src/displayComponents/dialogs/agreementDialog/agreementDialog.component';
import  {  PdfViewerModule  }  from  'ng2-pdf-viewer';
import { DirectoryComponent } from 'src/displayComponents/lists/directoryList/directory.component';
import { AssertedComponent } from 'src/displayComponents/lists/attendeeList/asserted.component';
/* Add Amplify imports 
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';

//Configure Amplify resources 
Amplify.configure(awsconfig);

*/
@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    VideoPlayerDialogComponent,
    HomeNavComponent,
    WebNavigationComponent,
    WebFooterComponent,
    DashboardComponent,
    MEAListComponent,
    DirectoryComponent,
    AssertedComponent,
    HomeComponent,
    HomeLayoutComponent,
    MessagesComponent,
    NotifyComponent,
    WebContactComponent,
    WebHomeComponent,
    NotifyDialogComponent,
    EventCalendarComponent,
    HoursFormComponent,
    SocialMediaFormComponent,
    WebHomeComponent,
    SimpleAttendeeFormComponent,
    ActivityFormComponent,
    LoginComponent,
    SupportComponent,
    CalendlyBookingComponent,
    ToolbarComponent,
    AppLayoutComponent,
    WebLayoutComponent,
    ResourceFormComponent,
    PartnerResourceListComponent,
    MaintenanceComponent,
    OrdersComponent,
    NotFoundComponent,
    PeopleComponent,
    PearlsComponent,
    PageComponent,
    AppLoginComponent,
    ErrorsComponent,
    PageFormComponent,
    SlidePanelComponent,
    ContactFormComponent,
    NameFormComponent,
    AddressFormComponent,
    EmailDialogComponent,
    SelectIdentificationDialogComponent,
    PurchaseListComponent,
    ProductListComponent,
    SendGroupEmailFormComponent,
    SysRolesFormComponent,
    OtherNamesFormComponent,
    PasswordFormComponent,
    PurchaseItemsListComponent,
    DupEmailList,
    EmailFormComponent,
    PartnerNavComponent,
    PhoneFormComponent,
    NatNumFormComponent,
    RelationListComponent,
    RelationsComponent,
    agreementFormComponent,
    AffiliateFormComponent,
    PersonFormComponent,
    MergePersonFormComponent,
    RelationListComponent,
    PartnerLayoutComponent,
    GuildComponent,
    GuildMembersComponent,
    RelationFormComponent,
    AgreementComponent,
    InviteFormComponent,
    PearlFormComponent,
    EditorComponent,
    InvitesComponent,
    EventsComponent,
    CertificationComponent,
    AttendeesComponent,
    ActiveCampaignComponent,
    SourceFormComponent,
    AttendedListDialogComponent,
    EventFormComponent,
    InviteListComponent,
    SubscriptionListComponent,
    AttendeeFormComponent,
    ActivityListComponent,
    PearlDialogComponent,
    InviteDialogComponent,
    TemplateComponent,
    SelectProductDialogComponent,
    SelectMeasurementDialogComponent,
    TemplateFormComponent,
    TestTemplateDialogComponent,
    ClientMemberListComponent,
    ClientMemberFormComponent,
    DeleteTemplateDialogComponent,
    ProgramItemsListComponent,
    PersonalEventsComponent,
    MovementFormComponent,
    UpcomingEventsComponent,
    MovementComponent,
    HelpDialogComponent,
    EmailListDialogComponent,
    GroupListComponent,
    DealsComponent,
    GroupFormComponent,
    GroupsComponent,
    GroupMemberListComponent,
    groupDeleteDialogComponent,
    PartnerFormComponent,
    OrderListComponent,
    ClientMemberListComponent,
    CourseListComponent,
    UpdateProductFormFormComponent,
    ProfileFormComponent,
    CreditFormComponent,
    CreditsComponent,
    CertificateListComponent,
    AssignProductFormFormComponent,
    RelationDialogComponent,
    AddEventDialogComponent,
    EditEventDialogComponent,
    EventDialogComponent,
    StudentsComponent,
    SelectListDialogComponent,
    IdentifiedListComponent,
    GroupMemberFormComponent,
    ProductsPageComponent,
    SelectPersonDialogComponent,
    EmailCheckDialogComponent,
    CertificateDialogComponent,
    SelectNameDialogComponent,
    CalendarComponent,
    WebScheduleComponent,
    SelectCustomerDialogComponent,
    AgreementDialogComponent,
    PrePaidMemberFormComponent,
    VisitListComponent,
    SendEmailFormComponent,
    IdentifiedFormComponent,
    PearlsInfoComponent,
    ProfileComponent,
    PearlListComponent,
    SeenPearlListComponent
  ],
  imports: [
    FlexLayoutModule,
 //   AmplifyUIAngularModule, /* Add Amplify module */
    VgCoreModule,
    VgControlsModule,
     VgOverlayPlayModule,
    VgBufferingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    NgxRerenderModule,
    MatProgressBarModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatAutocompleteModule,
    EditorModule,
    PdfViewerModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatTableModule,
    MatCardModule,
    MatGridListModule,
    ClipboardModule,
    MatTooltipModule,
    MatDialogModule,
    MatCheckboxModule,
    MatExpansionModule,
    FlexLayoutModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatSortModule,
    MatStepperModule,
    NgxMatFileInputModule,
    CustomMaterialModule,
    HttpClientModule,
    FormsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          [ 'link', 'image', 'video', 'formula' ],
        ]
      }
    })
  ],
  providers: [
    AgreementService,
   EventEmitterService,
   EventDBService,
   LoginService,
   UserService,
   SystemService,
   CalendlyService,
   ScriptService,
   InsitututeService,
   SystemVariablesService,
   ProductsService,
   PearlService,
   MemberService,
   HttpClient,
   TemplateService,
   SearchService,
   EmailService,
   InvitesService,
   ArangoService,
   WooCommerceService,

   RelationsService,
   AuthGuardService,
   ActivityService,
   AuthService,

   SelectsService,
   CommunityUpdateService,
   GroupsService,
   ChatMessageService,
   ChatseenMessageservice,
   ChatRoomService,
   AuthInterceptor,
   WebsiteService,
   ActiveCampaignService,
   HttpCancelService,
   { // persist data except actual messages
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },
  {
    multi: true,
    provide: HTTP_INTERCEPTORS,
    useClass: HttpCancelInterceptor
  },
  {
    provide: MAT_DATE_FORMATS,
    useValue: {
      parse: {
        dateInput: ['YYYY-MM-DD'],
      },
      display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'YYYY-MM',
        dateA11yLabel: 'YYYY-MM-DD',
        monthYearA11Label: 'YYYY-MM-DD'
      }
    }
  },
  {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: HammerGestureConfig
  },
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
    {provide: ErrorHandler, useClass: CustomErrorHandlerService},
  ],
  exports : [
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
export class AppLoadModule { }
