<div *ngIf="person" class="ident-link">
	<h2>Links to Calendly Booking Pages</h2>
	<ul *ngIf="!isMobile()">
		<li>A Coaching Session for yourself</li>
		<li>A Group Event Where the Attendees Pay at Registration (Schedule then creates an Event)</li>
		<li>A Group Event Where You pay Body of 9 for the Event and charge the Attendees whatever ticket price you decide.</li>
		<li>A 30 minute session with Susan</li>
	</ul>
	<span class="booking-link" *ngFor="let cal of booking">

		{{cal.title}}<br>
		<button mat-button [cdkCopyToClipboard]="cal.url+'?name='+person.contact.names.name.first+'/'+person.contact.names.name.last+'&email='+person.primaryEmail"><mat-icon >content_copy</mat-icon>COPY</button>
		<a href="{{cal.url}}?name={{person.contact.names.name.first}}%20{{person.contact.names.name.last}}&email={{person.primaryEmail}}" target="_blank">GO TO<mat-icon >shortcut</mat-icon></a>

	</span>
</div>
<hr>