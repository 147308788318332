import { Component } from '@angular/core';

@Component({
  selector: 'app-main-layout',
  template: `
    <app-navigation class="app-wrapper"></app-navigation>
  `,
  styles: []
})
export class AppLayoutComponent {}
