import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';

import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Observable, of, Subscription } from 'rxjs';
import { firstName, getUserName, lastName, mkEmptyPerson, mkSimplePerson, Person } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { Invite, FormInvite, InvitedStatus } from '../../../../../bo9-shared/models/person/invite.model';
import { NaturalNumber, NaturalNumberTypes } from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';
import { map, catchError, startWith, debounceTime, switchMap, finalize, tap } from 'rxjs/operators';

import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import { DateToYYMMDDhhmmss } from '../../../../../bo9-shared/helpers/time.helper';

import { SelectsService, SelectTypes } from 'src/services/selects/selects.service';
import { InvitesService } from 'src/services/invites/invites.service';
import { SearchService } from 'src/services/search/search.service';
import { findInvalidControlsRecursive } from '../../../helpers/form.helper';
import { responseEncode } from '../../../../../bo9-shared/uuid.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth/auth.service';


@Component({
  selector: 'invite-form',
  templateUrl: './inviteForm.component.html',
  styleUrls: ['./inviteForm.component.scss'],
  // make fade in animation available to this component

})
export class InviteFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() inputInvite: FormInvite;
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
  messagesLoading$: Observable<boolean>;


  people: Person[];
  valid: false;
  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;
  disableBtn = false;
  searchString: string;
  currentP$: Observable<Person>;
  InviteForm: FormGroup;
  relatingPersonName: string;

  validationMessages = validationMessages;

  PearlModuleTypes: string[];
  nnKeys: string[];
  nnSource: string[];

  emails$: Observable<string[]>;
  isLoading = false;

  emailKeys:{key: string; email: string, naturalNumber: NaturalNumber }[] =[];
  selectedKey: string;
  invites: string[];

  invited=false;
  firstname= '';
  selectedEmail: string;
  selectedInvite: string;
  toPerson: Person;
  user: Person;
  subscriptions: Subscription[] = [];
  public skills: Observable<string[]> = of(["Angular", "React", "Node", "Java"]);

  constructor(
    private formBuilder: FormBuilder,
    private arangoService: ArangoService,
    private selectsService: SelectsService,
    private invitesService: InvitesService,
    private searchService: SearchService,
    private router: Router,
    private auth: AuthService,
    ) 
  {
    this.nnKeys = Object.keys(NaturalNumber).map(key => NaturalNumber[key]).filter(value => typeof value === 'string') as string[];
    this.nnSource = Object.keys(NaturalNumberTypes).map(key => NaturalNumberTypes[key]).filter(value => typeof value === 'string') as string[];
    this.InviteForm = this.formBuilder.group({
      firstName: new FormControl('', Validators.compose([Validators.required])),
      lastName: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([ Validators.email])),
      inviteType: new FormControl(['Self'], Validators.compose([])),
      pearlModule: new FormControl(['Public'], Validators.compose([])),
      nnGuess: new FormControl('None', Validators.compose([Validators.required])),
      sourceType:  new FormControl('Asserted', Validators.compose([Validators.required])),
    });
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'InviteFormComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
  
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  ngOnInit() {
    this.subscriptions.push( this.selectsService.getSelects()
    .subscribe( (result: SelectTypes) => {
      this.PearlModuleTypes = this.selectsService.selects.pearlmoduletypes;
      this.invites = this.selectsService.selects.invitetypes;
      
    }))
   

    this.emailControl.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      tap(() => this.isLoading = true),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => this.lookup(value)
      .pipe(
        finalize( () => {this.isLoading = false;})
      )
      )
    ).subscribe ( (res) => {
      console.log(' RS is ' + JSON.stringify(res));
      this.skills= of(res);
      }
    );

  }


  displayFn(email?: any) : string {
    console.log('displayFn ' + JSON.stringify(email));
    return email ? email : ''
  }

  get emailControl() { return this.InviteForm.controls.email; }
  get firstNameControl() { return this.InviteForm.controls.firstName; }
  get lastNameControl() { return this.InviteForm.controls.lastName; }
  get eSearchControl() { return this.InviteForm.controls.eSearch;}

  optionSelected(event: any) {
    console.log('Selected  ' + JSON.stringify(event.source.value));
    this.selectedEmail = event.source.value;
    this.subscriptions.push(  this.searchService.searchEmail(event.source.value, false)
        .subscribe( (persons: Person[]) => {
          this.toPerson = persons[0];
          this.InviteForm.controls.firstName.setValue( firstName(this.toPerson));
          this.InviteForm.controls.lastName.setValue( lastName(this.toPerson));
          this.InviteForm.controls.email.setValue(this.toPerson.primaryEmail);
        }))
  }

  ngOnChanges() {
    this.selectedEmail = '';
    this.toPerson = null
    this.InviteForm.reset();
    if (this.inputInvite) {
       
        const p = this.inputInvite;
        this.toPerson = this.inputInvite.toPerson;
        this.InviteForm.patchValue({
          _key: p? p._key : '',
          firstName: p.toName? p.toName : '',
          email: p.toEmail? p.toEmail : '',
          inviteType: p.inviteType? (p.inviteType[0] !== '')? p.inviteType: ['Other'] : ['Other'],
          pearlModule: p.pearlModule? (p.pearlModule[0] !== '')? p.pearlModule: ['Public'] : ['Public'],
          nnGuess: p.naturalNumber? p.naturalNumber : NaturalNumber.None,
          sourceType: p.sourceType? p.sourceType: 'Invited',
        });
        if (this.inputInvite.toName) {
          const names = this.inputInvite.toName.split(' ');
          this.InviteForm.controls.firstName.setValue(names[0]?names[0]: '');
          this.InviteForm.controls.lastName.setValue(names[1]?names[1]: '');
        }
        if (this.inputInvite.fromFirstName && this.inputInvite.toLastName) {
          this.InviteForm.controls.firstName.setValue(this.inputInvite.toFirstName);
          this.InviteForm.controls.lastName.setValue(this.inputInvite.toLastName);
        }

     
    }
  }

  submitInviteForm() {
    console.log('Submit invite form ' + JSON.stringify(this.InviteForm.value));

    if (this.InviteForm.valid) {
      console.log('Submit invite form - valid');
      let exist = true;
        const rel: Invite = {
          _key: this.inputInvite._key,
          from: this.inputInvite.fromPerson._key,
          to: this.toPerson._key,
          invitedStatus: InvitedStatus.Invited,
          inviteType: this.InviteForm.value.inviteType,
          sourceType: this.InviteForm.value.sourceType,
          pearlModule: this.InviteForm.value.pearlModule,
          invited: this.InviteForm.value.nnGuess,
          existing: exist,
          meta: updateMeta()
        }
        rel.invitedPerson = {
          first: this.InviteForm.value.firstName,
          last: this.InviteForm.value.lastName,
          email: this.InviteForm.value.email,
          whenInvited: DateToYYMMDDhhmmss(),
          accept: responseEncode(rel.to,"Y"),
          decline: responseEncode(rel.to,"N"),
          inviteTo: this.inputInvite.fromPerson.primaryEmail,
          inviteBy: getUserName(this.inputInvite.fromPerson)
        }

        this.invitesService.updateInvite(rel)
        .subscribe( (result) => {
          alert('Invite ' + this.InviteForm.value.name + ' save result ' + JSON.stringify(result.content));
          this.notifyParent.emit('Invite Saved');
        });
    } else {
      alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.InviteForm)));
    }
  }


  lookup(value: string): Observable<any> {
    return new Observable( observer => {
      if (!value || value == '') {
        observer.next([]);
        observer.complete();
      } else {
        this.emailKeys = [];
        this.subscriptions.push(  this.searchService.searchEmail(value.toLowerCase(), false)
        .subscribe( (persons: Person[]) => {
          const emails =[];
          if (persons && persons.length > 0) {
            for (let i=0; i < persons.length; i++ ) {
              emails.push(persons[i].primaryEmail);
              const key = {
                email: persons[i].primaryEmail, 
                key:persons[i]._key, 
                naturalNumber: persons[i].naturalNumbers? persons[i].naturalNumbers.identified.naturalNumber : NaturalNumber.None }
              this.emailKeys.push(key);
            }
          }
         
          console.log('lookup  ' + JSON.stringify(emails));
          this.emails$ = of(emails)
          observer.next(emails);
          observer.complete();
        }))
      }

    })
  }
  
}
