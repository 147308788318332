
<my-slide-panel [activePane]="currentPane">
	<section leftPane>
		<button class='back-to-list' mat-stroked-button (click)="onCloseClick()">Back To List<mat-icon>arrow_forward</mat-icon></button>
		<invite-form  [inputInvite]="currentI$ | async" (notifyParent)="inviteSubmittedHandler($event)"></invite-form>
	</section>

	<section centerPane>
		<h2>Invited</h2>
		<div class="action">
			<span *ngIf="from && from !=''">
				<button type="button" (click)="onClickInvite(element)"> Invite <mat-icon>playlist_add</mat-icon></button>
			</span>
			<span>
				<mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="30" class='spin'> </mat-progress-spinner>
			</span>
			<span>
				<button mat-stroked-button (click)="onRefreshClick()">Refresh<mat-icon>refresh</mat-icon></button>
			</span>
		</div>

		<table mat-table [dataSource]="dataSource" matSort>
			<ng-container matColumnDef="edit">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> List/Edit </th>
				<td mat-cell *matCellDef="let element">
					<span *ngIf="from==''">
						<button type="button" (click)="onClickList(element)"> <mat-icon>list</mat-icon></button>
					</span>
					<span *ngIf="from !=''">
						<button type="button" (click)="onClickEdit(element)"> <mat-icon>edit</mat-icon></button>
					</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="from">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> From </th>
				<td mat-cell *matCellDef="let element"> {{element.fromFirstName}}  {{element.fromLastName}}</td>
			</ng-container>
			<ng-container matColumnDef="to">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> To </th>
				<td mat-cell *matCellDef="let element"> {{element.toFirstName}}  {{element.toLastName}}</td>
			</ng-container>
			<ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
				<td mat-cell *matCellDef="let element"> {{element.toEmail}} </td>
			</ng-container>
			<ng-container matColumnDef="inviteType">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Invite </th>
				<td mat-cell *matCellDef="let element"> {{element.inviteType}} </td>
			</ng-container>
			<ng-container matColumnDef="pearlModule">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Module </th>
				<td mat-cell *matCellDef="let element"> {{element.pearlModule}} </td>
			</ng-container>
			<ng-container matColumnDef="naturalNumber">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Nat. Num </th>
				<td mat-cell *matCellDef="let element"> {{element.naturalNumber}} </td>
			</ng-container>
			<ng-container matColumnDef="sourceType">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Source </th>
				<td mat-cell *matCellDef="let element"> {{element.sourceType}} </td>
			</ng-container>
			<ng-container matColumnDef="delete">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Delete </th>
				<td mat-cell *matCellDef="let element">
					<button type="button" (click)="onClickDelete(element._key)"> <mat-icon>delete</mat-icon></button>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		 	<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

		 </table>
		 <mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]"> </mat-paginator>
	</section>

	<section rightPane>

	</section>
</my-slide-panel>
