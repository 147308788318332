import { Component, forwardRef, ChangeDetectionStrategy, OnDestroy, OnInit, Input, OnChanges } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BO9Contact } from '../../../../../bo9-shared/models/contact/contact.model';
import { validationMessages } from 'src/helpers/form.helper';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth/auth.service';
import { Person } from '../../../../../bo9-shared/models/person/person.model';


// todo replace this with BO9Contact
export interface NameFormValues {
  title: string;
  first: string;
  middle: string
  last: string;
  }

@Component({
    selector: 'name-form',
    templateUrl: './nameForm.component.html',
    styleUrls: ['./nameForm.component.scss'],
    providers: [
        {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => NameFormComponent),
          multi: true
        },
        {
          provide: NG_VALIDATORS,
          useExisting: forwardRef(() => NameFormComponent),
          multi: true,
        }
      ],
      changeDetection: ChangeDetectionStrategy.OnPush
  })
  export class NameFormComponent implements ControlValueAccessor, OnDestroy {
    name: string;
    user: Person;
    form: FormGroup;
    subscriptions: Subscription[] = [];
    validationMessages = validationMessages;
  
    get value(): NameFormValues {
      console.log('name get '+ JSON.stringify(this.form.value));
      return this.form.value;
    }
  
    set value(value: NameFormValues) {
      console.log('name get '+ JSON.stringify(value));
      this.form.setValue(value);

      this.onChange(value);
      this.onTouched();
    }

    get firstControl() {
        return this.form.controls.first;
    }

    get lastControl() {
        return this.form.controls.last;
    }

    get titleControl() {
      return this.form.controls.title;
  }

  get middleControl() {
      return this.form.controls.middle;
  }
  
  
    constructor(private formBuilder: FormBuilder,
      private arangoService: ArangoService,
      private router: Router,     
      private auth: AuthService,
      ) {
      this.form = this.formBuilder.group({
        first: ['', Validators.compose([Validators.required])],
        middle: ['', Validators.compose([])],
        title: ['', Validators.compose([])],
        last: ['', Validators.compose([Validators.required])],
      });
      this.user = this.auth.getAuthUser();
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'NameFormComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
      this.subscriptions.push(
        this.form.valueChanges.subscribe(value => {
          //  console.log('name valuechange '+ JSON.stringify(value));
           
          this.onChange(value);
          this.onTouched();
        })
      );

    }

  
    ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  
    onChange: any = () => {

    };
    onTouched: any = () => {};
  
    registerOnChange(fn) {
      this.onChange = fn;
    }
  
    writeValue(value) {
      if (value) {
        console.log('Name write '+JSON.stringify(value))
        this.form.patchValue(value.name);
      }
  
      if (value === null) {
        this.form.reset();
      }
    }
  
    registerOnTouched(fn) {
      this.onTouched = fn;
    }
  
    validate(_: FormControl) {
      return this.form.valid ? true : false;
    }
  
    reset() {
      this.form.reset();
    }
  }