<div *ngIf="person" class='person-page'>
      <div *ngIf="operation == 'Invite'" > <invite-list [from]="person.primaryEmail" [canAll]=false ></invite-list></div>
      <div *ngIf="operation == 'Family'" > <relation-list [from]="person" [canAll]=false [name]="fromName" (notifyParent)="relateSubmittedHandler($event)"></relation-list></div>
      <div *ngIf="operation == 'Activity'" > <activity-list [from]="person._key" [canAll]=false></activity-list></div>
      <div *ngIf="operation == 'Edit'" ><profile-form [person]="person" (notifyParent)="personSubmittedHandler($event)"></profile-form></div>
      <div *ngIf="operation == 'Pearl'" ><seen-pearl-list [email]="email"></seen-pearl-list></div>
      <div *ngIf="operation == 'Subscriptions'" > <subscription-list [from]="person" [canAll]=false></subscription-list></div>
      <div *ngIf="operation == 'Groups'" > <group-list [from]="person._key" [canAll]=false></group-list></div>
     <!-- <div *ngIf="operation == 'Orders'" > <order-list [customers]="" [from]="person.primaryEmail" [canAll]=false></order-list></div> -->
</div>
