import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';


import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Person } from '../../../../../bo9-shared/models/person/person.model';

import { Subscription } from 'rxjs';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth/auth.service';


@Component({
  selector: 'app-dialog-selectPerson',
  templateUrl: './videoPlayerDialog.component.html',
  styleUrls: ['./videoPlayerDialog.component.scss'],

})

export class VideoPlayerDialogComponent implements OnInit  {
  vdata: any;
  user: Person;
  disableBtn = false;
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private router: Router,
       private arangoService: ArangoService,
    private dialogRef: MatDialogRef<VideoPlayerDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: { video: string} 
   ) {
    this.user = this.auth.getAuthUser();
    if (this.user) { // not logged in, dont send visit
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'VideoPlayerDialogComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
  }
    }

    videoPlayerInit(data: any) { // setup by html
        this.vdata = data;
        this.vdata.getDefaultMedia().subscriptions.loadedMetadata.subscribe(this.initVdo.bind(this));
      }

    activeIndex = 0;
    currentVideo = this.data.video;

    ngOnInit(): void { }


    initVdo() {
      this.vdata.play();
    }

  close() {
    this.dialogRef.close([]);
  }
}
