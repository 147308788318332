
<my-slide-panel [activePane]="currentPane">

  <div leftPane>
    <button mat-stroked-button (click)="onCloseClick()">Back<mat-icon>arrow_back</mat-icon></button> 
   
   
    <page-form [page]="selectedPage$ | async" (notifyParent)="PageSubmittedHandler($event)"></page-form>
  </div>
  <div centerPane>
    <button mat-stroked-button (click)="addPageClick()" matTooltip="Add a Page"><mat-icon>add_box</mat-icon></button>
    <button mat-stroked-button (click)="onRefreshClick()">Refresh<mat-icon>refresh</mat-icon></button>

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef> Edit </th>
        <td mat-cell *matCellDef="let element">
          <button type="button" (click)="onClickEdit(element)"> <mat-icon>edit</mat-icon></button>
        </td>
      </ng-container>
      <ng-container matColumnDef="_key">
        <th mat-header-cell *matHeaderCellDef> Key DO NOT CHANGE</th>
        <td mat-cell *matCellDef="let element"> {{element._key}} </td>
      </ng-container>
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
        <td mat-cell *matCellDef="let element"> {{element.title}} </td>
      </ng-container>
      <ng-container matColumnDef="pageType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Page Type </th>
        <td mat-cell *matCellDef="let element"> {{element.pageType}} </td>
      </ng-container>
      <ng-container matColumnDef="accesskey">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Access key </th>
        <td mat-cell *matCellDef="let element"> {{element.accessKey}} </td>
      </ng-container>
     
      <ng-container matColumnDef="timeStamp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Time Stamp </th>
        <td mat-cell *matCellDef="let element"> {{element.timeStamp}} </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete </th>
        <td mat-cell *matCellDef="let element"> <button mat-button type="button" (click)="onClickDelete(element)" matTooltip="Delete"> <mat-icon>delete</mat-icon></button></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
      </table>
      <mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]">
      </mat-paginator>

  </div>
  <div rightPane>

  </div>
  </my-slide-panel>
 