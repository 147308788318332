<my-slide-panel [activePane]="currentSubscriptionPane">
	<section centerPane>
		<h2>Available Subscriptions</h2>
		<table *ngIf="products">
			<tr>
				<th>Product</th>
				<th>Categories</th>
				<th>SKU</th>
				<th>Duration</th>
				<th>Product Status</th>
				<th>Price</th>
				<th>Purchase</th>
			</tr>
			<tr *ngFor="let item of products">
				<td>{{item.title}}</td><td>{{item.category}}</td>
				<td>{{item.sku}}</td>
				<td>{{item.moduleDetail.duration}}</td>
				<td>{{item.status}}</td>
				<td>${{item.price | number:'1.2-2'}}</td>
				<td>
					<span *ngIf="isAdmin()">
						<button mat-flat-button color="primary" name="xferbutton" (click)="onClickSubscribe(item)"> Subscribe </button> &nbsp;
					</span>
					<span *ngIf="item.status=='publish'">
						<a href="https://bodyof9.com/product/{{item.name}}" target="_blank" rel="noopener">Buy Now</a>
					</span>
				</td>
			</tr>
		</table>
		<hr>
		<h2>Subscriptions for {{ name }}</h2>
		<table *ngIf="userD">
			<tr>
			 	<th>Order ID</th>
				<th>Product</th>
				<th>Categories</th>
				<th>Duration</th>
				<th>Selected</th>
				<th>Order Status</th>
				<th>Start</th>
				<th>End</th>
				<th>Cancel</th>
			</tr>
			<tr *ngFor="let item of userD; let i = index;">
				<td>{{item.orderId}}</td>
				<td>{{item.productInfo.title}}</td>
				<td>{{item.productInfo.category}}</td>
				<td>{{item.userData.duration}}</td>
				<td>{{item.userData.selectList}}</td>
				<td>{{item.userData.status}}</td>
				<td>{{dateFormating(item.userData.date_started, "ts")}}</td>
				<td>{{dateFormating(item.userData.date_ended, "ts") }}</td>
			 	<td>
				<span *ngIf="item.userData.status!='Cancelled'">	<button mat-flat-button color="primary" name="xferbutton" (click)="onClickDelete(item,item.productIndex, item.orderIndex)"> Cancel </button></span>
			  </td>
			</tr>
		</table>
	</section>
</my-slide-panel>
