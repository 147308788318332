<header>
  <mat-toolbar>
    <!-- Logo -->
    <a class="BO9-logo"
      [class.dark]="isDarkTheme"
      [class.light]="isLightTheme"
      routerLink='/profile/Edit'>
      BO9
    </a>
    <button mat-button (click)="drawer.toggle()"><mat-icon>menu</mat-icon></button>
    Welcome {{ user.contact.names.name.first }}
    <div class="helpfooter"><button mat-stroked-button (click)="help()">Help</button></div>
    <div class="helpfooter"><button mat-stroked-button (click)="support()">Support</button></div>
    <div class="logoutfooter"><button mat-stroked-button (click)="logout()">Logout</button></div>
  </mat-toolbar>
    <app-notify></app-notify>


</header>
<div class="navigation-container" [class.navigation-is-mobile]="isMobile()">
<mat-sidenav-container autosize class="navigation-sidebar-container">
  <mat-sidenav #drawer mode="side" opened role="navigation">
  
    <mat-nav-list>
      <a class="sidebar-dashboard" mat-list-item *ngIf="isMaster()"
      routerLink='/dashboard'
      routerLinkActive="sidebar-active-link">
      <mat-icon>manage_accounts</mat-icon>
      <span *ngIf="!isMobile()">Admin</span>
    </a>

      <a class="sidebar-dashboard" mat-list-item *ngIf="isApprentice()"
      routerLink='/guild/edit'
      routerLinkActive="sidebar-active-link">
      <mat-icon>dashboard</mat-icon>
      <span *ngIf="!isMobile()">Dashboard</span>
    </a>
    
        <a class="sidebar-messages" mat-list-item 
        routerLink='/profile/Edit'
        routerLinkActive="sidebar-active-link">
        <mat-icon>edit</mat-icon>
        <span *ngIf="!isMobile()">Profile</span>
      </a>
      <a class="sidebar-messages" mat-list-item 
        routerLink='/profile/Activity'
        routerLinkActive="sidebar-active-link">
        <mat-icon>rowing</mat-icon>
        <span *ngIf="!isMobile()">Activities</span>
      </a>
      <a class="sidebar-messages" mat-list-item 
      routerLink='/profile/Family'
      routerLinkActive="sidebar-active-link">
      <mat-icon>family_restroom</mat-icon>
      <span *ngIf="!isMobile()">Relations</span>
      </a>
      <!--
      <a class="sidebar-messages" mat-list-item 
      routerLink='/profile/Orders'
      routerLinkActive="sidebar-active-link">
      <mat-icon>shopping_basket</mat-icon>
      <span class="sidebar-dashboard-text">Orders</span>
      </a>
      <a class="sidebar-messages" mat-list-item 
      routerLink='/profile/Groups'
      routerLinkActive="sidebar-active-link">
      <mat-icon>groups</mat-icon>
      <span *ngIf="!isMobile()">Groups</span>
      </a>
      <a class="sidebar-messages" mat-list-item
      routerLink='/profile/Invite'
        routerLinkActive="sidebar-active-link">
      <mat-icon>connect_without_contact</mat-icon>
      <span class="sidebar-dashboard-text">Invites</span>
      </a>-->
      <a class="sidebar-pearls" mat-list-item 
      routerLink='/profile/Pearl'
      routerLinkActive="sidebar-active-link">
      <mat-icon>subscriptions</mat-icon>
      <span *ngIf="!isMobile()">Pearls</span>
    </a>
    <a class="sidebar-pearls" mat-list-item 
    routerLink='/profile/Subscriptions'
    routerLinkActive="sidebar-active-link">
    <mat-icon>receipt_long</mat-icon>
    <span *ngIf="!isMobile()">Subscriptions</span>
  </a>
  </mat-nav-list>


    <div class="versionfooter">Version {{ version }}</div>
    <div class="versionfooter" [ngClass]="{'serverMismatch': serverVersion !== srvrVersion}">Srvr {{srvrVersion }}</div>
    <button mat-stroked-button (click)="releaseNotes()">Rel. Notes</button>
  
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
</div>
