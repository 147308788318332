import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription, timer } from 'rxjs';
import { mkEmptyPerson, Person, getGender } from '../../../../bo9-shared/models/person/person.model';
import { addNaturalNumber, NaturalNumber } from '../../../../bo9-shared/models/naturalNumber/naturalNumber.model';
import { validationMessages } from 'src/helpers/form.helper';
import { contactFullName, canContact, primaryEmail, contactFirstLastName } from '../../../../bo9-shared/models/contact/contact.model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';

import { SearchService } from 'src/services/search/search.service';
import { actCampaignDelay, contactLimit } from 'src/config/webapp.config';

import { ActiveCampaignService } from 'src/services/activecampaign/activecampaign.service';
import { EventEmitterService } from 'src/services/events/events.service';
import { dateFromYYYYMMDD, DateToYYMMDDhhmmss, dateFormating } from '../../../../bo9-shared/helpers/time.helper';
import { formPerson, makeFormPersonFromPerson } from '../../../../bo9-shared/models/person/formPerson.model';
import { Relation } from '../../../../bo9-shared/models/person/relation.model';
import { Difference } from '../../../../bo9-shared/models/person/differences.model';
import { clearRoles, removeRole, userIsAdmin, userIsSuperAdmin } from '../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { ActCamFields } from '../../../../bo9-shared/models/mailCampaign/activeCampaign.model';
import { searchParams, Visit } from '../../../../bo9-shared/models/page/page.model';
import { removeString } from '../../../../bo9-shared/helpers/text.helper';
import { MemberService } from 'src/services/memberService/member.service';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss'],
  // make fade in animation available to this component

})
export class PeopleComponent implements OnInit, OnDestroy, AfterViewInit, OnDestroy {
  messagesLoading$: Observable<boolean>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('table', {read: ElementRef}) table: ElementRef;
  people$: Observable<Person[]>;
  totalFound: number=0;
  totalFound$: Observable<number>;
  pageNum$: Observable<number>;
  totalPages$: Observable<number>;
  pageSize$: Observable<number>;
  capabilitySearch: string[] =[];
  pageNum: number=0;
  totalPages: number;
  pageSize: number=15;
  people: Person[];
  nnSearch: number[] = [];
  currentPane = 'center';
  valid: false;
  fromI$:Observable<string>;
  fromF$:Observable<Person>;
  fromA$:Observable<string>;
  fromG$:Observable<string>;
  email: string;
  fromName= 'Not Set';

  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string='';
  fieldValues: number;
  formPersons: formPerson[] =[];

  currentP$: Observable<Person>;
  currentIKey$: Observable<string>;
  currentPerson: Person;
  RelateForm: FormGroup;
  loading= false;
  invitingPersonName: string;
  familyPersonName: string;
  operation = '';
  contactLmt = contactLimit;
  makeEmail = false;
  emailer: Person;
  user: Person;

  origP: Person;
  realP: Person;
  newP: Person;
  diffs: Difference[];

  searching = false;
  length=0;
  selectedOption='Select One';

  actCamp$:Observable<any>;
  actCamp:any;
  visit: Visit;
  history: searchParams[];
  searches: searchParams[];
  identifiers: string[]=[];
  validationMessages = validationMessages;
  public displayedColumns = ['selection','mailcampaigns','_key', 'email', 'name', 'asserted', 'whenasserted','identified', 'whenidentified','capability', 'identifier', 'modified','delete'];

  public dataSource = new MatTableDataSource<formPerson>();
  nnKeys: number[]=[0,1,2,3,4,5,6,7,8,9,99];
  selections = []
  subscriptions: Subscription[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private arangoService: ArangoService,
    private searchService: SearchService,
    private activeCampaignService: ActiveCampaignService,
    private auth: AuthService,
    private memberService: MemberService,
    private router: Router,
    private emitterService: EventEmitterService
    )
  {

    this.user = this.auth.getAuthUser();

    this.visit = {
      page: this.router.url, component: 'PeopleComponent', 
      visitor: this.user._key,
      help: false
    };
    this.subscriptions.push(this.arangoService.getVisit(this.visit,0, 50).subscribe ({next: (res: any) => {
      if (res && res.content && res.content.length > 0) {
        this.searches = [];
        let found = 0;
        for (const s of res.content)
          if (s.search && found < 5 ) {
            let foundMatch = false;
            for (const search of this.searches) {
              if (search.limit === s.search.limit && search.offset === s.search.offset && search.search === s.search.search && search.otherParam === s.search.otherParam) {
                foundMatch = true;
                break;
              }
            }
            if (!foundMatch) {
              found++;
              this.searches.push(s.search);
            }

          }
      }
    }
    })); 
    this.subscriptions.push(this.arangoService.getIdentifiers()
    .subscribe(async (res) => {
      console.log('get identifers ' + JSON.stringify(res));
      for (const cat of res.content) {
        this.identifiers.push(cat.Name);
      }
    }
    ));
    this.subscriptions.push(this.arangoService.recordVisit(this.visit).subscribe ({next: () => {}})); 
    this.selections = [
      'Select One',
      'Edit',
      'Purchases',
      'Subscriptions',
      'Guild',
      'Pearls',
      'Group',
      'Merge',
      'Invite',
      'Family',
      'Visit',
      'Activity'
    ];

    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #028'); 
      this.router.navigate(['login'] );
    } else {
      if (userIsSuperAdmin(this.user)) {
        console.warn('User is super ');
        this.selections.push('SysRoles');
      }
    }
     this.subscriptions.push( this.activeCampaignService.getContactTagFieldValues(false) // people fields
     .subscribe( (res) => {
        this.makeForm();
     }))

  }


  isSuperAdmin() {
    return userIsSuperAdmin(this.user);
  }

  isAdmin() {
    return userIsAdmin(this.user);
  }

  get emailControl() { return this.RelateForm.controls.email; }
  get nameControl() { return this.RelateForm.controls.name; }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }
  makeForm() {

    this.RelateForm = this.formBuilder.group({
      name: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([ Validators.email])),
      Invite: new FormControl('', Validators.compose([])),
      pearlmodule: new FormControl('', Validators.compose([])),
    });
  }

  ngOnDestroy() {
    console.log('Destory person page');
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  submitRelateForm() {
    console.log('Submit relate form ' + JSON.stringify(this.RelateForm.value));

    if (this.RelateForm.valid) {
      console.log('Submit relate form - valid');
      this.currentPane = 'center'
    }
  }


  ngOnInit() {
    this.formPersons = [];
    this.searching = false;
  }


  onPageChange(event){
    console.log(event);
    console.log(event);
    const oldPage = this.pageNum
    this.pageNum = event.pageIndex
    if (oldPage < this.pageNum) {
      this.getPeople(this.searchString);
    }
    
  }

  onCloseEmail($event) {
    this.makeEmail = false;
  }

  sendEmail($event) {
    console.log('Send Email to '+$event);
    this.emailer = $event.realPerson;
    this.makeEmail = true;
  }


	showKey (key: String) {
		alert(key);
	}


  onClickSearch($event: any) {
    if ($event) {
      $event.preventDefault();
      this.currentPane = 'center'
    }
    this.formPersons = [];
    this.pageNum =0;

    this.searching = true;
    this.getPeople(this.searchString);

  }

  async getPeople(search: string, oldSearch?: searchParams[]) {

    const resI = await firstValueFrom(this.arangoService.getIdentifiers())
    this.identifiers = [];
      for (const cat of resI.content) {
        this.identifiers.push(cat.Name);
      }

    this.makeEmail= false;
    this.loading = true;
    let startWith =0;
    if (this.user.preferences) {
      this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }
    let limit = this.pageSize+1;
    if (oldSearch && oldSearch.length > 0) {
      this.dataSource.data=[];
      this.formPersons = [];
      this.length=0;
      limit = oldSearch[0].limit;
      search = oldSearch[0].search;
      startWith = oldSearch[0].offset;
      let nns;

      if (oldSearch[0].otherParam) {
        nns = oldSearch[0].otherParam.split(",");
      } 
      this.nnSearch = [];
      if (nns && nns.length > 0) {
        for(const n of nns) {
          this.nnSearch.push(+n);
        }
      }
    } else {

        this.selectedOption='Select One';
        // this.arangoService.search(this.searchString, (this.pageNum -1)* this.pageSize, this.pageSize)
        if (this.pageNum ===0) {
          this.dataSource.data=[];
          this.formPersons = [];
          this.length=0;
        } else {
          limit = this.pageSize;
          startWith = this.pageNum* this.pageSize + 1
        }
        console.log(`Search start ${startWith} limit ${limit}`);

    }
    if (this.nnSearch && this.nnSearch.length ==1 && this.nnSearch[0]==99) {
      this.nnSearch = [];
  }
    const resV = await firstValueFrom(this.arangoService.recordSearch(this.visit,search, this.nnSearch, this.capabilitySearch,startWith, limit));
   const res = await firstValueFrom(this.searchService.search(search, this.nnSearch, this.capabilitySearch, startWith, limit));
     // console.log('search  ' + JSON.stringify(res.content.persons));
      this.people = res.content;
      this.fieldValues = 0;
      this.searching = false;
      if (this.people) {
        for (let i=0; i< this.people.length; i++) {
          let actP:ActCamFields = null;
          if (!this.people[i].primaryEmail || this.people[i].primaryEmail=='') {
            this.people[i].primaryEmail = primaryEmail(this.people[i].contact);
          }
          if (this.people[i].primaryEmail) {
            const actContact  = await firstValueFrom(this.activeCampaignService.getContactByEmail(this.people[i].primaryEmail));// Activecampign person exists
            if (actContact) {
              actP = actContact;
            }
          }

          const roles = await firstValueFrom(this.arangoService.getRoles(this.people[i]));
          this.people[i].systemRoles = roles && roles.content[0]? roles.content[0].systemRoles: [];
          const fp = makeFormPersonFromPerson(this.people[i], actP);
        
          if (!this.searching) { // If the system is restarting, flush this entry

            this.formPersons.push(fp);
            this.length = this.formPersons.length;
            this.dataSource.data = this.formPersons as formPerson[];
          }

          this.fieldValues++;
          this.capabilitySearch = [];
      }
      }

      this.fieldValues = contactLimit;
      //this.dataSource.data = this.formPersons as formPerson[];
      this.loading = false;
      this.table.nativeElement.scrollIntoView(true);
  }


  mergeSubmittedHandler($event: any) {
    this.currentPane = 'center';
		console.log('Merged ');

  }

  onRepeatClick() {
    console.log("repeat "+JSON.stringify(this.history));
    this.getPeople(null, this.history);
  }

  onCloseClick() {
    this.selectedOption= 'Select One';
    this.currentPane = 'center';
    this.currentP$ = of(null);

    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.getPeople(this.searchString, this.history); // refresh
    }
  }

	onRefreshClick() {
		console.log('Refresh click ');
		this.formPersons = [];
		this.searching = false;
		this.loading = false;
		this.getPeople(this.searchString, this.history); // refresh
	}

  onSelected(choice:any, p:formPerson) {
    console.log('Selected ' + JSON.stringify(choice.value));
    switch (choice.value) {
      case '':
        break;
      case 'Merge':
        this.origP = p.realPerson;
        const mergeWith = prompt("Email to Merge with and make Primary? ");
        if (mergeWith && mergeWith==p.email) {
          alert(`Can't Merge with Self!`);
        } else if (mergeWith) {
          this.subscriptions.push( this.searchService.searchEmail(mergeWith, true)
          .subscribe( async (result) => {
            if (result && result.length > 0) {
              console.log('merge with ' + JSON.stringify(result));
              const res = await firstValueFrom(this.arangoService.mergeByKey(p.realPerson._key, result[0]._key));
              this.currentPane = 'center';
              console.log('Merged ');
              this.emitterService.emitter.emit('Message',mergeWith  + ' Merged and has become primary');
              if (this.user.preferences && this.user.preferences.autoRefresh) {
                this.getPeople(this.searchString, this.history); // refresh
              }
          
            } else {
              alert(`No one found for email ${mergeWith}`);
            }
            
          }))
        } else {
          alert(`No EMail given!`);
        }
        this.selectedOption='Select One';
        break;
      case 'Edit':
        console.log('Expand Person  Contact '+ JSON.stringify(p));

        if (p.naturalNumber && !p.realPerson.naturalNumbers) { // Only if this should override the current None
          p.realPerson.naturalNumbers.identified = addNaturalNumber(p.naturalNumber,'Replaced','System Override', DateToYYMMDDhhmmss(),'Person NN','','')
        }
        this.realP = p.realPerson;
        if (p.gender) this.realP.details.gender = getGender(p.gender); // Only if set
        console.log('Expand Person  Contact '+ JSON.stringify(p.realPerson.contact));
        this.actCamp = p.activeCampaignContact;

        this.currentPane = 'right';
        break;
      case 'Invite':
        this.currentPane = 'left';
        this.invitingPersonName = contactFullName(p.realPerson.contact);
        this.familyPersonName = ''
        this.operation = 'Invite';
        this.fromI$ = of(p.realPerson.primaryEmail);
        break;
      case 'Family':
        this.currentPane = 'left';
        this.invitingPersonName = ''
        this.familyPersonName = contactFullName(p.realPerson.contact);
        this.operation = 'Family';
        this.email= p.realPerson.primaryEmail;
        this.fromF$ = of(p.realPerson);
        this.fromName = p.realPerson.contact.names.name.first + ' ' +  p.realPerson.contact.names.name.last;
      break;
      case 'Group':
        console.log('Groups Person   '+ JSON.stringify(p));
        this.currentPane = 'left';
        this.invitingPersonName = ''
        this.familyPersonName = contactFullName(p.realPerson.contact);
        this.operation = 'Groups';
        this.fromG$= of(p.realPerson._key);
      break;
      case 'Guild':
        this.currentPane = 'left';
        this.operation = 'Guild';
        this.fromF$ = of(p.realPerson);
      break;      
      case 'Visit':
      this.currentPane = 'left';
      this.operation = 'Visit';
      this.fromF$ = of(p.realPerson);
    break;
      case 'Activity':
        this.currentPane = 'left';
        this.invitingPersonName = contactFullName(p.realPerson.contact);
        this.operation = 'Activity';
        this.fromA$ = of(null);
        this.fromA$ = of(p.realPerson._key);
      break;
      case 'Purchases':
        this.currentPane = 'left';
        this.operation = 'Purchases';
        this.fromF$ = of(p.realPerson);
        break;
      case 'Subscriptions':
        this.currentPane = 'left';
        this.operation = 'Subscription';
        this.fromF$ = of(p.realPerson);
        break;
      case 'Pearls':
        this.currentPane = 'left';
        this.operation = 'Pearl';
        this.email= p.realPerson.primaryEmail;
        break;
      case 'SysRoles':
        if (this.isSuperAdmin()) {
          this.currentPane = 'left';
          this.operation = 'SysRoles';

          this.origP = p.realPerson;
          break;
        }

      default: {
        console.error('Not known Selected ' + JSON.stringify(choice.value));
      }

    }
  }

  onClickSubscription(p:Person) {
    this.currentPane = 'left';
    this.operation = 'Subscription';
    this.fromF$ = of(p);
  }


  onClickPearls(p:Person) {
    this.currentPane = 'left';
    this.operation = 'Pearl';
    this.email= p.primaryEmail;
  }

  onClickDelete(p:formPerson) {
      if (confirm(`Are you sure you want to delete ${p.firstName} ${p.lastName} (${p._key}) ? !!THIS CANNOT BE UNDONE !!`)) {
        this.subscriptions.push( this.arangoService.deletePerson(p._key)
        .subscribe( (res) => {
          console.log('Save result  is: ' + JSON.stringify(res.content));
          this.emitterService.emitter.emit('Message',p.firstName+ ' ' +p.lastName + '{'+p._key + '}' + ' Deleted FOREVER ');
          if (this.user.preferences && this.user.preferences.autoRefresh) {
            this.getPeople(this.searchString, this.history); // refresh
          }
          
        }))
      }

  }
  onClickInvite(p:Person) {
    this.currentPane = 'left';
    this.invitingPersonName = contactFullName(p.contact);
    this.familyPersonName = ''
    this.operation = 'Invite';
    this.fromI$ = of(p.primaryEmail);
  }

  onClickAddToActiveCampign(p:Person) {
    if (canContact(p.contact)) {
      this.subscriptions.push( this.activeCampaignService.syncContact(p)
      .subscribe( (res:any) => {
        console.log('Save result  is: ' + JSON.stringify(res.content));
        
        this.emitterService.emitter.emit('Message','Added to ActiveCampaign ');
      }))
    } else {
      alert ("Contact is DNC, cannot add to Active Campaign");
    }


  }

  onClickActivity(p:Person) {
    this.currentPane = 'left';
    this.invitingPersonName = contactFullName(p.contact);
    this.operation = 'Activity';
    this.fromA$ = of(null);
    this.fromA$ = of(p._key);
  }

  onClickFamily(p:Person) {
    this.currentPane = 'left';
    this.invitingPersonName = ''
    this.familyPersonName = contactFullName(p.contact);
    this.operation = 'Family';
    this.email= p.primaryEmail;
    this.fromF$ = of(p);
    this.fromName = p.contact.names.name.first + ' ' +  p.contact.names.name.last;
  }

  onClickGroups(p:Person) {
    console.log('Groups Person   '+ JSON.stringify(p));
    this.currentPane = 'left';
    this.invitingPersonName = ''
    this.familyPersonName = contactFullName(p.contact);
    this.operation = 'Groups';
    this.fromG$= of(p._key);
  }
  addPersonClick() {
    console.log('Add Person  Contact ');
    this.currentP$ = of(mkEmptyPerson('Contact',''));
    this.currentPane = 'right';
    this.operation = 'AddPerson';
  }


  onClickExpand(p:formPerson) {
    this.selectedOption= 'Select One';
    console.log('Expand Person  Contact '+ JSON.stringify(p));

    if (p.naturalNumber && !p.realPerson.naturalNumbers) { // Only if this should override the current None
      p.realPerson.naturalNumbers.identified = addNaturalNumber(p.naturalNumber,'Replaced','System Override', DateToYYMMDDhhmmss(),'Person NN','','')
    }
    const realP:Person = p.realPerson;
    if (p.gender) realP.details.gender = getGender(p.gender); // Only if set
    console.log('Expand Person  Contact '+ JSON.stringify(p.realPerson.contact));
    this.currentP$ = of(realP);


    this.currentPane = 'right';
  }

  personSubmittedHandler($event: any) {
    this.selectedOption= 'Select One';
    console.log(' Person  submitted '+ JSON.stringify($event));
    this.currentPane = 'center';
    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.getPeople(this.searchString, this.history); // refresh
    }
  }

  groupHandler() {
    this.selectedOption= 'Select One';
    console.log(' Group done');
    this.currentPane = 'center';
    this.operation = '';

  }

 roleHandler() {
  this.selectedOption= 'Select One';
    console.log(' Group done');
    this.currentPane = 'center';
    this.operation = '';
  }

  relateSubmittedHandler(rel: Relation) {
    this.selectedOption= 'Select One';
    console.log(' Relate  submitted '+ JSON.stringify(rel));
    this.currentPane = 'center';
    this.fromF$ = of(null);
  }

	dateFormating(date: any, kind: string) {
		return dateFormating(date, kind);
  }
  isJourneyman(p: Person): boolean {
    if (p && p.systemRoles && (p.systemRoles.includes('Partner') || p.systemRoles.includes('Journeyman')) ) {
         return true

    } else {
      return false
    }

  }
  isSenior(p: Person): boolean {
    if (p && p.systemRoles && (p.systemRoles.includes('Practicing') || p.systemRoles.includes('SeniorJ')) ) {
         return true

    } else {
      return false
    }

  }

  isMaster(p: Person): boolean {
    if (p && p.systemRoles && p.systemRoles.includes('Master')) {
         return true

    } else {
      return false
    }

  }

  isApprentice(p: Person): boolean {
    if (p && p.systemRoles && p.systemRoles.includes('Apprentice')) {
         return true

    } else {
      return false
    }

  }

  canIdentify(p: Person): boolean {
    if (p) {
      const checkName = contactFirstLastName(p.contact);
      for (const name of this.identifiers) {
        if (name === checkName) {
          return true
        }
      }
    } 
    return false

  }

  clickIdentify(p: Person) {
    const name = contactFirstLastName(p.contact);
    this.subscriptions.push( this.arangoService.addIdentifier(name)
      .subscribe((result) => {
        this.emitterService.emitter.emit('Message','Person set as identifier ' + name);

        if (this.user.preferences && this.user.preferences.autoRefresh) {
          this.getPeople(this.searchString, this.history); // refresh
        }
      
    }))
  }

 async  clickRole($event: any,p: Person,  role: string) {

      if (role == "None") {
        p.systemRoles = clearRoles(p);
        const res = await firstValueFrom(this.memberService.dropMember(p.primaryEmail));
        const res1 = await firstValueFrom(this.arangoService.updateRoles(p, p.systemRoles));
      }
      if ($event.checked == true) {
        p.systemRoles = clearRoles(p);
        p.systemRoles.push(role);
        const res = await firstValueFrom(this.memberService.updateMember(p, role));
        const res1 = await firstValueFrom(this.arangoService.updateRoles(p, p.systemRoles));
        this.getPeople(this.searchString, this.history); // refresh
      }

      return true;

  }


  isSearch(cap: string) {
    return this.capabilitySearch.includes(cap);
  }
  
  clickSearch(cap: string) {
      this.capabilitySearch = [cap]
  }

}
