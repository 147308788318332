<div class="invite-modal">
  <h3>Notifications Received</h3>
  <ul>
    <li *ngFor="let m of data.messages"> {{ m }}</li>
  </ul>
 
    
  <footer>
    <button mat-stroked-button (click)="close()">
      Close
    </button>
  </footer>
</div>
