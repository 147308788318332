import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { networkConfig } from 'src/config/webapp.config';
import { RelationType } from '../../../../bo9-shared/models/person/relation.model';
import { BO9EVENTS } from '../../../../bo9-shared/models/BO9_base/BO9_base.model';
import { GroupType } from '../../../../bo9-shared/models/person/group.model';

export interface SelectTypes {
    pearlmoduletypes: string[];
    invitetypes: string[];
    relationbothtypes: RelationType[];
    relationtypes: string[];
    groupTypes: GroupType[]
}
@Injectable()
export class SelectsService {


    public selects: SelectTypes;

    constructor (
        private http: HttpClient,
    ) {
        this.selects = {
            pearlmoduletypes:[],
            invitetypes: [],
            relationtypes:[],
            relationbothtypes: [],
            groupTypes: []
        }
    }

    // TODO implement by distance, order by distance...

    private loadSelects(): Observable<any> {
        return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.SELECTS);
    }

    public setSelects(p: SelectTypes) {
        this.selects = p;
    }

    public getSelects(): Observable<SelectTypes> {
        return new Observable( observer =>{
            if (this.selects.pearlmoduletypes.length > 0) {
                observer.next(this.selects);
                observer.complete();
            }  else {
                const sub =  this.loadSelects()
                .subscribe({ 
                    next: (msg: any) => {
                    console.log('select from db');
                    const sel: SelectTypes = {
                        pearlmoduletypes: [],
                        relationtypes: [],
                        relationbothtypes: [],
                        invitetypes: [],
                        groupTypes: []
                    }
                    for (const pm of msg.content.pearlmoduletypes) {
                        sel.pearlmoduletypes.push(pm.modulename)
                    }
                    for (const pm of msg.content.relationtypes) {
                        sel.relationtypes.push(pm.relationtype)
                    }
                    sel.relationbothtypes = msg.content.relationtypes;
                    
                    for (const pm of msg.content.invitetypes) {
                        sel.invitetypes.push(pm.invitetype)
                    }
                    for (const pm of msg.content.grouptypes) {
                        sel.groupTypes.push(pm.grouptype)
                    }
                    this.setSelects(sel);
                    
                    observer.next(msg.content);
                    observer.complete();
                }, 
                error:( e) => {console.log('error from db'); observer.error(e)}
            } )
            }
        } )
    }
}