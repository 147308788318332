import { Component, forwardRef, ChangeDetectionStrategy, OnDestroy, OnInit, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { validationMessages } from 'src/helpers/form.helper';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { AuthService } from 'src/services/auth/auth.service';

import { BO9Address, cleanISO } from '../../../../../bo9-shared/models/contact/address.model';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
export interface AddrFormValues {
  items: BO9Address[]
}

@Component({
    selector: 'address-form',
    templateUrl: './addressForm.component.html',
    styleUrls: ['./addressForm.component.scss'],
    providers: [
        {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => AddressFormComponent),
          multi: true
        },
        {
          provide: NG_VALIDATORS,
          useExisting: forwardRef(() => AddressFormComponent),
          multi: true,
        }
      ],
      changeDetection: ChangeDetectionStrategy.OnPush
  })

  export class AddressFormComponent implements OnInit, OnDestroy, ControlValueAccessor, OnDestroy {
    form: FormGroup = this.formBuilder.group({
      items: this.formBuilder.array([])
    });
    subscriptions: Subscription[] = [];
    validationMessages = validationMessages;
    user: Person;
    constructor(private formBuilder: FormBuilder,     private auth: AuthService, 
      private router: Router,  
      private arangoService: ArangoService,
      private cdr: ChangeDetectorRef) {
      console.log('Addr Init '+ JSON.stringify(this.value));
      this.user = this.auth.getAuthUser();
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'AddressFormComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
      this.subscriptions.push(
        this.form.valueChanges.subscribe(value => {
         // console.log('Addr valuechange '+ JSON.stringify(value));
          this.onChange(value);
          this.onTouched();
        })
      );
    }

    ngOnInit() {
    }
  
    get value(): AddrFormValues {
      console.log('Addr get '+ JSON.stringify(this.form.value));
      return this.form.value;
    }
  
    set value(value: AddrFormValues) {
      console.log('Addr set '+ JSON.stringify(value));
      console.log('addr length '+ JSON.stringify(this.items.length));
      this.items.clear();

      if (value.items && value.items[0] === null && value.items[1]) {
        const res = value.items.splice(0,1); // remove null first address
      }
      for(const i in value.items) {
          console.log('Addr push '+ JSON.stringify(value.items[i]));
       
          value.items[i].country = cleanISO(value.items[i].country);
          if (value.items[i].country == '') {
            value.items[i].country = 'USA';
          }
         
          value.items[i].state = cleanISO(value.items[i].state);
          
          this.items.push( this.newAddrItem());

      }
      console.log('address length after '+ JSON.stringify(this.items.length));
      this.form.patchValue(value);
      this.cdr.detectChanges();
    }

    items: FormArray = this.form.get('items') as FormArray;
  
  
    newAddrItem() : FormGroup {
      return this.formBuilder.group( {
        name: ['', Validators.compose([])],
        street1: ['', Validators.compose([])],
        street2: ['', Validators.compose([])],
        city: ['', Validators.compose([Validators.required])],
        state: ['', Validators.compose([Validators.required])],
        zip: ['', Validators.compose([Validators.required])],
        country: ['USA', Validators.compose([])],
      });
    }
    addAddrItem() {
      this.items.push( this.newAddrItem());
    }

    removeAddrItem(i: number) {
      if (confirm('Please confirm delete')) {
        if (this.items.length !== 1) {
          this.items.removeAt(i);
        }
      }
    }

    ngOnDestroy() {
      console.log('Addr destroy ');
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  
    onChange: any = () => {

    };
    onTouched: any = () => {};
  
    registerOnChange(fn) {
      this.onChange = fn;
    }
  
    writeValue(value) {
      console.log('Addr write value ' + JSON.stringify(value));
      if (value) {
        this.value = value;
      }
  
      if (value === null) {
        this.form.reset();
      }
    }
  
    registerOnTouched(fn) {
      this.onTouched = fn;
    }
  
    validate(_: FormControl) {
      console.log('Addr valid get '+ JSON.stringify(this.form.valid));
      return this.form.valid ? true : false;
    }
  
    reset() {
      this.form.reset();
    }
  }