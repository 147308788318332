<header>
  <mat-toolbar>
    <!-- Logo -->
    <a class="BO9-logo"
      [class.dark]="isDarkTheme"
      [class.light]="isLightTheme"
      routerLink='/profile/Edit'>
      BO9
    </a>
    <button mat-button (click)="drawer.toggle()"><mat-icon>menu</mat-icon></button>
    Welcome {{ user.contact.names.name.first }}
    <div class="helpfooter"><button mat-stroked-button (click)="help()">Help</button></div><br>
    <div class="helpfooter"><button mat-stroked-button (click)="support()">Support</button></div>
    <div class="logoutfooter"><button mat-stroked-button (click)="logout()">Logout</button></div>
  </mat-toolbar>
    <app-notify></app-notify>


</header>

<mat-sidenav-container autosize class="navigation-container">
  <mat-sidenav #drawer mode="side" opened role="navigation">

    <mat-nav-list>
      <a class="sidebar-dashboard" mat-list-item *ngIf="isAdmin()"
      routerLink='/dashboard'
      routerLinkActive="sidebar-active-link">
      <mat-icon>manage_accounts</mat-icon>
      <span *ngIf="!isMobile()">Admin</span>
    </a>
    <a class="sidebar-messages" mat-list-item
    routerLink="/guild/edit"
    routerLinkActive="sidebar-active-link">
    <mat-icon>groups</mat-icon>
    <span *ngIf="!isMobile()">Dashboard</span>
    </a>

    <a class="sidebar-home" mat-list-item 
    routerLink='/events'
    routerLinkActive="sidebar-active-link">
    <mat-icon>event</mat-icon>
    <span *ngIf="!isMobile()">Event Mgr</span>
  </a>
    <a class="sidebar-home" mat-list-item
    routerLink='/profile/Edit'
    routerLinkActive="sidebar-active-link">
    <mat-icon>settings</mat-icon>
    <span *ngIf="!isMobile()">Other Features</span>
  </a>

  </mat-nav-list>


    <div class="versionfooter"> <span *ngIf="!isMobile()">Version {{ version }}</span></div>
    <div *ngIf="!isMobile()" class="versionfooter" [ngClass]="{'serverMismatch': serverVersion !== srvrVersion}">Srvr {{srvrVersion }}</div>
    <span *ngIf="!isMobile()"><button mat-stroked-button (click)="releaseNotes()">Rel. Notes</button></span>

  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
