import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { networkConfig } from 'src/config/webapp.config';
import { RelationType } from '../../../../bo9-shared/models/person/relation.model';
import { BO9EVENTS } from '../../../../bo9-shared/models/BO9_base/BO9_base.model';
import { mkEmptyPerson, Person, PersonsRetrieved, cleanGender } from '../../../../bo9-shared/models/person/person.model';
import { Activity } from '../../../../bo9-shared/models/activity/activity.model';
import { HttpCancelService } from '../httpServices/httpcancel.service';

export interface SelectTypes {
    pearlmoduletypes: string[];
    invitetypes: string[];
    relationtypes: RelationType[];
}
@Injectable()
export class SearchService {


    keysActivity: Map<string,Activity>;
    keysPerson: Map<string,Person>;
    keysEvent: Map<string,Person>;

    emails: Map<string, Person[]>;

    constructor (
        private http: HttpClient,
        private cancelService: HttpCancelService
    ) {
        this.flush();
    }

    flush() {
        this.keysActivity = new Map();
        this.keysPerson = new Map();
        this.keysEvent = new Map();
        this.emails = new Map();
    }

    emailSearch(search:string):Observable<any> {
        return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.SEARCHEMAIL + '/?search='+search+'&offset='+0+'&limit='+10+'&unique=true')
    }

    public searchEmail(search: string, force:boolean): Observable<Person[]> {
        return new Observable( observer =>{
            if (this.emails.size > 0 && this.emails.get(search) && !force) {
                observer.next(this.emails.get(search));
                observer.complete();
            }  else {
                const sub =  this.emailSearch(search)
                .subscribe({
                    next: (msg:any) => {
                        
                        const retrieved: PersonsRetrieved = msg.content;
                        if (retrieved.persons && retrieved.persons.length > 0) {
                            for (const i in retrieved.persons) {
                                retrieved.persons[i] = cleanGender(retrieved.persons[i]);
                            }
                            this.emails.set(search,retrieved.persons);
                            observer.next(retrieved.persons);
                            observer.complete();
                        } else {
                            observer.next(null);
                            observer.complete();
                        }
                    },
                    error: (e) => {
                        console.log('error from db'); 
                        observer.error(e)
                    }
                })
            }
        })
        
    }
      public searchEvent(search: string): Observable<any> {
        return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.SEARCHEVENT + '/?search='+search);
      }

    public search(search: string, nn?: number[], cap?: string[], skip?: number, limit?: number): Observable<any> {
        if (!skip) {
          skip = 0;
          if (!limit) {
            limit =20;
          }
        } else if (!limit) {
          limit = 20;
        }
       console.log(`search ${search} skip ${skip} limit ${limit}`);
        this.cancelService.cancelPendingRequests();
        

           if (search !== '') {
               let urlString = '/?search=' + search + '&offset=' + skip + '&limit=' + limit;
               if (nn && nn.length > 0) {
                   urlString += '&naturalnumber=' + JSON.stringify(nn);
               }
               if (cap) {
                   urlString += '&capabilities=' + cap.join(',');
               }

                return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.SEARCH + urlString);
            } else {
                return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.SEARCHSORT + '/?offset='+skip+'&limit='+limit);
            }
        
      }

      public searchActivity(search: string, from: string, to: string,kind:string): Observable<any> {
        return  this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.SEARCHACTIVITY + '/?search='+search+'&from='+from+'&to='+to+'&kind='+kind)
     }

      public searchActivityKey(search: string): Observable<any> {
        return  this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.SEARCHACTIVITY + '/?search='+search+'&offset='+0+'&limit='+1)
      }
    
      public searchAsserted(): Observable<any> {
        return  this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.SEARCH+ '/?search=0&asserted=0'+'&offset='+0+'&limit='+1)
     }
     
     public searchPersonKey(search: string, force:boolean): Promise<Person> {
        return new Promise( resolve =>{
            if (force) this.flush();
            
            if (this.keysPerson.size > 0 && this.keysPerson.get(search) && !force) {
                //console.log("Key cached is " + JSON.stringify(this.keysPerson.get(search)));
                resolve(this.keysPerson.get(search));
            }  else {
                const sub =  this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.SEARCHKEY + '/?search='+search)
                .subscribe( {next:(msg: any) => {
                    
                    const retrieved: PersonsRetrieved = msg.content;
                    //console.log("Key got is " + JSON.stringify(msg.content));
                    if (retrieved.persons.length > 0) {
                        this.keysPerson.set(search,retrieved.persons[0]);
                        resolve(retrieved.persons[0]);
                    } else {
                        resolve(null);
                    }
            }, error: (e) => {console.log('error from db ' +e)}})
            }
        } )
    }

    public searchEventKey(search: string, force:boolean): Promise<any> {
        return new Promise( resolve =>{
            if (force) this.flush();
            if (this.keysEvent.size > 0 && this.keysEvent.get(search) && !force) {
                resolve(this.keysEvent.get(search));
            }  else {
                const sub =  this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.SEARCHEVENT + '/?search='+search+'&offset='+0+'&limit='+1)
                .subscribe( {next:(msg: any) => {
                    
                    if (msg.content.event.length > 0 && search !== '') {
                        this.keysEvent.set(search,msg.content);
                        resolve(msg.content);

                    } else {
                        resolve(msg.content);
                    }
            },error: (e) => {console.log('error from db' + e)}}
                )
        }
        } )
    }

    
}