import webappConfig from '../../../../bo9-shared/config/webappconfig.json';
import { BO9EVENTS } from '../../../../bo9-shared/models/BO9_base/BO9_base.model';
import { forkJoin, Observable, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SystemVariable } from '../../../../bo9-shared/models/BO9_base/systemvariables.model';

export interface SysTuple {
    name:string;
    value: any;
}

export function getTupleFromArray( name: string, tuples: SysTuple[]): SysTuple {
    if (tuples && tuples.length > 0) {
        for (const t of tuples) {
            if (name === t.name) {
                return t;
            }
        }
    }
    return null;
}
@Injectable()
export class SystemVariablesService {

    systemvariables: SystemVariable[];
    log: any;
  
    constructor( private http: HttpClient) {

    }


    getSystemVariable(name: string) : Observable<string> {
        return new Observable( observer =>{

            if (this.systemvariables && this.systemvariables.length > 0) {
                observer.next(this.findVble(name));
                observer.complete();
            } else {
                const sub =  this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.SYSTEMVARIABLES)
                .subscribe( (msg: any) => {
                    
                    this.systemvariables = msg.content;

                    observer.next(this.findVble(name));
                    observer.complete();
                })
                }
            
     })
    }

    getSystemVariableArray(name: string) : Observable<string[]> {
        return new Observable( observer =>{

            if (this.systemvariables && this.systemvariables.length > 0) {
                observer.next(this.findArray(name));
                observer.complete();
            } else {
                const sub =  this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.SYSTEMVARIABLES)
                .subscribe( (msg: any) => {
                    
                    this.systemvariables = msg.content;

                    observer.next(this.findArray(name));
                    observer.complete();
                })
                }
            
     })
    }

  

    getSystemVariableTuples(name: string) : Observable<SysTuple[]> {
        return new Observable( observer =>{

            if (this.systemvariables && this.systemvariables.length > 0) {
                observer.next(this.findTuples(name));
                observer.complete();
            } else {
                const sub =  this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.SYSTEMVARIABLES)
                .subscribe( (msg: any) => {
                    
                    this.systemvariables = msg.content;

                    observer.next(this.findTuples(name));
                    observer.complete();
                })
                }
            
     })
    }

    findVble(name: string) : string {
        if (name && name != '') {
            for (const vble of this.systemvariables) {
                if (vble.name === name) {
                    return vble.value as string
                }
            }
        }
        return '';
    }

    findArray( name: string) : string[] {
        if (name && name != '') {
            for (const vble of this.systemvariables) {
                if (vble.name === name) {
                    return vble.value as string[];
                }
            }
        }
        return [];
    }    
    
    public findTuples( name: string) : SysTuple[] {
        let result: SysTuple[] = [];
        if (name && name != '') {
            for (const vble of this.systemvariables) {
                if (vble.name === name) {
                    const res: SysTuple[] = (vble.value as any);
                    return res
                }
            }
        }
        return result;
    }

    getSystemVariables(force: boolean) : Observable<SystemVariable[]> {
        return new Observable( observer =>{
            if (!force && this.systemvariables && this.systemvariables.length > 0) {
                observer.next(this.systemvariables);
                observer.complete();
            } else {
                const sub =  this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.SYSTEMVARIABLES)
                .subscribe( (msg: any) => {
                    
                    this.systemvariables = msg.content;
    
                    if ( this.systemvariables.length > 0) {
    
                        observer.next(this.systemvariables);
                        observer.complete();
                    } else {
                        observer.next(null);
                        observer.complete();
                    }
                })
            }


         })
    }

  }