<div>
      <button mat-stroked-button (click)="onRefreshClick()">Refresh<mat-icon>refresh</mat-icon></button>
      <mat-progress-spinner *ngIf="loading" mode="indeterminate">  </mat-progress-spinner>
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="view">
            <th mat-header-cell *matHeaderCellDef> View Order</th>
            <td mat-cell *matCellDef="let element">
              <button type="button" (click)="onClickView(element)" matTooltip="View"> <mat-icon>preview</mat-icon></button>
            </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> Order Id </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>

          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
            <td mat-cell *matCellDef="let element"> {{element.date_created}} </td>
          </ng-container>
          <ng-container matColumnDef="completed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Completed </th>
            <td mat-cell *matCellDef="let element"> {{element.date_completed}} </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.orderer}} </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Billing EMail </th>
            <td mat-cell *matCellDef="let element"> {{element.billing.email}} </td>
          </ng-container>
          <ng-container matColumnDef="items">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> # Items </th>
            <td mat-cell *matCellDef="let element" matTooltip={{element.tooltips}}> {{element.line_items.length}} </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let element"> {{element.status}}</td>
          </ng-container>
          <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Total </th>
            <td mat-cell *matCellDef="let element"> ${{element.total}}</td>
          </ng-container>
          <ng-container matColumnDef="links">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Downloads </th>
            <td mat-cell *matCellDef="let element"> 
              <span *ngFor="let link of element.calendly"> <a href={{link.download_url}}  target="_blank" rel="noopener">Link</a><br/></span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
          </table>
          <mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]">
          </mat-paginator>
    </div>
