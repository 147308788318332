import { Component } from '@angular/core';

@Component({
  selector: 'app-partner-layout',
  template: `
    <app-partnernav class="app-wrapper"></app-partnernav>
  `,
  styles: []
})
export class PartnerLayoutComponent {}
