import { BO9ServerConfigType } from '../../../bo9-shared/models/networkconfig/BO9networkconfigtypes.model';
import config from '../../../bo9-shared/config/webappconfig.json';


export const networkConfig: BO9ServerConfigType = config.networkConfig;
export const externalConfig: BO9ServerConfigType = config.externalConfig;

export const websitePages = config.websitePages;

export const websiteConfig = config.websiteConfig;

export const webAppVersion = config.admversion;
export const srvrVersion = config.version;

export const sockethostName = config.sockethostName;

export const BO9AppToken = config.BO9AppToken;

export const contactLimit = config.contactLimit;

export const ThisYear = new Date().getFullYear().toString();

export const actCampaignDelay = config.ActCampaignDelay;// msecs

export const firebaseConfig = config.firebaseConfig;

export const meetingUrl = config.meeting_url;

export const releaseLevel = config.releaseLevel;



