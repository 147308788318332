import { ChatRoom } from '../chat/chatroom.model';
import { createUUID } from '../../uuid.service';
import { BO9UUID, NamedModel } from '../UUID.model';
import { Meta, updateMeta } from '../BO9_base/meta.model';

import { NaturalNumberType, NaturalNumbersType, NaturalNumber, makeMTNaturalNumbers, mostAccurateNaturalNumber, stringToNaturalNumber, } from '../naturalNumber/naturalNumber.model';
import { getGender, Person } from './person.model';
import { canContact, contactFullName, primaryEmail } from '../contact/contact.model';
import { addEmail } from '../contact/email.model';
import { addName } from '../contact/name.model';
import { addPhone } from '../contact/phone.model';
import { addShortAddress, mkMTAddress } from '../contact/address.model';
import { DateToYYMMDDhhmmss } from '../../helpers/time.helper';
import { setPreferences } from './preferences.model';
import { ActCamFields } from '../mailCampaign/activeCampaign.model';



export type formPerson = {
    _key: string;
    email: string;
    naturalNumber: NaturalNumber;
    firstName: string;
    lastName: string;
    source: string;
    identified: string;
    modified: string;
    actCampNN?: string;
    realnn?: NaturalNumberType;
    realPerson?: Person;
    gender?: string;
    DNC?: boolean;
    selected?: boolean;
    admin?: boolean;
    primaryContact?: boolean;
    city? : string;
    state?: string;
    zip?: string;
    country?: string;

    phone?: string;
    activeCampaignContact?: any,
    idLink?: string,
    sysRoles?: string[];
    naturalNumbers?:NaturalNumbersType
  }

  function newMTFormPerson(): formPerson  {
    return {
      _key: '',
      email:  '',
      naturalNumber: NaturalNumber.None,
      firstName: '',
      lastName: '',
      source:  '',
      identified:  '',
      modified:  ''
    }
  }

  export function makePersonfromFormPerson(p: formPerson) : Person {
      const id = createUUID()
  
      const newPerson: Person = {
        _key: p._key? p._key: id,
        userUID: '',
        contact: {
          _key: createUUID(),
          usage: 'person',
          email: addEmail(null, false, p.email),
          names : addName(null, p.firstName, p.lastName),
          phone: p.phone?addPhone(null,false,p.phone): {DNC: false, items:[], updatedAt: DateToYYMMDDhhmmss()},
          URL: '',
          notes: '',
          address: { updatedAt:DateToYYMMDDhhmmss(), items: []}
        },
        appEntered: 'Event',
        externalIds: {
          activeCampaign:'',
      },
        primaryEmail: p.email,
        details: {
          birthday: '',
          gender: p.gender? p.gender: '',
        },
        preferences: setPreferences(),
        naturalNumbers: makeMTNaturalNumbers(),
        source: '',
        personOrOtherReferredBy: '',
        referralSource: '',
        agreements: [],
        meta: updateMeta()
      }
      return newPerson;
  }

  

  export function makeFormPersonFromPerson( p: Person, actP?: ActCamFields): formPerson {
    let fp: formPerson =  newMTFormPerson();
    fp.DNC = !(canContact(p.contact));


    fp.activeCampaignContact = actP;

    fp._key = p._key;
    fp.firstName = p.contact.names.name.first;
    fp.lastName = p.contact.names.name.last;
    if (p.contact.email.items && p.contact.email.items.length>0) {
      fp.email = primaryEmail(p.contact);
    } else if (p.primaryEmail) {
      fp.email = p.primaryEmail;
      p.contact.email = addEmail(p.contact.email,false, p.primaryEmail); // add it
    } else {
      fp.email = '';
    }
    // Ranked result

    if ( p.contact.address && p.contact.address.items && p.contact.address.items.length > 0) {
      if (p.contact.address.items && p.contact.address.items[0] === null && p.contact.address.items[1] !== null) {
        p.contact.address.items.splice(0,1); // remove null first item
      }
      fp.city = p.contact.address.items[0]? p.contact.address.items[0].city: '';
      if (p.contact.address && (p.contact.address.items[0].state !== null)) {
        if (typeof p.contact.address.items[0].state === 'string') {
          fp.state = p.contact.address.items[0].state;
        } else if ((p.contact.address.items[0].state as any).name) {
          fp.state = (p.contact.address.items[0].state as any).name;
        } else {
          fp.state = '';
        }
      }
      fp.zip = p.contact.address? p.contact.address.items[0].zip: '';
      if (p.contact.address && (p.contact.address.items[0].country !== null)) {
        if (typeof p.contact.address.items[0].country === 'string') {
          fp.country = p.contact.address.items[0].country;
        } else if (p.contact.address.items[0].country && (p.contact.address.items[0].country as any).name) {
          fp.country = (p.contact.address.items[0].country as any).name;
        } else {
          fp.country = 'USA';
        }
      }
    } else {
      fp.city =  '';
      fp.state =  '';
      fp.zip =  '';
      fp.country = 'USA';
    }

    fp.naturalNumbers = p.naturalNumbers;

    const accurateNN = mostAccurateNaturalNumber(p.naturalNumbers);
    if (accurateNN.naturalNumber === NaturalNumber.None|| accurateNN.naturalNumber === NaturalNumber.Any || accurateNN.naturalNumber === NaturalNumber.OldNone) {

      if (p.naturalNumbers.invited.idLink) {
        fp.idLink = p.naturalNumbers.invited.idLink;
      }

    } else {
      fp.naturalNumber = accurateNN.naturalNumber;
      if (actP && fp.naturalNumber != actP.naturalNumber) {
        fp.actCampNN =  actP.naturalNumber;
      }
      if (p.naturalNumbers.identified.dateSet) {
        fp.identified = p.naturalNumbers.identified.dateSet
      } else {
        if (accurateNN.meta) {
          fp.identified = accurateNN.meta.dateCreated
        } else {
          fp.identified = ''
        }
      }
      // console.log(`p m ${i} `+ JSON.stringify(p));
       

    }

    if (p.meta) {
      fp.modified = p.meta.lastModified ;
    } else if (p.contact && p.contact.meta) {
      fp.modified = p.contact.meta.lastModified;
    } else {
      fp.modified ='';
    }
    fp.realPerson = p



    return fp;


  }