import { createUUID } from '../../uuid.service';
import { ArangoNodeType,  BO9UUID } from "../arango/arango.model";
import { Meta, updateMeta } from "../BO9_base/meta.model";
import { EventParams } from '../event/event.model';
import { NaturalNumber, NaturalNumberType } from '../naturalNumber/naturalNumber.model';
import { formPerson } from './formPerson.model';
import { InvitedPerson, InvitedStatus } from './invite.model';
import { Person } from './person.model';


export interface Group {
    _key: BO9UUID;
    groupName: string;
    groupType: string;
    createdBy: BO9UUID;
    meta: Meta;
}

export interface GroupType {
    groupType: string;
}

export interface GroupMembership {
    _key: BO9UUID;
    from: BO9UUID; // Group key
    to: BO9UUID; // Person key
    groupOwner: BO9UUID;
    primaryContact: boolean;
    admin: boolean;
    invitedStatus: InvitedStatus,
    person?: Person;
    formPerson?: formPerson;
    invitedPerson?: InvitedPerson;
    eventParams?: EventParams;
    meta: Meta;
}

export interface formGroup {
    _key: string;
    createdBy: string;
    creatorName?: string;
    group: Group;
    isAdmin?: boolean;
    members: GroupMembership[];
    meta: Meta
  }

export function mkMTGroup() : Group {
    return ({
        _key: createUUID(),
        groupName: '',
        groupType: '',
        createdBy: '',
        meta: updateMeta()
    })
}

export function MemberIsAdmin(g: formGroup, userKey: string): boolean {

    if (g && g.members && g.members.length > 0) {
        for (const m of g.members) {
            if (m.to === userKey) {
                return m.admin;
            }
        }
    }

    return false;
}
