<my-slide-panel [activePane]="currentRelationshipPane">
	<section centerPane>
	 	<h2>Relations for: {{name}}</h2>

		<div class="action">
			<span *ngIf="from">
		 		<button type="button" (click)="onClickAdd()">Add a Relation <mat-icon>playlist_add</mat-icon></button>
			</span>
			<span>
				<mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="30" class='spin'> </mat-progress-spinner>
			</span>
			<span>
				<button mat-stroked-button (click)="onRefreshClick()">Refresh<mat-icon>refresh</mat-icon></button>
			</span>
		</div>

		<table mat-table [dataSource]="dataSource" matSort>
		 <ng-container matColumnDef="edit">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Edit </th>
			<td mat-cell *matCellDef="let element">
			<button type="button" (click)="onClickEdit(element)"> <mat-icon>edit</mat-icon></button>
			</td>
		 </ng-container>
     <ng-container matColumnDef="from">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> From </th>
      <td mat-cell *matCellDef="let element"> {{element.fromFirstName}}  {{element.fromLastName}}</td>
    </ng-container>
    <ng-container matColumnDef="person">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Person </th>
      <td mat-cell *matCellDef="let element"> {{element.toFirstName}}  {{element.toLastName}}</td>
    </ng-container>
		 <ng-container matColumnDef="email">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
			<td mat-cell *matCellDef="let element"> {{element.toEmail}} </td>
		 </ng-container>
		 <ng-container matColumnDef="relationType">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Is My </th>
			<td mat-cell *matCellDef="let element"> {{element.relationType}} </td>
		 </ng-container>

		 <ng-container matColumnDef="naturalNumber">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Nat. Num </th>
			<td mat-cell *matCellDef="let element"> {{element.naturalNumber}} </td>
		 </ng-container>
		 <ng-container matColumnDef="source">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Source </th>
			<td mat-cell *matCellDef="let element"> {{element.sourceType}} </td>
		 </ng-container>
		 <ng-container matColumnDef="birthOrder">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Birth Order (Birth Mother) </th>
			<td mat-cell *matCellDef="let element"> {{element.momBirthOrder}} </td>
		 </ng-container>
		 <ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Invite Status</th>
			<td mat-cell *matCellDef="let element"> {{element.invitedStatus}} </td>
		 </ng-container>
		 <ng-container matColumnDef="delete">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Delete </th>
			<td mat-cell *matCellDef="let element">
			  <button type="button" (click)="onClickDelete(element)"> <mat-icon>delete</mat-icon></button>
			</td>
		 </ng-container>
		 <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		 <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

		</table>

		<mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]">
		</mat-paginator>

 	</section>

	<section rightPane>
		<span *ngIf="operation=='Edit'">
			<relation-form
				[from]="from"
				[attendees]="attendees"
				[inputRelation]="currentI$ | async"
				(notifyParent)="relateSubmittedHandler($event)" >
			</relation-form>
		</span>
	</section>
</my-slide-panel>
