import { Component, OnDestroy, Input, OnChanges, Output, EventEmitter, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { firstValueFrom, Subscription } from 'rxjs';
import { canContact, mkMTContact } from '../../../../../bo9-shared/models/contact/contact.model';
import { validationMessages } from 'src/helpers/form.helper';
import { Event, EventAttendee, MTTransaction_Detail, Set_Transaction, attendeeUnique } from '../../../../../bo9-shared/models/event/event.model';

import { DateToYYMMDDhhmmss, OnlyTimePart } from '../../../../../bo9-shared/helpers/time.helper';
import { EventDBService } from 'src/services/events/eventdb.service';

import { ArangoService } from 'src/services/arangoservice/arango.service';

import { MatDialog } from '@angular/material/dialog';

import { findInvalidControlsRecursive } from '../../../helpers/form.helper';

import { GenderTypes, Person, personFromAttendee } from '../../../../../bo9-shared/models/person/person.model';



export interface AttendeeFormValues {
  email: string;
  first: string;
  last: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  gender: string;
  }

@Component({
    selector: 'simple-attendee-form',
    templateUrl: './simpleattendeeForm.component.html',
    styleUrls: ['./attendeeForm.component.scss'],
 
  })
  export class SimpleAttendeeFormComponent implements OnDestroy, OnChanges {

    name: string;
    form: FormGroup;
    user: Person;
    subscriptions: Subscription[] = [];
    validationMessages = validationMessages;
    event: Event
    selectedPerson: boolean;

  disableBtn = false;
  genderTypes = GenderTypes;

    get value(): AttendeeFormValues {
      console.log('name get '+ JSON.stringify(this.form.value));
      return this.form.value;
    }
  
    set value(value: AttendeeFormValues) {
      console.log('name get '+ JSON.stringify(value));
      this.form.setValue(value);
    }

    get firstControl() {
        return this.form.controls.first;
    }

    get lastControl() {
        return this.form.controls.last;
    }
    get phoneControl() {
      return this.form.controls.phone;
    }
    get genderControl() {
      return this.form.controls.gender;
    }

    isAdmin() {
      return false

    }
    get emailControl() {
      return this.form.controls.email;
    }
    constructor(private formBuilder: FormBuilder, 

      private arangoService: ArangoService,

      private dialog: MatDialog,

      private eventDBService: EventDBService) {

    this.makeForm();
    this.selectedPerson = false;

       this.eventDBService.getCurrentEvents().subscribe( (result:any) => {
        if (result && result.content) {
          this.event = result.content[0];
        }

      });
    }

    makeForm() {
      this.form = this.formBuilder.group({
        first: [' ', Validators.compose([Validators.required])],
        last: [' ', Validators.compose([Validators.required])],
        email: ['', Validators.compose([])],
        city: ['', Validators.compose([])],
        state: ['', Validators.compose([])],
        phone: ['', Validators.compose([])],
        gender: ['', Validators.compose([])],
        zip: [' ', Validators.compose([])],
        country: [' ', Validators.compose([])],
        _key: ['', Validators.compose([])],
        DNC: [false, Validators.compose([])],
        paid: ["0.00", Validators.compose([])],
      });
      
    }

    ngOnChanges() {
      console.log('OnChange ');
      this.form.reset();
    }

    selectDNC() {
      this.form.controls.DNC.setValue(!this.form.value.DNC);
      console.log('dnc'+ JSON.stringify(this.form.value.DNC));
    }
  

   async  submitForm() {
      console.log('Submit attendee form ' + JSON.stringify(this.form.value));

      if (this.form.valid) {
        this.disableBtn = true;
        const newAttendee: EventAttendee = {
          _key: this.form.value._key? this.form.value._key : '', // filled in on insert
          person_id: this.form.value._key? this.form.value._key : '', 
          contact: mkMTContact(),
          mailCampaignID: 0,
          event_id: this.event?._key,
          paid: this.form.value.amountPaid? this.form.value.amountPaid.replace('$',''): "0.00",
          transaction_details: Set_Transaction("Self Registered",this.form.value.amountPaid, MTTransaction_Detail()),
          attended: 0,
          email: this.form.value.email,
          first: this.form.value.first,
          last: this.form.value.last,
          street1: '',
          street2: '',
          city: this.form.value.city? this.form.value.city: '',
          state: this.form.value.state? this.form.value.state: '',
          phone: this.form.value.phone? this.form.value.phone:'',
          gender: this.form.value.gender,
          zip: this.form.value.zip? this.form.value.zip: '',
          country: this.form.value.country? this.form.value.country: 'USA',
          start_date: this.event.startDate,
          end_date: this.event.endDate,
          event_time: OnlyTimePart(this.event.startTimeUTC),
          DNC: this.form.value.DNC? this.form.value.DNC: false,
          end_time: "00:00",
          date_created: DateToYYMMDDhhmmss()
        }

          let res: any;
          
          res = await firstValueFrom(this.eventDBService.setSimpleEventAttendee(newAttendee));
          alert("Submitted");

          this.form.reset();
        

      this.disableBtn = false;
 
      } else {
        alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.form)));
      }
    }

  
    ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
    }


    resetForm() {
      this.form.reset();
      this.disableBtn = false;
    }

    

  }