import { FormActivity } from "../activity/activity.model";
import { BO9Contact } from "../contact/contact.model";
import { Person } from "../person/person.model";
import { WPUser } from "../person/wpuser.model";

export interface Member {
    affiliate_id: number,
    date_registered: string,
    earnings: number,
    flat_rate_basis: string,
    id: number,
    payment_email: string,
    rate: string,
    rate_type: string,
    referrals: number,
    rest_id: string,
    status: string,
    unpaid_earnings: number,
    user_id: number,
    visits: 0,
    temp_code?: string,
    wpUser?: WPUser,
    person?: Person,
    name?: string,
    expanded?: boolean,
    activities?: FormActivity[]
    }

    export interface AffiliateReferral {
        affiliate_id: number;
        amount: number;
        description: string;
        reference: string;
    }


    export function AffiliateByID( wpid: number, affs: Member[]): Member | null{
        if (affs && affs.length > 0){
            for (const aff of affs) {
                if (aff.user_id === wpid) {
                    return aff;
                }
            }
        }
        
        return null;
    }

    export function createCouponCode(c: BO9Contact) {
        return ((c.names.name.first.charAt(0)+c.names.name.last+'-'+new Date().getFullYear()+'-pp').toLowerCase());
    }