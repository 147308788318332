      <form [formGroup]="PageForm" (ngSubmit)="submitPageForm()" class="Page-form" novalidate >
        <button mat-button type="submit"  [disabled]="disableBtn"class="btn btn-save mat-submit-button">Submit</button>
        <div >
          <mat-form-field appearance="outline"  class="full-width">
            <mat-label>Key</mat-label>
            <input matInput  formControlName="_key"  />
           
          </mat-form-field>
        </div>
        <div >
          <mat-form-field appearance="outline" class="full-width" >
            <mat-label>Title</mat-label>
            <input matInput  formControlName="title" required />
            <mat-error *ngFor="let validation of validationMessages.title">
              <mat-error class="error-message"
                *ngIf="titleControl.hasError(validation.type) && titleControl.dirty 
                    || submitAttempt || titleControl.touched">
                {{ validation.message }}
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>
        <div>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Content</mat-label>
              <qeditor
                formControlName="content"
                placeholder="Type your wisdom here..."
                required
              ></qeditor>
              <mat-error *ngIf="PageForm.get('content').hasError('required')">
                Field is <strong>required</strong>
              </mat-error>
             </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" >
            <mat-label>PageType</mat-label>
            <mat-select matNativeControl  formControlName="pageType" required>
              <mat-option *ngFor="let nnI of pageT" [value]="nnI">
                {{ nnI }}
              </mat-option>
            </mat-select>

            </mat-form-field>
            <mat-form-field appearance="outline" >
              <mat-label>Access key</mat-label>
              <input matInput  formControlName="accessKey" />
  
              </mat-form-field>
              <mat-form-field>
                <ngx-mat-file-input [formControl]="fileControl">
                </ngx-mat-file-input>
             </mat-form-field>
            </div>       
            <button mat-button type="submit" [disabled]="disableBtn" class="btn btn-save mat-submit-button">Submit</button>    
            <div>
            <img *ngIf="image" src="{{image}}" alt="Page Image">    
          </div>
      </form>

   