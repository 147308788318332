import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Observable, of, Subscription } from 'rxjs';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';

import { NaturalNumber, NaturalNumberTypes, stringToNaturalNumber } from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';
import { Pearl, Review } from '../../../../../bo9-shared/models/pearl/pearl.model';
import { PearlsComponent } from 'src/pages/pearls/pearls.component';
import { createUUID } from '../../../../../bo9-shared/uuid.service';
import { DateToYYMMDDhhmmss } from '../../../../../bo9-shared/helpers/time.helper';
import { Meta } from '@angular/platform-browser';
import { UserService } from 'src/services/auth/session.service';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import { SelectsService } from 'src/services/selects/selects.service';
import { findInvalidControlsRecursive } from '../../../helpers/form.helper';


@Component({
  selector: 'pearl-form',
  templateUrl: './pearlForm.component.html',
  styleUrls: ['./pearlForm.component.scss'],
  // make fade in animation available to this component
})
export class PearlFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() pearl: Pearl;
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
  messagesLoading$: Observable<boolean>;


  valid: false;
  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;
  currentP$: Observable<Pearl>;
  PearlForm: FormGroup;

  validationMessages = validationMessages;

  invites: string[];
  PearlModuleTypes: string[]
  nnKeys: string[];
  nnSource: string[];

  isLoading = false;

  user: Person;
  disableBtn = false;
  files: any ;
  fileControl: FormControl;
  image: string;
  moderated: Review;
  subscriptions: Subscription[] = [];
  constructor(
    private auth: AuthService,
    private router: Router,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private arangoService: ArangoService,
    private selectsService: SelectsService,
    ) 
  {

    this.user = this.auth.getAuthUser();
    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #011'); 
      this.router.navigate(['login'] );
    }
  
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'PearlFormComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
  

    this.subscriptions.push(this.selectsService.getSelects()
    .subscribe( (result) => {
      this.PearlModuleTypes = this.selectsService.selects.pearlmoduletypes;
      this.invites = this.selectsService.selects.invitetypes;
    }));


    this.nnKeys = Object.keys(NaturalNumber).map(key => NaturalNumber[key]).filter(value => typeof value === 'string') as string[];
    this.nnSource = Object.keys(NaturalNumberTypes).map(key => NaturalNumberTypes[key]).filter(value => typeof value === 'string') as string[];
    this.PearlForm = this.formBuilder.group({
      _key:  new FormControl('', Validators.compose([])),
      title: new FormControl('', Validators.compose([Validators.required])),
      content: new FormControl('', Validators.compose([ Validators.required])),
      myNN: new FormControl('', Validators.compose([Validators.required])),
      theirNN: new FormControl('', Validators.compose([Validators.required])),
      relationship: new FormControl('', Validators.compose([Validators.required])),
      pearlmodule: new FormControl('', Validators.compose([Validators.required])),
      startDate: new FormControl('', Validators.compose([])),
      endDate: new FormControl('', Validators.compose([])),
      
    });
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  compareNNs(nn1: string, nn2: string): boolean {
    console.log('Compare NN1 ' + JSON.stringify(nn1));
    console.log('Compare NN2 ' + JSON.stringify(nn2));
    return (nn1 === nn2);
  }


  compareRels(rel1: string, rel2: string): boolean {
    console.log('Compare Rel1 ' + JSON.stringify(rel1));
    console.log('Compare Rel2 ' + JSON.stringify(rel2));
    return (rel1 === rel2);
  }

  delImage() {
    this.files =[];
    this.image = null;
  }

  ngOnInit() {
    this.files =[];
    this.image = null;
    this.fileControl = new FormControl('', []);
    this.moderated = {
      reviewer: '',
      approved: false,
      positive: 0,
      whenRated: '',
      reason: '',
      meta: updateMeta()
    };
    this.fileControl.valueChanges.subscribe((files: any) => {
      console.log('File ' + JSON.stringify(files.name))
      if (!Array.isArray(files)) {
        this.files= [files];
      } else {
        this.files = files;
      }
    })
  }

  get titleControl() { return this.PearlForm.controls.title; }
  get contentControl() { return this.PearlForm.controls.content; }
  get theirNNControl() { return this.PearlForm.controls.theirNN;}
  get relationshipControl() { return this.PearlForm.controls.relationship;}
  get pearlmoduleControl() { return this.PearlForm.controls.pearlmodule;}


  onPageChange(event){
    console.log(event);
  }

  optionSelected(event: any) {
    console.log('Selected  ' + JSON.stringify(event.source.value));
  }

  clickApproved() {

    this.moderated.approved = !this.moderated.approved;

  }

  ngOnChanges() {
  
    const p: Pearl = this.pearl
    this.PearlForm.reset();
    if (p) {
      console.log('Got Pearl form ' + JSON.stringify(this.pearl));
      //  console.log('Got Pearl img ' + JSON.stringify(this.pearl.fileInfo[0].Location));
        if (this.pearl && this.pearl.fileInfo &&  this.pearl.fileInfo.length > 0) {
          this.image = this.pearl.fileInfo[0].Location;
        } else {
          this.image = null;
          this.files = [];
        }
        if (!Array.isArray(p.pearlModule)) {
          p.pearlModule = [p.pearlModule];
        }
        if (!Array.isArray(p.relationship)) {
          p.relationship = [p.relationship];
        }
      if (this.pearl && this.pearl.moderated) {
        this.moderated= this.pearl.moderated;
      } 
    }

    this.PearlForm.patchValue({
      _key: p? p._key : '',
      title: p? p.title : '',
      content: p? p.content : '',
      relationship: p? (p.relationship[0] !== '')? p.relationship: ['Self'] : ['Self'],
      pearlmodule: p? (p.pearlModule[0] !== '')? p.pearlModule: ['Public'] : ['Public'],
      theirNN: p? p.theirNN : NaturalNumber.None,
      myNN: p? p.myNN : NaturalNumber.None,
      startDate: p? p.startDate: '',
      endDate: p? p.endDate: ''
    });

  }


  submitPearlForm() {
    console.log('Submit Pearls form ' + JSON.stringify(this.PearlForm.value));
    if (this.PearlForm.valid) {
      console.log('Submit Pearl form - valid');
      let dStart ='';
      let dEnd = '';
    

      if ( this.PearlForm.value.startDate)  {
        dStart = DateToYYMMDDhhmmss(this.PearlForm.value.startDate._d);
      }

      if (this.PearlForm.value.endDate) {
        dEnd = DateToYYMMDDhhmmss(this.PearlForm.value.endDate._d)
      }
      

      const p: Pearl = {
        _key: this.PearlForm.value._key? this.PearlForm.value._key: createUUID(),
        imageURL: '',
        title: this.PearlForm.value.title,
        myNN: this.PearlForm.value.myNN,
        theirNN: this.PearlForm.value.theirNN,
        content: this.PearlForm.value.content,
        relationship: this.PearlForm.value.relationship,
        pearlModule: this.PearlForm.value.pearlmodule,
        moderated: this.moderated,
        reviews: [],
        timeStamp: DateToYYMMDDhhmmss(new Date),
        startDate: dStart,
        endDate:  dEnd,
        meta: updateMeta(this.userService.get())
      }

      if (!this.moderated) {
        this.moderated = {
          reviewer: '',
          approved: false,
          positive: 0,
          whenRated: '',
          reason: '',
          meta: updateMeta()
        };
      }

      if (!this.moderated.approved) {
        if (confirm('Please confirm you wish to save the Pearl as not approved?')) {
          this.subscriptions.push( this.arangoService.addPearl(p, this.files? this.files[0]: [])
          .subscribe( (res) => {
            console.log('Pearl add response ' + JSON.stringify(res));
            
            this.notifyParent.emit('pearl Saved');
          }))
        }
      } else {


        if (this.moderated.approved) {
          p.moderated.reviewer = p.moderated.reviewer? p.moderated.reviewer:this.user.primaryEmail;
          p.moderated.whenRated = p.moderated.whenRated? p.moderated.whenRated :DateToYYMMDDhhmmss();
          p.moderated.reason = p.moderated.reason? p.moderated.reason: 'Admin Approved';
          p.moderated.meta = updateMeta('',p.moderated.meta);
        
        }

        this.subscriptions.push( this.arangoService.addPearl(p, this.files? this.files[0]: [])
        .subscribe( (res) => {
          console.log('Pearl add response  Approved' + JSON.stringify(res));

          this.notifyParent.emit('Approved pearl Saved');
        }))
      }
      

    } else {
      console.log('Submit Pearl form - Not valid');
      alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.PearlForm)));

    }
  }
  
}
