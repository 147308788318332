import { Injectable } from '@angular/core';
import events from 'events';
@Injectable()
export class EventEmitterService  {

    emitter: any;

    constructor () {
        console.log('Emitter create');
        this.emitter = new events.EventEmitter();
    }

};

