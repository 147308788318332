import { ChatRoom } from '../chat/chatroom.model';
import { createUUID, trimUUID } from '../../uuid.service';
import { BO9UUID, NamedModel } from '../UUID.model';
import { Meta, updateMeta } from '../BO9_base/meta.model';
import { DateStringType, DateTimeStringType } from '../BO9_base/BO9_base.model';
import { BO9Address, mergeAddress,  addShortAddress } from '../contact/address.model';
import { mergeEmail, addEmail } from '../contact/email.model';
import {  mergePhones, addPhone } from '../contact/phone.model';
import { Purchase } from '../purchase/purchase.model';
import { BO9Contact,  contactFullName,  makeContactFromForm,  mergeSocialMedia,  mkMTContact, primaryEmail } from '../contact/contact.model';
import { MediaGroupType, mkMTMedia } from '../media/media.model';
import { DateToYYMMDDhhmmss } from '../../helpers/time.helper';
import {  NaturalNumbersType,  makeMTNaturalNumber, mergeNaturalNumbers, addNaturalNumber, makeMTNaturalNumbers, setNaturalNumber, nnSource, stringToNaturalNumber, naturalNumberChanged, NaturalNumber, NaturalNumberTypes, hasNaturalNumber, } from '../naturalNumber/naturalNumber.model';
import { addName, FullName, mergeName } from '../contact/name.model';
import { DifferenceBySource } from './differences.model';
import { fillPreferences, Preferences, setPreferences } from './preferences.model';
import {  multiProduct, Product } from '../product/product.model';
import { InvitedPerson } from './invite.model';
import { EventAttendee, setIdentifiedAttendeeNaturalNumber } from '../event/event.model';
import { WooBilling } from '../woocommerce/wooorder.model';
import {  ProgramTracker} from '../program/program.model';
import { Enrolled, Student } from '../institute/student.model';
import { Agreement } from './agreements.model';


export const defaultAvatar = 'assets/avatar-default.jpg';

export const GenderTypes: string[] = ['Female', 'Male', 'Other','Not Entered'];

export type OtherIdentifyingModalitites = string;

export interface PersonsRetrieved {
    total: number,
    persons: Person[]
}

export interface DetailsType {
    gender: string;
    updatedAt?: DateStringType;
    birthday?: DateTimeStringType;
    birthPlace?: BO9Address;
    momBirthOrder?: number;  // Mother's side
    websiteUrl?: string;
}

export interface OtherTypes {
    typingModality: OtherIdentifyingModalitites;
    value: string;
    meta: Meta;
}

export interface ActionHistoryType {
    otherKey: string;
    otherType: string;
    actionBy: BO9UUID;
    commentary: string;
    meta: Meta;
}

export interface LastEvent {
    event_id: string;
    event_date: string;
}


export interface Person {
    _key: BO9UUID;
    userUID: string;
    contact: BO9Contact;
    primaryEmail: string;
    previousPrimaryEmail?: string;
    active?: boolean;
    avatarUrl?: string;
    imageURL?: string;
    fileInfo?: [{
        Etag: string,
        BucketName: string,
        Key: string,
        Location: string
    }],
    wsSocket?: any;
    isLoggedIn?: boolean;
    details: DetailsType;
    jwt?: any;
    userLastConnected?: Date | null;
    chatRooms?: ChatRoom[]; // User in organization community to see if any shared chats
    hide?: string;
    systemRoles?: string[];  // Only in backend,not required on frontend
    bio?: string;
    notes?: string;
    otherTypes?: OtherTypes[],
    naturalNumbers:NaturalNumbersType;
    media?: MediaGroupType;
    actionHistory?: ActionHistoryType[];
    valid?: boolean;
    appEntered: string;
    source: string;
    differences?: DifferenceBySource[];
    purchases?: Purchase[];
    personOrOtherReferredBy: string;
    referralSource: string;
    whichParentAdoptedThem?: string; // Mother or Father or Both.
    latestEvent?: LastEvent;
    preferences?: Preferences;
    programs?: ProgramTracker[],
    externalIds: {
        activeCampaign: string;
    },
    student?: Student,
    agreements: Agreement[];
    meta?: Meta
}

let personMap = new Map();

export function getGender(g: string): string {

    let res: string = '';

    if (g && g.toLowerCase().indexOf('f') == 0) {
        res = 'Female';
    } else if (g && g.toLowerCase().indexOf('m') == 0) {
        res = 'Male';
    }  else if (g) {
        res = g;
    } else {
        res= 'Not Entered'
    }

    return res
}

export function savePerson(p:Person) {
    if (!personMap.get(p._key)) {
        personMap.set(p._key, p);
    }
}

export function mkSimplePerson(firstName: string, lastName: string, email?: string ): Person {
    let p: Person;

    p = mkEmptyPerson('Admin', email);
    p._key = createUUID();

    if (email) {
        p.primaryEmail = email;
        p.contact.email = addEmail(null, false, email, 'primary');
    }

    p.details = {
        gender: '',
        momBirthOrder: 0
    }

    p.contact.names = addName(null, firstName, lastName, '', '');
    p.naturalNumbers = {
      identified: makeMTNaturalNumber(),
      asserted: makeMTNaturalNumber(),
      invited: makeMTNaturalNumber(),
      history: [],
    }
    return p
 }

export function mkEmptyPerson(app: string, email?: string): Person {
    const id = createUUID();
    return({
    _key: id,
    userUID: '',
    primaryEmail: email? email: '',
    contact: mkMTContact(),
    avatarUrl: '',
    active: false,
    wsSocket: null,
    details: {
        birthday: '',
        gender: '',
    },
    preferences: setPreferences(),
    appEntered: app,
    isLoggedIn: false,
    jwt: null,
    userLastConnected: null,
    chatRooms: [],
    bio: '',
    notes: '',
    naturalNumbers: makeMTNaturalNumbers(),
    otherTypes: [],
    valid: true,
    media:mkMTMedia(),
    actionHistory: [],
    purchases: [],
    source: '',
    personOrOtherReferredBy: '',
    referralSource: '',
    externalIds: {
        activeCampaign: '',
        },
    agreements: [],
    meta: updateMeta()
    })
}




export function hideExistenceInUser(u: Person): Person {


    return u;
}

// Since uuid are unique, return when any uuid fieldmatches
export function getUsersByUUID(uuid: any, items: any[]): Person[] {
  //  console.log('Get Users UUID ' + uuid + ' from ' + JSON.stringify(items));
    const result: Person[] = [];
    for (let i of items) {
        if ( i.uuid === uuid || 
            i.organizationId === uuid || i.employeeOf === uuid) {
                result.push(i);
            }
        
    }
    // console.log('getUser result ' + JSON.stringify(result));
    return result;
}

export function getUsersByEmail(email: string, items: Person[]): Person[] {
    const result: Person[] = [];
    for (let i of items) {
        for (let e of i.contact.email.items) {
            if (e.value=== email) {
                result.push(i);
            }
        }

        
    }
    // console.log('getUser result ' + JSON.stringify(result));
    return result;
}

export function getUserName(user: Person): string {
    return firstName(user) + ' ' + lastName(user);
}

export function firstName(user: Person): string {
    let name = '';
    if (user && user.contact && user.contact.names) {
        name = user.contact.names.name.first;
    }
    return name
}

export function lastName(user: Person): string {
    let name = '';
    if (user && user.contact && user.contact.names) {
        name = user.contact.names.name.last;
    }
    return name
}

export function personFromInvited(creator: string, invited: InvitedPerson): Person {
    let p: Person = mkEmptyPerson(creator, invited.email );

    p.contact.names.name.first = invited.first;
    p.contact.names.name.last = invited.last;
    return p;
}

export function namedModelForUser( uuid: BO9UUID,  items: Person[]): NamedModel {
    let result: NamedModel = {
        name: 'Unknown',
        uuid: uuid
    };
    for (let i of items) {
        if ( i._key === uuid ) {
                return( {
                    name: getUserName(i),
                    uuid: i._key
                })
            }
        
    }
    // console.log('getUser result ' + JSON.stringify(result));
    return result;
}

/* 
Submit person form {
    "contact":{"gender":"","name":{"title":"","first":"Jenny","middle":"","last":"Spring"},"otherNames":[],
    "email":{"DNC":true,"items":[{"type":"","value":"acromomma@gmail.com","status":""}]},
    "phone":{"DNC":false,"items":[{"type":"","number":"","countrycode":"01","status":""}]},
    "address":{"name":"","street1":"","street2":"","city":"Meadow Vista","state":"Ca","zip":"95722","country":"USA"}},
    "email":{"DNC":false,"items":[{"value":"acromomma@gmail.com","type":"","status":""}]},
    "primaryEmail":"acromomma@gmail.com","natnum":{"asserted":{"setAt":"","setBy":{"name":"system","uuid":"","comment":"Initialize None"},
    "naturalNumber":0,"source":"","dateSet":"2020-09-28T08:53:29"},
        "identified":{"setAt":"","setBy":"system","naturalNumber":"2","source":"Identified","dateSet":"2020-09-28T08:53:29"},
        "invited":{"setAt":"","setBy":{"name":"system","uuid":"","comment":"Initialize None"},"naturalNumber":0,"source":"","dateSet":"2020-09-28T08:53:29"}}}

*/

export function mergePersonWithOrigForm( oldP: Person, form: any): Person {

    let natnum = form.orignatnum;

    const p: Person = { // Only keep the key
        _key: form.origkey,
        userUID: form.origuserUID? form.origuserUID: '',
        contact: form.origcontact,
        naturalNumbers: form.orignatnum, 
        bio: form.origbio,
        notes: form.orignotes,
        source: form.origsource,
        primaryEmail: '',
        referralSource: '',
        appEntered: '',
        details: {
            birthday: form.origbirthday,
            gender: form.origgender
        },
        personOrOtherReferredBy: form.origpersonOrOther,
        preferences : {
            autoRefresh: form.origautoRefresh,
            eventFutureDays: form.origeventFutureDays,
            eventHistory: form.origeventHistory,
            rowsPerPage: form.origrowsPerPage
        },
       
        externalIds: {
            activeCampaign: '',
        },
        agreements: []
    }



    if (form.origcontact && form.origcontact.email && form.origcontact.email.items && form.origcontact.email.items.length > 0) {
        let primary = form.origcontact.email.items[form.origcontact.email.items.length -1].value // latest ie last one, but can be reset
        for (const e of form.origcontact.email.items) {
            if (e.primary) {
                primary = e.value; // takes last primary instead
            }
        }
        p.primaryEmail = primary;
    }


    if (p.details) {
        p.details.momBirthOrder = form.origbirthOrder;
        p.details.gender= form.origgender? form.origgender: form.origcontact.gender? form.origcontact.gender:'';
    } else {
        p.details = {
            momBirthOrder: form.origbirthOrder,
            gender: form.origgender? form.origgender: form.origcontact.gender? form.origcontact.gender:''
        }
    }


    if (natnum.identified && natnum.identified.naturalNumber !== "0") {
        let name = '';
        if (typeof natnum.identified.setBy === 'string') {
            name = natnum.identified.setBy 
        } else {
            name = natnum.identified.setBy.name 
        }
        const newIdentified = addNaturalNumber(stringToNaturalNumber(natnum.identified.naturalNumber),
        natnum.identified.setAt, name, natnum.identified.dateSet, natnum.identified.setAt,
        'Form Edit', '');
        if (naturalNumberChanged(p.naturalNumbers.identified, newIdentified)) {
            p.naturalNumbers = setNaturalNumber(p.naturalNumbers,newIdentified,  nnSource.Identified);
        }
    }

    if (natnum.asserted && natnum.asserted.naturalNumber  !== "0") {
        let name = '';
        if (typeof natnum.asserted.setBy === 'string') {
            name = natnum.asserted.setBy 
        } else {
            name = natnum.asserted.setBy.name 
        }
        const newAsserted = addNaturalNumber(stringToNaturalNumber(natnum.asserted.naturalNumber),
        natnum.asserted.setAt, name, natnum.asserted.dateSet, natnum.asserted.setAt,
        'Form Edit', '')
        if (naturalNumberChanged(p.naturalNumbers.asserted, newAsserted)) {
            p.naturalNumbers = setNaturalNumber(p.naturalNumbers,newAsserted,  nnSource.Asserted);
        }

    }

    if (natnum.invited && natnum.invited.naturalNumber  !== "0") {
        let name = '';
        if (typeof natnum.invited.setBy === 'string') {
            name = natnum.invited.setBy 
        } else {
            name = natnum.invited.setBy.name 
        }
        const newInvited = addNaturalNumber(stringToNaturalNumber(natnum.invited.naturalNumber),
        natnum.invited.setAt, name, natnum.invited.dateSet, natnum.invited.setAt,
        'Form Edit', '');
        if (naturalNumberChanged(p.naturalNumbers.invited, newInvited)) {
            p.naturalNumbers = setNaturalNumber(p.naturalNumbers,newInvited,  nnSource.Invited);
        }
    }


    p.meta = updateMeta(p._key,p.meta);

    return mergePerson(oldP, p, oldP._key);
}

export function personFromPersonForm( form: any): Person {

        let natnum = form.natnum;

        const p: Person = { // Only keep the key
            _key: form.key? form.key: createUUID(),
            userUID: form.userUID? form.userUID: '',
            contact: makeContactFromForm(form.contact),
            naturalNumbers: form.natnum, 
            bio: form.bio,
            notes: form.notes,
            source: form.source,
            primaryEmail: '',
            referralSource: '',
            appEntered: '',
            details: {
                birthday: form.birthday,
                gender: form.gender,
                momBirthOrder: form.birthOrder
            },
            personOrOtherReferredBy: form.personOrOther,
            preferences : {
                autoRefresh: form.autoRefresh,
                eventFutureDays: form.eventFutureDays,
                eventHistory: form.eventHistory,
                rowsPerPage: form.rowsPerPage
            },
            externalIds: {
                activeCampaign: '',
            },
            agreements: []
        }
        if (form.contact && form.contact.email && form.contact.email.items && form.contact.email.items.length > 0) {
            let primary = form.contact.email.items[form.contact.email.items.length -1].value // latest ie last one, but can be reset
            for (const e of form.contact.email.items) {
                if (e.primary) {
                    primary = e.value; // takes last primary instead
                }
            }
            p.primaryEmail = primary;
        }
 

        if (p.details) {
            p.details.momBirthOrder = form.birthOrder;
            p.details.gender= form.gender? form.gender: form.contact.gender? form.contact.gender:'';
            p.details.websiteUrl = form.websiteUrl? form.websiteUrl: '';
        } else {
            p.details = {
                momBirthOrder: form.birthOrder,
                gender: form.gender? form.gender: form.contact.gender? form.contact.gender:'',
                websiteUrl : form.websiteUrl? form.websiteUrl: ''
            }
        }


        if (natnum.identified && natnum.identified.naturalNumber !== "0") {
            let name = '';
            if (typeof natnum.identified.setBy === 'string') {
                name = natnum.identified.setBy 
            } else if (natnum.identified.setBy[0]) {
                const names: string[] = natnum.identified.setBy;
                name = names.join();
            } else {
                name = natnum.identified.setBy.name 
            }
            const newIdentified = addNaturalNumber(stringToNaturalNumber(natnum.identified.naturalNumber),
            natnum.identified.setAt, name, natnum.identified.dateSet, natnum.identified.setAt,
            'Form Edit', '');
            if (naturalNumberChanged(p.naturalNumbers.identified, newIdentified)) {
                p.naturalNumbers = setNaturalNumber(p.naturalNumbers,newIdentified,  nnSource.Identified);
            }
        }

        if (natnum.asserted && natnum.asserted.naturalNumber && natnum.asserted.naturalNumber  !== "0") {
            let name = '';
            if (typeof natnum.asserted.setBy === 'string') {
                name = natnum.asserted.setBy 
            } else if (natnum.asserted.setBy[0]) {
                const names: string[] = natnum.asserted.setBy;
                name = names.join();
            } else {
                name = natnum.asserted.setBy.name 
            }
            const newAsserted = addNaturalNumber(stringToNaturalNumber(natnum.asserted.naturalNumber),
            natnum.asserted.setAt, name, natnum.asserted.dateSet, natnum.asserted.setAt,
            'Form Edit', '')
            if (naturalNumberChanged(p.naturalNumbers.asserted, newAsserted)) {
                p.naturalNumbers = setNaturalNumber(p.naturalNumbers,newAsserted,  nnSource.Asserted);
            }
    
        }

        if (natnum.invited && natnum.invited.naturalNumber && natnum.invited.naturalNumber  !== "0") {
            let name = '';
            if (typeof natnum.invited.setBy === 'string') {
                name = natnum.invited.setBy 
            } else {
                name = natnum.invited.setBy.name 
            }
            const newInvited = addNaturalNumber(stringToNaturalNumber(natnum.invited.naturalNumber),
            natnum.invited.setAt, name, natnum.invited.dateSet, natnum.invited.setAt,
            'Form Edit', '');
            if (naturalNumberChanged(p.naturalNumbers.invited, newInvited)) {
                p.naturalNumbers = setNaturalNumber(p.naturalNumbers,newInvited,  nnSource.Invited);
            }
        }
 

        p.meta = updateMeta(p._key,p.meta);
        return(p);
    }

    
export function mergePerson(original: Person, result: Person, currentUser: BO9UUID): Person { // person becomes new
    var _ = require('lodash');
    let p: Person = result;

    if (original.imageURL  && !p.imageURL) {
        p.imageURL = original.imageURL;
    }

    p.contact.names = mergeName(original.contact.names, p.contact.names);

    p.contact.email = mergeEmail(original.contact.email, p.contact.email);

    p.primaryEmail = primaryEmail(p.contact);

    p.contact.phone = mergePhones(original.contact.phone, p.contact.phone);

    p.contact.address = mergeAddress(original.contact.address, p.contact.address);

    p.contact.socialMedia = mergeSocialMedia(original.contact.socialMedia ? original.contact.socialMedia : { updatedAt: '', items: [] },
        p.contact.socialMedia ? p.contact.socialMedia : { updatedAt: '', items: [] });
 
    // Take additional gender by preference if it exist
    if (p.details) {
        p.details.gender = (p.details && p.details.gender)? p.details.gender : original.details.gender;

    } else {
        p.details = {
            gender: (original.details && original.details.gender)? original.details.gender: ''
        }
    }

    if (original.preferences && !result.preferences) {
        p.preferences = fillPreferences(original.preferences)
    }
 
    

    if (p.media && original.media) {
        p.media.photo.permission == p.media.photo.permission && original.media.photo.permission;
        p.media.photo.updatedAt = DateToYYMMDDhhmmss();
        p.media.video.permission == p.media.video.permission && original.media.video.permission;
        p.media.video.updatedAt = DateToYYMMDDhhmmss();
        const temp = p.media.photo.items.concat(original.media.photo.items);
        p.media.photo.items= _.unionWith(temp,_.isEqual);; // Should be uniques
        const tempv = p.media.video.items.concat(original.media.video.items);
        p.media.video.items= _.unionWith(tempv,_.isEqual); // Should be uniques
    } else if (original.media && !result.media) {
        p.media = original.media;
    }

    if (p.purchases && original.purchases) {
        const tempp = p.purchases.concat(original.purchases);
       console.log(">>>>>>>>>>>>>>>>>>>Purchases "+JSON.stringify(tempp.length));
        p.purchases = _.unionWith(tempp,_.isEqual);
       console.log(">>>>>>>>>>>>>>>>POST Purchases "+JSON.stringify( p.purchases?.length));
    } else if (original.purchases && !result.purchases) {
        p.purchases = original.purchases;
    }

    if (p.programs && original.programs) {
        const tempp = p.programs.concat(original.programs);
      //  console.log(">>>>>>>>>>>>>>>>>>>Purchases "+JSON.stringify(tempp));
        p.programs = _.unionWith(tempp,_.isEqual);
       // console.log(">>>>>>>>>>>>>>>>POST Purchases "+JSON.stringify( p.purchases));
    } else if (original.programs && !p.programs) {
        p.programs = original.programs;
    }
   

    if (original.details.birthday != null && p.details.birthday == '') {
        p.details.birthday = original.details.birthday;
    }
    if (original.details.momBirthOrder != null && p.details.momBirthOrder == 0) {
        p.details.momBirthOrder = original.details.momBirthOrder;
    }
    if (original.details.websiteUrl != null && p.details.websiteUrl == '') {
        p.details.websiteUrl = original.details.websiteUrl;
    }

    if (original.bio != null && p.bio == '') {
        p.bio =  original.bio;
    }
    if (original.notes != null && p.notes == '') {
        p.notes = original.notes;
    } else if (original.notes != null && p.notes != null) {
        p.notes = p.notes?.concat(original.notes)
    }

    if (p.media && p.media.video.permission != null &&  original.media &&  original.media.video.permission != null) {
        p.media.video.permission = p.media.video.permission && original.media.video.permission
        p.media.video.updatedAt = DateToYYMMDDhhmmss(new Date);
    }
    if (p.media && p.media.photo.permission != null && original.media &&  original.media.photo.permission == true ) {
        p.media.photo.permission = p.media.photo.permission && original.media.photo.permission;
        p.media.photo.updatedAt = DateToYYMMDDhhmmss(new Date);
    }

    p.naturalNumbers = mergeNaturalNumbers(p.naturalNumbers, original.naturalNumbers);

    if (!p.student && original.student) {
        p.student = original.student;
    }
   
    if (p.actionHistory == null) {
        p.actionHistory = [];
    }

    p.actionHistory.push({ otherKey: result._key, 
            actionBy: currentUser, 
            otherType: 'Person',
            meta:  updateMeta(currentUser, p.meta),
            commentary: 'Updated/Merged' });

    p.meta  = updateMeta(currentUser,p.meta)
    // combine system roles
    if (p.systemRoles) {
        if (original.systemRoles) {
            p.systemRoles = _.uniq(original.systemRoles.concat(original.systemRoles));
        } 
    } else { // not set
        p.systemRoles = original.systemRoles;
    }
 
  

    return (p);
}

export function personHasNaturalNumber(p: Person): boolean {
    if (p && p.naturalNumbers && p.naturalNumbers.identified && p.naturalNumbers.identified.naturalNumber &&  hasNaturalNumber(p.naturalNumbers.identified.naturalNumber)) {
        return true
    }
    return false;
 }

export function personFromAttendee( p: EventAttendee): Person {
    const id = createUUID()

    let newPerson: Person = {
      _key: p._key? p._key: id,
      userUID: '',
      contact: {
        _key: createUUID(),
        usage: 'person',
        email: addEmail(null, p.DNC?p.DNC:false, p.email),
        names : addName(null, p.first, p.last),
        phone: addPhone(null,p.DNC?p.DNC:false,p.phone),
        URL: '',
        notes: '',
        address: addShortAddress(null, p.city, p.state, p.zip, p.country),
        
      },
      appEntered: 'Event',
      primaryEmail: p.email,
      details: {
        birthday: '',
        gender: p.gender? p.gender:  (p.person && p.person.details)?p.person.details.gender : '',
      },
      preferences: setPreferences(),
      naturalNumbers: makeMTNaturalNumbers(),
        source: '',
      notes: p.notes,
      personOrOtherReferredBy: '',
      referralSource: '',
      externalIds: {
        activeCampaign: '',
        },
      agreements: [],
      meta: updateMeta()
    }

    newPerson = setIdentifiedAttendeeNaturalNumber(p, newPerson);

    return newPerson;
  }

  export function personFromOrder( p: WooBilling): Person {
    const id = createUUID()

    const newPerson: Person = {
      _key:  id,
      userUID: '',
      contact: {
        _key: createUUID(),
        usage: 'person',
        email: addEmail(null, false, p.email),
        names : addName(null, p.first_name, p.last_name),
        phone: addPhone(null, false,p.phone),
        URL: '',
        notes: '',
        address: addShortAddress(null, p.city, p.state, p.postcode, p.country),
        
      },
      appEntered: 'Woocommerce Purchase',
      primaryEmail: p.email,
      details: {
        birthday: '',
        gender: '',
      },
      preferences: setPreferences(),
      naturalNumbers: makeMTNaturalNumbers(),
      source: 'Woocommerce',
      personOrOtherReferredBy: '',
      referralSource: '',
      externalIds: {
        activeCampaign: '',
        },
      agreements: [],
      meta: updateMeta()
    }
    return newPerson;
  }



  export function getPurchaseList(person: Person, products: Product[]): multiProduct[] {
      const mP: multiProduct[] = [];
      if (person && person.purchases) {
        for (let i=0; i < person.purchases.length; i++) {
            if (person.purchases[i]) {
                const purchase = person.purchases[i];
                for (let j=0; j< person.purchases[i].order.orderProducts.length; j++) {
                    let prod = products.find(e => e._key === purchase.order.orderProducts[j]._key);
                    if (!prod) { // No products from the Product List, could have been admin assigned subscription
                        const res = purchase.order.orderProducts[j]._key ? purchase.order.orderProducts[j]._key: ''.match(/^[0-9]+$/); // Is is  a real order, ie just numbers, or an uuid, no order
                        if (res) {
                            prod = purchase.order.orderProducts[j]; // So its an 'internally set' product
                        }
                    }
                    if (prod){
                        const orderId = purchase.order.orderId?purchase.order.orderId:'Not available';

                        mP.push( {
                             orderId: trimUUID(orderId),
                             productInfo: prod, 
                             userData: person.purchases[i].order.orderProducts[j].moduleDetail,
                            productIndex: i, orderIndex: j});
                    }
                }
            }
          }
      }
      return mP;
    
  }

  export function cleanGender(inVar: any): Person {

    if (inVar.details && inVar.details.gender) {
        return inVar;
    } else  if (inVar.contact || inVar.contact.gender) {
        if (inVar.details) {
            inVar.details.gender = inVar.contact.gender
        } else {
            inVar.details = {
                gender: inVar.contact.gender
            }
        }
        return inVar as Person;
    } else {
        return inVar as Person;
    }

}
