      <div>
      <form [formGroup]="agreementForm" (ngSubmit)="submitagreementForm()" class="agreement-form" novalidate >
        <button mat-button type="submit"  [disabled]="disableBtn"class="btn btn-save mat-submit-button">Submit</button>

          <mat-form-field appearance="outline" class="full-width" >
            <mat-label>Title</mat-label>
            <input matInput  formControlName="title" required />
           
          </mat-form-field>
          <mat-form-field appearance="outline" >
            <mat-label>Capabilities</mat-label>
            <mat-select matNativeControl   formControlName="capabilities" required multiple>
              <mat-option *ngFor="let nnI of capabilityList" [value]="nnI">
                {{ nnI }}
              </mat-option>
            </mat-select>

            </mat-form-field>

      </form>
      
      <button mat-stroked-button type="button" (click)="addVersionClick()" matTooltip="Add an Version">Add Version<mat-icon>add_box</mat-icon></button>
      </div>
            <div>
              <table mat-table [dataSource]="dataSource" matSort>
          
                <ng-container matColumnDef="version">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Version </th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    <input class="version" matInput [(ngModel)]="agreement.versions[i].version" value="agreement.versions[i].version"/> 
                  </td>
                </ng-container>
          
               
                <ng-container matColumnDef="created">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
                  <td mat-cell *matCellDef="let element"> {{element.dateCreated}} </td>
                </ng-container>
                          
               
                <ng-container matColumnDef="file">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> File </th>
                  <td mat-cell *matCellDef="let element; let i = index">  
                 <span *ngIf="element.sourceFileName">{{ element.sourceFileName }} </span>   <span *ngIf="!element.name"> <input type="file" (change)="uploadFile(i, $event)"></span> 
                  </td>
                </ng-container>
                <ng-container matColumnDef="delete">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete </th>
                  <td mat-cell *matCellDef="let element; let i = index"> <button mat-button type="button" (click)="onClickDelete(i)" matTooltip="Delete"> <mat-icon>delete</mat-icon></button></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              
              </table>
            </div>
             

   

   