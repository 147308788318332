<div class="pearl-modal">
  <mat-card class="bo9mat">
    <mat-card-header>


    </mat-card-header>
    <mat-card-content>
      <div flex="45">
        Do you really want to delete "<b>{{ data.pearl.title }}</b>"?
      </div>

    </mat-card-content>
  </mat-card>
  <footer>
    <button mat-stroked-button (click)="close()">
      NO!
    </button>
    <button class="btn-save" mat-flat-button
      (click)="deleteSelectPearl(data.pearl)">
      Delete Pearl
    </button>
  </footer>
</div>
