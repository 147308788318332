<div [formGroup]="form">
  <div>
    <h3>Emails</h3>
  
    <mat-label>Do Not Contact</mat-label> &nbsp; <mat-checkbox (change)="selectDNC()" [checked]="form.value.DNC"></mat-checkbox>

  <ng-container formArrayName="items">
    <div *ngFor="let item of items.controls; index as idx" [formGroupName]="idx">

      <span *ngIf="!item.value.toValidate; else NoPrimary">
        <mat-label>Primary? </mat-label><mat-checkbox (change)="selectPrimary(idx)" [checked]="item.value.primary"></mat-checkbox><br />
      </span>
      <ng-template #NoPrimary>Email Awaiting Validation<br /></ng-template>
      
        <mat-form-field  appearance="outline" >
          <mat-label>Type</mat-label>
          <mat-select matNativeControl  formControlName="type" required>
            <mat-option *ngFor="let nns of contactTypes" [value]="nns">
              {{ nns }}
            </mat-option>
          </mat-select>

        </mat-form-field>
        <span *ngIf="!item.value.primary; else IsPrimary">
        <mat-form-field  appearance="outline" >
          <mat-label>Email</mat-label>
          <input matInput 
            formControlName="value"
            id="value"
            email="true"
            (blur)="checkUnique(idx)"
          />

        </mat-form-field>
        
        <button mat-button type="button" (click)="removeEmailItem(idx)"> <mat-icon>delete</mat-icon></button>
        </span>
        <ng-template #IsPrimary><br/>{{ item.value.value }} 	<span (mouseenter)="changeText=true" (mouseleave)="changeText=false">
          <span *ngIf="!changeText"><span class="material-icons">help_outline</span></span>
          <span *ngIf="changeText"> Can't edit the Primary Email</span>
        </span></ng-template>
        
    </div>
  
    <span *ngIf="checkCanAdd(); else cantAddMore">
    <button mat-button type="button" (click)="addEmailItem()">Add New Email</button><br></span>
    <ng-template #cantAddMore>Unable to add additional EMails - {{ totalToValidate }} already requiring Validation</ng-template>
    
  </ng-container>
</div>
</div>