import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, ViewChild, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';

import { ArangoService } from 'src/services/arangoservice/arango.service';

import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person, personFromAttendee,getGender} from '../../../../../bo9-shared/models/person/person.model';
import { Difference} from '../../../../../bo9-shared/models/person/differences.model';
import {  differencesForSource, getDifferences, sameDifference} from '../../../../../bo9-shared/models/person/differencesCode.model';
import { validationMessages } from 'src/helpers/form.helper';

import {  Invite } from '../../../../../bo9-shared/models/person/invite.model';

import { Event, EventAttendee, orderFromEvent } from '../../../../../bo9-shared/models/event/event.model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';


import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { Visit } from '../../../../../bo9-shared/models/page/page.model';
import { SearchService } from 'src/services/search/search.service';


@Component({
  selector: 'visitlist',
  templateUrl: './visitList.component.html',
  styleUrls: ['./visitList.component.scss'],
  // make fade in animation available to this component

})
export class VisitListComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() person: Person;
  @Input() errors: string;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() notifyParent: EventEmitter<Person> = new EventEmitter<Person>();
  messagesLoading$: Observable<boolean>;
  events$: Observable<Person[]>;

  pageNum: number =0;
  totalPages: number;
  pageSize: number;
  attendees: EventAttendee[] =[];
  currentPane = 'center';
  lastPane: string;
  fromName = 'Not Set';

 
  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;

  currentP$: Observable<Person>;
  currentPerson: Person;
  people: Person;
  fieldValues: number;
  totalEvents: number;
  relatingPersonName: string;
  eventTag: string = '';
  operation: string = 'none';
  currentSource: EventAttendee;
  nnKeys: string[];
  fromF$:Observable<Person>;
  bySelect: string[] =[];
  attendee: EventAttendee;

  validationMessages = validationMessages;

  Invites$: Observable<Invite[]>;
  PearlModuleTypes$: Observable<string[]>;
  loading = false;
  email: string;
  idList = [];
  user: Person;

  visits: Visit[] = [];
  

  public displayedColumns = ['page','component','when','error'];
  public displayWhoColumn = ['who','when','component','version','error'];
  public dataSource = new MatTableDataSource<Visit>();
  visit: Visit;
  subscriptions: Subscription[] = [];
  constructor(
    private searchService: SearchService,
    private router: Router,
    private arangoService: ArangoService,
    private auth: AuthService
    ) 
  {
    this.user = this.auth.getAuthUser();
    if (!this.person) {
      this.person = this.user;
    }
    this.visit = {
      page: this.router.url, component: 'VisitListComponent', 
      visitor: this.user._key,
      help: false
    }
    this.subscriptions.push(this.arangoService.recordVisit(this.visit).subscribe ({next: () => {}})); 

  }
  

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }




  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.refresh();
    this.operation='none';
  }

  ngOnChanges() {

      this.refresh(); 

  }

  onPageChange(event){
    console.log(event);
    this.pageNum = event.pageIndex
    this.refresh();
  }

  refresh() {

      this.loading = true;
      let startWith =0;
      if (this.user.preferences) {
        this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
      }
      let limit = this.pageSize+1;

      // this.arangoService.search(this.searchString, (this.pageNum -1)* this.pageSize, this.pageSize)
      if (this.pageNum ===0) {
        this.dataSource.data=[];
        this.visits = [];
      } else {
        limit = this.pageSize;
        startWith = this.pageNum * this.pageSize + 1
      }
      console.log(`Search start ${startWith} limit ${limit}`);
      if (this.errors) {
        this.displayedColumns = this.displayWhoColumn;
        this.subscriptions.push(this.arangoService.getErrors(startWith, limit)
        .subscribe( async  (res: any) => {
         // console.log('search  ' + JSON.stringify(res.content.persons));
           const visitors: Visit[] = res.content;

          for(const visitor of visitors) {
            const who = await  this.searchService.searchPersonKey(visitor.visitor, false);
            if (who) {
              //console.log('who  ' + JSON.stringify(who));
              visitor.visitor = who.primaryEmail;
              visitor.error =  visitor.error.substring(0,  visitor.error.indexOf('\\n'));
              this.visits.push(visitor);
            }
          }

          this.fieldValues = 0;
  
  
          if (this.visits) {
   
                this.dataSource.data = this.visits
          }
  
          this.loading = false;
        }))
      } else {
        console.log(`Search start ${startWith} limit ${limit}`);
        const tVisit : Visit = {
          page: null,
          visitor: '',
          component: null
        }
        this.subscriptions.push(this.arangoService.getVisit(this.visit, startWith, limit)
        .subscribe(   (res: any) => {
         // console.log('search  ' + JSON.stringify(res.content.persons));
          this.visits = this.visits.concat(...res.content);
          this.fieldValues = 0;
  
  
          if (this.visits) {
   
                this.dataSource.data = this.visits
          }
  
          this.loading = false;
        }))
      }

  
    
  }

 


  ngOnInit() {
    this.operation='none';
    const str = VisitListComponent.name;
    console.log('Init visit');
  }

  onRefreshClick() {
    this.pageNum =0;
    this.visits = [];
    this.refresh();
  }

  onCloseClick() {
    this.operation='none';
    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.refresh();
    }
  }
}


