import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ChatMember } from '../../../../bo9-shared/models/chat/chatroom.model';
import { networkConfig } from 'src/config/webapp.config';
import { BO9EVENTS } from "../../../../bo9-shared/models/BO9_base/BO9_base.model";



@Injectable()
export class ChatRoomService {

    chats: ChatMember[];

    constructor (
        private http: HttpClient

    ) {
      this.chats = null;
    }

    private loadChatMemberList(): Observable<any> {
      console.log('Get Members  ' + JSON.stringify(networkConfig));
        return this.http.get(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.CHATROOM);
    }

    public setChatMembers(m: ChatMember[]) {
      this.chats = m;
    }

    public getChatMembers(): Observable<ChatMember[]> {
      return new Observable( observer =>{
        if (this.chats) {
            observer.next(this.chats);
            observer.complete();
        }  else {
          const sub = this.loadChatMemberList()
            .subscribe({next:  (msg: any) => {
              console.log('chats from db');
              this.setChatMembers(msg.content);
              if (sub) sub.unsubscribe();
              observer.next(msg.content);
              observer.complete();
          },error: (e) => {console.log('error from db'); observer.error(e)}})
        }
    } )
    }

    public createNewChatRoom(others: string[]) {
      const param: any = {
        others: others
      };
      console.log('create chat room ' + JSON.stringify(others));

      return this.http.post(networkConfig.hostName + ':' + networkConfig.controlPort + '/' + BO9EVENTS.CHATROOM, param);

    }

}

