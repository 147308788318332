
<my-slide-panel [activePane]="currentPane">

  <div leftPane>
    <button mat-stroked-button (click)="onCloseClick()">Back<mat-icon>arrow_back</mat-icon></button> 
   
   
    <credit-form [credit]="selectedCredit$ | async" (notifyParent)="CreditSubmittedHandler($event)"></credit-form>
  </div>
  <div centerPane>
    <button mat-stroked-button (click)="addCreditClick()" matTooltip="Add a Credit Source"><mat-icon>add_box</mat-icon></button>
    <button mat-stroked-button (click)="onRefreshClick()">Refresh<mat-icon>refresh</mat-icon></button>

    <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows >
      <ng-container matColumnDef="edit">
       <th mat-header-cell *matHeaderCellDef> Edit Credit Cert</th>
       <td mat-cell *matCellDef="let element">
         <button type="button" (click)="onClickEdit(element)" matTooltip="View"> <mat-icon>preview</mat-icon></button>
 
       </td>
      </ng-container>
 
     
      <ng-container matColumnDef="creditId">
       <th mat-header-cell *matHeaderCellDef> Credit id </th>
       <td mat-cell *matCellDef="let element">{{element.creditId}}</td>
      </ng-container>

      <ng-container matColumnDef="eventCode">
       <th mat-header-cell *matHeaderCellDef mat-sort-header> Event Code </th>
       <td mat-cell *matCellDef="let element" >  {{element.eventCode}} 
          </td>
      </ng-container>
      <ng-container matColumnDef="BO9Hours">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header> BO9Hours </th>
        <td mat-cell *matCellDef="let element"> {{element.hours.BO9?.hours}} </td>
       </ng-container>
      <ng-container matColumnDef="MTHours">
       <th mat-header-cell *matHeaderCellDef  mat-sort-header> MT Hours </th>
       <td mat-cell *matCellDef="let element"> {{element.hours.MT.hours}} </td>
      </ng-container>
      <ng-container matColumnDef="ICFHours">
       <th mat-header-cell *matHeaderCellDef  mat-sort-header> ICF Hours </th>
       <td mat-cell *matCellDef="let element"> {{element.hours.ICF.hours}}</td>
      </ng-container>
      <ng-container matColumnDef="ICFResourcePercentage">
       <th mat-header-cell *matHeaderCellDef  mat-sort-header> ICF Res % </th>
       <td mat-cell *matCellDef="let element"> {{element.hours.ICF.resourcePercentage}} </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header> Delete </th>
        <td mat-cell *matCellDef="let element"> <button type="button" (click)="onClickDelete(element)" matTooltip="View"> <mat-icon>delete</mat-icon></button> </td>
       </ng-container>
 

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>

     </table>
     <mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]"  (page)="onChangePage($event)"></mat-paginator>

  

  </div>
  <div rightPane>

  </div>
  </my-slide-panel>
 