import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { ArangoService } from 'src/services/arangoservice/arango.service';

import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person, mkEmptyPerson} from '../../../../bo9-shared/models/person/person.model';
import { Difference } from '../../../../bo9-shared/models/person/differences.model';
import { validationMessages } from 'src/helpers/form.helper';

import { Member } from '../../../../bo9-shared/models/member/member.model';

import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';

import { ActiveCampaignService } from 'src/services/activecampaign/activecampaign.service';

import { EventEmitterService } from 'src/services/events/events.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { dateFormating } from '../../../../bo9-shared/helpers/time.helper';
import { ActCamFields, ActiveCampaignDeal,ActiveCampaignPipeLines } from '../../../../bo9-shared/models/mailCampaign/activeCampaign.model';
import { DataSource } from '@angular/cdk/collections';

@Component({
  selector: 'app-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.scss'],
  animations: [
    trigger('detailExpand', [
    state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
    state('expanded', style({height: '*'})),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]),
],

})
export class DealsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  messagesLoading$: Observable<boolean>;
  expandedElement = DataSource;
  totalFound: number=0;
  totalFound$: Observable<number>;
  pageNum$: Observable<number>;
  totalPages$: Observable<number>;
  pageSize$: Observable<number>;
  pageNum: number =0;
  totalPages: number;
  pageSize: number;
  affiliate: Member;
  currentPane = 'center';
  valid: false;
  noAttendees = [];

  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;
  deals: ActiveCampaignDeal[];
  stages : { id: string, title: string}[] = [];

  searchString: string='';

  public displayedColumns = ['id', 'title', 'stage','contact','mdate', 'delete']; // No delete available?
  public dataSource = new MatTableDataSource<ActiveCampaignDeal>();

  RelateForm: FormGroup;

  relatingPersonName: string;
  totalEvents: number;
  eventNum = 0;
  event: Event;
  origP: Person;
  loading: boolean;
  newP: Person;
  difference: Difference;
  selectedPipeLine: string = '';
  disableBtn = true;
  operation="Merge";
  lastPane: string;
  user:Person;
  validationMessages = validationMessages;
  events: Event[];
  updated= false;
  subscriptions: Subscription[] = [];
  orderAsc = false;
  pipelines: ActiveCampaignPipeLines;
  selectedStage: string = '';

  constructor(

    private arangoService: ArangoService,
    private activeCampaignService: ActiveCampaignService,
    private auth: AuthService,
    private router: Router,
    private emitterService: EventEmitterService
    ) {
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'MemberComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #015'); 
      this.router.navigate(['login'] );
    }  
    this.pageNum =0;
    if (this.user.preferences) {
      this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }
    
    this.subscriptions.push( this.activeCampaignService.getPipeLines()
    .subscribe( async (result) => {
          this.pipelines = result.content;
         
    }))
    
  }

  selectAsc() {
    this.orderAsc = !this.orderAsc;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  dateFormating(date: any, kind: string) {
		return dateFormating(date, kind);
	}

  onClickSearch() {
    this.deals = [];
    this.pageNum = 0;
    this.loadDeals();
  }

  selectPipeline() {
    if (this.selectedPipeLine) {
      for (const p of this.pipelines.dealGroups) {
        if (p.id === this.selectedPipeLine[0]) {
          this.stages = [];
          for (const s of p.stages) { // add stages to list for selection from all
            for (const oneofall of this.pipelines.dealStages) {
              if (s == oneofall.id) {
                this.stages.push({id: s, title: oneofall.title });
              }
            }
          }
        }
      }
    }
  }


  async loadDeals() {
    this.disableBtn = true;
    this.loading = true;
    let startWith =0;
   
    let limit = this.pageSize+1;

    // this.arangoService.search(this.searchString, (this.pageNum -1)* this.pageSize, this.pageSize)
    if (this.pageNum ===0) {
      this.dataSource.data=[];
      this.deals=[];
    } else {
      limit = this.pageSize;
      startWith = this.pageNum* this.pageSize + 1
    }

    if (this.selectedPipeLine) {

    
      let returned = 0;
      let gotSoFar= startWith;
      while (returned < limit) {
      
        console.log("LOAD startwith "+gotSoFar+" limit "+limit+" returned "+returned);
        const dealList = await firstValueFrom(this.arangoService.getDeals(this.selectedPipeLine,this.selectedStage,this.orderAsc,gotSoFar, limit));
      
        if (dealList && dealList.content && dealList.content.deals && dealList.content.deals.length> 0) {
          gotSoFar+= dealList.content.deals.length;
          for (const aff of dealList.content.deals as ActiveCampaignDeal[]) {
                const actCampPerson =  await firstValueFrom(this.activeCampaignService.getContactById(aff.contact));
                const dealFields =  await firstValueFrom(this.activeCampaignService.getDealData(aff.links.dealCustomFieldData));
                for (const p of this.pipelines.dealGroups) {
                  if (p.id === aff.group) {
                    aff.group = p.title;
                    break;
                  }
                }

                if (actCampPerson) {
                  aff.dealFields = actCampPerson;
                  aff.fields = dealFields.content.dealCustomFieldData;
                  let found = false;

                  if (aff.title.includes(this.searchString)) {
                    found=true;
                    this.deals.push(aff);
                    this.dataSource.data = this.deals;
                    returned++;
                    //console.log("title "+aff.title);
                  } else  if (this.searchString !== '' && aff.fields && aff.fields.length > 0) {
                  // not in title, keep looking
                    for (const f of aff.fields) {
                    // console.log("fields"+JSON.stringify(f.fieldValue));
                      if (f.fieldValue.includes(this.searchString)) {
                        found = true;
                      }

                    }
                    if (found) {
                      this.deals.push(aff);
                      returned++;
                      console.log("Matches "+returned);
                      this.dataSource.data = this.deals;
                    }
                  } else {
                    this.deals.push(aff);
                    returned++;
                    this.dataSource.data = this.deals;
                  }

                
                }
              }
            } else {
              if(returned==0) {
                alert ("No deals found " + JSON.stringify(dealList.content));
              }

              returned = 100000; // hack to finish loop
            }
    }

    this.disableBtn = false;
    

    this.loading =false;
          

    } else {
      alert("No pipeline selected");
      this.disableBtn = false;
    

    this.loading =false;
    }

    }


  ngOnInit() {

  }

  stageSelected($event: any, element: ActiveCampaignDeal) {
    console.log(`stage selected `+JSON.stringify($event.value));
      this.subscriptions.push( this.activeCampaignService.updateDealStage(element.id, element.stage)
      .subscribe( (res) => {
        
        }))

  }

  onRefreshClick() {
    console.log('Refresh click ');
        this.loadDeals()// refreash
  }



  onDelete($event) {
    console.log('Delete click '+JSON.stringify($event));
    if (confirm("Are you sure you want to delete this Deals? "+ $event.title + '(ID: '+$event.id+ ' for '+$event.dealFields.email+')')) {
      this.subscriptions.push( this.activeCampaignService.deleteDeal($event.id)
      .subscribe ( (result) => {
        this.updated= true;
        console.log('Save result  is: ' + JSON.stringify(result.content));
        this.emitterService.emitter.emit('Message','Deal ' +  $event.title+ ' deleted');
        this.disableBtn = false;
        this.loading =false;
        if (this.user.preferences && this.user.preferences.autoRefresh) {
          this.loadDeals(); // refreash
        }
        
      }))
    }
  }

  onChangePage(event){
    console.log(event);
    const oldPage = this.pageNum
    this.pageNum = event.pageIndex
    if (oldPage < this.pageNum) {
      this.loadDeals();
    }
    this.pageSize = event.pageSize;

  }


}
