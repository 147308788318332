<div >

     <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="title">
               <th mat-header-cell *matHeaderCellDef style='width:35%; padding-left:4px;'> Title</th>
               <td mat-cell *matCellDef="let inelement" style='padding-left:4px;padding-right:10px;'>
						{{inelement?.title}}
					</td>
             </ng-container>
             <ng-container matColumnDef="sku">
               <th mat-header-cell *matHeaderCellDef> SKU</th>
               <td mat-cell *matCellDef="let inelement">
						{{inelement?.sku}}
               </td>
             </ng-container>
             <ng-container matColumnDef="quantity">
              <th mat-header-cell *matHeaderCellDef> Purchased</th>
              <td mat-cell *matCellDef="let inelement">
					  {{inelement?.quantity}}
              </td>
            </ng-container>
            <ng-container matColumnDef="remaining">
              <th mat-header-cell *matHeaderCellDef> Remaining</th>
              <td mat-cell *matCellDef="let inelement">
					  {{inelement?.quantity-inelement?.used}}
              </td>
            </ng-container>

            <ng-container matColumnDef="reset">
              <th mat-header-cell *matHeaderCellDef style='padding-right:4px;white-space:nowrap'> Reset Q</th>
              <td mat-cell *matCellDef="let inelement;let i = index"  style='padding-right:4px;'>
					  <button type="button" (click)="onClickReset(i)"><mat-icon>restore</mat-icon></button>
              </td>
            </ng-container>

             <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
             <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
             </table>

 <!-- <mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]"  (page)="onChangePage($event)">
  </mat-paginator>-->

 </div>
