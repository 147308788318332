<div >
	<h3>Purchases 	<mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="30" class='spin' style="float: right"> </mat-progress-spinner>
</h3>

	<button mat-button type="button" (click)="onClickSync()" class='sync-button'> Sync Purchases <mat-icon>cloud_sync</mat-icon></button>

  <span *ngIf="isAdmin()">&nbsp;<button mat-button type="button" (click)="onClickAddProduct()" class='sync-button'> Admin Add Purchase <mat-icon>add</mat-icon></button></span>
  
	<table mat-table [dataSource]="dataSource" matSort>

<!--
      <ng-container matColumnDef="orderid">
        <th mat-header-cell *matHeaderCellDef> Order Id </th>
        <td mat-cell *matCellDef="let element"> {{element.orderId}}</td>
      </ng-container> -->

      <ng-container matColumnDef="products">
        <th mat-header-cell *matHeaderCellDef mat-sort-header  class='purchase-header'> PRODUCTS </th>
        <td mat-cell *matCellDef="let element" class="product-area" >
			  <small><strong>Purchased on: </strong></small> &nbsp; {{dateFormating(element.timestamp,"purchased")}}<br><small><strong>Order ID: </strong></small> &nbsp; {{element.orderId}}
			  <purchaseitems-list [order]="element.order" [tracking]="orderTrack" class="purchase-list"></purchaseitems-list>
        </td>
      </ng-container>
      <ng-container matColumnDef="setone">
        <th mat-header-cell *matHeaderCellDef> Set to One</th>
        <td mat-cell *matCellDef="let inelement; let i = index"> <button type="button" (click)="onClickSetOne(i)"> <mat-icon>add</mat-icon></button>
         
        </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef class='purchase-header'> DELETE </th>
        <td mat-cell *matCellDef="let inelement; let i = index"> &nbsp; <button type="button" (click)="onClickDelete(i)"> <mat-icon>delete</mat-icon></button>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  <mat-paginator   [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]">
  </mat-paginator>

 </div>
