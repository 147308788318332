import { Person } from '../../../../bo9-shared/models/person/person.model';

import webappConfig from '../../../../bo9-shared/config/webappconfig.json';
import { BO9EVENTS } from '../../../../bo9-shared/models/BO9_base/BO9_base.model';
import { forkJoin, Observable, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Order, ActCampCustomer} from '../../../../bo9-shared/models/purchase/purchase.model';
import { mkMTMailCampaign, TransportNative} from '../../../../bo9-shared/models/mailCampaign/mailCampaign.model';
import { SearchService } from '../search/search.service';
@Injectable()
export class PearlService {

  constructor (
    private http: HttpClient,
    private searchService: SearchService
  ) {

    // hack not empty
  }

  tagAndFields: TransportNative = {
    customFields: [],
    tags:[]
  };

  startOffset= 0


    public subscribe(email: string, list: string):  Observable<any>  {
      const param: any = {
        email: email,
        list: list
      };
      console.log("subscribe  "+JSON.stringify(param));
       return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.PEARLSUBSCRIBE, param)

    }

    public unsubscribe(email: string):  Observable<any>  {
      const param: any = {
        email: email
      };
      console.log("subscribe  "+JSON.stringify(param));
       return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.PEARLUNSUBSCRIBE, param)

    }

    public getSeen(email: string):  Observable<any>  {
      console.log("get seen  "+JSON.stringify(email));
       return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.PEARLSEEN+'?email='+email)

    }

  

}
