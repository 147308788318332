import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { networkConfig, websiteConfig } from 'src/config/webapp.config';

@Injectable()
export class WebsiteService {

    page = new Map();

    constructor (
        private http: HttpClient
    ) {

    }
  
  //https://bodyof9.com/wp-json/wp/v2/pages/6912

  public loadGuildDoc(url:string): Observable<any> {
    console.log('Get doc  ' + JSON.stringify(url));
      return this.http.get(url);
  }

    private loadWebPage(pageId: Number): Observable<any> {
      console.log('Get webpage  ' + JSON.stringify(websiteConfig));
        return this.http.get(websiteConfig.hostName + '/' + websiteConfig.pages + pageId);
    }

    public setWebPage(pageId: number, webpage:any) {
        this.page.set(pageId, webpage);
      }
  
      public getWebPage(pageId: number): Observable<any> {
        return new Observable( observer =>{
          if (this.page.has(pageId)) {
              observer.next(this.page.get(pageId));
              observer.complete();
          }  else {
              const sub =  this.loadWebPage(pageId)
              .subscribe({
                next: (wp: any) => {
                console.log('page from website');

                this.setWebPage(pageId, wp);
                
                observer.next(wp);

                observer.complete();
                }, 
                error: (e) => {
                  console.log('error from db'); observer.error(e);
                }
              })
            }
      })
    }
}
