import { Person } from '../../../../bo9-shared/models/person/person.model';

import webappConfig from '../../../../bo9-shared/config/webappconfig.json';
import { BO9EVENTS } from '../../../../bo9-shared/models/BO9_base/BO9_base.model';
import { firstValueFrom, forkJoin, Observable, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Order, ActCampCustomer} from '../../../../bo9-shared/models/purchase/purchase.model';
import { TransportNative} from '../../../../bo9-shared/models/mailCampaign/mailCampaign.model';
import { ActCamCustomFieldMap, ActCamFields, actCampaignContactFromPerson, ActiveCampaignInboundContact, personFromActContact} from '../../../../bo9-shared/models/mailCampaign/activeCampaign.model';

import { SearchService } from '../search/search.service';
import { GroupMembership } from '../../../../bo9-shared/models/person/group.model';
@Injectable()
export class ActiveCampaignService {
  tagAndFields: TransportNative = {
    customFields: [],
    tags:[]
  };
  constructor (
    private http: HttpClient,
    private searchService: SearchService
  ) {

    // hack not empty
  }



  startOffset= 0;


    public getContacts(offset: number, count: number, fillType: string, email?: string):  Observable<any>  {

      return new Observable( (observer) => {
        let found = false;
        let limit = 100; // no cost here asking for 100
        if (offset ===0) {
          this.startOffset = 0;
        }  // don't need to use offset anymore, will take up from where left off

        const incoming:ActCamFields[] =[];
        // if email we're searching on that email
        const reqdURL = email? BO9EVENTS.ACTIVECAMPAIGNCONTACTBYEMAIL+'?email='+email + '&' : BO9EVENTS.READACTIVECAMPAIGN + '?';
        ( async () => {

          if (this.tagAndFields.customFields.length==0) {
            const res =  await firstValueFrom(this.getContactTagFieldValues(true));
          }
          while (incoming.length < count) {
            const result:any = await firstValueFrom(this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' +reqdURL+'limit='+limit+'&offset='+this.startOffset));
            if (result && result.content.contacts.length > 0) {
              if (result.content.contacts.length < count) {
                count = result.content.contacts.length;
              }
              for( const ctact of result.content.contacts) {
                this.startOffset ++; // next time move past this.
                if (!found) { // So - if we have enough, thats great - ignore the rest - we'll get them on next page
                  console.log(`Found ${incoming.length} to fill ${count}`);

                  const contact:ActCamFields = await firstValueFrom(this.getContactByEmail(ctact.email));
                  if (contact) {
                    contact.naturalNumberIssue = false;
                    if (fillType === 'merge') {
                      if (contact.person) {
                        if (contact.person.naturalNumbers.identified.naturalNumber != contact.naturalNumber) {
                            contact.naturalNumberIssue = true;
                            contact.naturalNumber = contact.naturalNumber;
                            incoming.push(contact);
                        }
                      }
                    } else if (fillType === 'todb') {
                      if (!contact.person) {
                        incoming.push(contact);
                      }
                    } else {
                      incoming.push(contact);
                    }
                   
                  }

                 
                  if (incoming.length === count) {
                    console.log(`Found ${incoming.length} to fill ${count}`);
                    found = true;
                  
                  };
                }
    
              }
            } else {
              count = incoming.length; // we're done - no more to read
            }
        }
        observer.next(incoming);
        observer.complete();
      })();
      })
    }
    
    public prepay(member: GroupMembership):  Observable<any>  {
      const param: any = {
        member: member
      };
      console.log("Prepay  "+JSON.stringify(param));
       return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVECAMPAIGNPREPAY, param)
    }

    public revoke(dealid: string, eid: string):  Observable<any>  {
      return this.http.delete(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVECAMPAIGNREVOKE+'?dealid='+dealid+'&eid='+eid);
    }

    public useprepay(dealid: string, eid: string):  Observable<any>  {
      const param: any = {
       dealid: dealid,
       eid: eid
      };
      return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVECAMPAIGNPREPAYUSE,param);
    }
    public updateDealStage(dealid: string, newStage: string):  Observable<any>  {
      const param: any = {
       dealid: dealid,
       newstage: newStage
      };
      return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVECAMPAIGNPREPAYUSE,param);
    }
    public invite(member: GroupMembership, inviteType: string):  Observable<any>  {
      const param: any = {
        member: member,
        inviteType: inviteType
      };
      console.log("Invite  "+JSON.stringify(param));
       return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVECAMPAIGNINVITE, param)
    }

    public readList(id: string):  Observable<any>  {

      console.log("Readlist  "+JSON.stringify(id));
       return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.READACTIVECAMPAIGNLIST+'?id='+id)
    }

    public deleteFromList(id: string, listid: string):  Observable<any>  {
        return this.http.delete(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVECAMPAIGNCONTACTLIST+'?id='+id+'&listid='+listid);
      }

    public readAllLists():  Observable<any>  {

       return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVECAMPAIGNLISTS)
    }

    public getContactLists(id: string):  Observable<any>  {

      console.log("getContactLists  "+JSON.stringify(id));
       return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVECAMPAIGNCONTACTLIST+'?id='+id)
    }


    public getPipeLines():  Observable<any>  {

      console.log("getPipelines  ");
       return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVECAMPAIGNPIPELINES)
    }



    public getContactByEmail(email: string):  Observable<ActCamFields>  {
        return new Observable( (observer) => {
          let incoming:ActCamFields;
          // if email we're searching on that email
          const reqdURL = BO9EVENTS.ACTIVECAMPAIGNCONTACTBYEMAIL+'?email='+email + '&single=true&'; 
          ( async () => {
  
            if (this.tagAndFields.customFields.length==0) {
              const res = await this.getContactTagFieldValues(true);
            }
          
            const result:any = await firstValueFrom(this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' +reqdURL));
            if (result && result.content) { 
                const ctact = result.content;
                const fields = result.content.fieldValues;
                const persons = await firstValueFrom(this.searchService.searchEmail(ctact.email, true));
                const actContact: ActiveCampaignInboundContact = {
                  id : ctact.id,
                  first_name : ctact.firstName,
                  last_name : ctact.lastName,
                  email: ctact.email,
                  phone: ctact.phone,
                  date: ctact.date,
                  org_name: ctact.orgName  // TODO if orgName doesn't exist make the GROUP
                }
                const newC:ActCamFields = ActCamCustomFieldMap(actContact,fields, persons? persons[0]: null, this.tagAndFields);
                newC.deals = result.content.deals;
                newC.tags = result.content.tags;
                incoming =newC ;
            }
            observer.next(incoming);
            observer.complete();
        })();
        })
    }

    public getContactById(id: string):  Observable<ActCamFields>  {
      return new Observable( (observer) => {
        let incoming:ActCamFields;
        // if email we're searching on that email
        const reqdURL = BO9EVENTS.ACTIVECAMPAIGNCONTACT+'?id='+id; 
        ( async () => {

          if (!(this.tagAndFields && this.tagAndFields.customFields && this.tagAndFields.customFields.length>0)) {
            const res = await firstValueFrom(this.getContactTagFieldValues(true));
          }
        
          const result:any = await firstValueFrom(this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' +reqdURL));
          if (result && result.content && result.content.contact ) { 
              const ctact = result.content.contact;
              const fields = result.content.fieldValues;
              const deals =  result.content.deals;
              const persons = await firstValueFrom(this.searchService.searchEmail(ctact.email, true));
              const actContact: ActiveCampaignInboundContact = {
                id : ctact.id,
                first_name : ctact.firstName,
                last_name : ctact.lastName,
                email: ctact.email,
                phone: ctact.phone,
                date: ctact.date,
                org_name: ctact.orgName  // TODO if orgName doesn't exist make the GROUP
              }
              const newC:ActCamFields = ActCamCustomFieldMap(actContact,fields, 
                  persons? persons[0]: null,  
                  this.tagAndFields);
              newC.deals = deals;
              incoming =newC ;
          }
          observer.next(incoming);
          observer.complete();
      })();
      })
  }


    public getTemplate(tID: string):  Observable<any>  {
     // console.log('get contact for ' + email);
      return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVECAMPAIGNTEMPLATE+'?templateID='+tID);
    }

    public getDealData(url: string):  Observable<any>  {
      // console.log('get tag and field values');
       return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVECAMPAIGNDEALDATA+'?url='+url);
     }

    public loadContactTagFieldValues():  Observable<any>  {
     // console.log('get tag and field values');
      return this.http.get(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.READACTIVECAMPAIGNTAGSANDFIELDS);
    }

    public deleteContact(id: string):  Observable<any>  {
      //console.log('delete contact ' + id);
      return this.http.delete(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.DELETEACTIVECAMPAIGNCONTACT+'?id='+id);
    }

    public deleteDeal(id: string):  Observable<any>  {
      //console.log('delete contact ' + id);
      return this.http.delete(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVECAMPAIGNDEAL+'?deal_id='+id);
    }

    public syncContact(p: Person):  Observable<any>  {
      return new Observable( observer=> {
        console.log('sync contact ' + p._key);
        const gfsub = this.getContactTagFieldValues(true) 
        .subscribe( async () => {
          const contact = actCampaignContactFromPerson(p, this.tagAndFields.customFields);
          console.log('sync contact ' + JSON.stringify(contact));
          const param: any = {
            contact: contact
          };
          const msg = await firstValueFrom(this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVECAMPAIGNCONTACT, param))
          observer.next(msg);
          observer.complete();
        })
      })
    }



    public createOrder(order: Order):  Observable<any>  {
      const param: any = {
        ecomOrder: order
      };
      console.log("createOrder  "+JSON.stringify(param));
       return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVECAMPAIGNCREATEORDER, param)

    }

    public createCustomer(cust: ActCampCustomer):  Observable<any>  {
      const param: any = {
        ecomCustomer: cust
      };
      console.log("createCustomer  "+JSON.stringify(param));
       return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVECAMPAIGNCREATECUSTOMER, param)

    }

    public addContactTag(id: string, tagId: string):  Observable<any>  {
      const param: any = {
        contact: id,
        tag: tagId
      };
      console.log("addContact Tag "+JSON.stringify(param));
       return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVECAMPAIGNADDCONTACTTAG, param)

    }


    public createTag(tag: string):  Observable<any>  {
      const param: any = {
        tag: tag
      };
       return this.http.post(webappConfig.networkConfig.hostName + ':' + webappConfig.networkConfig.controlPort + '/' + BO9EVENTS.ACTIVECAMPAIGNCREATETAG, param)

    }


    public getContactTagFieldValues(force: boolean): Observable<TransportNative> {
      return new Observable( observer =>{
          if (!force && this.tagAndFields.customFields && this.tagAndFields.customFields.length > 0) {
              observer.next(this.tagAndFields);
              observer.complete();
          }  else {
              const sub = this.loadContactTagFieldValues()
              .subscribe({
                next: (msg: any) => {
                  console.log('tagfields from db');
                  this.tagAndFields.customFields = msg.content.customFields.fields;
                  this.tagAndFields.tags = msg.content.tags.tags;
                  if (sub) sub.unsubscribe();
                  observer.next(this.tagAndFields);
                  observer.complete();
                  }, 
                error: (e) => {
                  console.log('error from db'); observer.error(e);
                  }
              })
          }
      } )
  }

}
