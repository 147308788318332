import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';

import { AuthService } from 'src/services/auth/auth.service';

import { Router } from '@angular/router';

import { forkJoin, Subscription } from 'rxjs';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { ArangoService } from 'src/services/arangoservice/arango.service';

@Component({
  selector: 'app-dialog-notify',
  templateUrl: './notifyDialog.component.html',
  styleUrls: ['./notifyDialog.component.scss'],

})

export class NotifyDialogComponent implements OnInit  {
  disableBtn = false;
  user: Person;
  subscriptions: Subscription[] = [];
  constructor(
    private dialogRef: MatDialogRef<NotifyDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: { messages: string[] } ,
    private auth: AuthService,

    private arangoService: ArangoService,
    private router: Router,



   ) {
      console.log ('Init notify dialog ' + JSON.stringify(data));
      this.user = this.auth.getAuthUser();
      this.subscriptions.forEach(s => s.unsubscribe());
      this.subscriptions.push(this.arangoService.recordVisit({
        page: this.router.url, component: 'NotifyDialogComponent', 
        visitor: this.user._key,
        help: false
      }).subscribe ({next: () => {}})); 
     }

  ngOnInit() {

    console.log ('Init noify init ' + JSON.stringify(this.data));
    this.user = this.auth.getAuthUser();

    if (!this.user) {
      console.error('User Not logged in ');
      alert('EMail support&#64;bodyof9.com - Permission  Error #006'); 
      this.router.navigate(['login'] );
    }

  }


  close() {
    this.dialogRef.close();
  }
}
