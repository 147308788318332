import { DateTimeStringType } from "../BO9_base/BO9_base.model";
import { ArangoNodeType, BO9UUID, ArangoKeyType, ArangoRevType } from "../arango/arango.model";
import { Meta, updateMeta } from "../BO9_base/meta.model";
import { ModuleDetail, Product } from '../product/product.model';
import { createUUID } from '../../uuid.service';
import { DateToYYMMDDhhmmss } from '../../helpers/time.helper';
import { WooWebHookOrder } from "../woocommerce/wooorder.model";
import { WooDownload } from "../woocommerce/woodownload.model";


export interface PurchasesType {
    id: string;
    status: string;  
}

export interface OrderTrack {
    orderId: string; // ToOrder
    productId: string;
    personId: string;  // FromPerso
    quantityUsed: number;
    resetCount: boolean;
    appliedTo?: any; // Depends on order/product applied.
    meta: Meta;
    notes: string;
}

export interface Order {  // Cases (externalid) required as is for active campaign
    orderId: string;
    orderKey: string;
    externalid: string;
    source: number;
    email: string;
    orderProducts: Product[];
    orderUrl: string;
    externalCreatedDate: string;
    totalPrice: string;
    currency: string;
    orderNumber: string;
    connectionid: string;
    customerid: string;
    download?: WooDownload;
}

export interface Purchase {
    _key: BO9UUID;
    from: string; // Person
    to: string; // Product or Event
    timestamp: DateTimeStringType;
    kind: PurchasesType;
    orderId: string;
    order: Order;
    customerId: string;
    meta: Meta;
}

export interface ActCampCustomer {
    connectionid: string,
    externalid: string,
    id: string,
    email: string,
    acceptsMarketing: string,
    created: boolean;
}

export function addPurchase(ps: Purchase[], p: Purchase): Purchase[] {
    let newPs: Purchase[];

    if (!ps) {
        newPs = [p]
    } else {
        newPs = ps;
        newPs.unshift(p);
    }

    return newPs;

}

export function createPurchaseFromSubscription(cid: string, email: string, p: Product[]):  Purchase {
    const order: Order = {
        orderId: createUUID(),
        orderKey: '',
        externalid: '',
        source: 0,
        email: email,
        orderProducts: p,
        orderUrl: '',
        externalCreatedDate: DateToYYMMDDhhmmss(),
        totalPrice: sumPrices(p).toPrecision(2),
        currency: '$',
        orderNumber: '1',
        connectionid: '',
        customerid: cid
    }

    const purchase: Purchase = {
        _key: createUUID(),
        from: cid,
        to: cid,
        timestamp: DateToYYMMDDhhmmss(),
        kind: {id: '1', status: 'Subscribed'},
        orderId: order.orderId,
        order: order,
        customerId: cid,
        meta: updateMeta()
    }

    return purchase;

}

export function createPurchaseFromOrder(p: Product[], worder: WooWebHookOrder):  Purchase {
    const order: Order = {
        orderId: worder.id.toString(),
        orderKey: worder.order_key,
        externalid: '',
        source: 0,
        email: worder.billing.email,
        orderProducts: p,
        orderUrl: '',
        externalCreatedDate: worder.date_completed? worder.date_completed: DateToYYMMDDhhmmss(),
        totalPrice: worder.total,
        currency: worder.currency,
        orderNumber: '1',
        connectionid: '',
        customerid: worder.customer_id.toString(),
        download: worder.download
    }

    const purchase: Purchase = {
        _key: createUUID(),
        from: worder.customer_id.toString(),
        to: worder.customer_id.toString(),
        timestamp: worder.date_completed? worder.date_completed: DateToYYMMDDhhmmss(),
        kind: {id: worder.line_items[0].id.toString(), status: worder.line_items[0].name},
        orderId: order.orderId,
        order: order,
        customerId: worder.customer_id.toString(),
        meta: updateMeta()
    }

    return purchase;

}

function sumPrices(p: Product[]): number {
    let sum = 0;

    for (const prod of p) {
        sum = sum + (+prod.price);
    }

    return sum;
}

export interface PurchaseOffsets {
    purchaseIndex: number,
    orderProductIndex: number
}

