import { Capabilities } from "../models/person/capabilities.model";
import { isSet, setBit, Word32Bit } from "./bit.helper";


export function checkCapable(c: Capabilities, token: any): boolean {

    let check : Capabilities;

    if (typeof (c) === "string") {
        check = Capabilities[c as keyof typeof Capabilities];
    } else {
        check = c;
    }
    //console.log('check capable '+JSON.stringify(c) + ' ' + JSON.stringify(token));


    switch (check) {
        case Capabilities.SuperAdmin: 
            if (isSet(token.capable as Word32Bit, Capabilities.SuperAdmin)) return true; else return false;
        case Capabilities.Admin: 
            if ( isSet(token.capable as Word32Bit, Capabilities.Admin) ||
            isSet(token.capable as Word32Bit, Capabilities.SuperAdmin)) return true; else return false;
        case Capabilities.Master: 
            if ( isSet(token.capable as Word32Bit, Capabilities.Master) ||
            isSet(token.capable as Word32Bit, Capabilities.Admin) ||
            isSet(token.capable as Word32Bit, Capabilities.SuperAdmin)) return true; else return false;
        case Capabilities.SeniorJ: 
            if (isSet(token.capable as Word32Bit, Capabilities.SeniorJ) ||
            isSet(token.capable as Word32Bit, Capabilities.Master) ||
            isSet(token.capable as Word32Bit, Capabilities.Admin) ||
            isSet(token.capable as Word32Bit, Capabilities.SuperAdmin)) return true; else return false;
        case Capabilities.Journeyman: 
            if (isSet(token.capable as Word32Bit, Capabilities.Journeyman) ||
            isSet(token.capable as Word32Bit, Capabilities.Master) ||
            isSet(token.capable as Word32Bit, Capabilities.SeniorJ) ||
            isSet(token.capable as Word32Bit, Capabilities.Admin) ||
                isSet(token.capable as Word32Bit, Capabilities.SuperAdmin)) return true; else return false;
        case Capabilities.Apprentice: 
            if (
            isSet(token.capable as Word32Bit, Capabilities.Apprentice) ||
                isSet(token.capable as Word32Bit, Capabilities.Journeyman) ||
                isSet(token.capable as Word32Bit, Capabilities.Master) ||
            isSet(token.capable as Word32Bit, Capabilities.SeniorJ) ||
            isSet(token.capable as Word32Bit, Capabilities.Admin) ||
            isSet(token.capable as Word32Bit, Capabilities.SuperAdmin)) return true; else return false;
        case Capabilities.User: 
            if (isSet(token.capable as Word32Bit, Capabilities.User) || 
            isSet(token.capable as Word32Bit, Capabilities.Apprentice) ||
                isSet(token.capable as Word32Bit, Capabilities.Journeyman) ||
                isSet(token.capable as Word32Bit, Capabilities.Master) ||
            isSet(token.capable as Word32Bit, Capabilities.SeniorJ) ||
            isSet(token.capable as Word32Bit, Capabilities.Admin) ||
            isSet(token.capable as Word32Bit, Capabilities.SuperAdmin)) return true; else return false;
        default:
            console.log('check capable falied '+JSON.stringify(c) + ' ' + JSON.stringify(token));
            return false;
    }

    return false;
  
  }

