//@ts-nocheck

type expectMatch = {
    obj: any;
    len: number;
}

function initCache (): any {
    const store:any = []
    // cache only first element, second is length to jump ahead for the parser
    const cache = function cache (value: any) {
      store.push(value.obj)
      return value
    }
  
    cache.get = (index: any) => {
      if (index >= store.length) {
        throw RangeError(`Can't resolve reference ${index + 1}`)
      }
  
      return store[index]
    }
  
    return cache
  }
  
  function expectType (str:any, cache:any): any {
    const types = /^(?:N(?=;)|[bidsSaOCrR](?=:)|[^:]+(?=:))/g
    const type = (types.exec(str) || [])[0]
  
    if (!type) {
      throw SyntaxError('Invalid input: ' + str)
    }
  
    switch (type) {
      case 'N':
        return cache([null, 2])
      case 'b':
        return cache(expectBool(str))
      case 'i':
        return cache(expectInt(str))
      case 'd':
        return cache(expectFloat(str))
      case 's':
        return cache(expectString(str))
      case 'S':
        return cache(expectEscapedString(str))
      case 'a':
        return expectArray(str, cache)
      case 'O':
        return expectObject(str, cache)
      case 'C':
        return expectClass(str, cache)
      case 'r':
      case 'R':
        return expectReference(str, cache)
      default:
        throw SyntaxError(`Invalid or unsupported data type: ${type}`)
    }
  }
  
  function expectBool (str:any): expectMatch {
    const reBool = /^b:([01]);/
    const [match, boolMatch] = reBool.exec(str) || []
  
    if (!boolMatch) {
      throw SyntaxError('Invalid bool value, expected 0 or 1')
    }
  
    return {obj:boolMatch === '1', len: match.length}
  }
  
  function expectInt (str:any): expectMatch {
    const reInt = /^i:([+-]?\d+);/
    const [match, intMatch] = reInt.exec(str) || []
  
    if (!intMatch) {
      throw SyntaxError('Expected an integer value')
    }
  
    return {obj: parseInt(intMatch, 10), len:match.length}
  }
  
  function expectFloat (str:any):expectMatch {
    const reFloat = /^d:(NAN|-?INF|(?:\d+\.\d*|\d*\.\d+|\d+)(?:[eE][+-]\d+)?);/
    const [match, floatMatch] = reFloat.exec(str) || []
  
    if (!floatMatch) {
      throw SyntaxError('Expected a float value')
    }
  
    let floatValue
  
    switch (floatMatch) {
      case 'NAN':
        floatValue = Number.NaN
        break
      case '-INF':
        floatValue = Number.NEGATIVE_INFINITY
        break
      case 'INF':
        floatValue = Number.POSITIVE_INFINITY
        break
      default:
        floatValue = parseFloat(floatMatch)
        break
    }
  
    return {obj: floatValue, len: match.length}
  }
  
  function readBytes (str:any, len:any, escapedString = false) {
    let bytes = 0
    let out = ''
    let c = 0
    const strLen = str.length
    let wasHighSurrogate = false
    let escapedChars = 0
  
    while (bytes < len && c < strLen) {
      let chr = str.charAt(c)
      const code = chr.charCodeAt(0)
      const isHighSurrogate = code >= 0xd800 && code <= 0xdbff
      const isLowSurrogate = code >= 0xdc00 && code <= 0xdfff
  
      if (escapedString && chr === '\\') {
        chr = String.fromCharCode(parseInt(str.substr(c + 1, 2), 16))
        escapedChars++
  
        // each escaped sequence is 3 characters. Go 2 chars ahead.
        // third character will be jumped over a few lines later
        c += 2
      }
  
      c++
  
      bytes += isHighSurrogate || (isLowSurrogate && wasHighSurrogate)
        // if high surrogate, count 2 bytes, as expectation is to be followed by low surrogate
        // if low surrogate preceded by high surrogate, add 2 bytes
        ? 2
        : code > 0x7ff
          // otherwise low surrogate falls into this part
          ? 3
          : code > 0x7f
            ? 2
            : 1
  
      // if high surrogate is not followed by low surrogate, add 1 more byte
      bytes += wasHighSurrogate && !isLowSurrogate ? 1 : 0
  
      out += chr
      wasHighSurrogate = isHighSurrogate
    }
  
    return {out: out, bytes: bytes, escapedChars: escapedChars}
  }
  

  function expectString (str:any): expectMatch {
    // PHP strings consist of one-byte characters.
    // JS uses 2 bytes with possible surrogate pairs.
    // Serialized length of 2 is still 1 JS string character
    const reStrLength = /^s:(\d+):"/g // also match the opening " char
    const [match, byteLenMatch] = reStrLength.exec(str) || []
  
    if (!match) {
      throw SyntaxError('Expected a string value')
    }
  
    const len = parseInt(byteLenMatch, 10)
  
    str = str.substr(match.length)
  
    const rb = readBytes(str, len);
    const strMatch = rb.out;
    const  bytes = rb.bytes;
  
    if (bytes !== len) {
      throw SyntaxError(`Expected string of ${len} bytes, but got ${bytes}`)
    }
  
    str = str.substr(strMatch.length)
  
    // strict parsing, match closing "; chars
    if (!str.startsWith('";')) {
      throw SyntaxError('Expected ";')
    }
  
    return {obj: strMatch, len: match.length + strMatch.length + 2} // skip last ";
  }
  
  function expectEscapedString (str:any): expectMatch {
    const reStrLength = /^S:(\d+):"/g // also match the opening " char
    const [match, strLenMatch] = reStrLength.exec(str) || []
  
    if (!match) {
      throw SyntaxError('Expected an escaped string value')
    }
  
    const len = parseInt(strLenMatch, 10)
  
    str = str.substr(match.length)


    const rb = readBytes(str, len,true);
    const strMatch = rb.out;
    const  bytes = rb.bytes;
    const escapedChars = rb.escapedChars

  
    if (bytes !== len) {
      throw SyntaxError(`Expected escaped string of ${len} bytes, but got ${bytes}`)
    }
  
    str = str.substr(strMatch.length + escapedChars * 2)
  
    // strict parsing, match closing "; chars
    if (!str.startsWith('";')) {
      throw SyntaxError('Expected ";')
    }

    return {obj: strMatch, len: match.length + strMatch.length + 2} // skip last ";
  }
  
  function expectKeyOrIndex (str:any): expectMatch {
    try {
      return expectString(str)
    } catch (err) {}
  
    try {
      return expectEscapedString(str)
    } catch (err) {}
  
    try {
      return expectInt(str)
    } catch (err) {
      throw SyntaxError('Expected key or index')
    }
  }
  
  function expectObject (str:any, cache:any) : expectMatch{
    // O:<class name length>:"class name":<prop count>:{<props and values>}
    // O:8:"stdClass":2:{s:3:"foo";s:3:"bar";s:3:"bar";s:3:"baz";}
    const reObjectLiteral = /^O:(\d+):"([^"]+)":(\d+):\{/
    const [objectLiteralBeginMatch, /* classNameLengthMatch */, className, propCountMatch] = reObjectLiteral.exec(str) || []
  
    if (!objectLiteralBeginMatch) {
      throw SyntaxError('Invalid input')
    }
  
    if (className !== 'stdClass') {
      throw SyntaxError(`Unsupported object type: ${className}`)
    }
  
    let totalOffset = objectLiteralBeginMatch.length
  
    const propCount = parseInt(propCountMatch, 10)
    const obj = {}
    cache([obj])
  
    str = str.substr(totalOffset)
  
    for (let i = 0; i < propCount; i++) {
      const prop = expectKeyOrIndex(str)
      str = str.substr(prop.len)
      totalOffset += prop.len
  
      const value = expectType(str, cache)
      str = str.substr(value.len)
      totalOffset += value.len;
  
      obj[prop.obj] = value.obj;
    }
  
    // strict parsing, expect } after object literal
    if (str.charAt(0) !== '}') {
      throw SyntaxError('Expected }')
    }
  
    return {obj:obj, len: totalOffset + 1} // skip final }
  }
  
  function expectClass (str:any, cache:any) {
    // can't be well supported, because requires calling eval (or similar)
    // in order to call serialized constructor name
    // which is unsafe
    // or assume that constructor is defined in global scope
    // but this is too much limiting
    throw Error('Not yet implemented')
  }
  
  function expectReference (str:any, cache:any): expectMatch {
    const reRef = /^[rR]:([1-9]\d*);/
    const [match, refIndex] = reRef.exec(str) || []
  
    if (!match) {
      throw SyntaxError('Expected reference value')
    }
  
    return {obj:cache.get(parseInt(refIndex, 10) - 1), len:match.length}
  }
  
  function expectArray (str:any, cache:any): expectMatch {
    const reArrayLength = /^a:(\d+):{/
    const [arrayLiteralBeginMatch, arrayLengthMatch] = reArrayLength.exec(str) || []
  
    if (!arrayLengthMatch) {
      throw SyntaxError('Expected array length annotation')
    }
  
    str = str.substr(arrayLiteralBeginMatch.length)
  
    const arr = expectArrayItems(str, parseInt(arrayLengthMatch, 10), cache)
  
    // strict parsing, expect closing } brace after array literal
    if (str.charAt(arr.len) !== '}') {
      throw SyntaxError('Expected }')
    }
  
    return {obj:arr.obj, len: arrayLiteralBeginMatch.length + arr.len + 1} // jump over }
  }
  
  function expectArrayItems (str:any, expectedItems = 0, cache:any): expectMatch {
    let key
    let item
    let totalOffset = 0
    let hasContinousIndexes = true
    let lastIndex = -1
    let items = {}
    cache([items])
  
    for (let i = 0; i < expectedItems; i++) {
      key = expectKeyOrIndex(str)
  
      hasContinousIndexes = hasContinousIndexes && typeof key.obj === 'number' && key.obj === lastIndex + 1
      lastIndex = key.obj
  
      str = str.substr(key.len)
      totalOffset += key.len
  
      // references are resolved immediately, so if duplicate key overwrites previous array index
      // the old value is anyway resolved
      // fixme: but next time the same reference should point to the new value
      item = expectType(str, cache)
      str = str.substr(item.len)
      totalOffset += item.len
  
      items[key.obj] = item.obj as any;
    }
  
    if (hasContinousIndexes) {
      items = Object.values(items)
    }
  
    return {obj:items, len:totalOffset}
  }
  
 export function php_unserialize (str:string) {
    //       discuss at: https://locutus.io/php/unserialize/
    //      original by: Arpad Ray (mailto:arpad@php.net)
    //      improved by: Pedro Tainha (https://www.pedrotainha.com)
    //      improved by: Kevin van Zonneveld (https://kvz.io)
    //      improved by: Kevin van Zonneveld (https://kvz.io)
    //      improved by: Chris
    //      improved by: James
    //      improved by: Le Torbi
    //      improved by: Eli Skeggs
    //      bugfixed by: dptr1988
    //      bugfixed by: Kevin van Zonneveld (https://kvz.io)
    //      bugfixed by: Brett Zamir (https://brett-zamir.me)
    //      bugfixed by: philippsimon (https://github.com/philippsimon/)
    //       revised by: d3x
    //         input by: Brett Zamir (https://brett-zamir.me)
    //         input by: Martin (https://www.erlenwiese.de/)
    //         input by: kilops
    //         input by: Jaroslaw Czarniak
    //         input by: lovasoa (https://github.com/lovasoa/)
    //      improved by: Rafał Kukawski
    // reimplemented by: Rafał Kukawski
    //           note 1: We feel the main purpose of this function should be
    //           note 1: to ease the transport of data between php & js
    //           note 1: Aiming for PHP-compatibility, we have to translate objects to arrays
    //        example 1: unserialize('a:3:{i:0;s:5:"Kevin";i:1;s:3:"van";i:2;s:9:"Zonneveld";}')
    //        returns 1: ['Kevin', 'van', 'Zonneveld']
    //        example 2: unserialize('a:2:{s:9:"firstName";s:5:"Kevin";s:7:"midName";s:3:"van";}')
    //        returns 2: {firstName: 'Kevin', midName: 'van'}
    //        example 3: unserialize('a:3:{s:2:"ü";s:2:"ü";s:3:"四";s:3:"四";s:4:"𠜎";s:4:"𠜎";}')
    //        returns 3: {'ü': 'ü', '四': '四', '𠜎': '𠜎'}
    //        example 4: unserialize(undefined)
    //        returns 4: false
    //        example 5: unserialize('O:8:"stdClass":1:{s:3:"foo";b:1;}')
    //        returns 5: { foo: true }
    //        example 6: unserialize('a:2:{i:0;N;i:1;s:0:"";}')
    //        returns 6: [null, ""]
    //        example 7: unserialize('S:7:"\\65\\73\\63\\61\\70\\65\\64";')
    //        returns 7: 'escaped'
  
    try {
      if (typeof str !== 'string') {
        return false
      }
  
      return expectType(str, initCache()).obj
    } catch (err) {
      console.error(err)
      return false
    }
  }

  export function php_serialize (mixedValue:any): string {
    //  discuss at: https://locutus.io/php/serialize/
    // original by: Arpad Ray (mailto:arpad@php.net)
    // improved by: Dino
    // improved by: Le Torbi (https://www.letorbi.de/)
    // improved by: Kevin van Zonneveld (https://kvz.io/)
    // bugfixed by: Andrej Pavlovic
    // bugfixed by: Garagoth
    // bugfixed by: Russell Walker (https://www.nbill.co.uk/)
    // bugfixed by: Jamie Beck (https://www.terabit.ca/)
    // bugfixed by: Kevin van Zonneveld (https://kvz.io/)
    // bugfixed by: Ben (https://benblume.co.uk/)
    // bugfixed by: Codestar (https://codestarlive.com/)
    // bugfixed by: idjem (https://github.com/idjem)
    //    input by: DtTvB (https://dt.in.th/2008-09-16.string-length-in-bytes.html)
    //    input by: Martin (https://www.erlenwiese.de/)
    //      note 1: We feel the main purpose of this function should be to ease
    //      note 1: the transport of data between php & js
    //      note 1: Aiming for PHP-compatibility, we have to translate objects to arrays
    //   example 1: serialize(['Kevin', 'van', 'Zonneveld'])
    //   returns 1: 'a:3:{i:0;s:5:"Kevin";i:1;s:3:"van";i:2;s:9:"Zonneveld";}'
    //   example 2: serialize({firstName: 'Kevin', midName: 'van'})
    //   returns 2: 'a:2:{s:9:"firstName";s:5:"Kevin";s:7:"midName";s:3:"van";}'
    //   example 3: serialize( {'ü': 'ü', '四': '四', '𠜎': '𠜎'})
    //   returns 3: 'a:3:{s:2:"ü";s:2:"ü";s:3:"四";s:3:"四";s:4:"𠜎";s:4:"𠜎";}'
    let val, key, okey
    let ktype = ''
    let vals = ''
    let count = 0
    const _utf8Size = function (str:any): number {
      return ~-encodeURI(str).split(/%..|./).length
    }
    const _getType = function (inp:any): string {
      let match
      let key
      let cons
      let types
      let type = typeof inp;
      if (type === 'object' && !inp) {
        return 'null'
      }
      if (type === 'object') {
        if (!inp.constructor) {
          return 'object'
        }
        cons = inp.constructor.toString()
        match = cons.match(/(\w+)\(/)
        if (match) {
          cons = match[1].toLowerCase()
        }
        types = ['boolean', 'number', 'string', 'array']
        for (key in types) {
          if (cons === types[key]) {
            type = types[key]
            break
          }
        }
      }
      return type
    }
    const type = _getType(mixedValue)
    switch (type) {
      case 'function':
        val = ''
        break
      case 'boolean':
        val = 'b:' + (mixedValue ? '1' : '0')
        break
      case 'number':
        val = (Math.round(mixedValue) === mixedValue ? 'i' : 'd') + ':' + mixedValue
        break
      case 'string':
        val = 's:' + _utf8Size(mixedValue) + ':"' + mixedValue + '"'
        break
      case 'array':
      case 'object':
        val = 'a'
        /*
        if (type === 'object') {
          var objname = mixedValue.constructor.toString().match(/(\w+)\(\)/);
          if (objname === undefined) {
            return;
          }
          objname[1] = serialize(objname[1]);
          val = 'O' + objname[1].substring(1, objname[1].length - 1);
        }
        */
        for (key in mixedValue) {
          if (mixedValue.hasOwnProperty(key)) {
            ktype = _getType(mixedValue[key])
            if (ktype === 'function') {
              continue
            }
            okey = (key.match(/^[0-9]+$/) ? parseInt(key, 10) : key)
            vals += php_serialize(okey) + php_serialize(mixedValue[key])
            count++
          }
        }
        val += ':' + count + ':{' + vals + '}'
        break
      case 'undefined':
      default:
        // Fall-through
        // if the JS object has a property which contains a null value,
        // the string cannot be unserialized by PHP
        val = 'N'
        break
    }
    if (type !== 'object' && type !== 'array') {
      val += ';'
    }
    return val
  }