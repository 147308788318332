<div class="help-modal">
  <h1 tabindex="1">{{ title }}</h1>    <button mat-stroked-button (click)="close()">
    Close
  </button>
 
  <div [innerHtml]="currentHelp?.content"></div>
  <div [innerHtml]="general?.content"></div>
    
  <footer>
    <button mat-stroked-button (click)="close()">
      Close
    </button>
  </footer>
</div>
