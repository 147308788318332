<div class="selectPerson-modal">
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"> </mat-progress-spinner>
	<button mat-stroked-button (click)="close()">Close</button>
	 &nbsp;

	<mat-card class="bo9mat">
		<h3>Program Participants</h3>
	 <mat-card-content>
		
		  <table mat-table [dataSource]="dataSource" matSort>


		  <ng-container matColumnDef="email">
			 <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
			 <td mat-cell *matCellDef="let element"  >
				<button mat-button [cdkCopyToClipboard]="element.email"><mat-icon >content_copy</mat-icon></button> {{element.email}}
			 </td>
		  </ng-container>
		  <ng-container matColumnDef="name">
			 <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
			 <td mat-cell *matCellDef="let element">  {{element.first}} {{element.last}} </td>
		  </ng-container>
		 

		  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		  <tr mat-row *matRowDef="let row; columns: displayedColumns;"  [ngClass]="{'make-gold': row.selected }"></tr>

		  </table>
	 </mat-card-content>
	 <h3>Program Participants - Full Email List with semi colons</h3>
	 <mat-card-content>
		 
		<button mat-button [cdkCopyToClipboard]="fullList"><mat-icon >content_copy</mat-icon></button>{{ fullList }}
	 </mat-card-content>
  </mat-card>
  <footer>
	 <button mat-stroked-button (click)="close()">Close</button>

  </footer>
</div>
