import { CalendlyEvent, CalendlyEventPayload } from '../calendly/calendlyEvent.model';
import { Person } from '../person/person.model';
import { Product } from '../product/product.model';
import { PurchaseOffsets } from '../purchase/purchase.model';
import { WooCustomer } from './woocustomer.model';
import { WooDownload } from './woodownload.model';

export enum WooOrderStatus {
    Pending=  "pending",
    Processing = "processing",
    OnHold = "on-hold",
    Completed = "completed",
    Cancelled = "cancelled",
    Refunded = "refunded",
    Failed = "failed"

}
export interface WooLink {
    href: string;
}
export interface WooWebHookOrder {
    id:number,
    parent_id: number,
    status: string,
    currency: string,
    version: string,
    prices_include_tax: boolean,
    date_created : Date,
    date_modified: Date,
    discount_total: string,
    discount_tax: string,
    shipping_total: string,
    shipping_tax: string,
    cart_tax: string,
    total: string,
    total_tax: string,
    customer_id: number,
    order_key: string,
    billing: WooBilling
    shipping: WooBilling,
    payment_method: string,
    payment_method_title: string,
    transaction_id: string,
    customer_ip_address: string,
    customer_user_agent: string,
    created_via: string,
    customer_note: string,
    date_completed: string| null,
    date_paid: Date,
    cart_hash: string,
    number: string,
    meta_data: any[],
    line_items:WooLineItem[],
    tax_lines: any[],
    shipping_lines: any[],
    fee_lines:any[],
    coupon_lines:any[],
    refunds: any[],
    date_created_gmt: Date,
    date_modified_gmt: Date,
    date_completed_gmt: Date | null,
    date_paid_gmt: Date,
    currency_symbol: string,
    download: WooDownload,
    links: {
        self: WooLink[],
        collection: WooLink[],
        customer:[]
    }

}

export interface WooShipping {
    method_id: string,
    method_title: string,
    total: string;
} 
export interface WooAPI3Order {
    payment_method: string,
    payment_method_title: string,
    set_paid: true,
    billing_address: WooBilling,
    shipping_address: WooBilling,
    customer_id: number,
    line_items: any[],
    shipping_lines: WooShipping[]
}
export interface WooOrder {

    id:	 number
    parent_id	: number,
    number	: string,
    order_key	: string,
    created_via	: string,
    version	: string,
    status	: string,
    currency	: string,
    date_created	: string,
    date_created_gmt	: string,
    date_modified	: string,
    date_modified_gmt	: string,
    discount_total	: string,
    discount_tax	: string,
    shipping_total	: string,
    shipping_tax	: string,
    cart_tax	: string,
    total	: string,
    total_tax	: string,
    prices_include_tax	: boolean,
    customer_id	: number,
    customer_ip_address	: string,
    customer_user_agent	: string,
    customer_note	: string,
    billing	: WooBilling,
    shipping	: any,
    payment_method	: string,
    payment_method_title	: string,
    transaction_id	: string,
    date_paid	: string,
    date_paid_gmt	: string,
    date_completed	: string,
    date_completed_gmt	: string,
    cart_hash	: string,
    meta_data	: any[],
    line_items	: any[],
    tax_lines	: any[],
    shipping_lines	: any[],
    fee_lines	: any[],
    coupon_lines	: any[],
    refunds	: any[],
    set_paid	: boolean,
    orderer?:  string,
    tooltips?: string,
    calendly?: WooDownload[],
    
}

export interface WooBilling {
    first_name:	string
    last_name:	string
    company:	string
    address_1:	string
    address_2:	string
    city:	string
    state:	string
    postcode:	string
    country:	string
    email:	string
    phone:	string

}
export interface WooLineItem {
    id : number,
    name	: string,
    product_id	: number,
    variation_id	: number,
    quantity	: number,
    tax_class	: string,
    subtotal	: string,
    subtotal_tax	: string,
    total	: string,
    total_tax	: string,
    taxes	: any[],
    meta_data	: any[],
    sku	: string,
    price	: string,
    calendy?: CalendlyEvent;
}

export function createWooCustomerFromPerson(person: Person): WooCustomer {
    let country = 'USA';
    if (person.contact.address.items && person.contact.address.items.length > 0 &&person.contact.address.items[0].country) {
        if ((person.contact.address.items[0].country as any).name) {
            country = (person.contact.address.items[0].country as any).name
        } else {
            country = person.contact.address.items[0].country
        }
    }
    const address: WooBilling = {
        first_name:	person.contact.names.name.first,
        last_name:	person.contact.names.name.last,
        company:	'',
        address_1:	person.contact.address.items[0].street1? person.contact.address.items[0].street1:'',
        address_2:	person.contact.address.items[0].street2? person.contact.address.items[0].street2:'',
        city:	person.contact.address.items[0].city? person.contact.address.items[0].city:'',
        state:	person.contact.address.items[0].state? person.contact.address.items[0].state:'',
        postcode:	person.contact.address.items[0].zip? person.contact.address.items[0].zip:'',
        country:	country,
        email:	person.primaryEmail,
        phone:	person.contact.phone.items[0].number? person.contact.phone.items[0].number:'',
    }
    const cust: WooCustomer = {
        email: person.primaryEmail,
        first_name:	person.contact.names.name.first,
        last_name:	person.contact.names.name.last,
        username: person.contact.names.name.first+'.'+person.contact.names.name.last,
        billing: address,
        shipping: address
    }

    return cust;
}

export function createWooOrderFromPersonProduct (product: Product, person: Person, customer: WooCustomer, quantity: string): WooAPI3Order {
    let country = 'USA';
    if (person.contact.address.items && person.contact.address.items.length > 0 &&person.contact.address.items[0].country) {
        if ((person.contact.address.items[0].country as any).name) {
            country = (person.contact.address.items[0].country as any).name
        } else {
            country = person.contact.address.items[0].country
        }
    }
    const address: WooBilling = {
        first_name:	person.contact.names.name.first,
        last_name:	person.contact.names.name.last,
        company:	'',
        address_1:	person.contact.address.items && person.contact.address.items[0] && person.contact.address.items[0].street1? person.contact.address.items[0].street1:'',
        address_2:	person.contact.address.items && person.contact.address.items[0] && person.contact.address.items[0].street2? person.contact.address.items[0].street2:'',
        city:	person.contact.address.items && person.contact.address.items[0] && person.contact.address.items[0].city? person.contact.address.items[0].city:'',
        state:	person.contact.address.items && person.contact.address.items[0] && person.contact.address.items[0].state? person.contact.address.items[0].state:'',
        postcode:	person.contact.address.items && person.contact.address.items[0] && person.contact.address.items[0].zip? person.contact.address.items[0].zip:'',
        country:	country,
        email:	person.primaryEmail,
        phone:	person.contact.phone.items && person.contact.phone.items[0] &&person.contact.phone.items[0].number? person.contact.phone.items[0].number:'',
    }

    const newOrder: WooAPI3Order = {
        
            payment_method: 'ABA',
            payment_method_title: 'Assigned By Admin',
            set_paid: true,

        billing_address: address,
        shipping_address: address,
        customer_id: customer.id? customer.id: 0, // 0 is Guest
        line_items: [{
            product_id: product.externalid.toString(),
            quantity: quantity,
            total: "0"
        }],
        shipping_lines: [{
            method_id: 'Assigned By Admin',
            method_title: 'Assigned By Admin',
            total: "0"
        }]

    }


    return newOrder;
}

export function findExistingPurchaseIndexes( order: WooWebHookOrder, person: Person) : PurchaseOffsets {
    let ret: PurchaseOffsets = {
        purchaseIndex: -1,
        orderProductIndex: -1
    }

    if (person.purchases && person.purchases.length >0) {
        for(let li=0; li< order.line_items.length; li++) {
            const item = order.line_items[li];
           // console.log("item j "+JSON.stringify(item));
            for( let i=0;  i< person.purchases.length; i++) {
                //console.log("product "+i);
                if (person.purchases[i].order && person.purchases[i].order.orderProducts && person.purchases[i].order.orderProducts.length > 0) {
                    for (let j=0; j<person.purchases[i].order.orderProducts.length; j++) {
                      // console.log("order j "+j);
                       // console.log("person order id "+person.purchases[i].order.orderId );
                      //  console.log("order id "+order.id );
                      //  console.log('person product id  '+person.purchases[i].order.orderProducts[j]._key);
                     //  console.log('itm product id  '+item.product_id.toString());
                        if ( (person.purchases[i].order.orderProducts[j]&& 
                            person.purchases[i].order.orderProducts[j]._key == item.product_id.toString()) &&
                                        (person.purchases[i].order.orderId == order.id.toString())) {
                            const found: PurchaseOffsets = {
                                purchaseIndex: i,
                                orderProductIndex: j
                            }
                            console.log("found"+JSON.stringify(found));
                            return (found)
                        }
                    }

                }
            }
        }
    }

    return ret;
}
