import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Observable, of, Subscription } from 'rxjs';
import { mkEmptyPerson, Person, mkSimplePerson } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { FormInvite, Invite, InvitedStatus } from '../../../../../bo9-shared/models/person/invite.model';
import { formGroup, GroupType, Group, GroupMembership } from '../../../../../bo9-shared/models/person/group.model';
import { addNaturalNumber, makeMTNaturalNumber, NaturalNumber, NaturalNumberTypes } from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';
import { createUUID } from '../../../../../bo9-shared/uuid.service';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';

import { SelectsService } from 'src/services/selects/selects.service';
import { SearchService } from 'src/services/search/search.service';

import { EventEmitterService } from 'src/services/events/events.service';
import { GroupsService } from 'src/services/groups/groups.service';
import { findInvalidControlsRecursive } from '../../../helpers/form.helper';

@Component({
  selector: 'group-form',
  templateUrl: './groupForm.component.html',
  styleUrls: ['./groupForm.component.scss'],
  // make fade in animation available to this component

})
export class GroupFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() inputGroup: Group;
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();
  messagesLoading$: Observable<boolean>;


  people: Person[];
  valid: false;
  maxSize: number = 7;
  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;
  emailKeys:{key: string; email: string, naturalNumber: NaturalNumber }[] =[];
  nameKeys:{key: string; name: string, naturalNumber: NaturalNumber }[] =[];
  searchString: string;
  currentP$: Observable<Person>;
  GroupForm: any;
  relatingPersonName: string;

  validationMessages = validationMessages;

  groupTypes: GroupType[];

  emails$: Observable<string[]>;
  names$: Observable<string[]>;
  isLoading = false;

  firstname = '';
  selectedEmail: string;
  selectedName: string;
  public emailList: Observable<string[]> = of(["Angular", "React", "Node", "Java"]);
  public nameList: Observable<string[]> = of(["Angular", "React", "Node", "Java"]);
  disableBtn = false;
  selectedKey: string;
  invites: string[];
  user: Person;
  invited=false;
  toPerson: Person;
  subscriptions: Subscription[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private emitterService: EventEmitterService,
    private arangoService: ArangoService,
    private selectsService: SelectsService,
    private router: Router,
    private groupsService: GroupsService,
    private auth: AuthService,
    ) 
  {
  
    this.GroupForm = this.formBuilder.group({
      groupName: new FormControl('Fred', Validators.compose([Validators.required])),
      groupType: new FormControl('', Validators.compose([Validators.required])),
      createdBy: new FormControl('', Validators.compose([])),
    });
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'GroupFormComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
    this.subscriptions.push( this.selectsService.getSelects()
    .subscribe( (result) => {
      this.groupTypes = this.selectsService.selects.groupTypes;
      console.log('getselects ' + JSON.stringify(result));
      
    }))
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit() {
  }


  onPageChange(event){
    console.log('Page change '+event);
  }


  ngOnChanges() {
    this.selectedEmail = '';

    
    if (this.inputGroup) {
     
      this.GroupForm.patchValue(this.inputGroup);
    }
  }

  submitGroupForm() {
    console.log('Submit group form ' + JSON.stringify(this.GroupForm.value));

    if (this.GroupForm.valid) {
      this.disableBtn = true;
      console.log('Submit group form - valid');
      let exist = true;
      const gKey = (this.inputGroup._key === '')? createUUID(): this.inputGroup._key;

      const group: Group = {
        _key: gKey,
        groupName: this.GroupForm.value.groupName,
        groupType:  this.GroupForm.value.groupType,
        createdBy: this.GroupForm.value.createdBy,
        meta: updateMeta()
      }
      console.log('Group is: ' + JSON.stringify(group));

      this.subscriptions.push( this.groupsService.updateGroup(group)
      .subscribe( (result) => {
        console.log('Update result  is: ' + JSON.stringify(result.content));
        this.emitterService.emitter.emit('Message','Group ' + this.GroupForm.value.groupName + ' saved');
      
        if (this.inputGroup._key === '') { // New group
          // If new group.... 
          const gm: GroupMembership = {
            _key: createUUID(),
            from: gKey,
            groupOwner: this.GroupForm.value.createdBy,
            to: this.GroupForm.value.createdBy,
            primaryContact: true,
            admin: true,
            invitedStatus: InvitedStatus.Accepted, // Owner
            meta: updateMeta()
          }
          
          this.subscriptions.push( this.groupsService.updateGroupMembership(gm)
          .subscribe( (result) => {
            console.log('Update result  is: ' + JSON.stringify(result.content));
            this.emitterService.emitter.emit('Message','Group Member ' + this.GroupForm.value.groupName + ' saved');
            this.notifyParent.emit('GroupMember  Saved');
            this.disableBtn = false;
          }));
        }

       
      }))


      console.log('group  ' + JSON.stringify(group));
     
    } else {
      alert("Oops missed something "  + JSON.stringify(findInvalidControlsRecursive(this.GroupForm)));
    }
  }
  
}
