import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnChanges, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { LoginService } from 'src/services/loginservice/login.service';
import { ArangoService } from 'src/services/arangoservice/arango.service';
import { Observable, of, Subscription } from 'rxjs';
import { Person } from '../../../../../bo9-shared/models/person/person.model';
import { validationMessages } from 'src/helpers/form.helper';
import { contactFullName } from '../../../../../bo9-shared/models/contact/contact.model';
import { FormRelation, Relation} from '../../../../../bo9-shared/models/person/relation.model';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import { BO9UUID } from '../../../../../bo9-shared/models/UUID.model';
import { RelationDialogComponent } from '../../dialogs/relationDialog/relationDialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchService } from 'src/services/search/search.service';
import { createUUID } from '../../../../../bo9-shared/uuid.service';
import { RelationsService } from 'src/services/relations/relations.service';
import { mkMTPearl, Pearl, PearlSearch } from '../../../../../bo9-shared/models/pearl/pearl.model';
import { PearlDialogComponent } from 'src/displayComponents/dialogs/pearlDialog/pearlDialog.component';
import { EventEmitterService } from 'src/services/events/events.service';
import { ParseTreeResult } from '@angular/compiler';
import { NaturalNumber, NaturalNumberTypes } from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';
import { SelectsService } from 'src/services/selects/selects.service';
import { DateToYYMMDDhhmmss } from '../../../../../bo9-shared/helpers/time.helper';
import { noHtml } from '../../../../../bo9-shared/helpers/text.helper';

import { findInvalidControlsRecursive } from '../../../helpers/form.helper';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import { PearlService } from 'src/services/pearl/pearl.service';
import { AuthService } from 'src/services/auth/auth.service';
import { userIsAdmin } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { Router } from '@angular/router';

@Component({
  selector: 'seen-pearl-list',
  templateUrl: './seenPearlList.component.html',
  styleUrls: ['./seenPearlList.component.scss'],
  // make fade in animation available to this component

})
export class SeenPearlListComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() email: string;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() notifyParent: EventEmitter<Pearl> = new EventEmitter<Pearl>();
  public displayedColumns = ['title', 'myNN', 'theirNN','relationship', 'pearlmodule', 'timeStamp'];
  public dataSource = new MatTableDataSource<any>();


  messagesLoading$: Observable<boolean>;

  currentPane = 'center';
  valid: false;

  directionLinks: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;

  currentI$: Observable<FormRelation>;
  currentPerson: Person;
  loading = false;
  fieldValues = 0;
  relations: FormRelation[] =[];
  SearchForm: FormGroup;
  validationMessages = validationMessages;
  pearlCount = 0;
  selectedPearl$: Observable<Pearl>;

  PearlModuleTypes$: Observable<string[]>;
  PearlModuleTypes: string[]
  nnKeys: string[];
  nnSource: string[];
  invites: string[];
  pageSize: number;
  user: Person;
  currentPearlPane = 'center';

  pearlSearch: PearlSearch = null;
  subscriptions: Subscription[] = [];
  constructor(
    private pearlService: PearlService,
    private auth: AuthService,
    private router: Router,
    private arangoService: ArangoService,
    )
  {
    this.currentPearlPane = 'center';
    this.user = this.auth.getAuthUser();
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'SeenPearlListComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe ({next: () => {}})); 
    if (userIsAdmin(this.user)) {
      this.displayedColumns = ['edit','title', 'myNN', 'theirNN','relationship', 'pearlmodule', 'timeStamp'];

    }

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }



  ngOnChanges() {
    this.loading = false;
    this.loadPearls();
  }

  loadPearls() {
    if (this.user.preferences) {
      this.pageSize = this.user.preferences.rowsPerPage? +this.user.preferences.rowsPerPage: 15;
    }

    this.subscriptions.push( this.pearlService.getSeen( this.email)
    .subscribe( (res:any) => {
    //  console.log("subscribe  "+JSON.stringify(res));
      this.dataSource.data =[];
      let line: any[] = [];
      for (const both of res.content) {
        both.p.lastAdded = both.s.pearltoperson.whenAdded;
		  both.p.content = noHtml(both.p.content,{});

        line.push(both.p);
      }
      this.dataSource.data = line;
      
    }))
  }

  onEditClick(element:Pearl) {
    this.selectedPearl$ = of(element);
  }

  onCloseClick() {
    this.selectedPearl$ = null;
  }

  pearlSubmittedHandler($event: any) {
    console.log(' Pearl  submitted '+ JSON.stringify($event));
    this.selectedPearl$ = of(null);
    this.loadPearls();
  }


  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.loadPearls();
  }



  ngOnInit() {
    //this.loadRelations();
  }

  onPageChange(event){
    console.log(event);
  }

}
