export enum Capabilities { // Bit positions
    None = 0,
    User = 1,
    tbd2 = 2,
    tbd3 = 3,
    Apprentice = 4,
    tbd5 = 5,
    tbd6 = 6,
    tbd7 = 7,
    tbd8 = 8,
    tbd9 = 9,
    tbd10 = 10,
    tbd11 = 11,
    tbd12 = 12,
    Journeyman = 13,
    SeniorJ = 14,
    tbd15 = 15,
    Master = 16,
    tbd17 = 17,
    tbd18 = 18,
    tbd19 = 19,
    SuperAdmin = 20,
    tbd21 = 21,
    tbd22 = 22,
    tbd23 = 23,
    tbd24 = 24,
    tbd25 = 25,
    tbd26 = 26,
    Admin = 27, // 3 ^ 3 - 9 is too obvious
    tbd28 = 28,
    tbd29 = 29,
    tbd30 = 30,
    tbd31 = 31,
}

export enum MatchCheckType{
    email = 'email',
    key = 'key',
    name='name'
}