import { Component, HostListener } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { AuthService } from '../services/auth/auth.service'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'admin';
  notified = false;

  crs;
  page: string;
  constructor(private authService: AuthService, 
    private router: Router) {

    console.log('Start App component');
    router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        let route = event.state.root.firstChild;
        this.page = 'page-' + JSON.stringify(route.data);
        console.log('Page '+this.page);
      }
    });
  }

  @HostListener('window:focus')
  onFocus(event: any): void {
      console.log('Got window focus ');
      if (!this.authService.isAuthenticated() && !this.notified) {
       // alert('You are no longer logged in'); // In this order so you can't NOT log out
        this.notified = true;
      //  this.router.navigate(['login'] );
      } else {
        console.log('Authenticated user');
        this.notified = false;
      }



  }
}
